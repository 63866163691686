<script lang="ts" setup>
import { Button, ButtonVariant } from '../../Button';
import { ErrorMessage } from '../../ErrorMessage';
import { TextStyle } from '../../TextStyle';
import { InputLabel } from '../../InputLabel';
import { DropZone } from '../../DropZone';
import { DocumentPlusIcon } from '@heroicons/vue/24/outline';
import { computed, ref, watch } from 'vue';
import { UploadedFile, useFileManager } from '@app/panel/Composables/useFileManager';
import { UploadedFile as UploadedFiles } from '@app/panel/Containers';

const props = withDefaults(defineProps<{
  label: string;
  accept: Array<'image' | 'document'>;
  disabled?: boolean;
  multiple?: boolean;
}>(), {
  disabled: false,
  multiple: false,
});

const IMAGE_ACCEPTS = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg',
  'image/svg+xml',
  'image/webp',
];

const DOCUMENT_ACCEPTS = [
  'application/pdf',
];

const emit = defineEmits<{
  upload: [files: File[]];
}>();

const modelValue = defineModel<UploadedFile[]>({ required: true });
const currentFile = defineModel<null | string>('currentFile', { required: false });

const $fileInput = ref<HTMLInputElement>();

const accepts = computed<string[]>(() => {
  const items: string[] = [];

  if (props.accept.includes('image')) {
    items.push(...IMAGE_ACCEPTS);
  }

  if (props.accept.includes('document')) {
    items.push(...DOCUMENT_ACCEPTS);
  }

  return items;
});

function handleUpload(files: File[]): void {
  emit('upload', files);
}

function openFileUploadDialog(): void {
  $fileInput.value.click();
}

function handleFileChange(): void {
  addFiles([...$fileInput.value.files]);
  $fileInput.value.value = null;
}

const {
  addFiles,
  error,
  removeFile,
  uploadedFiles,
} = useFileManager(1, 5_242_880, accepts.value);

watch(() => uploadedFiles.value, () => modelValue.value = uploadedFiles.value, { deep: true });

function deleteCurrentFile(): void {
  currentFile.value = null;
}
</script>

<template>
  <div>
    <InputLabel :label="label" />

    <div
      v-if="currentFile"
      class="w-full border p-2 rounded flex justify-between items-center"
    >
      <img
        class="w-20 rounded"
        :src="modelValue[0]?.url || currentFile"
      >
      <div class="flex space-x-2">
        <Button
          :variant="ButtonVariant.Default"
          @click="openFileUploadDialog"
        >
          {{ $t('panel.containers:file-upload:browse') }}
        </Button>
        <Button
          :variant="ButtonVariant.Default"
          @click="deleteCurrentFile"
        >
          {{ $t('panel.containers:file-upload:delete') }}
        </Button>
      </div>
    </div>

    <div
      class="mt-1 space-y-2"
    >
      <div
        v-show="!currentFile"
        :class="{
          'pointer-events-none': disabled
        }"
      >
        <DropZone
          v-slot="{ dropZoneActive }"
          v-on="! disabled ? { click: openFileUploadDialog, filesDropped: handleUpload } : { }"
        >
          <div
            class="flex justify-center px-6 pt-5 pb-6 border border-slate-300 hover:border-slate-400 hover:bg-slate-50 cursor-pointer border-dashed rounded-md"
            :class="{
              'border-slate-400 bg-slate-50': dropZoneActive,
              'border-red-300': error,
              'opacity-50 pointer-events-none': disabled
            }"
          >
            <div class="flex flex-col items-center space-y-2">
              <DocumentPlusIcon class="h-12 w-12 text-slate-400" />

              <TextStyle>
                <input
                  ref="$fileInput"
                  class="hidden"
                  type="file"
                  :accept="props.accept.join(', ')"
                  :multiple="multiple"
                  @change="handleFileChange"
                >

                <div class="flex space-x-1">
                  <Button
                    plain
                    :variant="ButtonVariant.Primary"
                    @click.stop="openFileUploadDialog"
                  >
                    {{ $t('panel.containers:file-upload:click-here') }}
                  </Button>

                  <span class="text-slate-700">
                    {{ $t('panel.containers:file-upload:drag-and-drop') }}
                  </span>
                </div>
              </TextStyle>
            </div>
          </div>
        </DropZone>
      </div>

      <UploadedFiles
        v-for="uploadedFile in uploadedFiles"
        :key="uploadedFile.id"
        :name="uploadedFile.name"
        :progress="uploadedFile.progress"
        @remove="removeFile(uploadedFile.id)"
      />
    </div>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
