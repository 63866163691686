<script setup lang="ts">

import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  Heading,
  HeadingSize,
  Modal,
  Select,
  TextContainer,
  TextInput,
} from '@app/panel/Components';
import { CubeIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { inject, Ref } from 'vue';
import * as Types from '@app/panel/types/generated';
import { Option } from '@app/panel/Components/Select';

defineProps<{
  open: boolean;
}>();

defineEmits<{
  (e: 'close'): void;
}>();

const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const returnAddressOptions = inject<Option[]>('returnAddressOptions');
const shipmentTypes = inject<Option[]>('shipmentTypes');

const form = useForm({
  return_address_id: null,
  shipment_type: null,
  tracking_number: '',
  tracking_url: '',
});

function submit() {
  form.put(route('panel.requests.approve-with-manual-shipment', returnOrder.value));
}

</script>

<template>
  <Modal
    :open="open"
    icon-color="blue"
    @close="$emit('close')"
  >
    <template #icon>
      <CubeIcon class="h-6 w-6" />
    </template>

    <Heading
      :size="HeadingSize.Medium"
      class="text-center"
    >
      {{ $t('panel.requests:detail-page-actions:approve-manual-shipment:title') }}
    </Heading>

    <TextContainer class="my-6">
      {{ $t('panel.requests:detail-page-actions:approve-manual-shipment:description') }}
    </TextContainer>

    <Form @submit="submit">
      <FormLayout>
        <FormLayout>
          <Select
            v-model="form.return_address_id"
            :label="$t('panel.requests:detail-page-actions:approve-manual-shipment:return-address-field')"
            :options="returnAddressOptions"
            :error="form.errors.return_address_id"
            autofocus
          />
        </FormLayout>
        <FormLayout>
          <Select
            v-model="form.shipment_type"
            :label="$t('panel.requests:detail-page-actions:approve-manual-shipment:shipment-type-field')"
            :options="shipmentTypes"
            :error="form.errors.shipment_type"
          />
        </FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.tracking_number"
            :label="$t('panel.requests:detail-page-actions:approve-manual-shipment:tracking-number-field')"
            :placeholder="$t('panel.requests:detail-page-actions:approve-manual-shipment:tracking-number-placeholder')"
            :error="form.errors.tracking_number"
            autofocus
          />
        </FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.tracking_url"
            :label="$t('panel.requests:detail-page-actions:approve-manual-shipment:tracking-url-field')"
            :placeholder="$t('panel.requests:detail-page-actions:approve-manual-shipment:tracking-url-placeholder')"
            :error="form.errors.tracking_url"
          />
        </FormLayout>

        <FormLayout>
          <Button
            full-width
            :type="ButtonType.Submit"
            :loading="form.processing"
            :variant="ButtonVariant.Primary"
          >
            {{ $t('panel.requests:detail-page-actions:approve-manual-shipment:approve-button') }}
          </Button>
        </FormLayout>
      </FormLayout>
    </Form>
  </Modal>
</template>
