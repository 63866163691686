import { usePage } from '@inertiajs/vue3';

export default function formatCurrency(value: number, locale: string): string {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: usePage().props.currency as string,
  });

  return formatter.format((value / 100));
}
