<script lang="ts" setup>
import { computed, inject, nextTick, watch } from 'vue';
import { Button, DraggableIcon, FormLayout } from '@app/panel/Components';
import { TranslationInputFields, TranslationInputFieldType } from '@app/panel/Containers';
import { PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { ReturnQuestionForm } from '@app/panel/types';
import { InertiaForm } from '@inertiajs/vue3';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { isEmpty } from 'lodash';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import * as Types from '@app/panel/types/generated';

type LocaleViewModel = Types.App.Models.ViewModels.LocaleViewModel;

const defaultLocale = inject<LocaleViewModel>('defaultLocale');
const locales = inject<LocaleViewModel[]>('locales');
const returnQuestionForm = inject<InertiaForm<ReturnQuestionForm>>('returnForm');

const showAnswerEditor = computed(() => {
  return [
    'dropdownSingle',
    'dropdownMulti',
    'radio',
    'checkbox',
  ].includes(returnQuestionForm.field_type);
});

function addAnswer(): void {
  const id = useUniqueId();
  const answersCount = Object.values(returnQuestionForm.answers).length;

  returnQuestionForm.answers[id] = {
    id,
    translations: {},
    sortOrder: answersCount > 0 ? answersCount : 0,
  };
}

function removeAnswer(id: string): void {
  delete returnQuestionForm.answers[id];
}

watch(() => returnQuestionForm.field_type, () => {
  if (showAnswerEditor.value && isEmpty(returnQuestionForm.answers)) {
    nextTick(() => {
      addAnswer();
    });
  }
});

const answersListId = useUniqueId('answers');

const answers = computed(() => {
  return Object.values(returnQuestionForm.answers);
});

const { sortedItems } = useDragAndDrop(answersListId, answers);

watch(sortedItems, () => {
  sortedItems.value.forEach((answer, index) => {
    returnQuestionForm.answers[answer.id].sortOrder = index;
  });
});
</script>

<template>
  <FormLayout>
    <TranslationInputFields
      v-model="returnQuestionForm.questionTranslations"
      :locales="locales"
      :errors="returnQuestionForm.errors"
      field-name="questionTranslations"
      :default-locale-id="defaultLocale?.id"
      :type="TranslationInputFieldType.TextInput"
      :label="$t('panel.settings.forms.list_forms.settings.return_questions.create.question_label')"
    />
    <TranslationInputFields
      v-model="returnQuestionForm.descriptionTranslations"
      :locales="locales"
      :errors="returnQuestionForm.errors"
      field-name="descriptionTranslations"
      :default-locale-id="defaultLocale?.id"
      :type="TranslationInputFieldType.Textarea"
      :label="$t('panel.settings.forms.list_forms.settings.return_questions.create.additional_description')"
    />
    <div v-if="showAnswerEditor">
      <table
        v-if="answers.length"
        :id="answersListId"
        class="w-full mb-4"
      >
        <tbody>
          <tr
            v-for="answer in answers"
            :id="answer.id"
            :key="answer.id"
            :label="answer.id"
          >
            <td class="w-full pb-3">
              <div class="flex">
                <div class="flex items-center self-start mt-8">
                  <DraggableIcon />
                </div>
                <div class="flex-1">
                  <TranslationInputFields
                    v-model="returnQuestionForm.answers[answer.id].translations"
                    :locales="locales"
                    :errors="returnQuestionForm.errors"
                    :field-name="`answers.${answer.id}.translations`"
                    :default-locale-id="defaultLocale?.id"
                    :type="TranslationInputFieldType.TextInput"
                    :label="$t('panel.settings.forms.list_forms.settings.return_questions.create.answer') + ` ${answer.sortOrder + 1}`"
                  />
                </div>
                <div class="mt-8 ml-2">
                  <TrashIcon
                    class="h-5 w-5 cursor-pointer text-slate-500 hover:text-slate-600"
                    @click="removeAnswer(answer.id)"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mr-9">
        <Button @click="addAnswer">
          <template #icon>
            <PlusIcon class="h-4 w-4" />
          </template>
          <span>{{ $t('panel.settings.forms.list_forms.settings.return_questions.create.add_answer') }}</span>
        </Button>
      </div>
    </div>
  </FormLayout>
</template>
