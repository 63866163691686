<script lang="ts" setup>
import { computed } from 'vue';
import {
  Badge,
  DataTable,
  Link,
  TextContainer,
  TextStyle,
  Tooltip,
} from '@app/panel/Components';
import {
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  ClockIcon,
  XCircleIcon,
} from '@heroicons/vue/24/outline';
import type { Types } from '@app/shared/types/generated-v2';
import { isEmpty } from 'lodash';
import { useBulkActionLoader } from '@app/panel/Composables/useBulkActionLoader';

type RequestOrderOverviewItemViewModel = Types['App.Http.ViewModels.Panel.Requests.RequestOrderOverviewItemViewModel'];

const props = defineProps<{
  headings: { text: string; value: string }[];
  activeHeadings: string[];
  orders: RequestOrderOverviewItemViewModel[];
  checkedItems: RequestOrderOverviewItemViewModel[];
  sortBy: string | null;
}>();

const emit = defineEmits<{
  check: [items: RequestOrderOverviewItemViewModel[]];
  sorted: [items: string | null];
}>();

const activeHeadingItems = computed(() => {
  return props.headings.filter((heading) => {
    return props.activeHeadings.includes(heading['value']);
  });
});

const { isRunningActiveBulkActions } = useBulkActionLoader();

function handleCheckedItems(items: RequestOrderOverviewItemViewModel[]): void {
  emit('check', items);
}

function handleSort(sort: string): void {
  emit('sorted', sort);
}
</script>

<template>
  <DataTable
    :headings="activeHeadingItems"
    :items="props.orders"
    :rounded="false"
    :checkable="true"
    :model-value="checkedItems"
    :sort-by="sortBy || undefined"
    :disabled-checkboxes="isRunningActiveBulkActions"
    @sorted="handleSort"
    @update:model-value="handleCheckedItems"
  >
    <template #returnNumber="{ item }">
      <div class="flex items-start justify-between">
        <TextContainer>
          <Link
            :href="$route('panel.requests.detail', item.id)"
            underline
          >
            {{ item.returnNumber }}
          </Link>
          <p>
            <TextStyle shade="subdued">
              {{ item.createdAt }}
            </TextStyle>
          </p>
        </TextContainer>
        <div
          v-if="item.noteCount > 0"
          class="ml-10 mt-0.5 flex items-center space-x-1"
          :class="{
            'opacity-40': ! item.hasUnreadNotes,
            'text-brand-500' : item.hasUnreadNotes,
          }"
        >
          <TextStyle :color="item.hasUnreadNotes ? 'text-brand-500' : ''">
            {{ item.noteCount }}
          </TextStyle>
          <ChatBubbleLeftIcon class="size-4" />
        </div>
      </div>
    </template>

    <template #customer="{ item }">
      <TextContainer>
        <div class="max-w-40 truncate">
          <TextStyle strong>
            {{ item.salesOrderNumber }}
          </TextStyle>
        </div>
        <Tooltip :content="item.customerEmail">
          <TextStyle shade="subdued">
            {{ item.customerName || '-' }}
          </TextStyle>
        </Tooltip>
      </TextContainer>
    </template>

    <template #state="{ item }: { item: RequestOrderOverviewItemViewModel }">
      <span
        v-if="item.state === 'request-pending'"
        class="flex items-center space-x-2 text-sm text-slate-500"
      >
        <ClockIcon class="h-5 text-slate-600" />
        <span>{{ $t('panel.requests.table_body.request_statuses.pending') }}</span>
      </span>
      <span
        v-if="item.state === 'request-rejected'"
        class="flex items-center space-x-2 text-sm text-red-600"
      >
        <XCircleIcon class="h-5 text-red-600" />
        <span>{{ $t('panel.requests.table_body.request_statuses.rejected') }}</span>
      </span>
      <span
        v-if="item.state === 'request-approved'"
        class="flex items-center space-x-2 text-sm text-green-600"
      >
        <CheckCircleIcon class="h-5 text-green-600" />
        <span>{{ $t('panel.requests.table_body.request_statuses.approved') }}</span>
      </span>
    </template>

    <template #status="{ item }: { item: RequestOrderOverviewItemViewModel }">
      <Badge :color="item.status.color">
        {{ item.status.label }}
      </Badge>
    </template>

    <template #tags="{ item }: { item: RequestOrderOverviewItemViewModel }">
      <div
        v-if="! isEmpty(item.tags)"
        class="flex items-center space-x-2"
      >
        <Badge
          v-for="tag in item.tags"
          :key="tag.id"
          :color="tag.color"
        >
          {{ tag.name }}
        </Badge>
      </div>

      <span
        v-else
        class="text-slate-300"
      >
        &mdash;
      </span>
    </template>
  </DataTable>
</template>
