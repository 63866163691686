<script lang="ts" setup>
import {
  ErrorMessage,
  Form,
  FormLayout,
  InputLabel,
  Page,
  Select,
  SelectOption,
  Stack,
  StackItem,
  StackSpacing,
  Textarea,
  TextInput,
  ToastVariant,
  Toggle,
} from '@app/panel/Components';
import {
  Button,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardSection,
  CardTitle,
  ColorInput,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { type Types } from '@app/shared/types/generated-v2';
import { PageNavigation } from './partials';
import { FormPreview } from '@app/panel/Containers';
import { FileUpload, UploadedFile } from '@app/panel/Containers';
import { useUpload } from '@app/panel/Composables/useUpload';
import { useForm } from '@inertiajs/vue3';
import { useToast } from '@app/panel/Composables/useToast';
import { router } from '@inertiajs/vue3';
import { computed, provide, watch } from 'vue';
import { useFileManager } from '@app/panel/Composables/useFileManager';
import { isEmpty } from 'lodash';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type FormViewModel = Types['App.Models.ViewModels.FormViewModel'];
type LocaleViewModel = Types['App.Models.ViewModels.LocaleViewModel'];
type FormLocaleViewModel = Types['App.Models.ViewModels.FormLocaleViewModel'];
type FormLocaleFallbackViewModel = Types['App.Models.ViewModels.FormLocaleFallbackViewModel'];

const props = defineProps<{
  form: FormViewModel;
  formLocale: FormLocaleViewModel;
  formLocaleFallback: FormLocaleFallbackViewModel;
  formLocales: FormLocaleViewModel[];
  attachmentFileSizeLimit: number;
  attachmentFileTypes: string[];
}>();

const localeOptions = computed<SelectOption[]>(() => {
  return props.formLocales.map((locale: LocaleViewModel): SelectOption => ({
    label: locale.name,
    value: locale.id,
  }));
});

const layoutForm = useForm({
  brand_color: props.formLocale.brandColor ?? '#000000',
  locale: props.formLocale.locale.id,
  cover_photo_src: props.formLocale.coverPhotoSource,
  logo_src: props.formLocale.logoSource,
  title: props.formLocale.title,
  intro_text: props.formLocale.introText,
  sync_with_default: props.formLocale.syncWithDefault,
  order_field_label: props.formLocale.orderFieldLabel,
  order_field_placeholder: props.formLocale.orderFieldPlaceholder,
  search_button_label: props.formLocale.searchButtonLabel,
});

type LayoutForm = typeof layoutForm;

watch(() => layoutForm.locale, () => {
  router.get(route('panel.settings.forms.layout', {
    form: props.form,
  }), { localeId: layoutForm.locale });
});

const {
  addFiles: addLogoImageFiles,
  clearFiles: clearLogoImageFiles,
  error: logoImageError,
  removeFile: removeLogoImageFile,
  setError: setLogoImageError,
  uploadedFiles: uploadedLogoImageFiles,
} = useFileManager(1, props.attachmentFileSizeLimit, props.attachmentFileTypes);

const {
  addFiles: addCoverImageFiles,
  clearFiles: clearCoverImageFiles,
  error: coverImageError,
  removeFile: removeCoverImageFile,
  setError: setCoverImageError,
  uploadedFiles: uploadedCoverImageFiles,
} = useFileManager(1, props.attachmentFileSizeLimit, props.attachmentFileTypes);

watch(() => uploadedLogoImageFiles.value, (newValue) => {
  if (isEmpty(newValue)) {
    return;
  }

  const logoImageFile = uploadedLogoImageFiles.value[0].file;

  if (!logoImageFile) {
    layoutForm.logo_src = props.formLocale.logoSource;
  } else {
    layoutForm.logo_src = URL.createObjectURL(logoImageFile);
  }
}, { deep: true });

watch(() => uploadedCoverImageFiles.value, () => {
  if (isEmpty(uploadedCoverImageFiles.value)) {
    return;
  }

  const coverImageFile = uploadedCoverImageFiles.value[0].file;

  if (!coverImageFile) {
    layoutForm.cover_photo_src = props.formLocale.logoSource;
  } else {
    layoutForm.cover_photo_src = URL.createObjectURL(coverImageFile);
  }
}, { deep: true });

function uploadLogoImageToVapor(): Promise<unknown> {
  const { upload } = useUpload();

  const logoImage = uploadedLogoImageFiles.value[0];

  return upload(logoImage.file, (progress) => logoImage.progress = progress)
    .then((response) => ({ id: 'logo', ...response }))
    .catch((error) => setLogoImageError(error));
}

function uploadCoverImageToVapor(): Promise<unknown> {
  const { upload } = useUpload();

  const coverImage = uploadedCoverImageFiles.value[0];

  return upload(coverImage.file, (progress) => coverImage.progress = progress)
    .then((response) => ({ id: 'cover', ...response }))
    .catch((error) => setCoverImageError(error));
}

function resetImageUploads(): void {
  clearLogoImageFiles();
  clearCoverImageFiles();
}

const { emitToastEvent } = useToast();

function prepareRequestData(response) {
  const logoResponse = response.find((item) => item.id === 'logo');
  const coverResponse = response.find((item) => item.id === 'cover');

  return Object.assign({
    form_locale_id: props.formLocale.id,
    brand_color: layoutForm.brand_color,
    title: layoutForm.title,
    intro_text: layoutForm.intro_text,
    sync_with_default: layoutForm.sync_with_default,
    order_field_label: layoutForm.order_field_label,
    order_field_placeholder: layoutForm.order_field_placeholder,
    search_button_label: layoutForm.search_button_label,
  },
  logoResponse && { logo_src: logoResponse },
  coverResponse && { cover_photo_src: coverResponse },
  );
}

async function uploadFilesToVapor() {
  const promises = [];

  if (!isEmpty(uploadedLogoImageFiles.value)) {
    promises.push(uploadLogoImageToVapor);
  }

  if (!isEmpty(uploadedCoverImageFiles.value)) {
    promises.push(uploadCoverImageToVapor);
  }

  return await Promise.all(promises.map((f) => f()))
    .then((response) => prepareRequestData(response))
    .catch((error) => {
      emitToastEvent({ message: error, variant: ToastVariant.Critical });
    });
}

function saveLayoutForm(): void {
  uploadFilesToVapor()
    .then((response) => {
      router.post(route('panel.settings.forms.layout.update', {
        form: props.form,
      }), response, {
        preserveState: (page) => {
          const formLocale = page.props.formLocale as FormLocaleViewModel;

          layoutForm.brand_color = formLocale.brandColor;

          return true;
        },
        preserveScroll: true,
        onError: (errors: Record<keyof LayoutForm, string>) => layoutForm.errors = errors,
        onSuccess: () => layoutForm.clearErrors(),
        onStart: () => layoutForm.processing = true,
        onFinish: () => {
          layoutForm.processing = false;
          resetImageUploads();
        },
      });
    });
}

provide('form', props.form);

const disableBranding = computed(() => !props.formLocale.isDefault && layoutForm.sync_with_default);

</script>

<template>
  <AppLayout :title="$t('panel.forms:layout:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem>
          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.logo-and-layout.logo-and-layout')">
                {{ $t('panel.forms:layout:title') }}
              </CardTitle>
              <CardDescription>{{ $t('panel.forms:layout:subtitle') }}</CardDescription>
            </CardHeader>
            <CardSection>
              <Form>
                <FormLayout>
                  <FormLayout>
                    <Select
                      v-model="layoutForm.locale"
                      :label="$t('panel.forms:layout:language')"
                      :options="localeOptions"
                    />
                  </FormLayout>

                  <FormLayout>
                    <TextInput
                      v-model="layoutForm.title"
                      :error="layoutForm.errors.title"
                      :label="$t('panel.forms:layout:title-input')"
                    />
                  </FormLayout>

                  <FormLayout>
                    <Textarea
                      v-model="layoutForm.intro_text"
                      :error="layoutForm.errors.intro_text"
                      :label="$t('panel.forms:layout:intro-text')"
                      :rows="3"
                    />
                  </FormLayout>

                  <FormLayout v-if="! formLocale.isDefault">
                    <Toggle
                      v-model="layoutForm.sync_with_default"
                      :error="layoutForm.errors.sync_with_default"
                      :title="$t('panel.forms:layout:sync-with-default')"
                    />
                  </FormLayout>

                  <FormLayout>
                    <TextInput
                      v-model="layoutForm.order_field_label"
                      :label="$t('panel.forms:layout:order-field-label:title')"
                      :error="layoutForm.errors.order_field_label"
                      :placeholder="$t('panel.forms:layout:order-field-label:placeholder')"
                    />
                  </FormLayout>

                  <FormLayout>
                    <TextInput
                      v-model="layoutForm.order_field_placeholder"
                      :label="$t('panel.forms:layout:order-field-placeholder:title')"
                      :error="layoutForm.errors.order_field_placeholder"
                      :placeholder="$t('panel.forms:layout:order-field-placeholder:placeholder')"
                    />
                  </FormLayout>

                  <FormLayout>
                    <TextInput
                      v-model="layoutForm.search_button_label"
                      :label="$t('panel.forms:layout:search-button-label:title')"
                      :error="layoutForm.errors.search_button_label"
                      :placeholder="$t('panel.forms:layout:search-button-label:placeholder')"
                    />
                  </FormLayout>

                  <FormLayout
                    :columns="1"
                    :columns-md="3"
                  >
                    <FormLayout>
                      <div>
                        <ColorInput
                          v-model="layoutForm.brand_color"
                          :label="$t('panel.forms:layout:brand-color')"
                          :disabled="disableBranding"
                          name="brand_color"
                        />
                      </div>
                    </FormLayout>

                    <FormLayout>
                      <div>
                        <InputLabel :label="$t('panel.forms:layout:logo-image')" />

                        <div class="mt-1 space-y-2">
                          <FileUpload
                            :disabled="disableBranding"
                            :accept="attachmentFileTypes"
                            :error="!! logoImageError"
                            @upload="addLogoImageFiles"
                          />

                          <UploadedFile
                            v-for="logoImageFile in uploadedLogoImageFiles"
                            :key="logoImageFile.id"
                            :name="logoImageFile.name"
                            :progress="logoImageFile.progress"
                            @remove="() => removeLogoImageFile(logoImageFile.id)"
                          />

                          <ErrorMessage
                            v-if="logoImageError"
                            :message="logoImageError"
                          />
                        </div>
                      </div>
                    </FormLayout>

                    <FormLayout>
                      <div>
                        <InputLabel :label="$t('panel.forms:layout:cover-image')" />

                        <div class="mt-1 space-y-2">
                          <FileUpload
                            :disabled="disableBranding"
                            :accept="attachmentFileTypes"
                            :error="!! coverImageError"
                            @upload="addCoverImageFiles"
                          />

                          <UploadedFile
                            v-for="coverImageFile in uploadedCoverImageFiles"
                            :key="coverImageFile.id"
                            :name="coverImageFile.name"
                            :progress="coverImageFile.progress"
                            @remove="() => removeCoverImageFile(coverImageFile.id)"
                          />

                          <ErrorMessage
                            v-if="coverImageError"
                            :message="coverImageError"
                          />
                        </div>
                      </div>
                    </FormLayout>
                  </FormLayout>
                </FormLayout>
              </Form>
            </CardSection>
            <CardFooter>
              <Button
                :disabled="! layoutForm.isDirty"
                :loading="layoutForm.processing"
                @click="saveLayoutForm"
              >
                {{ $t('panel.forms:layout:save') }}
              </Button>
            </CardFooter>
          </Card>
        </StackItem>

        <StackItem>
          <FormPreview
            :color="layoutForm.brand_color"
            :cover="layoutForm.cover_photo_src"
            :logo="layoutForm.logo_src"
            :title="layoutForm.title"
            :intro-text="layoutForm.intro_text"
            :order-field-label="layoutForm.order_field_label"
            :order-field-placeholder="layoutForm.order_field_placeholder"
            :search-button-label="layoutForm.search_button_label"
            :form-locale-fallback="formLocaleFallback"
          />
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>
</template>
