<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '@app/panel/Pages/Settings/Forms/partials';
import { Page } from '@app/panel/Components';
import { provide } from 'vue';
import * as Types from '@app/panel/types/generated';
import { SelectOption } from '@app/customer/Components';
import {
  DynamicReturnCostsForm,
  MultiColloForm,
  PrintableReturnForm,
  ShippingCreationForm,
  ShippingLabelForm,
  StoreLocationForm,
} from '@app/panel/Pages/Settings/Forms/ShippingSettings';
import InternationalShippingForm from '@app/panel/Pages/Settings/Forms/ShippingSettings/InternationalShippingForm.vue';

type FormShippingSettingsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel;

const props = defineProps<{
  form: FormShippingSettingsViewModel['form'];
  countryOptions: FormShippingSettingsViewModel['countryOptions'];
  shippingLabelReferenceTypes: SelectOption[];
  returnOrderMetadataKeys: SelectOption[];
  barcodeTypeOptions: SelectOption[];
  returnInstructions: FormShippingSettingsViewModel['returnInstructions'];
  shippingInstructions: FormShippingSettingsViewModel['shippingInstructions'];
  printProductIdentifierField: FormShippingSettingsViewModel['printProductIdentifierField'];
  printProductBarcodeField: FormShippingSettingsViewModel['printProductBarcodeField'];
  printProductFieldTypeOptions: FormShippingSettingsViewModel['printProductFieldTypeOptions'];
}>();

provide('form', props.form);

</script>

<template>
  <AppLayout :title="$t('panel.forms:shipping-methods:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <ShippingCreationForm
        :form="form"
        :country-options="countryOptions"
        :shipping-label-reference-types="shippingLabelReferenceTypes"
        :return-order-metadata-keys="returnOrderMetadataKeys"
      />

      <InternationalShippingForm
        :form="form"
        :country-options="countryOptions"
      />

      <ShippingLabelForm
        :form="form"
        :shipping-label-reference-types="shippingLabelReferenceTypes"
        :return-order-metadata-keys="returnOrderMetadataKeys"
      />

      <DynamicReturnCostsForm
        :form="form"
        :return-instructions="returnInstructions"
      />

      <StoreLocationForm
        :form="form"
        :shipping-instructions="shippingInstructions"
      />

      <PrintableReturnForm
        :form="form"
        :barcode-type-options="barcodeTypeOptions"
        :print-product-identifier-field="printProductIdentifierField"
        :print-product-barcode-field="printProductBarcodeField"
        :print-product-field-type-options="printProductFieldTypeOptions"
      />

      <MultiColloForm
        :form="form"
      />
    </Page>
  </AppLayout>
</template>
