<script lang="ts" setup>
import { useUniqueId } from '../../../Composables/useUniqueId';
import { computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: null,
  },

  hasFiles: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{ (e: 'submit', event: Event) }>();

const elementId = props.id || useUniqueId('form');

function onSubmit(e: Event): void {
  emit('submit', e);
}

const formEncoding = computed(() => {
  return props.hasFiles
    ? 'multipart/form-data'
    : 'application/x-www-form-urlencoded';
});
</script>

<template>
  <form
    :id="elementId"
    :enctype="formEncoding"
    @submit.prevent="onSubmit"
  >
    <slot />
  </form>
</template>
