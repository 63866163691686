export interface Heading {
  text: string;
  value: string;
  column?: string;
  sortable?: boolean;
  noWrap?: boolean;
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}
