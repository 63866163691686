<script lang="ts" setup>
import { Button, ButtonVariant, TextStyle } from '@app/panel/Components';
import { DropZone } from '@app/panel/Components';
import { DocumentPlusIcon } from '@heroicons/vue/24/outline';
import { computed, PropType, ref } from 'vue';

const props = defineProps({
  error: {
    type: Boolean,
    default: false,
  },

  accept: {
    type: Array as PropType<string[]>,
    default: () => ([]),
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  multiple: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{ (e: 'upload', files: File[]): void }>();

function handleUpload(files: File[]): void {
  emit('upload', files);
}

const acceptableFileTypes = computed<string>(() => {
  return props.accept.join(', ');
});

const fileInput = ref<HTMLInputElement>(null);

function openFileUploadDialog(): void {
  fileInput.value.click();
}

function handleFileChange(e: InputEvent): void {
  const target = e.target as HTMLInputElement;

  emit('upload', [...target.files]);

  target.value = null;
}
</script>

<template>
  <div
    :class="{
      'pointer-events-none': disabled
    }"
  >
    <DropZone
      v-slot="{ dropZoneActive }"
      v-on="! disabled ? { click: openFileUploadDialog, filesDropped: handleUpload } : { }"
    >
      <div
        class="flex justify-center px-6 pt-5 pb-6 border border-slate-300 hover:border-slate-400 hover:bg-slate-50 cursor-pointer border-dashed rounded-md"
        :class="{
          'border-slate-400 bg-slate-50': dropZoneActive,
          'border-red-300' : error,
          'opacity-50 pointer-events-none': disabled
        }"
      >
        <div class="flex flex-col items-center space-y-2">
          <DocumentPlusIcon class="h-12 w-12 text-slate-400" />

          <TextStyle>
            <input
              ref="fileInput"
              class="sr-only"
              type="file"
              :accept="acceptableFileTypes"
              :multiple="multiple"
              @change="handleFileChange"
            >

            <div class="flex space-x-1">
              <Button
                plain
                :variant="ButtonVariant.Primary"
                @click.stop="openFileUploadDialog"
              >
                {{ $t('panel.containers:file-upload:click-here') }}
              </Button>

              <span class="text-slate-700">
                {{ $t('panel.containers:file-upload:drag-and-drop') }}
              </span>
            </div>
          </TextStyle>
        </div>
      </div>
    </DropZone>
  </div>
</template>
