<script lang="ts" setup>
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Button,
  ButtonContent,
  ButtonIcon,
  DataList,
  DataListContent,
  DataListItem,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { router } from '@inertiajs/vue3';
import { ref } from 'vue';
import { PagePropsViewModel } from './types';
import { EllipsisHorizontalIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/vue/16/solid';
import CreateShippingInstructions from './Components/CreateShippingInstructions.vue';
import DeleteShippingInstructionsDialog from '../Components/DeleteShippingInstructionsDialog.vue';

const props = defineProps<PagePropsViewModel>();
const isCreateModalOpen = ref(false);
const isDeleteDialogOpen = ref(false);
const selectedShippingInstructionForRemoval = ref('');

function openDeleteDialog(shippingInstructionId: string) {
  selectedShippingInstructionForRemoval.value = shippingInstructionId;
  isDeleteDialogOpen.value = true;
}

function openCreateModal(): void {
  console.log(props.types);
  isCreateModalOpen.value = true;
}

function closeCreateModal(): void {
  isCreateModalOpen.value = false;
}

function goToEdit(shippingInstructionId: string) {
  router.visit(route('panel.settings.shipping-instructions.edit', shippingInstructionId));
}

</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:shipping-instructions:title')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:templates:shipping-instructions:title') }}</PageTitle>
        <PageDescription>{{ $t('panel.settings:templates:shipping-instructions:subtitle') }}</PageDescription>
        <template
          v-if="shippingInstructions.length !> 0"
          #page-header-actions
        >
          <Button
            @click="openCreateModal"
          >
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:templates:shipping-instructions:create') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <DataList v-if="props.shippingInstructions.length > 0">
        <DataListItem
          v-for="instruction in props.shippingInstructions"
          :id="instruction.id"
          :key="instruction.id"
        >
          <DataListContent>
            {{ instruction.name }}
          </DataListContent>

          <ActionList alignment="end">
            <ActionListTrigger>
              <Button
                icon-only
                variant="ghost"
                size="small"
              >
                <ButtonIcon :icon="EllipsisHorizontalIcon" />
              </Button>
            </ActionListTrigger>
            <ActionListBody>
              <ActionListSection label="Actions">
                <ActionListItem
                  :icon="PencilIcon"
                  as="button"
                  @click="goToEdit(instruction.id)"
                >
                  {{ $t('panel.global:actions:edit') }}
                </ActionListItem>
                <ActionListItem
                  :icon="TrashIcon"
                  as="button"
                  variant="destructive"
                  @click="openDeleteDialog(instruction.id)"
                >
                  {{ $t('panel.global:actions:delete') }}
                </ActionListItem>
              </ActionListSection>
            </ActionListBody>
          </ActionList>
        </DataListItem>
      </DataList>
      <EmptyState v-else>
        <EmptyStateContent>
          <EmptyStateTitle>{{ $t('panel.settings:templates:shipping-instructions:no-items-available') }}</EmptyStateTitle>
          <EmptyStateDescription>{{ $t('panel.settings:templates:shipping-instructions:no-items-available:description') }}</EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:templates:shipping-instructions:create') }}</ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>

  <CreateShippingInstructions
    :open="isCreateModalOpen"
    :types="props.types"
    @close="closeCreateModal"
  />
  <DeleteShippingInstructionsDialog
    :open="isDeleteDialogOpen"
    :shipping-instruction="selectedShippingInstructionForRemoval"
    @close="isDeleteDialogOpen = false"
  />
</template>
