<script setup lang="ts">
import { computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection, DataTable,
  Form,
  FormLayout,
  Select,
  TextStyle,
} from '@app/panel/Components';
import type { Types } from '@app/shared/types/generated-v2';
import { router } from '@inertiajs/vue3';

const props = withDefaults(
  defineProps<{
    owner: Types['App.Models.ViewModels.MemberViewModel'];
    isTenantOwner: boolean;
    ownershipTransferableUsers: Array<Types['App.Models.ViewModels.MemberViewModel']>;
    hasActiveOwnershipTransfer: boolean;
    openRequests: Array<Types['App.Models.ViewModels.OwnershipTransferRequestViewModel']>;
  }>(), {
    isTenantOwner: false,
    hasActiveOwnershipTransfer: false,
  },
);

const ownershipForm = useForm({
  user: null,
});

const transferableUsers = computed(() => {
  return props.ownershipTransferableUsers.map((member) => ({
    value: member.id,
    label: member.email,
  }));
});

const hasTransferableUsers = computed(() => {
  return props.ownershipTransferableUsers.length > 0;
});

function transferOwnership() {
  if (props.isTenantOwner) {
    ownershipForm.post(route('panel.account.transfer-ownership'), {
      preserveState: true,
      preserveScroll: true,
    });
  }
}

function deleteRequests() {
  router.delete(route('panel.account.ownership-transfer-requests.delete'));
}

const headings = [
  {
    text: 'Original Owner',
    value: 'originalOwner',
  },
  {
    text: 'New Owner',
    value: 'newOwner',
  },
  {
    text: 'Created At',
    value: 'createdAt',
  },
];
</script>

<template>
  <Card
    v-if="hasTransferableUsers"
    :title="$t('panel.account.team.transfer_ownership.card_title')"
    :subtitle="$t('panel.account.team.transfer_ownership.card_subtitle')"
  >
    <CardSection>
      <Form @submit="transferOwnership">
        <FormLayout>
          <FormLayout v-if="!isTenantOwner || hasActiveOwnershipTransfer">
            <Banner
              v-if="!isTenantOwner"
              :variant="BannerVariant.Warning"
            >
              {{ $t('panel.account.team.transfer_ownership.form_banner_warning') }}
              <TextStyle strong>
                {{ owner.email }}.
              </TextStyle>
            </Banner>

            <Banner
              v-if="hasActiveOwnershipTransfer"
              :variant="BannerVariant.Info"
            >
              <div class="flex justify-between">
                {{ $t('panel.account.team.transfer_ownership.form_banner_info') }}

                <Button
                  :variant="ButtonVariant.Primary"
                  plain
                  @click="deleteRequests()"
                >
                  <strong>{{ $t('panel.account.team.team_members.cancel-ownership-transfer-request') }}</strong>
                </Button>
              </div>
            </Banner>

            <div class="mb-1">
              <TextStyle strong>
                {{ $t('panel.account.team.team_members.open-transfer-requests') }}
              </TextStyle>
            </div>

            <DataTable
              v-if="hasActiveOwnershipTransfer"
              :headings="headings"
              :items="openRequests"
            />
          </FormLayout>

          <FormLayout v-if="isTenantOwner && !hasActiveOwnershipTransfer">
            <Select
              v-model="ownershipForm.user"
              label-hidden
              :options="transferableUsers"
              :error="ownershipForm.errors.user"
            />
          </FormLayout>

          <FormLayout>
            <div>
              <Button
                :type="ButtonType.Submit"
                :variant="ButtonVariant.Primary"
                :disabled="!isTenantOwner || ownershipForm.user === null || hasActiveOwnershipTransfer"
                :loading="ownershipForm.processing"
              >
                {{ $t('panel.account.team.transfer_ownership.transfer_ownership_button') }}
              </Button>
            </div>
          </FormLayout>
        </FormLayout>
      </Form>
    </CardSection>
  </Card>
</template>
