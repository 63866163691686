import { InertiaForm } from '@inertiajs/vue3';
import { computed, Ref } from 'vue';
import * as Types from '@app/panel/types/generated';

type ReturnOrderItemViewModel = Types.App.Models.ViewModels.ReturnOrderItemViewModel;

export function useValueOfDepreciatedItems(
  returnOrderItems: Ref<ReturnOrderItemViewModel[]>,
  traitForm: InertiaForm<{ subtractDepreciation: any }>,
) {
  function getReturnOrderItem(returnOrderItemId: string): ReturnOrderItemViewModel {
    return returnOrderItems.value.find((item: ReturnOrderItemViewModel) => item.id === returnOrderItemId);
  }

  const valueOfDepreciatedItems = computed(() => {
    let total = 0;

    Object.keys(traitForm.subtractDepreciation).forEach((key) => {
      if (traitForm.subtractDepreciation[key] === true) {
        total += getReturnOrderItem(key).totalDepreciation.amount;
      }
    });

    return total;
  });

  return {
    valueOfDepreciatedItems,
  };
}
