<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  ColorPicker,
  Form,
  FormLayout,
  Modal,
  Textarea,
  TextInput,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';

defineProps<{ open: boolean }>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const form = useForm({
  name: '',
  description: '',
  color: 'slate' as Types.App.Enums.ColorPalette,
});

function handleClose() {
  emit('close');

  form.reset();
}

function createStatus() {
  form.post(route('panel.settings.general.tags.store'), {
    onSuccess: () => handleClose(),
  });
}
</script>

<template>
  <Modal
    :open="open"
    @close="handleClose"
  >
    <Form
      id="create-form"
      @submit="createStatus"
    >
      <FormLayout>
        <FormLayout>
          <ColorPicker
            v-model="form.color"
            :label="$t('panel.settings.general_settings.tags.create_modal.tag_color_label')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.name"
            name="name"
            :label="$t('panel.settings:general:create:name')"
            :error="form.errors.name"
          />
        </FormLayout>

        <FormLayout>
          <Textarea
            v-model="form.description"
            name="description"
            :label="$t('panel.settings:general:create:description')"
            :error="form.errors.description"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="handleClose">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :disabled="! form.isDirty"
        :loading="form.processing"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="create-form"
      >
        {{ $t('panel.settings:general:create:save') }}
      </Button>
    </template>
  </Modal>
</template>
