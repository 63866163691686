<script lang="ts" setup>
import { Badge, BadgeRounded, Checkbox } from '@app/panel/Components';

defineProps<{
  modelValue: string[];
  value: string;
  title: string;
  description?: string;
  isPopular: boolean;
}>();

const emit = defineEmits<{
  'update:model-value': [string[]];
}>();

function onModelValueUpdate(value: string[]): void {
  emit('update:model-value', value);
}
</script>

<template>
  <div class="flex items-start space-x-2">
    <div class="flex flex-col">
      <Checkbox
        :model-value="modelValue"
        :value="value"
        @update:model-value="onModelValueUpdate"
      >
        <span class="font-medium mr-2">{{ title }}</span>
        <Badge
          v-if="isPopular"
          color="pink"
          :rounded="BadgeRounded.Full"
        >
          {{ $t('panel.dashboard:onboarding:steps:popular') }}
        </Badge>
      </Checkbox>
      <span
        v-if="description"
        class="text-sm text-slate-500 mt-1 pl-6"
      >
        {{ description }}
      </span>
    </div>
  </div>
</template>
