<script lang="ts" setup>
import {
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogCancelButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@returnless/focus-ui';
import { router } from '@inertiajs/vue3';

const props = defineProps<{
  open: boolean;
  shippingInstruction: string;
}>();

const emit = defineEmits<{
  close: [];
}>();

function abortDialog() {
  emit('close');
}

function removeShippingInstruction() {
  router.delete(route('panel.settings.shipping-instructions.delete', props.shippingInstruction));
  abortDialog();
}

</script>

<template>
  <AlertDialog
    :open="props.open"
    variant="destructive"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ $t('panel.settings:templates:shipping-instructions:edit:delete-title') }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ $t('panel.settings:templates:shipping-instructions:edit:delete-body') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancelButton @click="abortDialog">
          {{ $t('panel.settings:templates:shipping-instructions:edit:delete-cancel') }}
        </AlertDialogCancelButton>
        <AlertDialogActionButton
          variant="destructive"
          @click="removeShippingInstruction"
        >
          {{
            $t('panel.settings:templates:shipping-instructions:edit:delete-button')
          }}
        </AlertDialogActionButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
