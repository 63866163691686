<script lang="ts" setup>
import { computed, provide } from 'vue';
import {
  Button,
  ButtonVariant,
  CardSection,
  Link,
  Page,
  PageActions,
  PageActionsPosition,
  ResourceItem,
  ResourceList,
  UnsupportedFeature,
} from '@app/panel/Components';
import { Card, CardDescription, CardHeader, CardTitle } from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../partials';
import type { Types } from '@app/shared/types/generated-v2';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { router } from '@inertiajs/vue3';
import { StatusBadge } from '@app/panel/types';
import { useI18n } from 'vue-i18n';
import { CardLayout } from '@returnless/focus-ui';
import FeatureLimitWarning from '@app/panel/Pages/Settings/Components/FeatureLimitWarning.vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type ListFormAutomationsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ListFormAutomationsViewModel'];
type FormAutomationViewModel = Types['App.Models.ViewModels.FormAutomationViewModel'];

const props = defineProps<ListFormAutomationsViewModel>();

const { t } = useI18n();

provide('form', props.form);

const canShowAutomations = computed(() => {
  return props.featureUsageInfo.isAvailable || props.featureUsageInfo.usage > 0;
});

function goToAutomationsCreatePage(): void {
  router.get(route('panel.settings.forms.automations.create', props.form));
}

function edit(formAutomation: FormAutomationViewModel): void {
  router.get(route('panel.settings.forms.automations.edit', {
    form: props.form,
    formAutomation: formAutomation,
  }));
}

function getStatusBadges(automation: FormAutomationViewModel): StatusBadge[] {
  const badges = [];

  if (automation.broken) {
    badges.push({
      label: t('panel.settings:form:automations:broken'),
      color: 'red',
    });
  }

  badges.push({
    label: automation.isEnabled
      ? t('panel.settings:form:automations:enabled')
      : t('panel.settings:form:automations:disabled'),
    color: automation.isEnabled ? 'green' : 'orange',
  });

  return badges;
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:form:automations:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <PageActions
        v-if="canShowAutomations"
        :position="PageActionsPosition.End"
      >
        <div>
          <div class="flex items-center gap-4">
            <Link
              v-if="hasFailedFormAutomation"
              :href="$route('panel.settings.forms.failed-automations', form.id)"
            >
              {{ $t('panel.settings:form:automations:view-failed') }}
            </Link>

            <Button
              :variant="ButtonVariant.Primary"
              @click="goToAutomationsCreatePage"
            >
              <template #icon>
                <PlusIcon class="size-5" />
              </template>

              <span>{{ $t('panel.settings:form:automations:create') }}</span>
            </Button>
          </div>
        </div>
      </PageActions>

      <CardLayout>
        <FeatureLimitWarning :feature-usage-info="featureUsageInfo" />

        <div v-if="canShowAutomations">
          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.automations.automations')">
                {{ $t('panel.settings:form:automations:title') }}
              </CardTitle>
              <CardDescription>{{ $t('panel.settings:form:automations:subtitle') }}</CardDescription>
            </CardHeader>
            <CardSection>
              <ResourceList
                v-if="formAutomations.length > 0"
                :draggable="false"
              >
                <ResourceItem
                  v-for="(automation, index) in formAutomations"
                  :id="index"
                  :key="automation.id"
                  :label="automation.name"
                  :tags="automation.tags"
                  :badges="getStatusBadges(automation)"
                  @click="edit(automation)"
                />
              </ResourceList>
              <p
                v-else
                class="text-center text-sm text-slate-700"
              >
                {{ $t('panel.settings:form:automations:no-data') }}
              </p>
            </CardSection>
          </Card>
        </div>

        <div
          v-if="!canShowAutomations"
        >
          <UnsupportedFeature
            :feature="$t('panel.subscriptions:features:automations')"
            :available-in="[$t('panel.subscriptions:plans:integrate')]"
          />
        </div>
      </CardLayout>
    </Page>
  </AppLayout>
</template>
