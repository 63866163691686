<script lang="ts" setup>
import { inject, ref } from 'vue';
import {
  ButtonType,
  Card,
  CardSection,
  ErrorMessage,
  Form,
  FormLayout,
  Heading,
  InputLabel,
  Stack,
  StackItem,
  StackSpacing,
  Textarea,
  TextInput,
  TextInputFieldTypes,
  ToastVariant,
} from '@app/panel/Components';
import { FileUpload, UploadedFile } from '@app/panel/Containers';
import { router } from '@inertiajs/vue3';
import { useUpload } from '@app/panel/Composables/useUpload';
import { useToast } from '@app/panel/Composables/useToast';
import { App, type Types } from '@app/shared/types/generated-v2';
import { useFileManager } from '@app/panel/Composables/useFileManager';
import { isEmpty } from 'lodash';
import { useOnboarding } from '../../composables';
import { FormPreview } from '@app/panel/Containers';
import { watch } from 'vue';

type FormLocaleViewModel = Types['App.Models.ViewModels.FormLocaleViewModel'];

const props = defineProps<{
  formLocale: FormLocaleViewModel;
  formLocaleFallback: FormLocaleViewModel | null;
}>();

const { setLastCompletedStep, changeStep } = useOnboarding();

const stateType: App.Enums.OnboardingStateType = App.Enums.OnboardingStateType.HasReturnForm;

const uploading = ref(false);
const errors = ref(null);
const brandColor = ref(props.formLocale.brandColor ?? '#2b2bd1');
const title = ref(props.formLocale.title);
const introText = ref(props.formLocale.introText);

const attachmentFileSizeLimit = inject<number>('attachmentFileSizeLimit');
const attachmentFileTypes = inject<string[]>('attachmentFileTypes');

const {
  addFiles: addLogoImageFiles,
  error: logoImageError,
  removeFile: removeLogoImageFile,
  setError: setLogoImageError,
  uploadedFiles: uploadedLogoImageFiles,
} = useFileManager(1, attachmentFileSizeLimit, attachmentFileTypes);

const {
  addFiles: addCoverImageFiles,
  error: coverImageError,
  removeFile: removeCoverImageFile,
  setError: setCoverImageError,
  uploadedFiles: uploadedCoverImageFiles,
} = useFileManager(1, attachmentFileSizeLimit, attachmentFileTypes);

function uploadLogoImageToVapor(): Promise<unknown> {
  const { upload } = useUpload();

  const logoImage = uploadedLogoImageFiles.value[0];

  return upload(logoImage.file, (progress) => logoImage.progress = progress)
    .then((response) => ({ id: 'logo', ...response }))
    .catch((error) => setLogoImageError(error));
}

function uploadCoverImageToVapor(): Promise<unknown> {
  const { upload } = useUpload();

  const coverImage = uploadedCoverImageFiles.value[0];

  return upload(coverImage.file, (progress) => coverImage.progress = progress)
    .then((response) => ({ id: 'cover', ...response }))
    .catch((error) => setCoverImageError(error));
}

const { emitToastEvent } = useToast();

function prepareRequestData(response) {
  const logoResponse = response.find((item) => item.id === 'logo');
  const coverResponse = response.find((item) => item.id === 'cover');

  return Object.assign({
    brand_color: brandColor.value,
    title: title.value,
    intro_text: introText.value,
  },
  logoResponse && { logo_src: logoResponse },
  coverResponse && { cover_photo_src: coverResponse },
  );
}

async function uploadFilesToVapor() {
  const promises = [];

  if (!isEmpty(uploadedLogoImageFiles.value)) {
    promises.push(uploadLogoImageToVapor);
  }

  if (!isEmpty(uploadedCoverImageFiles.value)) {
    promises.push(uploadCoverImageToVapor);
  }

  return await Promise.all(promises.map((f) => f()))
    .then((response) => prepareRequestData(response))
    .catch((error) => {
      emitToastEvent({ message: error, variant: ToastVariant.Critical });
    });
}

function handleError(error: object): void {
  errors.value = error;
  changeStep(stateType);
}

function createReturnForm(): void {
  uploadFilesToVapor()
    .then((response) => {
      router.post(route('panel.onboarding.return-form'),
        response,
        {
          preserveState: true,
          onStart: () => uploading.value = true,
          onSuccess: () => setLastCompletedStep(stateType),
          onError: handleError,
          onFinish: () => uploading.value = false,
        });
    });
}

const cover_photo_src = ref(props.formLocale.coverPhotoSource);

watch(() => uploadedCoverImageFiles.value, (newValue) => {
  if (isEmpty(newValue)) {
    return;
  }

  const coverImageFile = uploadedCoverImageFiles.value[0].file;

  if (!coverImageFile) {
    cover_photo_src.value = props.formLocale.logoSource;
  } else {
    cover_photo_src.value = URL.createObjectURL(coverImageFile);
  }
}, { deep: true });

const logo_image_src = ref(props.formLocale.logoSource);

watch(() => uploadedLogoImageFiles.value, (newValue) => {
  if (isEmpty(newValue)) {
    return;
  }

  const coverImageFile = uploadedLogoImageFiles.value[0].file;

  if (!coverImageFile) {
    logo_image_src.value = props.formLocale.logoSource;
  } else {
    logo_image_src.value = URL.createObjectURL(coverImageFile);
  }
}, { deep: true });
</script>

<template>
  <Stack
    vertical
    :spacing="StackSpacing.ExtraLoose"
  >
    <StackItem>
      <Form @submit="createReturnForm">
        <Card
          :primary-footer-action="{
            content: $t('panel.dashboard.return_form.primary_card_action'),
            type: ButtonType.Submit,
            loading: uploading,
          }"
        >
          <CardSection>
            <FormLayout>
              <FormLayout>
                <TextInput
                  v-model="title"
                  :label="$t('panel.forms:layout:title-input')"
                  :error="errors?.title"
                />
              </FormLayout>
              <FormLayout>
                <Textarea
                  v-model="introText"
                  :label="$t('panel.forms:layout:intro-text')"
                  :rows="3"
                  :error="errors?.intro_text"
                />
              </FormLayout>
              <FormLayout class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div class="flex items-start justify-self-stretch">
                  <div class="flex-1 text-center">
                    <InputLabel :label="$t('panel.dashboard.return_form.brand_color_input_label')" />
                    <TextInput
                      v-model="brandColor"
                      :type="TextInputFieldTypes.Color"
                      :error="errors?.brand_color"
                    />
                  </div>
                </div>
                <div>
                  <InputLabel :label="$t('panel.dashboard.return_form.logo_upload_label')" />
                  <div class="space-y-2">
                    <FileUpload
                      :accept="attachmentFileTypes"
                      :error="!! logoImageError"
                      @upload="addLogoImageFiles"
                    />
                    <UploadedFile
                      v-for="logoImageFile in uploadedLogoImageFiles"
                      :key="logoImageFile.id"
                      :name="logoImageFile.name"
                      :progress="logoImageFile.progress"
                      @remove="() => removeLogoImageFile(logoImageFile.id)"
                    />
                    <ErrorMessage
                      v-if="logoImageError"
                      :message="logoImageError"
                    />
                  </div>
                </div>
                <div>
                  <InputLabel :label="$t('panel.dashboard.return_form.cover_image_label')" />
                  <div class="space-y-2">
                    <FileUpload
                      :accept="attachmentFileTypes"
                      :error="!! logoImageError"
                      @upload="addCoverImageFiles"
                    />
                    <UploadedFile
                      v-for="coverImageFile in uploadedCoverImageFiles"
                      :key="coverImageFile.id"
                      :name="coverImageFile.name"
                      :progress="coverImageFile.progress"
                      @remove="() => removeCoverImageFile(coverImageFile.id)"
                    />
                    <ErrorMessage
                      v-if="coverImageError"
                      :message="coverImageError"
                    />
                  </div>
                </div>
              </FormLayout>
            </FormLayout>
          </CardSection>
        </Card>
      </Form>
    </StackItem>
    <StackItem>
      <div class="mb-1">
        <Heading>{{ $t('panel.dashboard.return_form.form_preview_title') }}</Heading>
      </div>
      <FormPreview
        v-if="formLocale && formLocaleFallback"
        :color="brandColor"
        :cover="cover_photo_src"
        :logo="logo_image_src"
        :title="title"
        :intro-text="introText"
        :form-locale-fallback="formLocaleFallback"
      />
    </StackItem>
  </Stack>
</template>
