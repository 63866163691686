import {
  readonly,
  ref,
} from 'vue';

export function useMultiStep() {
  const firstStep = ref(1);
  const lastStep = ref(1);
  const currentStep = ref(firstStep.value);

  function setFirstStep(value: number) {
    firstStep.value = value;
  }

  function setLastStep(value: number) {
    lastStep.value = value;
  }

  function nextStep(): void {
    if (currentStep.value < lastStep.value) {
      currentStep.value++;
    }
  }

  function previousStep(): void {
    if (currentStep.value > firstStep.value) {
      currentStep.value--;
    }
  }

  function setCurrentStep(step: number): void {
    currentStep.value = step;
  }

  return {
    currentStep: readonly(currentStep),
    firstStep: readonly(firstStep),
    lastStep: readonly(lastStep),
    nextStep,
    previousStep,
    setCurrentStep,
    setFirstStep,
    setLastStep,
  };
}
