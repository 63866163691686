import { readonly, ref, watch } from 'vue';
import { UploadedFile } from './UploadedFile';
import { first } from 'lodash';

export function useFileManager(amountLimit: number, fileSizeLimit: number, allowedFileTypes: string[]) {
  const error = ref<string | null>(null);

  const uploadedFiles = ref<UploadedFile[]>([]);

  function exists(id: string): boolean {
    return uploadedFiles.value.some((file) => file.id === id);
  }

  function isTypeAllowed(type: string): boolean {
    return allowedFileTypes.includes(type);
  }

  function isUnderAmountLimit(): boolean {
    return uploadedFiles.value.length < amountLimit;
  }

  function isUnderFileSizeLimit(fileSize: number): boolean {
    return fileSize < fileSizeLimit;
  }

  function clearErrors(): void {
    error.value = null;
  }

  function addFiles(files: File[]): void {
    files.forEach((file) => {
      const uploadedFile = new UploadedFile(file);

      if (exists(uploadedFile.id)) {
        return;
      }

      if (!isUnderAmountLimit() && amountLimit === 1) {
        clearFiles();

        addFiles([first(files)]);

        return;
      }

      if (!isUnderAmountLimit()) {
        error.value = 'Allowed number of files is exceeded.';
        return;
      }

      if (!isTypeAllowed(uploadedFile.type)) {
        error.value = `File type for ${uploadedFile.name} is not allowed.`;
        return;
      }

      if (!isUnderFileSizeLimit(uploadedFile.size)) {
        error.value = `File ${uploadedFile.name} is too large.`;
        return;
      }

      uploadedFiles.value.push(uploadedFile);
    });
  }

  function removeFile(id: string): void {
    uploadedFiles.value = uploadedFiles.value.filter((file) => file.id !== id);
  }

  function setError(message: string): void {
    error.value = message;
  }

  function clearFiles(): void {
    uploadedFiles.value = [];
  }

  watch(() => uploadedFiles.value.length, () => clearErrors());

  return {
    addFiles,
    clearFiles,
    error: readonly(error),
    setError,
    uploadedFiles,
    removeFile,
  };
}
