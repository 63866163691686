<script lang="ts" setup generic="T">
import { Checkbox } from '../../Checkbox';
import { TextContainer } from '../../TextContainer';
import { TextStyle, TextStyleShade } from '../../TextStyle';
import {
  get,
  isEmpty,
  isEqual,
  omit,
  xorWith,
} from 'lodash';
import TableCell from './TableCell.vue';
import { Heading, SortOrder } from '../types';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

const props = withDefaults(defineProps<{
  headings: Heading[];
  items: T[];
  id?: string | null;
  disabledCheckboxes?: boolean;
  rounded?: boolean;
  draggable?: boolean;
  checkable?: boolean;
  clickable?: boolean;
  modelValue?: any[];
  headingsVisible?: boolean;
  actionsText?: string | null;
  emptyStateText?: string | null;
  sortBy?: string;
}>(), {
  id: null,
  disabledCheckboxes: false,
  rounded: true,
  draggable: false,
  checkable: false,
  clickable: false,
  modelValue: [] as any[],
  headingsVisible: true,
  actionsText: null,
  emptyStateText: null,
  sortBy: '',
});

const classList = computed(() => {
  return [
    { 'rounded-md border': props.rounded },
  ];
});

let row;

function getSortedItems(target: HTMLElement) {
  const tableBodyElement = target.closest('tbody');
  const rows = Array.from(tableBodyElement.children);

  let sortedItems = [...props.items];

  props.items.forEach((item, index) => {
    const newIndex = rows.findIndex((row) => {
      return parseInt(row.id) === index;
    });

    sortedItems.splice(newIndex, 1, item);
  });

  return sortedItems;
}

function onDragstart(event: Event) {
  row = event.target;
}

function onDragenter(event: Event) {
  const target = event.target as HTMLElement;
  const parentRowElement = target.parentNode as HTMLTableRowElement;

  let children = Array.from(parentRowElement.parentNode.children);

  if (children.indexOf(parentRowElement) > children.indexOf(row)) {
    if (!row.contains(target.parentNode)) {
      parentRowElement.after(row);
    }
  } else {
    parentRowElement.before(row);
  }
}

const emit = defineEmits([
  'change',
  'rowClick',
  'sorted',
  'update:modelValue',
]);

function onDrop(event) {
  const sortedItems = getSortedItems(event.target);

  emit('change', sortedItems);
}

const isAllChecked = computed(() => {
  return isEmpty(xorWith(props.items, props.modelValue, isEqual));
});

function onCheckboxChange(value) {
  emit('update:modelValue', value);
}

function checkAll() {
  emit('update:modelValue', [...props.items]);
}

function uncheckAll() {
  emit('update:modelValue', []);
}

const computedItems = computed(() => {
  return props.items.map((item) => {
    return {
      rowId: useUniqueId('table-row'),
      ...item,
    };
  });
});

function handleRowClick(item: unknown, index: number, event: Event): void {
  emit('rowClick', item, index, event);
}

const sortByColumn = computed<string | null>(() => {
  const heading = props.headings.find((heading) => {
    return props.sortBy === heading.column || props.sortBy === `-${heading.column}`;
  });

  return heading?.column ?? null;
});

const sortOrder = computed<SortOrder | null>(() => {
  if (!props.sortBy) {
    return null;
  }

  return props.sortBy.includes('-')
    ? SortOrder.Desc
    : SortOrder.Asc;
});

function toggleSortOrder(heading: Heading): string | null {
  if (!sortOrder.value) {
    return heading.column;
  }

  if (sortOrder.value === SortOrder.Asc) {
    return `-${heading.column}`;
  }

  if (sortOrder.value === SortOrder.Desc) {
    return null;
  }
}

function handleSort(heading: Heading): void {
  const toggledSortBy = toggleSortOrder(heading);
  const sortByColumnChanged = sortByColumn.value !== heading.column;

  if (sortByColumnChanged) {
    emit('sorted', heading.column);
  }

  if (!sortByColumnChanged && !toggledSortBy) {
    emit('sorted', null);
  }

  if (!sortByColumnChanged && toggledSortBy) {
    emit('sorted', toggledSortBy);
  }
}
</script>

<template>
  <div
    data-test="data-table-wrapper"
    class="overflow-x-auto border-slate-300 bg-white"
    :class="classList"
  >
    <table
      :id="id"
      class="min-w-full"
    >
      <thead
        v-if="headingsVisible"
        class="bg-slate-50 border-b"
      >
        <tr>
          <TableCell
            v-if="checkable"
            header
            width="10"
          >
            <div class="flex justify-center pl-3">
              <Checkbox
                :model-value="isAllChecked"
                :disabled="disabledCheckboxes"
                @update:model-value="(isChecked) => isChecked ?
                  checkAll() :
                  uncheckAll()
                "
              />
            </div>
          </TableCell>

          <TableCell
            v-for="(heading, headingIndex) in headings"
            :key="headingIndex"
            header
          >
            <div
              class="group flex items-center space-x-1 group"
              :class="{'cursor-pointer hover:text-slate-700': heading.sortable}"
              v-on="heading.sortable ? { click: () => handleSort(heading) } : {}"
            >
              <span class="whitespace-nowrap">
                {{ heading['text'] }}
              </span>

              <div
                v-if="heading.sortable"
                class="flex items-center space-x-1"
              >
                <ArrowUpIcon
                  v-if="sortOrder === SortOrder.Asc || sortByColumn !== heading.column"
                  class="w-3 h-3"
                  :class="{ 'invisible group-hover:visible': sortByColumn !== heading.column}"
                />
                <ArrowDownIcon
                  v-if="sortByColumn === heading.column && sortOrder === SortOrder.Desc"
                  class="w-3 h-3"
                />
              </div>
            </div>
          </TableCell>

          <TableCell
            v-if="!!$slots.actions"
            data-test="data-table-actions-header"
            class="text-right"
            header
          >
            {{ actionsText || $t('panel.global:data-table:actions') }}
          </TableCell>
        </tr>
      </thead>

      <tbody
        v-if="items.length > 0"
        class="divide-y divide-slate-200"
      >
        <tr
          v-for="item in computedItems"
          :id="item.id"
          :key="item.rowId"
          :draggable="draggable"
          :class="{ 'hover:bg-slate-50 cursor-pointer': clickable }"
          @dragstart="(e) => onDragstart && onDragstart(e)"
          @dragenter="(e) => draggable && onDragenter(e)"
          @dragend="(e) => draggable && onDrop(e)"
        >
          <TableCell v-if="checkable">
            <div class="flex justify-center pl-3">
              <Checkbox
                :model-value="modelValue"
                :disabled="disabledCheckboxes"
                :value="omit(item, 'rowId')"
                @update:model-value="(value) => onCheckboxChange(value)"
              />
            </div>
          </TableCell>

          <TableCell
            v-for="(heading, headingIndex) in headings"
            :key="headingIndex"
            :no-wrap="heading.noWrap"
            @click="(e) => handleRowClick(item, itemIndex, e)"
          >
            <slot
              :name="heading.value"
              :item="item"
            >
              <TextContainer>
                <TextStyle :shade="TextStyleShade.Subdued">
                  {{ get(item, heading.value) }}
                </TextStyle>
              </TextContainer>
            </slot>
          </TableCell>

          <TableCell
            v-if="!!$slots.actions"
            data-test="data-table-actions"
            class="flex justify-end"
          >
            <slot
              name="actions"
              :item="item"
            />
          </TableCell>
        </tr>
      </tbody>

      <tbody
        v-if="items.length === 0"
      >
        <tr>
          <TableCell :colspan="headings.length + 1">
            <TextContainer text-center>
              <TextStyle :shade="TextStyleShade.Subdued">
                {{ emptyStateText || $t('panel.global:data-table:empty-data') }}
              </TextStyle>
            </TextContainer>
          </TableCell>
        </tr>
      </tbody>
    </table>
  </div>
</template>
