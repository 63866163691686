<script lang="ts" setup>
import { computed } from 'vue';
import {
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  Select,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  preferences: Types['App.Http.ViewModels.Panel.Account.PreferencesViewModel']['preferences'];
  platformIntegrations: Types['App.Http.ViewModels.Panel.Account.PreferencesViewModel']['platformIntegrationsForBarcodeRedirect'];
}>();

const barcodePreferencesForm = useForm({
  barcode_redirect_to: props.preferences.barcodeRedirectTo || null,
});

const updateBarcodePreferences = () => {
  barcodePreferencesForm.put(route('panel.account.preferences.update'), {
    preserveScroll: true,
  });
};

const platformIntegrationsOptions = computed(() => {
  return Object.entries(props.platformIntegrations).map(([value, label]) => {
    return { value, label };
  });
});
</script>

<template>
  <Form @submit="updateBarcodePreferences">
    <Card
      class="overflow-visible"
      :title="$t('panel.account:preferences:barcode:card_title')"
      :primary-footer-action="{
        content: $t('panel.account:preferences:barcode:save_button'),
        type: ButtonType.Submit,
        loading: barcodePreferencesForm.processing,
        disabled: !barcodePreferencesForm.isDirty,
      }"
    >
      <CardSection>
        <FormLayout>
          <Select
            v-model="barcodePreferencesForm.barcode_redirect_to"
            placeholder="Select..."
            :label="$t('panel.account:preferences:barcode:redirect_to_field')"
            :help-text="$t('panel.account:preferences:barcode:redirect_to_help_text')"
            :options="platformIntegrationsOptions"
          />
        </FormLayout>
      </CardSection>
    </Card>
  </Form>
</template>
