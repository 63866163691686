<script lang="ts" setup>
import { inject, PropType, ref } from 'vue';
import { FormLayout, Select, Toggle } from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { InertiaForm } from '@inertiajs/vue3';
import { type ReturnQuestionForm } from '@app/panel/types';
import { ClearConditionsModal } from '@app/panel/Containers';
import { isEmpty } from 'lodash';

const { t } = useI18n();

const props = defineProps({
  availableFieldTypes: {
    type: Array as PropType<string[]>,
    required: true,
  },
});

const returnQuestionForm = inject<InertiaForm<ReturnQuestionForm>>('returnForm');

const questionLevelOptions = [
  { value: 'order', label: t('panel.forms:return-questions:level:return') },
  { value: 'order-item', label: t('panel.forms:return-questions:level:return-item') },
];

const inputTypeOptions = props.availableFieldTypes.map((fieldType: string) => {
  return { value: fieldType, label: t(`panel.forms:return-questions:input-types:${fieldType}`) };
});

const currentLevel = ref<string | null>(null);

function updateLevel(level: string): void {
  if (!isEmpty(returnQuestionForm.conditions)) {
    currentLevel.value = level;
    openClearConditionsModal();
    return;
  }

  returnQuestionForm.level = level;
}

const isClearConditionsModalOpen = ref<boolean>(false);

function openClearConditionsModal(): void {
  isClearConditionsModalOpen.value = true;
}

function closeClearConditionsModal(): void {
  isClearConditionsModalOpen.value = false;
}

function proceedLevelChange(): void {
  returnQuestionForm.conditions = {};
  returnQuestionForm.level = currentLevel.value;
  currentLevel.value = null;
  closeClearConditionsModal();
}
</script>

<template>
  <div class="mb-6 space-y-3">
    <Toggle
      v-model="returnQuestionForm.is_enabled"
      :title="$t('panel.settings.forms.list_forms.settings.return_questions.create.enable')"
    />

    <Toggle
      v-model="returnQuestionForm.is_answer_required"
      :title="$t('panel.settings.forms.list_forms.settings.return_questions.create.require_answer')"
    />
  </div>

  <FormLayout
    :columns="1"
    :columns-sm="2"
  >
    <FormLayout>
      <Select
        :model-value="returnQuestionForm.level"
        :label="$t('panel.settings.forms.list_forms.settings.return_questions.create.question_level_label')"
        :options="questionLevelOptions"
        :error="returnQuestionForm.errors.level"
        @update:model-value="updateLevel"
      />
    </FormLayout>

    <FormLayout>
      <Select
        v-model="returnQuestionForm.field_type"
        :label="$t('panel.settings.forms.list_forms.settings.return_questions.create.input_type_label')"
        :options="inputTypeOptions"
        :error="returnQuestionForm.errors.field_type"
      />
    </FormLayout>
  </FormLayout>

  <ClearConditionsModal
    :open="isClearConditionsModalOpen"
    @close="closeClearConditionsModal"
    @proceed="proceedLevelChange"
  />
</template>
