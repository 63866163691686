<script lang="ts" setup>
import { computed, useAttrs } from 'vue';

const attrs = useAttrs();

const props = withDefaults(defineProps<{
  clickable?: boolean;
}>(), {
  clickable: false,
});

const classList = computed(() => {
  return {
    'cursor-pointer hover:bg-slate-100': attrs.onClick instanceof Function || props.clickable,
  };
});
</script>

<template>
  <li class="first:pt-0 last:pb-0 py-2">
    <div
      class="p-2 rounded-md"
      :class="classList"
    >
      <slot />
    </div>
  </li>
</template>
