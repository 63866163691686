<script setup lang="ts">
import { DateInput } from '@app/panel/Components';
import { computed, ref, watch } from 'vue';
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = withDefaults(defineProps<{
  initFrom: string | null;
  initTo: string | null;
  minimalDate?: string | null;
}>(), {
  minimalDate: null,
});

const from = ref(props.initFrom ? props.initFrom : dayjs().subtract(1, 'month').format('YYYY-MM-DD'));
const to = ref(props.initTo ? props.initTo : dayjs().format('YYYY-MM-DD'));

const emit = defineEmits<{
  (e: 'updated', from: string, to: string);
}>();

function dateRangeIsValid(): boolean {
  return !dayjs(from.value).isAfter(dayjs(to.value));
}

const fromError = computed(() => dateRangeIsValid() ? null : t('panel.analytics:global:date-range-error', { from: from.value, to: to.value }));

watch([from, to], () => {
  if (dateRangeIsValid()) {
    emit('updated', from.value, to.value);
  }
});
</script>

<template>
  <div class="flex space-x-3">
    <DateInput
      v-model="from"
      :label="$t('panel.analytics:global:date-range-from')"
      :error="fromError"
      :show-tooltip-error="true"
      :min="minimalDate"
    />

    <DateInput
      v-model="to"
      :label="$t('panel.analytics:global:date-range-to')"
      :min="minimalDate"
    />
  </div>
</template>
