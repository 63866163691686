<script setup lang="ts">
import { AppLayout } from '@app/panel/Layouts';
import {
  Alert,
  Button,
  ButtonContent,
  ButtonIcon,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardSection,
  CardTitle,
  Form,
  FormLayout,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
  Select,
  SelectOption,
  Separator,
  Toggle,
} from '@returnless/focus-ui';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/vue/24/solid';
import { useForm } from '@inertiajs/vue3';
import { DowngradeFeatureViewModel, FormDowngradePageViewModel } from './types';
import { computed } from 'vue';
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';

const props = defineProps<FormDowngradePageViewModel>();

const form = useForm({
  features: props.downgradeData,
});

function getCurrentUsage(feature: string): number {
  const featureValue = form.features[feature];

  return Object.keys(featureValue).filter((item: string) => form.features[feature][item]).length;
}

function isWithinLimit(downgrade: DowngradeFeatureViewModel): boolean {
  if (downgrade.keepOneItem) {
    return getCurrentUsage(downgrade.featureIdentifier) === 1;
  }

  return downgrade.limit === null || getCurrentUsage(downgrade.featureIdentifier) <= downgrade.limit;
}

const hasIncompleteDowngradeFeature = computed(() => {
  return props.downgrades.some((downgrade: DowngradeFeatureViewModel) => {
    return !isWithinLimit(downgrade);
  });
});

function selectFeatureItem(featureIdentifier: string, selectedItemId: string): void {
  Object.keys(form.features[featureIdentifier]).forEach((itemId: string) => {
    form.features[featureIdentifier][itemId] = itemId === selectedItemId;
  });
}

function onSubmit() {
  // The controller will handle the correct redirection (so preserve state should be false)
  form.put(route('panel.account.downgrade.form.save', props.formIdentifier), {
    preserveScroll: false,
    preserveState: false,
  });
}
</script>

<template>
  <AppLayout>
    <Page size="compact">
      <div class="flex space-x-4">
        <div class="flex size-10 shrink-0 items-center justify-center rounded-md bg-brand-500 text-white sm:size-12">
          <DocumentDuplicateIcon class="h-6 text-white" />
        </div>

        <PageHeader>
          <PageTitle>{{ $t('panel.account:downgrade:form:title') }} <span class="italic">{{ formName }}</span></PageTitle>
          <PageDescription>
            {{ $t('panel.account:downgrade:form:description', {
              planLabel: planLabel,
            }) }}
          </PageDescription>
        </PageHeader>
      </div>

      <Card>
        <Form>
          <div
            v-for="(downgrade, index) in downgrades"
            :key="downgrade.featureIdentifier"
          >
            <Separator v-if="index > 0" />

            <div
              class="flex items-start justify-between"
            >
              <CardHeader>
                <CardTitle>{{ downgrade.featureLabel }}</CardTitle>
                <CardDescription>
                  {{ downgrade.description }}
                </CardDescription>
              </CardHeader>
              <CardTitle class="pr-6 pt-6">
                <span
                  v-if="!isWithinLimit(downgrade)"
                  class="flex items-end"
                >
                  <span class="text-red-600">{{ getCurrentUsage(downgrade.featureIdentifier) }}</span>
                  <span class="text-base font-medium text-slate-400">&nbsp;/ {{ downgrade.limit }}</span>
                </span>

                <CheckCircleIcon
                  v-else
                  class="size-6 fill-emerald-500"
                />
              </CardTitle>
            </div>
            <CardSection class="w-full">
              <FormLayout>
                <Alert
                  v-if="$page.props.errors[`features.${downgrade.featureIdentifier}`]"
                  variant="destructive"
                >
                  {{ $page.props.errors[`features.${downgrade.featureIdentifier}`] }}
                </Alert>

                <template v-if="downgrade.keepOneItem">
                  <Select
                    label-hidden
                    :placeholder="$t('panel.account:downgrade:global:select-one', { feature: downgrade.featureLabel })"
                    @update:model-value="(value) => selectFeatureItem(downgrade.featureIdentifier, value)"
                  >
                    <SelectOption
                      v-for="featureItem in downgrade.featureItems"
                      :key="featureItem.id"
                      :value="featureItem.id"
                    >
                      {{ featureItem.name }}
                    </SelectOption>
                  </Select>
                </template>

                <template v-if="!downgrade.keepOneItem">
                  <Toggle
                    v-for="featureItem in downgrade.featureItems"
                    :key="featureItem.id"
                    v-model="form.features[downgrade.featureIdentifier][featureItem.id]"
                    :label="featureItem.name"
                    :help-text="featureItem.description"
                    :disabled="downgrade.limit === 0 || featureItem.isForcefullyDisabled"
                  />
                </template>
              </FormLayout>
            </CardSection>
          </div>
        </Form>

        <CardFooter>
          <Button
            :disabled="hasIncompleteDowngradeFeature"
            @click="onSubmit"
          >
            <ButtonContent>{{ $t('panel.account:downgrade:form:buttons:save-and-continue') }}</ButtonContent>
            <ButtonIcon :icon="ArrowRightIcon" />
          </Button>
        </CardFooter>
      </Card>
    </Page>
  </AppLayout>
</template>
