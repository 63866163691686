<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Checkbox,
  Form,
  FormLayout,
  Modal,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import Textarea from '@app/customer/Components/Textarea/components/Textarea.vue';
import { onMounted } from 'vue';

const props = defineProps<{
  open: boolean;
  events: string[];
  signingSecret: string;
  testMode: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

function handleClose(): void {
  emit('close');

  form.reset();
}

const form = useForm({
  endpoint: '',
  is_enabled: true,
  test_mode: false,
  description: '',
  events: [],
});

onMounted(() => {
  form.test_mode = props.testMode;
});

function create(): void {
  form.post(route('panel.settings.integrations.webhooks.store'), {
    onSuccess: () => handleClose(),
  });
}
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.settings:integrations:webhooks')"
    @close="handleClose"
  >
    <Form
      id="createWebhookSubscriptionForm"
      @submit="create"
    >
      <FormLayout>
        <Toggle
          v-model="form.is_enabled"
          :title="$t('panel.settings:integrations:webhooks:is_enabled')"
        />

        <Toggle
          v-model="form.test_mode"
          :title="$t('panel.settings:integrations:webhooks:test_mode')"
        />

        <TextInput
          v-model="form.endpoint"
          :label="$t('panel.settings:integrations:webhooks:endpoint')"
          :error="form.errors.endpoint"
          placeholder="https://"
        />

        <TextInput
          :value="signingSecret"
          disabled
          allow-copy
          :label="$t('panel.settings:integrations:webhooks:webhook_signing_secret')"
        />

        <Textarea
          v-model="form.description"
          :label="$t('panel.settings:integrations:webhooks:description')"
          :error="form.errors.description"
          :placeholder="$t('panel.settings:integrations:webhooks:description:placeholder')"
        />

        <div class="space-y-2">
          <Checkbox
            v-for="(event, key) in events"
            :key="key"
            v-model="form.events"
            :value="event"
            :label="event"
          />
        </div>
      </FormLayout>
    </Form>

    <template #actions>
      <ButtonGroup>
        <Button @click="handleClose">
          {{ $t('panel.settings:integrations:webhooks:cancel') }}
        </Button>

        <Button
          :loading="form.processing"
          :variant="ButtonVariant.Primary"
          form="createWebhookSubscriptionForm"
          :type="ButtonType.Submit"
        >
          {{ $t('panel.settings:integrations:webhooks:create-subscription') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
