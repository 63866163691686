<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  vertical: {
    type: Boolean,
    default: false,
  },
});

const classList = computed(() => {
  return [
    'border-slate-200',
    { 'mx-4 border-l h-full w-[1px]': props.vertical },
    { 'my-4 border-t h-[1px] w-full': !props.vertical },
  ];
});
</script>

<template>
  <div>
    <hr :class="classList">
  </div>
</template>
