<script lang="ts">
import { computed, defineComponent } from 'vue';
import { InputLabel } from '../../InputLabel';
import { useUniqueId } from '../../../Composables/useUniqueId';

export default defineComponent({
  components: { InputLabel },

  props: {
    modelValue: {
      type: String,
      default: null,
    },

    value: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const elementId = computed(() => props.id || useUniqueId('searchField'));

    const checked = computed(() => {
      return props.modelValue === props.value;
    });

    const onRadioButtonChange = (): void => {
      if (!props.disabled) {
        emit('update:modelValue', props.value);
      }
    };

    return {
      checked,
      elementId,
      onRadioButtonChange,
    };
  },
});
</script>

<template>
  <div
    data-test="radio-button"
    class="w-fit cursor-pointer flex items-center space-x-2 text-sm"
    @click="onRadioButtonChange"
  >
    <input
      :id="elementId"
      type="radio"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      class="cursor-pointer border-slate-300 focus:ring-slate-900 mb-1 accent-brand-500"
    >

    <InputLabel
      :label="label"
      :label-for="elementId"
    />
  </div>
</template>
