<script lang="ts" setup>
import {
  TextContainer,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  TextStyleTracking,
  TextStyleTransform,
  TimelineItem,
} from '@app/panel/Components';
import { CheckIcon } from '@heroicons/vue/24/outline';

defineProps({
  label: {
    type: String,
    required: true,
  },

  description: {
    type: String,
    default: null,
  },

  date: {
    type: String,
    required: false,
    default: null,
  },

  completed: {
    type: Boolean,
    default: false,
  },

  checked: {
    type: Boolean,
    default: false,
  },

  clickable: {
    type: Boolean,
    default: false,
  },

  lastItem: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'check'): void;
}>();

function onCheckableTimelineItemCheck(): void {
  emit('check');
}
</script>

<template>
  <div>
    <TimelineItem
      :date="date"
      :last-item="lastItem"
      :icon-ring-color="checked ? 'brand' : null"
      :separation-line-color="completed ? 'brand' : 'gray'"
    >
      <template #icon>
        <div
          class="w-full h-full"
          :class="clickable ? 'cursor-pointer' : ''"
          @click="clickable ? onCheckableTimelineItemCheck() : ''"
        >
          <div
            v-if="$slots['default']"
            class="bg-white w-full h-full flex items-center rounded-full justify-center"
          >
            <slot />
          </div>
          <div
            v-else-if="checked"
            class="bg-white w-full h-full flex items-center rounded-full justify-center"
          >
            <span class="h-3 w-3 bg-brand-500 rounded-full" />
          </div>

          <div
            v-else-if="completed"
            class="bg-brand-500 w-full h-full flex items-center rounded-full justify-center"
          >
            <CheckIcon class="h-5 w-5 text-white" />
          </div>

          <div
            v-else
            class="bg-white w-full h-full flex items-center rounded-full justify-center"
          >
            <div class="w-full h-full rounded-full ring-2 bg-transparent flex justify-center items-center ring-slate-400">
              <span class="h-3 w-3 rounded-full bg-slate-300" />
            </div>
          </div>
        </div>
      </template>

      <div class="flex flex-col">
        <TextContainer>
          <TextStyle
            :font-size="TextStyleFontSize.Small"
            :color="checked ? 'brand' : 'slate'"
            :tracking="TextStyleTracking.Wide"
            :shade="completed && !checked ? TextStyleShade.Dense : TextStyleShade.Subdued"
            :transform="TextStyleTransform.Uppercase"
            semi-bold
          >
            {{ label }}
          </TextStyle>
        </TextContainer>

        <TextContainer v-if="description">
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ description }}
          </TextStyle>
        </TextContainer>
      </div>
    </TimelineItem>
  </div>
</template>
