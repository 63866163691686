<script setup lang="ts">
import { TextStyle } from '../TextStyle';
import { Button, ButtonVariant } from '../Button';
import { Heading, HeadingSize } from '../Heading';
import { NoSymbolIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';

defineProps<{
  feature: string;
  availableIn: Array<string>;
}>();

function upgrade() {
  router.visit(route('panel.account.billing'));
}
</script>

<template>
  <div
    class="flex min-h-80 items-center justify-center p-4"
  >
    <div class="flex flex-col items-center space-y-2">
      <NoSymbolIcon class="size-12 text-slate-400" />

      <div class="max-w-[26rem] space-y-2 text-center">
        <Heading :size="HeadingSize.Small">
          {{ $t('panel.subscriptions:features:unsupported:title', { feature: feature }) }}
        </Heading>
        <TextStyle
          font-size="small"
          class="block whitespace-pre-line leading-normal"
        >
          {{ $t('panel.subscriptions:features:unsupported:description', { available_in: availableIn.join(' / ') }) }}
        </TextStyle>
      </div>
      <div class="pt-4">
        <Button
          :variant="ButtonVariant.Primary"
          size="small"
          @click="upgrade"
        >
          <template #icon>
            <PlusIcon class="size-5" />
          </template>

          {{ $t('panel.subscriptions:features:unsupported:buttons:view-upgrade-options') }}
        </Button>
      </div>
    </div>
  </div>
</template>
