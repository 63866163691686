<script lang="ts">
import {
  defineComponent,
  h,
  VNode,
} from 'vue';
import { theme } from '../../../Composables/useTheme';

export default defineComponent({
  setup(_, { slots }) {
    return () => h('div', { class: 'flex justify-around border-t border-slate-200 divide-x' }, (slots.default()[0].children as VNode[]).map((el: VNode) => {
      return h(el, { class: `flex flex-1 justify-center p-4 ${theme('focus-outline-color')}` });
    }));
  },
});
</script>
