<script lang="ts" setup>
import { CardSection, Form, FormLayout, Select, Stack, StackItem, StackSpacing, Toggle } from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import * as Types from '@app/panel/types/generated';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

type FormShippingSettingsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel;

const props = defineProps<{
  form: FormShippingSettingsViewModel['form'];
}>();

const multicolloForm = useForm({
  multicollo: props.form.multicollo,
  free_shipping_for_multicollo: props.form.freeShippingForMulticollo,
});

function saveMultiCollo() {
  multicolloForm.put(route('panel.settings.forms.shipping-settings.multicollo.update', { form: props.form }));
}

function multiColloOptions() {
  const items = [...Array(props.form.additionalShipments + 1).keys(), -1];

  return items.map((amount) => {
    let translationKey = 'panel.settings.forms.list_forms.settings.shipping_settings.collo.option.some';

    if (amount === 0) {
      translationKey = 'panel.settings.forms.list_forms.settings.shipping_settings.collo.option.none';
    }

    if (amount === 1) {
      translationKey = 'panel.settings.forms.list_forms.settings.shipping_settings.collo.option.one';
    }

    if (amount === -1) {
      translationKey = 'panel.settings.forms.list_forms.settings.shipping_settings.collo.option.unlimited';
    }

    return {
      label: t(translationKey, { amount }),
      value: amount,
    };
  });
}

</script>

<template>
  <Form @submit="saveMultiCollo">
    <Stack
      vertical
      :spacing="StackSpacing.ExtraLoose"
    >
      <StackItem>
        <Card>
          <CardHeader>
            <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.multicollo')">
              {{ $t('panel.settings.forms.list_forms.settings.shipping_settings.multicollo') }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <FormLayout>
                <Select
                  v-model="multicolloForm.multicollo"
                  :options="multiColloOptions()"
                  :error="multicolloForm.errors.multicollo"
                  :label="$t('panel.settings.forms.list_forms.settings.shipping_settings.collo.title')"
                />
              </FormLayout>
              <FormLayout>
                <Toggle
                  v-model="multicolloForm.free_shipping_for_multicollo"
                  :title="$t('panel.settings.forms.list_forms.settings.shipping_settings.free_shipping')"
                />
              </FormLayout>
            </FormLayout>
          </CardSection>
          <CardFooter>
            <Button
              type="submit"
              :loading="multicolloForm.processing"
              :disabled="!multicolloForm.isDirty"
            >
              {{ $t('panel.settings.forms.list_forms.settings.shipping_settings.save_form') }}
            </Button>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  </Form>
</template>
