<script lang="ts" setup>
import { provide } from 'vue';
import { Card } from '../../Card';

const props = defineProps({
  id: {
    type: String,
    default: null,
  },

  draggable: {
    type: Boolean,
    default: true,
  },
});

provide('draggable', props.draggable);
</script>

<template>
  <Card>
    <table
      :id="id"
      class="w-full rounded-lg overflow-hidden"
    >
      <tbody class="divide-y">
        <slot />
      </tbody>
    </table>
  </Card>
</template>
