<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import {
  Badge,
  Button,
  ButtonContent,
  ButtonGroup,
  ButtonIcon,
  Card,
  CardDescription,
  CardHeader,
  CardLayout,
  CardMedia,
  CardSection,
  CardTitle,
  Page,
  PageBody,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { PlusIcon } from '@heroicons/vue/16/solid';
import { ListFormsViewModel } from './types';
import { useI18n } from 'vue-i18n';
import { Color } from '@app/panel/Components';

const { t } = useI18n();

defineProps<ListFormsViewModel>();
</script>

<template>
  <AppLayout :title="t('panel.settings.forms.list_forms.head_title')">
    <Page>
      <PageHeader>
        <PageTitle>{{ t('panel.settings.forms.list_forms.page_title') }}</PageTitle>
        <PageDescription>
          {{ t('panel.settings.forms.list_forms.page_description') }}
        </PageDescription>
        <template #page-header-actions>
          <ButtonGroup>
            <Button :href="$route('panel.settings.forms.create')">
              <ButtonIcon :icon="PlusIcon" />
              <ButtonContent>{{ t('panel.settings.forms.list_forms.add_return_form_cta') }}</ButtonContent>
            </Button>
          </ButtonGroup>
        </template>
      </PageHeader>

      <PageBody>
        <CardLayout :columns="3">
          <Card
            v-for="form in forms"
            :key="form.id"
          >
            <CardMedia
              alt="Form cover photo"
              :ratio="16 / 6"
              :source="form.coverPhotoSrc ?? ''"
              :subdued="!form.isEnabled"
            >
              <div class="flex gap-2">
                <Badge
                  v-if="form.isTestModeEnabled"
                  :color="Color.Yellow"
                >
                  {{ t('panel.global:test-mode') }}
                </Badge>
                <Badge
                  v-if="!form.isEnabled"
                  :color="Color.Red"
                >
                  {{ t('panel.global:disabled') }}
                </Badge>
                <Badge
                  v-if="form.isVirtual"
                  :color="Color.Blue"
                >
                  {{ form.name }}
                </Badge>
              </div>
            </CardMedia>
            <CardHeader>
              <CardTitle>{{ form.name }}</CardTitle>
              <CardDescription>{{ form.description }}</CardDescription>
            </CardHeader>
            <CardSection>
              <ButtonGroup>
                <Button
                  variant="secondary"
                  :href="$route('panel.settings.forms.general', form.id)"
                >
                  {{ t('panel.settings.forms.list_forms.settings_button') }}
                </Button>
                <Button
                  :href="$route('panel.form-preview-url', form.id)"
                  native
                  target="_blank"
                  variant="outline"
                >
                  {{ t('panel.settings.forms.list_forms.preview_button') }}
                </Button>
              </ButtonGroup>
            </CardSection>
          </Card>
        </CardLayout>
      </PageBody>
    </Page>
  </AppLayout>
</template>
