<script lang="ts" setup>
import { Badge } from '@app/panel/Components';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { BillingSubscriptionToggleItem } from '../types';
import { Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  id?: string;
  ariaLabel?: string;
  modelValue: number | string;
  billingCycleOptions: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['billingCycleOptions'];
}>();

const emit = defineEmits<{
  (e: 'update:model-value', modelValue: string | number): void;
}>();

const elementId = props.id || useUniqueId('billing-subscription-toggle');

function isChecked(item: BillingSubscriptionToggleItem): boolean {
  return props.modelValue === item.value;
}

function updateModelValue(item: BillingSubscriptionToggleItem): void {
  emit('update:model-value', item.value);
}
</script>

<template>
  <div
    class="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-sm font-semibold leading-5 ring-1 ring-inset ring-slate-200 billingCycleOptions-center"
    role="radiogroup"
    :aria-labelledby="elementId"
  >
    <label
      :id="elementId"
      class="sr-only"
    >
      {{ ariaLabel || 'Billing subscription toggle' }}
    </label>

    <div
      v-for="(billingCycleOption, index) in billingCycleOptions"
      :key="index"
      class="text-slate-500 cursor-pointer rounded-full px-2.5 py-1"
      :class="{ 'bg-brand-500 text-white': isChecked(billingCycleOption) }"
      role="radio"
      :aria-checked="isChecked(billingCycleOption)"
      tabindex="0"
      @click="updateModelValue(billingCycleOption)"
      @keyup.enter="updateModelValue(billingCycleOption)"
    >
      <div class="flex billingCycleOptions-center justify-center space-x-2">
        <span class>{{ billingCycleOption.label }}</span>
        <Badge
          v-if="billingCycleOption.badge"
          :color="billingCycleOption.badge.color"
        >
          <span class="whitespace-nowrap">
            {{ billingCycleOption.badge.label }}
          </span>
        </Badge>
      </div>
    </div>
  </div>
</template>
