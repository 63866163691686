<script lang="ts" setup>
import { Stack, StackItem, StackSpacing } from '@app/panel/Components';
import {
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogCancelButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  ButtonGroup,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardLayout,
  CardSection,
  CardTitle,
  Checkbox,
  CheckboxGroup,
  FormLayout,
  Page,
  TextField,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { router, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import { ParameterList } from '@app/panel/Containers';
import { hasFeature } from '@app/panel/Utils';
import { PagePropsViewModel } from './types';

const props = defineProps<PagePropsViewModel>();

const form = useForm({
  title: props.noteTemplate.title,
  description: props.noteTemplate.description,
  is_enabled_on_requests: props.noteTemplate.isEnabledOnRequests,
  is_enabled_on_returns: props.noteTemplate.isEnabledOnReturns,
  is_enabled_on_payment_requests: props.noteTemplate.isEnabledOnPaymentRequests,
});

const alertDialogOpen = ref(false);

function getLocaleById(localeId: string) {
  return props.locales.find((l) => l.id == localeId);
}

function getContentFromLocaleId(localeId: string) {
  return props.noteTemplate.noteTemplateLocales.find((ntl) => ntl.locale.id === localeId)?.content ?? '';
}

const noteTemplateLocaleForms = props.locales.map((locale) => {
  return useForm({
    locale_id: locale.id,
    content: getContentFromLocaleId(locale.id),
  });
});

function update() {
  form.put(route('panel.settings.note-templates.update', props.noteTemplate), {
    preserveScroll: true,
  });
}

function updateTemplateLocaleForm(localeId: string) {
  const noteTemplateLocaleForm = noteTemplateLocaleForms.find((f) => f.locale_id === localeId);
  noteTemplateLocaleForm.put(route('panel.settings.note-template.locale.update', props.noteTemplate), {
    preserveScroll: true,
  });
}

function removeNoteTemplate() {
  router.delete(route('panel.settings.note-templates.delete', props.noteTemplate));
}

</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:note-templates:edit:head')">
    <Page :navigation="PageNavigation">
      <CardLayout>
        <Card>
          <CardHeader>
            <CardTitle>{{ $t('panel.settings:templates:note-templates:edit:title') }}</CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <FormLayout>
                <CheckboxGroup :label="$t('panel.settings:templates:note-templates:edit:enable-label')">
                  <Checkbox
                    v-model="form.is_enabled_on_requests"
                    :label="$t('panel.settings:templates:note-templates:requests')"
                    name="enabled_on_requests"
                    value="1"
                  />

                  <Checkbox
                    v-model="form.is_enabled_on_returns"
                    :label="$t('panel.settings:templates:note-templates:returns')"
                    name="enabled_on_returns"
                    value="1"
                  />

                  <Checkbox
                    v-if="hasFeature('PaymentRequests')"
                    v-model="form.is_enabled_on_payment_requests"
                    :label="$t('panel.settings:templates:note-templates:payment-requests')"
                    name="enabled_on_payment_requests"
                    value="1"
                  />
                </CheckboxGroup>
              </FormLayout>

              <FormLayout>
                <TextField
                  v-model="form.title"
                  :error="form.errors.title"
                  :help-text="$t('panel.settings:templates:note-templates:edit:title-subtitle')"
                  :label="$t('panel.settings:templates:note-templates:edit:title-label')"
                  max-length="80"
                />
              </FormLayout>

              <FormLayout>
                <TextField
                  v-model="form.description"
                  :error="form.errors.description"
                  :help-text="$t('panel.settings:templates:note-templates:edit:description-subtitle')"
                  :label="$t('panel.settings:templates:note-templates:edit:description-label')"
                  max-length="280"
                  multiline
                  rows="2"
                />
              </FormLayout>
            </FormLayout>
          </CardSection>
          <CardFooter>
            <ButtonGroup>
              <Button
                variant="destructive"
                @click="alertDialogOpen = true"
              >
                {{ $t('panel.settings:templates:note-templates:edit:secondary-footer-action') }}
              </Button>

              <Button
                variant="primary"
                :loading="form.processing"
                @click="update"
              >
                {{ $t('panel.settings:templates:note-templates:edit:primary-footer-action') }}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>

        <Card
          v-for="(noteTemplateLocaleForm, index) in noteTemplateLocaleForms"
          :key="index"
        >
          <CardHeader>
            <CardTitle>
              {{ `${noteTemplate.title} (${getLocaleById(noteTemplateLocaleForm.locale_id).name})` }}
            </CardTitle>
            <CardDescription>
              {{
                $t('panel.settings:templates:note-templates:edit:locale-subtitle')
              }}
            </CardDescription>
          </CardHeader>
          <CardSection>
            <ParameterList :parameters="parameters" />

            <Stack
              :spacing="StackSpacing.ExtraLoose"
              vertical
            >
              <StackItem>
                <FormLayout>
                  <FormLayout>
                    <TextField
                      v-model="noteTemplateLocaleForm.content"
                      :error="noteTemplateLocaleForm.errors.content"
                      multiline
                      rows="8"
                      max-length="2500"
                    />
                  </FormLayout>
                </FormLayout>
              </StackItem>
            </Stack>
          </CardSection>
          <CardFooter>
            <Button
              :disabled="!noteTemplateLocaleForm.isDirty"
              :loading="noteTemplateLocaleForm.processing"
              variant="primary"
              @click="updateTemplateLocaleForm(noteTemplateLocaleForm.locale_id)"
            >
              {{ $t('panel.global:actions:save') }}
            </Button>
          </CardFooter>
        </Card>
      </CardLayout>
    </Page>
  </AppLayout>

  <AlertDialog
    :open="alertDialogOpen"
    variant="destructive"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ $t('panel.settings:templates:note-templates:edit:delete-title') }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ $t('panel.settings:templates:note-templates:edit:delete-body') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancelButton @click="alertDialogOpen = false">
          {{ $t('panel.settings:templates:note-templates:edit:delete-cancel') }}
        </AlertDialogCancelButton>
        <AlertDialogActionButton
          variant="destructive"
          @click="removeNoteTemplate"
        >
          {{
            $t('panel.settings:templates:note-templates:edit:delete-button')
          }}
        </AlertDialogActionButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
