<script lang="ts" setup>
import { provide, ref, toRef } from 'vue';
import { ColorPalette } from '@app/panel/Components';
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Badge,
  Button,
  ButtonContent,
  ButtonIcon,
  DataList,
  DataListContent,
  DataListItem,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
  StatusIndicator,
} from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import { AppLayout } from '@app/panel/Layouts';
import { router } from '@inertiajs/vue3';
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/vue/16/solid';
import { CreateRequestStatusModal, EditRequestStatusModal } from '../partials/RequestStatuses';
import { PageNavigation } from '../partials';
import { PagePropsViewModel, RequestStatusViewModel } from './types';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

const props = defineProps<PagePropsViewModel>();

const statusForm = useForm<{
  label: string | null;
  color: string;
  translations: Record<string, string>;
  is_default_status: boolean;
  visible_on_tracking_page: boolean;
  should_notify_user: boolean;
}>({
  label: null,
  color: ColorPalette[0],
  translations: {},
  is_default_status: false,
  visible_on_tracking_page: false,
  should_notify_user: true,
});

const currentEditingItem = ref<RequestStatusViewModel | null>(null);
const isCreateStatusModalOpen = ref(false);
const defaultLocale = ref(props.locales[0]);
const items = toRef(props, 'requestStatuses');

function resetStatusForm(): void {
  statusForm.reset();
  statusForm.clearErrors();
}

function openCreateStatusModal(): void {
  isCreateStatusModalOpen.value = true;
}

function closeCreateStatusModal(): void {
  isCreateStatusModalOpen.value = false;

  resetStatusForm();
}

function closeEditStatusModal(): void {
  currentEditingItem.value = null;

  resetStatusForm();
}

function saveSortedItems(items: string[]): void {
  router.put(route('panel.settings.general.sort-request-status'), { items }, {
    preserveScroll: true,
    preserveState: false,
  });
}

function remove(status: RequestStatusViewModel): void {
  router.delete(route('panel.settings.general.remove-request-status', status.id));
}

function edit(status: RequestStatusViewModel): void {
  currentEditingItem.value = status;

  statusForm.label = status.label;
  statusForm.color = status.color;
  statusForm.translations = status.labelTranslations;
  statusForm.is_default_status = status.isDefaultStatus;
  statusForm.visible_on_tracking_page = status.visibleOnTrackingPage;
  statusForm.should_notify_user = status.shouldNotifyUser;
}

provide('statusForm', statusForm);
provide('defaultLocale', defaultLocale);
provide('locales', props.locales);
</script>

<template>
  <AppLayout :title="t('panel.settings.general_settings.request_statuses.head_title')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle :page-help="getHelpscoutArticleBeacon('general-settings.request-statuses.request-statuses')">
          {{ t('panel.settings.general_settings.request_statuses.title') }}
        </PageTitle>
        <PageDescription>{{ t('panel.settings.general_settings.request_statuses.subtitle') }}</PageDescription>
        <template #page-header-actions>
          <Button @click="openCreateStatusModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ t('panel.settings.general_settings.request_statuses.add') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <DataList
        sortable
        @sort="saveSortedItems"
      >
        <DataListItem
          v-for="item in items"
          :id="item.id"
          :key="item.id"
        >
          <DataListContent>
            <Badge :color="item.color">
              {{ item.label }}
            </Badge>
          </DataListContent>
          <StatusIndicator
            v-if="item.isDefaultStatus"
            color="blue"
            size="small"
          >
            {{ t('panel.settings.general_settings.request_statuses.data_table.default') }}
          </StatusIndicator>
          <ActionList alignment="end">
            <ActionListTrigger>
              <Button
                icon-only
                variant="ghost"
                size="small"
              >
                <ButtonIcon :icon="EllipsisHorizontalIcon" />
              </Button>
            </ActionListTrigger>
            <ActionListBody>
              <ActionListSection>
                <ActionListItem @click="edit(item)">
                  {{ t('panel.settings.general_settings.request_statuses.data_table.edit_button') }}
                </ActionListItem>
                <ActionListItem
                  variant="destructive"
                  @click="remove(item)"
                >
                  {{ t('panel.settings.general_settings.request_statuses.data_table.remove_button') }}
                </ActionListItem>
              </ActionListSection>
            </ActionListBody>
          </ActionList>
        </DataListItem>
      </DataList>
    </Page>
  </AppLayout>

  <CreateRequestStatusModal
    :open="isCreateStatusModalOpen"
    @close="closeCreateStatusModal"
  />

  <EditRequestStatusModal
    :current="currentEditingItem"
    @close="closeEditStatusModal"
  />
</template>
