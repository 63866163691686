<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardLayout,
  CardSection,
  CardTitle,
  FormLayout,
  Page,
  TextField,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { router, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import { LocaleViewModel, PagePropsViewModel, ReturnInstructionLocaleViewModel } from './types';
import DeleteReturnInstructionDialog from '../Components/DeleteReturnInstructionDialog.vue';
import { useConfirm } from '@app/panel/Composables/useConfirm';
import { useI18n } from 'vue-i18n';

const { confirm } = useConfirm();

const { t } = useI18n();

const props = defineProps<PagePropsViewModel>();

const form = useForm({
  name: props.returnInstruction.name,
});

const isDeleteDialogOpen = ref(false);
const selectedReturnInstructionForRemoval = ref('');

function openDeleteModal(returnInstructionId: string) {
  selectedReturnInstructionForRemoval.value = returnInstructionId;
  isDeleteDialogOpen.value = true;
}

function getLocaleById(localeId: string) {
  return props.locales.find((locale: LocaleViewModel) => locale.id == localeId);
}

function getFormLocaleData(localeId: string) {
  return props.returnInstruction.returnInstructionLocales.find(
    (returnInstructionLocale: ReturnInstructionLocaleViewModel) => returnInstructionLocale.locale.id === localeId,
  );
}

const returnInstructionLocaleForms = props.locales.map((locale: LocaleViewModel) => {
  const returnInstructionLocale = getFormLocaleData(locale.id);

  return useForm({
    id: returnInstructionLocale?.id || null,
    locale_id: locale.id,
    title: returnInstructionLocale?.title,
    content: returnInstructionLocale?.content,
  });
});

function update() {
  form.put(route('panel.settings.return-instructions.update', props.returnInstruction), {
    preserveScroll: true,
  });
}

function updateTemplateLocaleForm(localeId: string) {
  const shippingInstructionLocaleForm = returnInstructionLocaleForms.find(
    (returnInstructionLocaleForm) => returnInstructionLocaleForm.locale_id === localeId,
  );

  shippingInstructionLocaleForm.put(route('panel.settings.return-instructions.locale.update', props.returnInstruction), {
    preserveScroll: true,
    preserveState: false,
  });
}

function removeCustomLocale(localeId: string): void {
  confirm(
    () => {
      router.delete(route('panel.settings.return-instructions.locale.delete', {
        returnInstruction: props.returnInstruction.id,
        locale: localeId,
      }), {
        preserveScroll: true,
        preserveState: false,
      });
    },
    t('panel.settings:templates:return-instructions:edit:restore-default-confirmation'),
  );
}

function shouldShowRestoreToDefault(localeId: string): boolean {
  const currentReturnInstructionLocale = props.returnInstruction.returnInstructionLocales.find(
    (returnInstructionLocale: ReturnInstructionLocaleViewModel) => returnInstructionLocale.locale.id === localeId,
  );

  return props.returnInstruction.isDefault && currentReturnInstructionLocale?.id !== null;
}

</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:return-instructions:edit')">
    <Page :navigation="PageNavigation">
      <CardLayout>
        <Card v-if="!props.returnInstruction.isDefault">
          <CardHeader>
            <CardTitle>{{ $t('panel.settings:templates:return-instructions:general-settings') }}</CardTitle>
          </CardHeader>

          <CardSection>
            <FormLayout>
              <TextField
                v-model="form.name"
                :label="$t('panel.settings:templates:return-instructions:label')"
                :help-text="$t('panel.settings:templates:return-instructions:internal-use')"
                :error="form.errors.name"
                max-length="80"
              />
            </FormLayout>
          </CardSection>
          <CardFooter>
            <ButtonGroup>
              <Button
                variant="destructive"
                @click="openDeleteModal(props.returnInstruction.id)"
              >
                {{ $t('panel.settings:templates:return-instructions:remove') }}
              </Button>

              <Button
                variant="primary"
                @click="update"
              >
                {{ $t('panel.settings:templates:return-instructions:update') }}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>

        <Card
          v-for="(returnInstructionLocaleForm, index) in returnInstructionLocaleForms"
          :key="index"
        >
          <CardHeader>
            <CardTitle>
              {{ `${props.returnInstruction.name} (${getLocaleById(returnInstructionLocaleForm.locale_id).name})` }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <TextField
                v-model="returnInstructionLocaleForm.title"
                :label="$t('panel.settings:templates:return-instructions:edit:title')"
                :error="returnInstructionLocaleForm.errors.title"
                max-length="80"
              />
              <TextField
                v-model="returnInstructionLocaleForm.content"
                :label="$t('panel.settings:templates:return-instructions:edit:description')"
                :error="returnInstructionLocaleForm.errors.content"
                multiline
                rows="3"
                max-length="2500"
              />
            </FormLayout>
          </CardSection>
          <CardFooter>
            <ButtonGroup>
              <Button
                v-if="shouldShowRestoreToDefault(returnInstructionLocaleForm.locale_id)"
                variant="secondary"
                @click="removeCustomLocale(returnInstructionLocaleForm.locale_id)"
              >
                {{ $t('panel.settings:templates:return-instructions:edit:restore-default') }}
              </Button>
              <Button
                :disabled="!returnInstructionLocaleForm.isDirty"
                :loading="returnInstructionLocaleForm.processing"
                variant="primary"
                @click="updateTemplateLocaleForm(returnInstructionLocaleForm.locale_id)"
              >
                {{ $t('panel.global:actions:save') }}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </CardLayout>
    </page>
  </AppLayout>

  <DeleteReturnInstructionDialog
    :open="isDeleteDialogOpen"
    :return-instruction="selectedReturnInstructionForRemoval"
    @close="isDeleteDialogOpen = false"
  />
</template>
