<script lang="ts" setup>
import { computed } from 'vue';
import Note from './Note.vue';
import NoteForm from './NoteForm.vue';
import { type Types } from '@app/shared/types/generated-v2';

const props = withDefaults(defineProps<{
  returnOrder: Types['App.Models.ViewModels.ReturnOrderViewModel'];
  noteTemplates?: Array<Types['App.Models.ViewModels.NoteTemplateViewModel']>;
  notes: Types['App.Models.ViewModels.NoteViewModel'][];
  standalone?: boolean;
  allowVisibleForCustomers?: boolean;
}>(), {
  standalone: false,
  allowVisibleForCustomers: false,
  noteTemplates: () => [],
});

const emit = defineEmits<{
  (e: 'submit', form: any): void;
}>();

function submit(form: any) {
  emit('submit', form);
}

const notesClassList = computed(() => {
  if (props.standalone) {
    return [
      'p-6',
    ];
  }
  return 'mb-4';
});

const hasNotes = computed((): boolean => {
  return props.notes && props.notes.length > 0;
});

const formClassList = computed(() => {
  if (props.standalone) {
    return [
      'p-4',
      'border-t',
      'bg-slate-50',
      { 'mt-4': !hasNotes.value },
    ];
  }
  return '';
});
</script>

<template>
  <div>
    <div
      v-if="hasNotes"
      class="space-y-4"
      :class="notesClassList"
    >
      <Note
        v-for="note in notes"
        :key="note.id"
        :note="note"
      />
    </div>

    <div :class="formClassList">
      <NoteForm
        :return-order="returnOrder"
        :note-templates="noteTemplates"
        :allow-visible-for-customers="allowVisibleForCustomers"
        @submit="submit"
      />
    </div>
  </div>
</template>
