<script lang="ts" setup>
import { Component, computed, h, PropType, useSlots } from 'vue';

const props = defineProps({
  navigation: {
    type: Object as PropType<Component>,
    default: null,
  },
});

const slots = useSlots();

const hasNavigation = computed(() => props.navigation || !!slots['page-navigation']);

const mainClassList = computed(() => {
  return hasNavigation.value ? 'col-span-9' : 'col-span-12';
});

const PageNavigation = props.navigation
  ? h(props.navigation)
  : h('div');
</script>

<template>
  <main class="mx-auto max-w-8xl py-8 sm:px-6 lg:px-8">
    <div
      data-test="page-body"
      class="lg:grid lg:grid-cols-12 lg:gap-x-5"
    >
      <div
        v-if="hasNavigation"
        data-test="page-navigation"
        class="pb-6 pt-2 px-2 lg:col-span-3 lg:py-0 lg:px-0"
      >
        <PageNavigation />
        <slot name="page-navigation" />
      </div>

      <div
        data-test="page-content"
        :class="mainClassList"
      >
        <slot />
      </div>
    </div>
  </main>
</template>
