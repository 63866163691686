<script lang="ts" setup>
import {
  Head,
  useForm,
} from '@inertiajs/vue3';
import { GuestLayout } from '@app/panel/Layouts';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  Link,
  TextContainer,
  TextInput,
  TextInputAutocompleteOption,
  TextInputFieldTypes,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { asset } from 'laravel-vapor';

const form = useForm({
  name: '',
  website_url: '',
  email: '',
});

function submit() {
  form.post(route('express-register.store'));
}
</script>

<template>
  <Head>
    <title>{{ $t('panel.auth:express_register:title') }}</title>
  </Head>

  <GuestLayout
    :title="$t('panel.auth:express_register:title')"
    :hero-image="asset('img/registration-background.jpeg')"
  >
    <template #subtitle>
      <TextContainer>
        <h2 class="text-lg text-brand-500 font-medium">
          {{ $t('panel.auth:express_register:subtitle') }}
        </h2>
      </TextContainer>
    </template>
    <div>
      <Form @submit="submit">
        <FormLayout>
          <FormLayout>
            <TextInput
              v-model="form.name"
              :error="form.errors.name"
              :label="$t('panel.auth:express_register:name')"
              :placeholder="$t('panel.auth:express_register:name-placeholder')"
              autofocus
              name="name"
              required
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.website_url"
              :error="form.errors.website_url"
              :label="$t('panel.auth:express_register:website')"
              :placeholder="$t('panel.auth:express_register:website-placeholder')"
              name="website_url"
              required
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.email"
              :error="form.errors.email"
              :label="$t('panel.auth:express_register:email')"
              :placeholder="$t('panel.auth:express_register:email-placeholder')"
              :autocomplete="TextInputAutocompleteOption.Email"
              name="email"
              required
              :type="TextInputFieldTypes.Email"
            />
          </FormLayout>

          <FormLayout>
            <div class="flex flex-col">
              <TextContainer>
                <TextStyle :shade="TextStyleShade.Subdued">
                  <i18n-t
                    keypath="panel.auth:express_register:gdpr"
                    for="panel.auth:express_register:gdpr"
                  >
                    <template #term>
                      <Link
                        native
                        target="_blank"
                        href="https://docs.returnless.com/docs/legal-privacy-security/coe77kv1ku3ja-general-terms-and-conditions"
                      >
                        {{ $t('panel.auth:express_register:term') }}
                      </Link>
                    </template>
                    <template #data_processing>
                      <Link
                        native
                        target="_blank"
                        href="https://docs.returnless.com/docs/legal-privacy-security/73s61b7qwfl3i-data-processing-agreement"
                      >
                        {{ $t('panel.auth:express_register:data_processing') }}
                      </Link>
                    </template>
                  </i18n-t>
                </TextStyle>
              </TextContainer>
            </div>
          </FormLayout>

          <FormLayout>
            <Button
              full-width
              :type="ButtonType.Submit"
              :variant="ButtonVariant.Primary"
              :loading="form.processing"
            >
              {{ $t('panel.auth:express_register:primary-action') }}
            </Button>
          </FormLayout>
        </FormLayout>
      </Form>
    </div>
  </GuestLayout>
</template>
