<script lang="ts" setup>
import {
  Badge,
  Banner,
  BannerVariant,
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  DataTable,
  Form, FormLayout,
  InputLabel,
  Modal,
  TextInput,
} from '@app/panel/Components';
import { router, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import type { Types } from '@app/shared/types/generated-v2';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/vue/20/solid';
import TextStyle from '@app/panel/Components/TextStyle/components/TextStyle.vue';

type PartnerManagementViewModel = Types['App.Http.ViewModels.Panel.Account.PartnerManagementViewModel'];

const props = withDefaults(
  defineProps<{
    tenants: PartnerManagementViewModel['tenants'];
    invitations: PartnerManagementViewModel['invitations'];
    partnerVerificationCode?: PartnerManagementViewModel['partnerVerificationCode'];
    createAccountUrl: string;
  }>(), {
    invitations: () => ([]),
    partnerVerificationCode: null,
  },
);

const form = useForm({
  partner_verification_code: props.partnerVerificationCode,
});

function submitForm() {
  form.post(route('partner.initiate-access'));
}

const revokingAccess = ref(false);
const editItem = ref(false);
const currentItem = ref(null);

function revokeAccess(tenant): void {
  revokingAccess.value = true;
  currentItem.value = tenant;
}

function editTenant(tenant): void {
  editItem.value = true;
  currentItem.value = tenant;
}

function closeRevokeModal() {
  revokingAccess.value = false;
  currentItem.value = null;
}

function closeEditModal() {
  editItem.value = false;
  currentItem.value = null;
}

function updatePartnerTenant(item) {
  router.put(route('panel.account.partner-management.update-tenant', { tenant: item.id }), {
    po_number: item.poNumber,
  }, {
    onFinish: () => {
      closeEditModal();
    },
  });
}

function confirmRevokeAccess(item) {
  router.delete(route('panel.partner.revoke-access', { partner: item.id, type: 'partner' }), {
    onFinish: () => {
      closeRevokeModal();
    },
  });
}

function goToCreateAccountPage(): void {
  router.get(props.createAccountUrl);
}

function getPercentageChange(oldNumber: number, change: number): number {
  return Math.round((change / oldNumber) * 100);
}
</script>

<template>
  <Card
    :title="$t('panel.account:partner:partner-access:page_title')"
    :subtitle="$t('panel.account:partner:partner-access:page_subtitle_title')"
  >
    <CardSection>
      <Form @submit="submitForm">
        <InputLabel label="Attach a webshop using their partner code" />
        <div class="flex w-full space-x-4">
          <div class="flex-1">
            <TextInput
              v-model="form.partner_verification_code"
              :error="form.errors.partner_verification_code"
              required
            />
          </div>
          <div>
            <Button
              :type="ButtonType.Submit"
              :variant="ButtonVariant.Primary"
            >
              {{ $t('panel.account:partner:partner-access:attach_webshop:button') }}
            </Button>
            <span class="mx-2.5 text-xs text-slate-500">OR</span>
            <Button
              :type="ButtonType.Button"
              :variant="ButtonVariant.Default"
              @click="goToCreateAccountPage"
            >
              {{ $t('panel.account:partner:partner-access:create-account') }}
            </Button>
          </div>
        </div>
      </Form>
    </CardSection>
    <CardSection v-if="invitations.length > 0">
      <Banner
        v-for="invitation in invitations"
        :key="invitation.id"
        :variant="BannerVariant.Info"
      >
        {{ $t('panel.account:partner:partner-access:invite_pending', { name: invitation.tenant.name }) }}
      </Banner>
    </CardSection>
    <CardSection>
      <DataTable
        :items="tenants"
        :headings="[{
          text: $t('panel.account:partner:partner-access:table:headings:name'),
          value: 'name'
        }, {
          text: $t('panel.account:partner:partner-access:table:headings:recent-return-orders-count'),
          value: 'recentReturnOrderCount'
        }, {
          text: $t('panel.account:partner:partner-access:table:headings:po-number'),
          value: 'poNumber'
        }]"
      >
        <template #poNumber="{ item }">
          <TextStyle>
            {{ item.poNumber ?? '-' }}
          </TextStyle>
        </template>
        <template #recentReturnOrderCount="{ item }">
          <div class="text-sm font-medium flex items-center space-x-2">
            <span>{{ item.recentReturnOrderCount ?? 0 }}</span>

            <Badge
              v-if="item.differanceLastPeriod !== 0"
              :color="item.differanceLastPeriod > 0 ? 'green' : 'red'"
              :title="$t('panel.account:partner:partner-access:table:difference-last-period')"
            >
              <div class="flex items-center">
                <ArrowUpIcon
                  v-if="item.differanceLastPeriod > 0"
                  class="h-4 w-4 text-green-600"
                />
                <ArrowDownIcon
                  v-if="item.differanceLastPeriod < 0"
                  class="h-4 w-4 text-red-600"
                />

                <span>{{ getPercentageChange(item.recentReturnOrderCount, item.differanceLastPeriod) }}%</span>
              </div>
            </Badge>
          </div>
        </template>

        <template #actions="{ item }">
          <div class="flex space-x-3">
            <Button
              plain
              :variant="ButtonVariant.Primary"
              @click="editTenant(item)"
            >
              {{ $t('panel.account:partner:partner-access:table:edit:button_label') }}
            </Button>

            <Button
              plain
              :variant="ButtonVariant.Critical"
              @click="revokeAccess(item)"
            >
              {{ $t('panel.account:partner:partner-access:table:revoke:button_label') }}
            </Button>
          </div>
        </template>
      </DataTable>
    </CardSection>
  </Card>

  <Modal
    :open="revokingAccess"
    title="Revoke access"
    @close="closeRevokeModal"
  >
    {{ $t('panel.account:partner:partner-access:revoke-modal:info', { name: currentItem.name }) }}
    <template #actions>
      <Button @click="closeRevokeModal">
        {{ $t('panel.global:actions:cancel') }}
      </Button>
      <Button
        :variant="ButtonVariant.Critical"
        @click="confirmRevokeAccess(currentItem)"
      >
        {{ $t('panel.global:actions:save') }}
      </Button>
    </template>
  </Modal>

  <Modal
    :open="editItem"
    :title="$t('panel.account:partner:partner-access:edit-tenant-modal:title')"
    @close="closeEditModal"
  >
    <Form>
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="currentItem.name"
            :label="$t('panel.account:partner:partner-access:edit-tenant-modal:webshop-name')"
            disabled
          />
        </Formlayout>

        <FormLayout>
          <TextInput
            v-model="currentItem.poNumber"
            :label="$t('panel.account:partner:partner-access:edit-tenant-modal:po-number')"
          />
        </Formlayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="closeEditModal">
        {{ $t('panel.global:actions:cancel') }}
      </Button>
      <Button
        :variant="ButtonVariant.Primary"
        @click="updatePartnerTenant(currentItem)"
      >
        {{ $t('panel.global:actions:save') }}
      </Button>
    </template>
  </Modal>
</template>
