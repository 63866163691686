<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  Banner,
  CalloutCard,
  Card,
  CardSection,
  Modal,
  Stack,
  StackItem,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { PaymentMethod } from '@app/panel/types';
import { router } from '@inertiajs/vue3';
import { CreditCardIcon } from '@heroicons/vue/24/outline';
import { asset } from 'laravel-vapor';
import type { Types } from '@app/shared/types/generated-v2';
import AddPaymentMethodForm from '@app/panel/Pages/Account/partials/AddPaymentMethodForm.vue';
import UpdateBillingInvoiceDetailsForm from '@app/panel/Pages/Account/partials/UpdateBillingInvoiceDetailsForm.vue';

const props = defineProps<{
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  paymentMethods: Array<PaymentMethod>;
  countries: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['countries'];
}>();

const step = computed(() => props.tenant.isInvoiceDetailsComplete ? 2 : 1);
const paymentMethodModalOpen = ref(false);
const settingDefaultPaymentMethod = ref<string | null>(null);

function openPaymentMethodModal(): void {
  paymentMethodModalOpen.value = true;
}

function closePaymentMethodModal(): void {
  paymentMethodModalOpen.value = false;
}

function setDefaultPaymentMethod(method: PaymentMethod): void {
  router.put(route('panel.account.payment-methods.update'), {
    method: method.id,
  }, {
    preserveScroll: true,
    onStart: () => {
      settingDefaultPaymentMethod.value = method.id;
    },
    onFinish: () => {
      settingDefaultPaymentMethod.value = null;
    },
  });
}

</script>

<template>
  <Card :title="$t('panel.account.billing_and_subscription.payment_method.card_title')">
    <CardSection v-if="!tenant.isCustomer">
      <Banner variant="warning">
        {{ $t('panel.account.billing_and_subscription.payment_method.warning_banner') }}
      </Banner>
    </CardSection>

    <div v-if="tenant.isCustomer">
      <CardSection>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
          <div
            v-for="(method, methodKey) in paymentMethods"
            :key="methodKey"
          >
            <CalloutCard
              v-if="method.type === 'card'"
              :title="method.brand"
              :checked="method.isDefaultMethod"
              :loading="settingDefaultPaymentMethod === method.id"
              @click="setDefaultPaymentMethod(method)"
            >
              <Stack>
                <StackItem>
                  <img
                    class="h-6"
                    :src="asset('img/payment-methods/creditcard.svg')"
                    loading="lazy"
                  >
                </StackItem>

                <StackItem>
                  {{
                    $t('panel.account.billing_and_subscription.payment_method.credit_card_last4', { last4: method.last4 })
                  }}
                  <TextContainer>
                    {{
                      $t('panel.account.billing_and_subscription.payment_method.credit_card_expiration', { expiration: method.expiration })
                    }}
                  </TextContainer>
                </StackItem>
              </Stack>
            </CalloutCard>

            <CalloutCard
              v-if="method.type === 'sepa_debit'"
              :title="method.bankCode"
              :checked="method.isDefaultMethod"
              :loading="settingDefaultPaymentMethod === method.id"
              @click="setDefaultPaymentMethod(method)"
            >
              <Stack>
                <StackItem>
                  <img
                    class="h-6"
                    :src="asset('img/payment-methods/directdebit.svg')"
                    loading="lazy"
                  >
                </StackItem>

                <StackItem>
                  {{
                    $t('panel.account.billing_and_subscription.payment_method.credit_card_last4', { last4: method.last4 })
                  }}
                </StackItem>
              </Stack>
            </CalloutCard>
          </div>

          <div>
            <CalloutCard
              empty
              @click="openPaymentMethodModal"
            >
              <div class="flex justify-center items-center">
                <TextContainer>
                  <CreditCardIcon class="w-10 h-10 text-slate-300 mx-auto" />
                  <TextStyle
                    strong
                    :shade="TextStyleShade.Subdued"
                  >
                    {{ $t('panel.account.billing_and_subscription.payment_method.payment_method_cta') }}
                  </TextStyle>
                </TextContainer>
              </div>
            </CalloutCard>
          </div>
        </div>
      </CardSection>

      <Modal
        icon-color="blue"
        :title="step === 2 ? $t('panel.account.billing_and_subscription.payment_method.modal_title') : $t('panel.account:billing:payment-methods:update-invoice-details:title')"
        :open="paymentMethodModalOpen"
        @close="closePaymentMethodModal"
      >
        <template #icon>
          <CreditCardIcon class="h-6 w-6" />
        </template>

        <UpdateBillingInvoiceDetailsForm
          v-if="step === 1"
          :tenant="tenant"
          :countries="props.countries"
        />

        <AddPaymentMethodForm
          v-if="step === 2"
          :tenant="tenant"
          :after-successful-submit="closePaymentMethodModal"
        />
      </Modal>
    </div>
  </Card>
</template>
