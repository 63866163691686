<script lang="ts" setup>
import {
  ActionList,
  Button,
  ButtonVariant,
  Modal,
  PageActions,
  Popover,
  SplitButton,
  TextContainer,
} from '@app/panel/Components';
import { CheckCircleIcon, ChevronDownIcon, XCircleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { computed, inject, ref, Ref } from 'vue';
import * as Types from '@app/panel/types/generated';
import { useModal } from '@app/panel/Composables/useModal';
import { ButtonBorder, ButtonRounded } from '@app/panel/Components/Button';
import ApproveManualShipmentModal from '@app/panel/Pages/Returns/partials/ApproveManualShipmentModal.vue';

const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const requestStatuses = inject<Types.App.Models.ViewModels.RequestStatusViewModel[]>('requestStatuses');

const statusButton = ref<HTMLElement>(null);

const disableApprove = computed(() => returnOrder.value.state === 'request-approved');
const disableReject = computed(() => returnOrder.value.state === 'request-rejected');

const statusPopoverOpen = ref(false);
const statusItems = computed(() => {
  return requestStatuses.map((status) => {
    return {
      content: status.label,
      onClick: () => changeStatus(status),
      disabled: returnOrder.value.status.label === status.label,
      color: status.color,
    };
  });
});

const {
  isOpen: isDeleteModalOpen,
  close: closeDeleteModal,
  open: openDeleteModal,
} = useModal();

const {
  isOpen: isManualShipmentModalOpen,
  close: closeManualShipmentModal,
  open: openManualShipmentModal,
} = useModal();

function toggleStatusPopover(): void {
  statusPopoverOpen.value = !statusPopoverOpen.value;
}

function closeStatusPopover(): void {
  statusPopoverOpen.value = false;
}

function changeStatus(status: Types.App.Models.ViewModels.ReturnStatusViewModel) {
  router.put(route('panel.requests.update-status', returnOrder.value), { status: status.id });
  toggleStatusPopover();
}

function approveRequest(): void {
  router.put(route('panel.requests.approve', returnOrder.value));
}

function approveRequestWithoutShipment(): void {
  router.put(route('panel.requests.approve-without-shipment', returnOrder.value));
}

function rejectRequest(): void {
  router.put(route('panel.requests.reject', returnOrder.value));
}

function deleteOrder(): void {
  router.delete(route('panel.requests.delete', returnOrder.value));
}
</script>

<template>
  <PageActions>
    <div>
      <SplitButton
        data-test="approve"
        :disabled="disableApprove"
        :rounded="ButtonRounded.Left"
        @click="approveRequest"
      >
        <template #icon>
          <CheckCircleIcon class="h-5 text-green-600" />
        </template>
        {{ $t('panel.requests.detail_page_actions.approve') }}

        <template #dropdown>
          <Button
            data-test="approve-with-manual-shipment"
            :full-width="true"
            :align-left="true"
            :border="ButtonBorder.None"
            @click="openManualShipmentModal"
          >
            <template #icon>
              <CheckCircleIcon class="h-5 text-green-600" />
            </template>

            {{ $t('panel.requests.detail_page_actions.approve_with_manual_shipment') }}
          </Button>
        </template>
      </SplitButton>

      <Button
        data-test="approve-without-shipment"
        @click="approveRequestWithoutShipment"
      >
        <template #icon>
          <CheckCircleIcon class="h-5 text-green-600" />
        </template>

        {{ $t('panel.requests.detail_page_actions.approve_without_shipment') }}
      </Button>

      <Button
        data-test="reject"
        :disabled="disableReject"
        @click="rejectRequest"
      >
        <template #icon>
          <XCircleIcon class="h-5 text-red-600" />
        </template>

        {{ $t('panel.requests.detail_page_actions.reject') }}
      </Button>
    </div>

    <div>
      <Button
        :variant="ButtonVariant.Critical"
        plain
        class="mr-5"
        @click="openDeleteModal"
      >
        {{ $t('panel.requests.detail_page_actions.delete_button') }}
      </Button>

      <div ref="statusButton">
        <Button
          :variant="ButtonVariant.Primary"
          @click="toggleStatusPopover"
          @keyup.esc="closeStatusPopover"
        >
          <template #icon>
            <ChevronDownIcon class="h-5" />
          </template>

          {{ $t('panel.requests.detail_page_actions.change_status_button') }}
        </Button>
      </div>

      <Popover
        :parent="statusButton"
        :active="statusPopoverOpen"
        :min-width="200"
        @close="toggleStatusPopover"
      >
        <ActionList :items="statusItems" />
      </Popover>
    </div>
  </PageActions>

  <Modal
    :open="isDeleteModalOpen"
    :title="$t('panel.requests:detail_page_actions:delete_modal:title', { number: returnOrder.returnNumber })"
    @close="closeDeleteModal"
  >
    <TextContainer>
      {{ $t('panel.requests:detail_page_actions:delete_modal:text', { number: returnOrder.returnNumber }) }}
    </TextContainer>
    <template #actions>
      <Button @click="closeDeleteModal">
        {{ $t('panel.requests:detail_page_actions:delete_modal:cancel_button') }}
      </Button>
      <Button
        :variant="ButtonVariant.Critical"
        @click="deleteOrder"
      >
        {{ $t('panel.requests:detail_page_actions:delete_modal:confirm_button') }}
      </Button>
    </template>
  </Modal>

  <ApproveManualShipmentModal
    :open="isManualShipmentModalOpen"
    @close="closeManualShipmentModal"
  />
</template>
