<script lang="ts" setup>
import {
  Badge,
  Button,
  Card,
  CardSection,
  Listbox,
  ListboxOption,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { ref } from 'vue';
import ManagePaymentRequestModal from './ManagePaymentRequestModal.vue';
import type { Types } from '@app/shared/types/generated-v2';
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';

type ReturnOrderViewModel = Types['App.Models.ViewModels.ReturnOrderViewModel'];
type PaymentRequestViewModel = Types['App.Models.ViewModels.PaymentRequestViewModel'];
type NoteTemplateViewModel = Types['App.Models.ViewModels.NoteTemplateViewModel'];
type CountryViewModel = Types['App.Models.ViewModels.CountryViewModel'];

const props = defineProps<{
  returnOrder: ReturnOrderViewModel;
  noteTemplates: NoteTemplateViewModel;
  country: CountryViewModel;
  paymentRequests: PaymentRequestViewModel[];
}>();

const createModalOpen = ref(false);
const activePaymentRequest = ref<null | PaymentRequestViewModel>(null);

async function showCreateModal() {
  createModalOpen.value = true;
}

function closeCreateModal() {
  createModalOpen.value = false;
}

function createPaymentRequest() {
  activePaymentRequest.value = null;
  showCreateModal();
}

function editPaymentRequest(paymentRequest: PaymentRequestViewModel) {
  activePaymentRequest.value = paymentRequest;
  showCreateModal();
}

function remindPaymentRequest(paymentRequest: PaymentRequestViewModel) {
  router.post(route('panel.return-orders.payment-request.remind', {
    returnOrder: props.returnOrder,
    paymentRequest: paymentRequest,
  }), {}, {
    preserveScroll: true,
  });
}
</script>

<template>
  <Card :title="$t('panel.returns:detail:payment-requests')">
    <Listbox
      v-if="paymentRequests.length > 0"
      class="-mb-6"
    >
      <ListboxOption
        v-for="paymentRequest in paymentRequests"
        :key="paymentRequest.id"
        @click="editPaymentRequest(paymentRequest)"
      >
        <div class="flex">
          <div class="text-sm w-full text-slate-700">
            <div class="flex items-center md:flex-col lg:flex-row justify-between space-x-2">
              <div class="flex flex-col">
                <TextStyle strong>
                  {{ paymentRequest.referenceNumber }}
                </TextStyle>
                <TextStyle :shade="TextStyleShade.Pale">
                  {{ paymentRequest.total }}
                </TextStyle>
              </div>
              <Badge
                v-if="paymentRequest.isPaid"
                color="green"
              >
                {{ $t('panel.returns:detail:payment-requests:paid') }}
              </Badge>
              <Button
                v-if="! paymentRequest.isPaid"
                @click.stop="remindPaymentRequest(paymentRequest)"
              >
                <template #icon>
                  <EnvelopeIcon class="h-5 w-5" />
                </template>
                {{ $t('panel.returns:detail:payment-requests:remind') }}
              </Button>
            </div>
          </div>
        </div>
      </ListboxOption>
    </Listbox>
    <CardSection>
      <Button
        full-width
        @click="createPaymentRequest"
      >
        {{ $t('panel.returns:detail:payment-requests:manage:create') }}
      </Button>
    </CardSection>
  </Card>
  <ManagePaymentRequestModal
    :open="createModalOpen"
    :return-order="returnOrder"
    :note-templates="noteTemplates"
    :active-payment-request="activePaymentRequest"
    :country="country"
    @close="closeCreateModal"
  />
</template>
