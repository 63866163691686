<script lang="ts" setup>
import { provide, ref } from 'vue';
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Badge,
  Button,
  ButtonContent,
  ButtonIcon,
  DataList,
  DataListContent,
  DataListItem,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/vue/16/solid';
import { AppLayout } from '@app/panel/Layouts';
import { router, useForm } from '@inertiajs/vue3';
import { CreateTagModal, EditTagModal, PageNavigation } from '../partials';
import { TagsPagePropsViewModel, TagViewModel } from './types';
import { useI18n } from 'vue-i18n';
import { DependencyWarningDialog, useDependencyWarning } from '@app/panel/Containers/DependencyWarning';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

defineProps<TagsPagePropsViewModel>();

const { t } = useI18n();

const { showDependencyWarning } = useDependencyWarning();

const form = useForm<{
  id: string | null;
  name: string;
  description: string;
  color: string;
}>({
  id: null,
  name: '',
  description: '',
  color: '',
});

const isCreateModalOpen = ref<boolean>(false);
const isEditModalOpen = ref<boolean>(false);

function openCreateModal(): void {
  isCreateModalOpen.value = true;
}

function closeCreateModal(): void {
  isCreateModalOpen.value = false;
}

function openEditModal(tag: TagViewModel): void {
  form.id = tag.id;
  form.name = tag.name;
  form.description = tag.description || '';
  form.color = tag.color;
  form.defaults();

  isEditModalOpen.value = true;
}

function resetForm() {
  form.id = null;
  form.name = '';
  form.description = '';
  form.color = '';
  form.defaults();
}

function closeEditModal(): void {
  isEditModalOpen.value = false;

  resetForm();
}

function remove(tag: TagViewModel): void {
  if (tag.dependencyWarning !== null) {
    showDependencyWarning(tag.dependencyWarning);
    return;
  }

  router.delete(route('panel.settings.general.tags.remove', tag));
}

function saveSortedItems(items: string[]): void {
  router.put(route('panel.settings.general.tags.sort'), { items }, {
    preserveScroll: true,
    preserveState: false,
  });
}

provide('tagForm', form);
</script>

<template>
  <AppLayout :title="t('panel.settings:general:tags')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle :page-help="getHelpscoutArticleBeacon('general-settings.tags.tags')">
          {{ t('panel.settings:general:tags') }}
        </PageTitle>
        <PageDescription>{{ t('panel.settings:general:tags:description') }}</PageDescription>
        <template
          v-if="tags.length > 0"
          #page-header-actions
        >
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ t('panel.settings:general:tags:add') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <DataList
        v-if="tags.length > 0"
        sortable
        @sort="saveSortedItems"
      >
        <DataListItem
          v-for="tag in tags"
          :id="tag.id"
          :key="tag.id"
        >
          <DataListContent>
            <Badge :color="tag.color">
              {{ tag.name }}
            </Badge>
          </DataListContent>
          <ActionList alignment="end">
            <ActionListTrigger>
              <Button
                icon-only
                variant="ghost"
                size="small"
              >
                <ButtonIcon :icon="EllipsisHorizontalIcon" />
              </Button>
            </ActionListTrigger>
            <ActionListBody>
              <ActionListSection>
                <ActionListItem @click="openEditModal(tag)">
                  {{ t('panel.settings:general:tags:edit') }}
                </ActionListItem>
                <ActionListItem
                  variant="destructive"
                  @click="remove(tag)"
                >
                  {{ t('panel.settings:general:tags:remove') }}
                </ActionListItem>
              </ActionListSection>
            </ActionListBody>
          </ActionList>
        </DataListItem>
      </DataList>

      <EmptyState v-if="tags.length === 0">
        <EmptyStateContent>
          <EmptyStateTitle>{{ t('panel.settings:general:tags:empty:title') }}</EmptyStateTitle>
          <EmptyStateDescription>{{ t('panel.settings:general:tags:empty:description') }}</EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ t('panel.settings:general:tags:add') }}</ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>

  <CreateTagModal
    :open="isCreateModalOpen"
    @close="closeCreateModal"
  />

  <EditTagModal
    :open="isEditModalOpen"
    @close="closeEditModal"
  />

  <DependencyWarningDialog />
</template>
