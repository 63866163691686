<script lang="ts" setup>
import {
  AdobeCommerceModal,
  BigCommerceModal,
  BolModal,
  CCVShopModal,
  ChannableModal,
  ChannelEngineModal,
  ExactModal,
  GoedgepicktModal,
  ItsPerfectModal,
  JTLWawiModal,
  LightspeedModal,
  Magento2Modal,
  MontaModal,
  OilModal,
  OpenModal,
  OracleNetsuiteModal,
  PicqerModal,
  PulpoModal,
  QLSModal,
  SalesforceModal,
  SalesupplyModal,
  Sendcloud3Modal,
  SendcloudModal,
  SherpaanModal,
  ShopifyModal,
  ShopifyPlusModal,
  Shopware5Modal,
  ShopwareModal,
  VirtualIntegrationModal,
  WicsModal,
  WooCommerceModal,
  XPRTModal,
} from '@app/panel/Partials';
import type { Types } from '@app/shared/types/generated-v2';

defineProps<{
  integration: Types['App.Models.ViewModels.PlatformIntegrationViewModel'] | null;
  tenant: Types['App.Models.ViewModels.TenantViewModel'];
  selectedDriver: string | null;
  loading: boolean;
  onClose: () => void;
  onVerify: () => void;
}>();

const integrations = {
  'adobe-commerce': AdobeCommerceModal,
  'big-commerce': BigCommerceModal,
  'bol': BolModal,
  'ccvshop': CCVShopModal,
  'channable': ChannableModal,
  'channel-engine': ChannelEngineModal,
  'exact': ExactModal,
  'goedgepickt': GoedgepicktModal,
  'its-perfect': ItsPerfectModal,
  'jtl-wawi': JTLWawiModal,
  'lightspeed': LightspeedModal,
  'magento2': Magento2Modal,
  'monta': MontaModal,
  'oil': OilModal,
  'open': OpenModal,
  'oracle-netsuite': OracleNetsuiteModal,
  'picqer': PicqerModal,
  'pulpo': PulpoModal,
  'qls': QLSModal,
  'salesforce': SalesforceModal,
  'salesupply': SalesupplyModal,
  'sendcloud': SendcloudModal,
  'sendcloud3': Sendcloud3Modal,
  'sherpaan': SherpaanModal,
  'shopify': ShopifyModal,
  'shopify-plus': ShopifyPlusModal,
  'shopware': ShopwareModal,
  'shopware5': Shopware5Modal,
  'virtual-integration': VirtualIntegrationModal,
  'wics': WicsModal,
  'woocommerce': WooCommerceModal,
  'xprt': XPRTModal,
};
</script>

<template>
  <div>
    <template
      v-for="(component, driver) in integrations"
      :key="driver"
    >
      <component
        :is="component"
        :open="driver === selectedDriver"
        :integration="integration"
        :tenant="tenant"
        :loading="loading"
        @close="() => onClose()"
        @verify="() => onVerify()"
      />
    </template>
  </div>
</template>
