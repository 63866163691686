<script lang="ts" setup>
import { computed, ref, useSlots } from 'vue';
import { Link } from '@inertiajs/vue3';
import { theme } from '../../../Composables/useTheme';
import { Popover } from '../../Popover';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },

  href: {
    type: String,
    default: null,
  },
});

const slots = useSlots();

const hasDropdownContent = computed<boolean>(() => {
  return !!slots['dropdown-content'];
});

const classList = computed(() => {
  return [
    { 'bg-slate-200': props.active },
    { 'hover:bg-slate-100': !props.active },
    theme('focus-outline-color'),
  ];
});

const topBarItem = ref<HTMLElement>(null);

const isPopoverOpen = ref<boolean>(false);

function openPopover(): void {
  isPopoverOpen.value = true;
}

function closePopover(): void {
  isPopoverOpen.value = false;
}
</script>

<template>
  <div class="relative">
    <component
      :is="href ? Link : 'button'"
      ref="topBarItem"
      :href="href"
      class="inline-block cursor-pointer rounded-md px-1.5 lg:px-3 py-2 text-sm font-medium"
      :class="classList"
      @click="openPopover"
      @keyup.esc="closePopover"
    >
      <slot />
      <span
        v-if="$slots['badge']"
        class="relative left-1 items-center justify-center px-1.5 py-1 ml-1 text-xs leading-none text-white bg-slate-400 rounded-full min-w-[1.5rem] text-center inline-block"
      >
        <slot name="badge" />
      </span>
    </component>

    <Popover
      :active="hasDropdownContent && isPopoverOpen"
      :parent="topBarItem"
      flush
      @close="closePopover"
    >
      <slot name="dropdown-content" />
    </Popover>
  </div>
</template>
