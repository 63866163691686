<script lang="ts" setup>
import { Button, Popover, PopoverPlacement } from '@app/panel/Components';
import { ButtonRounded } from '@app/panel/Components/Button';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';

const emit = defineEmits<{
  click: [];
}>();

const {
  disabled = false,
} = defineProps<{
  disabled: boolean;
}>();

const buttonDropdownButton = ref<HTMLElement | null>(null);
const popoverOpen = ref(false);

function togglePopover(): void {
  popoverOpen.value = !popoverOpen.value;
}

function closePopover(): void {
  popoverOpen.value = false;
}

function handleButtonClick(): void {
  closePopover();

  emit('click');
}
</script>

<template>
  <div
    ref="buttonDropdownButton"
    v-click-away="closePopover"
    class="flex relative"
    @keyup.esc="closePopover"
  >
    <Button
      v-bind="$attrs"
      :disabled="disabled"
      :rounded="ButtonRounded.Left"
      @click="handleButtonClick"
    >
      <template #icon>
        <slot name="icon" />
      </template>

      <slot />
    </Button>
    <div class="-ml-0.5">
      <Button
        :disabled="disabled"
        :rounded="ButtonRounded.Right"
        flush
        @click="togglePopover"
      >
        <div class="h-5 w-6 flex items-center justify-center">
          <ChevronDownIcon class="h-4" />
        </div>
      </Button>
    </div>

    <Popover
      :active="popoverOpen"
      flush
      :placement="PopoverPlacement.BottomStart"
      :parent="buttonDropdownButton"
      :offset="6"
    >
      <div
        class="flex flex-col items-start"
        @click="closePopover"
      >
        <slot name="dropdown" />
      </div>
    </Popover>
  </div>
</template>
