<script lang="ts" setup>
import {
  onMounted,
  ref,
  watch,
} from 'vue';
import {
  Card,
  CardSection,
  Form,
  Page,
} from '@app/panel/Components';
import {
  RequestsActiveFilters,
  RequestsDataTable,
  RequestsFilters,
  RequestsPageActions,
  RequestsTableFooter,
  RequestsTabs,
} from './partials';
import {
  get,
  isArray,
  set,
} from 'lodash';
import { AppLayout } from '@app/panel/Layouts';
import { router, useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import type { Types } from '@app/shared/types/generated-v2';
import { Heading } from '@app/panel/Components/DataTable';
import { cleanObject } from '@app/panel/Utils/object';

const props = defineProps<Types['App.Http.ViewModels.Panel.Requests.RequestOrdersOverviewViewModel']>();

const filterForm = useForm({
  filter: {
    created_at: {
      from: '',
      to: '',
    },
    quantity: {
      from: '',
      to: '',
    },
    value: {
      from: '',
      to: '',
    },
    shipping_integration: '',
    platform_integration: '',
    form: '',
    return_type: '',
    request_status: '',
    return_order_state: '',
    locale: '',
    note_filter: '',
    tags: [],
    is_test_record: props.filters.is_test_record,
    ...props.filters,
  },
  sort: props.sort,
  per_page: props.perPage,
});

function sort(column: string) {
  filterForm.sort = column;
  onFormChange();
}

const { t } = useI18n();

const headings: Heading[] = [
  {
    text: t('panel.requests.table_headings.return_number'),
    value: 'returnNumber',
    column: 'return_number',
    sortable: true,
  },
  { text: t('panel.requests.table_headings.state'), value: 'state' },
  { text: t('panel.returns.table_headings.status'), value: 'status' },
  { text: t('panel.requests.table_headings.sales_order'), value: 'customer' },
  { text: t('panel.requests.table_headings.value'), value: 'value', column: 'value', sortable: true },
  { text: t('panel.requests.table_headings.quantity'), value: 'quantity', column: 'quantity', sortable: true },
  { text: t('panel.returns.table_headings.tags'), value: 'tags' },
];

const activeHeadings = ref([]);
const defaultActiveHeadings = [
  'returnNumber',
  'hasUnreadNotes',
  'customer',
  'status',
  'state',
  'value',
  'quantity',
  'tags',
];

const ACTIVE_HEADINGS_KEY = 'requestsActiveHeadings';

watch(activeHeadings, (newValue) => {
  localStorage.setItem(ACTIVE_HEADINGS_KEY, JSON.stringify(newValue));
});

onMounted(() => {
  const storedActiveHeadings = localStorage.getItem(ACTIVE_HEADINGS_KEY);

  activeHeadings.value = storedActiveHeadings ? JSON.parse(storedActiveHeadings) : defaultActiveHeadings;
});

const filtersOpen = ref(false);

const onFormChange = () => {
  router.get(route('panel.requests'), cleanObject(filterForm.data()), {
    preserveScroll: true,
    preserveState: true,
  });
};

const handleFilterChange = (newFilterForm): void => {
  filterForm.filter = {
    ...newFilterForm.filter,
    is_test_record: filterForm.filter.is_test_record,
  };
  filterForm.per_page = newFilterForm.per_page;
  onFormChange();
};

const handleTableFooterChange = (per_page): void => {
  filterForm.per_page = per_page;
  onFormChange();
};

const resetFilter = (path: string): void => {
  const value = get(filterForm, path);

  set(filterForm, path, isArray(value) ? [] : '');

  onFormChange();
};

const toggleFilters = (): boolean => filtersOpen.value = !filtersOpen.value;

const checkedRequestOrders = ref([]);

function updateCheckedItems(items): void {
  checkedRequestOrders.value = items;
}

function toggleShowTestData(state: boolean) {
  filterForm.filter.is_test_record = state;
  handleFilterChange(filterForm);
}
</script>

<template>
  <AppLayout :title="$t('panel.requests.heading')">
    <Page>
      <RequestsPageActions
        v-model:active-headings="activeHeadings"
        :headings="headings"
        :toggle-filters="toggleFilters"
        :filter-tabs="filterTabs"
        :show-test-records="filters.is_test_record === true || filters.is_test_record === 'true'"
        @toggle-show-test-data="toggleShowTestData"
      />

      <Form
        method="GET"
        @submit="filterForm"
      >
        <RequestsFilters
          :filters-open="filtersOpen"
          :filter="filterForm"
          :per-page="perPage"
          :platform-integrations="platformIntegrations"
          :locales="locales"
          :forms="forms"
          :filter-tabs="filterTabs"
          :request-statuses="requestStatuses"
          :return-order-states="returnOrderStates"
          :note-filter-options="noteFilterOptions"
          :tags="tags"
          @change="handleFilterChange"
        />

        <RequestsActiveFilters
          :filter-form="filterForm"
          :platform-integrations="platformIntegrations"
          :locales="locales"
          :forms="forms"
          :request-statuses="requestStatuses"
          :return-order-states="returnOrderStates"
          :note-filter-options="noteFilterOptions"
          :reset-filter="resetFilter"
          :tags="tags"
        />

        <div class="mt-6">
          <Card>
            <CardSection flush>
              <RequestsTabs
                :filter-tabs="filterTabs"
                :previous-page-url="previousPageUrl"
                :next-page-url="nextPageUrl"
              />
            </CardSection>

            <RequestsDataTable
              :checked-items="checkedRequestOrders"
              :headings="headings"
              :active-headings="activeHeadings"
              :orders="orders"
              :sort-by="filterForm.sort ?? null"
              @sorted="sort"
              @check="updateCheckedItems"
            />

            <CardSection dense>
              <RequestsTableFooter
                :checked-items="checkedRequestOrders"
                :request-statuses="requestStatuses"
                :tags="tags"
                :paginator-status="paginatorStatus"
                :per-page="perPage"
                :previous-page-url="previousPageUrl"
                :next-page-url="nextPageUrl"
                @change="handleTableFooterChange"
              />
            </CardSection>
          </Card>
        </div>
      </Form>
    </Page>
  </AppLayout>
</template>
