<script lang="ts" setup>
import { ref } from 'vue';
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline';
import { ArrowRightIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import GlobalSearchBar from './GlobalSearchBar.vue';
import { usePageLoad } from '@app/panel/Composables/usePageLoad';
import { Spinner } from '@app/panel/Components';

defineProps({
  searchBarPlaceholder: {
    type: String,
    default: '',
  },
});

const menuOpen = ref(false);
const searchBarExpanded = ref(false);

const toggleMenu = () => menuOpen.value = !menuOpen.value;
const toggleSearchBar = () => searchBarExpanded.value = !searchBarExpanded.value;

const { loading } = usePageLoad();
</script>

<template>
  <div class="bg-white/50 backdrop-blur-lg shadow-sm border-b md:h-fit sticky top-0 z-10">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div
        class="flex flex-col md:flex-row items-center justify-between"
      >
        <div
          data-test="top-bar-container"
          class="w-full md:w-fit flex items-center h-16"
          :class="{ 'flex-1': searchBarExpanded }"
        >
          <div class="shrink-0 relative rounded overflow-hidden">
            <div
              class="absolute inset-0 bg-brand-500 place-items-center"
              :class="{
                'hidden': !loading,
                'grid': loading,
              }"
            >
              <Spinner variant="light" />
            </div>
            <img
              src="https://www.returnless.com/hubfs/Favicon-RGB-White-3.png"
              class="size-10"
              loading="lazy"
            >
          </div>

          <div
            data-test="search-bar-container"
            class="flex-1"
            :class="searchBarExpanded ? 'block' : 'md:hidden'"
          >
            <GlobalSearchBar :placeholder="searchBarPlaceholder" />
          </div>

          <div
            data-test="menu-toggler"
            class="block md:hidden cursor-pointer"
            @click="toggleMenu"
          >
            <XMarkIcon
              v-if="menuOpen"
              class="w-6 h-6 text-slate-400"
            />

            <Bars3Icon
              v-else
              class="w-6 h-6 text-slate-400"
            />
          </div>
          <div
            v-if="!searchBarExpanded"
            data-test="navigation-container"
            class="hidden md:block"
          >
            <div class="ml-10 flex items-baseline space-x-4">
              <slot name="navigation" />
            </div>
          </div>
        </div>

        <div class="relative">
          <slot />
        </div>

        <div
          data-test="menu-container"
          class="md:flex md:items-center md:h-fit w-full md:w-fit"
          :class="menuOpen ? 'h-72' : 'h-0 invisible md:visible'"
        >
          <div
            :class="menuOpen ? 'opacity-1' : 'opacity-0 md:opacity-100'"
          >
            <div
              class="flex flex-col md:flex-row md:items-center"
              :class="menuOpen ? 'visible' : 'invisible md:visible'"
            >
              <div
                v-if="!searchBarExpanded"
                class="w-96 hidden xl:block"
              >
                <GlobalSearchBar :placeholder="searchBarPlaceholder" />
              </div>

              <div
                data-test="search-bar-toggler"
                class="cursor-pointer pr-2 py-2 text-slate-400 hover:text-slate-500 rounded-md hidden md:block xl:hidden"
                @click="toggleSearchBar"
              >
                <ArrowRightIcon
                  v-if="searchBarExpanded"
                  class="h-5 w-5"
                />

                <MagnifyingGlassIcon
                  v-else
                  class="h-5 w-5"
                />
              </div>

              <div class="block md:hidden pt-3 pb-4 space-y-3">
                <slot name="navigation" />
              </div>

              <slot name="documentation" />

              <div class="block">
                <slot name="user-menu" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
