<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  Avatar,
  AvatarSize,
  ButtonType,
  Card,
  CardSection,
  FileUploadButton,
  Form,
  FormLayout,
  Select,
  TextContainer,
  TextInput,
  TextStyle,
  TextStyleShade,
  ToastVariant,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { useUpload } from '@app/panel/Composables/useUpload';
import { useToast } from '@app/panel/Composables/useToast';
import { Types } from '@app/shared/types/generated-v2';
import timezones from '@app/panel/Utils/timezones';
import { usePage } from '@inertiajs/vue3';

const props = defineProps<{
  user: Types['App.Http.ViewModels.Panel.Account.ProfileViewModel']['user'];
  languages: Types['App.Http.ViewModels.Panel.Account.ProfileViewModel']['languages'];
}>();

const largeAvatarSize = AvatarSize.ExtraLarge;
const profileFileImageUploadProgress = ref(0);
const personalInformationForm = useForm({
  email: props.user.email,
  name: props.user.name,
  timezone: props.user.timezone,
  language: props.user.language,
});

function updatePersonalInformation() {
  const languageChanged = props.user.language !== personalInformationForm.language;

  personalInformationForm.put(route('panel.account.update'), {
    preserveScroll: true,
    onSuccess: () => languageChanged && window.location.reload(),
  });
}

const { emitToastEvent } = useToast();

const uploadProfilePicture = (file: File) => {
  const { upload } = useUpload();

  profileFileImageUploadProgress.value = 0;
  upload(file, (progress) => profileFileImageUploadProgress.value = progress)
    .then((response) => {
      router.put(route('panel.account.profile-picture'), {
        image: response as any,
      });
    })
    .catch((error) => {
      emitToastEvent({
        title: 'Failed to upload image',
        message: error,
        variant: ToastVariant.Critical,
      });
    })
    .finally(() => {
      profileFileImageUploadProgress.value = 0;
    });
};

const timezoneOptions = computed(() => {
  return timezones.map((timezone) => {
    return { value: timezone, label: timezone };
  });
});
</script>

<template>
  <Form
    has-files
    @submit="updatePersonalInformation"
  >
    <Card
      class="overflow-visible"
      :title="$t('panel.account.profile.personal_information.card_title')"
      :primary-footer-action="{
        content: $t('panel.account.profile.personal_information.save_button'),
        type: ButtonType.Submit,
        loading: personalInformationForm.processing
      }"
    >
      <CardSection>
        <FormLayout>
          <FormLayout>
            <TextContainer>
              <div class="ml-1 mb-1">
                <TextStyle
                  :shade="TextStyleShade.Default"
                  strong
                >
                  {{ $t('panel.account.profile.personal_information.photo_field') }}
                </TextStyle>
              </div>

              <div class="flex items-center">
                <Avatar
                  :src="user.profilePhotoUrl"
                  rounded
                  :size="largeAvatarSize"
                />

                <div class="ml-5">
                  <FileUploadButton
                    :loading="profileFileImageUploadProgress > 0"
                    :label="$t('panel.account.profile.personal_information.photo_upload_button')"
                    @change="uploadProfilePicture"
                  />
                </div>
              </div>
            </TextContainer>
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="personalInformationForm.email"
                :error="personalInformationForm.errors.email || usePage().props.errors?.email"
                :label="$t('panel.account.profile.personal_information.email_address_field')"
                type="email"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="personalInformationForm.name"
                :error="personalInformationForm.errors.name"
                :label="$t('panel.account.profile.personal_information.name_field')"
              />
            </FormLayout>
          </FormLayout>

          <FormLayout>
            <Select
              v-model="personalInformationForm.timezone"
              :label="$t('panel.account.profile.personal_information.timezone_field')"
              :help-text="$t('panel.account.profile.personal_information.timezone_help_text')"
              :options="timezoneOptions"
            />
          </FormLayout>

          <FormLayout v-if="languages.length > 1">
            <Select
              v-model="personalInformationForm.language"
              :label="$t('panel.account.profile.personal_information.language_field')"
              :help-text="$t('panel.account.profile.personal_information.language_help_text')"
              :options="languages"
            />
          </FormLayout>
        </FormLayout>
      </CardSection>
    </Card>
  </Form>
</template>
