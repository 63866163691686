<script lang='ts' setup>
import { computed, ref } from 'vue';
import { Pie } from 'vue-chartjs';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  Title,
  Tooltip,
} from 'chart.js';
import { ChartColor, doughnutBackgroundColors } from '../config';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
);

const props = withDefaults(defineProps<{
  chartId?: string;
  labels: string[];
  datasets: { label: string; data: number[] }[];
  height?: number;
  hideLabels: boolean;
}>(), {
  chartId: 'pie-chart',
  height: 400,
  hideLabels: false,
});

const computedDatasets = computed(() => {
  return props.datasets.map((dataset) => {
    return {
      backgroundColor: dataset.data.map((_, index) => {
        return doughnutBackgroundColors[index];
      }),

      data: dataset.data,
      cutout: '70%',
    };
  });
});

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: computedDatasets.value,
  };
});

const chartOptions = {
  layout: {
    padding: 10,
  },

  animation: {
    duration: 0,
  },

  plugins: {
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        pointStyle: 'circle',
        usePointStyle: true,
      },
    },
  },

  responsive: true,
  maintainAspectRatio: false,
};

function drawLine(ctx, x, y, xLine, yLine, extraLine) {
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(xLine, yLine);
  ctx.lineTo(xLine + extraLine, yLine);
  ctx.strokeStyle = ChartColor.Slate700;
  ctx.stroke();
}

function drawText(ctx, textXPosition, label, labelX, labelY) {
  ctx.textAlign = textXPosition;
  ctx.font = '14px';
  ctx.textBaseline = 'middle';
  ctx.fillStyle = ChartColor.Slate700;
  ctx.fillText(label, labelX, labelY);
}

const labelLinesPlugin = {
  id: 'doughnutLableLine',

  afterDraw(chart) {
    const { ctx, chartArea: { width, height } } = chart;

    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { x, y } = datapoint.tooltipPosition();

        const halfWidth = width / 2;
        const halfHeight = height / 2;

        const xLine = x >= halfWidth ? x + 15 : x - 15;
        const yLine = y >= halfHeight ? y + 15 : y - 15;
        const extraLine = x >= halfWidth ? 70 : -70;

        drawLine(ctx, x, y, xLine, yLine, extraLine);

        const textXPosition = x >= halfWidth ? 'left' : 'right';
        const textSpacing = x >= halfWidth ? 5 : -5;

        const label = chart.data.labels[index];
        const value = chart.data.datasets[i].data[index];

        const pieceLabel = `${label} (${value})`;
        const labelX = xLine + extraLine + textSpacing;

        drawText(ctx, textXPosition, pieceLabel, labelX, yLine);
      });
    });
  },
};

const plugins = ref([]);

if (!props.hideLabels) {
  plugins.value.push(labelLinesPlugin);
}
</script>

<template>
  <Pie
    :chart-id="chartId"
    :chart-data="chartData"
    :height="height"
    :chart-options="chartOptions"
    :plugins="plugins"
  />
</template>
