import { readonly, ref, Ref, watch } from 'vue';
import { debounce, isEmpty } from 'lodash';

type Predicate<T> = (item: T, query: string) => boolean;

interface Options {
  debounceTimeout?: number;
  filter?: boolean;
  initialQuery?: string;
}

function filterItems<T>(
  items: T[],
  predicate: Predicate<T>,
  query: string,
  filter: boolean,
): T[] {
  if (!isEmpty(query)) {
    return items.filter((item) => predicate(item, query));
  }

  return filter ? items : [];
}

export function useSearch<T>(
  items: T[],
  predicate: Predicate<T>,
  { debounceTimeout, filter = false, initialQuery = '' }: Options = {},
): [
    Ref<T[]>,
    (event: Event | string) => void,
    Readonly<Ref<boolean>>,
    Readonly<Ref<string>>,
  ] {
  const loading = ref<boolean>(false);
  const query = ref<string>(initialQuery);
  const filteredItems = ref<T[]>(
    filterItems(items, predicate, query.value, filter),
  ) as Ref<T[]>;

  function handleChange(event: Event | string): void {
    if (typeof event === 'string') {
      query.value = event;
      return;
    }

    query.value = ((event as Event).target as HTMLInputElement).value;
  }

  const filterWithDebounce = debounce(() => {
    filteredItems.value = filterItems(items, predicate, query.value, filter);
    loading.value = false;
  }, debounceTimeout);

  watch(query, () => {
    loading.value = true;
    filterWithDebounce();
  });

  return [
    filteredItems,
    handleChange,
    readonly(loading),
    readonly(query),
  ];
}
