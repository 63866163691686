<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { Form as FormType, Locale, PlatformIntegration } from '@app/panel/types';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Combobox,
  FooterActionPosition,
  Form,
  FormLayout,
  Modal,
  ModalSize,
  Select,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { FilterTabs } from '@app/panel/Containers';
import { TextInputIconPosition } from '@app/panel/Components';
import { App, type Types } from '@app/shared/types/generated-v2';
import { ComboboxOption } from '@app/panel/Components/Combobox/types';
import { SelectOption } from '@app/customer/Components';
import { FunnelIcon } from '@heroicons/vue/24/outline';

type FilterTabViewModel = Types['App.Models.ViewModels.FilterTabViewModel'];
type RequestStatusViewModel = Types['App.Models.ViewModels.RequestStatusViewModel'];
type TagViewModel = Types['App.Models.ViewModels.TagViewModel'];

const props = defineProps({
  perPage: {
    type: Number,
    required: true,
  },

  platformIntegrations: {
    type: Array,
    required: true,
  },

  locales: {
    type: Array,
    required: true,
  },

  forms: {
    type: Array,
    required: true,
  },

  requestStatuses: {
    type: Array,
    required: true,
  },

  returnOrderStates: {
    type: Array,
    required: true,
  },

  filter: {
    type: Object,
    required: true,
  },

  filtersOpen: {
    type: Boolean,
    default: false,
  },

  filterTabs: {
    type: Array as PropType<FilterTabViewModel[]>,
    required: true,
  },

  noteFilterOptions: {
    type: Array as PropType<SelectOption[]>,
    required: true,
  },

  tags: {
    type: Array as PropType<TagViewModel[]>,
    required: true,
  },
});

const emit = defineEmits<{
  change: [form: typeof filterForm];
}>();

const saveFilterModalOpen = ref(false);
const manageFilterModalOpen = ref(false);
const saveFilterForm = useForm({
  label: null,
  filters: null,
  type: 'request',
});
const filterForm = useForm({
  filter: {
    created_at: {
      from: '',
      to: '',
    },
    quantity: {
      from: '',
      to: '',
    },
    value: {
      from: '',
      to: '',
    },
    shipping_integration: '',
    platform_integration: '',
    request_status: '',
    return_order_state: '',
    form: '',
    return_type: '',
    note_filter: '',
    tags: [],
    ...props.filter.filter,
  },
  per_page: props.filter.per_page,
});

const tagOptions = computed<ComboboxOption[]>(() => {
  return props.tags.map((tag: TagViewModel) => {
    return {
      label: tag.name,
      value: tag.id,
      color: tag.color,
    };
  });
});

const platformIntegrationOptions = computed(() => {
  return props.platformIntegrations.map((integration: PlatformIntegration) => {
    return {
      value: integration.id,
      label: integration.logo.name,
    };
  });
});

const localeOptions = computed(() => {
  return props.locales.map((locale: Locale) => {
    return {
      value: locale.id,
      label: locale.name,
    };
  });
});

const formOptions = computed(() => {
  return props.forms.map((form: FormType) => {
    return {
      value: form.id,
      label: form.name,
    };
  });
});

const statusOptions = computed(() => {
  return props.requestStatuses.map((status: RequestStatusViewModel) => {
    return {
      value: status.id,
      label: status.label,
    };
  });
});

const stateOptions = computed(() => {
  return props.returnOrderStates.map((returnOrderState: { id: string; label: string }) => {
    return {
      value: returnOrderState.id,
      label: returnOrderState.label,
    };
  });
});

const onFilterChange = (): void => {
  emit('change', filterForm);
};

function openSaveFilterModal(): void {
  saveFilterModalOpen.value = true;
}

function closeSaveFilterModal(): void {
  saveFilterModalOpen.value = false;

  saveFilterForm.reset();
}

function submitSaveFilter(): void {
  saveFilterForm.filters = window.location.search;

  saveFilterForm.post(route('panel.settings.filter-tabs.store'), {
    onSuccess: () => closeSaveFilterModal(),
  });
}

function openManageFiltersModal(): void {
  manageFilterModalOpen.value = true;
}

function closeManageFiltersModal(): void {
  manageFilterModalOpen.value = false;
}
</script>

<template>
  <Card
    v-if="filtersOpen"
    :secondary-footer-actions="[
      {
        content: $t('panel.requests.filters.save'),
        disabled: !$page.props.hasActiveFilter,
        callback: () => openSaveFilterModal(),
      },
      {
        content: $t('panel.requests.filters.manage'),
        disabled: !filterTabs || filterTabs.length === 0,
        callback: () => openManageFiltersModal(),
      },
    ]"
    :footer-action-position="FooterActionPosition.Left"
  >
    <CardSection>
      <FormLayout
        :columns-md="2"
        :columns-lg="3"
      >
        <FormLayout :columns="2">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.created_at.from"
              :label="$t('panel.requests.filters.created_from')"
              :placeholder="$t('panel.requests.filters.created_from')"
              :type="TextInputFieldTypes.Date"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.created_at.to"
              :label="$t('panel.requests.filters.created_to')"
              :placeholder="$t('panel.requests.filters.created_to')"
              :type="TextInputFieldTypes.Date"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout :columns="2">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.value.from"
              :label="$t('panel.requests.filters.value_from_label')"
              :placeholder="$t('panel.requests.filters.value_from_placeholder')"
              :type="TextInputFieldTypes.Number"
              :icon-position="TextInputIconPosition.Left"
              @change="onFilterChange"
            >
              <template #icon>
                €
              </template>
            </TextInput>
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.value.to"
              :label="$t('panel.requests.filters.value_to_label')"
              :placeholder="$t('panel.requests.filters.value_to_placeholder')"
              :type="TextInputFieldTypes.Number"
              :icon-position="TextInputIconPosition.Left"
              @change="onFilterChange"
            >
              <template #icon>
                €
              </template>
            </TextInput>
          </FormLayout>
        </FormLayout>

        <FormLayout :columns="2">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.quantity.from"
              :label="$t('panel.requests.filters.quantity_from_label')"
              :placeholder="$t('panel.requests.filters.quantity_from_placeholder')"
              :type="TextInputFieldTypes.Number"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.quantity.to"
              :label="$t('panel.requests.filters.quantity_to_label')"
              :placeholder="$t('panel.requests.filters.quantity_to_placeholder')"
              :type="TextInputFieldTypes.Number"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout>
          <Select
            v-model="filterForm.filter.platform_integration"
            :label="$t('panel.requests.filters.integration_label')"
            :options="platformIntegrationOptions"
            @change="onFilterChange"
          />
        </FormLayout>

        <FormLayout>
          <Select
            v-model="filterForm.filter.locale"
            :label="$t('panel.requests.filters.locale_label')"
            :options="localeOptions"
            @change="onFilterChange"
          />
        </FormLayout>

        <FormLayout>
          <Select
            v-model="filterForm.filter.form"
            :label="$t('panel.requests.filters.form_label')"
            :options="formOptions"
            @change="onFilterChange"
          />
        </FormLayout>

        <FormLayout :columns="2">
          <FormLayout>
            <Select
              v-model="filterForm.filter.return_order_state"
              :label="$t('panel.requests.filters.state_label')"
              :options="stateOptions"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <Select
              v-model="filterForm.filter.request_status"
              :label="$t('panel.requests.filters.status_label')"
              :options="statusOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout>
          <Select
            v-model="filterForm.filter.note_filter"
            :label="$t('panel.requests.filters.notes_label')"
            :options="noteFilterOptions"
            @change="onFilterChange"
          />
        </FormLayout>

        <FormLayout>
          <Combobox
            v-model="filterForm.filter.tags"
            label="Tags"
            :options="tagOptions"
            @change="onFilterChange"
          />
        </FormLayout>
      </FormLayout>
    </CardSection>
  </Card>

  <Modal
    :size="ModalSize.Small"
    icon-color="blue"
    :open="saveFilterModalOpen"
    :title="$t('panel.returns.filters.manage_filters')"
    @close="closeSaveFilterModal"
  >
    <template #icon>
      <FunnelIcon class="w-5" />
    </template>

    <Form
      id="save-filter-form"
      @submit="submitSaveFilter"
    >
      <FormLayout>
        <TextInput
          v-model="saveFilterForm.label"
          :error="saveFilterForm.errors.label"
          :label="$t('panel.requests.filters.save_filter_modal.text_input_label')"
          :max-length="30"
        />
      </FormLayout>
    </Form>

    <template #actions>
      <Button
        :disabled="! saveFilterForm.isDirty"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="save-filter-form"
      >
        {{ $t('panel.requests.filters.save_filter_modal.action') }}
      </Button>
    </template>
  </Modal>

  <FilterTabs
    :open="manageFilterModalOpen"
    :filter-tabs="filterTabs"
    :type="App.Enums.FilterTabType.REQUEST"
    @close="closeManageFiltersModal"
  />
</template>
