<script lang="ts" setup>
import { provide, toRef, watch } from 'vue';
import {
  Button,
  ButtonVariant,
  CardSection,
  Page,
  PageActions,
  PageActionsPosition,
  ResourceItem,
  ResourceList,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { Card, CardDescription, CardHeader, CardTitle } from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { router } from '@inertiajs/vue3';
import { PageNavigation } from '../partials';
import type { Types } from '@app/shared/types/generated-v2';
import { App } from '@app/shared/types/generated-v2';
import { useUniqueId } from '@app/customer/Composables/useUniqueId';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import { StatusBadge } from '@app/panel/types';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type ListReturnRulesViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ReturnRules.ListReturnRulesViewModel'];
type ReturnRuleViewModel = Types['App.Models.ViewModels.ReturnRuleViewModel'];

const props = defineProps<ListReturnRulesViewModel>();

provide('form', props.form);

const reactiveReturnRules = toRef(props, 'returnRules');
const returnRuleTableId = useUniqueId('table');
const { sortedItems: returnRulesSortedItems } = useDragAndDrop(returnRuleTableId, reactiveReturnRules);

watch(returnRulesSortedItems, (newValue) => {
  const sortedIds = newValue.map((item) => item.id);

  router.put(route('panel.settings.forms.return-rules.sort', { form: props.form }), {
    items: sortedIds,
    return_rule_level: App.Enums.EntityLevel.RETURN,
  }, {
    preserveScroll: true,
    preserveState: false,
  });
});

const reactiveItemRules = toRef(props, 'itemRules');
const itemRuleTableId = useUniqueId('table');
const { sortedItems: itemRulesSortedItems } = useDragAndDrop(itemRuleTableId, reactiveItemRules);

watch(itemRulesSortedItems, (newValue) => {
  const sortedIds = newValue.map((item) => item.id);

  router.put(route('panel.settings.forms.return-rules.sort', { form: props.form }), {
    items: sortedIds,
    return_rule_level: App.Enums.EntityLevel.ITEM,
  }, {
    preserveScroll: true,
    preserveState: false,
  });
});

function goToReturnRuleCreatePage(): void {
  router.get(route('panel.settings.form.return-rules.create', props.form));
}

function goToReturnRulePage(returnRule): void {
  router.get(route('panel.settings.form.return-rules.show', { form: props.form, returnRule }));
}

function goToItemRuleCreatePage(): void {
  router.get(route('panel.settings.form.item-rules.create', props.form));
}

const { t } = useI18n();

function getStatusBadges(returnRule: ReturnRuleViewModel): StatusBadge[] {
  const badges = [];

  if (returnRule.broken) {
    badges.push({
      label: t('panel.settings:form:automations:broken'),
      color: 'red',
    });
  }

  badges.push({
    label: returnRule.isEnabled
      ? t('panel.settings.forms.list_forms.settings.return_questions.active')
      : t('panel.settings.forms.list_forms.settings.return_questions.disabled'),
    color: returnRule.isEnabled ? 'green' : 'orange',
  });

  return badges;
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:form:return-rules:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <div>
        <div class="space-y-8">
          <PageActions :position="PageActionsPosition.End">
            <div>
              <Button
                :variant="ButtonVariant.Primary"
                @click="goToReturnRuleCreatePage"
              >
                <template #icon>
                  <PlusIcon class="size-5" />
                </template>

                <span>{{ $t('panel.settings:form:return-rules:create') }}</span>
              </Button>
            </div>
          </PageActions>

          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.return-rules.return-level-rules')">
                {{ $t('panel.settings:form:return-rules:title') }}
              </CardTitle>
              <CardDescription>{{ $t('panel.settings:form:return-rules:subtitle') }}</CardDescription>
            </CardHeader>
            <CardSection>
              <ResourceList
                v-if="returnRules.length > 0"
                :id="returnRuleTableId"
              >
                <ResourceItem
                  v-for="returnRule in returnRules"
                  :id="returnRule.id"
                  :key="returnRule.id"
                  :label="returnRule.name"
                  :tags="returnRule.tags"
                  :badges="getStatusBadges(returnRule)"
                  @click="() => goToReturnRulePage(returnRule)"
                />
              </ResourceList>

              <div
                v-else
                class="my-2"
              >
                <TextContainer text-center>
                  <TextStyle :shade="TextStyleShade.Subdued">
                    {{ $t('panel.settings:form:return-rules:no-data') }}
                  </TextStyle>
                </TextContainer>
              </div>
            </CardSection>
          </Card>

          <PageActions :position="PageActionsPosition.End">
            <div>
              <Button
                :variant="ButtonVariant.Primary"
                @click="goToItemRuleCreatePage"
              >
                <template #icon>
                  <PlusIcon class="size-5" />
                </template>

                <span>{{ $t('panel.settings:form:item-rules:create') }}</span>
              </Button>
            </div>
          </PageActions>

          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.return-rules.return-item-level-rules')">
                {{ $t('panel.settings:form:item-rules:title') }}
              </CardTitle>
              <CardDescription>{{ $t('panel.settings:form:item-rules:subtitle') }}</CardDescription>
            </CardHeader>
            <CardSection>
              <ResourceList
                v-if="itemRules.length > 0"
                :id="itemRuleTableId"
              >
                <ResourceItem
                  v-for="itemRule in itemRules"
                  :id="itemRule.id"
                  :key="itemRule.id"
                  :label="itemRule.name"
                  :tags="itemRule.tags"
                  :badges="getStatusBadges(itemRule)"
                  @click="() => goToReturnRulePage(itemRule)"
                />
              </ResourceList>

              <div
                v-else
                class="my-2"
              >
                <TextContainer text-center>
                  <TextStyle :shade="TextStyleShade.Subdued">
                    {{ $t('panel.settings:form:item-rules:no-data') }}
                  </TextStyle>
                </TextContainer>
              </div>
            </CardSection>
          </Card>
        </div>
      </div>
    </Page>
  </AppLayout>
</template>
