<script lang="ts" setup>
import {
  BarChart,
  Card,
  CardSection,
  Form,
  Page,
} from '@app/panel/Components';
import { AppLayout } from '@app/panel/Layouts';
import Navigation from './partials/Navigation.vue';
import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import OverviewActions from './blocks/Overview/OverviewActions.vue';
import OverviewFilters from './blocks/Overview/OverviewFilters.vue';
import OverviewActiveFilters from './blocks/Overview/OverviewActiveFilters.vue';
import DailyReturnsOverviewChart from './blocks/DailyReturnsOverviewChart/DailyReturnsOverviewChart.vue';
import HorizontalBarChart from './components/HorizontalBarChart.vue';
import { get, isArray, set } from 'lodash';
import { type Types } from '@app/shared/types/generated-v2';
import { InsightsItem } from '@app/panel/Containers';

type OverviewViewModel = Types['App.Http.ViewModels.Panel.Analytics.Overview.OverviewViewModel'];

const props = defineProps<OverviewViewModel>();

const filterForm = useForm({
  filter: {
    state: [],
    form: '',
    value: { from: '', to: '' },
    quantity: { from: '', to: '' },
    locale: '',
    return_status: '',
    request_status: '',
    ...props.filters,
  },
  date_filter: {
    from: props.dateFilter.from,
    to: props.dateFilter.to,
  },
});

const filtersOpen = ref(false);

function toggleFilters() {
  filtersOpen.value = !filtersOpen.value;
}

function updateDateRangeFilter(from: string, to: string) {
  filterForm.date_filter.from = from;
  filterForm.date_filter.to = to;
  onFormChange();
}

const onFormChange = () => {
  filterForm.get(route('panel.analytics.overview'), {
    preserveScroll: true,
  });
};

const handleFilterChange = (newFilterForm): void => {
  filterForm.filter = newFilterForm.filter;
  onFormChange();
};

const resetFilter = (path: string): void => {
  const value = get(filterForm, path);

  set(filterForm, path, isArray(value) ? [] : '');

  onFormChange();
};
</script>

<template>
  <AppLayout :title="$t('panel.analytics:overview:title')">
    <Page>
      <template #page-navigation>
        <Navigation />
      </template>

      <div class="mb-6">
        <OverviewActions
          :filter="filterForm"
          :date-filter="dateFilter"
          :filter-tabs="[]"
          @toggle-filters="toggleFilters"
          @updated-date-range="updateDateRangeFilter"
        />
      </div>

      <Form
        method="GET"
        @submit="onFormChange"
      >
        <OverviewFilters
          :filters-open="filtersOpen"
          :filter="filterForm"
          :filter-tabs="filterTabs"
          :has-active-filter="hasActiveFilter"
          :states="states"
          :forms="forms"
          :locales="locales"
          :return-statuses="returnStatuses"
          :request-statuses="requestStatuses"
          @change="handleFilterChange"
        />

        <OverviewActiveFilters
          :filter-form="filterForm"
          :reset-filter="resetFilter"
          :states="states"
          :forms="forms"
          :locales="locales"
          :return-statuses="returnStatuses"
          :request-statuses="requestStatuses"
        />
      </Form>

      <div class="mt-6">
        <DailyReturnsOverviewChart
          :data-points="overviewReturnsDataPoints"
        />
      </div>

      <div class="mt-6">
        <Card :title="$t('panel.analytics:overview:shipping-insights:heading')">
          <CardSection class="flex space-x-3">
            <BarChart
              :datasets="periodicalShipmentNumbersBarChart.datasets"
              :labels="periodicalShipmentNumbersBarChart.labels"
              :show-legend="false"
              class="w-3/5 pt-12"
              height="medium"
              stacked
            />
            <div class="w-2/5">
              <div
                class="grid grid-cols-1 h-full gap-[0.063rem] border-l border-slate-200 divide-y divide-slate-200"
              >
                <InsightsItem
                  :item="{
                    label: $t('panel.analytics:shipping:insights:announced_today'),
                    value: props.shipmentNumbers.announced,
                  }"
                />

                <InsightsItem
                  :item="{
                    label: $t('panel.analytics:shipping:insights:currently_en_route'),
                    value: props.shipmentNumbers.enRoute,
                  }"
                />

                <InsightsItem
                  v-if="deliveryPredictions[0]"
                  :item="{
                    label: $t('panel.analytics:shipping:insights:predicted-deliveries-for') + ' ' + deliveryPredictions[0].label,
                    value: deliveryPredictions[0].range + ' *',
                    badge: {
                      icon: '',
                      color: 'yellow',
                      label: 'BETA'
                    }
                  }"
                />
              </div>

              <div
                v-if="deliveryPredictions[0]"
                class="mx-4 text-xs text-slate-400 italic"
              >
                {{ $t('panel.analytics:shipping:prediction:disclaimer') }}
              </div>
            </div>
          </CardSection>
          <template #footer>
            <div class="flex justify-end p-3">
              <a
                :href="$route('panel.analytics.shipping', dateFilter)"
                class="text-slate-700 hover:underline"
              >
                {{ $t('panel.analytics:overview:shipping-insights:view-more') }} &rarr;
              </a>
            </div>
          </template>
        </Card>
      </div>

      <div class="mt-6">
        <Card :title="$t('panel.analytics:overview:return-reasons-chart:title')">
          <CardSection>
            <HorizontalBarChart
              :data="returnReasons.data"
            />
          </CardSection>
        </Card>
      </div>
    </Page>
  </AppLayout>
</template>
