<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { App, type Types } from '@app/shared/types/generated-v2';
import { Banner, Page } from '@app/panel/Components';
import {
  ExpressModal,
  InfoBlock,
  PrimaryReturnAddress,
  ReturnForm,
  ReturnQuestions,
  ReturnRules,
  Steps,
  TestReturn,
} from '@app/panel/Pages/Onboarding';
import {
  computed,
  ref,
} from 'vue';
import { useOnboarding } from './Onboarding/composables';
import { watch } from 'vue';
import { provide } from 'vue';
import DashboardAnalytics from '@app/panel/Pages/Analytics/partials/DashboardAnalytics.vue';

type OnboardingDashboardViewModel = Types['App.Http.ViewModels.Panel.OnboardingDashboardViewModel'];

const props = defineProps<OnboardingDashboardViewModel>();

const isOnboarding = computed((): boolean => {
  return !Object.values(props.onboardingStatus).every(Boolean);
});

const isBannerCloseClicked = ref<boolean>(false);

function closeBanner(): void {
  isBannerCloseClicked.value = true;
}

const { setOnboardingStatus, isStepChecked } = useOnboarding();

watch(
  () => props.onboardingStatus,
  () => setOnboardingStatus(props.onboardingStatus),
  { immediate: true },
);

provide('attachmentFileSizeLimit', props.attachmentFileSizeLimit);
provide('attachmentFileTypes', props.attachmentFileTypes);
</script>

<template>
  <AppLayout :title="$t('panel.dashboard.page_title')">
    <Page>
      <div
        v-if="$attrs.emailHasBeenVerified && !isBannerCloseClicked"
        class="mb-6"
      >
        <Banner
          closable
          :open="$attrs.emailHasBeenVerified && !isBannerCloseClicked"
          @close="closeBanner"
        >
          {{ $t('panel.dashboard.email_verification_banner') }}
        </Banner>
      </div>

      <div
        v-if="!isOnboarding"
        class="mt-6 mb-12 p-4 sm:p-0"
      >
        <DashboardAnalytics />
      </div>

      <div v-if="isOnboarding">
        <div class="p-4 sm:py-10">
          <InfoBlock :form-locale="formLocale" />
        </div>

        <div class="grid grid-cols-12 mt-10 sm:gap-4 lg:gap-12">
          <div class="col-span-12 lg:col-span-3 max-w-[18rem] p-4">
            <Steps />
          </div>

          <div class="col-span-12 lg:col-span-9">
            <PrimaryReturnAddress
              v-show="isStepChecked(App.Enums.OnboardingStateType.HasReturnAddress)"
              :tenant="tenant"
              :countries="countries"
              :return-address="returnAddress"
            />
            <ReturnForm
              v-if="formLocale"
              v-show="isStepChecked(App.Enums.OnboardingStateType.HasReturnForm)"
              :form-locale="formLocale"
              :form-locale-fallback="formLocaleFallback"
            />
            <ReturnQuestions
              v-show="isStepChecked(App.Enums.OnboardingStateType.HasReturnQuestions)"
              :return-form="returnForm"
              :predefined-return-questions="predefinedReturnQuestions"
              :stored-return-questions="storedReturnQuestions"
              :used-return-questions="usedReturnQuestions"
            />
            <ReturnRules
              v-show="isStepChecked(App.Enums.OnboardingStateType.HasReturnRules)"
              :return-form="returnForm"
              :predefined-return-rules="predefinedReturnRules"
              :stored-return-rules="storedReturnRules"
            />
            <TestReturn
              v-if="returnForm"
              v-show="isStepChecked(App.Enums.OnboardingStateType.HasTestReturn)"
              :form="returnForm"
            />
          </div>
        </div>
      </div>
    </Page>
  </AppLayout>

  <ExpressModal
    :show="!tenant.hasMarketingPopupAnswered && !tenant.isPartner"
    :onboarding-industries="onboardingIndustries"
  />
</template>
