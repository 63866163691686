<script setup lang="ts">
import {
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Link,
} from '@returnless/focus-ui';
import { useI18n } from 'vue-i18n';
import { useDependencyWarning } from '@app/panel/Containers/DependencyWarning';
import { onUnmounted } from 'vue';

const { t } = useI18n();

const { dependencyWarning, closeDependencyWarning } = useDependencyWarning();

onUnmounted(() => {
  closeDependencyWarning();
});
</script>

<template>
  <AlertDialog
    :open="dependencyWarning !== null"
    class="z-[55]"
  >
    <AlertDialogContent v-if="dependencyWarning">
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ dependencyWarning.title }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {{ dependencyWarning.description }}
        </AlertDialogDescription>
        <ul>
          <li
            v-for="dependency in dependencyWarning.dependencies"
            :key="dependency.label"
          >
            <Link :href="dependency.link">
              {{ dependency.label }}
            </Link>
          </li>
        </ul>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogActionButton @click="closeDependencyWarning">
          {{ t('panel.global:actions:close') }}
        </AlertDialogActionButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
