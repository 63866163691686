<script setup lang="ts">
import { Button, ButtonType, ButtonVariant, Form, FormLayout, Modal, Stack, TextInput } from '@app/panel/Components';
import { inject, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { App } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  open: boolean;
  selected: {
    id: string;
    key: string;
    value: string | number | undefined;
  };
}>();

const form = useForm({
  id: props.selected?.id,
  key: props.selected?.key,
  value: props.selected?.value,
});

watch(() => props.selected, (newValue) => {
  if (newValue) {
    form.id = newValue.id;
    form.key = newValue.key;
    form.value = newValue.value;
  }
});

const emit = defineEmits<{
  close: [];
}>();

const close = () => {
  form.reset();
  emit('close');
};

const returnOrder = inject<App.Models.ViewModels.ReturnOrderViewModel>('returnOrder');

const submit = async () => {
  form.put(route('panel.return-orders.metadata.edit', {
    returnOrder: returnOrder.value.id,
  }), {
    onSuccess: close,
    preserveScroll: true,
  });
};
</script>

<template>
  <Modal
    :open="open"
    :title="t('panel.returns:detail:edit_metadata_modal:modal_title')"
    @close="close"
  >
    <Form
      @submit.prevent="submit"
    >
      <Stack vertical>
        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <TextInput
              v-model="form.key"
              :error="form.errors.key"
              :label="t('panel.returns:detail:metadata:manage:key_field')"
              :max-length="255"
              disabled
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.value"
              :error="form.errors.value"
              :label="t('panel.returns:detail:metadata:manage:value_field')"
              :max-length="255"
            />
          </FormLayout>
        </FormLayout>
      </Stack>
    </Form>

    <template #actions>
      <Button @click="close">
        {{ t('panel.global:actions:cancel') }}
      </Button>
      <Button
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
        @click="submit"
      >
        {{ t('panel.returns:detail:metadata:manage:edit') }}
      </Button>
    </template>
  </Modal>
</template>
