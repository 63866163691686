export enum FormStructureFieldType {
  Checkbox = 'checkbox',
  Group = 'group',
  MultiSelect = 'multiSelect',
  Password = 'password',
  Repeater = 'repeater',
  Layout = 'layout',
  Select = 'select',
  String = 'string',
}

export interface FormStructureField {
  default: string[] | string;
  fields?: FormStructureField[];
  flip_column_name?: boolean;
  help_text?: string;
  key: string;
  label: string;
  options?: Record<string, string> | string;
  placeholder?: string;
  secondary_options?: Record<string, string>;
  title?: string;
  type: FormStructureFieldType;
  validation: string[];
}

export interface FormStructure {
  fields: FormStructureField[];
  key: string;
  title: string;
}
