import { router } from '@inertiajs/vue3';
import { BarcodeScanner } from './BarcodeScanner.mjs';
import { onMounted, onUnmounted } from 'vue';

/**
 * Start listening to barcode reader input events.
 *
 * @see https://www.notion.so/returnless/Barcode-scanning-e552e157d6ef4dd1817787ff8d430645
 */
export default function UseBarcodeScanner() {
  const barcodeScanner = new BarcodeScanner({
    visitHandle(barcode: string) {
      router.get(route('panel.barcode-lookup'), {
        barcode,
      });
    },
  });

  onMounted(() => {
    barcodeScanner.startListening();
  });

  onUnmounted(() => {
    barcodeScanner.stopListening();
  });
}
