<script lang="ts" setup>
import {
  ActionList,
  ActionListItem,
  Popover,
  PopoverPlacement,
  Spinner,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/vue/24/outline';
import { computed, ref } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';
import { useApi } from '@app/panel/Composables/useHttp';

type NoteTemplateViewModel = Types['App.Models.ViewModels.NoteTemplateViewModel'];
type ReturnOrderViewModel = Types['App.Models.ViewModels.ReturnOrderViewModel'];

const props = defineProps<{
  returnOrder: ReturnOrderViewModel;
  noteTemplates: NoteTemplateViewModel[];
}>();

const emit = defineEmits<{
  (e: 'attach', template: string): void;
}>();

const loading = ref<boolean>(false);
const isModalOpen = ref<boolean>(false);

function togglePopover(): void {
  isModalOpen.value = !isModalOpen.value;
}

function closePopover(): void {
  isModalOpen.value = false;
}

function getNoteTemplate(id: string): Promise<string> {
  loading.value = true;

  return useApi()
    .get(route('api.v1.note-templates.convert', { noteTemplate: id, return_order_id: props.returnOrder.id }))
    .then((response) => response.data.data)
    .finally(() => loading.value = false);
}

async function handleTemplateAttachment(id: string): Promise<void> {
  const template = await getNoteTemplate(id);

  emit('attach', template);

  closePopover();
}

const templateItems = computed<ActionListItem[]>(() => {
  return props.noteTemplates.map((template) => ({
    content: template.title,
    onClick: () => handleTemplateAttachment(template.id),
  }));
});

const noteReplies = ref<HTMLElement>(null);
</script>

<template>
  <div>
    <div
      ref="noteReplies"
      tabindex="0"
      class="outline-none"
      @keyup.esc="closePopover"
    >
      <Spinner v-if="loading" />
      <ChatBubbleLeftEllipsisIcon
        v-if="! loading"
        class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
        @click="togglePopover"
      />
    </div>

    <Popover
      :placement="PopoverPlacement.TopStart"
      :active="isModalOpen"
      :parent="noteReplies"
      @close="closePopover"
    >
      <ActionList
        v-if="templateItems.length > 0"
        :items="templateItems"
      />

      <TextContainer
        v-else
        text-center
      >
        <TextStyle
          no-wrap
          :shade="TextStyleShade.Subdued"
        >
          <span>{{ $t('panel.containers:notes-section:no-templates') }}</span>
        </TextStyle>
      </TextContainer>
    </Popover>
  </div>
</template>
