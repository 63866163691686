import { App } from '@app/shared/types/generated-v2';
import { readonly, ref, watch } from 'vue';

type OnboardingStatus = Record<App.Enums.OnboardingStateType, boolean>;

const onboardingStatus = ref<Omit<OnboardingStatus, 'hasIntegrations'>>({
  hasReturnAddress: false,
  hasReturnForm: false,
  hasReturnQuestions: false,
  hasReturnRules: false,
  hasTestReturn: false,
});

const checkedStep = ref<App.Enums.OnboardingStateType | null>(null);
const lastCompletedStep = ref<App.Enums.OnboardingStateType | null>(null);

export function useOnboarding() {
  function getFirstUncompletedStep(): App.Enums.OnboardingStateType {
    const onboardingStatusKeys = Object.keys(onboardingStatus.value);
    const uncompletedStep = onboardingStatusKeys.find((key) => !onboardingStatus.value[key]);
    return uncompletedStep as App.Enums.OnboardingStateType || null;
  }

  function changeStep(step: App.Enums.OnboardingStateType): void {
    checkedStep.value = step;
  }

  function isStepChecked(step: App.Enums.OnboardingStateType): boolean {
    return checkedStep.value === step;
  }

  function isCompleted(state: App.Enums.OnboardingStateType): boolean {
    return onboardingStatus.value[state];
  }

  function isStepClickable(onboardingStatusKey: App.Enums.OnboardingStateType) {
    return isCompleted(onboardingStatusKey) || getFirstUncompletedStep() === onboardingStatusKey;
  }

  function setLastCompletedStep(value: App.Enums.OnboardingStateType): void {
    lastCompletedStep.value = value;
  }

  function setOnboardingStatus(value: typeof onboardingStatus.value): void {
    onboardingStatus.value = value;
  }

  watch(onboardingStatus, () => checkedStep.value = getFirstUncompletedStep());

  return {
    changeStep,
    checkedStep: readonly(checkedStep),
    isStepChecked,
    isStepClickable,
    lastCompletedStep: readonly(lastCompletedStep),
    onboardingStatus: readonly(onboardingStatus),
    setLastCompletedStep,
    setOnboardingStatus,
  };
}
