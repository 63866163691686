<script setup lang="ts">
import { InertiaForm } from '@inertiajs/vue3';
import { FieldRenderer } from '.';
import { inject } from 'vue';

interface Field {
  key: string;
  label: string;
  fields: Array<Field>;
  options: Record<string, string>;
  placeholder: string;
  validation: Array<string>;
  default: string;
  help_text: string;
  type: string;
  title: string;
}

defineProps<{
  fields: Array<Field>;
}>();

const form = inject<InertiaForm<object>>('form');

function getBlockErrors(blockName: string): null | Record<string, string> {
  const scopedErrors = Object.keys(form.errors)
    .filter((key: string) => key.startsWith(blockName))
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: form.errors[key],
      };
    }, {});

  if (Object.keys(scopedErrors).length === 0) {
    return null;
  }

  return scopedErrors;
}
</script>

<template>
  <div
    v-for="(field) in fields"
    :key="field.key"
  >
    <div
      v-if="Array.isArray(field)"
      class="flex items-center space-x-2"
    >
      <div
        v-for="(fieldItem) in field"
        :key="fieldItem.key"
        :class="{ 'flex-1': fieldItem.fullWidth === true }"
      >
        <FieldRenderer
          :field-name="fieldItem.key"
          :fields="fieldItem.fields"
          :label="fieldItem.label"
          :options="fieldItem.options"
          :placeholder="fieldItem.placeholder"
          :validation="fieldItem.validation"
          :default="fieldItem.default"
          :help-text="fieldItem.help_text || null"
          :type="fieldItem.type"
          :title="fieldItem.title"
          :errors="getBlockErrors(fieldItem.key)"
        />
      </div>
    </div>

    <div v-else>
      <div>
        <FieldRenderer
          :field-name="field.key"
          :fields="field.fields"
          :label="field.label"
          :options="field.options"
          :placeholder="field.placeholder"
          :validation="field.validation"
          :default="field.default"
          :help-text="field.help_text || null"
          :type="field.type"
          :title="field.title"
          :errors="getBlockErrors(field.key)"
        />
      </div>
    </div>
  </div>
</template>
