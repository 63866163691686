/**
 * This class handles barcode input from a barcode scanner.
 * Used in both the main app and the Chrome extension:
 * https://github.com/Returnless-com/returnless-chrome-barcode-scanner-client/blob/main/content.js
 * Keep both implementations in sync.
 */
export class BarcodeScanner {
  /**
   * @typedef {{ visitHandle: (barcode: string) => void }} BarcodeScannerOptions
   */

  /** @private @const {number} */
  INTERVAL_PER_CHARACTER = 20;

  /** @private @const {number} */
  MINIMAL_CHARACTERS = 8;

  /** @private @type {string} */
  barcode = '';

  /** @private @type {?number} */
  timeoutId = null;

  /** @private @type {BarcodeScannerOptions} */
  options;

  /**
   * @param {BarcodeScannerOptions} options - Configuration options.
   */
  constructor(options) {
    this.options = options;
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  /** @public */
  startListening() {
    window.addEventListener('keypress', this.onKeyPress);
  }

  /** @public */
  stopListening() {
    window.removeEventListener('keypress', this.onKeyPress);
  }

  /**
   * Handles keypress events.
   * @private
   * @param {KeyboardEvent} event
   */
  onKeyPress(event) {
    if (event.key === 'Enter' && this.barcode.length >= this.MINIMAL_CHARACTERS) {
      this.options.visitHandle(this.barcode);
      this.barcode = '';
      this.timeoutId = null;
    }

    if (this.timeoutId !== null) {
      window.clearTimeout(this.timeoutId);
    }

    if (event.key.length === 1 && !event.ctrlKey && !event.metaKey && !event.altKey) {
      this.barcode += event.key;
    }

    this.timeoutId = window.setTimeout(() => {
      this.barcode = '';
      this.timeoutId = null;
    }, this.INTERVAL_PER_CHARACTER);
  }
}
