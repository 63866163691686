<script lang="ts" setup>
import { inject } from 'vue';
import {
  Badge,
  BadgeRounded,
  Link,
  TextStyle,
} from '../../';
import { ChevronRightIcon } from '@heroicons/vue/24/outline';
import { StatusBadge } from '../../../types';
import { type Tag } from '../types';

withDefaults(defineProps<{
  id: string | number | null;
  label: string;
  description?: string | null;
  tags?: Tag[];
  badges?: StatusBadge[];
  showNavigationIcon?: boolean;
}>(), {
  description: null,
  tags: () => ([]),
  badges: () => ([]),
  showNavigationIcon: true,
});

const draggable = inject('draggable', true);

function makeAreaUnClickable(e: MouseEvent): void {
  e.preventDefault();

  e.stopPropagation();
}
</script>

<template>
  <tr
    :id="id"
    class="group flex cursor-pointer text-sm hover:bg-slate-50"
  >
    <td
      v-if="draggable"
      class="flex cursor-auto items-center justify-center py-4 pl-4"
      @click.capture="makeAreaUnClickable"
    >
      <svg
        id="draggable-icon"
        xmlns="http://www.w3.org/2000/svg"
        class="mr-2 size-5 text-slate-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
        />
      </svg>
    </td>

    <td
      class="flex-1 px-2 py-4"
      :class="{ 'pl-4': ! draggable }"
    >
      <div class="flex flex-col space-y-2">
        <div class="flex space-x-4">
          <Link class="pointer-events-none">
            {{ label }}
          </Link>

          <div
            v-if="!!tags"
            class="flex flex-wrap gap-2"
          >
            <Badge
              v-for="tag in tags"
              :key="tag.id"
              :color="tag.color"
            >
              {{ tag.name }}
            </Badge>
          </div>
        </div>

        <TextStyle v-if="description">
          {{ description }}
        </TextStyle>
      </div>
    </td>

    <td class="flex items-center justify-center space-x-4 py-4 pr-4">
      <Badge
        v-for="(badge, badgeIndex) in badges"
        :key="badgeIndex"
        :color="badge.color"
        :rounded="BadgeRounded.Full"
      >
        {{ badge.label }}
      </Badge>

      <ChevronRightIcon
        v-if="showNavigationIcon"
        class="size-5 text-slate-400 group-hover:text-slate-500"
      />
    </td>
  </tr>
</template>
