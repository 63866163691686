<script lang="ts" setup>
import { computed } from 'vue';
import {
  Badge,
  BadgeRounded,
  BadgeSize,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { get, isArray, isEmpty } from 'lodash';
import * as Types from '@app/panel/types/generated';

type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;

const props = defineProps<{
  filterForm: any;
  resetFilter: Function;
  formShippingMethodsFilterViewModel: FormShippingMethodsViewModel['formShippingMethodsFilterViewModel'];
}>();

const { t } = useI18n();

const items = [
  {
    label: t('panel.forms:shipping-methods:filters:fields:from-country'),
    filterFormPath: 'filter.from_country',
    options: 'formShippingMethodsFilterViewModel.fromCountries',
    path: 'name',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:to-country'),
    filterFormPath: 'filter.to_country',
    options: 'formShippingMethodsFilterViewModel.toCountries',
    path: 'name',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:min-weight-from'),
    filterFormPath: 'filter.min_weight.from',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:min-weight-to'),
    filterFormPath: 'filter.min_weight.to',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-weight-from'),
    filterFormPath: 'filter.max_weight.from',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-weight-to'),
    filterFormPath: 'filter.max_weight.to',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:price-from'),
    filterFormPath: 'filter.price.from',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:price-to'),
    filterFormPath: 'filter.price.to',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:type'),
    filterFormPath: 'filter.type',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-width-from'),
    filterFormPath: 'filter.max_width.from',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-width-to'),
    filterFormPath: 'filter.max_width.to',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-height-from'),
    filterFormPath: 'filter.max_height.from',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-height-to'),
    filterFormPath: 'filter.max_height.to',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-length-from'),
    filterFormPath: 'filter.max_length.from',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:max-length-to'),
    filterFormPath: 'filter.max_length.to',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:is-printerless'),
    filterFormPath: 'filter.is_printerless',
    type: 'boolean',
  },
  {
    label: t('panel.forms:shipping-methods:filters:fields:is-active'),
    filterFormPath: 'filter.is_active',
    type: 'boolean',
  },
];

const filters = computed(() => {
  return items.map((item) => {
    let filterValue = get(props.filterForm, item.filterFormPath);

    if (item.options) {
      if (isArray(filterValue)) {
        const data = [];

        filterValue.forEach((value) => {
          const selectedOption = props[item.options].find((option) => {
            return option.id === value || option.value === value;
          });

          data.push(get(selectedOption, item.path));
        });

        return {
          ...item,
          value: data,
        };
      }

      const selectedOption = get(props, item.options).find((option) => {
        return option.id === filterValue || option.value === filterValue;
      });

      return {
        ...item,
        value: get(selectedOption, item.path),
      };
    }

    return {
      ...item,
      value: filterValue,
    };
  });
});

const activeFilters = computed(() => {
  return filters.value.filter((item) => {
    return !isEmpty(item.value);
  });
});
</script>

<template>
  <div
    v-if="activeFilters.length > 0"
    class="flex flex-wrap flex-items-center gap-2 mt-6"
  >
    <Badge
      v-for="(filter, index) in activeFilters"
      :key="index"
      color="blue"
      :rounded="BadgeRounded.Full"
      closeable
      :size="BadgeSize.Large"
      @close="() => resetFilter(filter.filterFormPath)"
    >
      <span v-if="isArray(filter.value)">
        {{ filter.label }}: {{ filter.value.join(', ') }}
      </span>

      <span v-else-if="filter.type === 'boolean'">
        <span v-if="filter.value == true">{{ filter.label }}: {{ $t('panel.forms:shipping-methods:filters:fields:boolean:yes') }}</span>
        <span v-else>{{ filter.label }}: {{ $t('panel.forms:shipping-methods:filters:fields:boolean:no') }}</span>
      </span>

      <span v-else-if="filter.label === 'Value from' || filter.label === 'Value to'">
        {{ filter.label }} € {{ filter.value }}
      </span>

      <span v-else-if="filter.label === 'Price from' || filter.label === 'Price to'">
        {{ filter.label }} € {{ filter.value }}
      </span>

      <span v-else>{{ filter.label }}: {{ filter.value }}</span>
    </Badge>
  </div>
</template>
