<script lang="ts" setup>
import {
  Select,
  SelectOption,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { Autocomplete, AutocompleteItem } from '@returnless/focus-ui';
import { Country } from '@app/panel/types';
import * as Types from '@app/panel/types/generated';
import type { InertiaForm } from '@inertiajs/vue3';
import type { ReturnForm } from '../index';
import { isArray, isNull, map } from 'lodash';
import { ConditionSubOperator } from '@app/shared/lib/ConditionEngine';
import { computed, inject } from 'vue';
import { PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { App } from '@app/shared/types/generated-v2';
import { useApi } from '@app/panel/Composables/useHttp';
import { useDebounceAutocompleteItems } from '@app/panel/Composables/useDebounceAutocompleteItems';

type ReturnConditionType = Types.App.Enums.ReturnConditionType;
type ReturnReasonViewModel = Types.App.Models.ViewModels.ReturnReasonViewModel;
type TagViewModel = Types.App.Models.ViewModels.TagViewModel;
type SelectOptionViewModel = Types.App.Models.ViewModels.SelectOptionViewModel;

const props = withDefaults(defineProps<{
  index?: number | null;
  conditionId: string;
  conditionTypeMap: Record<string, ReturnConditionType>;
  returnReasons: ReturnReasonViewModel[];
  returnAddressesOptions: SelectOptionViewModel[];
  returnStatusesOptions: SelectOptionViewModel[];
  requestStatusesOptions: SelectOptionViewModel[];
  returnQuestions: Record<string, SelectOption>;
  tags: TagViewModel[];
}>(), {
  index: null,
});

const returnForm = inject<InertiaForm<ReturnForm>>('returnForm')!;

const returnQuestions = inject<Array<{
  id: string;
  label: string;
  options: unknown[];
}>>('returnQuestions')!;

const countries = inject<Country[]>('countries');

const condition = computed(() => returnForm.conditions[props.conditionId]);

const fieldType = computed<ReturnConditionType>(() => {
  const input = condition.value.input;
  return props.conditionTypeMap[input];
});

const modelValue = computed(() => {
  if (!isNull(props.index)) {
    return returnForm.conditions[props.conditionId].value[props.index];
  }

  return returnForm.conditions[props.conditionId]?.value ?? null;
});

const subSelectionModelValue = computed(() => {
  if (!isNull(props.index)) {
    return returnForm.conditions[props.conditionId].subSelection[props.index];
  }
  return returnForm.conditions[props.conditionId].subSelection;
});

const countryOptions = computed((): SelectOption[] => {
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

// TODO: Fetch these values from the backend
const currencyOptions = [
  { value: 'EUR', label: 'Euro' },
  { value: 'USD', label: 'US Dollar' },
];

const returnReasonOptions = computed(() => {
  return props.returnReasons.map((returnReason) => {
    return { value: returnReason.id, label: returnReason.label };
  });
});

const tagsOptions = computed(() => {
  return props.tags.map((tag) => {
    return { value: tag.id, label: tag.name };
  });
});

const returnQuestionOptions = computed(() => {
  return map(props.returnQuestions, (returnQuestion, id) => {
    return { value: id, label: returnQuestion.label };
  });
});

const returnQuestionAnswerOptions = computed(() => {
  return Object.values(returnForm.conditions).reduce((acc, condition) => {
    const options = props.returnQuestions[condition.value]?.options;
    if (options) {
      acc[condition.id] = map(options, (label, value) => {
        return { label, value };
      });
    }
    return acc;
  }, {});
});

const isOnlyValueInput = computed(() => {
  if (!isArray(returnForm.conditions[condition.value.id]?.value)) {
    return true;
  }

  return returnForm.conditions[condition.value.id].value.length === 1;
});

const multiInput = computed<boolean>(() => {
  return !isNull(props.index);
});

const canHaveSubOperations = computed(() => {
  return Object.keys(ConditionSubOperator).includes(condition.value.comparator);
});

const isLastValueInput = computed<boolean>(() => {
  if (!isArray(returnForm.conditions[condition.value.id]?.value)) {
    return true;
  }

  return returnForm.conditions[condition.value.id].value.length - 1 === props.index;
});

function removeConditionValue(id: string, key: string, index: number): void {
  returnForm.conditions[id][key].splice(index, 1);
}

function extractConditionInput(id: string, index: number): void {
  removeConditionValue(id, 'value', index);

  if ('subSelection' in returnForm.conditions[id]) {
    removeConditionValue(id, 'subSelection', index);
  }
}

function addNewConditionValue(id: string, key: string): void {
  const oldValue = returnForm.conditions[id][key]
    ? returnForm.conditions[id][key]
    : '';
  if (!isArray(returnForm.conditions[id][key])) {
    returnForm.conditions[id][key] = [oldValue as string, ''];
  } else {
    returnForm.conditions[id][key] = [...oldValue, ''];
  }
}

function insertConditionInput(id: string): void {
  addNewConditionValue(id, 'value');

  const hasSubselection = fieldType.value === 'question';
  if (hasSubselection) {
    addNewConditionValue(id, 'subSelection');
  }
}

const returnQuestionMultiAnswerOptions = computed(() => {
  return Object.values(returnForm.conditions).reduce((acc, condition) => {
    if (!isArray(condition.value) || !('subSelection' in condition)) {
      return acc;
    }

    acc[condition.id] = condition.value.map((item) => {
      const options = returnQuestions[item]?.options;

      if (options) {
        return map(options, (label, value) => {
          return { label, value };
        });
      }
      return [];
    });
    return acc;
  }, {});
});

function getSubselectionOptions(id: string, index: number): string[] {
  if (returnQuestionMultiAnswerOptions.value[id]) {
    return returnQuestionMultiAnswerOptions.value[id][index];
  }
  return [];
}

function updateSubSelection(subSelection): void {
  if (!isNull(props.index)) {
    (returnForm.conditions[props.conditionId].subSelection as string[])[props.index] = subSelection;
    return;
  }
  returnForm.conditions[props.conditionId].subSelection = subSelection;
}

function updateValue(value): void {
  if (!isNull(props.index)) {
    (returnForm.conditions[props.conditionId].value as string[])[props.index] = value;

    return;
  }

  returnForm.conditions[props.conditionId].value = value;
}

const form = inject('form');

const { autocompleteItems } = useDebounceAutocompleteItems<string>(modelValue, async (searchQuery: string) => {
  const autocompleteApiUrl = route('api.v1.form.condition.autocomplete', {
    form: form.id,
    conditionField: returnForm.conditions[props.conditionId].input,
  });

  const response = await useApi().get(autocompleteApiUrl, {
    params: {
      search: searchQuery,
    },
  });

  autocompleteItems.value = response.data;
});

function onAutoCompleteSelect(value: string): void {
  updateValue(value);
  autocompleteItems.value = [];
}

</script>

<template>
  <div class="w-full">
    <div v-if="fieldType === App.Enums.ReturnConditionType.TEXT || fieldType === App.Enums.ReturnConditionType.META_DATA">
      <TextInput
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        show-tooltip-error
        :model-value="modelValue"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.AUTO_COMPLETE">
      <Autocomplete
        :model-value="modelValue"
        :placeholder="$t('panel.settings:form:conditions:modal:autocomplete:label')"
        label-hidden
        @update:model-value="updateValue"
      >
        <AutocompleteItem
          v-for="autocompleteItem in autocompleteItems"
          :key="autocompleteItem"
          @click="onAutoCompleteSelect(autocompleteItem)"
        >
          {{ autocompleteItem }}
        </AutocompleteItem>
      </Autocomplete>
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.DATE_TIME">
      <TextInput
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        show-tooltip-error
        :model-value="modelValue"
        :type="TextInputFieldTypes.Date"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.CURRENCY_SELECT">
      <Select
        :id="`qurrency_select_${conditionId}`"
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="currencyOptions"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.COUNTRY_SELECT">
      <Select
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="countryOptions"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.PRICE">
      <TextInput
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        show-tooltip-error
        :model-value="modelValue"
        :min="0"
        :type="TextInputFieldTypes.Number"
        allow-decimal
        @update:model-value="updateValue"
      >
        <template #addon>
          &euro;
        </template>
      </TextInput>
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.WEIGHT">
      <TextInput
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        show-tooltip-error
        :model-value="modelValue"
        :min="0"
        :type="TextInputFieldTypes.Number"
        allow-decimal
        @update:model-value="updateValue"
      >
        <template #addon>
          <div>
            &#103;
          </div>
        </template>
      </TextInput>
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.DIMENSION">
      <TextInput
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        show-tooltip-error
        :min="0"
        :type="TextInputFieldTypes.Number"
        :model-value="modelValue"
        allow-decimal
        @update:model-value="updateValue"
      >
        <template #addon>
          &#13213;
        </template>
      </TextInput>
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.NUMBER">
      <TextInput
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        show-tooltip-error
        :model-value="modelValue"
        :min="0"
        :type="TextInputFieldTypes.Number"
        allow-decimal
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.RETURN_ADDRESS_SELECT">
      <Select
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="returnAddressesOptions"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.RETURN_REASON">
      <Select
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="returnReasonOptions"
        @update:model-value="updateValue"
      />
    </div>

    <div
      v-if="fieldType === App.Enums.ReturnConditionType.QUESTION"
      class="flex w-full space-x-4"
    >
      <div class="flex-1">
        <Select
          show-tooltip-error
          :error="returnForm.errors[`conditions.${conditionId}.value`]"
          :options="returnQuestionOptions"
          :model-value="modelValue"
          @update:model-value="updateValue"
        />
      </div>

      <div class="flex-1">
        <Select
          show-tooltip-error
          :error="returnForm.errors[`conditions.${conditionId}.value`]"
          :model-value="subSelectionModelValue"
          :options="multiInput
            ? getSubselectionOptions(condition.id, index)
            : returnQuestionAnswerOptions[condition.id]"
          @update:model-value="updateSubSelection"
        />
      </div>
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.RETURN_STATUS">
      <Select
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="returnStatusesOptions"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.REQUEST_STATUS">
      <Select
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="requestStatusesOptions"
        @update:model-value="updateValue"
      />
    </div>

    <div v-if="fieldType === App.Enums.ReturnConditionType.TAGS">
      <Select
        show-tooltip-error
        :error="returnForm.errors[`conditions.${conditionId}.value`]"
        :model-value="modelValue"
        :options="tagsOptions"
        @update:model-value="updateValue"
      />
    </div>
  </div>

  <div
    v-if="canHaveSubOperations && isLastValueInput"
    data-testid="insert-condition"
    class="flex items-center space-x-1.5"
  >
    <TrashIcon
      v-if="! isOnlyValueInput"
      class="z-10 size-4 cursor-pointer text-slate-500 hover:text-slate-600"
      @click="() => extractConditionInput(condition.id, index)"
    />

    <div
      class="flex cursor-pointer items-center justify-center text-slate-500 hover:text-slate-600"
      @click="() => insertConditionInput(condition.id)"
    >
      <PlusIcon class="size-4" />
    </div>
  </div>

  <div
    v-if="multiInput && ! isLastValueInput"
    data-testid="remove-condition"
    class="flex flex-1 justify-center"
    @click="() => extractConditionInput(condition.id, index)"
  >
    <TrashIcon
      class="z-10 size-4 cursor-pointer text-slate-500 hover:text-slate-600"
    />
  </div>
</template>
