<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Form,
  FormLayout,
  Modal,
  ModalSize,
  Select,
  TextInput,
  TextInputIconPosition,
} from '@app/panel/Components';
import { FilterTabs } from '@app/panel/Containers';
import { FooterActionPosition } from '@app/panel/Components/Card/types';
import { computed, ref } from 'vue';
import * as Types from '@app/panel/types/generated';
import { App } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';

type FilterTabViewModel = Types.App.Models.ViewModels.FilterTabViewModel;
type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;

const props = defineProps<{
  hasActiveFilter: boolean;
  filterTabs: FilterTabViewModel[];
  filterForm: any;
  formShippingMethodsFilterViewModel: FormShippingMethodsViewModel['formShippingMethodsFilterViewModel'];
}>();

const emit = defineEmits<{
  change: [];
}>();

const { t } = useI18n();

const saveFilterModalOpen = ref(false);

const saveFilterForm = useForm({
  label: null,
  filters: null,
  type: 'shipping_method',
});

const onFilterChange = (): void => {
  emit('change');
};

function openSaveFilterModal(): void {
  saveFilterModalOpen.value = true;
}

function closeSaveFilterModal(): void {
  saveFilterModalOpen.value = false;

  saveFilterForm.reset();
}

function submitSaveFilter(): void {
  saveFilterForm.filters = window.location.search;
  saveFilterForm.post(route('panel.settings.filter-tabs.store'), {
    onSuccess: () => closeSaveFilterModal(),
  });
}

const manageFilterModalOpen = ref(false);

function openManageFiltersModal(): void {
  manageFilterModalOpen.value = true;
}

function closeManageFiltersModal(): void {
  manageFilterModalOpen.value = false;
}

const fromCountryOptions = computed(() => {
  return props.formShippingMethodsFilterViewModel.fromCountries.map((country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });
});

const toCountryOptions = computed(() => {
  return props.formShippingMethodsFilterViewModel.toCountries.map((country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });
});

const shippingMethodTypeOptions = computed(() => {
  return props.formShippingMethodsFilterViewModel.shippingMethodTypes.map((shippingMethodType) => {
    return {
      label: t(`panel.shipping-method-type:${shippingMethodType}`),
      value: shippingMethodType,
    };
  });
});

const booleanOptions = computed(() => {
  return [
    { label: t('panel.forms:shipping-methods:filters:fields:boolean:yes'), value: 1 },
    { label: t('panel.forms:shipping-methods:filters:fields:boolean:no'), value: 0 },
  ];
});
</script>

<template>
  <Card
    :secondary-footer-actions="[
      {
        content: 'Save current filter',
        disabled: ! hasActiveFilter,
        callback: () => openSaveFilterModal(),
      },
      {
        content: 'Manage filters',
        disabled: !filterTabs || filterTabs.length === 0,
        callback: () => openManageFiltersModal(),
      },
    ]"
    :footer-action-position="FooterActionPosition.Left"
  >
    <CardSection>
      <FormLayout>
        <FormLayout :columns="2">
          <FormLayout>
            <Select
              v-model="filterForm.filter.from_country"
              :label="$t('panel.forms:shipping-methods:filters:fields:from-country')"
              :options="fromCountryOptions"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.to_country"
              :label="$t('panel.forms:shipping-methods:filters:fields:to-country')"
              :options="toCountryOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="4">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.min_weight.from"
              :label="$t('panel.forms:shipping-methods:filters:fields:min-weight-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.min_weight.to"
              :label="$t('panel.forms:shipping-methods:filters:fields:min-weight-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_weight.from"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-weight-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_weight.to"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-weight-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="2">
          <FormLayout :columns="2">
            <FormLayout>
              <TextInput
                v-model="filterForm.filter.price.from"
                :label="$t('panel.forms:shipping-methods:filters:fields:price-from')"
                type="number"
                :icon-position="TextInputIconPosition.Left"
                @change="onFilterChange"
              >
                <template #icon>
                  €
                </template>
              </TextInput>
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="filterForm.filter.price.to"
                :label="$t('panel.forms:shipping-methods:filters:fields:price-to')"
                type="number"
                :icon-position="TextInputIconPosition.Left"
                @change="onFilterChange"
              >
                <template #icon>
                  €
                </template>
              </TextInput>
            </FormLayout>
          </FormLayout>

          <FormLayout>
            <Select
              v-model="filterForm.filter.type"
              :label="$t('panel.forms:shipping-methods:filters:fields:type')"
              :options="shippingMethodTypeOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="4">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_width.from"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-width-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_width.to"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-width-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_height.from"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-height-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_height.to"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-height-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout columns="4">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_length.from"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-length-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.max_length.to"
              :label="$t('panel.forms:shipping-methods:filters:fields:max-length-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <FormLayout>
              <Select
                v-model="filterForm.filter.is_printerless"
                :label="$t('panel.forms:shipping-methods:filters:fields:is-printerless')"
                :options="booleanOptions"
                @change="onFilterChange"
              />
            </FormLayout>
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.is_active"
              :label="$t('panel.forms:shipping-methods:filters:fields:is-active')"
              :options="booleanOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </CardSection>
  </Card>

  <Modal
    :size="ModalSize.Small"
    :open="saveFilterModalOpen"
    @close="closeSaveFilterModal"
  >
    <Form @submit="submitSaveFilter">
      <FormLayout>
        <TextInput
          v-model="saveFilterForm.label"
          :error="saveFilterForm.errors.label"
          :label="$t('panel.returns.filters.save_filter_modal.text_input_label')"
          :max-length="30"
        />

        <Button
          :disabled="! saveFilterForm.isDirty"
          :type="ButtonType.Submit"
          :variant="ButtonVariant.Primary"
        >
          {{ $t('panel.returns.filters.save_filter_modal.action') }}
        </Button>
      </FormLayout>
    </Form>
  </Modal>

  <FilterTabs
    :open="manageFilterModalOpen"
    :filter-tabs="filterTabs"
    :type="App.Enums.FilterTabType.SHIPPING_METHOD"
    @close="closeManageFiltersModal"
  />
</template>
