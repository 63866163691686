<script lang="ts" setup>
import { Banner, BannerVariant } from '@app/panel/Components';
import { FieldRenderer } from '@app/panel/Pages/Settings/Integrations/partials';
import { FormStructure, FormStructureField } from '@app/panel/Pages/Settings/Integrations/types';
import { InertiaForm } from '@inertiajs/vue3';
import { inject, onMounted } from 'vue';

const props = withDefaults(defineProps<{
  modelValue: object[] | null;
  fields: FormStructureField[];
  error?: string;
  fieldName?: string;
  title?: string;
}>(), {
  modelValue: () => ([]),
  error: null,
  fieldName: null,
  title: null,
});

const form = inject<InertiaForm<FormStructure>>('form');

const emit = defineEmits(['update:modelValue']);

function scaffoldInitialValue() {
  return props.fields.reduce((accumulator, field) => {
    accumulator[field.key] = form[`${props.fieldName}.${field.key}`] ?? false;
    return accumulator;
  }, {});
}

onMounted(() => {
  emit('update:modelValue', scaffoldInitialValue());
});
</script>

<template>
  <div>
    <span
      v-if="title"
      class="block w-fit text-sm font-medium text-slate-700 mb-1"
    >
      {{ title }}
    </span>

    <div
      v-if="error"
      class="mt-2 mb-3"
    >
      <Banner :variant="BannerVariant.Critical">
        {{ error }}
      </Banner>
    </div>

    <div class="space-y-3">
      <template
        v-for="field in fields"
        :key="field.key"
      >
        <FieldRenderer
          :validation="field.validation"
          :field-name="`${fieldName}[${field.key}]`"
          :label="field.label"
          :type="field.type"
          :placeholder="field.placeholder"
          :fields="field.fields"
          :options="field.options"
        />
      </template>
    </div>
  </div>
</template>
