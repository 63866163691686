<script lang="ts" setup>
import { computed, ref, toRef, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { provide } from 'vue';
import * as Types from '@app/panel/types/generated';
import { AppLayout } from '@app/panel/Layouts';
import {
  Banner,
  BannerVariant,
  ButtonType,
  Checkbox,
  Form,
  FormLayout,
  HelpText,
  InputLabel,
  Link,
  Modal,
  Page,
  Select,
  SelectOption,
  Stack,
  StackItem,
  StackSpacing,
  Textarea,
  TextContainer,
  TextInput,
  TextInputFieldTypes,
  Toggle,
} from '@app/panel/Components';
import { Button, Card, CardDescription, CardFooter, CardHeader, CardSection, CardTitle } from '@returnless/focus-ui';
import { TranslationInputFields, TranslationInputFieldType } from '@app/panel/Containers';
import { PageNavigation } from './partials';
import { router } from '@inertiajs/vue3';
import DuplicateFormModal from './partials/DuplicateFormModal/DuplicateFormModal.vue';
import TestModeSettingToggle from './partials/TestModeSettingToggle.vue';
import { usePermission } from '@app/panel/Composables/usePermission';
import { App } from '@app/shared/types/generated-v2';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type EditGeneralFormViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.EditGeneralFormViewModel;

const props = defineProps<{
  defaultLocale: EditGeneralFormViewModel['defaultLocale'];
  domain: EditGeneralFormViewModel['domain'];
  form: EditGeneralFormViewModel['form'];
  formLocales: EditGeneralFormViewModel['formLocales'];
  hasActiveReturnImporters: EditGeneralFormViewModel['hasActiveReturnImporters'];
  locales: EditGeneralFormViewModel['locales'];
  orderMutationTypes: EditGeneralFormViewModel['orderMutationTypes'];
  productDetailTypes: EditGeneralFormViewModel['productDetailTypes'];
}>();

const { can } = usePermission();

const notificationPreferences = ref<string[]>(props.form.returnOrderMutationNotificationPreferences);
const processingNotificationPreferences = ref<boolean>(false);

function saveReturnOrderMutationNotificationPreferences(): void {
  router.put(route('panel.settings.forms.email-notification.update', props.form), {
    return_order_mutation_notification_preferences: notificationPreferences.value,
  }, {
    preserveState: true,
    preserveScroll: true,
    onStart: () => processingNotificationPreferences.value = true,
    onFinish: () => processingNotificationPreferences.value = false,
  });
}

watch(() => props.form?.faviconUrl, (newValue) => returnForm.favicon = newValue);

const returnForm = useForm({
  id: props.form?.id,
  is_enabled: props.form?.isEnabled,
  is_test_mode: props.form?.isTestModeEnabled ?? true,
  name: props.form?.name,
  description: props.form?.description,
  reply_to_address: getReplyToAddressLocales(),
  shop_name: getShopNameLocales(),
  website_url: getWebsiteUrlLocales(),
  default_locale: props.defaultLocale.id,
  favicon: props.form?.faviconUrl,
  locales: getLocales(),
});

const localeOptions = computed((): SelectOption[] => {
  return props.locales.map((locale) => {
    return {
      label: locale.name,
      value: locale.id,
      disabled: !returnForm.locales.includes(locale.id),
    };
  });
});

function getReplyToAddressLocales() {
  return props.formLocales.reduce((acc, formLocale) => {
    if (formLocale.replyToAddress === null) {
      return acc;
    }

    return { ...acc, [formLocale.locale.id]: formLocale.replyToAddress };
  }, {});
}

function getShopNameLocales() {
  return props.formLocales.reduce((acc, formLocale) => {
    if (formLocale.shopName === null) {
      return acc;
    }

    return { ...acc, [formLocale.locale.id]: formLocale.shopName };
  }, {});
}

function getWebsiteUrlLocales() {
  return props.formLocales.reduce((acc, formLocale) => {
    if (formLocale.websiteUrl === null) {
      return acc;
    }

    return { ...acc, [formLocale.locale.id]: formLocale.websiteUrl };
  }, {});
}

function getLocales() {
  return props.formLocales
    .filter((formLocale) => formLocale.isEnabled)
    .map((formLocale) => formLocale.locale.id);
}

function updateReturnForm() {
  returnForm
    .put(route('panel.settings.forms.update', { form: props.form }), {
      preserveState: true,
      preserveScroll: true,
    });
}

function openPreview(): void {
  window.open(route('panel.form-preview-url', props.form.id));
}

function deleteForm(): void {
  router.delete(route('panel.settings.forms.remove', props.form.id));
}

const deleteFormModalOpen = ref(false);

function openDeleteFormModal(): void {
  deleteFormModalOpen.value = true;
}

function closeDeleteFormModal(): void {
  deleteFormModalOpen.value = false;
}

const duplicateFormModalOpen = ref(false);

function openDuplicateFormModal() {
  duplicateFormModalOpen.value = true;
}

function closeDuplicateFormModal(): void {
  duplicateFormModalOpen.value = false;
}

const visibleProductDetails = toRef(props.form, 'visibleProductDetails');

const processingProductDetails = ref<boolean>(false);

function saveVisibleProductDetails(): void {
  router.put(route('panel.settings.forms.product-details.update', { form: props.form }), {
    visible_product_details: visibleProductDetails.value,
  }, {
    preserveScroll: true,
    onBefore: () => processingProductDetails.value = true,
    onFinish: () => processingProductDetails.value = false,
  });
}

const advancedSettingsForm = useForm({
  should_use_url_as_link_for_logo: props.form?.shouldUseUrlAsLinkForLogo,
  should_block_search_engine_indexing: props.form?.shouldBlockSearchEngineIndexing,
  is_email_change_allowed: props.form?.isEmailChangeAllowed,
  are_customer_notes_allowed: props.form.areCustomerNotesAllowed,
  show_price_when_free_shipping: props.form.showPriceWhenFreeShipping,
  required_phone_number: props.form.requiredPhoneNumber,
  send_shipping_notifications_from_carriers: props.form.sendShippingNotificationsFromCarriers,
  required_order_number_prefixes: props.form.requiredOrderNumberPrefixes,
  gtm_container_id: props.form.gtmContainerId,
});

function updateAdvancedSettings(): void {
  advancedSettingsForm.put(route('panel.settings.forms.advanced-settings.update', { form: props.form }), {
    preserveState: true,
    preserveScroll: true,
  });
}

provide('form', props.form);
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.general.edit.heading_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form
        :form-data="returnForm"
        @submit="updateReturnForm"
      >
        <Stack
          vertical
          :spacing="StackSpacing.ExtraLoose"
        >
          <StackItem v-if="!form.isVirtual">
            <div
              v-if="form.isTestModeEnabled && hasActiveReturnImporters"
              class="mb-4"
            >
              <Banner :variant="BannerVariant.Warning">
                {{ $t('panel.settings:form:general:test-mode:warning') }}
              </Banner>
            </div>

            <div
              v-if="domain"
              class="mb-4"
            >
              <TextContainer>
                <div class="flex items-center justify-between">
                  <p>
                    {{ $t('panel.settings.forms.list_forms.settings.general.edit.preview') }}
                    <Link @click="openPreview">
                      {{ domain }}
                    </Link>
                  </p>

                  <div class="flex space-x-2">
                    <Button
                      size="small"
                      variant="ghost"
                      @click="openPreview"
                    >
                      {{ $t('panel.settings.forms.list_forms.settings.general.edit.preview_action') }}
                    </Button>

                    <Button
                      size="small"
                      variant="ghost"
                      @click="openDuplicateFormModal"
                    >
                      {{ $t('panel.settings:forms:duplicate-form:show') }}
                    </Button>
                  </div>
                </div>
              </TextContainer>
            </div>

            <Card v-if="!form.isVirtual">
              <CardHeader>
                <CardTitle :card-help="getHelpscoutArticleBeacon('form.general.general-settings')">
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.card_title') }}
                </CardTitle>
              </CardHeader>
              <CardSection>
                <FormLayout>
                  <FormLayout>
                    <FormLayout>
                      <div class="space-y-6">
                        <Toggle
                          v-model="returnForm.is_enabled"
                          :title="$t('panel.settings.forms.list_forms.settings.general.edit.enable_form_toggle')"
                          :subtitle="$t('panel.settings.forms.list_forms.settings.general.edit.enable_form_toggle_subtitle')"
                        />

                        <TestModeSettingToggle
                          v-model="returnForm.is_test_mode"
                          :error="returnForm.errors.is_test_mode"
                        />
                      </div>
                    </FormLayout>
                  </FormLayout>

                  <hr>

                  <FormLayout>
                    <TextInput
                      v-model="returnForm.name"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.form_title_input_label')"
                      :help-text="$t('panel.settings.forms.list_forms.settings.general.edit.form_title_input_help_text')"
                      :error="returnForm.errors.name"
                    />
                  </FormLayout>

                  <FormLayout>
                    <Textarea
                      v-model="returnForm.description"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.description_textarea_label')"
                      :help-text="$t('panel.settings.forms.list_forms.settings.general.edit.description_textarea_help_text')"
                    />
                  </FormLayout>

                  <div class="flex items-start">
                    <div class="flex-1">
                      <FormLayout>
                        <TranslationInputFields
                          v-model="returnForm.website_url"
                          :locales="props.locales"
                          field-name="website_url"
                          :errors="returnForm.errors"
                          :default-locale-id="returnForm.default_locale"
                          :type="TranslationInputFieldType.TextInput"
                          :label="$t('panel.settings.forms.list_forms.settings.general.edit.form_website_url_input_label')"
                        />
                      </FormLayout>
                    </div>

                    <div
                      v-if="returnForm.favicon"
                      class="mt-8"
                      title="Favicon"
                    >
                      <img
                        :src="returnForm.favicon"
                        class="w-6 ml-4 mb-2 rounded"
                        loading="lazy"
                      >
                    </div>
                  </div>

                  <FormLayout>
                    <TranslationInputFields
                      v-model="returnForm.reply_to_address"
                      :locales="props.locales"
                      field-name="reply_to_address"
                      :errors="returnForm.errors"
                      :default-locale-id="returnForm.default_locale"
                      :type="TranslationInputFieldType.TextInput"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.customer_support_email_input_label')"
                      :help-text="$t('panel.settings.forms.list_forms.settings.general.edit.customer_support_email_input_help_text')"
                    />
                  </FormLayout>

                  <FormLayout>
                    <TranslationInputFields
                      v-model="returnForm.shop_name"
                      :locales="props.locales"
                      field-name="shop_name"
                      :errors="returnForm.errors"
                      :default-locale-id="returnForm.default_locale"
                      :type="TranslationInputFieldType.TextInput"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.shop_name_input_label')"
                      :help-text="$t('panel.settings.forms.list_forms.settings.general.edit.shop_name_input_help_text')"
                    />
                  </FormLayout>

                  <FormLayout>
                    <div>
                      <InputLabel
                        :label="$t('panel.settings.forms.list_forms.settings.general.edit.languages_checkboxes_label')"
                        flush
                      />

                      <HelpText>
                        {{ $t('panel.settings.forms.list_forms.settings.general.edit.languages_checkboxes_help_text') }}
                      </HelpText>

                      <div class="mt-2 mb-3">
                        <Banner
                          :open="!! returnForm.errors.locales"
                          :variant="BannerVariant.Critical"
                        >
                          {{ returnForm.errors.locales }}
                        </Banner>
                      </div>

                      <div class="space-y-1">
                        <Checkbox
                          v-for="locale in locales"
                          :key="locale.id"
                          v-model="returnForm.locales"
                          :disabled="returnForm.default_locale === locale.id"
                          :label="locale.name"
                          :value="locale.id"
                        />
                      </div>
                    </div>
                  </FormLayout>

                  <FormLayout>
                    <Select
                      v-model="returnForm.default_locale"
                      :options="localeOptions"
                      :error="returnForm.errors.default_locale"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.default_locale_label')"
                    />
                  </FormLayout>
                </FormLayout>
              </CardSection>
              <CardFooter>
                <Button
                  variant="primary"
                  type="submit"
                  :loading="returnForm.processing"
                  :disabled="!returnForm.isDirty"
                >
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.save_form') }}
                </Button>
              </CardFooter>
            </Card>
          </StackItem>

          <StackItem v-if="!form.isVirtual">
            <Form @submit="saveVisibleProductDetails">
              <Card>
                <CardHeader>
                  <CardTitle :card-help="getHelpscoutArticleBeacon('form.general.product-details')">
                    {{ $t('panel.settings.forms.list_forms.settings.general.edit.product_details_title') }}
                  </CardTitle>
                  <CardDescription>
                    {{ $t('panel.settings.forms.list_forms.settings.general.edit.product_details_description') }}
                  </CardDescription>
                </CardHeader>
                <CardSection>
                  <div class="flex flex-col space-y-3">
                    <Toggle
                      v-for="detail in productDetailTypes"
                      :key="detail"
                      v-model="visibleProductDetails"
                      :title="$t(`panel.product-detail-type:${detail}`)"
                      :value="detail"
                    />
                  </div>
                </CardSection>
                <CardFooter>
                  <Button
                    type="submit"
                    :loading="processingProductDetails"
                  >
                    {{ $t('panel.settings.forms.list_forms.settings.general.edit.save_form') }}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </StackItem>

          <StackItem>
            <Card>
              <CardHeader>
                <CardTitle :card-help="getHelpscoutArticleBeacon('form.general.email-notifications')">
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.email_notifications_title') }}
                </CardTitle>
                <CardDescription>{{ $t('panel.settings.forms.list_forms.settings.general.edit.email_notifications_subtitle') }}</CardDescription>
              </CardHeader>
              <CardSection>
                <div class="flex flex-col space-y-3">
                  <template
                    v-for="orderMutationType in orderMutationTypes"
                    :key="orderMutationType"
                  >
                    <Toggle
                      v-model="notificationPreferences"
                      :title="$t(`panel.form-notification-type:${orderMutationType}`)"
                      :value="orderMutationType"
                    />
                  </template>
                </div>
              </CardSection>
              <CardFooter>
                <Button
                  :loading="processingNotificationPreferences"
                  @click="saveReturnOrderMutationNotificationPreferences"
                >
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.save') }}
                </Button>
              </CardFooter>
            </Card>
          </StackItem>

          <StackItem v-if="!form.isVirtual">
            <Form @submit="updateAdvancedSettings">
              <Card>
                <CardHeader>
                  <CardTitle :card-help="getHelpscoutArticleBeacon('form.general.advanced-settings')">
                    {{ $t('panel.settings.forms.list_forms.settings.general.edit.advanced_settings') }}
                  </CardTitle>
                  <CardDescription>{{ $t('panel.settings.forms.list_forms.settings.general.edit.advanced_settings_subtitle') }}</CardDescription>
                </CardHeader>
                <CardSection>
                  <div class="flex flex-col space-y-3">
                    <Toggle
                      v-model="advancedSettingsForm.should_use_url_as_link_for_logo"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.should_use_url_as_link_for_logo')"
                    />
                    <Toggle
                      v-model="advancedSettingsForm.should_block_search_engine_indexing"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.should_block_search_engine_indexing')"
                    />
                    <Toggle
                      v-model="advancedSettingsForm.is_email_change_allowed"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.is_email_change_allowed')"
                    />
                    <Toggle
                      v-model="advancedSettingsForm.are_customer_notes_allowed"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.are_customer_notes_allowed')"
                    />
                    <Toggle
                      v-model="advancedSettingsForm.show_price_when_free_shipping"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.show_price_when_free_shipping')"
                    />
                    <Toggle
                      v-model="advancedSettingsForm.required_phone_number"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.required_phone_number')"
                    />
                    <Toggle
                      v-model="advancedSettingsForm.send_shipping_notifications_from_carriers"
                      :title="$t('panel.settings.forms.list_forms.settings.general.edit.send_shipping_notifications_from_carriers')"
                    />
                  </div>
                </CardSection>
                <CardSection>
                  <FormLayout>
                    <TextInput
                      v-model="advancedSettingsForm.required_order_number_prefixes"
                      field-name="required_order_number_prefixes"
                      :error="advancedSettingsForm.errors.required_order_number_prefixes"
                      :type="TextInputFieldTypes.Text"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.required_order_number_prefixes')"
                      :help-text="$t('panel.settings.forms.list_forms.settings.general.edit.required_order_number_prefixes_help_text')"
                    />
                  </FormLayout>
                </CardSection>
                <CardSection>
                  <FormLayout>
                    <TextInput
                      v-model="advancedSettingsForm.gtm_container_id"
                      field-name="gtm_container_id"
                      :error="advancedSettingsForm.errors.gtm_container_id"
                      :type="TextInputFieldTypes.Text"
                      :label="$t('panel.settings.forms.list_forms.settings.general.edit.form_gtm_container_input_label')"
                    />
                  </FormLayout>
                </CardSection>
                <CardFooter>
                  <Button
                    type="submit"
                    :disabled="!advancedSettingsForm.isDirty"
                    :loading="advancedSettingsForm.processing"
                  >
                    {{ $t('panel.settings.forms.list_forms.settings.general.edit.save_form') }}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </StackItem>

          <StackItem v-if="can(App.Enums.PermissionType.FORM_DELETE)">
            <Card>
              <CardHeader>
                <CardTitle :card-help="getHelpscoutArticleBeacon('form.general.delete-form')">
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.delete_form_title') }}
                </CardTitle>
              </CardHeader>
              <CardSection>
                <TextContainer>
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.delete_form_description') }}
                </TextContainer>
              </CardSection>
              <CardFooter>
                <Button
                  variant="destructive"
                  @click="openDeleteFormModal"
                >
                  {{ $t('panel.settings.forms.list_forms.settings.general.edit.delete_form_button') }}
                </Button>
              </CardFooter>
            </Card>
          </StackItem>
        </Stack>
      </Form>
    </Page>
  </AppLayout>

  <Form
    id="deleteForm"
    @submit="deleteForm"
  />

  <Modal
    :open="deleteFormModalOpen"
    :title="$t('panel.settings.forms.list_forms.settings.general.edit.delete_form_modal_title')"
    @close="closeDeleteFormModal"
  >
    <TextContainer>
      {{ $t('panel.settings.forms.list_forms.settings.general.edit.delete_form_modal_description') }}
    </TextContainer>
    <template #actions>
      <Button
        variant="ghost"
        @click="closeDeleteFormModal"
      >
        {{ $t('panel.settings.forms.list_forms.settings.general.edit.delete_form_modal_cancel_button') }}
      </Button>
      <Button
        form="deleteForm"
        variant="destructive"
        :type="ButtonType.Submit"
      >
        {{ $t('panel.settings.forms.list_forms.settings.general.edit.delete_form_modal_proceed_button') }}
      </Button>
    </template>
  </Modal>

  <DuplicateFormModal
    :open="duplicateFormModalOpen"
    :form="form"
    @close="closeDuplicateFormModal"
  />
</template>
