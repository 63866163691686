import { includes, split } from 'lodash';
import { Ref, watch } from 'vue';

export function usePrecomputedValues(
  formStructure: Ref<object>,
  precomputedValues: Record<string, any>,
) {
  function replaceWithPrecomputed(field) {
    Object.entries(field).forEach(([key, value]) => {
      const optionName = includes(value as string, '@computed::')
        ? split(value as string, '@computed::').pop()
        : null;

      if (optionName) {
        field[key] = precomputedValues.value[optionName];
      }
    });
  }

  function iterateFields(fields) {
    if (!Array.isArray(fields) || !fields.length) {
      return;
    }

    fields.forEach((field) => {
      replaceWithPrecomputed(field);

      if (Array.isArray(field.fields) && field.fields.length) {
        iterateFields(field.fields);
      }
    });
  }

  watch(formStructure, (newFormStructure) => {
    Object.values(newFormStructure)
      .forEach((value) => {
        iterateFields(value.fields);
      });
  }, { immediate: true });
}
