<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  Modal,
  TextInput,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import { BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { onMounted, PropType } from 'vue';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;

type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'verify'): void;
}>();

const form = useForm({
  title: '',
  description: '',
  api_key: '',
  web_user_login: '',
  client_id: '',
});

const {
  currentStep,
  firstStep,
  lastStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function verify(): void {
  form.submit('post', route('panel.integration.sherpaan.save-credentials'), {
    onSuccess: () => emit('verify'),
  });
}

function openPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>
      <Heading strong>
        {{ $t('panel.dashboard.integrations.sherpaan.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <div
      v-if="currentStep === 1"
      class="space-y-6"
    >
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.title"
            :label="$t('panel.dashboard.integrations.sherpaan.step1.title')"
            :error="form.errors.title"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.description"
            :label="$t('panel.dashboard.integrations.sherpaan.step1.description')"
            :error="form.errors.description"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.client_id"
            :label="$t('panel.dashboard.integrations.sherpaan.step1.client_id')"
            :error="form.errors.client_id"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.api_key"
            :label="$t('panel.dashboard.integrations.sherpaan.step1.api_key')"
            :error="form.errors.api_key"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.web_user_login"
            :label="$t('panel.dashboard.integrations.sherpaan.step1.web_user')"
            :error="form.errors.web_user_login"
          />
        </FormLayout>
      </FormLayout>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.sherpaan.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.sherpaan.activate_integration_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
