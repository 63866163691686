<script lang="ts" setup>
import { Card, CardSection, Select } from '@app/panel/Components';
import { Alert, AlertDescription, ButtonGroup, Link } from '@returnless/focus-ui';
import { Types } from '@app/shared/types/generated-v2';
import { router } from '@inertiajs/vue3';
import { ref, watch } from 'vue';
import { BillingSubscriptionFeatures, BillingSubscriptionPlans, BillingSubscriptionToggle } from '.';

const props = defineProps<{
  amount: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['amount'];
  amountOptionsAnnual: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['amountOptionsAnnual'];
  amountOptionsMonthly: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['amountOptionsMonthly'];
  billingCycle: string | number;
  billingCycleOptions: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['billingCycleOptions'];
  countries: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['countries'];
  currentProduct: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['currentProduct'];
  features: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['features'];
  paymentMethods: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['paymentMethods'];
  plans: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['plans'];
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  suggestedPlanIdentifier: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['suggestedPlanIdentifier'];
  suggestedPlanLabel: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['suggestedPlanLabel'];
  hasExceededPlan: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['hasExceededPlan'];
}>();

const amount = ref<number>(props.amount);

const billingCycleModelValue = ref<string | number>(props.billingCycle);

function setBillingCycle(value: string | number): void {
  billingCycleModelValue.value = value;
}

watch([amount, billingCycleModelValue], () => {
  router.get(route('panel.account.billing'), {
    amount: amount.value,
    billing_cycle: billingCycleModelValue.value,
  }, { replace: true, preserveState: true });
});
</script>

<template>
  <Card :title="$t('panel.account:billing:select-your-subscription')">
    <CardSection>
      <div class="space-y-6">
        <Alert
          v-if="hasExceededPlan"
          variant="warning"
        >
          <AlertDescription>
            <div>
              <strong>{{ $t('panel.account:billing:exceeded-plan:title', {currentPlan: currentProduct.subscriptionPlan.name}) }}</strong>
              <p>{{ $t('panel.account:billing:exceeded-plan:description', {suggestedPlan: suggestedPlanLabel}) }}</p>
            </div>
          </AlertDescription>

          <ButtonGroup>
            <Link
              :href="$route('panel.account.features.summary', {plan: suggestedPlanIdentifier})"
              underline
            >
              {{ $t('panel.account:billing:exceeded-plan:buttons:view-and-disable') }}
            </Link>
          </ButtonGroup>
        </Alert>

        <div class="mx-auto max-w-xs space-y-6">
          <Select
            v-model="amount"
            :label="$t('panel.account:billing:select-amount-of-returns:label')"
            :placeholder="$t('panel.account:billing:select-amount-of-returns:placeholder')"
            :options="billingCycleModelValue === 'monthly' ? amountOptionsMonthly : amountOptionsAnnual"
          />

          <BillingSubscriptionToggle
            :billing-cycle-options="billingCycleOptions"
            :model-value="billingCycleModelValue"
            @update:model-value="setBillingCycle"
          />
        </div>

        <BillingSubscriptionPlans
          :plans="plans"
          :current-product="currentProduct"
          :countries="countries"
          :tenant="tenant"
          :payment-methods="paymentMethods"
          :suggested-plan-label="suggestedPlanLabel"
          :has-exceeded-plan="hasExceededPlan"
        />
      </div>
    </CardSection>

    <BillingSubscriptionFeatures :features="features" />
  </Card>
</template>
