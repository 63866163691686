<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import { Alert, Button, Form, FormLayout, Link, TextField } from '@returnless/focus-ui';
import { GuestLayout } from '@app/panel/Layouts';
import { asset } from 'laravel-vapor';

const form = useForm<{
  email: string;
}>({
  email: '',
});

function submit(): void {
  form.post(route('password.email'));
}
</script>

<template>
  <GuestLayout
    :title="$t('panel.auth.forgot_password.title')"
    :hero-image="asset('img/login-background.jpeg')"
  >
    <template #subtitle>
      <Link :href="$route('login')">
        {{ $t('panel.auth.forgot_password.login_link') }}
      </Link>
    </template>

    <Alert
      v-if="form.wasSuccessful && !form.hasErrors"
      :dismissible="false"
      variant="warning"
    >
      {{ $t('panel.auth.forgot_password.success_message') }}
    </Alert>

    <div v-else>
      <Form @submit="submit">
        <FormLayout>
          <TextField
            v-model="form.email"
            :error="form.errors.email"
            :label="$t('panel.auth.forgot_password.email_field_label')"
            :placeholder="$t('panel.auth.forgot_password.email_field_placeholder')"
            auto-complete="email"
            autofocus
            name="email"
            required
            type="email"
          />

          <Button
            :loading="form.processing"
            full-width
            type="submit"
            variant="primary"
          >
            {{ $t('panel.auth.forgot_password.send_recovery_link_button') }}
          </Button>
        </FormLayout>
      </Form>
    </div>
  </GuestLayout>
</template>
