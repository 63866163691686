<script lang="ts" setup>
import { computed, inject, ref, watch } from 'vue';
import { InertiaForm } from '@inertiajs/vue3';
import { Checkbox, TextInput, TextInputFieldTypes } from '@app/panel/Components';
import { centsToEuro } from '@app/panel/Utils/centsToEuro';

const props = withDefaults(defineProps<{
  children: any[];
  fieldName: string;
  label: string;
  value: string;
  allowDecimal?: boolean;
  placeholder?: null | string;
}>(), {
  allowDecimal: true,
  placeholder: null,
});

const form = inject<InertiaForm<InertiaForm<any>>>('traitForm');
const precomputedValues = inject<Record<string, any>>('precomputedValues');

const placeholder = computed(() => {
  if (props.placeholder.includes('@computed::')) {
    return precomputedValues.value[props.placeholder.split('@computed::')[1]];
  }

  return null;
});

const enabled = ref(true);

const displayValue = ref(centsToEuro(form[props.fieldName]));
const valueInCents = computed<number>(() => displayValue.value ? Math.round(parseFloat(displayValue.value) * 100) : 0);

watch(displayValue, () => {
  form[props.fieldName] = enabled.value ? '0' : valueInCents.value;
});

watch(enabled, () => {
  form[props.fieldName] = enabled.value ? '0' : valueInCents.value;
}, { immediate: true });

const step = computed(() => props.allowDecimal ? 'any' : null);

const computedLabel = computed(() => {
  let label = props.label;

  Object.keys(precomputedValues.value).forEach((key) => {
    label = label.replace(new RegExp(`@computed::${key}`, 'g'), precomputedValues.value[key]);
  });

  return label;
});
</script>

<template>
  <div class="flex justify-between items-center py-4">
    <div class="flex-1">
      <Checkbox
        v-model="enabled"
        :label="computedLabel"
      />
    </div>

    <div class="w-28">
      <TextInput
        v-model="displayValue"
        :type="TextInputFieldTypes.Number"
        :step="step"
        :placeholder="placeholder"
      />
    </div>
  </div>
</template>
