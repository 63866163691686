<script setup lang="ts">
import DateRange from '@app/panel/Pages/Analytics/components/DateRange.vue';
import { Badge, Button, ButtonIconPosition } from '@app/panel/Components';
import { Card, CardSection, FormLayout, Select, SelectOption } from '@returnless/focus-ui';
import { ref, watch } from 'vue';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import { type PerformanceAnalyticsFiltersViewModel } from '../types';
import { useForm } from '@inertiajs/vue3';

type FiltersForm = {
  from: string;
  to: string;
  filter: {
    form: string | null;
  };
};

const props = defineProps<{
  filters: PerformanceAnalyticsFiltersViewModel;
}>();

const filterForm = useForm<FiltersForm>({
  from: props.filters.from,
  to: props.filters.to,
  filter: {
    form: props.filters.formUuid,
  },
});

watch(() => filterForm.data(), () => applyFilters(), { deep: true });

const filtersOpen = ref(false);

function toggleFilters(): void {
  filtersOpen.value = !filtersOpen.value;
}

function applyFilters(): void {
  filterForm.get(route('panel.analytics.performance'), {
    preserveScroll: true,
    preserveState: true,
    replace: true,
  });
}

function updateDateRangeFilter(from: string, to: string): void {
  filterForm.from = from;
  filterForm.to = to;
}

function removeFilterByKey(key: string): void {
  filterForm.filter[key] = null;
}

</script>

<template>
  <div>
    <div class="flex items-end justify-between">
      <DateRange
        :init-from="filters.from"
        :init-to="filters.to"
        :minimal-date="filters.minimalDate"
        @updated="updateDateRangeFilter"
      />

      <div class="flex space-x-3">
        <Button
          :icon-position="ButtonIconPosition.Left"
          @click="toggleFilters"
          @keyup.esc="toggleFilters"
        >
          <template #icon>
            <FunnelIcon class="size-5" />
          </template>

          {{ $t('panel.global:labels:filters') }}
        </Button>
      </div>
    </div>
  </div>

  <div v-if="filtersOpen">
    <Card>
      <CardSection>
        <FormLayout
          :columns="3"
        >
          <Select
            v-model="filterForm.filter.form"
            name="form"
            :label="$t('panel.analytics:performance:filters:form:title')"
            :placeholder="$t('panel.analytics:performance:filters:form:placeholder')"
          >
            <SelectOption
              v-for="formOption in filters.formOptions"
              :key="formOption.value"
              :value="formOption.value"
            >
              {{ formOption.label }}
            </SelectOption>
          </Select>
        </FormLayout>
      </CardSection>
    </Card>
  </div>

  <div
    v-if="filters.activeFilters.length > 0"
    class="gap-3"
  >
    <Badge
      v-for="activeFilter in filters.activeFilters"
      :key="activeFilter.label"
      color="blue"
      closeable
      @close="removeFilterByKey(activeFilter.key)"
    >
      {{ activeFilter.label }}
    </Badge>
  </div>
</template>
