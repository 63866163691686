<script lang="ts" setup>
import {
  computed,
} from 'vue';
import {
  Pagination,
  Tabs,
  TabsItem,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';

type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;

const props = defineProps<{
  form: FormShippingMethodsViewModel['form'];
  selectedContract: FormShippingMethodsViewModel['selectedContract'];
  previousPageUrl: null | string;
  nextPageUrl: null | string;
  filterTabs: any;
}>();

const activeFilterTab = computed(() => {
  return new URLSearchParams(window.location.search).get('active-tab');
});

function setFilter(tab) {
  const contractId = props.selectedContract?.id ?? null;

  let url = route('panel.settings.forms.shipping-methods', props.form) + `?${tab.queryString}&active-tab=${tab.id}`;

  if (contractId) {
    url += '&contract=' + contractId;
  }

  router.visit(url);
}

function resetFilters(): void {
  const contractId = props.selectedContract?.id ?? null;

  let url = route('panel.settings.forms.shipping-methods', props.form);

  if (contractId) {
    url += '?contract=' + contractId;
  }

  router.visit(url);
}
</script>

<template>
  <div class="flex justify-between items-center -ml-3">
    <Tabs>
      <TabsItem
        :active="! activeFilterTab"
        @click="resetFilters"
      >
        {{ $t('panel.analytics:products:table:tabs:all') }}
      </TabsItem>

      <div
        v-for="tab in filterTabs"
        :key="tab.id"
      >
        <TabsItem
          :active="activeFilterTab === tab.id"
          type="button"
          @click="setFilter(tab)"
        >
          {{ tab.label }}
        </TabsItem>
      </div>
    </Tabs>

    <div>
      <Pagination
        :href-next="nextPageUrl"
        :href-previous="previousPageUrl"
      />
    </div>
  </div>
</template>
