<script lang="ts" setup>
import { Badge, BadgeRounded } from '../../Badge';
import { ErrorMessage } from '../../ErrorMessage';
import { Popover } from '../../Popover';
import { TextContainer } from '../../TextContainer';
import { TextStyle, TextStyleFontSize, TextStyleShade } from '../../TextStyle';
import { InputLabel } from '../../InputLabel';
import { computed, ref } from 'vue';
import type { ComboboxOption } from '../types';
import { chain, first, isEmpty } from 'lodash';
import { theme } from '@app/panel/Composables/useTheme';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useComboBoxResize } from '@app/panel/Components/Combobox/components/useComboBoxResize';

const props = withDefaults(
  defineProps<{
    id?: string;
    label?: string;
    modelValue: Array<string | number>;
    options: ComboboxOption[];
    error?: string;
  }>(), {
    id: null,
    label: null,
    error: null,
  },
);

const emit = defineEmits<{
  'update:model-value': [modelValue: Array<string | number>];
  'change': [];
}>();

const elementId = computed(() => props.id || useUniqueId('textField'));
const open = ref<boolean>(false);
const activeItem = ref(null);

const checkedItems = computed(() => {
  return props.options.filter((option) => props.modelValue.includes(option.value));
});

const unusedOptions = computed(() => {
  return props.options.filter((option) => {
    return !props.modelValue.includes(option.value);
  });
});

function toggleCombobox(): void {
  open.value = !open.value;
}

function closeCombobox(): void {
  open.value = false;
}

function updateModelValue(modelValue: Array<string | number>): void {
  emit('update:model-value', modelValue);
  emit('change');
}

function removeItem(item: ComboboxOption): void {
  const newModelValue = props.modelValue.filter((value) => {
    return value !== item.value;
  });

  updateModelValue(newModelValue);
}

function addItem(item: ComboboxOption): void {
  updateModelValue([...props.modelValue, item.value]);
}

function toggleActiveItem(event: KeyboardEvent) {
  event.preventDefault();

  const direction = event.key;

  if (!activeItem.value) {
    activeItem.value = first(unusedOptions.value).value;
  }

  const current = props.options.find((option) => option.value === activeItem.value);

  const itemIndex = chain(unusedOptions.value)
    .map((item) => item.value)
    .indexOf(current.value)
    .value();

  switch (direction) {
    case 'ArrowUp':
      if (itemIndex === 0) {
        break;
      }

      activeItem.value = unusedOptions.value[itemIndex - 1].value;
      break;

    case 'ArrowDown':
      if (itemIndex === unusedOptions.value.length - 1) {
        break;
      }

      activeItem.value = unusedOptions.value[itemIndex + 1].value;
      break;
  }
}

function setFocusToOption(index: number): void {
  const option = unusedOptions.value[index];

  if (option) {
    activeItem.value = option.value;
  }
}

function addCurrentActiveElement() {
  const optionIndex = unusedOptions.value.findIndex((option) => option.value === activeItem.value);

  if (optionIndex === -1) {
    return;
  }

  addItem(unusedOptions.value[optionIndex]);
  setFocusToOption(optionIndex + 1);
}

const { $comboBox } = useComboBoxResize();
</script>

<template>
  <div>
    <InputLabel :label="label" />

    <button
      ref="$comboBox"
      type="button"
      role="combobox"
      aria-haspopup="listbox"
      :aria-controls="`${elementId}_dropdown`"
      :aria-labelledby="`${elementId}_label`"
      :aria-activedescendant="`${elementId}_element_${activeItem}`"
      :aria-expanded="open"
      class="flex flex-wrap items-center gap-1 bg-white rounded-md focus:outline-none focus:ring-2 w-full appearance-none border min-h-[2.375rem] px-3 py-2 shadow-sm cursor-pointer select-none font-normal"
      :class="[
        theme(
          ['border', 'focus-within-border-color'],
          props.error ? 'critical' : 'default',
        ),
        theme('border-size'),
        theme('shadow'),
      ]"
      tabindex="0"
      @keydown.stop="toggleActiveItem"
      @click="toggleCombobox"
      @keyup.esc="closeCombobox"
      @keyup.enter="addCurrentActiveElement"
    >
      <template v-if="checkedItems.length > 0">
        <Badge
          v-for="item in checkedItems"
          :key="item.value"
          :color="item.color || 'slate'"
          :rounded="BadgeRounded.Full"
          closeable
          @close="removeItem(item)"
        >
          {{ item.label }}
        </Badge>
      </template>
      <template v-if="checkedItems.length === 0">
        <TextStyle :shade="TextStyleShade.Pale">
          {{ $t('panel.global:select:empty-option-label') }}
        </TextStyle>
      </template>
    </button>

    <Popover
      flush
      class="mt-1 text-base sm:text-sm"
      :active="open"
      @close="closeCombobox"
    >
      <ul
        v-if="! isEmpty(unusedOptions)"
        :id="`${elementId}_dropdown`"
        role="listbox"
        :aria-multiselectable="false"
        class="max-h-56 overflow-auto"
      >
        <li
          v-for="option in unusedOptions"
          :id="`${elementId}_element_${option.value}`"
          :key="option.value"
          :aria-selected="modelValue.includes(option.value)"
          role="option"
          class="cursor-pointer p-2"
          :class="activeItem === option.value ? 'bg-slate-50' : ''"
          @mouseover="activeItem = option.value"
          @click.stop="addItem(option)"
        >
          {{ option.label }}
        </li>
      </ul>

      <TextContainer
        v-if="isEmpty(unusedOptions)"
        text-center
      >
        <TextStyle :font-size="TextStyleFontSize.Small">
          <div class="p-2">
            {{ $t('panel.global:select:no-options-available') }}
          </div>
        </TextStyle>
      </TextContainer>
    </Popover>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
