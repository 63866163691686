<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  List,
  ListItem,
  Modal,
  TextContainer,
  TextInput,
  TextInputFieldTypes,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { usePanel } from '@app/panel/Composables/useHttp';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import {
  ArrowRightCircleIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  CogIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { asset } from 'laravel-vapor';
import { onMounted, PropType, ref } from 'vue';

type PlatformIntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;

type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

const props = defineProps({
  integration: {
    type: Object as PropType<PlatformIntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'verify'): void;
}>();

function verify(): void {
  emit('verify');
}

const {
  currentStep,
  firstStep,
  lastStep,
  nextStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(4);
});

function magentoNextStep(): void {
  if (currentStep.value === 2) {
    saveCredentials();
  }

  nextStep();
}

const callbackUrl = 'https://panel.returnless.com/connect/magento2/callback/' + props.tenant.id;

const identityLinkUrl = 'https://panel.returnless.com/connect/magento2/authorize';

const form = useForm({
  basic_auth_username: '',
  basic_auth_password: '',
  store_base_url: '',
});

let advancedSettingsOpen = ref(false);

function toggleAdvancedSettings(): void {
  advancedSettingsOpen.value = !advancedSettingsOpen.value;
}

function isAdvancedSettingsOpen(): boolean {
  return advancedSettingsOpen.value;
}

function saveCredentials(): void {
  usePanel().post(route('panel.temporary-credentials.create', 'magento2'), form.data());
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.dashboard.integrations.magento2.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <TextContainer text-center>
      <TextStyle :shade="TextStyleShade.Subdued">
        {{ $t('panel.dashboard.integrations.magento2.description') }}
      </TextStyle>
    </TextContainer>

    <div v-if="currentStep === 1">
      <div class="space-y-1 mt-6">
        <TextStyle
          bold
          :font-size="TextStyleFontSize.Base"
        >
          {{ $t('panel.dashboard.integrations.magento2.step') }} 1
        </TextStyle>

        <TextStyle>
          <List marker-subdued>
            <ListItem>{{ $t('panel.dashboard.integrations.magento2.step1.item1') }}</ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step1.item2.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step1.item2.part2') }}</strong>
            </ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step1.item3.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step1.item3.part2') }}</strong>
            </ListItem>
          </List>
        </TextStyle>
      </div>

      <img
        :alt="$t('panel.dashboard.integrations.magento2.step1.image_alt_text')"
        :src="asset('img/integrations/magento2-integration-step-1.png')"
        class="my-6"
        loading="lazy"
      >
    </div>

    <div
      v-if="currentStep === 2"
      class="space-y-6"
    >
      <div class="space-y-1 mt-6">
        <TextStyle
          bold
          :font-size="TextStyleFontSize.Base"
        >
          {{ $t('panel.dashboard.integrations.magento2.step') }} 2
        </TextStyle>

        <TextStyle>
          <List marker-subdued>
            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step2.item1.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step2.item1.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.magento2.step2.item1.part3') }}
            </ListItem>
          </List>
        </TextStyle>
      </div>

      <FormLayout>
        <FormLayout>
          <TextInput
            :label="$t('panel.dashboard.integrations.magento2.step2.callback_url_input_label')"
            disabled
            allow-copy
            :value="callbackUrl"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            :label="$t('panel.dashboard.integrations.magento2.step2.identity_url_input_label')"
            disabled
            allow-copy
            :value="identityLinkUrl"
          />
        </FormLayout>
      </FormLayout>

      <Button
        full-width
        @click="toggleAdvancedSettings()"
      >
        <template #icon>
          <CogIcon class="h-5 w-5 text-gray-400" />
        </template>
        <span>{{ $t('panel.dashboard.integrations.magento2.step2.advanced-settings-button') }}</span>
      </Button>

      <FormLayout v-if="isAdvancedSettingsOpen()">
        <FormLayout>
          <TextInput
            v-model="form.basic_auth_username"
            :label="$t('panel.dashboard.integrations.magento2.step2.basic_auth_username')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_password"
            :label="$t('panel.dashboard.integrations.magento2.step2.basic_auth_password')"
            :type="TextInputFieldTypes.Password"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.store_base_url"
            :label="$t('panel.integrations:magento:connect:step2:store-base-url:label')"
            :type="TextInputFieldTypes.URL"
          />
        </FormLayout>
      </FormLayout>

      <img
        :alt="$t('panel.dashboard.integrations.magento2.step2.image_alt_text')"
        :src="asset('img/integrations/magento2-integration-step-2.png')"
        loading="lazy"
      >
    </div>

    <div
      v-if="currentStep === 3"
      class="space-y-6"
    >
      <div class="space-y-1 mt-6">
        <TextStyle
          bold
          :font-size="TextStyleFontSize.Base"
        >
          {{ $t('panel.dashboard.integrations.magento2.step') }} 3
        </TextStyle>

        <TextStyle>
          <List marker-subdued>
            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step3.item1.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step3.item1.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.magento2.step3.item1.part3') }}
            </ListItem>
          </List>

          <div class="pl-6">
            <List marker-subdued>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item2') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item3') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item4') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item5') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item6') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item7') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item8') }}</ListItem>
              <ListItem>{{ $t('panel.dashboard.integrations.magento2.step3.item9') }}</ListItem>
            </List>
          </div>
        </textstyle>
      </div>

      <img
        :alt="$t('panel.dashboard.integrations.magento2.step3.image_alt_text')"
        :src="asset('img/integrations/magento2-integration-step-3.png')"
        loading="lazy"
      >
    </div>

    <div
      v-if="currentStep === 4"
      class="space-y-6"
    >
      <div class="space-y-1 mt-6">
        <TextStyle
          bold
          :font-size="TextStyleFontSize.Base"
        >
          {{ $t('panel.dashboard.integrations.magento2.step') }} 4
        </TextStyle>

        <TextStyle>
          <List marker-subdued>
            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step4.item1.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step4.item1.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.magento2.step4.item1.part3') }}
            </ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step4.item2.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step4.item2.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.magento2.step4.item2.part3') }}
            </ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.magento2.step4.item3.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.magento2.step4.item3.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.magento2.step4.item3.part3') }}
            </ListItem>
          </List>
        </TextStyle>
      </div>

      <img
        class="w-1/2 m-auto"
        :alt="$t('panel.dashboard.integrations.magento2.step4.image_alt_text')"
        :src="asset('img/integrations/magento2-integration-step-4.png')"
        loading="lazy"
      >
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.magento2.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.magento2.verify_integration_button') }}</span>
        </Button>

        <Button
          v-else
          full-width
          :variant="ButtonVariant.Primary"
          @click="magentoNextStep"
        >
          <template #icon>
            <ArrowRightCircleIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.magento2.next_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
