<script lang="ts" setup>
import {
  computed,
  PropType,
  ref,
} from 'vue';
import {
  Pagination,
  Select,
  TextContainer,
  TextStyle,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { router } from '@inertiajs/vue3';
import { SelectOption } from '@app/customer/Components';
import { App } from '@app/shared/types/generated-v2';
import { useBulkActionLoader } from '@app/panel/Composables/useBulkActionLoader';
import { useI18n } from 'vue-i18n';

type BulkActionRequestOrderType = Types.App.Enums.BulkActionRequestOrderType;
type RequestStatusViewModel = Types.App.Models.ViewModels.RequestStatusViewModel;
type TagViewModel = Types.App.Models.ViewModels.TagViewModel;

const { t } = useI18n();

const props = defineProps({
  paginatorStatus: {
    type: String,
    required: true,
  },

  perPage: {
    type: Number,
    required: true,
  },

  previousPageUrl: {
    type: String,
    default: null,
  },

  nextPageUrl: {
    type: String,
    default: null,
  },

  checkedItems: {
    type: Array,
    required: true,
  },

  requestStatuses: {
    type: Array as PropType<RequestStatusViewModel[]>,
    required: true,
  },

  tags: {
    type: Array as PropType<TagViewModel[]>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'change', rowsPerPage: number): void;
}>();

const rowsPerPage = ref(props.perPage);
const { isRunningActiveBulkActions } = useBulkActionLoader();

const paginationOptions = computed(() => {
  const options = [5, 10, 25, 50, 75, 100];
  return options.map((option) => {
    return {
      value: option,
      label: t('panel.global:pagination:option', { option }),
    };
  });
});

const onRowsPerPageChange = () => {
  emit('change', rowsPerPage.value);
};

const hasCheckedItems = computed<boolean>(() => {
  return props.checkedItems.length > 0 && isRunningActiveBulkActions.value === false;
});

const actionValue = ref<BulkActionRequestOrderType | null>(null);

const requestStatusOptions = computed<SelectOption[]>(() => {
  return props.requestStatuses.map((status) => {
    return {
      label: status.label,
      value: status.id,
    };
  });
});

const tagOptions = computed<SelectOption[]>(() => {
  return props.tags.map((tag) => {
    return {
      label: tag.name,
      value: tag.id,
    };
  });
});

const actionOptions = computed(() => {
  return [
    {
      label: 'Approve',
      value: 'approve',
    },
    {
      label: 'Approve without shipment',
      value: 'approve-without-shipment',
    },
    {
      label: 'Reject',
      value: 'reject',
    },
    {
      label: 'Set status',
      value: requestStatusOptions.value,
    },
    {
      label: 'Add tag',
      value: tagOptions.value,
    },
  ];
});

const checkedItemsIds = computed<string[]>(() => {
  return props.checkedItems.map((item) => item.id);
});

const requestStatusesIds = computed(() => {
  return props.requestStatuses.map((status) => status.id);
});

const tagValues = computed<string[]>(() => {
  return tagOptions.value.map((option) => option.value as string);
});

function getBulkAction(actionValue: string): App.Enums.BulkActionRequestOrderType | string {
  if (requestStatusesIds.value.includes(actionValue)) {
    return App.Enums.BulkActionRequestOrderType.SET_STATUS;
  }

  if (tagValues.value.includes(actionValue)) {
    return App.Enums.BulkActionRequestOrderType.ADD_TAG;
  }

  return null;
}

function saveInBulkAction(): void {
  const action = getBulkAction(actionValue.value);

  router.post(route('panel.requests.bulk-actions'), {
    ids: checkedItemsIds.value,
    action: action || actionValue.value,
    ...(action && { action_value: actionValue.value }),
  }, {
    preserveState: false,
    preserveScroll: true,
  });
}
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center space-x-4">
      <Select
        v-model="actionValue"
        :options="actionOptions"
        :disabled="! hasCheckedItems"
        placeholder="Action"
        @change="saveInBulkAction"
      />

      <TextContainer>
        <TextStyle no-wrap>
          {{ paginatorStatus }}
        </TextStyle>
      </TextContainer>
    </div>

    <div class="flex items-center space-x-4">
      <Select
        v-model="rowsPerPage"
        label-hidden
        :options="paginationOptions"
        @change="onRowsPerPageChange"
      />

      <Pagination
        :href-next="nextPageUrl"
        :href-previous="previousPageUrl"
      />
    </div>
  </div>
</template>
