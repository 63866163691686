<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  Modal,
  TextInput,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';

defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

function handleClose(): void {
  emit('close');
  form.reset();
}

const form = useForm({
  name: '',
});

function create(): void {
  form.post(route('panel.settings.integrations.api-keys.store'), {
    onSuccess: () => handleClose(),
  });
}
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.settings:integrations:api-keys')"
    @close="handleClose"
  >
    <Form
      id="createApiKeyForm"
      @submit="create"
    >
      <FormLayout>
        <TextInput
          v-model="form.name"
          :label="$t('panel.settings:integrations:api-keys:name')"
          :error="form.errors.name"
        />
      </FormLayout>
    </Form>

    <template #actions>
      <ButtonGroup>
        <Button @click="handleClose">
          {{ $t('panel.settings:integrations:api-keys:cancel') }}
        </Button>

        <Button
          :loading="form.processing"
          :variant="ButtonVariant.Primary"
          form="createApiKeyForm"
          :type="ButtonType.Submit"
        >
          {{ $t('panel.settings:integrations:api-keys:create-api-key') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
