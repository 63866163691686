<script lang="ts" setup>
import {
  BuildingStorefrontIcon,
  CheckIcon,
  ClockIcon,
  NoSymbolIcon,
  QrCodeIcon,
  TruckIcon,
} from '@heroicons/vue/24/outline';
import { Tooltip } from '@app/panel/Components';
import { App } from '@app/shared/types/generated-v2';
import { HourglassIcon } from '@app/panel/Icons';

type ReturnOrderState = App.Enums.ReturnOrder.ReturnOrderState;

defineProps<{
  state: ReturnOrderState;
  showText?: boolean;
}>();
const states = App.Enums.ReturnOrder.ReturnOrderState;

const getStateIconComponent = (state: string) => {
  switch (state) {
    case states.REQUEST_PENDING:
      return ClockIcon;
    case states.REQUEST_REJECTED:
      return NoSymbolIcon;
    case states.REQUEST_APPROVED:
      return QrCodeIcon;
    case states.RETURN_SHIPPED:
      return TruckIcon;
    case states.RETURN_ANNOUNCED:
      return BuildingStorefrontIcon;
    case states.RETURN_PROCESSING:
      return HourglassIcon;
    case states.RETURN_RESOLVED:
      return CheckIcon;
    default:
      return null;
  }
};

const getStateColor = (state: ReturnOrderState) => {
  switch (state) {
    case states.REQUEST_PENDING:
    case states.RETURN_SHIPPED:
    case states.RETURN_ANNOUNCED:
    case states.RETURN_PROCESSING:
      return 'text-slate-600';
    case states.REQUEST_APPROVED:
    case states.RETURN_RESOLVED:
      return 'text-green-600';
    case states.REQUEST_REJECTED:
      return 'text-red-600';
    default:
      return 'text-slate-600';
  }
};
</script>

<template>
  <span
    :class="`${getStateColor(state)} flex items-center space-x-2 text-sm`"
  >
    <span :class="`size-5`">
      <Tooltip
        v-if="!showText"
        :content="$t(`enums.return-order-state:${state}`)"
      >
        <component
          :is="getStateIconComponent(state)"
          class
        />
      </Tooltip>

      <component
        :is="getStateIconComponent(state)"
        v-else
      />
    </span>

    <span v-if="showText">{{ $t(`enums.return-order-state:${state}`) }}</span>
  </span>
</template>
