<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import { ref, watch } from 'vue';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  Modal,
  Textarea,
  TextContainer,
  TextInput,
} from '@app/panel/Components';

const props = defineProps<{
  open: boolean;
  form: any;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const showSuccessModal = ref(false);

function closeModal() {
  emit('close');
}

function closeSuccessModal() {
  emit('close');
  showSuccessModal.value = false;
}

const form = useForm({
  name: '',
  description: '',
});

function sumbitDuplicateForm() {
  form.post(route('panel.settings.forms.duplicate', props.form), {
    preserveScroll: true,
    onSuccess: () => {
      showSuccessModal.value = true;
    },
  });
}

watch(() => props.open, () => {
  if (props.open) {
    form.name = props.form.name;
    form.description = props.form.description;
  }
});
</script>

<template>
  <Modal
    :open="open && !showSuccessModal"
    :title="$t('panel.settings:forms:duplicate-form:title')"
    @close="closeModal"
  >
    <Form
      id="duplicate-form-form"
      @submit="sumbitDuplicateForm"
    >
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.name"
            :error="form.errors.name"
            :label="$t('panel.settings:forms:duplicate-form:name')"
          />
        </FormLayout>

        <FormLayout>
          <Textarea
            v-model="form.description"
            :error="form.errors.description"
            :label="$t('panel.settings:forms:duplicate-form:description')"
            :rows="4"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="closeModal">
        {{ $t('panel.settings:forms:duplicate-form:cancel') }}
      </Button>
      <Button
        :disabled="! form.isDirty"
        :loading="form.processing"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="duplicate-form-form"
      >
        {{ $t('panel.settings:forms:duplicate-form:duplicate') }}
      </Button>
    </template>
  </Modal>

  <Modal
    :open="showSuccessModal"
    :title="$t('panel.settings:forms:duplicate-form:success:title')"
    @close="closeSuccessModal"
  >
    <TextContainer class="mb-4">
      {{ $t('panel.settings:forms:duplicate-form:success:description') }}
    </TextContainer>

    <template #actions>
      <Button
        :variant="ButtonVariant.Primary"
        @click="closeSuccessModal"
      >
        {{ $t('panel.settings:forms:duplicate-form:success:close') }}
      </Button>
    </template>
  </Modal>
</template>
