<script lang="ts" setup>
import {
  ref,
} from 'vue';
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Card,
  CardSection,
  CardShadow,
  Heading,
  HeadingElement,
  Overlay,
  OverlayShade,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import { asset } from 'laravel-vapor';
import { PlusSmallIcon } from '@heroicons/vue/24/outline';

const loading = ref(false);

interface RequestAuthorizationProps {
  provider: string;
  providerImageSrc: string;
  baseUrl: string;
  credential: string;
  successCallbackUrl: string;
}

const props = defineProps<RequestAuthorizationProps>();

function accept(): void {
  router.post(route('panel.oauth.adobe-commerce.authorize'), {
    credential: props.credential,
    success_callback_url: props.successCallbackUrl,
  }, {
    onStart: () => loading.value = true,
    onFinish: () => loading.value = false,
  });
}

function cancel() {
  window.history.back();
}
</script>

<template>
  <Head>
    <title>Authorize {{ provider }}</title>
  </Head>

  <Teleport to="body">
    <Overlay
      :shade="OverlayShade.Light"
    >
      <Card :shadow="CardShadow.ExtraLarge">
        <CardSection>
          <div class="mx-4">
            <div class="flex gap-12 mt-10 items-center w-2/3 mx-auto">
              <img
                :src="asset('favicon-180.png')"
                class="w-14 m-auto rounded-sm"
                loading="lazy"
              >

              <PlusSmallIcon
                class="h-6 w-6 text-slate-400"
              />

              <img
                :src="providerImageSrc"
                class="w-14 m-auto rounded-md"
                alt="Integration logo"
                loading="lazy"
              >
            </div>

            <div class="my-12 text-center w-3/4 m-auto">
              <Heading :element="HeadingElement.H3">
                {{ $t('panel.oauth.integration_consent.header', { integration_name: provider }) }}
              </Heading>

              <div class="mt-2">
                <TextContainer>
                  <TextStyle :shade="TextStyleShade.Subdued">
                    {{ $t('panel.oauth.integration_consent.description', { integration_name: provider }) }}
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </div>

          <div
            v-if="$page.props.isImpersonating"
            class="bg-slate-200 px-4 py-2 text-sm mb-10 rounded"
          >
            You are currently not logged in as your normal user but as <strong>
              {{ $page.props.user.email }}.</strong><br>
            Are you sure you want to continue adding this integration?
          </div>

          <ButtonGroup>
            <Button @click="cancel">
              {{ $t('panel.oauth.integration_consent.cancel_button') }}
            </Button>

            <Button
              :loading="loading"
              :variant="ButtonVariant.Primary"
              @click="accept"
            >
              {{ $t('panel.oauth.integration_consent.connect_button') }}
            </Button>
          </ButtonGroup>
        </CardSection>
      </Card>
    </Overlay>
  </Teleport>
</template>
