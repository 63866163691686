export const conditionOptions = {
  EQUALS: [
    'auto_complete',
    'country_select',
    'currency_select',
    'date_time',
    'dimension',
    'meta_data',
    'number',
    'price',
    'question',
    'request_status',
    'return_address_select',
    'return_reason',
    'return_status',
    'text',
    'weight',
  ],
  GREATER_THAN: [
    'date_time',
    'dimension',
    'meta_data',
    'number',
    'price',
    'weight',
  ],
  GREATER_THAN_EQUAL: [
    'date_time',
    'dimension',
    'meta_data',
    'number',
    'price',
    'weight',
  ],
  INCLUDES: [
    'auto_complete',
    'meta_data',
    'tags',
    'text',
  ],
  LESS_THAN: [
    'date_time',
    'dimension',
    'meta_data',
    'number',
    'price',
    'weight',
  ],
  LESS_THAN_EQUAL: [
    'date_time',
    'dimension',
    'meta_data',
    'number',
    'price',
    'weight',
  ],
  NOT_EQUALS: [
    'auto_complete',
    'country_select',
    'currency_select',
    'date_time',
    'dimension',
    'meta_data',
    'number',
    'price',
    'question',
    'request_status',
    'return_address_select',
    'return_reason',
    'return_status',
    'text',
    'weight',
  ],
  NOT_INCLUDES: [
    'auto_complete',
    'meta_data',
    'tags',
    'text',
  ],
  STARTS_WITH: [
    'auto_complete',
    'meta_data',
    'text',
  ],
  ENDS_WITH: [
    'auto_complete',
    'meta_data',
    'text',
  ],
};

export const switchButtonOptions = [
  {
    label: 'And',
    value: 'AND',
  },
  {
    label: 'Or',
    value: 'OR',
  },
];
