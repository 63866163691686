<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Card,
  CardSection,
  DataTable,
  Link,
  TextContainer,
  TextStyle,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { Types } from '@app/shared/types/generated-v2';

defineProps<{
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  invoices: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['invoices'];
}>();

const { t } = useI18n();

const headings = [
  {
    text: t('panel.account.billing_and_subscription.billing_history.date_column'),
    value: 'date',
  },

  {
    text: t('panel.account.billing_and_subscription.billing_history.invoice_number_column'),
    value: 'invoiceNumber',
  },

  {
    text: t('panel.account.billing_and_subscription.billing_history.amount_column'),
    value: 'amount',
  },
];

function downloadInvoice(invoice): void {
  window.open(route('panel.account.invoices.show', invoice), '_blank');
}
</script>

<template>
  <Card
    v-if="tenant.isCustomer && invoices.length > 0"
    :title="$t('panel.account.billing_and_subscription.billing_history.card_title')"
  >
    <CardSection>
      <DataTable
        :headings="headings"
        :items="invoices"
      >
        <template #date="{ item }">
          <TextContainer>
            <TextStyle
              variation="strong"
              class="text-slate-900"
            >
              {{ item.date }}
            </TextStyle>
          </TextContainer>
        </template>

        <template #actions="{ item }">
          <span class="space-x-4 text-sm">
            <Link
              :href="$route('panel.account.invoice-specifications', item.id)"
              target="_blank"
              native
            >
              {{ $t('panel.account.billing_and_subscription.billing_history.download_specifications') }}
            </Link>

            <Button
              plain
              :variant="ButtonVariant.Primary"
              @click="downloadInvoice(item)"
            >
              {{ $t('panel.account.billing_and_subscription.billing_history.view_invoice_cta') }}
            </Button>
          </span>
        </template>
      </DataTable>
    </CardSection>
  </Card>
</template>
