<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import {
  Badge,
  BadgeSize,
  BarChart,
  Card,
  CardSection,
  Heading,
  Page,
  RangeChart,
  Stack,
  StackItem,
  StackSpacing,
} from '@app/panel/Components';
import { KPICard } from '@returnless/focus-ui';
import Navigation from './partials/Navigation.vue';
import { type Types } from '@app/shared/types/generated-v2';
import { InsightsItem } from '@app/panel/Containers';
import HorizontalBarChart from './components/HorizontalBarChart.vue';
import ShipmentTimings from './blocks/Shipping/ShipmentTimings.vue';
import ShipmentCosts from './blocks/Shipping/ShipmentCosts.vue';
import ShipmentRoutes from './blocks/Shipping/ShipmentRoutes.vue';
import ShipmentFilters from './blocks/Shipping/ShipmentFilters.vue';
import { InertiaForm, useForm } from '@inertiajs/vue3';
import { ref } from 'vue';

type ShippingAnalyticsViewModel = Types['App.Http.ViewModels.Panel.Analytics.Shipping.ShippingAnalyticsViewModel'];

const props = defineProps<ShippingAnalyticsViewModel>();

const filterForm = ref(useForm({
  from: props.filters.from,
  to: props.filters.to,
  filter: {
    carrier: props.filters.carrier,
    from_country: props.filters.from_country,
    to_country: props.filters.to_country,
  },
}));

function selectCarrier(carrier: string): void {
  if (carrier === 'Others') {
    return;
  }

  filterForm.value.filter.carrier = carrier;

  updateForm();
}

function onFormChange(newFilterForm: InertiaForm): void {
  filterForm.value = newFilterForm;

  updateForm();
}

function updateForm(): void {
  filterForm.value.get(route('panel.analytics.shipping'), {
    preserveScroll: true,
    preserveState: true,
  });
}
</script>

<template>
  <AppLayout :title="$t('panel.analytics:shipping:title')">
    <Page>
      <template #page-navigation>
        <Navigation />
      </template>

      <ShipmentFilters
        :filter-form="filterForm"
        :carriers="carriers"
        :from-countries="fromCountries"
        :to-countries="toCountries"
        @on-form-change="onFormChange"
      />

      <div class="mb-12">
        <Stack
          vertical
          :spacing="StackSpacing.ExtraLoose"
        >
          <StackItem class="space-y-6">
            <KPICard>
              <InsightsItem
                :item="{
                  label: $t('panel.analytics:shipping:insights:announced'),
                  value: props.shipmentNumbers.announced
                }"
              />

              <InsightsItem
                :item="{
                  label: $t('panel.analytics:shipping:insights:sent'),
                  value: props.shipmentNumbers.sent,
                }"
              />

              <InsightsItem
                :item="{
                  label: $t('panel.analytics:shipping:insights:delivered'),
                  value: props.shipmentNumbers.delivered,
                }"
              />

              <InsightsItem
                :item="{
                  label: $t('panel.analytics:shipping:insights:with-errors'),
                  value: props.shipmentNumbers.errors,
                }"
              />

              <InsightsItem
                :item="{
                  label: $t('panel.analytics:shipping:insights:delayed'),
                  value: props.shipmentNumbers.delayed,
                }"
              />

              <InsightsItem
                :item="{
                  label: $t('panel.analytics:shipping:insights:returned-to-sender'),
                  value: props.shipmentNumbers.returnedToSender,
                }"
              />
            </KPICard>
          </StackItem>

          <StackItem>
            <Card :title="$t('panel.analytics:shipping:periodical-statistics')">
              <CardSection>
                <BarChart
                  :labels="periodicalShipmentNumbersBarChart.labels"
                  :datasets="periodicalShipmentNumbersBarChart.datasets"
                  stacked
                />
              </CardSection>

              <div
                v-if="deliveryPredictionsRangeChart.dataset.length > 0"
                class="p-6 border-t border-slate-200 bg-slate-50"
              >
                <div class="space-y-4">
                  <div class="flex space-x-2 justify-between ">
                    <Heading>{{ $t('panel.analytics:shipping:prediction:heading') }}</Heading>
                    <Badge
                      color="yellow"
                      :size="BadgeSize.Large"
                    >
                      BETA
                    </Badge>
                  </div>

                  <RangeChart
                    :dataset="deliveryPredictionsRangeChart.dataset"
                    :from="deliveryPredictionsRangeChart.from"
                    :to="deliveryPredictionsRangeChart.to"
                  />

                  <div class="text-xs text-slate-400 italic">
                    {{ $t('panel.analytics:shipping:prediction:disclaimer') }}
                  </div>
                </div>
              </div>
            </Card>
          </StackItem>

          <StackItem>
            <ShipmentTimings
              :average-times="props.averageTimes"
              :average-times-by-carrier="props.averageTimesByCarrier"
              :average-times-global-offset="props.averageTimesGlobalOffset"
            />
          </StackItem>

          <StackItem>
            <div class="flex space-x-8">
              <div class="flex flex-1 flex-col space-y-5">
                <Card
                  v-if="!filters.carrier"
                  :title="$t('panel.analytics:shipping:rankings:top-carriers')"
                >
                  <CardSection>
                    <HorizontalBarChart
                      :data="topCarriersHorizontalBarChart.data"
                      clickable
                      @clicked="(carrier) => selectCarrier(carrier)"
                    />
                  </CardSection>
                </Card>
                <Card
                  v-if="topShippingMethodTypesHorizontalBarChart.data.length > 0"
                  :title="$t('panel.analytics:shipping:rankings:top-shipment-types')"
                >
                  <CardSection>
                    <HorizontalBarChart :data="topShippingMethodTypesHorizontalBarChart.data" />
                  </CardSection>
                </Card>
              </div>

              <div class="flex-1">
                <Card :title="$t('panel.analytics:shipping:rankings:top-shipping-methods')">
                  <CardSection>
                    <HorizontalBarChart :data="topShippingMethodsHorizontalBarChart.data" />
                  </CardSection>
                </Card>
              </div>
            </div>
          </StackItem>

          <StackItem>
            <ShipmentRoutes
              :top-routes="props.topRoutes"
            />
          </StackItem>

          <StackItem>
            <ShipmentCosts
              :average-costs="props.averageCosts"
              :average-costs-by-carrier="props.averageCostsByCarrier"
            />
          </StackItem>
        </Stack>
      </div>
    </Page>
  </AppLayout>
</template>
