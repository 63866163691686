<script lang="ts" setup>
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardSection,
  CardTitle,
  Checkbox,
  Form,
  FormLayout,
  TextField,
} from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import { GeneralSettingsPageViewModel } from '@app/panel/Pages/Settings/GeneralSettings/General/types';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

const props = defineProps<{
  returnOrderNumberFormat: GeneralSettingsPageViewModel['returnOrderNumberFormat'];
}>();

const returnOrderNumberFormatForm = useForm({
  prefix: props.returnOrderNumberFormat.prefix,
  show_year: props.returnOrderNumberFormat.showYear,
  separator: props.returnOrderNumberFormat.separator,
  padding: props.returnOrderNumberFormat.padding,
  increment: props.returnOrderNumberFormat.increment,
});

function submitReturnOrderNumberFormatForm() {
  returnOrderNumberFormatForm.put(route('panel.settings.general.return-order-number-format.update'), {
    preserveState: true,
    preserveScroll: true,
  });
}
</script>

<template>
  <Form @submit="submitReturnOrderNumberFormatForm">
    <Card>
      <CardHeader>
        <CardTitle :card-help="getHelpscoutArticleBeacon('general-settings.general-settings.return-number-formatting')">
          {{ t('panel.settings.general_settings.general_settings.return_number_formatting.card_title') }}
        </CardTitle>
      </CardHeader>
      <CardSection>
        <FormLayout>
          <TextField
            v-model="returnOrderNumberFormatForm.prefix"
            :error="returnOrderNumberFormatForm.errors.prefix"
            :label="t('panel.settings.general_settings.general_settings.return_number_formatting.forms.prefix_label')"
            name="prefix"
          />

          <Checkbox
            v-model="returnOrderNumberFormatForm.show_year"
            :label="t('panel.settings.general_settings.general_settings.return_number_formatting.forms.show_year_label')"
            name="show_year"
            value="1"
          />

          <TextField
            v-model="returnOrderNumberFormatForm.separator"
            :error="returnOrderNumberFormatForm.errors.separator"
            :label="t('panel.settings.general_settings.general_settings.return_number_formatting.forms.separator_label')"
            name="separator"
          />

          <TextField
            v-model="returnOrderNumberFormatForm.padding"
            :error="returnOrderNumberFormatForm.errors.padding"
            :label="t('panel.settings.general_settings.general_settings.return_number_formatting.forms.length_of_number_label')"
            name="padding"
          />

          <TextField
            v-model="returnOrderNumberFormatForm.increment"
            :error="returnOrderNumberFormatForm.errors.increment"
            :label="t('panel.settings.general_settings.general_settings.return_number_formatting.forms.next_number_label')"
            name="increment"
          />
        </FormLayout>
      </CardSection>
      <CardFooter>
        <Button
          :disabled="!returnOrderNumberFormatForm.isDirty"
          :loading="returnOrderNumberFormatForm.processing"
          type="submit"
        >
          {{ t('panel.settings.general_settings.general_settings.return_number_formatting.primary_footer_action') }}
        </Button>
      </CardFooter>
    </Card>
  </Form>
</template>
