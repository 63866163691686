<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  List,
  ListItem,
  Modal,
  Select,
  TextInput,
  TextInputFieldTypes,
  TextStyle,
  Toggle,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import { BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { onMounted, PropType } from 'vue';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;

type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'verify'): void;
}>();

const form = useForm({
  title: '',
  description: '',
  basic_auth_username: '',
  basic_auth_password: '',
  order_enabled: true,
  order_url: '',
  order_url_method: 'GET',
  return_enabled: false,
  return_url: '',
  coupon_enabled: false,
  coupon_url: '',
  giftcard_enabled: false,
  giftcard_url: '',
  invoice_enabled: false,
  invoice_url: '',
});

const {
  currentStep,
  firstStep,
  lastStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function verify(): void {
  form.submit('post', route('panel.integration.open.save-credentials'), {
    onSuccess: () => emit('verify'),
  });
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.dashboard.integrations.open.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <div
      v-if="currentStep === 1"
      class="space-y-6"
    >
      <div class="space-y-1 mt-6">
        <TextStyle>
          <List marker-subdued>
            <ListItem>{{ $t('panel.dashboard.integrations.open.step1.item1') }}</ListItem>
            <ListItem>{{ $t('panel.dashboard.integrations.open.step1.item2') }}</ListItem>
            <ListItem>{{ $t('panel.dashboard.integrations.open.step1.item3') }}</ListItem>
          </List>
        </TextStyle>
      </div>

      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.title"
            :label="$t('panel.dashboard.integrations.open.step1.title')"
            :error="form.errors.title"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.description"
            :label="$t('panel.dashboard.integrations.open.step1.description')"
            :error="form.errors.description"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_username"
            :label="$t('panel.dashboard.integrations.open.step1.basic_auth_username')"
            :error="form.errors.basic_auth_username"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_password"
            :label="$t('panel.dashboard.integrations.open.step1.basic_auth_password')"
            :type="TextInputFieldTypes.Password"
            :error="form.errors.basic_auth_password"
          />
        </FormLayout>

        <div class="space-y-2">
          <div
            class="flex space-x-2"
            :class="[form.errors.order_url ? 'items-start' : 'items-center']"
          >
            <div class="w-1/4">
              <Select
                v-model="form.order_url_method"
                :options="[
                  { label: 'GET', value: 'GET' },
                  { label: 'POST', value: 'POST' },
                ]"
              />
            </div>
            <div class="flex-1">
              <TextInput
                v-model="form.order_url"
                placeholder="https://www.yourapplication.com/api/order-search-example/"
                :error="form.errors.order_url"
              />
            </div>
          </div>
        </div>

        <div class="space-y-2">
          <Toggle
            v-model="form.return_enabled"
            class="mb-1"
            :title="$t('panel.dashboard.integrations.open.step1.create_return')"
          />
          <TextInput
            v-model="form.return_url"
            placeholder="https://www.yourapplication.com/api/create-return-example/"
            :error="form.errors.return_url"
          />
        </div>

        <div class="space-y-2">
          <Toggle
            v-model="form.coupon_enabled"
            class="mb-1"
            :title="$t('panel.dashboard.integrations.open.step1.create_coupon')"
          />
          <TextInput
            v-model="form.coupon_url"
            placeholder="https://www.yourapplication.com/api/create-coupon-example/"
            :error="form.errors.coupon_url"
          />
        </div>

        <div class="space-y-2">
          <Toggle
            v-model="form.giftcard_enabled"
            class="mb-1"
            :title="$t('panel.dashboard.integrations.open.step1.create_gift_card')"
          />
          <TextInput
            v-model="form.giftcard_url"
            placeholder="https://www.yourapplication.com/api/create-gift-card-example/"
            :error="form.errors.giftcard_url"
          />
        </div>

        <div class="space-y-2">
          <Toggle
            v-model="form.invoice_enabled"
            class="mb-1"
            :title="$t('panel.dashboard.integrations.open.step1.create_credit_invoice')"
          />
          <TextInput
            v-model="form.invoice_url"
            placeholder="https://www.yourapplication.com/api/create-credit-invoice-example/"
            :error="form.errors.invoice_url"
          />
        </div>
      </FormLayout>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.open.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.open.activate_integration_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
