<script lang="ts" setup>
import {
  Card,
  CardSection,
  HeadingElement,
  HeadingSize,
  Stack,
  StackItem,
  StackSpacing,
  Toggle,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import Heading from '@app/panel/Components/Heading/components/Heading.vue';
import type { Types } from '@app/shared/types/generated-v2';
import { onMounted, ref } from 'vue';
import { useIsDirty } from '@app/panel/Composables/useIsDirty';
import { useLoading } from '@app/panel/Composables/useLoading';

interface NotificationPreference {
  notification: string;
  route: string;
  action: 'enable' | 'disable';
}

type NotificationPreferenceViewModel = Types['App.Http.ViewModels.Panel.Account.NotificationViewModel'];

const props = defineProps< {
  notifications: Types['App.Http.ViewModels.Panel.Account.PreferencesViewModel']['notifications'];
}>();

const notificationPreferences = ref<NotificationPreference[]>([]);
const { isDirty, reset } = useIsDirty(notificationPreferences);
const { isLoading, startLoading, stopLoading } = useLoading();

function initNotificationPreferences(): void {
  Object.values(props.notifications).flat()
    .forEach((notification) => {
      notificationPreferences.value.push({
        notification: notification.notification,
        route: 'mail',
        action: notification.notifications?.includes('mail')
          ? 'enable'
          : 'disable',
      });

      reset(notificationPreferences.value);
    });
}

onMounted(() => initNotificationPreferences());

function findPreference(notification: NotificationPreferenceViewModel): NotificationPreference {
  return notificationPreferences.value.find((preference) => preference.notification === notification.notification);
}

function togglePreference(notification: NotificationPreferenceViewModel, value: boolean) {
  const preference = findPreference(notification);
  const action = value ? 'enable' : 'disable';

  preference.action = action;
}

function savePreferences() {
  router.put(route('panel.account.update-notification'), {
    notification_preferences: notificationPreferences.value,
  }, {
    preserveScroll: true,
    onBefore: () => startLoading(),
    onFinish: () => {
      stopLoading();
      reset();
    },
  });
}
</script>

<template>
  <Card
    :title="$t('panel.account:preferences:notifications:card_title')"
    :subtitle="$t('panel.account:preferences:notifications:card_subtitle')"
    :primary-footer-action="{
      content: $t('panel.account:preferences:notifications:save_button'),
      callback: () => savePreferences(),
      disabled: ! isDirty,
      loading: isLoading,
    }"
  >
    <CardSection>
      <div
        v-for="(groupNotifications, group) in notifications"
        :key="group"
        class="mb-3"
      >
        <Heading
          :element="HeadingElement.H4"
          :size="HeadingSize.Small"
          class="mb-3"
        >
          {{ group }}
        </Heading>

        <div
          v-for="(notification, index) in groupNotifications"
          :key="index"
        >
          <Stack
            vertical
            :spacing="StackSpacing.BaseTight"
          >
            <StackItem>
              <Toggle
                :model-value="findPreference(notification)?.action === 'enable'"
                :name="notification.title"
                value="mail"
                :title="notification.title"
                :subtitle="notification.description ? notification.description: null"
                @update:model-value="value => togglePreference(notification, value as boolean)"
              />
            </StackItem>
          </Stack>
        </div>
      </div>
    </CardSection>
  </Card>
</template>
