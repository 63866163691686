<script lang='ts' setup>
import { computed, reactive, ref, watch } from 'vue';
import { Line } from 'vue-chartjs';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { TextStyle } from '../../TextStyle';
import { ChartColor, chartColors, options } from '../config';
import { cloneDeep } from 'lodash';
import { ActiveDataPoint } from '@app/panel/Pages/Analytics/types/ActiveDataPoint';
import { onBeforeUnmount } from 'vue';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
);

const props = withDefaults(defineProps<{
  labels: string[];
  datasets: { label: string; data: number[] }[];
  height?: number | 'h-full';
  showLegend?: boolean;
  hideYAxis?: boolean;
}>(), {
  height: 80,
  showLegend: false,
  hideYAxis: false,
});

const emit = defineEmits<{
  activeDataPointChanged: [activeDataPoint: null | ActiveDataPoint];
}>();

const computedDatasets = computed(() => {
  return props.datasets.map((dataset, index) => {
    return Object.assign(dataset, { ...chartColors[index], borderColor: chartColors[index].backgroundColor });
  });
});

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: computedDatasets.value,
  };
});

const legendLabels = computed(() => {
  return props.datasets.reduce((previous: string[], current) => {
    if (current['label']) {
      previous.push(current['label']);
    }

    return previous;
  }, []);
});

const $line = ref(null);

const active = reactive({
  data: {},
  labels: {},
});

const hasActive = computed(() => active.data[0] !== undefined && active.data[0] !== null);

watch(active, () => {
  const activeItem = hasActive.value ? { amount: active.data[0], label: active.labels[0] } : null;

  emit('activeDataPointChanged', activeItem);
});

function renderActiveElementIndicator(ctx, chartArea, metaData, index) {
  ctx.beginPath();
  ctx.setLineDash([2, 5]);
  ctx.moveTo(metaData.x, chartArea.bottom);
  ctx.lineTo(metaData.x, 0);
  ctx.strokeStyle = ChartColor.Slate400;
  ctx.stroke();
  ctx.setLineDash([]);
  ctx.closePath();

  ctx.fillStyle = chartColors[index].hoverBackgroundColor;
  ctx.beginPath();
  ctx.arc(metaData.x, metaData.y, 4, 0, 2 * Math.PI);
  ctx.fill();
  ctx.stroke();
}

function resetActiveData(line) {
  active.data = {};
  active.labels = {};

  line.updateChart();
}

function trackActiveLineElement(e, line) {
  e.preventDefault();
  e.stopPropagation();

  line.updateChart();

  const lineChart = line.chart;
  const { ctx, chartArea } = lineChart;

  const points = lineChart.getElementsAtEventForMode(e, 'index', { intersect: false }, true);

  lineChart.data.datasets.forEach((_, i) => {
    if (points.length && points[i]) {
      const point = points[i];
      active.data[i] = lineChart.data.datasets[point.datasetIndex].data[point.index];
      active.labels[i] = lineChart.data.labels[point.index];
      renderActiveElementIndicator(ctx, chartArea, points[i].element, i);
    } else {
      active.data[i] = null;
      active.labels[i] = null;
    }
  });
}

function onCanvasMouseMove(event) {
  trackActiveLineElement(event, $line.value);
}

function onCanvasActiveDataReset() {
  resetActiveData($line.value);
}

function onChartRender() {
  $line.value.updateChart();

  $line.value.chart.canvas.addEventListener('mousemove', onCanvasMouseMove, true);
  $line.value.chart.canvas.addEventListener('mouseleave', onCanvasActiveDataReset);
}

const lineChartOptions = cloneDeep(options);
lineChartOptions.scales.y.ticks.display = !props.hideYAxis;
lineChartOptions.scales.x.grid.display = true;

onBeforeUnmount(() => {
  $line.value.chart.canvas.removeEventListener('mousemove', onCanvasMouseMove, true);
  $line.value.chart.canvas.removeEventListener('mouseleave', onCanvasActiveDataReset);
});
</script>

<template>
  <div>
    <div>
      <div class="flex justify-between">
        <div
          v-if="showLegend"
          class="flex space-x-4 ml-auto"
        >
          <div
            v-for="(label, index) in legendLabels"
            :key="index"
            class="flex items-center space-x-2"
          >
            <div
              class="rounded-full w-4 h-4"
              :style="{backgroundColor: chartColors[index].backgroundColor}"
            />

            <TextStyle>
              {{ label }}
            </TextStyle>
          </div>
        </div>
      </div>
    </div>

    <Line
      ref="$line"
      :chart-data="chartData"
      :chart-options="lineChartOptions"
      :height="height"
      @chart:rendered="onChartRender"
    />
  </div>
</template>
