<script lang="ts" setup>

import {
  Alert,
  AlertDescription,
  Button,
  ButtonGroup,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardIcon,
  CardSection,
  CardTitle,
  FormLayout,
  TextStyle,
} from '@returnless/focus-ui';
import { asset } from 'laravel-vapor';
import { PaywallPageViewModel } from './types';

const props = defineProps<PaywallPageViewModel>();
const isBlocked: boolean = props.tenant.limitAccessType === 'disable-account';

</script>

<template>
  <div class="flex h-screen w-screen items-center justify-center">
    <div class="mx-auto w-full max-w-2xl px-4">
      <Card>
        <CardHeader>
          <CardIcon
            :source="asset('img/favicon_light.svg')"
            alt="Returnless"
          />
          <CardTitle>{{ isBlocked ? $t('panel.paywall:disabled-account:title') : $t('panel.paywall:paywall:title') }}</CardTitle>
          <CardDescription>{{ isBlocked ? $t('panel.paywall:disabled-account:description') : $t('panel.paywall:paywall:description') }}</CardDescription>
        </CardHeader>
        <CardSection>
          <FormLayout>
            <FormLayout>
              <TextStyle strong>
                {{ $t('panel.paywall:bank:title') }}
              </TextStyle>
              <Alert>
                <AlertDescription class="!block">
                  <TextStyle>
                    {{ $t('panel.paywall:bank:account-holder') }}
                  </TextStyle>
                  <br>
                  <TextStyle>
                    {{ $t('panel.paywall:bank:iban') }}
                  </TextStyle>
                  <br>
                  <TextStyle>
                    {{ $t('panel.paywall:bank:bic-swift') }}
                  </TextStyle>
                </AlertDescription>
              </Alert>
            </FormLayout>

            <TextStyle>
              {{ $t('panel.paywall:follow-up') }}
            </TextStyle>
          </FormLayout>
        </CardSection>

        <CardFooter>
          <ButtonGroup>
            <Button
              variant="secondary"
              native
              href="mailto:support@returnless.com"
            >
              {{ $t('panel.paywall:contact-support') }}
            </Button>
            <Button :href="$route('panel.account.billing')">
              {{ $t('panel.paywall:action') }}
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  </div>
</template>
