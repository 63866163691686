<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { theme } from '../../../Composables/useTheme';
import ErrorMessage from '@app/panel/Components/ErrorMessage/components/ErrorMessage.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';

const props = withDefaults(defineProps<{
  modelValue: string[] | boolean;
  id?: null | string;
  label?: null | string;
  name?: null | string;
  value?: string | null;
  title?: null | string;
  subtitle?: null | string;
  disabled?: boolean;
  error?: string | null;
  size?: 'sm' | 'md';
}>(), {
  id: null,
  label: null,
  name: null,
  value: null,
  title: null,
  subtitle: null,
  modelValue: false,
  disabled: false,
  error: null,
  size: 'md',
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean | unknown[]): void;
}>();

const elementId = computed(() => props.id || useUniqueId('searchField'));

const checked = computed(() => {
  if (props.modelValue instanceof Array) {
    return props.modelValue.includes(props.value);
  }

  return props.modelValue;
});

const onToggleChange = (e: InputEvent): void => {
  if (props.disabled) {
    return;
  }

  const target = e.target as HTMLInputElement;

  if (props.modelValue === null || typeof props.modelValue === 'boolean') {
    return emit('update:modelValue', target.checked);
  }

  const value = target.value;
  const newModelValue = [...props.modelValue];

  if (target.checked) {
    newModelValue.push(value);
  } else {
    newModelValue.splice(newModelValue.indexOf(value), 1);
  }

  emit('update:modelValue', newModelValue);
};

const toggleInput = ref<HTMLInputElement>(null);

function activateToggle() {
  toggleInput.value.click();
}

const classList = computed(() => {
  return {
    'opacity-60 cursor-not-allowed': props.disabled,
    'cursor-pointer': !props.disabled,
  };
});
</script>

<template>
  <div>
    <label
      data-test="toggle"
      :for="elementId"
      class="flex w-fit items-center space-x-3"
      :class="classList"
    >
      <span
        class="relative"
        tabindex="0"
        :class="theme('focus-outline-color')"
        @keyup.enter="activateToggle"
      >

        <input
          :id="elementId"
          ref="toggleInput"
          type="checkbox"
          class="sr-only"
          :name="name"
          :value="value"
          :checked="checked"
          @change="onToggleChange"
        >

        <span
          class="block rounded-full"
          :class="{
            'bg-brand-500': checked,
            'bg-slate-200': ! checked,
            'w-8 h-5': size === 'sm',
            'w-10 h-6': size === 'md',
          }"
        />

        <span
          class="absolute bg-white w-4 h-4 rounded-full transition"
          :class="{
            'translate-x-full': checked && size === 'md',
            'translate-x-[75%]': checked && size === 'sm',
            'left-1 top-1': size === 'md',
            'left-0 top-0 mt-0.5 ml-0.5': size === 'sm',
          }"
        />

      </span>

      <span :class="{'flex flex-col': subtitle}">
        <span
          v-if="title"
          data-test="toggle-title"
          class="text-sm text-slate-900"
          :class="{
            'text-slate-300 opacity-50': props.disabled
          }"
        >
          {{ title }}
        </span>

        <span
          v-if="subtitle"
          data-test="toggle-subtitle"
          class="block text-xs text-slate-500"
        >
          {{ subtitle }}
        </span>
      </span>
    </label>

    <div
      class="flex space-x-1 items-center mt-1"
      :class="{
        'ml-8 pl-3': size === 'sm',
        'ml-10 pl-3': size === 'md',
      }"
    >
      <ExclamationCircleIcon
        v-if="error"
        ref="errorIcon"
        class="h-5 w-5 fill-red-500"
      />

      <ErrorMessage
        v-if="error"
        class="!mt-0"
        :message="error"
      />
    </div>
  </div>
</template>
