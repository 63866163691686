<script lang="ts" setup>
import { Toggle } from '@returnless/focus-ui';

const modelValue = defineModel<boolean>();
</script>

<template>
  <label
    class="inline-flex rounded-md border bg-white px-4 py-2 text-sm font-medium leading-none shadow-sm hover:bg-slate-100 active:opacity-80 active:shadow-inner"
    role="button"
  >
    <Toggle
      v-model="modelValue"
      :label="$t('panel.global:test-mode')"
    />
  </label>
</template>
