<script lang="ts" setup>
import { Select } from '@app/panel/Components';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type Parameter = { label: string; value: string };

type TriggerOption = {
  label: string;
  value: string;
  parameterTitle: string;
  parameters: undefined | Parameter[];
};

const props = defineProps<{
  triggerOptions: TriggerOption[];
  currentSelectedTriggerOption?: TriggerOption | undefined;
  trigger: null | string;
  parameter?: string;
  errors: Record<string, string>;
}>();

const emit = defineEmits<{
  (e: 'update:trigger', value: string);
  (e: 'update:parameter', value: string);
}>();

const triggerBinding = computed({
  get: () => props.trigger,
  set: (newValue) => {
    emit('update:trigger', newValue);
    parameterBinding.value = null;
  },
});

const parameterBinding = computed({
  get: () => props.parameter,
  set: (newValue) => emit('update:parameter', newValue),
});

const selectedTriggerOption = computed<null | TriggerOption>(() => {
  return props.triggerOptions.find((option) => option.value === props.trigger) ?? null;
});

const parameters = computed<null | Parameter[]>(() => {
  return selectedTriggerOption.value?.parameters ?? null;
});

const inapplicableTriggerError = computed<string | null>(() => {
  if (triggerBinding.value === null) {
    return null;
  }

  const isValidTrigger = props.triggerOptions.some((option) => option.value === triggerBinding.value);

  return isValidTrigger
    ? null
    : t('panel.forms:trigger:not_applicable');
});

const applicableTriggerOptions = computed(() => {
  if (inapplicableTriggerError.value === null) {
    return props.triggerOptions;
  }

  return [...props.triggerOptions, props.currentSelectedTriggerOption];
});

</script>

<template>
  <div class="flex flex-col items-start space-y-4">
    <div
      class="flex w-full items-start space-x-3"
    >
      <div class="flex-1">
        <Select
          v-model="triggerBinding"
          :label="$t('panel.settings:form:automations:create:trigger-label')"
          :options="applicableTriggerOptions"
          :error="errors.trigger ?? inapplicableTriggerError"
        />
      </div>

      <div
        v-if="parameters !== null"
        class="flex-1"
      >
        <Select
          v-model="parameterBinding"
          :label="selectedTriggerOption.parameterTitle"
          :options="parameters"
          :error="errors.parameter"
          :disabled="triggerOptions === null"
        />
      </div>
    </div>
  </div>
</template>
