<script lang="ts" setup>
import { CardSection, Form, Stack, StackItem, StackSpacing, TextInput, Toggle } from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type FormShippingSettingsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel;
const props = defineProps<{
  form: FormShippingSettingsViewModel['form'];
  countryOptions: FormShippingSettingsViewModel['countryOptions'];
}>();

const form = useForm({
  default_product_weight: props.form.defaultProductWeight,
  default_product_length: props.form.defaultProductLength,
  default_product_width: props.form.defaultProductWidth,
  default_product_height: props.form.defaultProductHeight,
  dynamic_product_weight: props.form.dynamicProductWeight,
  dynamic_product_dimensions: props.form.dynamicProductDimensions,
});

function save() {
  form.put(route('panel.settings.forms.shipping-settings.shipment-creation.update', { form: props.form }));
}

</script>

<template>
  <Form @submit="save">
    <Stack
      vertical
      :spacing="StackSpacing.ExtraLoose"
    >
      <StackItem>
        <Card>
          <CardHeader>
            <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.shipment-creation-settings')">
              {{ $t('panel.settings:forms:shipping-settings:shipping-creation:title') }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <div class="flex flex-col space-y-6">
              <div class="flex w-full items-end space-x-8">
                <div class="flex-1">
                  <TextInput
                    v-model="form.default_product_weight"
                    :label="$t('panel.settings:forms:shipping-settings:shipping-creation:weight:input:title')"
                    :help-text="$t('panel.settings:forms:shipping-settings:shipping-creation:weight:input:subtitle')"
                    :error="form.errors.default_product_weight"
                  />
                </div>
                <div class="flex-1">
                  <Toggle
                    v-model="form.dynamic_product_weight"
                    :title="$t('panel.settings:forms:shipping-settings:shipping-creation:weight:dynamic:title')"
                    :subtitle="$t('panel.settings:forms:shipping-settings:shipping-creation:weight:dynamic:subtitle')"
                  />
                </div>
              </div>
              <div class="flex w-full items-end space-x-8">
                <div class="flex-1 items-end gap-2 grid grid-cols-3">
                  <TextInput
                    v-model="form.default_product_length"
                    :label="$t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:input:title')"
                    :help-text="$t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:input:length:subtitle')"
                    :error="form.errors.default_product_length"
                  />
                  <TextInput
                    v-model="form.default_product_width"
                    :help-text="$t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:input:width:subtitle')"
                    :error="form.errors.default_product_width"
                  />
                  <TextInput
                    v-model="form.default_product_height"
                    :help-text="$t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:input:height:subtitle')"
                    :error="form.errors.default_product_height"
                  />
                </div>
                <div class="flex-1">
                  <Toggle
                    v-model="form.dynamic_product_dimensions"
                    :title="$t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:dynamic:title')"
                    :subtitle="$t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:dynamic:length:subtitle')"
                  />
                </div>
              </div>
            </div>
          </CardSection>
          <CardFooter>
            <Button
              type="submit"
              :loading="form.processing"
              :disabled="!form.isDirty"
            >
              {{ $t('panel.settings:forms:shipping-settings:shipping-creation:dimensions:save') }}
            </Button>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  </Form>
</template>
