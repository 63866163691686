<script setup lang="ts">
import TextStyle from '@app/panel/Components/TextStyle/components/TextStyle.vue';
import type { Types } from '@app/shared/types/generated-v2';
import { Badge } from '@app/customer/Components';

type RangeChartViewModel = Types['App.Http.ViewModels.Panel.Analytics.RangeChartViewModel'];

const props = defineProps<RangeChartViewModel>();

function calculateOffset(lowValue: number) {
  const fullValue = props.to - props.from;
  const offsetValue = lowValue - props.from;

  return Math.round((offsetValue * 100) / fullValue);
}

function calculateWidth(lowValue: number, highValue: number) {
  if (highValue === 0) {
    return 0;
  }

  const fullValue = props.to - props.from;
  const widthValue = highValue - lowValue;

  return Math.max(Math.round((widthValue * 100) / fullValue), 1);
}

</script>

<template>
  <div class="space-y-3">
    <div
      v-for="data in dataset"
      :key="data.label"
      class="flex justify-between items-center w-full group"
    >
      <div class="space-x-4 w-80">
        <TextStyle>
          {{ data.label }}
        </TextStyle>

        <Badge
          v-if="data.highValue > 0"
          color="slate"
        >
          {{ data.lowValue }} ~ {{ data.highValue }}
        </Badge>
      </div>
      <div
        :title="`${data.lowValue } ~ ${ data.highValue }`"
        class="flex justify-between w-full space-x-2 items-center"
      >
        <span class="text-xs text-slate-500 font-medium">{{ from }}</span>
        <div class="relative w-full bg-slate-200 h-2">
          <span
            class="absolute rounded-full h-2 group-hover:brightness-75"
            :class="`bg-${data.color}-500`"
            :style="`left: ${calculateOffset(data.lowValue )}%; width: ${calculateWidth(data.lowValue, data.highValue)}%;`"
          />
        </div>
        <span class="text-xs text-slate-500 font-medium">{{ to }}</span>
      </div>
    </div>
  </div>
</template>
