<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import { GuestLayout } from '@app/panel/Layouts';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { asset } from 'laravel-vapor';
import * as Types from '@app/panel/types/generated';

type ShowFinishExpressRegisterViewModel = Types.App.Http.ViewModels.Panel.Auth.ShowFinishExpressRegisterViewModel;

const props = defineProps<{
  postUrl: ShowFinishExpressRegisterViewModel['postUrl'];
}>();

const form = useForm({
  password: '',
  password_confirmation: '',
});

function submit() {
  form.post(props.postUrl);
}
</script>

<template>
  <GuestLayout
    :title="$t('panel.auth:finish_express_register:title')"
    :hero-image="asset('img/login-background.jpeg')"
  >
    <Form @submit="submit">
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.password"
            :error="form.errors.password"
            :label="$t('panel.auth:finish_express_register:password')"
            :placeholder="$t('panel.auth:finish_express_register:password-placeholder')"
            autocomplete="current-password"
            name="password"
            required
            :type="TextInputFieldTypes.Password"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.password_confirmation"
            :error="form.errors.password_confirmation"
            :label="$t('panel.auth:finish_express_register:confirm_password')"
            :placeholder="$t('panel.auth:finish_express_register:confirm_password-placeholder')"
            autocomplete="new-password"
            name="password-confirmation"
            required
            type="password"
          />
        </FormLayout>

        <FormLayout>
          <Button
            full-width
            :type="ButtonType.Submit"
            :loading="form.processing"
            :variant="ButtonVariant.Primary"
          >
            {{ $t('panel.auth:finish_express_register:finish_button') }}
          </Button>
        </FormLayout>
      </FormLayout>
    </Form>
  </GuestLayout>
</template>
