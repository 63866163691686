<script lang="ts" setup>
import { Card, CardHeader, CardLayout, CardSection, CardTitle } from '@returnless/focus-ui';
import { computed } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';
import StoreLocationsDataTableFooter from './StoreLocationsDataTableFooter.vue';
import StoreLocationsDataTableView from './StoreLocationsDataTableView.vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';
import { useI18n } from 'vue-i18n';

type FormStoreLocationListViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationListViewModel'];
type FormStoreLocationItemViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationItemViewModel'];
type FormViewModel = Types['App.Models.ViewModels.FormViewModel'];

const { t } = useI18n();

const props = defineProps<{
  dataTableItems: FormStoreLocationItemViewModel[];
  selectedDataTableItems: FormStoreLocationItemViewModel[];
  form: FormViewModel;
  formStoreLocationsListViewModel: FormStoreLocationListViewModel;
}>();

const emit = defineEmits<{
  'update:dataTableItems': [value: FormStoreLocationItemViewModel[]];
  'update:selectedDataTableItems': [value: FormStoreLocationItemViewModel[]];
}>();

const computedDataTableItems = computed({
  get: () => props.dataTableItems,
  set: (value) => emit('update:dataTableItems', value),
});

const computedSelectedDataTableItems = computed({
  get: () => props.selectedDataTableItems,
  set: (value) => emit('update:selectedDataTableItems', value),
});
</script>

<template>
  <StoreLocationsDataTableView
    v-model:data-table-items="computedDataTableItems"
    v-model:selected-data-table-items="computedSelectedDataTableItems"
    :form="form"
  />
  <StoreLocationsDataTableFooter
    :paginator-status="formStoreLocationsListViewModel.paginatorStatus"
    :previous-page-url="formStoreLocationsListViewModel.previousPageUrl"
    :next-page-url="formStoreLocationsListViewModel.nextPageUrl"
  />
</template>
