<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '@app/panel/Pages/Settings/Forms/partials';
import {
  Button,
  ButtonType,
  ButtonVariant,
  EmptyState,
  Form,
  Link,
  PageActions,
  PageActionsPosition,
  UnsupportedFeature,
} from '@app/panel/Components';
import { Page, PageHeader, PageTitle } from '@returnless/focus-ui';
import { computed, provide, ref, watch } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';
import StoreLocationsDataTable from './DataTable/StoreLocationsDataTable.vue';
import { router } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type FormStoreLocationsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationsViewModel'];

const { t } = useI18n();

const props = defineProps<FormStoreLocationsViewModel>();

provide('form', props.form);

const dataTableItems = computed(() => props.storeLocationList.storeLocations);

const selectedDataTableItems = ref([]);

watch(dataTableItems, () => {
  selectedDataTableItems.value = dataTableItems.value.filter((storeLocation) => storeLocation.isSelected);
}, { immediate: true });

function updateCheckedStoreLocations() {
  const selectedStoreLocationIds = selectedDataTableItems.value.flatMap((item) => item.id);

  const selectedStoreLocationStates = dataTableItems.value.map((item) => ({
    id: item.id,
    is_selected: selectedStoreLocationIds.includes(item.id),
  }));

  router.put(route('panel.settings.forms.update-store-locations', { form: props.form.id }), {
    selected_store_location_states: selectedStoreLocationStates,
  }, {
    preserveScroll: true,
    preserveState: false,
  });
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:forms:store-locations:head')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle :page-help="getHelpscoutArticleBeacon('form.store-locations.store-locations')">
          {{ t('panel.settings:forms:store-locations:head') }}
        </PageTitle>
        <template #page-header-actions>
          <Button
            v-if="dataTableItems.length > 0"
            form="datatable-form"
            :variant="ButtonVariant.Primary"
            :type="ButtonType.Submit"
          >
            {{ t('panel.global:actions:save') }}
          </Button>
        </template>
      </PageHeader>

      <div v-if="canUseStoreLocations">
        <EmptyState
          v-if="dataTableItems.length === 0"
          :heading="$t('panel.settings:forms:store-locations:empty-state:heading')"
        >
          {{ $t('panel.settings:forms:store-locations:empty-state:content') }}
          <template #actions>
            <Link
              href="https://docs.returnless.com/docs/product-guide/i4axdkihk628j-manage-store-locations"
              target="_blank"
              native
            >
              {{ $t('panel.settings:forms:store-locations:empty-state:learn-more') }}
            </Link>
            <Button
              variant="primary"
              :href="$route('panel.settings.store-locations')"
            >
              {{ $t('panel.settings:forms:store-locations:empty-state:add-store-location') }}
            </Button>
          </template>
        </EmptyState>

        <Form
          v-if="dataTableItems.length > 0"
          id="datatable-form"
          method="post"
          @submit="updateCheckedStoreLocations"
        >
          <div class="space-y-4">
            <StoreLocationsDataTable
              v-model:data-table-items="dataTableItems"
              v-model:selected-data-table-items="selectedDataTableItems"
              :form="form"
              :form-store-locations-list-view-model="storeLocationList"
            />
          </div>
        </Form>
      </div>

      <div v-if="!canUseStoreLocations">
        <UnsupportedFeature
          :feature="$t('panel.subscriptions:features:store-locations')"
          :available-in="[$t('panel.subscriptions:plans:automate')]"
        />
      </div>
    </Page>
  </AppLayout>
</template>
