<script lang="ts" setup>
import { InputLabel } from '../InputLabel';
import ColorPalette from './colors';

defineProps<{
  label: string;
}>();

const model = defineModel<string>();

const colors = Object.values(ColorPalette);
</script>

<template>
  <div>
    <InputLabel :label="label" />

    <div class="my-2 grid grid-cols-9 gap-x-4 gap-y-3">
      <div
        v-for="(color, index) in colors"
        :key="index"
        class="flex shrink-0 cursor-pointer items-center justify-center rounded-full bg-gradient-to-tr from-white/20 shadow-inner size-6 hover:opacity-75"
        :class="`bg-${color}-500`"
        @click="model = color"
      >
        <div
          v-if="model === color"
          class="rounded-full bg-white shadow size-2"
        />
      </div>
    </div>
  </div>
</template>
