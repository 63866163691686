<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  InputLabel,
  Link,
  Modal,
  ModalSize,
  Select,
  TextInput,
  TextInputFieldTypes,
  TextInputIconPosition,
  Toggle,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { CubeIcon } from '@heroicons/vue/24/outline';
import TextContainer from '@app/customer/Components/TextContainer/components/TextContainer.vue';

type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;

const props = defineProps<{
  open: boolean;
  form: FormShippingMethodsViewModel['form'];
  customShippingContracts: FormShippingMethodsViewModel['customShippingContracts'];
  allCountries: FormShippingMethodsViewModel['allCountries'];
  shippingMethodTypes: FormShippingMethodsViewModel['shippingMethodTypes'];
  returnAddresses: FormShippingMethodsViewModel['returnAddresses'];
  hasCustomShippingIntegration: FormShippingMethodsViewModel['hasCustomShippingIntegration'];
  shippingInstructions: FormShippingMethodsViewModel['shippingInstructions'];
  selectedContract: FormShippingMethodsViewModel['selectedContract'];
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { t } = useI18n();

function close() {
  emit('close');
}

function getDefaultContract(): string {
  if (props.selectedContract?.shippingIntegration.driver === 'custom') {
    return props.selectedContract.id;
  }

  if (props.customShippingContracts.length === 1) {
    return props.customShippingContracts[0].id;
  }

  return null;
}

function getDefaultReturnAddress() {
  if (props.returnAddresses.length === 1) {
    return props.returnAddresses[0].id;
  }

  return null;
}

const shippingMethodForm = useForm({
  name: null,
  shipping_contract_id: getDefaultContract(),
  from_country_id: null,
  return_addresses_id: getDefaultReturnAddress(),
  minimal_weight: null,
  maximum_weight: null,
  minimum_width: 0,
  minimum_height: 0,
  minimum_length: 0,
  maximum_width: null,
  maximum_height: null,
  maximum_length: null,
  type: null,
  is_printerless: false,
  shipping_instruction_id: null,
  is_preferred: false,
  price: null,
});

function submit() {
  shippingMethodForm.post(route('panel.settings.forms.custom-shipping-methods.create', props.form), {
    preserveState: (item) => {
      // should preserve state if there are any errors
      return Object.keys(item.props.errors).length > 0;
    },
  });
}

const shippingContractOptions = computed(() => {
  return props.customShippingContracts.map((contract) => {
    return {
      label: contract.name,
      value: contract.id,
    };
  });
});

const shippingMethodTypeOptions = computed(() => {
  return props.shippingMethodTypes.filter((shippingMethodType) => {
    return shippingMethodType !== 'manual';
  }).map((shippingMethodType) => {
    return {
      label: t(`panel.shipping-method-type:${shippingMethodType}`),
      value: shippingMethodType,
    };
  });
});

const allCountryOptions = computed(() => {
  return props.allCountries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

const returnAddressesOptions = computed(() => {
  return props.returnAddresses.map((returnAddress) => {
    return {
      label: returnAddress.name,
      value: returnAddress.id,
    };
  });
});

const shippingInstructionOptions = computed(() => {
  return [
    { label: '-', value: null },
    ...props.shippingInstructions.map((instruction) => {
      return {
        label: instruction.name,
        value: instruction.id,
      };
    }),
  ];
});
</script>

<template>
  <Modal
    :open="open"
    :size="ModalSize.Large"
    icon-color="blue"
    :title="$t('panel.forms:shipping-methods:add:title')"
    @close="close"
  >
    <template #icon>
      <CubeIcon class="w-5" />
    </template>

    <div class="mb-8">
      <TextContainer>
        <i18n-t
          v-if="hasCustomShippingIntegration"
          keypath="panel.forms:shipping-methods:add:with-contract:description"
          for="panel.forms:shipping-methods:add:with-contract:description"
        >
          <template #guide>
            <Link
              native
              target="_blank"
              href="https://docs.returnless.com/docs/product-guide/38icogh3qauac-shipping-methods"
            >
              {{ $t('panel.forms:shipping-methods:add:with-contract:description:guide') }}
            </Link>
          </template>
        </i18n-t>
        <i18n-t
          v-if="! hasCustomShippingIntegration"
          keypath="panel.forms:shipping-methods:add:without-contract:description"
          for="panel.forms:shipping-methods:add:without-contract:description"
        >
          <template #shippingContract>
            <Link
              :href="$route('panel.settings.integrations.shipping-contracts.list-integrations')"
            >
              {{ $t('panel.forms:shipping-methods:add:without-contract:description:shipping-contracts') }}
            </Link>
          </template>
        </i18n-t>
      </TextContainer>
    </div>

    <Form
      v-if="hasCustomShippingIntegration"
      id="createShippingMethod"
      @submit="submit"
    >
      <FormLayout>
        <FormLayout :columns="2">
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.name"
              :label="$t('panel.forms:shipping-methods:add:name')"
              :error="shippingMethodForm.errors.name"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="shippingMethodForm.shipping_contract_id"
              :label="$t('panel.forms:shipping-methods:add:contract')"
              :options="shippingContractOptions"
              :error="shippingMethodForm.errors.shipping_contract_id"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="shippingMethodForm.from_country_id"
              :label="$t('panel.forms:shipping-methods:add:from-country')"
              :options="allCountryOptions"
              :error="shippingMethodForm.errors.from_country_id"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="shippingMethodForm.return_addresses_id"
              :label="$t('panel.forms:shipping-methods:add:return-address')"
              :options="returnAddressesOptions"
              :error="shippingMethodForm.errors.return_addresses_id"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimal_weight"
              :label="$t('panel.forms:shipping-methods:add:minimal-weight')"
              :error="shippingMethodForm.errors.minimal_weight"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_weight"
              :label="$t('panel.forms:shipping-methods:add:maximum-weight')"
              :error="shippingMethodForm.errors.maximum_weight"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="3">
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimum_width"
              :label="$t('panel.forms:shipping-methods:add:minimum-width')"
              :error="shippingMethodForm.errors.minimum_width"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimum_height"
              :label="$t('panel.forms:shipping-methods:add:minimum-height')"
              :error="shippingMethodForm.errors.minimum_height"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimum_length"
              :label="$t('panel.forms:shipping-methods:add:minimum-length')"
              :error="shippingMethodForm.errors.minimum_length"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="3">
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_width"
              :label="$t('panel.forms:shipping-methods:add:maximum-width')"
              :error="shippingMethodForm.errors.maximum_width"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_height"
              :label="$t('panel.forms:shipping-methods:add:maximum-height')"
              :error="shippingMethodForm.errors.maximum_height"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_length"
              :label="$t('panel.forms:shipping-methods:add:maximum-length')"
              :error="shippingMethodForm.errors.maximum_length"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="2">
          <FormLayout>
            <Select
              v-model="shippingMethodForm.type"
              :label="$t('panel.forms:shipping-methods:add:type')"
              :options="shippingMethodTypeOptions"
              :error="shippingMethodForm.errors.type"
            />
          </FormLayout>
          <FormLayout>
            <div class="flex flex-col">
              <InputLabel
                class="shrink-0"
                :label="$t('panel.settings:general:shipping-methods:printerless:title')"
              />
              <div class="flex-1 flex items-center">
                <Toggle
                  v-model="shippingMethodForm.is_printerless"
                  :title="$t('panel.settings:general:shipping-methods:printerless:toggle')"
                />
              </div>
            </div>
          </FormLayout>
          <FormLayout>
            <Select
              v-model="shippingMethodForm.shipping_instruction_id"
              :label="$t('panel.settings:general:shipping-methods:shipping-instruction')"
              :options="shippingInstructionOptions"
              :error="shippingMethodForm.errors.shipping_instruction_id"
            />
          </FormLayout>
          <FormLayout>
            <FormLayout>
              <TextInput
                v-model="shippingMethodForm.price"
                :icon-position="TextInputIconPosition.Left"
                :label="$t('panel.forms:shipping-methods:edit-custom:price')"
                :type="TextInputFieldTypes.Number"
                :error="shippingMethodForm.errors.price"
                allow-decimal
              />
            </FormLayout>
          </FormLayout>
          <FormLayout>
            <div class="flex flex-col">
              <InputLabel
                class="shrink-0"
                :label="$t('panel.settings:general:shipping-methods:preferred:title')"
              />
              <div class="flex-1 flex items-center">
                <Toggle
                  v-model="shippingMethodForm.is_preferred"
                  :title="$t('panel.settings:general:shipping-methods:preferred:toggle')"
                />
              </div>
            </div>
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </Form>

    <template
      v-if="hasCustomShippingIntegration"
      #actions
    >
      <Button
        :loading="shippingMethodForm.processing"
        @click="close"
      >
        {{ $t('panel.forms:shipping-methods:add:cancel') }}
      </Button>
      <Button
        form="createShippingMethod"
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
      >
        {{ $t('panel.forms:shipping-methods:add:save') }}
      </Button>
    </template>
  </Modal>
</template>
