<script lang="ts" setup>
import { computed, inject } from 'vue';
import { InertiaForm } from '@inertiajs/vue3';
import { TextInput, TextInputFieldTypes } from '@app/panel/Components';

const props = defineProps({
  children: {
    type: Array,
    default: () => ([]),
  },

  fieldName: {
    type: String,
    required: true,
  },

  label: {
    type: String,
    required: true,
  },

  value: {
    type: String,
    required: true,
  },

  allowDecimal: {
    type: Boolean,
    default: true,
  },
});

const form = inject<InertiaForm<any>>('traitForm');

const step = computed(() => {
  if (props.allowDecimal) {
    return 'any';
  }

  return null;
});
</script>

<template>
  <div class="flex justify-between items-center py-4">
    <span class="flex-1">{{ label }}</span>

    <div class="w-32">
      <TextInput
        v-model="form[fieldName]"
        :type="TextInputFieldTypes.Number"
        :step="step"
      />
    </div>
  </div>
</template>
