<script lang="ts" setup>
import {
  ButtonType,
  CardSection,
  Form,
  FormLayout,
  Select,
  Stack,
  StackItem,
  StackSpacing,
  Toggle,
} from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import { type Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const props = defineProps<{
  form: Types['App.Models.ViewModels.FormViewModel'];
  printProductIdentifierField: string;
  printProductBarcodeField: string;
  barcodeTypeOptions: Types['App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel']['barcodeTypeOptions'];
  printProductFieldTypeOptions: Types['App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel']['printProductFieldTypeOptions'];
}>();

const form = useForm({
  is_print_return_form_enabled: props.form.isPrintReturnFormEnabled,
  barcode_type: props.form.barcodeType,
  print_product_identifier_field: props.printProductIdentifierField,
  print_product_barcode_field: props.printProductBarcodeField,
});

function save(): void {
  form.put(route('panel.settings.forms.shipping-settings.printable-settings.update', props.form), {
    preserveScroll: true,
  });
}
</script>

<template>
  <div>
    <Form @submit="save">
      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem>
          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.printable-return-form')">
                {{ $t('panel.settings:forms:shipping-settings:printable-settings:title') }}
              </CardTitle>
            </CardHeader>
            <CardSection>
              <FormLayout>
                <FormLayout>
                  <Toggle
                    v-model="form.is_print_return_form_enabled"
                    :title="$t('panel.settings:forms:shipping-settings:printable-settings:enable_print_return_form')"
                  />
                </FormLayout>
                <FormLayout>
                  <Select
                    v-model="form.barcode_type"
                    :options="barcodeTypeOptions"
                    :label="$t('panel.settings:forms:shipping-settings:printable-settings:barcode-type')"
                  />
                </FormLayout>
                <FormLayout>
                  <Select
                    v-model="form.print_product_identifier_field"
                    :options="printProductFieldTypeOptions"
                    :label="$t('panel.settings:forms:shipping-settings:printable-settings:product-identifier')"
                  />
                </FormLayout>
                <FormLayout>
                  <Select
                    v-model="form.print_product_barcode_field"
                    :options="printProductFieldTypeOptions"
                    :label="$t('panel.settings:forms:shipping-settings:printable-settings:product-barcode')"
                  />
                </FormLayout>
              </FormLayout>
            </CardSection>
            <CardFooter>
              <Button
                type="submit"
                :loading="form.processing"
                :disabled="!form.isDirty"
              >
                {{ $t('panel.global:actions:save') }}
              </Button>
            </CardFooter>
          </Card>
        </StackItem>
      </Stack>
    </Form>
  </div>
</template>
