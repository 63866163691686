<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';
import { Spinner } from '../../Spinner';
import { theme } from '../../../Composables/useTheme';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },

  modelValue: {
    type: File,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  accept: {
    type: Array,
    default: () => [
      'image/apng',
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'image/webp',
    ],
  },
});

const emit = defineEmits(['update:modelValue', 'change']);

const handleFileChange = (e: InputEvent): void => {
  const target = e.target as HTMLInputElement;

  emit('update:modelValue', target.files[0]);
  emit('change', target.files[0]);
};

const acceptableFileTypes = computed(() => {
  return props.accept.join(', ');
});

const fileInput = ref<HTMLInputElement>(null);

function activeFileInput() {
  fileInput.value.click();
}
</script>

<template>
  <label
    data-test="file-upload-button"
    tabindex="0"
    class="relative inline-block text-sm cursor-pointer file-select border border-slate-300 bg-white py-2 px-3 font-medium text-slate-700 hover:bg-slate-50 focus:outline-none rounded-md"
    :class="[
      theme('focus-ring-color'),
      theme('focus-ring-size'),
      theme('focus-ring-offset')
    ]"
    @keyup.enter="activeFileInput"
  >
    <span :class="{ 'invisible': loading }">
      {{ label }}
    </span>

    <input
      ref="fileInput"
      type="file"
      class="hidden"
      :accept="acceptableFileTypes"
      @change="handleFileChange"
    >

    <div
      v-if="loading"
      class="absolute inset-0 flex justify-center items-center"
    >
      <Spinner
        data-test="button-spinner"
      />
    </div>
  </label>
</template>
