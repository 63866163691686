<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { Form as FormType } from '@app/panel/types';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Combobox,
  DataTable,
  Form,
  FormLayout,
  Modal,
  ModalSize,
  Select,
  TextInput,
  TextInputIconPosition,
} from '@app/panel/Components';
import { FooterActionPosition } from '@app/panel/Components/Card/types';
import { router } from '@inertiajs/vue3';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import { LocaleViewModel, PagePropsViewModel } from './../types';

const props = defineProps<{
  filtersOpen: boolean;
  filter: any;
  forms: PagePropsViewModel['forms'];
  brands: PagePropsViewModel['brands'];
  suppliers: PagePropsViewModel['suppliers'];
  returnReasons: PagePropsViewModel['returnReasons'];
  locales: PagePropsViewModel['locales'];
  filterTabs: PagePropsViewModel['filterTabs'];
  hasActiveFilter: PagePropsViewModel['hasActiveFilter'];
}>();

const emit = defineEmits<{
  (e: 'change', form: typeof filterForm): void;
}>();

const saveFilterModalOpen = ref(false);

const saveFilterForm = useForm({
  label: null,
  filters: null,
  type: 'product',
});

const filterForm = useForm({
  filter: {
    name: '',
    sku: '',
    form: '',
    brand: '',
    supplier: '',
    amount_return_orders: { from: '', to: '' },
    quantity_returned: { from: '', to: '' },
    value: { from: '', to: '' },
    order_date: { from: '', to: '' },
    return_reasons: [],
    category: '',
    locale: '',
    ...props.filter.filter,
  },
});

const onFilterChange = (): void => {
  emit('change', filterForm);
};

const formOptions = computed(() => {
  return props.forms.map((form: FormType) => {
    return {
      value: form.id,
      label: form.name,
    };
  });
});

const brandOptions = computed(() => {
  return props.brands.map((brand: string) => {
    return {
      value: brand,
      label: brand,
    };
  });
});

const supplierOptions = computed(() => {
  return props.suppliers.map((supplier: string) => {
    return {
      value: supplier,
      label: supplier,
    };
  });
});

const returnReasonsOptions = computed(() => {
  return props.returnReasons.map((returnReasons) => {
    return {
      value: returnReasons.id,
      label: returnReasons.label,
    };
  });
});

const localeOptions = computed(() => props.locales.map((locale: LocaleViewModel) => ({
  value: locale.id,
  label: locale.name,
})));

const manageFilterModalOpen = ref(false);

function openManageFiltersModal(): void {
  manageFilterModalOpen.value = true;
}

function closeManageFiltersModal(): void {
  manageFilterModalOpen.value = false;
}

function openSaveFilterModal(): void {
  saveFilterModalOpen.value = true;
}

function closeSaveFilterModal(): void {
  saveFilterModalOpen.value = false;

  saveFilterForm.reset();
}

function submitSaveFilter(): void {
  saveFilterForm.filters = window.location.search;
  saveFilterForm.post(route('panel.settings.filter-tabs.store'), {
    onSuccess: () => closeSaveFilterModal(),
  });
}

function deleteFilter(filterTab): void {
  router.delete(route('panel.settings.filter-tabs.delete', filterTab.id));
}

</script>

<template>
  <Card
    v-if="filtersOpen"
    :secondary-footer-actions="[
      {
        content: $t('panel.analytics:products:table:filters:actions:save'),
        type: ButtonType.Submit,
        disabled: ! hasActiveFilter,
        callback: openSaveFilterModal,
      },
      {
        content: $t('panel.analytics:products:table:filters:actions:manage'),
        type: ButtonType.Submit,
        disabled: !filterTabs || filterTabs.length === 0,
        callback: openManageFiltersModal,
      },
    ]"
    :footer-action-position="FooterActionPosition.Left"
  >
    <CardSection>
      <FormLayout>
        <FormLayout :columns="2">
          <TextInput
            v-model="filterForm.filter.name"
            :label="$t('panel.analytics:products:table:filters:fields:name:title')"
            :placeholder="$t('panel.analytics:products:table:filters:fields:name:placeholder')"
            @change="onFilterChange"
          />
          <TextInput
            v-model="filterForm.filter.sku"
            :label="$t('panel.analytics:products:table:filters:fields:sku:title')"
            :placeholder="$t('panel.analytics:products:table:filters:fields:sku:placeholder')"
            @change="onFilterChange"
          />
        </FormLayout>
        <FormLayout
          :columns="3"
        >
          <FormLayout>
            <Select
              v-model="filterForm.filter.form"
              :label="$t('panel.analytics:products:table:filters:fields:form:title')"
              :placeholder="$t('panel.analytics:products:table:filters:fields:from:placeholder')"
              :options="formOptions"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.brand"
              :label="$t('panel.analytics:products:table:filters:fields:brand:title')"
              :placeholder="$t('panel.analytics:products:table:filters:fields:brand:placeholder')"
              :options="brandOptions"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.supplier"
              :label="$t('panel.analytics:products:table:filters:fields:supplier:title')"
              :placeholder="$t('panel.analytics:products:table:filters:fields:supplier:placeholder')"
              :options="supplierOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="4">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.amount_return_orders.from"
              :label="$t('panel.analytics:products:table:filters:fields:amount-return-orders-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.amount_return_orders.to"
              :label="$t('panel.analytics:products:table:filters:fields:amount-return-orders-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.quantity_returned.from"
              :label="$t('panel.analytics:products:table:filters:fields:quantity-returned-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.quantity_returned.to"
              :label="$t('panel.analytics:products:table:filters:fields:quantity-returned-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="4">
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.value.from"
              :label="$t('panel.analytics:products:table:filters:fields:value-from')"
              type="number"
              :icon-position="TextInputIconPosition.Left"
              @change="onFilterChange"
            >
              <template #icon>
                €
              </template>
            </TextInput>
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.value.to"
              :label="$t('panel.analytics:products:table:filters:fields:value-to')"
              type="number"
              :icon-position="TextInputIconPosition.Left"
              @change="onFilterChange"
            >
              <template #icon>
                €
              </template>
            </TextInput>
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.order_date.from"
              :label="$t('panel.analytics:products:table:filters:fields:order-date-from')"
              type="date"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.order_date.to"
              :label="$t('panel.analytics:products:table:filters:fields:order-date-to')"
              type="date"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="3">
          <FormLayout>
            <Combobox
              v-model="filterForm.filter.return_reasons"
              :label="$t('panel.analytics:products:table:filters:fields:return-reasons')"
              :options="returnReasonsOptions"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.category"
              :label="$t('panel.analytics:products:table:filters:fields:category')"
              type="text"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.locale"
              :label="$t('panel.analytics:products:table:filters:fields:locale')"
              :options="localeOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </CardSection>
  </Card>

  <Modal
    :size="ModalSize.Small"
    :open="saveFilterModalOpen"
    @close="closeSaveFilterModal"
  >
    <Form @submit="submitSaveFilter">
      <FormLayout>
        <TextInput
          v-model="saveFilterForm.label"
          :error="saveFilterForm.errors.label"
          :label="$t('panel.analytics:products:table:filters:save:title')"
          :max-length="30"
        />

        <Button
          :disabled="! saveFilterForm.isDirty"
          :type="ButtonType.Submit"
          :variant="ButtonVariant.Primary"
        >
          {{ $t('panel.analytics:products:table:filters:save:button') }}
        </Button>
      </FormLayout>
    </Form>
  </Modal>

  <Modal
    :title="$t('panel.analytics:products:table:filters:manage:title')"
    icon-color="blue"
    :size="ModalSize.Small"
    :open="manageFilterModalOpen"
    @close="closeManageFiltersModal"
  >
    <template #icon>
      <FunnelIcon class="w-5" />
    </template>

    <div class="p-2">
      <DataTable
        :headings="[{
          text: null,
          value: 'label'
        }]"
        :headings-visible="false"
        :items="filterTabs"
      >
        <template #actions="{ item }">
          <Button
            plain
            :variant="ButtonVariant.Critical"
            @click="deleteFilter(item)"
          >
            {{ $t('panel.analytics:products:table:filters:manage:remove') }}
          </Button>
        </template>
      </DataTable>
    </div>
  </Modal>
</template>
