<script lang="ts" setup>
import { ActionList, Button, ButtonVariant, Modal, PageActions, Popover, TextContainer } from '@app/panel/Components';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { computed, inject, provide, ref, Ref } from 'vue';
import * as Types from '@app/panel/types/generated';
import { router } from '@inertiajs/vue3';
import { useModal } from '@app/panel/Composables/useModal';
import { TraitModal } from '@app/panel/Pages/Returns/partials/';
import { Trait, TraitType } from './Trait/types';
import { useI18n } from 'vue-i18n';
import { PrinterIcon } from '@heroicons/vue/24/solid';

const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const returnStatuses = inject<Types.App.Models.ViewModels.ReturnStatusViewModel[]>('returnStatuses');
const traits = inject<Ref<Trait[]>>('integrationTraits');

const statusButton = ref<HTMLElement>(null);
const statusPopoverOpen = ref(false);

const statusItems = computed(() => {
  return returnStatuses.map((status) => {
    return {
      content: status.label,
      onClick: () => changeStatus(status),
      disabled: returnOrder.value.status.label === status.label,
      color: status.color,
    };
  });
});

const {
  isOpen: isDeleteModalOpen,
  close: closeDeleteModal,
  open: openDeleteModal,
} = useModal();

function toggleStatusPopover(): void {
  statusPopoverOpen.value = !statusPopoverOpen.value;
}

function closeStatusPopover(): void {
  statusPopoverOpen.value = false;
}

const traitModalOpen = ref(false);
const selectedTrait = ref(null);
const selectedTraitIndex = ref(null);

provide('selectedTrait', selectedTrait);

function openTraitModal(trait, key): void {
  selectedTrait.value = trait;
  selectedTraitIndex.value = key;
  traitModalOpen.value = true;
}

function closeTraitModal(): void {
  selectedTrait.value = null;
  selectedTraitIndex.value = null;
  traitModalOpen.value = false;
}

function changeStatus(status: Types.App.Models.ViewModels.ReturnStatusViewModel) {
  router.put(route('panel.return-orders.update-status', returnOrder.value), { status: status.id });
  toggleStatusPopover();
}

function deleteOrder(): void {
  router.delete(route('panel.return-orders.delete', returnOrder.value));
}

const { t } = useI18n();

const traitButtonLabels: Record<TraitType, string> = {
  CreateCoupon: t('panel.returns.detail_page_actions.generate_coupon'),
  CreditInvoice: t('panel.returns.detail_page_actions.credit_invoice'),
  GiftCardAccount: t('panel.returns.detail_page_actions.giftcard_account'),
  GiftCard: t('panel.returns.detail_page_actions.create_giftcard'),
  CreateRefund: t('panel.returns.detail_page_actions.create_refund'),
  CreateReturn: t('panel.returns.detail_page_actions.create_return'),
  FinalizeReturn: t('panel.returns.detail_page_actions.finalize_return'),
};

function printReturnForm(): void {
  window.open(route('panel.return-orders.generate-document', { returnOrder: returnOrder.value }));
}
</script>

<template>
  <PageActions>
    <div>
      <div class="flex flex-wrap gap-2">
        <Button
          v-for="(trait, index) in traits"
          :key="index"
          @click="() => openTraitModal(trait, index)"
        >
          <template #icon>
            <img
              class="h-5"
              :src="trait.logo"
              loading="lazy"
            >
          </template>

          {{ traitButtonLabels[trait.trait] }}
        </Button>
      </div>
    </div>

    <div>
      <div class="flex max-h-9 items-center space-x-4">
        <Button
          :variant="ButtonVariant.Critical"
          plain
          @click="openDeleteModal"
        >
          {{ $t('panel.returns.detail_page_actions.delete_button') }}
        </Button>
        <Button
          :variant="ButtonVariant.Default"
          @click="printReturnForm"
        >
          <template #icon>
            <PrinterIcon />
          </template>
          <span>{{ $t('panel.returns.detail_page_actions.print_return') }}</span>
        </Button>
      </div>

      <div ref="statusButton">
        <Button
          :variant="ButtonVariant.Primary"
          @click="toggleStatusPopover"
          @keyup.esc="closeStatusPopover"
        >
          <template #icon>
            <ChevronDownIcon class="h-5" />
          </template>

          {{ $t('panel.returns.detail_page_actions.change_status_button') }}
        </Button>
      </div>

      <Popover
        :active="statusPopoverOpen"
        :parent="statusButton"
        :min-width="200"
        @close="toggleStatusPopover"
      >
        <ActionList :items="statusItems" />
      </Popover>
    </div>
  </PageActions>

  <Modal
    :open="isDeleteModalOpen"
    :title="$t('panel.returns:detail_page_actions:delete_modal:title', { number: returnOrder.returnNumber })"
    @close="closeDeleteModal"
  >
    <TextContainer>
      {{ $t('panel.returns:detail_page_actions:delete_modal:text', { number: returnOrder.returnNumber }) }}
    </TextContainer>

    <template #actions>
      <Button @click="closeDeleteModal">
        {{ $t('panel.returns:detail_page_actions:delete_modal:cancel_button') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="deleteOrder"
      >
        {{ $t('panel.returns:detail_page_actions:delete_modal:confirm_button') }}
      </Button>
    </template>
  </Modal>

  <TraitModal
    :is-open="traitModalOpen"
    :return-order="returnOrder"
    @close="closeTraitModal"
  />
</template>
