<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { Card, DataTable, Page } from '@app/panel/Components';
import { Avatar, Link, TextStyle } from '@returnless/focus-ui';
import Navigation from '@app/panel/Pages/Analytics/partials/Navigation.vue';
import { PagePropsViewModel } from './types';
import { computed, onMounted, ref, watch } from 'vue';
import { router, useForm } from '@inertiajs/vue3';
import ProductsDataTableFilters from '@app/panel/Pages/Analytics/ListProducts/components/ProductsDataTableFilters.vue';
import ProductsDataTableActions from '@app/panel/Pages/Analytics/ListProducts/components/ProductsDataTableActions.vue';
import { cleanObject } from '@app/panel/Utils/object';
import CardSection from '@app/panel/Components/Card/components/CardSection.vue';
import ProductsDataTableTabs from '@app/panel/Pages/Analytics/ListProducts/components/ProductsDataTableTabs.vue';
import ProductsDataTableActiveFilters
  from '@app/panel/Pages/Analytics/ListProducts/components/ProductsDataTableActiveFilters.vue';
import { get, isArray, set } from 'lodash';
import ProductsDataFooter from '@app/panel/Pages/Analytics/ListProducts/components/ProductsDataFooter.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<PagePropsViewModel>();

const headings = ref([
  { text: t('panel.analytics:products:table:header:name'), value: 'name' },
  { text: t('panel.analytics:products:table:header:return-orders'), value: 'returnOrdersQuantity', column: 'return_orders_count', sortable: true },
  { text: t('panel.analytics:products:table:header:qty-returned'), value: 'returnedQuantity', column: 'return_order_items_sum_quantity', sortable: true },
  { text: t('panel.analytics:products:table:header:value'), value: 'value', column: 'return_order_items_sum_value', sortable: true },
]);

const defaultActiveHeadings = [
  'name',
  'returnOrdersQuantity',
  'returnedQuantity',
  'value',
];

const ACTIVE_HEADINGS_KEY = 'productsActiveHeadings';

const activeHeaderValues = ref<string[]>([]);

watch(activeHeaderValues, (newValue) => {
  localStorage.setItem(ACTIVE_HEADINGS_KEY, JSON.stringify(newValue));
});

onMounted(() => {
  const storedActiveHeadings = localStorage.getItem(ACTIVE_HEADINGS_KEY);
  activeHeaderValues.value = storedActiveHeadings ? JSON.parse(storedActiveHeadings) : defaultActiveHeadings;
});

const activeHeader = computed(() => headings.value.filter((heading) => activeHeaderValues.value.includes(heading.value)));

const filtersOpen = ref(false);

const filterForm = useForm({
  filter: {
    name: '',
    sku: '',
    form: '',
    brand: '',
    supplier: '',
    amount_return_orders: { from: '', to: '' },
    quantity_returned: { from: '', to: '' },
    value: { from: '', to: '' },
    order_date: { from: '', to: '' },
    return_reasons: [],
    locale: '',
    ...props.filters,
  },
  sort: props.sort,
  per_page: props.perPage,
  date_filter: {
    from: props.dateFilterFrom,
    to: props.dateFilterTo,
  },
});

function handleFilterChange(newFilterForm: any): void {
  filterForm.filter = newFilterForm.filter;
  filterForm.per_page = newFilterForm.per_page;
  onFormChange();
}

function toggleFilters(): void {
  filtersOpen.value = !filtersOpen.value;
}

function updateDateRangeFilter(from: string, to: string) {
  filterForm.date_filter.from = from;
  filterForm.date_filter.to = to;
  onFormChange();
}

function onFormChange() {
  router.get(route('panel.analytics.products'), cleanObject(filterForm.data()), {
    preserveScroll: true,
    preserveState: true,
  });
}

function sort(column: string) {
  filterForm.sort = column;
  onFormChange();
}

function resetFilter(filterPath: string): void {
  const value = get(filterForm, filterPath);
  set(filterForm, filterPath, isArray(value) ? [] : '');
  onFormChange();
}

function handleTableFooterChange(perPage: number): void {
  filterForm.per_page = perPage;
  onFormChange();
}
</script>

<template>
  <AppLayout :title="$t('panel.analytics:products:title')">
    <Page :navigation="Navigation">
      <div class="mb-4 flex flex-col gap-4">
        <ProductsDataTableActions
          v-model:active-headings="activeHeaderValues"
          :filter="filterForm"
          :date-filter="filterForm.date_filter"
          :headings="headings"
          :toggle-filters="toggleFilters"
          :filter-tabs="filterTabs"
          @updated-date-range="updateDateRangeFilter"
        />

        <ProductsDataTableFilters
          :filters-open="filtersOpen"
          :filter="filterForm"
          :forms="forms"
          :brands="brands"
          :suppliers="suppliers"
          :return-reasons="returnReasons"
          :locales="locales"
          :filter-tabs="filterTabs"
          :has-active-filter="hasActiveFilter"
          @change="handleFilterChange"
        />

        <ProductsDataTableActiveFilters
          :filter-form="filterForm"
          :reset-filter="resetFilter"
          :forms="forms"
          :brands="brands"
          :suppliers="suppliers"
          :return-reasons="returnReasons"
          :locales="locales"
        />
      </div>

      <Card>
        <CardSection flush>
          <ProductsDataTableTabs
            :filter-tabs="filterTabs"
            :previous-page-url="previousPageUrl"
            :next-page-url="nextPageUrl"
          />
        </CardSection>

        <DataTable
          :rounded="false"
          :items="records"
          :headings="activeHeader"
          :sort-by="filterForm.sort"
          @sorted="sort"
        >
          <template #name="{ item }">
            <div class="flex items-center space-x-4">
              <Avatar :source="item.image" />
              <div class="flex flex-col">
                <Link :href="$route('panel.analytics.products.show', { product: item.id, date_filter: filterForm.date_filter })">
                  {{ item.name }}
                </Link>
                <TextStyle subdued>
                  {{ item.sku }}
                </TextStyle>
              </div>
            </div>
          </template>
        </DataTable>

        <CardSection dense>
          <ProductsDataFooter
            :paginator-status="paginatorStatus"
            :per-page="perPage"
            :previous-page-url="previousPageUrl"
            :next-page-url="nextPageUrl"
            @change="handleTableFooterChange"
          />
        </CardSection>
      </Card>
    </Page>
  </AppLayout>
</template>
