<script lang="ts" setup>
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  Form,
  FormLayout,
  TextField,
} from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';

const props = defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

function closeDialog() {
  emit('close');
}

const form = useForm({
  name: '',
});

function save() {
  form.post(route('panel.settings.return-instructions.store'), {
    preserveScroll: true,
  });
}

</script>

<template>
  <Dialog
    :open="props.open"
    @cancel="closeDialog"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('panel.settings:templates:return-instructions:create') }}</DialogTitle>
      </DialogHeader>
      <DialogSection>
        <Form
          id="create-form"
          @submit="save"
        >
          <FormLayout>
            <TextField
              v-model="form.name"
              :label="$t('panel.settings:templates:return-instructions:label')"
              :error="form.errors.name"
              max-length="80"
            />
          </FormLayout>
        </Form>
      </DialogSection>
      <DialogFooter>
        <Button
          variant="secondary"
          form="close-form"
          @click="closeDialog"
        >
          {{ $t('panel.global:actions:cancel') }}
        </Button>

        <Button
          :disabled="!form.isDirty"
          :loading="form.processing"
          variant="primary"
          type="submit"
          form="create-form"
        >
          {{ $t('panel.settings:templates:return-instructions:save') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
