<script lang="ts" setup>
import {
  Card,
  CardSection,
  DataTable,
  Heading,
  Page,
  Pagination,
  SearchInput,
  Select,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { PageNavigation } from '@app/panel/Pages/Settings/Integrations/partials';
import { AppLayout } from '@app/panel/Layouts';
import { type Heading as HeadingI } from '@app/panel/Components/DataTable';
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';
import { computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { debounce } from 'lodash';
import { ApplicationLogViewModel, ListApplicationLogsPageViewModel } from './types';
import ApplicationLogDetailModal from './ApplicationLogDetailModal.vue';
import { Option } from '@app/panel/Components/Select/types';
import { Badge } from '@returnless/focus-ui';
const props = defineProps<ListApplicationLogsPageViewModel>();

const loading = ref<boolean>(false);

const form = useForm({
  per_page: props.perPage,
  action_filter: props.actionFilter,
  search: props.search,
});

const { t } = useI18n();

const headings: HeadingI[] = [
  { text: t('panel.settings:integrations:application-logs:message'), value: 'message', noWrap: false },
];

const currentApplicationLog = ref<ApplicationLogViewModel | null>(null);

function setCurrentItem(item: ApplicationLogViewModel): void {
  currentApplicationLog.value = item;
}

function unsetCurrentItem(): void {
  currentApplicationLog.value = null;
}

const paginationOptions = computed(() => {
  const options = [5, 10, 25, 50, 75, 100];

  return options.map((option) => {
    return {
      value: option,
      label: t('panel.global:pagination:option', { option }),
    };
  });
});

function refreshData(): void {
  form.get(route('panel.settings.integrations.application-logs'), {
    preserveState: true,
    preserveScroll: true,
    onStart: () => loading.value = true,
    onFinish: () => loading.value = false,
  });
}

watch(() => form.action_filter, refreshData);

const debouncedDataRefresh = debounce(refreshData, 1500);

watch(() => form.search, () => {
  loading.value = true;
  debouncedDataRefresh();
});

watch(() => form.per_page, refreshData);

const actionFilterOptions = computed<Option[]>(() => props.actionFilterOptions.map((option): Option => ({
  value: option.value,
  label: option.label,
})));

</script>

<template>
  <AppLayout :title="$t('panel.settings:integrations:application-logs')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <div class="mb-6">
        <Heading>{{ $t('panel.settings:integrations:application-logs') }}</Heading>
      </div>
      <Card>
        <div class="flex justify-between gap-4 px-3 py-2.5">
          <div class="flex grow items-center space-x-4">
            <SearchInput
              v-model="form.search"
              class="!w-96"
              :loading="loading"
              :placeholder="$t('panel.settings:integrations:application-logs:search-for-message')"
            />
            <label class="flex items-center space-x-2">
              <span class="cursor-pointer">{{ $t('panel.settings:integrations:application-logs:action:filter') }}</span>
              <Select
                v-model="form.action_filter"
                class="!w-auto"
                :options="actionFilterOptions"
                :nullable-label="$t('panel.global:select:all')"
              />
            </label>
          </div>
          <Pagination
            :href-next="nextPageUrl"
            :href-previous="previousPageUrl"
          />
        </div>
        <DataTable
          :headings="headings"
          :items="logs"
          :rounded="false"
          clickable
          @row-click="setCurrentItem"
        >
          <template #message="{item}">
            <div class="group flex flex-col space-y-1">
              <div class="flex items-start space-x-2">
                <Badge
                  v-if="item.actionLabel !== null"
                  color="blue"
                  class="-mt-0.5 shrink-0"
                >
                  {{ item.actionLabel }}
                </Badge>
                <TextStyle :shade="TextStyleShade.Default">
                  <span class="line-clamp-3">
                    {{ item.message }}
                  </span>
                </TextStyle>
              </div>
              <TextStyle
                :shade="TextStyleShade.Pale"
                :font-size="TextStyleFontSize.Small"
              >
                <div class="flex items-center justify-between space-x-2">
                  <span>{{ $t('panel.settings:integrations:application-logs:occurred', { occurrences: item.occurrences }) }}</span>
                  <span>{{ item.updatedAt }}</span>
                </div>
              </TextStyle>
            </div>
          </template>
        </DataTable>
        <CardSection dense>
          <div class="flex items-center">
            <TextStyle no-wrap>
              {{ $t('panel.settings:integrations:application-logs:results', { results: paginatorStatus }) }}
            </TextStyle>
            <div class="ml-auto flex w-fit space-x-4">
              <Select
                v-model="form.per_page"
                label-hidden
                :options="paginationOptions"
              />
              <Pagination
                :href-next="nextPageUrl"
                :href-previous="previousPageUrl"
              />
            </div>
          </div>
        </CardSection>
      </Card>
    </Page>
  </AppLayout>

  <ApplicationLogDetailModal
    :application-log="currentApplicationLog"
    @close="unsetCurrentItem"
  />
</template>
