<script lang="ts" setup>
import { computed } from 'vue';
import { InputLabel } from '../../InputLabel';
import { HelpText } from '../../HelpText';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { theme } from '../../../Composables/useTheme';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { ErrorMessage } from '../../ErrorMessage';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },

  id: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  labelHidden: {
    type: Boolean,
    default: false,
  },

  helpText: {
    type: String,
    default: null,
  },

  rows: {
    type: Number,
    default: 3,
  },

  placeholder: {
    type: String,
    default: null,
  },

  error: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  maxLength: {
    type: Number,
    default: 10_000,
  },
});

const emit = defineEmits(['update:modelValue']);

const elementId = computed(() => props.id || useUniqueId('textArea'));

function onChanged(e: InputEvent): void {
  emit('update:modelValue', (e.target as HTMLFormElement).value);
}

const classList = computed(() => {
  return [
    theme(
      [
        'border',
        'focus-within-border-color',
        'focus-within-ring-color',
        'focus-within-ring-size',
      ],
      props.error ? 'critical' : 'default',
    ),
    theme('border-size'),
  ];
});
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="!!helpText"
    />

    <HelpText v-if="helpText">
      {{ helpText }}
    </HelpText>

    <div class="relative">
      <textarea
        :id="elementId"
        :name="name"
        :rows="rows"
        class="shadow-sm block w-full text-sm border rounded-md px-3 py-2 text-slate-700 focus:outline-none"
        :class="classList"
        :placeholder="placeholder"
        :value="modelValue"
        :disabled="disabled"
        :maxlength="maxLength"
        @input="onChanged"
      />

      <div class="absolute top-2 right-3">
        <ExclamationCircleIcon
          v-if="error"
          class="h-5 w-5 fill-red-500"
        />
      </div>

      <div
        data-test="text-input-icon"
        class="z-50 right-3 top-2 absolute text-bg-slate text-slate-400 flex justify-center items-center"
      >
        <slot name="icon" />
      </div>
    </div>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
