export { default as Modal } from './Modal.vue';
export { default as ModalActions } from './ModalActions.vue';
export { default as ModalBody } from './ModalBody.vue';

export enum ModalSize {
  Small = 'small',
  Default = 'default',
  Large = 'large',
  ExtraLarge = 'extra-large',
}
