<script setup lang="ts">

import type { Types } from '@app/shared/types/generated-v2';
import { Button, ButtonType, ButtonVariant, FormLayout } from '@app/panel/Components';
import BillingInvoiceDetailsForm from './Billing/components/BillingInvoiceDetailsForm.vue';
import { useForm } from '@inertiajs/vue3';
import { provide } from 'vue';

const props = defineProps<{
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  countries: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['countries'];
}>();

const invoiceDetailsForm = useForm({
  country: props.tenant.country?.id,
  name: props.tenant.name,
  invoice_email: props.tenant.invoiceEmail,
  street: props.tenant.street,
  postcode: props.tenant.postcode,
  city: props.tenant.city,
  po_number: props.tenant.poNumber,
  vat_number: props.tenant.vatNumber,
});

provide('invoiceDetailsForm', invoiceDetailsForm);

function submitBillingInvoiceDetailsForm(): void {
  invoiceDetailsForm.put(route('panel.account.update-billing-details'), {
    preserveScroll: true,
  });
}
</script>

<template>
  <FormLayout>
    <BillingInvoiceDetailsForm
      id="invoice-details"
      :tenant="props.tenant"
      :countries="props.countries"
      :on-submit="submitBillingInvoiceDetailsForm"
    />
    <Button
      form="invoice-details"
      full-width
      :type="ButtonType.Submit"
      :variant="ButtonVariant.Primary"
      :loading="invoiceDetailsForm.processing"
      @click="submitBillingInvoiceDetailsForm"
    >
      {{ $t('panel.account:billing:payment-methods:modal-next-step-button:label') }}
    </Button>
  </FormLayout>
</template>
