<script lang="ts" setup>
import { Head, useForm } from '@inertiajs/vue3';
import { GuestLayout } from '@app/panel/Layouts';
import { Button, Form, FormLayout, Link, TextField, TextStyle } from '@returnless/focus-ui';
import { asset } from 'laravel-vapor';

const props = defineProps<{
  errors: Record<string, string[]>;
  honeypot: {
    enabled: boolean;
    nameFieldName: string;
    validFromFieldName: string;
    encryptedValidFrom: string;
  };
  language: null | string;
  referrer: null | string;
  partner: null | string;
  partnerId: null | string;
  signature: null | string;
}>();

const form = useForm({
  name: '',
  company_name: '',
  website_url: '',
  email: '',
  password: '',
  password_confirmation: '',
  language: props.language,
  referrer: props.referrer,
  partner: props.partner,
  partner_id: props.partnerId,
  signature: props.signature,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  [props.honeypot.nameFieldName]: '',
  [props.honeypot.validFromFieldName]: props.honeypot.encryptedValidFrom,
});

function submit(): void {
  form.post(route('register', {
    partner: props.partner,
    partner_id: props.partnerId,
    signature: props.signature,
  }));
}
</script>

<template>
  <Head>
    <title>{{ $t('panel.auth.register.title') }}</title>
  </Head>

  <GuestLayout
    :title="$t('panel.auth.register.title')"
    :hero-image="asset('img/registration-background.jpeg')"
  >
    <template #subtitle>
      <Link :href="$route('login')">
        {{ $t('panel.auth.register.login_link') }}
      </Link>
    </template>
    <div>
      <Form @submit="submit">
        <div
          v-if="honeypot.enabled"
          :name="`${honeypot.nameFieldName}_wrap`"
          style="display:none;"
        >
          <input
            :id="honeypot.nameFieldName"
            v-model="form[honeypot.nameFieldName]"
            type="text"
            :name="honeypot.nameFieldName"
          >
          <input
            v-model="form[honeypot.validFromFieldName]"
            type="text"
            :name="honeypot.validFromFieldName"
          >
        </div>

        <FormLayout>
          <TextField
            v-model="form.name"
            :error="form.errors.name"
            :label="$t('panel.auth.register.name_field_label')"
            :placeholder="$t('panel.auth.register.name_field_placeholder')"
            autofocus
            name="name"
            required
          />
          <TextField
            v-model="form.company_name"
            :error="form.errors.company_name"
            :label="$t('panel.auth.register.company_name_field_label')"
            :placeholder="$t('panel.auth.register.company_name_field_placeholder')"
            auto-complete="organization"
            name="company-name"
            required
          />

          <TextField
            v-model="form.website_url"
            :error="form.errors.website_url"
            :label="$t('panel.auth:express_register:website')"
            :placeholder="$t('panel.auth:express_register:website-placeholder')"
            name="website_url"
            auto-complete="url"
            required
          />

          <div class="h-8" />

          <TextField
            v-model="form.email"
            :error="form.errors.email"
            :label="$t('panel.auth.register.email_field_label')"
            :placeholder="$t('panel.auth.register.email_field_placeholder')"
            auto-complete="email"
            name="email"
            required
            type="email"
          />

          <TextField
            v-model="form.password"
            :error="form.errors.password"
            :label="$t('panel.auth.register.password_field_label')"
            :placeholder="$t('panel.auth.register.password_field_placeholder')"
            auto-complete="new-password"
            name="password"
            required
            type="password"
          />

          <TextField
            v-model="form.password_confirmation"
            :error="form.errors.password_confirmation"
            :label="$t('panel.auth.register.confirm_password_field_label')"
            :placeholder="$t('panel.auth.register.confirm_password_field_placeholder')"
            auto-complete="new-password"
            name="password-confirmation"
            required
            type="password"
          />

          <div class="flex flex-col">
            <TextStyle subdued>
              <i18n-t
                keypath="panel.auth:express_register:gdpr"
                for="panel.auth:express_register:gdpr"
              >
                <template #term>
                  <Link
                    native
                    target="_blank"
                    href="https://docs.returnless.com/docs/legal-privacy-security/coe77kv1ku3ja-general-terms-and-conditions"
                  >
                    {{ $t('panel.auth:express_register:term') }}
                  </Link>
                </template>
                <template #data_processing>
                  <Link
                    native
                    target="_blank"
                    href="https://docs.returnless.com/docs/legal-privacy-security/73s61b7qwfl3i-data-processing-agreement"
                  >
                    {{ $t('panel.auth:express_register:data_processing') }}
                  </Link>
                </template>
              </i18n-t>
            </TextStyle>
          </div>

          <Button
            :loading="form.processing"
            full-width
            type="submit"
            variant="primary"
          >
            {{ $t('panel.auth.register.register_button') }}
          </Button>
        </FormLayout>
      </Form>
    </div>
  </GuestLayout>
</template>
