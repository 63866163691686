<script lang="ts" setup>
import {
  nextTick,
  reactive,
  ref,
} from 'vue';
import { usePanel } from '@app/panel/Composables/useHttp';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  Link,
  Modal,
  TextContainer,
  TextInput,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'confirmed'): void;
}>();

const confirmingPassword = ref(false);

const form = reactive({
  password: '',
  error: '',
  processing: false,
});

const passwordInput = ref(null);

defineProps<{
  restriction?: string;
  warning?: string;
}>();

const startConfirmingPassword = () => {
  usePanel()
    .get('/user/confirmed-password-status')
    .then((response) => {
      if (response.data.confirmed) {
        emit('confirmed');
      } else {
        confirmingPassword.value = true;
        nextTick(() => {
          passwordInput.value.focus();
        });
      }
    });
};

const confirmPassword = () => {
  form.processing = true;
  usePanel().post('/user/confirm-password', {
    password: form.password,
  }).then(() => {
    form.processing = false;

    nextTick().then(() => {
      closeModal();
      emit('confirmed');
    });
  }).catch((error) => {
    form.processing = false;
    form.error = error.response.data.errors.password[0];
    passwordInput.value.focus();
  });
};

const closeModal = () => {
  confirmingPassword.value = false;
  form.password = '';
  form.error = '';
};

</script>

<template>
  <span @click="startConfirmingPassword">
    <slot />
  </span>

  <Modal
    size="small"
    :title="$t('panel.global.password_confirmation.title')"
    :open="confirmingPassword"
    @close="closeModal"
  >
    <Form @submit="confirmPassword">
      <FormLayout>
        <div
          v-if="restriction"
          class="mb-5"
        >
          <Banner
            :variant="BannerVariant.Critical"
          >
            {{ restriction }}
          </Banner>
        </div>

        <div
          v-if="!restriction && warning"
          class="mb-5"
        >
          <Banner
            :variant="BannerVariant.Warning"
          >
            {{ warning }}
          </Banner>
        </div>

        <FormLayout>
          <TextContainer>
            <TextStyle :shade="TextStyleShade.Subdued">
              {{ $t('panel.global.password_confirmation.description') }}
            </TextStyle>
          </TextContainer>
        </FormLayout>

        <FormLayout>
          <Link href="/forgot-password">
            {{ $t('panel.global.password_confirmation.forgot_password') }}
          </Link>
        </FormLayout>

        <FormLayout>
          <TextInput
            ref="passwordInput"
            v-model="form.password"
            autofocus
            type="password"
            :placeholder="t('panel.global:input:password:label')"
            :disabled="!!restriction"
            :error="form.error"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="closeModal">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        :loading="form.processing"
        :disabled="!!restriction"
        @click="confirmPassword"
      >
        {{ $t('panel.global:actions:confirm') }}
      </Button>
    </template>
  </Modal>
</template>
