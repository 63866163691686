<script lang="ts" setup>
import { inject } from 'vue';
import {
  Button,
  ButtonType,
  ButtonVariant,
  ColorPicker,
  Form,
  FormLayout,
  Modal,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { InertiaForm } from '@inertiajs/vue3';
import { StatusForm } from './types';
import { TranslationInputFields, TranslationInputFieldType } from '@app/panel/Containers';
import * as Types from '@app/panel/types/generated';

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const locales = inject<Types.App.Models.ViewModels.LocaleViewModel[]>('locales')!;
const defaultLocale = inject<Types.App.Models.ViewModels.LocaleViewModel>('defaultLocale')!;

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onCreateStatusModalClose() {
  emit('close');
}

const statusForm = inject<InertiaForm<StatusForm>>('statusForm')!;

function createStatus() {
  statusForm.post(route('panel.settings.general.create-request-status'), {
    onSuccess: () => onCreateStatusModalClose(),
  });
}
</script>

<template>
  <Modal
    :open="open"
    @close="onCreateStatusModalClose"
  >
    <Form
      id="create-form"
      @submit="createStatus"
    >
      <FormLayout>
        <FormLayout>
          <ColorPicker
            v-model="statusForm.color"
            :label="$t('panel.settings.general_settings.request_statuses.create_modal.status_color_label')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="statusForm.label"
            name="label"
            :label="$t('panel.settings.general_settings.request_statuses.create_modal.status_label_input')"
            :error="statusForm.errors.label"
          />
        </FormLayout>

        <FormLayout>
          <TranslationInputFields
            v-model="statusForm.translations"
            :locales="locales"
            :errors="statusForm.errors"
            field-name="translations"
            :default-locale-id="defaultLocale?.id"
            :type="TranslationInputFieldType.TextInput"
            :label="$t('panel.settings.general_settings.request_statuses.create_modal.status_translation_input')"
          />
        </FormLayout>

        <FormLayout>
          <Toggle
            v-model="statusForm.is_default_status"
            :title="$t('panel.settings.general_settings.request_statuses.create_modal.default')"
          />
        </FormLayout>

        <FormLayout>
          <Toggle
            v-model="statusForm.visible_on_tracking_page"
            :title="$t('panel.settings.general_settings.request_statuses.create_modal.visible_on_tracking_page')"
          />
        </FormLayout>

        <FormLayout>
          <Toggle
            v-model="statusForm.should_notify_user"
            :title="$t('panel.settings.general_settings.request_statuses.create_modal.should_notify_user')"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="onCreateStatusModalClose">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :loading="statusForm.processing"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="create-form"
      >
        {{ $t('panel.settings.general_settings.request_statuses.create_modal.save_button') }}
      </Button>
    </template>
  </Modal>
</template>
