<script lang="ts" setup>
import { computed, onMounted, ref, toRef, watch } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import {
  Avatar,
  Badge,
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Checkbox,
  Combobox,
  DataTable,
  DraggableIcon,
  FooterActionPosition,
  FormLayout,
  Link,
  Modal,
  Page,
  Pagination,
  Popover,
  Select,
  Stack,
  StackItem,
  StackSpacing,
  StatusIndicator,
  Tabs,
  TabsItem,
  TestModeToggle,
  TextContainer,
  TextInput,
  TextStyle,
  Tooltip,
} from '@app/panel/Components';
import { cleanObject } from '@app/panel/Utils/object';
import { OptionViewModel, PagePropsViewModel, TagViewModel } from './types';
import { useI18n } from 'vue-i18n';
import { isEmpty } from 'lodash';
import { BuildingStorefrontIcon, ChatBubbleLeftIcon, FunnelIcon, ViewColumnsIcon } from '@heroicons/vue/24/outline';

import { router, useForm } from '@inertiajs/vue3';
import { ButtonGroup, Form } from '@app/customer/Components';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import ExportReturnsButton from '@app/panel/Pages/Returns/Overview/Components/ExportReturnsButton.vue';
import { truthy } from '@app/panel/Utils/truthy';
import { ReturnStateLabel } from '../partials';

const props = defineProps<PagePropsViewModel>();

const { t } = useI18n();

const rowsPerPage = ref(props.perPage);
const bulkAction = ref<string>();
const checkedItems = ref<{ id: string }[]>([]);
const columnsButton = ref<HTMLElement | undefined>(undefined);
const columnsPopoverOpen = ref(false);
const activeHeadings = ref<string[]>([]);
const filtersOpen = ref(false);
const saveFilterModalOpen = ref(false);
const editFilterModalOpen = ref(false);

const headings = [
  {
    text: t('panel.returns.table_headings.return_number'),
    value: 'returnNumber',
    column: 'return_number',
    sortable: true,
  },
  // TODO RET-297 remove this condition when fully implemented
  props.useNewReturnOrderStates && { text: t('panel.returns.table_headings.state'), value: 'state' },
  { text: t('panel.returns.table_headings.status'), value: 'status' },
  { text: t('panel.returns.table_headings.sales_order'), value: 'customer' },
  { text: t('panel.returns.table_headings.shipment'), value: 'trackingNumber' },
  { text: t('panel.returns.table_headings.value'), value: 'value.label', column: 'value', sortable: true },
  { text: t('panel.returns.table_headings.quantity'), value: 'quantity', column: 'quantity', sortable: true },
  { text: t('panel.returns.table_headings.tags'), value: 'tags' },
]
  // TODO RET-297 remove this condition when fully implemented
  .filter((heading) => heading !== false);

const tableHeadings = computed(() => {
  return headings.filter((heading) => {
    return activeHeadings.value.includes(heading.value);
  });
});

const paginationOptions = [5, 10, 25, 50, 75, 100].map((option) => {
  return {
    value: option,
    label: t('panel.global:pagination:option', { option }),
  };
});

const bulkActionOptions = [
  {
    label: 'Set status',
    value: props.returnStatusOptions,
  },
  {
    label: 'Set tag',
    value: props.tags.map((tag: TagViewModel) => {
      return {
        value: tag.id,
        label: tag.name,
      };
    }),
  },
];

const filterForm = useForm({
  filter: {
    created_at: { from: '', to: '' },
    quantity: { from: '', to: '' },
    value: { from: '', to: '' },
    platform_integration: '',
    form: '',
    return_status: '',
    state: '',
    tenant: '',
    return_type: '',
    shipping_status: '',
    locale: '',
    note_filter: '',
    tags: [],
    customers: '',
    is_test_record: props.filters.is_test_record,
    ...props.filters,
  },
  sort: props.sort,
  per_page: props.perPage,
} as {
  filter: { [key: string]: any };
  sort: string | null;
  per_page: number;
});

function sort(column: string) {
  filterForm.sort = column;
  onFormChange();
}

const saveFilterForm = useForm<{
  label: string | null;
  filters: string | null;
  type: string;
}>({
  label: null,
  filters: null,
  type: 'return',
});

const activeFilterTab = new URLSearchParams(window.location.search).get('active-tab');

const filtersTableId = useUniqueId('filtersTable');

const { sortedItems } = useDragAndDrop(
  filtersTableId,
  toRef(props, 'filterTabs'),
  editFilterModalOpen,
);

watch(sortedItems, (newValue) => {
  router.put(route('panel.filter-tabs.sort'), {
    items: newValue.map((item) => item.id),
    type: 'return',
  }, {
    preserveScroll: true,
    preserveState: true,
  });
});

function deleteFilter(filterTab): void {
  router.delete(route('panel.settings.filter-tabs.delete', filterTab.id));
}

function onRowsPerPageChange(): void {
  filterForm.per_page = rowsPerPage.value;
  onFormChange();
}

function onFormChange(): void {
  router.get(route('panel.return-orders.overview'), cleanObject(filterForm.data()), {
    preserveScroll: true,
    preserveState: true,
  });
}

function getBulkActionType(value?: string): string {
  if (props.returnStatusOptions.find((status: OptionViewModel) => status.value === value)) {
    return 'set-status';
  }
  if (props.tags.find((status: TagViewModel) => status.id === value)) {
    return 'add-tag';
  }
  throw new Error('Invalid bulk action type');
}

function saveInBulkAction(): void {
  router.post(route('panel.return-orders.bulk-actions'), {
    ids: checkedItems.value.map((item) => item.id),
    action: getBulkActionType(bulkAction.value),
    action_value: bulkAction.value,
  }, {
    preserveState: false,
    preserveScroll: true,
  });
}

function closeSaveFilterModal(): void {
  saveFilterModalOpen.value = false;
}

function closeEditFilterModal(): void {
  editFilterModalOpen.value = false;
}

function submitSaveFilter(): void {
  saveFilterForm.filters = window.location.search;
  saveFilterForm.post(route('panel.settings.filter-tabs.store'), {
    onSuccess: () => closeSaveFilterModal(),
  });
}

function resetFilter(key: keyof typeof filterForm.filter): void {
  const filter = filterForm.filter[key];

  if (Array.isArray(filter)) {
    filterForm.filter[key] = [];
  } else if (typeof filter === 'object') {
    Object.keys(filter).forEach((subKey) => {
      filterForm.filter[key][subKey] = '';
    });
  } else {
    filterForm.filter[key] = '';
  }

  onFormChange();
}

watch(activeHeadings, (newValue) => {
  activeHeadings.value = newValue;
  localStorage.setItem('returnsActiveHeadings', JSON.stringify(newValue));
});

onMounted((): void => {
  const storedActiveHeadings = localStorage.getItem('returnsActiveHeadings');
  activeHeadings.value = storedActiveHeadings
    ? JSON.parse(storedActiveHeadings)
    : headings.map((heading) => heading.value);
});
</script>

<template>
  <AppLayout>
    <Page>
      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem>
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <div ref="columnsButton">
                <Button @click="columnsPopoverOpen = !columnsPopoverOpen">
                  <template #icon>
                    <ViewColumnsIcon class="size-5" />
                  </template>

                  {{ t('panel.returns.page_actions.columns_button') }}
                </Button>
              </div>

              <Button @click="filtersOpen = !filtersOpen">
                <template #icon>
                  <FunnelIcon class="size-5" />
                </template>
                {{ t('panel.returns.page_actions.filter_button') }}
              </Button>
            </div>

            <Popover
              :active="columnsPopoverOpen"
              :parent="columnsButton"
              @close="columnsPopoverOpen = false"
            >
              <div
                v-for="tableHeading in headings"
                :key="tableHeading.value"
              >
                <Checkbox
                  v-model="activeHeadings"
                  :label="tableHeading.text"
                  :name="tableHeading.text"
                  :value="tableHeading.value"
                  class="p-2 hover:bg-gray-50"
                  full-width
                />
              </div>
            </Popover>

            <ButtonGroup>
              <TestModeToggle
                v-if="! $page.props.isPartner"
                :model-value="truthy(filterForm.filter.is_test_record)"
                @update:model-value="testMode => filterForm.filter.is_test_record = testMode"
                @change="onFormChange"
              />
              <ExportReturnsButton />
            </ButtonGroup>
          </div>
        </StackItem>

        <StackItem v-if="filtersOpen">
          <Card
            :footer-action-position="FooterActionPosition.Left"
            :secondary-footer-actions="[
              {
                content: 'Save current filter',
                disabled: !$page.props.hasActiveFilter,
                callback: () => saveFilterModalOpen = true,
              },
              {
                content: 'Manage filters',
                disabled: !filterTabs || filterTabs.length === 0,
                callback: () => editFilterModalOpen = true,
              },
            ]"
          >
            <CardSection>
              <FormLayout
                :columns-md="2"
                :columns-lg="3"
              >
                <FormLayout :columns="2">
                  <TextInput
                    v-model="filterForm.filter.created_at.from"
                    :label="t('panel.returns.filters.created_from_label')"
                    placeholder="Created from"
                    type="date"
                    @change="onFormChange"
                  />
                  <TextInput
                    v-model="filterForm.filter.created_at.to"
                    :label="t('panel.returns.filters.created_to_label')"
                    placeholder="Created to"
                    type="date"
                    @change="onFormChange"
                  />
                </FormLayout>

                <FormLayout :columns="2">
                  <TextInput
                    v-model="filterForm.filter.value.from"
                    :label="t('panel.returns.filters.value_from_label')"
                    :placeholder="t('panel.returns.filters.value_from_placeholder')"
                    icon-position="left"
                    type="number"
                    @change="onFormChange"
                  >
                    <template #icon>
                      €
                    </template>
                  </TextInput>
                  <TextInput
                    v-model="filterForm.filter.value.to"
                    :label="t('panel.returns.filters.value_to_label')"
                    :placeholder="t('panel.returns.filters.value_to_placeholder')"
                    icon-position="left"
                    type="number"
                    @change="onFormChange"
                  >
                    <template #icon>
                      €
                    </template>
                  </TextInput>
                </FormLayout>

                <FormLayout :columns="2">
                  <TextInput
                    v-model="filterForm.filter.quantity.from"
                    :label="t('panel.returns.filters.quantity_from_label')"
                    :placeholder="t('panel.returns.filters.quantity_from_placeholder')"
                    type="number"
                    @change="onFormChange"
                  />
                  <TextInput
                    v-model="filterForm.filter.quantity.to"
                    :label="t('panel.returns.filters.quantity_to_label')"
                    :placeholder="t('panel.returns.filters.quantity_to_placeholder')"
                    type="number"
                    @change="onFormChange"
                  />
                </FormLayout>

                <Select
                  v-model="filterForm.filter.platform_integration"
                  :options="platformIntegrationOptions"
                  :label="t('panel.returns.filters.integration_label')"
                  @change="onFormChange"
                />
                <Select
                  v-model="filterForm.filter.locale"
                  :options="localeOptions"
                  :label="t('panel.returns.filters.locale_label')"
                  @change="onFormChange"
                />
                <Select
                  v-model="filterForm.filter.form"
                  :options="formOptions"
                  :label="t('panel.returns.filters.form_label')"
                  @change="onFormChange"
                />
                <Select
                  v-model="filterForm.filter.return_status"
                  :options="returnStatusOptions"
                  :label="t('panel.returns.filters.status_label')"
                  @change="onFormChange"
                />
                <Select
                  v-if="useNewReturnOrderStates"
                  v-model="filterForm.filter.state"
                  :options="stateOptions"
                  :label="t('panel.global:filters:return-order-state')"
                  @change="onFormChange"
                />
                <Select
                  v-model="filterForm.filter.shipping_status"
                  :options="shipmentStatusOptions"
                  :label="t('panel.returns.filters.shipment_status_label')"
                  @change="onFormChange"
                />
                <Select
                  v-if="$page.props.isPartner"
                  v-model="filterForm.filter.tenant"
                  :options="tenantOptions"
                  :label="t('panel.returns.filters.tenant_label')"
                  @change="onFormChange"
                />
                <Select
                  v-model="filterForm.filter.note_filter"
                  :options="noteFilterOptions"
                  :label="t('panel.requests.filters.notes_label')"
                  @change="onFormChange"
                />
                <Combobox
                  v-model="filterForm.filter.tags"
                  :options="tagOptions"
                  label="Tags"
                  @change="onFormChange"
                />
                <TextInput
                  v-model="filterForm.filter.customers"
                  :label="t('panel.return-orders:filters:customers')"
                  :placeholder="t('panel.return-orders:filters:customers:placeholder')"
                  type="text"
                  @change="onFormChange"
                />
              </FormLayout>
            </CardSection>
          </Card>
        </StackItem>

        <div
          v-if="activeFilters.length"
          class="flex-items-center flex flex-wrap gap-2"
        >
          <Badge
            v-for="activeFilter in activeFilters"
            :key="activeFilter.key"
            color="blue"
            closeable
            @close="resetFilter(activeFilter.key)"
          >
            {{ activeFilter.label }}: {{ activeFilter.value }}
          </Badge>
        </div>

        <StackItem>
          <Card>
            <CardSection flush>
              <div class="flex items-center justify-between">
                <Tabs>
                  <TabsItem
                    :active="! activeFilterTab"
                    :href="$route('panel.return-orders.overview')"
                  >
                    {{ t('panel.returns.tabs.all') }}
                  </TabsItem>

                  <TabsItem
                    v-for="tab in filterTabs"
                    :key="tab.id"
                    :active="activeFilterTab === tab.id"
                    :href="$route('panel.return-orders.overview') + `?${tab.queryString}&active-tab=${tab.id}`"
                  >
                    {{ tab.label }}
                  </TabsItem>
                </Tabs>

                <Pagination
                  :href-next="nextPageUrl"
                  :href-previous="previousPageUrl"
                />
              </div>
            </CardSection>

            <DataTable
              v-model="checkedItems"
              :items="records"
              :headings="tableHeadings"
              :rounded="false"
              :checkable="true"
              :sort-by="filterForm.sort ?? undefined"
              @sorted="sort"
            >
              <template #returnNumber="{ item }">
                <div class="flex items-center justify-between">
                  <TextContainer>
                    <Link :href="$route('panel.return-orders.detail', item.id)">
                      {{ item.returnNumber }}
                    </Link>
                    <TextStyle
                      shade="subdued"
                      block
                    >
                      {{ item.createdAt }}
                    </TextStyle>
                  </TextContainer>
                  <div
                    v-if="item.noteCount > 0"
                    class="ml-10 mt-0.5 flex items-center space-x-1"
                    :class="{
                      'opacity-40': ! item.hasUnreadNotes,
                      'text-brand-500' : item.hasUnreadNotes,
                    }"
                  >
                    <TextStyle :color="item.hasUnreadNotes ? 'text-brand-500' : ''">
                      {{ item.noteCount }}
                    </TextStyle>
                    <ChatBubbleLeftIcon class="size-4" />
                  </div>
                </div>
              </template>

              <template #customer="{ item }">
                <TextContainer>
                  <div class="max-w-40 truncate">
                    <TextStyle strong>
                      {{ item.salesOrderNumber }}
                    </TextStyle>
                  </div>
                  <Tooltip :content="item.customerEmail">
                    <TextStyle shade="subdued">
                      {{ item.customerName || '-' }}
                    </TextStyle>
                  </Tooltip>
                </TextContainer>
              </template>

              <template #status="{ item }">
                <Badge :color="item.status.color">
                  {{ item.status.label }}
                </Badge>
              </template>

              <!-- TODO RET-297 remove this useNewReturnOrderStates v-if when fully implemented -->
              <template
                v-if="useNewReturnOrderStates"
                #state="{ item }"
              >
                <ReturnStateLabel
                  :state="item.state"
                />
              </template>

              <template #trackingNumber="{ item }">
                <TextContainer>
                  <span
                    v-if="item.shipments.length === 0"
                    class="text-slate-300"
                  >&mdash;</span>

                  <span
                    v-if="item.shipments.length === 1 && !item.shipments[0].isReturnInStore"
                    class="flex space-x-4"
                  >

                    <span class="w-8">
                      <Tooltip
                        v-if="item.shipments[0].image !== null"
                        :content="item.shipments[0].image.name"
                      >
                        <Avatar :src="item.shipments[0].image.imageSourceUrl" />
                      </Tooltip>
                    </span>

                    <span>
                      <TextStyle strong>
                        {{ item.shipments[0].trackingNumber }}
                      </TextStyle>
                      <span class="flex items-center space-x-1">
                        <StatusIndicator :color="item.shipments[0].statusBadge.color" />
                        <span>{{ item.shipments[0].statusBadge.label }}</span>
                      </span>
                    </span>
                  </span>

                  <span
                    v-if="item.shipments.length > 1 && !item.shipments[0].isReturnInStore"
                    class="flex space-x-4"
                  >
                    <span class="flex space-x-0.5 overflow-hidden">
                      <template
                        v-for="shipment in item.shipments"
                        :key="shipment.id"
                      >
                        <Tooltip
                          v-if="shipment.image !== null"
                          :content="shipment.image.name"
                        >
                          <Avatar :src="shipment.image.imageSourceUrl" />
                        </Tooltip>
                      </template>
                    </span>
                  </span>

                  <span
                    v-if="item.shipments[0] && item.shipments[0].isReturnInStore"
                    class="flex items-center space-x-4"
                  >

                    <span class="w-8">
                      <Tooltip
                        :content="t('panel.shipping-method-type:return-in-store')"
                      >
                        <div class="grid size-8 place-items-center rounded-md bg-slate-200">
                          <BuildingStorefrontIcon class="size-5 text-slate-600" />
                        </div>
                      </Tooltip>
                    </span>

                    <span>
                      <TextStyle strong>
                        {{ item.shipments[0].trackingNumber }}
                      </TextStyle>
                    </span>
                  </span>
                </TextContainer>
              </template>

              <template #tags="{ item }">
                <div
                  v-if="! isEmpty(item.tags)"
                  class="flex items-center space-x-2"
                >
                  <Badge
                    v-for="tag in item.tags"
                    :key="tag.id"
                    :color="tag.color"
                  >
                    {{ tag.name }}
                  </Badge>
                </div>

                <span
                  v-else
                  class="text-slate-300"
                >
                  &mdash;
                </span>
              </template>
            </DataTable>

            <CardSection dense>
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-4">
                  <Select
                    v-model="bulkAction"
                    :options="bulkActionOptions"
                    placeholder="Action"
                    :disabled="checkedItems.length === 0"
                    @change="saveInBulkAction"
                  />

                  <TextContainer>
                    <TextStyle no-wrap>
                      {{ paginatorStatus }}
                    </TextStyle>
                  </TextContainer>
                </div>

                <div class="flex items-center space-x-4">
                  <Select
                    v-model="rowsPerPage"
                    label-hidden
                    :options="paginationOptions"
                    @change="onRowsPerPageChange"
                  />

                  <Pagination
                    :href-next="nextPageUrl"
                    :href-previous="previousPageUrl"
                  />
                </div>
              </div>
            </CardSection>
          </Card>
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>

  <Modal
    :open="saveFilterModalOpen"
    :title="t('panel.returns.filters.manage_filters')"
    @close="closeSaveFilterModal"
  >
    <Form
      id="save-filter-form"
      @submit="submitSaveFilter"
    >
      <FormLayout>
        <TextInput
          v-model="saveFilterForm.label!"
          :error="saveFilterForm.errors.label"
          :label="t('panel.returns.filters.save_filter_modal.text_input_label')"
          :max-length="30"
        />
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="closeSaveFilterModal">
        {{ t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :disabled="! saveFilterForm.isDirty"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="save-filter-form"
      >
        {{ t('panel.returns.filters.save_filter_modal.action') }}
      </Button>
    </template>
  </Modal>

  <Modal
    :open="editFilterModalOpen"
    :title="t('panel.returns.filters.manage_filters')"
    @close="closeEditFilterModal"
  >
    <DataTable
      :id="filtersTableId"
      :headings-visible="false"
      :headings="[{
        text: t('panel.returns.filters.removal_modal.name'),
        value: 'label'
      }]"
      :items="filterTabs"
    >
      <template #label="{ item }">
        <div class="flex">
          <DraggableIcon />

          <TextStyle>
            {{ item.label }}
          </TextStyle>
        </div>
      </template>

      <template #actions="{ item }">
        <Button
          plain
          :variant="ButtonVariant.Critical"
          @click="deleteFilter(item)"
        >
          {{ t('panel.returns.filters.removal_modal.action') }}
        </Button>
      </template>
    </DataTable>
  </Modal>
</template>
