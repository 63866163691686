<script lang="ts" setup>
import {
  DataList,
  DataListContent,
  DataListItem,
  DataListItemLine,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  Link,
} from '@returnless/focus-ui';
import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import type { Types } from '@app/shared/types/generated-v2';

type FormViewModel = Types['App.Models.ViewModels.FormViewModel'];

defineProps<{
  open: boolean;
  forms: FormViewModel[];
  createdPlatformIntegrationId: string | null;
}>();

const emit = defineEmits<{
  close: [];
}>();

function handleClose(): void {
  emit('close');
}

</script>

<template>
  <Dialog
    :open="open"
    @cancel="handleClose"
  >
    <DialogContent>
      <DialogHeader>
        <div class="flex flex-row">
          <CheckCircleIcon class="h-6 w-6 text-green-500 mr-2" />
          <DialogTitle>{{ $t('panel.integrations:connect-complete:title') }}</DialogTitle>
        </div>
        <DialogDescription>{{ $t('panel.integrations:connect-complete:description') }}</DialogDescription>
      </DialogHeader>
      <DialogSection>
        <DataList>
          <DataListItem
            v-for="form in forms"
            :id="form.uuid"
            :key="form.uuid"
          >
            <DataListContent>
              <Link
                :href="$route('panel.settings.forms.integrations', form.id)"
              >
                <DataListItemLine>
                  {{ form.name }}
                </DataListItemLine>
              </Link>
            </DataListContent>
          </DataListItem>
        </DataList>
      </DialogSection>
      <DialogFooter class="flex flex-row justify-center items-center">
        <Link
          :href="$route('panel.settings.integrations.platform.show', { platformIntegrationTenant: createdPlatformIntegrationId })"
        >
          {{ $t('panel.integrations:change-integration-settings') }}
        </Link>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
