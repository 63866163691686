<script lang="ts" setup>
import * as Types from '@app/panel/types/generated';
import { ref } from 'vue';
import {
  Badge,
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
  Textarea,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { PaperClipIcon, PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import NoteAvatar from './NoteAvatar.vue';
import { MarkdownService } from '@app/shared/Services';

const props = defineProps<{
  note: Types.App.Models.ViewModels.NoteViewModel;
}>();

const editMode = ref(false);
const modalOpened = ref(false);

const form = useForm({
  note: props.note.note,
});

function cancel() {
  editMode.value = false;
  form.reset();
}

function save() {
  form.put(route('panel.note.update', props.note.id), {
    preserveState: false,
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    },
  });
}

function remove() {
  form.delete(route('panel.note.delete', props.note.id), {
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    },
  });
}

function openModal() {
  modalOpened.value = true;
}

function closeModal() {
  modalOpened.value = false;
}
</script>

<template>
  <div class="group flex space-x-4">
    <NoteAvatar :note="note" />

    <div
      class="flex w-full flex-col"
    >
      <div class="flex items-center justify-start">
        <div class="flex w-full items-center justify-between space-x-2">
          <div>
            <TextStyle strong>
              <div class="mb-0.5">
                <div
                  class="flex space-x-2"
                >
                  <span>
                    {{ note.user?.name ?? note.customer?.name ?? note.actor ?? 'Automation' }}
                  </span>

                  <Badge
                    v-if="note.user && note.isVisibleForCustomer"
                    color="slate"
                  >
                    {{ $t('panel.containers:notes-section:visible-for-customer') }}
                  </Badge>

                  <Badge
                    v-if="note.customer"
                    color="red"
                  >
                    {{ $t('panel.containers:notes-section:customer') }}
                  </Badge>
                </div>
              </div>
            </TextStyle>
          </div>

          <div
            class="hidden shrink-0 justify-between space-x-2 group-hover:flex"
          >
            <div @click="editMode = true">
              <PencilSquareIcon class="size-5 cursor-pointer text-slate-400 hover:text-slate-500" />
            </div>
            <div @click="openModal">
              <TrashIcon class="size-5 cursor-pointer text-slate-400 hover:text-slate-500" />
            </div>
          </div>
        </div>
      </div>

      <div class="mb-1.5 mt-0.5">
        <div
          v-if="!editMode"
          class="hyphens-auto break-words text-sm prose"
          v-html="MarkdownService.parse(note.note)"
        />
        <div
          v-else
          class="mt-1.5 flex flex-1 flex-col space-y-2"
        >
          <Textarea
            v-model="form.note"
            :error="form.errors.note"
            :placeholder="$t('panel.containers:notes-section:add-note')"
            required
          />
          <div class="flex space-x-2">
            <Button
              :variant="ButtonVariant.Default"
              @click="cancel"
            >
              {{ $t('panel.containers:notes-section:cancel') }}
            </Button>
            <Button
              :variant="ButtonVariant.Primary"
              @click="save"
            >
              {{ $t('panel.containers:notes-section:save') }}
            </Button>
          </div>
        </div>
      </div>

      <div class="flex flex-col whitespace-nowrap">
        <TextStyle
          :shade="TextStyleShade.Subdued"
          :font-size="TextStyleFontSize.Small"
        >
          {{ note.createdAt }}
        </TextStyle>
      </div>

      <div class="flex flex-wrap gap-2">
        <template
          v-for="attachment in note.attachments"
          :key="attachment.id"
        >
          <div class="w-fit cursor-pointer rounded-md bg-blue-50 px-2 py-1.5 hover:bg-blue-100">
            <a
              class="flex items-center space-x-1.5"
              :href="attachment.path"
              target="_blank"
            >
              <PaperClipIcon class="size-4 text-slate-500" />

              <span class="text-xs text-slate-700">{{ attachment.filename }}</span>
            </a>
          </div>
        </template>
      </div>
    </div>
  </div>

  <Modal
    :open="modalOpened"
    icon-color="red"
    :size="ModalSize.Default"
    :title="$t('panel.containers:notes-section:title-delete-note')"
    @close="closeModal"
  >
    <TextContainer :spacing="TextContainerSpacing.Loose">
      <TextStyle :shade="TextStyleShade.Subdued">
        <p>
          {{ $t('panel.containers:notes-section:delete-body') }}
        </p>
      </TextStyle>
    </TextContainer>

    <template #actions>
      <Button @click="closeModal">
        {{ $t('panel.containers:notes-section:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="remove"
      >
        {{ $t('panel.containers:notes-section:delete') }}
      </Button>
    </template>
  </Modal>
</template>
