<script lang="ts" setup>
import { ref, watch, watchEffect } from 'vue';
import {
  Button,
  ButtonIconPosition,
  Checkbox,
  PageActions,
  Popover,
  TestModeToggle,
} from '@app/panel/Components';
import { FunnelIcon, ViewColumnsIcon } from '@heroicons/vue/24/outline';
import ExportReturnsButton from '../../../Returns/Overview/Components/ExportReturnsButton.vue';

const props = defineProps({
  headings: {
    type: Array,
    required: true,
  },

  activeHeadings: {
    type: Array,
    required: true,
  },

  toggleFilters: {
    type: Function,
    required: true,
  },

  filterTabs: {
    type: Array,
    required: true,
  },

  showTestRecords: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits([
  'update:active-headings',
  'toggleShowTestData',
]);

const columnsButton = ref<HTMLElement>();
const columnsPopoverOpen = ref(false);
const checkedColumns = ref(props.activeHeadings);

function toggleColumnsPopover() {
  columnsPopoverOpen.value = !columnsPopoverOpen.value;
}

function onCheckboxChange() {
  emit('update:active-headings', checkedColumns.value);
}

watchEffect(() => checkedColumns.value = props.activeHeadings);

const showTestData = ref(props.showTestRecords);
watch(showTestData, () => emit('toggleShowTestData', showTestData.value));
</script>

<template>
  <PageActions>
    <div>
      <div class="relative">
        <div class="flex items-stretch h-full">
          <div ref="columnsButton">
            <Button
              @click="toggleColumnsPopover"
              @keyup.esc="toggleColumnsPopover"
            >
              <template #icon>
                <ViewColumnsIcon class="h-5 w-5" />
              </template>

              {{ $t('panel.returns.page_actions.columns_button') }}
            </Button>
          </div>

          <Popover
            :active="columnsPopoverOpen"
            :parent="columnsButton"
            @close="toggleColumnsPopover"
          >
            <div
              v-for="(heading, index) in headings"
              :key="index"
            >
              <Checkbox
                v-model="checkedColumns"
                full-width
                class="p-2 hover:bg-gray-50"
                :label="heading.text"
                :name="heading.text"
                :value="heading.value"
                @change="onCheckboxChange"
              />
            </div>
          </Popover>
        </div>
      </div>

      <Button
        :icon-position="ButtonIconPosition.Left"
        @click="() => toggleFilters()"
        @keyup.esc="() => toggleFilters()"
      >
        <template #icon>
          <FunnelIcon class="h-5 w-5" />
        </template>

        {{ $t('panel.returns.page_actions.filter_button') }}
      </Button>
    </div>

    <div class="flex space-x-3">
      <TestModeToggle v-model="showTestData" />

      <ExportReturnsButton />
    </div>
  </PageActions>
</template>
