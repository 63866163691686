<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import {
  Card,
  CardSection,
  Page,
  Stack,
  StackItem,
  StackSpacing,
} from '@app/panel/Components';
import Navigation from './partials/Navigation.vue';
import ProductDetail from '@app/panel/Pages/Analytics/components/ProductDetail.vue';
import ListOfQuestions from '@app/panel/Pages/Analytics/components/ListOfQuestions.vue';
import { type Types } from '@app/shared/types/generated-v2';
import LineChart from '@app/panel/Pages/Analytics/components/LineChart.vue';
import DateRange from '@app/panel/Pages/Analytics/components/DateRange.vue';
import { useForm } from '@inertiajs/vue3';
import TextStyle from '@app/panel/Components/TextStyle/components/TextStyle.vue';
import Heading from '@app/panel/Components/Heading/components/Heading.vue';
import HorizontalBarChart from '@app/panel/Pages/Analytics/components/HorizontalBarChart.vue';

type ProductAnalyticsViewModel = Types['App.Http.ViewModels.Panel.Analytics.Products.ProductAnalyticsViewModel'];
const props = defineProps<ProductAnalyticsViewModel>();

const filterForm = useForm({
  date_filter: { from: props.filter.from, to: props.filter.to },
});

function onFormChange() {
  filterForm.get(
    route('panel.analytics.products.show', { product: props.product.id }),
  );
}

function updateDateRangeFilter(from: string, to: string) {
  filterForm.date_filter.from = from;
  filterForm.date_filter.to = to;
  onFormChange();
}

</script>

<template>
  <AppLayout :title="$t('panel.analytics:products:title')">
    <Page>
      <template #page-navigation>
        <Navigation />
      </template>

      <Stack
        vertical
        :spacing="StackSpacing.Loose"
      >
        <div class="flex">
          <DateRange
            :init-from="filterForm.date_filter.from"
            :init-to="filterForm.date_filter.to"
            @updated="updateDateRangeFilter"
          />
        </div>
        <StackItem>
          <ProductDetail
            :product="product"
            :total-returns="returnOrderItemsQuantityInformation.quantity"
          />
        </StackItem>

        <div class="flex space-x-4">
          <StackItem class="w-3/4 ">
            <LineChart
              :data-points="amountOfReturn"
              :line-label="$t('panel.analytics:products:amount-of-return')"
            />
          </StackItem>
          <Card
            class="w-1/4"
            divide
          >
            <CardSection class="h-1/3">
              <TextStyle shade="subdued">
                {{ $t('panel.analytics:products:missed-revenue') }}
              </TextStyle>
              <Heading> {{ returnOrderItemsInformation.totalSoldValue }}</Heading>
            </CardSection>
            <CardSection class="h-1/3">
              <div class="flex items-center justify-between">
                <TextStyle shade="subdued">
                  {{ $t('panel.analytics:products:product-cost') }}
                </TextStyle>
              </div>
              <Heading> {{ returnOrderItemsInformation.totalItemCostValue }}</Heading>
            </CardSection>
            <CardSection class="h-1/3">
              <div class="flex items-center justify-between">
                <TextStyle shade="subdued">
                  {{ $t('panel.analytics:products:product-margin') }}
                </TextStyle>
              </div>
              <Heading> {{ returnOrderItemsInformation.missedRevenue }}</Heading>
            </CardSection>
          </Card>
        </div>
        <stackItem>
          <Card
            :title="$t('panel.analytics:products:quantities')"
          >
            <CardSection>
              <Stack
                horizontal
                :spacing="StackSpacing.Loose"
              >
                <StackItem class="w-1/3">
                  <div class="flex items-center justify-between">
                    <TextStyle shade="subdued">
                      {{ $t('panel.analytics:products:quantity:total-returned-request') }}
                    </TextStyle>
                  </div>
                  <Heading>{{ returnOrderItemsQuantityInformation.quantity }}</Heading>
                </StackItem>
                <StackItem class="w-1/3">
                  <div class="flex items-center justify-between">
                    <TextStyle shade="subdued">
                      {{ $t('panel.analytics:products:quantity:total-received') }}
                    </TextStyle>
                  </div>
                  <Heading>{{ returnOrderItemsQuantityInformation.quantityReceived }}</Heading>
                </StackItem>
                <StackItem class="w-1/3">
                  <div class="flex items-center justify-between">
                    <TextStyle shade="subdued">
                      {{ $t('panel.analytics:products:quantity:total-back-to-stock') }}
                    </TextStyle>
                  </div>
                  <Heading>{{ returnOrderItemsQuantityInformation.quantityBackInStock }}</Heading>
                </StackItem>
              </Stack>
            </CardSection>
          </Card>
        </stackItem>

        <StackItem>
          <Card
            :title="$t('panel.analytics:products:reasons-of-returns')"
          >
            <CardSection>
              <HorizontalBarChart
                :data="returnReasons.data"
              />
            </CardSection>
          </Card>
        </StackItem>

        <StackItem>
          <Card
            :title="$t('panel.analytics:products:return-questions')"
          >
            <CardSection>
              <ListOfQuestions
                :forms-questions-answers="formsQuestionsAnswers"
              />
            </CardSection>
          </Card>
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>
</template>
