<script lang="ts" setup>
import { provide, ref } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { PageNavigation } from '../partials';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  CardSeparator,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Modal,
  Page,
  TextContainer,
  TextInput,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/panel/Components';
import { TagList, TagPopover } from '@app/panel/Containers';
import { useTags } from '@app/panel/Composables/useTags';
import { Types } from '@app/shared/types/generated-v2';
import { ConditionEngine } from '@app/panel/Containers';
import Actions from '@app/panel/Containers/Actions/components/Actions.vue';
import ConfirmTriggerChange from '@app/panel/Pages/Settings/Forms/Automations/partials/ConfirmTriggerChange.vue';
import {
  useConfirmTriggerChange,
} from '@app/panel/Pages/Settings/Forms/Automations/composables/useConfirmTriggerChange';
import { isEmpty } from 'lodash';
import { router } from '@inertiajs/vue3';
import AutomationTriggerSelect
  from '@app/panel/Containers/AutomationTriggerSelect/components/AutomationTriggerSelect.vue';

type EditFormAutomationsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.EditFormAutomationsViewModel'];

const props = defineProps<EditFormAutomationsViewModel>();

const automationForm = useForm({
  name: props.formAutomation.name,
  trigger: props.formAutomation.trigger,
  parameter: props.formAutomation.parameter,
  conditions: !isEmpty(props.formAutomation.conditions) ? props.formAutomation.conditions : {},
  is_enabled: props.formAutomation.isEnabled,
  actions: !isEmpty(props.formAutomation.formAutomationActions) ? props.formAutomation.formAutomationActions : {},
  tags: [],
});

provide('form', props.form);
provide('countries', props.countries);
provide('returnForm', automationForm);

const { activeTags, removeTag } = useTags(props.tags, automationForm, props.formAutomation.tags);

const { show, asyncConfirm, confirm, cancel } = useConfirmTriggerChange();

function submitForm(): void {
  automationForm.put(route('panel.settings.forms.automations.update', {
    form: props.form,
    formAutomation: props.formAutomation,
  }));
}

function triggerUpdated(newTriggerValue: string) {
  const hasConditions = Object.keys(automationForm.conditions).length > 0;
  const hasActions = Object.keys(automationForm.actions).length > 0;

  if (automationForm.trigger !== null && (hasConditions || hasActions)) {
    asyncConfirm().then((confirmed) => {
      if (confirmed) {
        automationForm.conditions = {};
        automationForm.actions = {};
        automationForm.trigger = newTriggerValue;
      }
    });
  } else {
    automationForm.trigger = newTriggerValue;
  }
}

const isDeleteModalOpen = ref<boolean>(false);

function openDeleteModal(): void {
  isDeleteModalOpen.value = true;
}

function closeDeleteModal(): void {
  isDeleteModalOpen.value = false;
}

function deleteAutomation(): void {
  router.delete(route('panel.settings.forms.automations.delete', {
    form: props.form,
    formAutomation: props.formAutomation,
  }));
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:form:automations:edit:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="submitForm">
        <Card
          :divide="false"
          :secondary-footer-actions="[{
            content: $t('panel.settings:form:automations:edit:delete-cta'),
            variant: ButtonVariant.Critical,
            plain: true,
            callback: openDeleteModal,
          }]"
          :primary-footer-action="{
            content: $t('panel.settings:form:automations:edit:update-cta'),
            type: ButtonType.Submit,
            loading: automationForm.processing,
          }"
        >
          <div class="px-6 pt-6">
            <div class="flex justify-between">
              <div class="flex space-x-4">
                <Heading>
                  <span class="whitespace-nowrap">
                    {{ $t('panel.settings:form:automations:edit:title') }}
                  </span>
                </Heading>

                <TagList
                  :tags="activeTags"
                  @remove="removeTag"
                />
              </div>

              <TagPopover
                v-model="automationForm.tags"
                :tags="tags"
              />
            </div>
          </div>

          <CardSection>
            <FormLayout>
              <FormLayout :columns="2">
                <TextInput
                  v-model="automationForm.name"
                  :label="$t('panel.settings:form:automations:edit:name-label')"
                  :error="automationForm.errors.name"
                />

                <AutomationTriggerSelect
                  v-model:parameter="automationForm.parameter"
                  :trigger="automationForm.trigger"
                  :trigger-options="selectableTriggerOptions"
                  :current-selected-trigger-option="currentSelectedTriggerOption"
                  :errors="automationForm.errors"
                  @update:trigger="triggerUpdated"
                />
              </FormLayout>

              <Toggle
                v-model="automationForm.is_enabled"
                :title="$t('panel.settings:form:automations:edit:enable-label')"
                :error="automationForm.errors.is_enabled"
              />
            </FormLayout>
          </CardSection>

          <CardSeparator />

          <CardSection>
            <div class="mb-4">
              <Heading :element="HeadingElement.H3">
                <TextStyle
                  strong
                  color="slate"
                  :font-size="TextStyleFontSize.Base"
                  :shade="TextStyleShade.Dense"
                >
                  {{ $t('panel.settings:form:automations:edit:conditions-title') }}
                </TextStyle>
              </Heading>
            </div>

            <div class="mb-4">
              <Banner
                :open="!!automationForm.errors.conditions"
                :title="automationForm.errors.conditions"
                :variant="BannerVariant.Critical"
              />
            </div>

            <ConditionEngine
              :available-condition-types="availableConditionTypes[automationForm.trigger] ?? []"
              :condition-type-map="conditionTypeMap"
              :return-reasons="returnReasons"
              :return-statuses-options="returnStatusesOptions"
              :request-statuses-options="requestStatusesOptions"
              :return-questions="returnQuestions"
              :tags="tags"
              :disabled="automationForm.trigger === null"
              :non-applicable-condition-fields="nonApplicableConditionFields"
            />
          </CardSection>

          <CardSeparator />

          <CardSection>
            <div class="mb-4">
              <Heading :element="HeadingElement.H3">
                <TextStyle
                  strong
                  color="slate"
                  :font-size="TextStyleFontSize.Base"
                  :shade="TextStyleShade.Dense"
                >
                  {{ $t('panel.settings:form:automations:edit:action-trigger-title') }}
                </TextStyle>
              </Heading>
            </div>

            <Actions
              v-model="automationForm.actions"
              :action-options="actionOptions[automationForm.trigger] ?? []"
              :errors="automationForm.errors"
              :disabled="automationForm.trigger === null"
              :form="form"
              :form-automation-platform-integration-options="formAutomationPlatformIntegrationOptions"
            />
          </CardSection>
        </Card>
      </Form>

      <ConfirmTriggerChange
        :open="show"
        @confirm="confirm"
        @cancel="cancel"
      />
    </Page>
  </AppLayout>

  <Modal
    :open="isDeleteModalOpen"
    :title="$t('panel.settings:form:automations:delete:title')"
    @close="closeDeleteModal"
  >
    <TextContainer>
      {{ $t('panel.settings:form:automations:delete:message') }}
    </TextContainer>

    <template #actions>
      <Button @click="closeDeleteModal">
        {{ $t('panel.settings:form:automations:delete:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="deleteAutomation"
      >
        {{ $t('panel.settings:form:automations:delete:delete') }}
      </Button>
    </template>
  </Modal>
</template>
