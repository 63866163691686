<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { isNull } from 'lodash';
import { AppLayout } from '@app/panel/Layouts';
import { router } from '@inertiajs/vue3';
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Button,
  ButtonContent,
  ButtonGroup,
  ButtonIcon,
  DataTable,
  DataTableRoot,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { PageNavigation } from './partials';
import {
  CreateWebhookModal,
  DeleteWebhookModal,
  EditWebhookModal,
} from './partials/Webhooks';
import { CheckIcon, EllipsisHorizontalIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/16/solid';
import * as Types from '@app/panel/types/generated';
import { TestModeToggle } from '@app/panel/Components';

type WebhookSubscriptionViewModel = Types.App.Models.ViewModels.WebhookSubscriptionViewModel;

const { t } = useI18n();

const props = defineProps<{
  webhookSubscriptions: WebhookSubscriptionViewModel[];
  events: string[];
  signingSecret: string;
  testMode: boolean;
}>();

const currentEditingSubscription = ref<WebhookSubscriptionViewModel | null>(null);
const isEditModalOpen = computed(() => !isNull(currentEditingSubscription.value));

function openEditModal(token: WebhookSubscriptionViewModel): void {
  currentEditingSubscription.value = token;
}

function closeEditModal(): void {
  currentEditingSubscription.value = null;
}

const isCreateModalOpen = ref<boolean>(false);

function openCreateModal(): void {
  isCreateModalOpen.value = true;
}

function closeCreateModal(): void {
  isCreateModalOpen.value = false;
}

const currentDeletingSubscription = ref<WebhookSubscriptionViewModel | null>(null);
const isDeleteModalOpen = ref<boolean>(false);

function openDeleteModal(item: WebhookSubscriptionViewModel): void {
  currentDeletingSubscription.value = item;
  isDeleteModalOpen.value = true;
}

function closeDeleteModal(): void {
  isDeleteModalOpen.value = false;
}

function onFormChange(): void {
  router.get(route('panel.settings.integrations.webhooks', { test_mode: !props.testMode }));
}

const columns = [
  { accessorKey: 'endpoint', header: t('panel.settings:integrations:webhooks:endpoint') },
  { accessorKey: 'isEnabled', header: t('panel.settings:integrations:webhooks:enabled') },
  { accessorKey: 'actions', header: 'Actions', columnType: 'numeric' },
];
</script>

<template>
  <AppLayout :title="$t('panel.settings:integrations:webhooks')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:integrations:webhooks') }}</PageTitle>
        <template #page-header-actions>
          <ButtonGroup>
            <TestModeToggle
              :model-value="testMode"
              @update:model-value="onFormChange"
            />

            <Button
              v-if="webhookSubscriptions.length > 0"
              @click="openCreateModal"
            >
              <ButtonIcon :icon="PlusIcon" />
              <ButtonContent>{{ $t('panel.settings:integrations:webhooks:add-endpoint') }}</ButtonContent>
            </Button>
          </ButtonGroup>
        </template>
      </PageHeader>

      <DataTableRoot
        v-if="webhookSubscriptions.length > 0"
        :columns="columns"
        :rows="webhookSubscriptions"
      >
        <DataTable>
          <template #isEnabled="{row}">
            <CheckIcon
              v-if="row.isEnabled"
              class="size-4"
            />
            <XMarkIcon
              v-if="!row.isEnabled"
              class="size-4"
            />
          </template>
          <template #actions="{ row }">
            <ActionList alignment="end">
              <ActionListTrigger>
                <Button
                  icon-only
                  variant="ghost"
                  size="small"
                >
                  <ButtonIcon :icon="EllipsisHorizontalIcon" />
                </Button>
              </ActionListTrigger>
              <ActionListBody>
                <ActionListSection>
                  <ActionListItem @click="openEditModal(row)">
                    {{ $t('panel.settings:integrations:webhooks:edit') }}
                  </ActionListItem>
                  <ActionListItem
                    variant="destructive"
                    @click="openDeleteModal(row)"
                  >
                    {{ $t('panel.settings:integrations:webhooks:delete') }}
                  </ActionListItem>
                </ActionListSection>
              </ActionListBody>
            </ActionList>
          </template>
        </DataTable>
      </DataTableRoot>

      <EmptyState v-if="webhookSubscriptions.length === 0">
        <EmptyStateContent>
          <EmptyStateTitle v-if="testMode">
            {{ $t('panel.settings:integrations:webhooks:empty:test-mode:title') }}
          </EmptyStateTitle>
          <EmptyStateTitle v-if="!testMode">
            {{ $t('panel.settings:integrations:webhooks:empty:live-mode:title') }}
          </EmptyStateTitle>
          <EmptyStateDescription>{{ $t('panel.settings:integrations:webhooks:empty:description') }}</EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:integrations:webhooks:add-endpoint') }}</ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>

  <CreateWebhookModal
    :open="isCreateModalOpen"
    :events="events"
    :signing-secret="signingSecret"
    :test-mode="testMode"
    @close="closeCreateModal"
  />

  <EditWebhookModal
    :current="currentEditingSubscription"
    :open="isEditModalOpen"
    :events="events"
    :signing-secret="signingSecret"
    @close="closeEditModal"
  />

  <DeleteWebhookModal
    :open="isDeleteModalOpen"
    :current-item="currentDeletingSubscription"
    @close="closeDeleteModal"
  />
</template>
