<script lang="ts" setup>
import {
  CardSection,
  Form,
  FormLayout,
  Select,
  SelectOption,
  Stack,
  StackItem,
  StackSpacing,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import { type Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { TranslationInputFields, TranslationInputFieldType } from '@app/panel/Containers';
import { computed } from 'vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const props = defineProps<{
  form: Types['App.Models.ViewModels.FormViewModel'];
  shippingInstructions: Array<Types['App.Models.ViewModels.ShippingInstructionViewModel']>;
}>();

const form = useForm({
  store_location_enabled: props.form.storeLocationsEnabled,
  store_location_max_radius: props.form.storeLocationMaxRadius,
  store_location_max_amount: props.form.storeLocationMaxAmount,
  store_location_show_barcode: props.form.storeLocationShowBarcode,
  store_location_show_qr_code: props.form.storeLocationShowQrCode,
  store_location_show_nearby_locations_button: props.form.storeLocationShowNearbyLocationsButton,
  store_location_shipping_method_labels_per_locale: Object.keys(props.form.storeLocationShippingMethodLabelsPerLocale).length > 0
    ? props.form.storeLocationShippingMethodLabelsPerLocale
    : {},
  store_location_shipping_method_descriptions_per_locale: Object.keys(props.form.storeLocationShippingMethodDescriptionsPerLocale).length > 0
    ? props.form.storeLocationShippingMethodDescriptionsPerLocale
    : {},
  store_location_shipping_instruction_uuid: props.form.storeLocationShippingInstructionUuid,
});

const shippingInstructionOptions = computed<SelectOption[]>(
  () => props.shippingInstructions.map((instruction: Types['App.Models.ViewModels.ShippingInstructionViewModel']) => (
    {
      value: instruction.id,
      label: instruction.name,
    }
  )),
);

function save(): void {
  form.put(route('panel.settings.forms.shipping-settings.store-location-settings.update', props.form), {
    preserveScroll: true,
  });
}
</script>

<template>
  <div>
    <Form @submit="save">
      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem>
          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.store-location-settings')">
                {{ $t('panel.settings:forms:shipping-settings:store-location-settings:title') }}
              </CardTitle>
            </CardHeader>
            <CardSection>
              <FormLayout>
                <FormLayout>
                  <Toggle
                    v-model="form.store_location_enabled"
                    :title="$t('panel.settings:forms:shipping-settings:store-location-settings:enable-store-locations')"
                    :error="form.errors.store_location_enabled"
                  />
                </FormLayout>
                <FormLayout>
                  <TextInput
                    v-model="form.store_location_max_radius"
                    :label="$t('panel.settings:forms:shipping-settings:store-location-settings:max-radius')"
                    :error="form.errors.store_location_max_radius"
                    required
                  />
                </FormLayout>
                <FormLayout>
                  <TextInput
                    v-model="form.store_location_max_amount"
                    :label="$t('panel.settings:forms:shipping-settings:store-location-settings:max-amount')"
                    :error="form.errors.store_location_max_amount"
                    required
                  />
                </FormLayout>
                <FormLayout>
                  <Toggle
                    v-model="form.store_location_show_barcode"
                    :title="$t('panel.settings:forms:shipping-settings:store-location-settings:show-barcode')"
                    :error="form.errors.store_location_show_barcode"
                  />
                </FormLayout>
                <FormLayout>
                  <Toggle
                    v-model="form.store_location_show_qr_code"
                    :title="$t('panel.settings:forms:shipping-settings:store-location-settings:show-qr-code')"
                    :error="form.errors.store_location_show_qr_code"
                  />
                </FormLayout>
                <FormLayout>
                  <Toggle
                    v-model="form.store_location_show_nearby_locations_button"
                    :title="$t('panel.settings:forms:shipping-settings:store-location-settings:show-nearby-locations')"
                    :error="form.errors.store_location_show_nearby_locations_button"
                  />
                </FormLayout>
                <FormLayout v-if="props.form.locales !== null">
                  <TranslationInputFields
                    v-model="form.store_location_shipping_method_labels_per_locale"
                    :locales="props.form.locales"
                    :errors="form.errors"
                    field-name="store_location_shipping_method_labels_per_locale"
                    :default-locale-id="props.form.defaultFormLocale.locale.id"
                    :type="TranslationInputFieldType.TextInput"
                    :label="$t('panel.settings:forms:shipping-settings:store-location-settings:shipping-method-label:label')"
                    :help-text="$t('panel.settings:forms:shipping-settings:store-location-settings:shipping-method-label:help-text')"
                  />
                </FormLayout>
                <FormLayout v-if="props.form.locales !== null">
                  <TranslationInputFields
                    v-model="form.store_location_shipping_method_descriptions_per_locale"
                    :locales="props.form.locales"
                    :errors="form.errors"
                    field-name="store_location_shipping_method_descriptions_per_locale"
                    :default-locale-id="props.form.defaultFormLocale.locale.id"
                    :type="TranslationInputFieldType.TextInput"
                    :label="$t('panel.settings:forms:shipping-settings:store-location-settings:shipping-method-description:label')"
                    :help-text="$t('panel.settings:forms:shipping-settings:store-location-settings:shipping-method-description:help-text')"
                  />
                </FormLayout>
                <FormLayout>
                  <Select
                    v-model="form.store_location_shipping_instruction_uuid"
                    :label="$t('panel.settings_forms:shipping-settings:store-location-settings:shipping-instruction:label')"
                    :help-text="$t('panel.settings_forms:shipping-settings:store-location-settings:shipping-instruction:help-text')"
                    :error="form.errors.store_location_shipping_instruction_uuid"
                    :nullable-label="$t('panel.settings_forms:shipping-settings:store-location-settings:shipping-instruction:nullable-label')"
                    :options="shippingInstructionOptions"
                  />
                </FormLayout>
              </FormLayout>
            </CardSection>
            <CardFooter>
              <Button
                type="submit"
                :loading="form.processing"
                :disabled="!form.isDirty"
              >
                {{ $t('panel.global:actions:save') }}
              </Button>
            </CardFooter>
          </Card>
        </StackItem>
      </Stack>
    </Form>
  </div>
</template>
