<script lang="ts" setup>
import {
  DeprecatedBarChart,
  LineChart,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { ActiveDataPoint } from '@app/panel/Pages/Analytics/types/ActiveDataPoint';

type DataPoint = Types.App.Http.ViewModels.Panel.Analytics.DataPoint;

const props = withDefaults(defineProps<{
  label: string;
  dataPoints: DataPoint[];
  type: 'bar' | 'line';
  height?: number | 'h-full';
  hideYAxis?: boolean;
}>(), {
  hideYAxis: false,
  height: 80,
});

const emit = defineEmits<{
  activeDataPointChanged: [activeItem: null | ActiveDataPoint];
}>();

const data = computed<{ labels: string[]; values: [] }>(() => {
  return {
    labels: props.dataPoints.flatMap((dataPoint) => dataPoint.label),
    values: props.dataPoints.flatMap((dataPoint) => dataPoint.value),
  };
});

function onActiveDataPointChange(activeDataPoint: null | ActiveDataPoint) {
  emit('activeDataPointChanged', activeDataPoint);
}
</script>

<template>
  <DeprecatedBarChart
    v-if="type === 'bar'"
    :labels="data.labels"
    :datasets="[{
      label: label,
      data: data.values,
    }]"
  />
  <LineChart
    v-if="type === 'line'"
    :labels="data.labels"
    :datasets="[{
      label: label,
      data: data.values,
    }]"
    :hide-y-axis="hideYAxis"
    :height="height"
    @active-data-point-changed="onActiveDataPointChange"
  />
</template>
