import { computed, readonly, ref } from 'vue';

export function useHexColor() {
  const hexColor = ref<string>(null);

  function setHexColor(value: string) {
    hexColor.value = value;
  }

  function hexdec(hex) {
    return parseInt(hex, 16);
  }

  function getContrast() {
    const r1 = hexdec(hexColor.value.substring(1, 3));
    const g1 = hexdec(hexColor.value.substring(3, 5));
    const b1 = hexdec(hexColor.value.substring(5, 7));

    const l1 = 0.2126 * ((r1 / 255) ** 2.2) + 0.7152 * ((g1 / 255) ** 2.2) + 0.0722 * ((b1 / 255) ** 2.2);
    const l2 = 0.2126 * ((0 / 255) ** 2.2) + 0.7152 * ((0 / 255) ** 2.2) + 0.0722 * ((0 / 255) ** 2.2);

    let contrast;

    if (l1 > l2) {
      contrast = (l1 + 0.05) / (l2 + 0.05);
    } else {
      contrast = (l2 + 0.05) / (l1 + 0.05);
    }

    return contrast;
  }

  const contrast = computed(() => {
    return getContrast() > 5
      ? '#000000'
      : '#ffffff';
  });

  return {
    hexColor: readonly(hexColor),
    contrast,
    setHexColor,
  };
}
