import { createApp, DefineComponent, h } from 'vue';
import { createInertiaApp, Link, usePage } from '@inertiajs/vue3';
import VueClickAway from 'vue3-click-away';
import Vapor from 'laravel-vapor';
import * as Sentry from '@sentry/vue';
import { getEnv } from '@app/shared/Utils';
import { User } from '@app/panel/types';
import createI18n from './Utils/I18n';
import { fallbackImage } from '@app/shared/Directives';
import ignoreErrors from '@app/shared/config/sentry-errors';

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
(globalThis as any).FOCUSUI_DARKMODE_SUPPORTED = false;

// Temporary disabling dark mode until it's fully supported
(globalThis as any).FOCUSUI_DARKMODE_SUPPORTED = false;

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
  progress: {
    delay: 0,
    color: '#2b2bd1',
  },
  title: (title: string) => {
    const appName = usePage().props.appName as string | undefined;

    if (!appName) {
      return title;
    }

    return `${title} - ${appName}`;
  },
  resolve: (name: string): DefineComponent => {
    const pages = import.meta.glob<DefineComponent>('./Pages/**/*.vue', { eager: true });

    return pages[`./Pages/${name}.vue`];
  },
  setup({ el, App, props, plugin }) {
    const i18n = createI18n(<string>props.initialPage.props.language);

    const application = createApp({
      render: () => h(App, props),
    })
      .use(VueClickAway)
      .use(i18n)
      .use(plugin)
      .component('InertiaLink', Link)
      .directive('fallback-img', fallbackImage);

    application.config.globalProperties.$route = route;

    Sentry.init({
      ignoreErrors,
      app: application,
      dsn: getEnv<string>('sentry_dns') ?? undefined,
      integrations: [],
      trackComponents: true,
    });

    const user = props.initialPage.props.user as User;

    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
      });
    }

    application.mount(el);

    return application;
  },
});
