<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  Checkbox,
  Form,
  FormLayout,
  Modal,
  Select,
  TextInput,
  TextInputFieldTypes,
  TextInputIconPosition,
  TextStyle,
} from '@app/panel/Components';
import type { Types } from '@app/shared/types/generated-v2';
import { App } from '@app/shared/types/generated-v2';
import { InertiaForm, router, useForm, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import { cleanObject } from '@app/panel/Utils/object';
import { useI18n } from 'vue-i18n';
import { InputLabel } from '@returnless/focus-ui';

type FormShippingMethodsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel'];

const props = defineProps<{
  open: boolean;
  form: FormShippingMethodsViewModel['form'];
  shippingInstructions: FormShippingMethodsViewModel['shippingInstructions'];
  selectedContract: FormShippingMethodsViewModel['selectedContract'];
  currentEditingShippingMethod?: FormShippingMethodsViewModel['currentEditingShippingMethod'];
  currentEditingFormShippingMethod?: FormShippingMethodsViewModel['currentEditingFormShippingMethod'];
  returnAddresses: FormShippingMethodsViewModel['returnAddresses'];
  filterForm: InertiaForm;
}>();

const hasPartner = computed<boolean>(() => {
  return usePage().props.hasPartner as boolean;
});

const { t } = useI18n();

const shippingMethodName = computed<string>(() => props.currentEditingFormShippingMethod?.name || props.currentEditingShippingMethod?.name);

const shippingMethodUpdateForm = useForm({
  price: props.currentEditingShippingMethod?.fromCountry.supportsPaidReturns
    ? props.currentEditingFormShippingMethod?.priceMajor
    : 0,
  name: shippingMethodName.value,
  is_preferred: props.currentEditingFormShippingMethod?.isPreferred,
  shipping_instruction_id: props.currentEditingFormShippingMethod?.shippingInstruction?.id,
  return_address_id: props.currentEditingFormShippingMethod?.returnAddress?.id,
});

const shippingInstructionOptions = computed(() => {
  return props.shippingInstructions.map((instruction) => {
    return {
      label: instruction.name,
      value: instruction.id,
    };
  });
});

const returnAddressesOptions = computed(() => {
  return props.returnAddresses.map((returnAddress) => {
    return {
      label: returnAddress.name,
      value: returnAddress.id,
    };
  });
});

function submitShippingMethodForm(): void {
  shippingMethodUpdateForm.put(route('panel.settings.forms.update-shipping-method', {
    form: props.form,
    shippingMethod: props.currentEditingShippingMethod.id,
    shippingContract: props.selectedContract?.id ?? null,
  }), {
    preserveScroll: true,
  });
}

function closeShippingEditModal(): void {
  const url = route('panel.settings.forms.shipping-methods', { form: props.form });

  const data = cleanObject({
    ...props.filterForm.data(),
    contract: props.selectedContract?.id,
  });

  router.get(url, data, {
    preserveScroll: true,
  });
}
</script>

<template>
  <Modal
    :open="open"
    :title="t('panel.forms:shipping-methods:edit', { name: currentEditingShippingMethod?.name })"
    @close="closeShippingEditModal"
  >
    <template #icon>
      <img
        :src="currentEditingShippingMethod?.imageSrc"
        :alt="t('panel.forms:shipping-methods:carrier-image')"
        class="size-full"
        loading="lazy"
      >
    </template>

    <div>
      <p
        class="mb-6 flex divide-x text-sm text-gray-500"
      >
        <span class="flex-1 text-center">
          {{ currentEditingShippingMethod.fromCountry.isoCode }}
          {{ t('panel.forms:shipping-methods:to') }}
          {{ currentEditingShippingMethod.toCountry.isoCode }}
        </span>
        <span class="flex-1 text-center">
          {{ currentEditingShippingMethod.fromWeight ? currentEditingShippingMethod.fromWeight : '-' }}
          {{ t('panel.forms:shipping-methods:to') }}
          {{ currentEditingShippingMethod.toWeight ? currentEditingShippingMethod.toWeight : '-' }}
        </span>
        <span
          v-if="selectedContract === null && ! hasPartner"
          class="flex-1 text-center"
        >
          {{ t('panel.forms:shipping-methods:cost', { cost: currentEditingShippingMethod.cost }) }}
        </span>
        <span
          v-if="currentEditingShippingMethod.type === App.Enums.ShippingMethodType.PickUp"
          class="flex-1 text-center"
        >
          {{ t('panel.forms:shipping-methods:home-address') }}
        </span>
        <span
          v-if="currentEditingShippingMethod.type === App.Enums.ShippingMethodType.DropOff"
          class="flex-1 text-center"
        >
          {{ t('panel.forms:shipping-methods:parcelshop') }}
        </span>
      </p>
    </div>

    <Form
      id="updateShippingMethodForm"
      @submit="submitShippingMethodForm"
    >
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="shippingMethodUpdateForm.price"
            :icon-position="TextInputIconPosition.Left"
            :label="t('panel.forms:shipping-methods:price')"
            :type="TextInputFieldTypes.Number"
            :help-text="currentEditingShippingMethod?.fromCountry.supportsPaidReturns
              ? t('panel.forms:shipping-methods:vat-included')
              : t('panel.forms:shipping-methods:no-paid-returns-available')"
            :error="shippingMethodUpdateForm.errors.price"
            allow-decimal
            step="0.01"
            min="0"
            :disabled="currentEditingShippingMethod.fromCountry.supportsPaidReturns === false"
          >
            <template #icon>
              <TextStyle>
                {{
                  currentEditingFormShippingMethod?.shippingMethod.fromCountry.currencySymbol ?? currentEditingShippingMethod.fromCountry.currencySymbol
                }}
              </TextStyle>
            </template>
          </TextInput>
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="shippingMethodUpdateForm.name"
            :label="t('panel.forms:shipping-methods:shipping-method-name')"
            :error="shippingMethodUpdateForm.errors.name"
          />
        </FormLayout>

        <FormLayout>
          <Select
            v-model="shippingMethodUpdateForm.shipping_instruction_id"
            :label="t('panel.forms:shipping-methods:shipping-instruction')"
            :options="shippingInstructionOptions"
            :nullable-label="t('panel.forms:shipping-methods:shipping-instruction:nullable-label')"
            :error="shippingMethodUpdateForm.errors.shipping_instruction_id"
          />
        </FormLayout>

        <FormLayout
          v-if="!currentEditingShippingMethod.canEditReturnAddress"
          class="!gap-0"
        >
          <InputLabel
            :label="t('panel.forms:shipping-methods:add:return-address')"
          />
          <p class="text-sm text-slate-400">
            {{ t('panel.forms:shipping-methods:edit:cannot-change-return-address-dhl') }}
          </p>
        </FormLayout>

        <FormLayout
          v-if="currentEditingShippingMethod.canEditReturnAddress"
          class="!gap-0"
        >
          <Select
            v-model="shippingMethodUpdateForm.return_address_id"
            :label="t('panel.forms:shipping-methods:add:return-address')"
            :options="returnAddressesOptions"
            :error="shippingMethodUpdateForm.errors.return_address_id"
            :disabled="!currentEditingShippingMethod.canEditReturnAddress"
          />
        </FormLayout>

        <FormLayout>
          <Checkbox
            v-model="shippingMethodUpdateForm.is_preferred"
            :label="t('panel.forms:shipping-methods:is-preferred')"
          />
        </FormLayout>
      </FormLayout>
    </Form>
    <template #actions>
      <Button
        v-if="currentEditingFormShippingMethod"
        form="updateShippingMethodForm"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
      >
        {{ t('panel.forms:shipping-methods:edit:save') }}
      </Button>

      <Button
        v-if="! currentEditingFormShippingMethod"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="updateShippingMethodForm"
      >
        {{ t('panel.forms:shipping-methods:edit:enable', { name: currentEditingShippingMethod.carrier.name }) }}
      </Button>
    </template>
  </Modal>
</template>
