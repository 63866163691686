<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Card,
  CardSection,
  Heading,
  Modal,
  ModalSize,
  ResourceItem,
  ResourceList,
  Stack,
  StackItem,
  TextStyleShade,
} from '@app/panel/Components';
import TextStyle from '@app/panel/Components/TextStyle/components/TextStyle.vue';
import { App, type Types } from '@app/shared/types/generated-v2';
import { computed, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { StatusBadge } from '@app/panel/types';

const props = defineProps<{
  returnOrder: Types['App.Http.ViewModels.Panel.Requests.Detail.ShowRequestOrderViewModel']['returnOrder'];
  selectedReturnAddress: Types['App.Http.ViewModels.Panel.Requests.Detail.ShowRequestOrderViewModel']['returnAddress'];
  returnAddressOptions: { value: string; label: string; description: string }[];
}>();

const modalOpen = ref(false);

const form = useForm({
  return_address_id: props.selectedReturnAddress?.id ?? null,
});

function openModal() {
  form.return_address_id = props.selectedReturnAddress?.id ?? null;
  modalOpen.value = true;
}

function closeModal() {
  modalOpen.value = false;
}

const addressLine = computed<null | string>(() => {
  if (props.selectedReturnAddress === null) {
    return null;
  }

  return `${props.selectedReturnAddress.addressLine.trim()}, ${props.selectedReturnAddress.country.isoCode}`;
});

function selectReturnAddress(id: null | string) {
  form.return_address_id = id;
}

function saveReturnAddress() {
  form.put(route('panel.requests.return-address.update', { returnOrder: props.returnOrder }), {
    preserveScroll: true,
    onSuccess: () => {
      closeModal();
    },
  });
}

function getActiveBadge(returnAddressId: null | string): StatusBadge[] {
  return returnAddressId === form.return_address_id
    ? [{ color: App.Enums.ColorPalette.Green, label: 'Selected' }]
    : [];
}
</script>

<template>
  <Card>
    <CardSection>
      <div class="flex flex-col -mx-6">
        <div
          v-if="selectedReturnAddress?.rawMapImageSrc"
          class="relative w-full -mt-6 mb-2"
        >
          <img
            class="w-full -mt-6"
            :src="selectedReturnAddress.rawMapImageSrc"
          >
          <div class="absolute bottom-0 left-0 w-full h-10 bg-gradient-to-b from-transparent to-60% to-white" />
        </div>

        <div class="mx-6">
          <Heading>{{ $t('panel.requests.return_address.card_title') }}</Heading>

          <div class="flex justify-between mt-6 items-center">
            <div
              v-if="selectedReturnAddress"
              class="flex flex-col"
            >
              <TextStyle
                :shade="TextStyleShade.Dense"
                strong
              >
                {{ selectedReturnAddress.name }}
              </TextStyle>
              <TextStyle :shade="TextStyleShade.Subdued">
                {{ addressLine }}
              </TextStyle>
            </div>
            <div
              v-if="! selectedReturnAddress"
              class="flex flex-col"
            >
              <TextStyle
                :shade="TextStyleShade.Dense"
              >
                {{ $t('panel.requests.return_address.use_default') }}
              </TextStyle>
            </div>
            <div>
              <TextStyle
                class="cursor-pointer hover:text-brand-600"
                :shade="TextStyleShade.Subdued"
                :color="App.Enums.ColorPalette.Brand"
                @click="openModal"
              >
                {{ $t('panel.requests.return_address.change_address') }}
              </TextStyle>
            </div>
          </div>
        </div>
      </div>
    </CardSection>
  </Card>

  <Modal
    :open="modalOpen"
    :size="ModalSize.Large"
    :title="$t('panel.requests.return_address.modal_title')"
    @close="closeModal"
  >
    <Stack vertical>
      <StackItem>
        <ResourceList :draggable="false">
          <ResourceItem
            :id="null"
            :label="$t('panel.requests.return_address.use_default')"
            :show-navigation-icon="false"
            :badges="getActiveBadge(null)"
            @click="selectReturnAddress(null)"
          />
        </ResourceList>
      </StackItem>

      <StackItem class="max-h-[60vh] overflow-auto">
        <ResourceList :draggable="false">
          <ResourceItem
            v-for="returnAddressOption in returnAddressOptions"
            :id="returnAddressOption.value"
            :key="returnAddressOption.value"
            :draggable="false"
            :label="returnAddressOption.label"
            :description="returnAddressOption.description"
            :show-navigation-icon="false"
            :badges="getActiveBadge(returnAddressOption.value)"
            @click="selectReturnAddress(returnAddressOption.value)"
          />
        </ResourceList>
      </StackItem>
    </Stack>
    <template #actions>
      <Button @click="closeModal">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :disabled="!form.isDirty"
        :loading="form.processing"
        @click="saveReturnAddress"
      >
        {{ $t('panel.global:actions:save') }}
      </Button>
    </template>
  </Modal>
</template>
