<script lang="ts" setup>
import {
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Page,
  PageActions,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { AppLayout } from '@app/panel/Layouts';
import { FieldRenderer, PageNavigation } from '../partials';
import { computed, provide, toRef } from 'vue';
import TextInput from '@app/customer/Components/TextInput/components/TextInput.vue';
import Toggle from '@app/panel/Components/Toggle/components/Toggle.vue';
import * as Types from '@app/panel/types/generated';
import { useIntegrations } from '@app/panel/Composables/useIntegrations';
import { usePrecomputedValues } from '@app/panel/Composables/usePrecomputedValues';
import { transform } from 'lodash';

type CreateShippingContractViewModel = Types.App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.CreateShippingContractViewModel;

const props = defineProps<{
  shippingIntegration: CreateShippingContractViewModel['shippingIntegration'];
  formStructure: CreateShippingContractViewModel['formStructure'];
  countries: CreateShippingContractViewModel['countries'];
}>();

const countryOptions = computed(() => {
  return transform(props.countries, (result, value) => {
    return result[value.isoCode] = value.name;
  });
});

const precomputedValues = computed(() => {
  return {
    countries: countryOptions.value,
  };
});

const { settingsScaffold } = useIntegrations(props.formStructure);

usePrecomputedValues(toRef(props, 'formStructure'), precomputedValues);

const form = useForm({
  ...settingsScaffold.value,
  name: null,
  description: null,
  is_enabled: true,
});

function save(): void {
  form.post(route('panel.settings.integrations.shipping-contracts.store', {
    shippingIntegration: props.shippingIntegration,
  }), {
    preserveScroll: true,
    preserveState: true,
  });
}

provide('form', form);
</script>

<template>
  <AppLayout :title="$t('panel.settings:contracts:shipping:create:title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <PageActions>
        <div>
          <Heading :element="HeadingElement.H3">
            {{ $t('panel.settings:contracts:shipping:create:title') }}
          </Heading>
        </div>
      </PageActions>

      <Form @submit="save">
        <Card
          :primary-footer-action="{
            content: $t('panel.settings:contracts:shipping:create:save'),
            type: ButtonType.Submit,
            loading: form.processing,
          }"
        >
          <CardSection>
            <FormLayout>
              <TextInput
                v-model="form.name"
                :error="form.errors.name"
                :label="$t('panel.settings:contracts:shipping:create:name')"
              />
              <TextInput
                v-model="form.description"
                :error="form.errors.description"
                :label="$t('panel.settings:contracts:shipping:create:description')"
              />
              <Toggle
                v-model="form.is_enabled"
                :title="$t('panel.settings:contracts:shipping:create:enable')"
              />
            </FormLayout>
          </CardSection>
          <CardSection
            v-for="(section, index) in formStructure"
            :key="index"
          >
            <FormLayout>
              <FormLayout
                v-for="field in section.fields"
                :key="field.key"
              >
                <FieldRenderer
                  :field-name="field.key"
                  :fields="field.fields"
                  :label="field.label"
                  :options="field.options"
                  :placeholder="field.placeholder"
                  :type="field.type"
                  :validation="field.validation"
                />
              </FormLayout>
            </FormLayout>
          </CardSection>
        </Card>
      </Form>
    </Page>
  </AppLayout>
</template>
