<script setup lang="ts">
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  Link,
} from '@returnless/focus-ui';
import { router, usePage } from '@inertiajs/vue3';
import { ref } from 'vue';

const page = usePage();

const showDialog = ref(true);
const dismissedAt = localStorage.getItem('downgrade_dialog_dismissed_at') || '0';
const dayInMilliSeconds = 86400000;

// Show the dialog if it has been dismissed more than a day ago
showDialog.value = (parseInt(dismissedAt) || 0) + dayInMilliSeconds < Date.now();

function goToDowngradeTool() {
  closeDialog();

  router.visit(route('panel.account.downgrade.tenant'));
}
function closeDialog() {
  localStorage.setItem('downgrade_dialog_dismissed_at', Date.now().toString());

  showDialog.value = false;
}
</script>

<template>
  <AlertDialog
    :open="page.props.showDowngradeRequiredDialog && showDialog"
    @close="closeDialog"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ $t('panel.account:downgrade:required-downgrade-modal:title') }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          <i18n-t
            keypath="panel.account:downgrade:required-downgrade-modal:description"
            tag="TextStyle"
          >
            <template #currentPlan>
              {{ page.props.downgradeRequiredData.currentPlan || '' }}
            </template>
            <template #downgradeBefore>
              <strong>{{ page.props.downgradeRequiredData.downgradeBefore || '' }}</strong>
            </template>
            <template #newLine>
              <br><br>
            </template>
            <template #viewUpgradeOptionsLink>
              <Link :href="$route('panel.account.billing')">
                {{ $t('panel.account:downgrade:global:view-upgrade-options') }}
              </Link>
            </template>
          </i18n-t>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <Button
          variant="secondary"
          @click="closeDialog"
        >
          {{ $t('panel.account:downgrade:required-downgrade-modal:downgrade-later-button') }}
        </Button>
        <Button
          variant="primary"
          @click="goToDowngradeTool"
        >
          {{ $t('panel.account:downgrade:required-downgrade-modal:downgrade-now-button') }}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
