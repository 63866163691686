<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  Modal,
  TextInput,
  TextInputFieldTypes,
  TextStyle,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import { BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { onMounted, PropType } from 'vue';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;
type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

defineEmits<{
  verify: [];
}>();

const form = useForm({
  title: '',
  public_key: '',
  secret_key: '',
});

const {
  currentStep,
  firstStep,
  lastStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function saveCredentials(): void {
  form.submit('post', route('panel.integration.sendcloud3.save-credentials'));
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.integrations:sendcloud3:title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <div
      v-if="currentStep === 1"
      class="space-y-6"
    >
      <div class="space-y-1 mt-6">
        <TextStyle>
          <strong>{{ $t('panel.integrations:sendcloud3:step1:explanation') }}</strong>
        </TextStyle>
      </div>

      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.title"
            :label="$t('panel.integrations:sendcloud3:step1:title')"
            :error="form.errors.title"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.public_key"
            :label="$t('panel.integrations:sendcloud3:step1:public_key')"
            :type="TextInputFieldTypes.Text"
            :error="form.errors.public_key"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.secret_key"
            :label="$t('panel.integrations:sendcloud3:step1:secret_key')"
            :type="TextInputFieldTypes.Password"
            :error="form.errors.secret_key"
          />
        </FormLayout>
      </FormLayout>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.integrations:sendcloud3:integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="saveCredentials"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:sendcloud3:verify_integration_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
