<script lang="ts" setup>
import {
  Button,
  Popover,
  PopoverPlacement,
  Spinner,
  StatusIndicator,
  TextStyle,
} from '@app/panel/Components';
import { MagnifyingGlassIcon, TagIcon } from '@heroicons/vue/24/outline';
import { theme } from '@app/panel/Composables/useTheme';
import { computed, ref } from 'vue';
import type { Types } from '@app/shared/types/generated-v2';
import { useSearch } from '@app/panel/Composables/useSearch';
import { isEmpty } from 'lodash';

type TagViewModel = Types['App.Models.ViewModels.TagViewModel'];

const props = defineProps<{
  tags: TagViewModel[];
  modelValue: string[];
}>();

const emit = defineEmits<{
  (e: 'update:model-value', modelValue: string[]);
}>();

const parent = ref<HTMLElement | null>(null);
const active = ref<boolean>(false);

function toggle(): void {
  active.value = !active.value;
}

function close(): void {
  active.value = false;
}

function updateModelValue(tagId: string): void {
  emit('update:model-value', [...props.modelValue, tagId]);
}

const predicate = (tag: TagViewModel, query: string) => tag.name.toLowerCase().includes(query.toLowerCase());

const [
  filteredTags,
  handleChange,
  loading,
  query,
] = useSearch(
  props.tags,
  predicate,
  { debounceTimeout: 200, filter: true },
);

const unusedTags = computed(() => {
  return filteredTags.value.filter((filteredTag) => {
    return !props.modelValue.includes(filteredTag.id);
  });
});
</script>

<template>
  <div
    ref="parent"
    v-click-away="close"
    class="relative"
    @keyup.esc="close"
  >
    <Button
      data-testid="tags-button"
      @click="toggle"
    >
      <div class="flex items-center space-x-1.5">
        <TagIcon class="size-4" />

        <span class="text-xs">{{ $t('panel.containers:tags-popover:tags') }}</span>
      </div>
    </Button>

    <Popover
      data-testid="tags-popover"
      :parent="parent"
      :active="active"
      :placement="PopoverPlacement.BottomEnd"
    >
      <div class="relative mx-auto min-w-56 text-sm text-slate-600">
        <input
          data-testid="tags-search-input"
          class="h-8 w-full rounded-md border border-slate-300 bg-white px-3 pl-8 text-sm focus:outline-none"
          type="search"
          name="search"
          :placeholder="$t('panel.containers:tags-popover:search')"
          @input="handleChange"
        >
        <button
          type="submit"
          class="absolute left-0 top-0 ml-3 mt-2"
          :class="theme('focus-outline-color')"
        >
          <Spinner
            v-if="loading"
            class="w-4"
          />
          <MagnifyingGlassIcon
            v-else
            class="w-4"
          />
        </button>
      </div>

      <div class="mt-2 max-h-72 overflow-y-auto">
        <template v-if="! isEmpty(tags)">
          <button
            v-for="tag in unusedTags"
            :key="tag.id"
            data-testid="tag-search-item"
            class="w-full cursor-pointer p-1 hover:bg-slate-50"
            @click.stop="updateModelValue(tag.id)"
          >
            <div class="flex items-center space-x-2">
              <div>
                <StatusIndicator :color="tag.color" />
              </div>

              <TextStyle no-wrap>
                {{ tag.name }}
              </TextStyle>
            </div>
          </button>
        </template>

        <div
          v-if="! isEmpty(tags) && isEmpty(filteredTags) && ! isEmpty(query)"
          class="text-center"
          data-testid="no-tags-found"
        >
          <TextStyle>{{ $t('panel.containers:tags-popover:no-tags-found', { query }) }}</TextStyle>
        </div>

        <div
          v-else-if="isEmpty(tags) || isEmpty(unusedTags)"
          data-testid="no-tags-available"
          class="text-center"
        >
          <TextStyle>{{ $t('panel.containers:tags-popover:no-tags-available') }}</TextStyle>
        </div>
      </div>
    </Popover>
  </div>
</template>
