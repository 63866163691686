<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Page,
  PageActions,
  StackSpacing,
  TextContainer,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { AppLayout } from '@app/panel/Layouts';
import * as Types from '@app/panel/types/generated';
import { FieldRenderer, PageNavigation } from '../partials';
import { computed, provide, ref, toRef } from 'vue';
import TextInput from '@app/customer/Components/TextInput/components/TextInput.vue';
import Toggle from '@app/panel/Components/Toggle/components/Toggle.vue';
import { PasswordConfirmation } from '@app/panel/Containers';
import Stack from '@app/panel/Components/Stack/components/Stack.vue';
import StackItem from '@app/panel/Components/Stack/components/StackItem.vue';
import { router } from '@inertiajs/vue3';
import { useIntegrations } from '@app/panel/Composables/useIntegrations';
import { usePrecomputedValues } from '@app/panel/Composables/usePrecomputedValues';
import { transform } from 'lodash';

type ShowShippingContractViewModel = Types.App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.ShowShippingContractViewModel;

const props = defineProps<{
  countries: ShowShippingContractViewModel['countries'];
  formStructure: ShowShippingContractViewModel['formStructure'];
  settings: ShowShippingContractViewModel['settings'];
  shippingContract: ShowShippingContractViewModel['shippingContract'];
  showUpdateButton: boolean;
  shippingContracts: ShowShippingContractViewModel['shippingContracts'];
}>();

const countryOptions = computed(() => {
  return transform(props.countries, (result, value) => {
    return result[value.isoCode] = value.name;
  });
});

const precomputedValues = computed(() => {
  return {
    countries: countryOptions.value,
    default_contracts: props.shippingContract.contractDetails['default_contracts'],
  };
});

usePrecomputedValues(toRef(props, 'formStructure'), precomputedValues);

const isRemoving = ref(false);

const { settingsScaffold } = useIntegrations(props.formStructure);

const form = useForm({
  ...settingsScaffold.value,
  ...props.settings,
  default_contracts: props.shippingContract.contractDetails['default_contracts'],
});

function save(): void {
  form.put(route('panel.settings.integrations.shipping-contracts.update', {
    shippingContract: props.shippingContract,
  }), {
    preserveScroll: true,
    preserveState: true,
  });
}

function removeIntegration() {
  router.delete(route('panel.settings.integrations.shipping-contracts.delete', props.shippingContract), {
    onStart: () => isRemoving.value = true,
    onFinish: () => isRemoving.value = false,
  });
}

function updateContractData(): void {
  router.get(route('panel.settings.integrations.shipping-contracts.refresh', props.shippingContract.id));
}

function getBlockErrors(blockName: string): null | Record<string, string> {
  const scopedErrors = Object.keys(form.errors)
    .filter((key: string) => key.startsWith(blockName))
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: form.errors[key],
      };
    }, {});

  if (Object.keys(scopedErrors).length === 0) {
    return null;
  }

  return scopedErrors;
}

provide('form', form);
</script>

<template>
  <AppLayout :title="$t('panel.settings:contracts:shipping:show:title', { name: props.shippingContract.name })">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <PageActions>
        <div>
          <Heading :element="HeadingElement.H3">
            {{ $t('panel.settings:contracts:shipping:show:title', { name: props.shippingContract.name }) }}
          </Heading>
        </div>

        <div>
          <Button
            v-if="showUpdateButton"
            :variant="ButtonVariant.Primary"
            :type="ButtonType.Button"
            @click="updateContractData()"
          >
            {{ $t('panel.settings:contracts:shipping:show:refresh-button') }}
          </Button>
        </div>
      </PageActions>

      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem>
          <Form @submit="save">
            <Card
              :primary-footer-action="{
                content: $t('panel.settings:contracts:shipping:show:save'),
                type: ButtonType.Submit,
                loading: form.processing,
              }"
            >
              <CardSection>
                <FormLayout>
                  <Banner
                    v-if="!props.shippingContract.isActivated"
                    :variant="BannerVariant.Info"
                  >
                    {{ $t('panel.settings:contracts:shipping:pending-warning') }}
                  </Banner>

                  <TextInput
                    v-model="form.name"
                    label="Name"
                    :error="form.errors.name"
                  />
                  <TextInput
                    v-model="form.description"
                    label="Description"
                    :error="form.errors.description"
                  />
                  <Toggle
                    v-model="form.is_enabled"
                    title="Enable contract"
                  />
                </FormLayout>
              </CardSection>

              <CardSection
                v-for="(section, index) in formStructure"
                :key="index"
              >
                <FormLayout
                  v-if="section.key==='contracts' && shippingContracts.length !== 0"
                >
                  <div>
                    <Heading :element="HeadingElement.H3">
                      {{ section.title }}
                    </Heading>
                  </div>
                  <FormLayout
                    v-for="(contract, contractIndex) in shippingContracts"
                    :key="contractIndex"
                  >
                    <FieldRenderer
                      v-if="contract.options.length !== 0"
                      :field-name="'default_contracts.' + contractIndex"
                      :label="contract.label"
                      :options="contract.options"
                      type="select"
                    />

                    <FieldRenderer
                      v-if="contract.options.length === 0"
                      :field-name="'default_contracts.' + contractIndex"
                      :label="contract.label"
                      placeholder="e.g. carrier contract ID"
                      type="string"
                    />
                  </FormLayout>
                </FormLayout>

                <FormLayout
                  v-else
                >
                  <FormLayout
                    v-for="field in section.fields"
                    :key="field.key"
                  >
                    <FieldRenderer
                      :field-name="field.key"
                      :label="field.label"
                      :type="field.type"
                      :fields="field.fields"
                      :options="field.options"
                      :errors="getBlockErrors(field.key)"
                    />
                  </FormLayout>
                </FormLayout>
              </CardSection>
            </Card>
          </Form>
        </StackItem>

        <StackItem>
          <Card
            :title="$t('panel.settings:contracts:shipping:show:delete:title')"
          >
            <CardSection>
              <TextContainer>
                {{ $t('panel.settings:contracts:shipping:show:delete:message') }}
              </TextContainer>
            </CardSection>
            <CardSection
              subdued
              dense
            >
              <div class="flex justify-end">
                <PasswordConfirmation @confirmed="removeIntegration">
                  <Button
                    :variant="ButtonVariant.Critical"
                    :loading="isRemoving"
                  >
                    {{ $t('panel.settings:contracts:shipping:show:delete:action') }}
                  </Button>
                </PasswordConfirmation>
              </div>
            </CardSection>
          </Card>
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>
</template>
