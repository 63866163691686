<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  Button,
  Card,
  CardSection,
  Heading,
  Listbox,
  ListboxOption,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import ShipmentModal from '@app/panel/Pages/Returns/partials/DetailShipments/ShipmentModal.vue';
import ShipmentItem from '@app/panel/Pages/Returns/partials/DetailShipments/ShipmentItem.vue';
import CreateShipmentModal from '@app/panel/Pages/Returns/partials/DetailShipments/CreateShipmentModal.vue';
import type { Types } from '@app/shared/types/generated-v2';
import { useApi } from '@app/panel/Composables/useHttp';
import ExternalShipmentItem from '@app/panel/Pages/Returns/partials/DetailShipments/ExternalShipmentItem.vue';

type ShipmentViewModel = Types['App.Models.ViewModels.ShipmentViewModel'];
type FormShippingMethodViewModel = Types['App.Models.ViewModels.FormShippingMethodViewModel'];
type ReturnOrderViewModel = Types['App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderViewModel'];
type ReturnAddressViewModel = Types['App.Models.ViewModels.ReturnAddressViewModel'];

const props = defineProps<{
  returnOrder: ReturnOrderViewModel;
  selectedReturnAddress: null | ReturnAddressViewModel;
}>();

const currentShipmentId = ref<string | null>(null);
const currentShipment = computed((): ShipmentViewModel => {
  const shipments = props.returnOrder.shipments as ShipmentViewModel[];

  return shipments.find(
    (shipment: ShipmentViewModel) => shipment.id === currentShipmentId.value,
  )!;
});

function setCurrentShipmentId(shipmentId: string): void {
  currentShipmentId.value = shipmentId;
}

const isCurrentShipmentSet = computed<boolean>(() => currentShipmentId.value !== null);

function clearCurrentShipment(): void {
  currentShipmentId.value = null;
}

const createShipmentModalOpen = ref<boolean>(false);

const formShippingMethods = ref<FormShippingMethodViewModel[]>([]);

async function showCreateShipmentModal() {
  const response = await useApi().get(route('api.v1.return-order.form-shipping-methods', props.returnOrder.id));
  formShippingMethods.value = response.data.data;
  createShipmentModalOpen.value = true;
}

function closeCreateShipmentModal() {
  createShipmentModalOpen.value = false;
}

const addressLine = computed<null | string>(() => {
  if (props.selectedReturnAddress === null) {
    return null;
  }

  return `${props.selectedReturnAddress.addressLine.trim()}, ${props.selectedReturnAddress.country.isoCode}`;
});
</script>

<template>
  <Card>
    <CardSection>
      <div class="-mx-6 flex flex-col">
        <div
          v-if="selectedReturnAddress?.rawMapImageSrc"
          class="relative -mt-6 mb-2 w-full"
        >
          <img
            class="-mt-6 w-full"
            :src="selectedReturnAddress.rawMapImageSrc"
          >
          <div class="absolute bottom-0 left-0 h-10 w-full bg-gradient-to-b from-transparent to-white to-60%" />
        </div>

        <div
          v-if="selectedReturnAddress"
          class="mx-6 mb-10 flex flex-col"
        >
          <Heading class="mb-4">
            {{ $t('panel.returns:detail:shipments:return-address') }}
          </Heading>
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ selectedReturnAddress.name }}
          </TextStyle>
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ addressLine }}
          </TextStyle>
        </div>

        <Heading class="mx-6">
          {{ $t('panel.returns:detail:shipments') }}
        </Heading>

        <div class="-mb-4">
          <TextContainer
            v-if="returnOrder.shipments.length === 0 && returnOrder.externalShipments.length === 0"
            text-center
            class="py-6"
          >
            {{ $t('panel.returns:detail:shipments:no-shipments') }}
          </TextContainer>

          <Listbox v-if="returnOrder.shipments.length > 0">
            <ListboxOption
              v-for="shipment in returnOrder.shipments"
              :key="shipment.id"
              @click="setCurrentShipmentId(shipment.id)"
            >
              <ShipmentItem :shipment="shipment" />
            </ListboxOption>
          </Listbox>

          <Listbox v-if="returnOrder.externalShipments.length > 0">
            <ListboxOption
              v-for="externalShipment in returnOrder.externalShipments"
              :key="externalShipment.id"
            >
              <ExternalShipmentItem :external-shipment="externalShipment" />
            </ListboxOption>
          </Listbox>

          <div class="mb-4 px-6">
            <Button
              full-width
              @click="showCreateShipmentModal"
            >
              {{ $t('panel.returns:detail:shipments:create') }}
            </Button>
          </div>
        </div>
      </div>
    </CardSection>
  </Card>

  <ShipmentModal
    :open="isCurrentShipmentSet"
    :current-shipment="currentShipment"
    @on-clear="clearCurrentShipment"
  />

  <CreateShipmentModal
    :open="createShipmentModalOpen"
    :form-shipping-methods="formShippingMethods"
    :return-order="returnOrder"
    @close="closeCreateShipmentModal"
  />
</template>
