<script lang="ts" setup>
import { computed, PropType, ref, toRef } from 'vue';
import {
  AttachmentItem,
  AttachmentList,
  Badge,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  Heading,
  HeadingElement,
  Link,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/panel/Components';
import { Stepper } from '@returnless/focus-ui';
import { ArrowTrendingDownIcon, CheckIcon } from '@heroicons/vue/24/outline';
import * as Types from '@app/panel/types/generated';
import { App } from '@app/shared/types/generated-v2';
import { InertiaForm, router } from '@inertiajs/vue3';
import { NoteSection } from '@app/panel/Containers';
import { debounce, isEmpty } from 'lodash';
import DepreciationModal from './DepreciationModal.vue';
import { useModal } from '@app/panel/Composables/useModal';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  item: {
    type: Object as PropType<Types.App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderItemViewModel>,
    required: true,
  },

  order: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnOrderViewModel>,
    required: true,
  },

  noteTemplates: {
    type: Object as PropType<Types.App.Models.ViewModels.NoteTemplateViewModel>,
    required: true,
  },
});

const { t } = useI18n();
const notesVisible = ref(false);
const executingAction = ref(false);

const {
  isOpen: isDepreciationModalOpen,
  close: closeDepreciationModal,
  open: openDepreciationModal,
} = useModal();

const reactiveItem = toRef(props, 'item');
const updateValueChange = debounce(function (attribute, value) {
  router.put(route('panel.return-items.update', props.item), {
    [attribute]: value,
  }, {
    preserveState: true,
    preserveScroll: true,
    onStart: () => executingAction.value = true,
    onFinish: () => executingAction.value = false,
  });
}, 500);

function changeBackInStock(newValue) {
  if (newValue > props.item.quantityReceived) {
    reactiveItem.value['quantityBackInStock'] = props.item.quantityReceived;
  } else {
    reactiveItem.value['quantityBackInStock'] = newValue;
  }

  updateValueChange('quantity_back_in_stock', newValue);
}

function changeQuantityReceived(newValue, $item: Types.App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderItemViewModel) {
  if (newValue > Math.max($item.quantity, $item.salesOrderItem?.quantity)) {
    reactiveItem.value['quantityReceived'] = Math.max($item.quantity, $item.salesOrderItem?.quantity);
  } else {
    reactiveItem.value['quantityReceived'] = newValue;
  }
  updateValueChange('quantity_received', reactiveItem.value['quantityReceived']);
}

function createNote(form: InertiaForm<object>) {
  form.post(route('panel.return-items.create-note', props.item), {
    preserveState: true,
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
      executingAction.value = false;
    },
  });
}

const markAsReceivedLoading = ref<boolean>(false);

function markAsReceived(): void {
  router.put(route('panel.return-items.mark-as-received', props.item), {}, {
    preserveScroll: true,
    onStart: () => markAsReceivedLoading.value = true,
    onFinish: () => markAsReceivedLoading.value = false,
  });
}

const isReceived = computed(() => {
  return reactiveItem.value.quantityReceived >= props.item.quantity;
});

const isBackInStock = computed(() => {
  return reactiveItem.value.quantityBackInStock === props.item.quantity;
});

const hasDiscount = computed(() => {
  return props.item.salesOrderItem.discount.amount > 0;
});
</script>

<template>
  <div class="flex space-x-6">
    <div class="shrink-0 basis-48">
      <img
        v-if="item.product.imageSource"
        v-fallback-img
        class="mx-auto h-auto w-48 max-w-full rounded-md object-contain"
        :src="item.product.imageSource"
        :alt="item.product.name"
        loading="lazy"
      >
    </div>
    <div class="flex-1 space-y-4">
      <div class="-mb-3 flex items-start justify-between space-x-2">
        <div class="space-y-2">
          <Heading
            :element="HeadingElement.H3"
          >
            <a
              class="hover:underline"
              :href="$route('panel.analytics.products.show', { product: item.product.id })"
            >
              {{ item.salesOrderItem.productName ? item.salesOrderItem.productName : item.product.name }}
            </a>
          </Heading>
          <div
            v-if="! isEmpty(item.salesOrderItem.extensionAttributes)"
            class="flex flex-wrap items-center gap-2 text-sm text-slate-500"
          >
            <template
              v-for="(attribute, index) in item.salesOrderItem.extensionAttributes"
              :key="index"
            >
              <Badge color="slate">
                <span>{{ attribute.option }}: </span>
                <span>{{ attribute.value }}</span>
              </Badge>
            </template>
          </div>
        </div>
        <div class="flex flex-col items-end text-right">
          <TextStyle
            :font-size="TextStyleFontSize.Small"
            :shade="TextStyleShade.Pale"
            no-wrap
          >
            {{ t('panel.containers:return-item:return-qty') }}
          </TextStyle>
          <span class="text-2xl font-bold tracking-normal text-slate-700">
            {{ item.quantity }} / {{ item.salesOrderItem.quantity }}
          </span>
        </div>
      </div>

      <div class="flex justify-between space-x-4">
        <div class="w-full flex-1">
          <Breadcrumbs>
            <Breadcrumbs>
              <BreadcrumbsItem
                v-if="item.product.sku"
                :name="t('panel.containers:return-item:sku')"
                :value="item.product.sku"
              />
              <BreadcrumbsItem
                v-if="item.product.barcode"
                :name="t('panel.containers:return-item:barcode')"
                :value="item.product.barcode"
              />
              <BreadcrumbsItem
                v-if="item.product.brand"
                :name="t('panel.containers:return-item:brand')"
                :value="item.product.brand"
              />
            </Breadcrumbs>
            <Breadcrumbs>
              <BreadcrumbsItem>
                <span class="space-x-1">
                  <span class="font-semibold">{{ t('panel.containers:return-item:price') }}:</span>
                  <span>{{ item.salesOrderItem.price.label }}</span>
                  <Badge
                    v-if="hasDiscount"
                    color="green"
                  >
                    {{ t('panel.containers:return_order:discount', {discount: item.salesOrderItem.discount.label}) }}
                  </Badge>
                </span>
              </BreadcrumbsItem>
            </Breadcrumbs>
          </Breadcrumbs>
        </div>
        <div v-if="item.returnType">
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ item.returnType }}
          </TextStyle>
        </div>
      </div>

      <div class="flex justify-between space-x-4">
        <div class="flex flex-col">
          <div class="flex flex-col">
            <TextStyle
              :shade="TextStyleShade.Dense"
              strong
            >
              {{ t('panel.containers.return_order.return_reason') }}
            </TextStyle>
            <TextStyle :shade="TextStyleShade.Subdued">
              {{ item.returnReason }}
            </TextStyle>
          </div>
          <div
            v-if="item.answers.length"
            class="-mb-0.5"
          >
            <div
              v-for="answer in item.answers"
              :key="answer.id"
              class="py-2 text-sm"
            >
              <TextStyle
                strong
                :shade="TextStyleShade.Dense"
                full-width
              >
                {{ answer.question }}
              </TextStyle>

              <p class="text-slate-500">
                <Link
                  v-if="answer.answer && answer.answerIsUrl"
                  :href="answer.answer"
                  target="_blank"
                  native
                  class="block max-w-lg overflow-hidden truncate"
                >
                  {{ answer.answer }}
                </Link>
                <span v-if="answer.answer && ! answer.answerIsUrl">{{ answer.answer }}</span>
                <span v-if="answer.option">{{ answer.option.answer }}</span>

                <AttachmentList v-if="! isEmpty(answer.attachments)">
                  <AttachmentItem
                    v-for="attachment in answer.attachments"
                    :key="attachment.id"
                  >
                    {{ attachment.filename }}

                    <template #actions>
                      <Link
                        native
                        :href="attachment.path"
                        target="_blank"
                      >
                        {{ t('panel.containers.return_order.view') }}
                      </Link>
                    </template>
                  </AttachmentItem>
                </AttachmentList>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap gap-4">
        <div class="text-center">
          <Stepper
            :model-value="reactiveItem.quantityReceived"
            :min="0"
            :max="Math.max(item.quantity, item.salesOrderItem?.quantity)"
            :label="t('panel.containers.return_order.qty_received')"
            :color="isReceived ? App.Enums.ColorPalette.Green : App.Enums.ColorPalette.Slate"
            :disabled="executingAction"
            @update:model-value="(value) => changeQuantityReceived(value, item)"
          />
        </div>

        <div class="text-center">
          <Stepper
            :model-value="reactiveItem.quantityBackInStock"
            :min="0"
            :max="item.quantityReceived"
            :label="t('panel.containers.return_order.qty_stock')"
            :color="isBackInStock ? App.Enums.ColorPalette.Green : App.Enums.ColorPalette.Slate"
            :disabled="executingAction"
            @update:model-value="changeBackInStock"
          />
        </div>

        <div class="flex flex-col gap-y-4">
          <div>
            <Button
              :disabled="isReceived"
              :loading="markAsReceivedLoading"
              @click="markAsReceived"
            >
              <template #icon>
                <CheckIcon class="mr-2 size-5 text-green-500" />
              </template>
              <span class="whitespace-nowrap">{{ t('panel.containers.return_order.mark_received') }}</span>
            </Button>
          </div>

          <div class="text-center">
            <Button @click="openDepreciationModal">
              <template #icon>
                <ArrowTrendingDownIcon class="size-5" />
              </template>

              {{ t('panel.containers.return_order.add_depreciation') }}
            </Button>

            <TextStyle
              v-if="item.rawTotalDepreciation > 0"
              class="mt-1 block"
              :shade="TextStyleShade.Pale"
              :font-size="TextStyleFontSize.Small"
            >
              <span class="text-orange-600">
                {{ item.totalDepreciation.label }} {{ t('panel.containers.return_order.depreciation') }}
              </span>
            </TextStyle>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <label class="flex cursor-pointer items-center">
          <Toggle
            v-model="notesVisible"
          />

          <TextStyle :shade="TextStyleShade.Dense">
            <span>{{ t('panel.containers.return_order.show_notes') }}</span>
            <TextStyle :shade="TextStyleShade.Subdued">
              ({{ item.notes?.length ?? 0 }})
            </TextStyle>
          </TextStyle>
        </label>
      </div>

      <div v-if="notesVisible">
        <NoteSection
          :note-templates="noteTemplates"
          :return-order="order"
          :notes="item.notes"
          @submit="createNote"
        />
      </div>
    </div>
  </div>

  <DepreciationModal
    :open="isDepreciationModalOpen"
    :item="item"
    @close="closeDepreciationModal"
  />
</template>
