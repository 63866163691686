<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Form,
  FormLayout,
  InputLabel,
  Modal,
  ModalSize,
  Select,
  SelectOption,
  TextInput,
  TextInputFieldTypes,
  Toggle,
} from '@app/panel/Components';
import { CubeIcon } from '@heroicons/vue/24/outline';
import * as Types from '@app/panel/types/generated';
import { useForm } from '@inertiajs/vue3';
import { computed, inject, watch } from 'vue';
import { isNull } from 'lodash';
import { App } from '@app/panel/types/generated';

type ShippingMethodViewModel = Types.App.Models.ViewModels.ShippingMethodViewModel;
type CountryViewModel = Types.App.Models.ViewModels.CountryViewModel;
type CarrierViewModel = App.Models.ViewModels.CarrierViewModel;
type ShippingIntegrationViewModel = App.Models.ViewModels.ShippingIntegrationViewModel;

const props = defineProps<{
  current?: ShippingMethodViewModel;
}>();

const emit = defineEmits<{ (e: 'close') }>();

const countries = inject<CountryViewModel[]>('countries');
const carriers = inject<CarrierViewModel[]>('carriers');
const shippingIntegrations = inject<ShippingIntegrationViewModel[]>('shippingIntegrations');

const form = useForm({
  name: '',
  carrier_id: '',
  shipping_integration_id: '',
  custom_integration_url: '',
  from_country_id: '',
  to_country_id: '',
  min_weight: 0,
  max_weight: 0,
  max_width: 0,
  max_height: 0,
  max_length: 0,
  type: '',
  is_printerless: false,
});

const isOpen = computed(() => {
  return !isNull(props.current);
});

let customIntegration = null;

const carrierOptions = computed<SelectOption[]>(() => {
  return carriers.map((carrier) => {
    return {
      label: carrier.name,
      value: carrier.id,
    };
  });
});

const countryOptions = computed<SelectOption[]>(() => {
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

const integrationOptions = computed<SelectOption[]>(() => {
  return shippingIntegrations.map((shippingIntegration) => {
    if (shippingIntegration.name === 'Custom') {
      customIntegration = shippingIntegration;
    }

    return {
      label: shippingIntegration.name,
      value: shippingIntegration.id,
    };
  });
});

function handleClose(): void {
  emit('close');
}

function update(): void {
  form.put(route('panel.settings.general.shipping-methods.update', props.current.id), {
    onSuccess: () => handleClose(),
  });
}

const typeOptions: SelectOption[] = [
  {
    label: 'Pick up',
    value: 'pick-up',
  },

  {
    label: 'Drop off',
    value: 'drop-off',
  },
];

watch(() => props.current, (shippingMethod: ShippingMethodViewModel) => {
  if (shippingMethod) {
    form.name = shippingMethod.name;
    form.shipping_integration_id = shippingMethod.shippingIntegration.id;
    form.carrier_id = shippingMethod.carrier.id;
    form.custom_integration_url = shippingMethod.customIntegrationUrl;
    form.from_country_id = shippingMethod.fromCountry.id;
    form.to_country_id = shippingMethod.toCountry.id;
    form.min_weight = shippingMethod.fromWeightRaw;
    form.max_weight = shippingMethod.toWeightRaw;
    form.max_width = shippingMethod.maxWidthRaw;
    form.max_height = shippingMethod.maxHeightRaw;
    form.max_length = shippingMethod.maxLengthRaw;
    form.type = shippingMethod.type;
    form.is_printerless = shippingMethod.isPrinterless;

    form.defaults();
  }
}, { immediate: true });
</script>

<template>
  <Modal
    :open="isOpen"
    :title="$t('panel.settings:general:shipping-methods:edit-shipping-method')"
    icon-color="blue"
    :size="ModalSize.Large"
    @close="handleClose"
  >
    <template #icon>
      <CubeIcon class="size-6" />
    </template>

    <Form>
      <FormLayout>
        <TextInput
          v-model="form.name"
          :error="form.errors.name"
          :label="$t('panel.settings:general:shipping-methods:name')"
        />
      </FormLayout>

      <Divider />

      <FormLayout>
        <FormLayout>
          <Select
            v-model="form.carrier_id"
            :options="carrierOptions"
            :error="form.errors.carrier_id"
            :label="$t('panel.settings:general:shipping-methods:carrier')"
          />
        </FormLayout>

        <FormLayout>
          <Select
            v-model="form.shipping_integration_id"
            :options="integrationOptions"
            :error="form.errors.shipping_integration_id"
            :label="$t('panel.settings:general:shipping-methods:integration')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-if="form.shipping_integration_id === customIntegration.id"
            v-model="form.custom_integration_url"
            :error="form.errors.custom_integration_url"
            :label="$t('panel.settings:general:shipping-methods:request-url')"
            placeholder="https://www.travelshop.com/api/customshippingmethod/"
          />
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <Select
              v-model="form.from_country_id"
              :options="countryOptions"
              :error="form.errors.from_country_id"
              :label="$t('panel.settings:general:shipping-methods:from-country')"
            />
          </FormLayout>

          <FormLayout>
            <Select
              v-model="form.to_country_id"
              :options="countryOptions"
              :error="form.errors.to_country_id"
              :label="$t('panel.settings:general:shipping-methods:to-country')"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <TextInput
              v-model="form.min_weight"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.min_weight"
              :label="$t('panel.settings:general:shipping-methods:minimal-weight')"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.max_weight"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_weight"
              :label="$t('panel.settings:general:shipping-methods:maximum-weight')"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="3"
        >
          <FormLayout>
            <TextInput
              v-model="form.max_width"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_width"
              :label="$t('panel.settings:general:shipping-methods:maximum-width')"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.max_height"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_height"
              :label="$t('panel.settings:general:shipping-methods:maximum-height')"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.max_length"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_length"
              :label="$t('panel.settings:general:shipping-methods:maximum-length')"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <Select
              v-model="form.type"
              :options="typeOptions"
              :error="form.errors.type"
              :label="$t('panel.settings:general:shipping-methods:type')"
            />
          </FormLayout>

          <FormLayout>
            <div>
              <InputLabel
                :label="$t('panel.settings:general:shipping-methods:printerless:title')"
              />

              <Toggle
                v-model="form.is_printerless"
                :error="form.errors.is_printerless"
              />
            </div>
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <ButtonGroup full-width>
        <Button full-width>
          {{ $t('panel.settings:general:shipping-methods:verify-request-url') }}
        </Button>

        <Button
          :loading="form.processing"
          :disabled="! form.isDirty"
          full-width
          :variant="ButtonVariant.Primary"
          @click="update"
        >
          {{ $t('panel.settings:general:shipping-methods:save-shipping-method') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
