<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';

type PersonalAccessTokenViewModel = Types.App.Models.ViewModels.PersonalAccessTokenViewModel;

const props = defineProps<{
  open: boolean;
  current: PersonalAccessTokenViewModel;
}>();

const emit = defineEmits<{ (e: 'close'): void }>();

function handleClose(): void {
  emit('close');
}

function revoke(): void {
  router.delete(route('panel.settings.integrations.api-keys.revoke', {
    token: props.current,
  }), {
    preserveState: false,
  });
}
</script>

<template>
  <Modal
    :open="open"
    icon-color="red"
    :size="ModalSize.Default"
    :title="$t('panel.settings:integrations:api-keys:revoke-action')"
    @close="handleClose"
  >
    <template #icon>
      <ExclamationTriangleIcon class="h-6 w-6" />
    </template>

    <TextContainer :spacing="TextContainerSpacing.Loose">
      <TextStyle :shade="TextStyleShade.Subdued">
        <p>
          {{ $t('panel.settings:integrations:api-keys:irreversible-action') }}
        </p>
      </TextStyle>
    </TextContainer>

    <template #actions>
      <Button @click="handleClose">
        {{ $t('panel.settings:integrations:api-keys:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="revoke"
      >
        {{ $t('panel.settings:integrations:api-keys:revoke-api-key') }}
      </Button>
    </template>
  </Modal>
</template>
