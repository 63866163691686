<script lang="ts" setup>
import {
  Select,
  type SelectOption,
  Tabs,
  TabsItem,
  TextInput,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import type { FormStructure } from '../types';
import { InertiaForm } from '@inertiajs/vue3';
import { entries, first } from 'lodash';
import { inject, ref } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';

type ReturnReasonViewModel = Types['App.Models.ViewModels.ReturnReasonViewModel'];
type ReturnStatusViewModel = Types['App.Models.ViewModels.ReturnStatusViewModel'];
type PlatformIntegrationTenantViewModel = Types['App.Models.ViewModels.PlatformIntegrationTenantViewModel'];

const props = withDefaults(defineProps<{
  primaryName?: string;
  secondaryName: string;
  credentials: object;
  platformIntegrationTenant: PlatformIntegrationTenantViewModel;
  returnReasons: ReturnReasonViewModel[];
  returnStatuses: ReturnStatusViewModel[];
  section: FormStructure;
}>(), {
  primaryName: 'Returnless',
});

const form = inject<InertiaForm<{
  return_statuses_map: Record<string, string>;
  return_reasons_map: Record<string, string>;
}>>('form');

const activeMappingTab = ref<string | null>(
  first(props.section.fields).key,
);

function setActiveMappingTab(tab: string): void {
  activeMappingTab.value = tab;
}

function getMappingModelValue(tab: string, key: string) {
  return form[tab][key];
}

function setMappingModelValue(tab: string, key: string, value: string) {
  form[tab][key] = value;
}

function getSelectOptions(data: Record<string, string>): SelectOption[] {
  return entries(data).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });
}
</script>

<template>
  <Tabs>
    <TabsItem
      v-for="tab in section.fields"
      :key="tab.key"
      type="button"
      :active="activeMappingTab === tab.key"
      @click="setActiveMappingTab(tab.key)"
    >
      {{ tab.label }}
    </TabsItem>
  </Tabs>

  <section class="w-full">
    <template
      v-for="tab in section.fields"
      :key="tab.key"
    >
      <div
        v-if="activeMappingTab === tab.key"
        class="mt-6 flex flex-col space-y-2"
      >
        <div class="w-8/12 mx-auto grid grid-cols-1 gap-2">
          <div class="grid grid-cols-2 mb-2">
            <TextStyle
              text-center
              :shade="TextStyleShade.Dense"
            >
              {{ tab.flip_column_name === true ? secondaryName : primaryName }}
            </TextStyle>
            <TextStyle
              text-center
              :shade="TextStyleShade.Dense"
            >
              {{ tab.flip_column_name === true ? primaryName : secondaryName }}
            </TextStyle>
          </div>
          <div
            v-for="(internalValue, key) in tab.options"
            :key="key"
            class="flex space-x-2"
          >
            <TextInput
              :value="internalValue"
              disabled
            />

            <Select
              :options="getSelectOptions(tab.secondary_options)"
              :model-value="getMappingModelValue(tab.key, key)"
              @update:model-value="(value) => setMappingModelValue(tab.key, key, value)"
            />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>
