import { Component, Ref } from 'vue';
import OrderLine from './blocks/OrderLine.vue';
import ReturnOrderLine from './blocks/ReturnOrderLine.vue';
import Card from './blocks/Card.vue';
import ValueLabel from './blocks/ValueLabel.vue';
import ValueInputLabel from './blocks/ValueInputLabel.vue';
import ValueCheckboxInputLabel from './blocks/ValueCheckboxInputLabel.vue';
import ValueCheckboxLabel from './blocks/ValueCheckboxLabel.vue';

export function resolveComponent(component: string): Component {
  const componentMap = {
    orderLines: OrderLine,
    returnOrderLines: ReturnOrderLine,
    card: Card,
    valueLabel: ValueLabel,
    valueInputLabel: ValueInputLabel,
    valueCheckboxInputLabel: ValueCheckboxInputLabel,
    valueCheckboxLabel: ValueCheckboxLabel,
  };

  return componentMap[component];
}

export function isComputedValue(value: string) {
  return value.startsWith('@computed::');
}

export function getComputedValue(computedValues: Ref<object>, value: string) {
  const parsedValue = value.replace('@computed::', '');

  return computedValues.value[parsedValue];
}
