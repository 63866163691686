<script lang="ts" setup>
import { PropType } from 'vue';
import OrderLine from './blocks/OrderLine.vue';
import ResolutionType from './blocks/ResolutionType.vue';
import ReturnOrderLine from './blocks/ReturnOrderLine.vue';
import Card from './blocks/Card.vue';
import ValueLabel from './blocks/ValueLabel.vue';
import ValueInputLabel from './blocks/ValueInputLabel.vue';
import ValueCheckboxInputLabel from './blocks/ValueCheckboxInputLabel.vue';
import ValueCheckboxLabel from './blocks/ValueCheckboxLabel.vue';
import SwitchLabel from './blocks/SwitchLabel.vue';
import SwitchLabelWithComment from './blocks/SwitchLabelWithComment.vue';
import { TraitBlock } from './types';
import BannerBlock from '@app/panel/Pages/Returns/partials/Trait/blocks/BannerBlock.vue';

defineProps({
  children: {
    type: Array as PropType<TraitBlock[]>,
    default: () => ([]),
  },
});

defineEmits(['submit']);

function resolveComponent(component: string) {
  return {
    orderLines: OrderLine,
    resolutionType: ResolutionType,
    returnOrderLines: ReturnOrderLine,
    card: Card,
    valueLabel: ValueLabel,
    valueInputLabel: ValueInputLabel,
    valueCheckboxInputLabel: ValueCheckboxInputLabel,
    valueCheckboxLabel: ValueCheckboxLabel,
    switchLabel: SwitchLabel,
    switchLabelWithComment: SwitchLabelWithComment,
    banner: BannerBlock,
  }[component];
}
</script>

<template>
  <template
    v-for="(child, childIndex) in children"
    :key="childIndex"
  >
    <component
      :is="resolveComponent(child.type)"
      :children="child.children"
      v-bind="{ ...child.properties }"
    />
  </template>
</template>
