<script lang="ts" setup>
import {
  computed,
  PropType,
  provide,
  ref,
} from 'vue';
import {
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  DataTable,
  Form,
  FormLayout,
  Modal,
  ModalSize,
  Page,
  Select,
  Stack,
  StackItem,
  TextInput,
} from '@app/panel/Components';
import { PasswordConfirmation } from '@app/panel/Containers';
import { AppLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { PageNavigation } from './partials';
import { PlusIcon } from '@heroicons/vue/24/outline';
import * as Types from '@app/panel/types/generated';
import { router } from '@inertiajs/vue3';

const props = defineProps({
  form: {
    type: Object as PropType<Types.App.Models.ViewModels.FormViewModel>,
    required: true,
  },

  domains: {
    type: Array as PropType<Types.App.Models.ViewModels.DomainViewModel[]>,
    required: true,
  },

  locales: {
    type: Array as PropType<Types.App.Models.ViewModels.FormLocaleViewModel[]>,
    default: () => ([]),
  },
});

provide('form', props.form);

const editingItem = ref(null);

function editItem(item): void {
  editingItem.value = item;
  editDomainForm.domain = item.domain.split('.')[0];
}

function stopEditing(): void {
  editingItem.value = null;
}

const addDomainForm = useForm({
  domain: '',
});

const editDomainForm = useForm({
  domain: null,
});

function submitDomainForm(): void {
  addDomainForm.post(route('panel.settings.forms.domains.store', props.form), {
    onSuccess: () => addDomainForm.reset(),
  });
}

function updateDomainForm(): void {
  editDomainForm.put(route('panel.settings.forms.domains.update', {
    form: props.form,
    domain: editingItem.value.id,
  }), { onSuccess: () => stopEditing() });
}

const domainLocaleForm = useForm({
  locales: props.locales.reduce((acc, locale) => {
    acc[locale.id] = locale.domain ? locale.domain.id : false;
    return acc;
  }, {}),
});

const domainOptions = computed(() => {
  return props.domains.map((domain) => {
    return { value: domain.id, label: domain.domain };
  });
});

function submitLocaleDomains() {
  domainLocaleForm.put(route('panel.settings.forms.domain-locales.update', props.form));
}

function deleteDomain(item) {
  router.delete(route('panel.settings.forms.domains.delete', {
    form: props.form,
    domain: item.id,
  }));
}
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.head_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Stack vertical>
        <StackItem>
          <Card
            :title="$t('panel.forms:domains:title')"
            :subtitle="$t('panel.forms:domains:subtitle')"
          >
            <CardSection>
              <Form @submit="submitDomainForm">
                <div class="flex flex-col sm:flex-row">
                  <div class="flex-1">
                    <TextInput
                      v-model="addDomainForm.domain"
                      :label="$t('panel.forms:domains:add-input-label')"
                      :error="addDomainForm.errors.domain"
                    >
                      <template #addon>
                        .returnless.com
                      </template>
                    </TextInput>
                  </div>

                  <div class="mt-6 sm:ml-4">
                    <Button
                      :variant="ButtonVariant.Primary"
                      :type="ButtonType.Submit"
                      full-width
                    >
                      <template #icon>
                        <PlusIcon />
                      </template>

                      {{ $t('panel.forms:domains:add-button') }}
                    </Button>
                  </div>
                </div>
              </Form>
            </CardSection>

            <CardSection>
              <DataTable
                :headings="[{
                  text: $t('panel.forms:domains:table-heading'),
                  value: 'domain',
                }]"
                :items="domains"
              >
                <template #actions="{ item }">
                  <ButtonGroup>
                    <Button
                      plain
                      :variant="ButtonVariant.Primary"
                      @click="editItem(item)"
                    >
                      {{ $t('panel.forms:domains:table-action-edit') }}
                    </Button>
                    <PasswordConfirmation @confirmed="deleteDomain(item)">
                      <Button
                        plain
                        :variant="ButtonVariant.Critical"
                      >
                        {{ $t('panel.forms:domains:table-action-delete') }}
                      </Button>
                    </PasswordConfirmation>
                  </ButtonGroup>
                </template>
              </DataTable>
            </CardSection>
          </Card>
        </StackItem>

        <StackItem>
          <Form @submit="submitLocaleDomains">
            <Card
              :primary-footer-action="{
                content: $t('panel.forms:domains:domain-select-button'),
                type: ButtonType.Submit,
                loading: domainLocaleForm.processing,
                disabled: ! domainLocaleForm.isDirty,
              }"
            >
              <CardSection>
                <FormLayout>
                  <div
                    v-for="locale in locales"
                    :key="locale.id"
                  >
                    <Select
                      v-model="domainLocaleForm.locales[locale.id]"
                      :label="locale.locale.name"
                      :options="domainOptions"
                    />
                  </div>
                </FormLayout>
              </CardSection>
            </Card>
          </Form>
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>

  <Modal
    :open="editingItem !== null"
    :title="$t('panel.forms:domains:edit-modal:title')"
    :size="ModalSize.Small"
    @close="stopEditing"
  >
    <Form
      id="updateDomainForm"
      @submit="updateDomainForm"
    >
      <TextInput
        v-model="editDomainForm.domain"
        :error="editDomainForm.errors.domain"
      >
        <template #addon>
          .returnless.com
        </template>
      </TextInput>
    </Form>

    <template #actions>
      <Button @click="stopEditing">
        {{ $t('panel.forms:domains:edit-modal:cancel') }}
      </Button>

      <Button
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="updateDomainForm"
        :disabled="! editDomainForm.isDirty"
      >
        {{ $t('panel.forms:domains:edit-modal:save') }}
      </Button>
    </template>
  </Modal>
</template>
