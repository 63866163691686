<script lang="ts" setup>
import {
  ButtonType,
  CardSection,
  Form,
  FormLayout,
  Select, SelectOption,
  Stack,
  StackItem,
  StackSpacing,
  TextInput, TextInputFieldTypes,
  TextInputIconPosition,
  TextStyle,
} from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type FormShippingSettingsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel;

const props = defineProps<{
  form: FormShippingSettingsViewModel['form'];
  returnInstructions: FormShippingSettingsViewModel['returnInstructions'];
}>();

const dynamicReturnCostsForm = useForm({
  dynamic_return_costs_per_item: props.form.dynamicReturnCostsPerItem ?? 0,
  dynamic_return_costs_return_instruction_id: props.form.dynamicReturnCostReturnInstructionId,
  dynamic_return_costs_start_from: props.form.dynamicReturnCostsStartFrom ?? 1,
});

const returnInstructionOptions = computed<SelectOption[]>(() => {
  return props.returnInstructions.map((instruction) => {
    return {
      value: instruction.id,
      label: instruction.name,
    };
  });
});

function saveMultiCollo() {
  dynamicReturnCostsForm.put(route('panel.settings.forms.shipping-settings.dynamic-return-costs.update', { form: props.form }));
}
</script>

<template>
  <Form @submit="saveMultiCollo">
    <Stack
      vertical
      :spacing="StackSpacing.ExtraLoose"
    >
      <StackItem>
        <Card>
          <CardHeader>
            <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.dynamic-return-cost-settings')">
              {{ $t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:title') }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <FormLayout>
                <TextInput
                  v-model="dynamicReturnCostsForm.dynamic_return_costs_per_item"
                  :icon-position="TextInputIconPosition.Left"
                  :label="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:cost-per-item')"
                  :help-text="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:cost-per-item:help-text')"
                  :type="TextInputFieldTypes.Number"
                  step="0.01"
                  min="0"
                  :error="dynamicReturnCostsForm.errors.dynamic_return_costs_per_item"
                  allow-decimal
                >
                  <template #icon>
                    <TextStyle>&euro;</TextStyle>
                  </template>
                </TextInput>
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="dynamicReturnCostsForm.dynamic_return_costs_start_from"
                  :label="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:cost-start-from')"
                  :help-text="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:cost-start-from:help-text')"
                  :type="TextInputFieldTypes.Number"
                  step="1"
                  min="1"
                  :error="dynamicReturnCostsForm.errors.dynamic_return_costs_start_from"
                  allow-decimal
                />
              </FormLayout>

              <FormLayout>
                <Select
                  v-model="dynamicReturnCostsForm.dynamic_return_costs_return_instruction_id"
                  :label="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:return-instruction')"
                  :help-text="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:return-instruction:help-text')"
                  :error="dynamicReturnCostsForm.errors.dynamic_return_costs_return_instruction_id"
                  :nullable-label="$t('panel.settings:forms:shipping-settings:dynamic-return-costs-settings:return-instruction:nullable-label')"
                  :options="returnInstructionOptions"
                />
              </FormLayout>
            </FormLayout>
          </CardSection>
          <CardFooter>
            <Button
              type="submit"
              :loading="dynamicReturnCostsForm.processing"
              :disabled="!dynamicReturnCostsForm.isDirty"
            >
              {{ $t('panel.global:actions:save') }}
            </Button>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  </Form>
</template>
