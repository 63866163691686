<script setup lang="ts">
import { App, Types } from '@app/shared/types/generated-v2';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { Banner, BannerVariant, Button, List, ListItem, Select, TextInput } from '@app/panel/Components';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { getRandomId } from '@app/panel/Utils';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type ActionViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.Settings.ActionViewModel'];
type ActionFieldType = App.Enums.ActionFieldType | `${App.Enums.ActionFieldType}`;
type ActionNumberFieldViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.Settings.NumberActionFieldViewModel'];
type ActionSelectFieldViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.Settings.SelectActionFieldViewModel'];
type AbstractFieldViewModel = ActionNumberFieldViewModel | ActionSelectFieldViewModel;

const props = defineProps<{
  actions: ActionViewModel[];
  errors: Record<string, string>;
}>();

const actionItems = defineModel<ActionItemType[]>({ required: true });

type ActionItemType = {
  id: string;
  type: ActionViewModel['type'] | null;
  parameters: any;
};

function addActionItem(): void {
  actionItems.value.push({
    id: getRandomId(),
    type: null,
    parameters: {},
  });
}

function removeActionItem(id: string): void {
  actionItems.value = actionItems.value.filter((item): boolean => item.id !== id);
}

function getFieldsFromActionType(actionType: string | null): AbstractFieldViewModel[] {
  if (actionType === null) {
    return [];
  }

  return props.actions.find((action): boolean => action.type === actionType)?.fields ?? [];
}

function isField<T extends AbstractFieldViewModel>(field: AbstractFieldViewModel, type: ActionFieldType): field is T {
  return field.type === type;
}

function getAvailableActionsForRow(actionItemRow: ActionItemType): { label: string; value: string }[] {
  return props.actions
    .filter(function (action: ActionViewModel): boolean {
      // Actions that are not unique should not even be filtered
      if (!action.isUnique) {
        return true;
      }

      // If the current item already has selected the action then allow it
      if (action.type === actionItemRow.type) {
        return true;
      }

      const isActionAlreadySelected = actionItems.value.some((actionItem): boolean => actionItem.type === action.type);

      return !isActionAlreadySelected;
    })
    .map(function (action: ActionViewModel): { label: string; value: string } {
      return {
        label: action.label,
        value: action.type,
      };
    });
}
</script>

<template>
  <div class="flex flex-col space-y-4 items-start">
    <Banner
      v-if="props.errors['action_items'] ?? null"
      :variant="BannerVariant.Critical"
      class="w-full"
    >
      <List>
        <ListItem>{{ props.errors['action_items'] }}</ListItem>
      </List>
    </Banner>

    <div
      v-if="actionItems.length > 0"
      class="flex flex-col w-full gap-4"
    >
      <div
        v-for="actionItem in actionItems"
        :key="actionItem.id"
        class="flex items-center w-full gap-2"
      >
        <div
          class="w-full grid grid-cols-5 space-x-4"
        >
          <div
            :class="{
              'col-span-5': getFieldsFromActionType(actionItem.type).length === 0,
              'col-span-2': getFieldsFromActionType(actionItem.type).length > 0,
            }"
          >
            <Select
              v-model="actionItem.type"
              :options="getAvailableActionsForRow(actionItem)"
              :error="errors[`action_items.${actionItem.id}.type`]"
              @change="() => actionItem.parameters = {}"
            />
          </div>
          <div class="col-span-3 flex gap-3">
            <template
              v-for="(field, index) in getFieldsFromActionType(actionItem.type)"
              :key="index"
            >
              <TextInput
                v-if="isField<ActionNumberFieldViewModel>(field, 'number')"
                v-model="actionItem.parameters[field.name]"
                class="flex-1"
                type="number"
                :error="errors[`action_items.${actionItem.id}.parameters`]"
              />

              <Select
                v-if="isField<ActionSelectFieldViewModel>(field, 'select')"
                v-model="actionItem.parameters[field.name]"
                class="flex-1"
                :options="field.options"
                :disabled="field.options.length === 0"
                :error="errors[`action_items.${actionItem.id}.parameters`]"
              />
            </template>
          </div>
        </div>
        <div
          class="shrink-0"
        >
          <TrashIcon
            class="size-5 cursor-pointer text-slate-500 hover:text-slate-600"
            @click="removeActionItem(actionItem.id)"
          />
        </div>
      </div>
    </div>

    <Button
      @click="addActionItem"
    >
      <template #icon>
        <PlusIcon />
      </template>
      <span>{{ t('panel.settings:form:return-rules:create:action:add') }}</span>
    </Button>
  </div>
</template>
