<script lang="ts" setup>
import {
  Badge,
  Button,
  Card,
  CardSection,
  Listbox,
  ListboxOption,
  Modal,
  Stack,
  StackItem,
  StackSpacing,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { asset } from 'laravel-vapor';
import * as Types from '@app/panel/types/generated';
import { computed, ref } from 'vue';
import { isEmpty } from 'lodash';
import { router } from '@inertiajs/vue3';

type PaymentTransactionViewModel = Types.App.Models.ViewModels.PaymentTransactionViewModel;

const props = defineProps<{
  transactions: Array<PaymentTransactionViewModel>;
}>();

const isRefunding = ref(false);

const hasTransactions = computed<boolean>(() => {
  return !isEmpty(props.transactions);
});

const currentTransaction = ref<PaymentTransactionViewModel | null>(null);

function setCurrentTransaction(transaction: PaymentTransactionViewModel): void {
  currentTransaction.value = transaction;
}

function clearCurrentTransaction(): void {
  currentTransaction.value = null;
}

function refund() {
  router.post(route('panel.payment-transaction.refund', currentTransaction.value), {}, {
    preserveState: false,
    preserveScroll: true,
    onStart: () => isRefunding.value = true,
    onFinish: () => isRefunding.value = false,
  });
}
</script>

<template>
  <Card :title="$t('panel.returns:detail:transactions')">
    <div v-if="hasTransactions">
      <Listbox>
        <ListboxOption
          v-for="transaction in transactions"
          :key="transaction.id"
          @click="() => setCurrentTransaction(transaction)"
        >
          <div class="flex space-x-2">
            <img
              class="h-10 w-10"
              :alt="transaction.paymentMethod.name"
              :src="asset(`img/payment-methods/${transaction.paymentMethod.systemName}.svg`)"
              loading="lazy"
            >

            <TextContainer>
              <div>
                <TextStyle strong>
                  {{ transaction.id }}
                </TextStyle>
              </div>

              <TextStyle :shade="TextStyleShade.Pale">
                <p>
                  {{ $t('panel.returns:detail:transactions:amount') }} {{ transaction.amount }}
                  <span
                    v-if="! transaction.isEuroPayment"
                    class="italic"
                  >
                    ({{ transaction.amountCaptured }})
                  </span>
                </p>
              </TextStyle>

              <div
                v-if="transaction.error"
                class="mt-2"
              >
                <Badge color="red">
                  {{ transaction.error }}
                </Badge>
              </div>
            </TextContainer>
          </div>
        </ListboxOption>
      </Listbox>
    </div>

    <CardSection v-if="! hasTransactions">
      <TextContainer text-center>
        {{ $t('panel.returns:detail:transactions:no-transactions') }}
      </TextContainer>
    </CardSection>
  </Card>

  <Modal
    :open="currentTransaction !== null"
    :title="`Transaction ${currentTransaction?.id}`"
    @close="clearCurrentTransaction"
  >
    <template #icon>
      <img
        :src="asset(`img/payment-methods/${currentTransaction.paymentMethod.systemName}.svg`)"
        :alt="currentTransaction.paymentMethod.name"
        loading="lazy"
      >
    </template>

    <Stack
      v-if="currentTransaction !== null"
      vertical
      :spacing="StackSpacing.ExtraLoose"
    >
      <StackItem>
        <div
          class="grid gap-2 grid-cols-4"
        >
          <TextContainer>
            <TextStyle strong>
              <p>{{ $t('panel.returns:detail:transactions:method') }}</p>
            </TextStyle>

            <TextStyle>
              <p>{{ currentTransaction.paymentMethod.name }}</p>
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle strong>
              <p>{{ $t('panel.returns:detail:transactions:amount') }}</p>
            </TextStyle>

            <TextStyle>
              <p>
                {{ currentTransaction.amount }}
              </p>
              <p
                v-if="! currentTransaction.isEuroPayment"
                class="text-xs text-slate-600"
              >
                {{ currentTransaction.amountCaptured }}
              </p>
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle strong>
              <p>{{ $t('panel.returns:detail:transactions:cost') }}</p>
            </TextStyle>

            <TextStyle>
              <p>{{ currentTransaction.costPrice }}</p>
            </TextStyle>
          </TextContainer>

          <TextContainer>
            <TextStyle strong>
              <p>{{ $t('panel.returns:detail:transactions:status') }}</p>
            </TextStyle>

            <TextStyle>
              <p>{{ currentTransaction.status ?? currentTransaction.refundStatus }}</p>
            </TextStyle>
          </TextContainer>
        </div>
      </StackItem>
    </Stack>

    <template #actions>
      <Button @click="clearCurrentTransaction">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        v-if="currentTransaction.transactionType === 'payment'"
        :loading="isRefunding"
        variant="primary"
        :disabled="currentTransaction.isRefunded"
        @click="refund"
      >
        {{ $t('panel.returns:detail:transactions:refund-payment') }}
      </Button>
    </template>
  </Modal>
</template>
