<script setup lang="ts">
import {
  Badge,
  Button,
  ButtonVariant,
  InputLabel,
  Modal,
  ModalSize,
  Textarea,
  TextInput,
  TextInputFieldTypes,
  TextInputIconPosition,
  TextStyle,
  Toggle,
} from '@app/panel/Components';
import { App, Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import DraggableIcon from '@app/panel/Components/DraggableIcon/components/DraggableIcon.vue';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { computed, onMounted, watch } from 'vue';
import { round } from 'lodash';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { router } from '@inertiajs/vue3';
import NoteReplies from '@app/panel/Containers/NotesSection/components/NoteReplies.vue';
import { ParameterList } from '@app/panel/Containers';
import { getRandomId } from '@app/panel/Utils';

type ReturnOrderViewModel = Types['App.Models.ViewModels.ReturnOrderViewModel'];
type PaymentRequestViewModel = Types['App.Models.ViewModels.PaymentRequestViewModel'];
type PaymentRequestLineViewModel = Types['App.Models.ViewModels.PaymentRequestLineViewModel'];
type NoteTemplateViewModel = Types['App.Models.ViewModels.NoteTemplateViewModel'];
type CountryViewModel = Types['App.Models.ViewModels.CountryViewModel'];

const props = defineProps<{
  returnOrder: ReturnOrderViewModel;
  country: CountryViewModel;
  open: boolean;
  activePaymentRequest: null | PaymentRequestViewModel;
  noteTemplates: NoteTemplateViewModel[];
}>();

const emit = defineEmits<{
  close: [];
}>();

type PaymentLine = {
  id: string;
  sort_order: number;
  description: null | string;
  quantity: null | number;
  price: null | string;
  required: boolean;
};

const form = useForm({
  description: '',
  items: {} as Record<string, PaymentLine>,
});

function addRow() {
  const randomId = getRandomId();

  form.items[randomId] = {
    id: randomId,
    sort_order: Object.keys(form.items).length,
    description: null,
    quantity: null,
    price: null,
    required: false,
  };
}

function removeRow(id: string): void {
  delete form.items[id];
}

watch(() => props.activePaymentRequest, () => {
  form.description = props.activePaymentRequest?.description ?? '';

  form.items = {};

  props.activePaymentRequest?.paymentRequestLines.forEach((paymentRequestLine: PaymentRequestLineViewModel) => {
    form.items[paymentRequestLine.id] = {
      id: paymentRequestLine.id,
      sort_order: paymentRequestLine.sortOrder,
      description: paymentRequestLine.description,
      quantity: paymentRequestLine.quantity,
      price: round(paymentRequestLine.price / 100, 2).toFixed(2),
      required: paymentRequestLine.required,
    };
  });

  if (Object.keys(form.items).length === 0) {
    addRow();
  }
}, { immediate: true });

function close() {
  emit('close');
}

const country = computed(() => props.returnOrder.customer.addresses[0].country ?? props.country);
const currencySymbol = computed(() => country.value.currencySymbol);
const currencyCode = computed(() => country.value.currencyCode);
const vatPercentage = computed(() => country.value.vatPercentage);

function getPriceExclusiveVat(item: PaymentLine): string {
  const total = parseFloat(item.price ?? '0');

  const price = total / (100 + vatPercentage.value) * 100;

  return (price === 0 || isNaN(price)) ? '-' : formatCurrency(price);
}

function formatCurrency(price: number): string {
  const formatter = new Intl.NumberFormat(props.returnOrder.locale.isoCode.replace('_', '-'), {
    style: 'currency',
    currency: currencyCode.value,
  });

  return formatter.format(price);
}

const total = computed<{
  subTotal: string;
  tax: string;
  total: string;
}>(() => {
  const items = Object.keys(form.items).map((key) => form.items[key]);
  const total = items.reduce((accumulator, item) => accumulator + (item.quantity * parseFloat(item.price) || 0), 0);
  const subTotal = total / (100 + vatPercentage.value) * 100;

  return {
    subTotal: formatCurrency(subTotal),
    tax: formatCurrency(total - subTotal),
    total: formatCurrency(total),
  };
});

function save() {
  if (props.activePaymentRequest === null) {
    form.post(route('panel.return-orders.payment-request.create', { returnOrder: props.returnOrder }), {
      preserveScroll: true,
      onSuccess: afterSave,
    });
  } else {
    form.put(route('panel.return-orders.payment-request.update', {
      returnOrder: props.returnOrder,
      paymentRequest: props.activePaymentRequest,
    }), {
      preserveScroll: true,
      onSuccess: afterSave,
    });
  }
}

function afterSave(): void {
  close();
  form.reset();
  addRow();
}

const tableId = useUniqueId('paymentRequestModal');

onMounted(() => {
  const items = computed(() => Object.values(form.items));
  const { sortedItems } = useDragAndDrop(tableId, items);

  watch(sortedItems, () => {
    const sortedIds = sortedItems.value
      .filter((item: PaymentLine) => item !== undefined)
      .map((item: PaymentLine) => item.id);

    sortedIds.forEach((id: string) => {
      form.items[id].sort_order = sortedIds.indexOf(id);
    });
  });
});

function remove() {
  router.delete(route('panel.return-orders.payment-request.delete', {
    returnOrder: props.returnOrder,
    paymentRequest: props.activePaymentRequest,
  }), {
    preserveScroll: true,
    onSuccess: () => close(),
  });
}

function isPaymentRequestLinePaid(paymentRequestLineId: string): boolean {
  const paymentRequestLine = props.activePaymentRequest?.paymentRequestLines.find((paymentRequestLine: PaymentRequestLineViewModel) => paymentRequestLine.id === paymentRequestLineId) ?? null;

  if (paymentRequestLine === null) {
    return false;
  }

  return paymentRequestLine.required || props.activePaymentRequest.selectedPaymentRequestLineIds.includes(paymentRequestLineId);
}

function setFromTemplate(template: string): void {
  form.description = template;
}

const parameters = computed<string[]>(() => [
  App.Enums.EmailTemplateParameterType.PAYMENT_REQUEST_REFERENCE,
].map((parameter: string) => '{{ ' + parameter + ' }}'));
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.returns:detail:payment-requests:manage:title')"
    :size="ModalSize.ExtraLarge"
    @close="close"
  >
    <div class="flex flex-col space-y-6">
      <div class="flex flex-col space-y-1">
        <div class="flex items-center justify-between">
          <div class="flex items-start space-x-2">
            <InputLabel :label="$t('panel.returns:detail:payment-requests:manage:description')" />
            <NoteReplies
              :return-order="returnOrder"
              :note-templates="noteTemplates"
              @attach="setFromTemplate"
            />
          </div>

          <ParameterList :parameters="parameters" />
        </div>

        <Textarea
          v-model="form.description"
          :error="form.errors.description"
          :disabled="activePaymentRequest?.hasStartedPaying"
        />
      </div>

      <div class="flex flex-col overflow-hidden rounded border shadow-sm">
        <table
          :id="tableId"
          class="min-w-full table-auto divide-y"
        >
          <thead class="border-b bg-slate-50">
            <tr>
              <th class="relative sm:w-12 sm:px-6" />
              <th class="min-w-48 px-2 py-2.5 text-left text-xs font-medium uppercase tracking-wider text-slate-500">
                {{ $t('panel.returns:detail:payment-requests:manage:table:item') }}
              </th>
              <th class="px-2 py-2.5 text-left text-xs font-medium uppercase tracking-wider text-slate-500">
                {{ $t('panel.returns:detail:payment-requests:manage:table:quantity') }}
              </th>
              <th class="px-2 py-2.5 text-left text-xs font-medium uppercase tracking-wider text-slate-500">
                {{ $t('panel.returns:detail:payment-requests:manage:table:price-incl-vat') }}
              </th>
              <th class="px-2 py-2.5 text-left text-xs font-medium uppercase tracking-wider text-slate-500">
                {{ $t('panel.returns:detail:payment-requests:manage:table:price-excl-vat') }}
              </th>
              <th class="px-2 py-2.5 text-left text-xs font-medium uppercase tracking-wider text-slate-500">
                {{ $t('panel.returns:detail:payment-requests:manage:table:required') }}
              </th>
              <th class="relative py-3.5 pl-3 pr-4 sm:pr-3" />
            </tr>
          </thead>
          <tbody class="divide-y bg-white">
            <tr
              v-for="id in Object.keys(form.items)"
              :id="id"
              :key="id"
            >
              <td class="relative pl-3">
                <DraggableIcon
                  v-show="activePaymentRequest === null || ! activePaymentRequest?.hasStartedPaying"
                  class="mr-2 size-5 cursor-move select-none text-slate-400"
                />
                <div
                  v-if="activePaymentRequest?.isPaid && isPaymentRequestLinePaid(id)"
                  class="mr-2"
                >
                  <Badge color="green">
                    {{ $t('panel.returns:detail:payment-requests:paid') }}
                  </Badge>
                </div>
              </td>
              <td class="whitespace-nowrap px-2 py-2.5 text-sm font-medium text-slate-900">
                <TextInput
                  v-model="form.items[id].description"
                  :error="form.errors['items.' + id + '.description']"
                  :disabled="activePaymentRequest?.hasStartedPaying"
                />
              </td>
              <td class="w-28 whitespace-nowrap px-2 py-2.5 text-sm text-slate-500">
                <TextInput
                  v-model="form.items[id].quantity"
                  :type="TextInputFieldTypes.Number"
                  :error="form.errors['items.' + id + '.quantity']"
                  :disabled="activePaymentRequest?.hasStartedPaying"
                />
              </td>
              <td class="w-40 whitespace-nowrap px-2 py-2.5 text-sm text-slate-500">
                <TextInput
                  v-model="form.items[id].price"
                  :icon-position="TextInputIconPosition.Left"
                  :type="TextInputFieldTypes.Number"
                  allow-decimal
                  :error="form.errors['items.' + id + '.price']"
                  :disabled="activePaymentRequest?.hasStartedPaying"
                >
                  <template #icon>
                    <TextStyle>{{ currencySymbol }}</TextStyle>
                  </template>
                </TextInput>
              </td>
              <td class="w-32 whitespace-nowrap px-2 py-2.5 text-sm text-slate-500">
                <p class="mb-1 text-sm font-medium text-slate-500">
                  {{ getPriceExclusiveVat(form.items[id]) }}
                </p>
              </td>
              <td class="w-20 whitespace-nowrap px-2 py-2.5 text-sm text-slate-500">
                <Toggle
                  v-model="form.items[id].required"
                  :disabled="activePaymentRequest?.hasStartedPaying"
                />
              </td>
              <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                <TrashIcon
                  v-if="Object.keys(form.items).length > 1 && (activePaymentRequest === null || ! activePaymentRequest?.hasStartedPaying)"
                  class="size-5 cursor-pointer text-slate-400 hover:text-red-600"
                  @click="removeRow(id)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="activePaymentRequest === null || ! activePaymentRequest.hasStartedPaying"
          class="w-full border-t bg-slate-50 py-3 text-center"
        >
          <Button
            plain
            variant="primary"
            @click="addRow"
          >
            + {{ $t('panel.returns:detail:payment-requests:manage:table:add-item') }}
          </Button>
        </div>
      </div>

      <div class="flex justify-end">
        <div class="w-1/3 overflow-hidden rounded border shadow-sm">
          <div class="block border-b bg-slate-50 px-2 py-2.5 text-xs font-medium uppercase tracking-wider text-slate-500">
            {{ $t('panel.returns:detail:payment-requests:manage:table:summary') }}
          </div>
          <div class="grid grid-cols-2 gap-x-16 gap-y-4 px-2 py-2.5 text-sm">
            <TextStyle shade="subdued">
              {{ $t('panel.returns:detail:payment-requests:manage:table:subtotal') }}
            </TextStyle>
            <TextStyle
              semi-bold
              shade="subdued"
            >
              {{ total.subTotal }}
            </TextStyle>
            <TextStyle shade="subdued">
              {{ $t('panel.returns:detail:payment-requests:manage:table:tax') }}
            </TextStyle>
            <TextStyle
              semi-bold
              shade="subdued"
            >
              {{ total.tax }}
            </TextStyle>
          </div>
          <div class="grid grid-cols-2 gap-x-16 gap-y-4 border-t px-2 py-2.5 text-sm">
            <TextStyle
              shade="subdued"
              semi-bold
            >
              {{ $t('panel.returns:detail:payment-requests:manage:table:total') }}
            </TextStyle>
            <TextStyle semi-bold>
              {{ total.total }}
            </TextStyle>
          </div>
          <div
            v-if="activePaymentRequest?.isPaid"
            class="grid grid-cols-2 gap-x-16 gap-y-4 border-t px-2 py-2.5 text-sm"
          >
            <TextStyle
              shade="subdued"
              semi-bold
            >
              {{ $t('panel.returns:detail:payment-requests:manage:table:paid') }}
            </TextStyle>
            <TextStyle semi-bold>
              {{ activePaymentRequest.totalSelected }}
            </TextStyle>
          </div>
        </div>
      </div>
    </div>

    <template #actions>
      <div class="flex w-full items-center justify-between">
        <div>
          <Button
            v-if="activePaymentRequest !== null"
            :variant="ButtonVariant.Critical"
            :disabled="activePaymentRequest?.hasStartedPaying"
            plain
            @click="remove"
          >
            {{ $t('panel.global:actions:delete') }}
          </Button>
        </div>

        <Button
          :variant="ButtonVariant.Primary"
          :disabled="activePaymentRequest?.hasStartedPaying"
          @click="save"
        >
          <span v-if="props.activePaymentRequest === null">{{ $t('panel.global:actions:save') }}</span>
          <span v-if="props.activePaymentRequest !== null">{{ $t('panel.global:actions:update') }}</span>
        </Button>
      </div>
    </template>
  </Modal>
</template>
