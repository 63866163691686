<script lang="ts" setup>
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import {
  Button,
  ButtonVariant,
  Modal,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';

defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'confirm'): void;
}>();

function cancel(): void {
  emit('cancel');
}

function confirm(): void {
  emit('confirm');
}

</script>

<template>
  <Modal
    :open="open"
    icon-color="red"
    :title="$t('panel.settings:form:automations:trigger-change:title')"
    @close="cancel"
  >
    <template #icon>
      <ExclamationTriangleIcon class="h-6 w-6" />
    </template>

    <TextContainer :spacing="TextContainerSpacing.Loose">
      <TextStyle :shade="TextStyleShade.Subdued">
        <p>
          {{ $t('panel.settings:form:automations:trigger-change:message') }}
        </p>
      </TextStyle>
    </TextContainer>

    <template #actions>
      <Button @click="cancel">
        Cancel
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="confirm"
      >
        Confirm
      </Button>
    </template>
  </Modal>
</template>
