<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import {
  Button,
  Heading,
  HeadingElement,
  HeadingSize,
  Stack,
  StackItem,
  StackSpacing,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import FormPreview from './FormPreview.vue';
import { useOnboarding } from '../composables';
import { confettiService, getOnboardingState, type OnboardingState } from '../services';
import { type Types } from '@app/shared/types/generated-v2';
import { usePage } from '@inertiajs/vue3';
import { CalendarIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

type FormLocaleViewModel = Types['App.Models.ViewModels.FormLocaleViewModel'];

defineProps<{ formLocale?: FormLocaleViewModel }>();

const { checkedStep, lastCompletedStep } = useOnboarding();
const { startConfettiAnimation } = confettiService();

const onboardingState = computed<OnboardingState>(() => {
  if (!checkedStep.value) return null;
  return getOnboardingState(checkedStep.value);
});

const demoLink = computed<string | null>(() => {
  return usePage().props.demoLink as string;
});

const returnFormPreview = ref<HTMLElement | null>(null);

function setReturnFormPreview(element: HTMLElement): void {
  returnFormPreview.value = element;
}

function celebrateFormCreation(): void {
  if (lastCompletedStep.value !== 'hasReturnForm') {
    return;
  }

  if (!returnFormPreview.value) {
    return;
  }

  startConfettiAnimation(returnFormPreview.value);
}

function openMeetingInvite(): void {
  window.open(demoLink.value ?? 'https://www.returnless.com/meetings/joost-kempe/support-call');
}

watchEffect(celebrateFormCreation);
</script>

<template>
  <div>
    <div class="grid grid-cols-12 sm:gap-4 lg:gap-12">
      <FormPreview
        v-if="formLocale"
        :form="formLocale"
        @ref="setReturnFormPreview"
      />
      <div class="col-span-12 lg:col-span-9 max-w-3xl mt-4 sm:mt-0">
        <TextContainer>
          <Stack
            vertical
            :spacing="StackSpacing.Loose"
          >
            <StackItem>
              <div>
                <Heading
                  :element="HeadingElement.H1"
                  :size="HeadingSize.Large"
                >
                  <span v-if="onboardingState?.title">{{ $t(onboardingState.title) }}</span>
                </Heading>
              </div>
            </StackItem>
            <StackItem>
              <TextStyle :shade="TextStyleShade.Subdued">
                <p v-if="onboardingState?.subtitle">
                  {{ $t(onboardingState.subtitle) }}
                </p>
              </TextStyle>
            </StackItem>
            <StackItem>
              <Button @click="openMeetingInvite">
                <template #icon>
                  <CalendarIcon class="w-5 h-5" />
                </template>
                <span>{{ $t('panel.dashboard.onboarding_info.contact_info.schedule_meeting') }}</span>
              </Button>
            </StackItem>
          </Stack>
        </TextContainer>
      </div>
    </div>
  </div>
</template>
