<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import {
  ActionList,
  BulkActionLoader,
  Link as LinkComponent,
  Popover,
  TextStyle,
  TextStyleShade,
  Toast,
  ToastGroup,
  TopBar,
  TopBarItem,
  UserMenu,
} from '@app/panel/Components';
import { Head, usePage } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { User } from '@app/panel/types';
import {
  BuildingOfficeIcon,
  ChevronDownIcon,
  CogIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  MapPinIcon,
  QuestionMarkCircleIcon,
  ShareIcon,
  UserIcon,
} from '@heroicons/vue/24/outline';
import { ToastNotification, useToast } from '@app/panel/Composables/useToast';
import { SettingsLink } from '@app/panel/Containers';
import { ArrowRightIcon } from '@heroicons/vue/24/solid';
import { ToastVariant } from '@app/panel/Components/Toast';
import { useI18n } from 'vue-i18n';
import { useErrorHandler } from '@app/panel/Composables/useErrorHandler';
import useBarcodeScanner from '@app/panel/Composables/useBarcodeScanner/useBarcodeScanner';
import AlertDialog from './Components/AlertDialog.vue';
import ConfirmationDialog from './Components/ConfirmationDialog.vue';
import DowngradeRequiredDialog from './Components/DowngradeRequiredDialog.vue';
import FeatureLimitReachedDialog from './Components/FeatureLimitReachedDialog.vue';
import { usePanel } from '@app/panel/Composables/useHttp';

const props = withDefaults(defineProps<{
  title?: string;
  listenToBarcodeScanner?: boolean;
}>(), {
  title: 'Returnless',
  listenToBarcodeScanner: true,
});

const user = computed((): User => (usePage().props.user as User));

const redirectTo = computed<string>(() => usePage().props.redirectTo as string);

const userMenuOpen = ref(false);
const openUserMenu = () => (userMenuOpen.value = true);
const closeUserMenu = () => (userMenuOpen.value = false);

function signOut(): void {
  router.post('/logout');
}

const {
  emitToastEvents,
  closeToast,
  startListeningToastEvents,
  toastIDs,
  toastNotifications,
} = useToast();

onMounted(() => {
  startListeningToastEvents();
  handleRedirectsWhenApplicable();
});

watch(() => usePage().props.toastNotifications, (newPageNotifications: ToastNotification[]) => {
  if (newPageNotifications !== null) {
    setTimeout(() => {
      emitToastEvents(newPageNotifications);
    }, 100);
  }
}, { immediate: true });

function handleRedirectsWhenApplicable(): void {
  if (!redirectTo.value) {
    return;
  }

  window.open(redirectTo.value, '_blank');
}

if (props.listenToBarcodeScanner) {
  useBarcodeScanner();
}

const { t } = useI18n();

const { emitToastEvent } = useToast();

let removeInertiaExceptionHandler = null;

function startInertiaExceptionHandler(): void {
  removeInertiaExceptionHandler = router.on('exception', (event) => {
    if (!navigator.onLine || event.detail.exception.message === 'Network Error') {
      emitToastEvent({
        title: t('panel.global:offline'),
        message: t('panel.global:internet-disconnected'),
        variant: ToastVariant.Critical,
      });

      event.preventDefault();
    }
  });
}

function setBeaconDetails() {
  if (typeof window.Beacon !== 'function') {
    return;
  }

  window.Beacon('identify', {
    name: user.value.name,
    email: user.value.email,
    company: usePage().props.currentTenant,
    jobTitle: user.value.isOwner ? 'Owner' : 'Team Member',
    avatar: user.value.profilePhotoUrl,
    signature: usePage().props.beaconSignature,
    isOwner: user.value.isOwner,
    userId: user.value.id,
    activeSubscription: usePage().props.hasSubscription,
    isPartner: usePage().props.isPartner,
    hasPartner: usePage().props.hasPartner,
    tenantId: usePage().props.currentTenantId,
    stripeCustomerId: usePage().props.stripeCustomerId,
    subscriptionPlan: usePage().props.subscriptionPlan,
    lastActivityAt: user.value.lastActivityAt,
    language: (navigator.language || navigator.userLanguage).split('-')[0],
  });
}

function openBeacon(): void {
  if (typeof window.Beacon !== 'function') {
    return;
  }

  window.Beacon('open');
}

const userMenu = ref<HTMLElement>(null);

const {
  register: registerErrorHandler,
  remove: removeErrorHandler,
} = useErrorHandler();

onMounted(() => {
  startInertiaExceptionHandler();
  registerErrorHandler();
  setBeaconDetails();
});

onUnmounted(() => {
  removeInertiaExceptionHandler();
  removeErrorHandler();
});

setInterval(() => {
  usePanel()
    .post(route('panel.pulse'))
    .catch(() => void 0);
}, 5 * 60 * 1000); // execute the pulse every 5 minutes
</script>

<template>
  <Head>
    <title>{{ title }}</title>
  </Head>

  <div
    v-if="$page.props.isImpersonating"
    class="bg-red-700 px-8 py-2 text-sm text-white"
  >
    You are currently impersonating a user
  </div>

  <div
    v-if="$page.props.showDemoBanner"
    class="w-full text-sm"
  >
    <div class="flex items-center justify-center space-x-2.5 bg-brand-500 px-6 py-3 text-white">
      <span>{{ $t('panel.global:demo-banner:title') }}</span>
      <a
        :href="usePage().props.demoLink"
        target="_blank"
        class="text-md flex cursor-pointer items-center space-x-1.5 font-bold hover:underline"
      >
        <span>{{ $t('panel.global:demo-banner:action') }}</span>
        <ArrowRightIcon class="size-4" />
      </a>
    </div>
  </div>

  <div class="flex h-full flex-col">
    <div class="flex-1">
      <TopBar :search-bar-placeholder="$t('panel.containers.search-bar.placeholder')">
        <template #navigation>
          <TopBarItem
            :active="$route().current('panel.dashboard*')"
            href="/"
          >
            {{ $t('panel.global.top-bar.dashboard') }}
          </TopBarItem>

          <TopBarItem
            v-if="! $page.props.isPartner"
            :active="$route().current('panel.requests*')"
            href="/requests"
          >
            {{ $t('panel.global.top-bar.requests') }}
            <template
              v-if="$page.props.openRequestCount"
              #badge
            >
              {{ $page.props.openRequestCount }}
            </template>
          </TopBarItem>

          <TopBarItem
            :active="$route().current('panel.return-orders.*')"
            href="/returns"
          >
            {{ $t('panel.global.top-bar.returns') }}
          </TopBarItem>

          <TopBarItem
            v-if="! $page.props.isPartner"
            :active="$route().current('panel.analytics*')"
            href="/analytics"
          >
            {{ $t('panel.global.top-bar.analytics') }}
          </TopBarItem>

          <TopBarItem>
            <template #dropdown-content>
              <div class="w-52 max-w-md md:w-screen lg:max-w-2xl">
                <div
                  v-if="$page.props.isPartner"
                  class="grid grid-cols-1 sm:p-5 lg:grid-cols-2"
                >
                  <SettingsLink
                    :title="$t('panel.global.top-bar.settings.partner_management.label')"
                    :description="$t('panel.global.top-bar.settings.partner_management.description')"
                    :href="$route('panel.account.partner-management')"
                  >
                    <BuildingOfficeIcon class="size-6" />
                  </SettingsLink>
                </div>
                <div
                  v-if="! $page.props.isPartner"
                  class="grid grid-cols-1 sm:p-5 lg:grid-cols-2"
                >
                  <div>
                    <SettingsLink
                      :title="$t('panel.global.top-bar.settings.general_settings.label')"
                      :description="$t('panel.global.top-bar.settings.general_settings.description')"
                      :href="$route('panel.settings.general-settings')"
                    >
                      <CogIcon class="size-6" />
                    </SettingsLink>
                  </div>

                  <div>
                    <SettingsLink
                      :title="$t('panel.global.top-bar.settings.return_forms.label')"
                      :description="$t('panel.global.top-bar.settings.return_forms.description')"
                      :href="$route('panel.settings.forms')"
                    >
                      <DocumentDuplicateIcon class="size-6" />
                    </SettingsLink>
                  </div>

                  <div>
                    <SettingsLink
                      :title="$t('panel.global.top-bar.settings.integrations.label')"
                      :description="$t('panel.global.top-bar.settings.integrations.description')"
                      :href="$route('panel.settings.integrations.platform')"
                    >
                      <ShareIcon class="size-6" />
                    </SettingsLink>
                  </div>

                  <div>
                    <SettingsLink
                      :title="$t('panel.global.top-bar.settings.templates.label')"
                      :description="$t('panel.global.top-bar.settings.templates.description')"
                      :href="$route('panel.settings.email-templates')"
                    >
                      <EnvelopeIcon class="size-6" />
                    </SettingsLink>
                  </div>

                  <div>
                    <SettingsLink
                      :title="$t('panel.global.top-bar.settings.return_addresses.label')"
                      :description="$t('panel.global.top-bar.settings.return_addresses.description')"
                      :href="$route('panel.settings.return-addresses')"
                    >
                      <MapPinIcon class="size-6" />
                    </SettingsLink>
                  </div>
                </div>

                <div class="grid grid-cols-1 bg-slate-50 sm:p-5 lg:grid-cols-2">
                  <div>
                    <SettingsLink
                      dark
                      :title="$t('panel.global.top-bar.settings.billing_and_subscription.label')"
                      :description="$t('panel.global.top-bar.settings.billing_and_subscription.description')"
                      :href="$route('panel.account.billing')"
                    >
                      <CreditCardIcon class="size-6" />
                    </SettingsLink>
                  </div>

                  <div>
                    <SettingsLink
                      dark
                      :title="$t('panel.global.top-bar.settings.team_members.label')"
                      :description="$t('panel.global.top-bar.settings.team_members.description')"
                      :href="$route('panel.account.team')"
                    >
                      <UserIcon class="size-6" />
                    </SettingsLink>
                  </div>
                </div>
              </div>
            </template>

            <div
              class="flex items-center space-x-2"
            >
              <span>{{ $t('panel.global.top-bar.settings.label') }}</span>
              <ChevronDownIcon
                id="icontest"
                class="size-4"
              />
            </div>
          </TopBarItem>
        </template>

        <template #documentation>
          <TopBarItem>
            <QuestionMarkCircleIcon class="size-6" />
            <template #dropdown-content>
              <ActionList
                :items="[
                  {
                    content: $t('panel.global:top-bar:developer-docs'),
                    href: 'https://docs.returnless.com/docs/api-rest-reference',
                    target: '_blank',
                    native: true,
                  },
                  {
                    content: $t('panel.global:top-bar:support-articles'),
                    href: 'https://docs.returnless.com/',
                    target: '_blank',
                    native: true,
                  },
                  {
                    content: $t('panel.global:top-bar:contact-support'),
                    onClick: openBeacon,
                  },
                ]"
              />
            </template>
          </TopBarItem>
        </template>

        <template #user-menu>
          <div class="flex w-full justify-between space-x-3 border-t border-slate-700 pb-3 pt-5 md:w-fit md:border-none md:py-0">
            <div class="order-3 md:order-1" />

            <div class="order-1 mx-1 flex items-center justify-between md:order-3">
              <div ref="userMenu">
                <UserMenu
                  :profile-photo="user.profilePhotoUrl"
                  :name="user.name"
                  @click="openUserMenu"
                  @close="closeUserMenu"
                />
              </div>

              <Popover
                flush
                :active="userMenuOpen"
                :parent="userMenu"
                @close="closeUserMenu"
              >
                <div class="border-b bg-slate-50 p-2 text-sm">
                  <p>
                    <TextStyle strong>
                      <span class="block">
                        {{ user.name }}
                      </span>
                    </TextStyle>
                    <TextStyle :shade="TextStyleShade.Subdued">
                      {{ user.email }}
                    </TextStyle>
                  </p>
                  <TextStyle :shade="TextStyleShade.Subdued">
                    {{ $page.props.currentTenant }}
                  </TextStyle>
                  <p v-if="$page.props.canSwitchAccounts">
                    <LinkComponent :href="$route('panel.account-selection')">
                      Switch accounts
                    </LinkComponent>
                  </p>
                </div>
                <ActionList
                  :items="[
                    {
                      content: $t('panel.global.top-bar.my-account'),
                      href: '/account',
                    },
                    {
                      content: $t('panel.global.top-bar.sign-out'),
                      onClick: signOut,
                    },
                  ]"
                />
              </Popover>
            </div>
          </div>
        </template>
      </TopBar>

      <ToastGroup>
        <Toast
          v-for="toast in toastNotifications"
          :key="toast.id"
          :open="toastIDs.includes(toast.id)"
          :title="toast.title"
          :message="toast.message"
          :variant="toast.variant"
          :duration="toast.duration"
          :action="toast.action"
          @close="() => closeToast(toast.id)"
        />
      </ToastGroup>

      <AlertDialog />
      <ConfirmationDialog />

      <DowngradeRequiredDialog />

      <FeatureLimitReachedDialog />

      <slot />
    </div>

    <BulkActionLoader />
  </div>
</template>

<style>
html, body, #app {
  height: 100%;
}

@media only screen and (max-width: 400px) {
  .user-info {
    display: none;
  }
}
</style>
