<script setup lang="ts">
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@returnless/focus-ui';
import { router, usePage } from '@inertiajs/vue3';
import { usePageLoad } from '@app/panel/Composables/usePageLoad';
import { useFeatureWarning } from '@app/panel/Composables/useFeatureWarning';
import { useI18n } from 'vue-i18n';

const { loading } = usePageLoad();
const { t } = useI18n();

const { featureWarningMessage, setFeatureWarningMessage } = useFeatureWarning();

router.on('finish', () => {
  const pageProps = usePage().props;
  const featureLimitWarning = pageProps.featureLimitWarning as string ?? null;
  setFeatureWarningMessage(featureLimitWarning);
});

function closeDialog() {
  setFeatureWarningMessage(null);
}
</script>

<template>
  <Dialog
    :open="featureWarningMessage !== null"
    @cancel="closeDialog"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          {{ t('panel.feature-limit-reached:modal:title') }}
        </DialogTitle>
        <DialogDescription>{{ featureWarningMessage }}</DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          variant="secondary"
          :disabled="loading"
          @click="closeDialog"
        >
          {{ t('panel.feature-limit-reached:modal:buttons:ignore-and-continue') }}
        </Button>
        <Button
          variant="primary"
          :href="$route('panel.account.billing')"
          :loading="loading"
        >
          {{ t('panel.feature-limit-reached:modal:buttons:view-upgrade-options') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
