<script lang="ts">
import { defineComponent } from 'vue';
import { GuestLayout } from '@app/panel/Layouts';
import { Link, TextContainer } from '@app/panel/Components';
import { router } from '@inertiajs/vue3';

export default defineComponent({
  components: { GuestLayout, TextContainer, Link },

  setup() {
    function signOut(): void {
      router.post('/logout');
    }

    return {
      signOut,
    };
  },
});
</script>

<template>
  <GuestLayout title="Verify your email address">
    <template #subtitle>
      <TextContainer>
        Or
        <Link @click="signOut">
          Sign out
        </Link>
      </TextContainer>
    </template>

    <button>Resend verification email</button>

    verify email
  </GuestLayout>
</template>
