<script lang="ts" setup>
import {
  Avatar,
  AvatarSize,
  Card,
  CardSection,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { isEmpty } from 'lodash';
import { useI18n } from 'vue-i18n';

type RefundViewModel = Types.App.Models.ViewModels.RefundViewModel;

const props = defineProps<{
  refunds: Array<RefundViewModel>;
}>();

const hasCoupons = computed(() => !isEmpty(props.refunds));

const { t } = useI18n();
</script>

<template>
  <Card :title="t('panel.returns:detail:refunds')">
    <CardSection v-if="hasCoupons">
      <ul class="divide-y space-y-2">
        <li
          v-for="refund in refunds"
          :key="refund.id"
          class="first:pt-0 last:pb-0 py-4"
        >
          <div class="flex justify-center items-center space-x-2">
            <Avatar
              :src="refund.platformIntegration.logo.imageSourceUrl"
              :size="AvatarSize.Large"
            />

            <div class="flex-1">
              <TextContainer>
                <div class="mt-1">
                  <TextStyle :shade="TextStyleShade.Pale">
                    {{ refund.platformIntegration.name }} ID(s):
                  </TextStyle>
                  <TextStyle :shade="TextStyleShade.Dense">
                    {{ refund.externalId }}
                    <span
                      v-if="refund.isOnlineRefund !== null"
                      class="lowercase"
                    >
                      ({{ refund.isOnlineRefund
                        ? $t('panel.returns:detail:refunds:online')
                        : $t('panel.returns:detail:refunds:offline') }})
                    </span>
                  </TextStyle>
                </div>

                <div class="mt-1">
                  <TextStyle :shade="TextStyleShade.Pale">
                    {{ $t('panel.returns:detail:refunds:amount') }}:
                  </TextStyle>
                  <TextStyle :shade="TextStyleShade.Dense">
                    {{ refund.amount }}
                  </TextStyle>
                </div>

                <div class="mt-1">
                  <TextStyle
                    v-for="refundItem in refund.refundItems"
                    :key="refundItem.id"
                    class="flex flex-col"
                  >
                    <TextStyle>
                      {{ refundItem.name }}
                    </TextStyle>
                    <TextStyle>
                      <TextStyle :shade="TextStyleShade.Pale">
                        {{ $t('panel.returns:detail:refunds:quantity') }}:
                      </TextStyle>
                      {{ refundItem.quantity }}
                    </TextStyle>
                  </TextStyle>
                </div>
              </TextContainer>
            </div>
          </div>
        </li>
      </ul>
    </CardSection>

    <CardSection v-if="! hasCoupons">
      <TextContainer text-center>
        {{ $t('panel.returns:detail:refunds:no-refunds') }}
      </TextContainer>
    </CardSection>
  </Card>
</template>
