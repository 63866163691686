<script lang="ts" setup>
import {
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  Page,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { AppLayout } from '@app/panel/Layouts';
import * as Types from '@app/panel/types/generated';
import { FieldRenderer, PageNavigation } from './partials';
import { FormStructure } from './types';
import { keys } from 'lodash';
import { PropType, provide, ref } from 'vue';
import { useIntegrations } from '@app/panel/Composables/useIntegrations';

const props = defineProps({
  shippingIntegrationTenant: {
    type: Object as PropType<Types.App.Models.ViewModels.ShippingIntegrationTenantViewModel>,
    required: true,
  },

  formStructure: {
    type: Array as PropType<FormStructure[]>,
    required: false,
    default: () => [],
  },

  returnStatuses: {
    type: Array as PropType<Types.App.Models.ViewModels.ReturnStatusViewModel[]>,
    required: false,
    default: () => [],
  },

  settings: {
    type: Object,
    required: true,
  },
});

const { settingsScaffold } = useIntegrations(props.formStructure);

const form = useForm({ ...settingsScaffold.value, ...props.settings });

function filterFieldsByBlock(data: object, blockKey: string): Record<string, string> {
  const block = props.formStructure.find((item) => item.key === blockKey);
  const fieldNames = block.fields.map((field) => field.key);

  return keys(data)
    .filter((fieldName) => fieldNames.includes(fieldName))
    .reduce((acc, fieldName) => ({ ...acc, [fieldName]: data[fieldName] }), {});
}

const lastBlockKey = ref(null);

function save(block: string): void {
  form
    .transform((data) => filterFieldsByBlock(data, block))
    .put(route('panel.settings.integrations.shipping.update', {
      shippingIntegrationTenant: props.shippingIntegrationTenant,
      block,
    }), {
      preserveScroll: true,
      preserveState: true,
      onBefore: () => {
        lastBlockKey.value = block;
      },
      onFinish: () => {
        lastBlockKey.value = null;
      },
    });
}

provide('form', form);
</script>

<template>
  <AppLayout :title="$t('panel.settings.integrations.shipping.page_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <div class="space-y-8">
        <template
          v-for="(section, index) in formStructure"
          :key="index"
        >
          <Form @submit="() => save(section.key)">
            <Card
              :title="section.title"
              :primary-footer-action="{
                content: $t('panel.settings.integrations.shipping.settings_page.save'),
                type: ButtonType.Submit,
                loading: form.processing && lastBlockKey === section.key,
              }"
            >
              <CardSection>
                <FormLayout>
                  <FormLayout
                    v-for="field in section.fields"
                    :key="field.key"
                  >
                    <FieldRenderer
                      :field-name="field.key"
                      :label="field.label"
                      :type="field.type"
                      :options="field.options"
                    />
                  </FormLayout>
                </FormLayout>
              </CardSection>
            </Card>
          </Form>
        </template>
      </div>
    </Page>
  </AppLayout>
</template>
