<script lang="ts" setup>
import * as Types from '@app/panel/types/generated';
import {
  DoughnutChart,
} from '@app/panel/Components';
import { computed } from 'vue';

type OverviewViewModel = Types.App.Http.ViewModels.Panel.Analytics.Overview.OverviewViewModel;

const props = defineProps<{
  returnReasons: OverviewViewModel['returnReasons'];
}>();

const labels = computed(() => props.returnReasons.map((returnReason) => returnReason.label));
const values = computed(() => props.returnReasons.map((returnReason) => returnReason.value));
</script>

<template>
  <DoughnutChart
    :labels="labels"
    :datasets="[{ data: values }]"
    hide-labels
  />
</template>
