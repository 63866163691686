<script lang="ts">
import { defineComponent } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { Form } from '@app/panel/Components';

export default defineComponent({
  components: { Form },

  setup() {
    const form = useForm({
      password: '',
    });

    function submit() {
      form.post(route('password.confirm'));
    }

    return {
      form,
      submit,
    };
  },
});
</script>

<template>
  <div>
    Confirm password

    <Form @submit="submit">
      <label for="f-password">COnfirm password:</label>
      <input
        id="f-password"
        v-model="form.password"
        type="password"
        name="password"
      >
      <button type="submit">
        Confirm
      </button>
    </Form>
  </div>
</template>
