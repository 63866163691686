<script setup lang="ts">
import {
  Badge,
  Button,
  ButtonIconPosition,
  Card,
  CardSection,
  Divider,
  FormLayout,
  Select,
} from '@app/panel/Components';
import { computed, ref, Ref } from 'vue';
import DateRange from '@app/panel/Pages/Analytics/components/DateRange.vue';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import type { Types } from '@app/shared/types/generated-v2';
import { InertiaForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

type ShippingAnalyticsViewModel = Types['App.Http.ViewModels.Panel.Analytics.Shipping.ShippingAnalyticsViewModel'];

const { t } = useI18n();

const props = defineProps<{
  filterForm: Ref<InertiaForm>;
  carriers: ShippingAnalyticsViewModel['carriers'];
  fromCountries: ShippingAnalyticsViewModel['fromCountries'];
  toCountries: ShippingAnalyticsViewModel['toCountries'];
}>();

const emit = defineEmits<{
  onFormChange: [filterForm: InertiaForm];
}>();

const filtersOpen = ref(false);

function toggleFilters() {
  filtersOpen.value = !filtersOpen.value;
}

function updateDateRangeFilter(from: string, to: string) {
  emit('onFormChange', {
    ...props.filterForm,
    from,
    to,
  });
}

const updateFilter = (path: string, value: undefined | string): void => {
  const form = props.filterForm;

  form.filter[path] = value;

  emit('onFormChange', form);
};

const activeFilters = computed(() => {
  return Object.keys(props.filterForm.filter)
    .filter((key) => props.filterForm.filter[key])
    .map((key) => {
      return {
        label: t(`panel.analytics:shipping:filters:${key}`),
        value: props.filterForm.filter[key],
        path: key,
      };
    });
});
</script>

<template>
  <div class="mb-6">
    <div class="flex items-end justify-between">
      <DateRange
        :init-from="filterForm.from"
        :init-to="filterForm.to"
        @updated="(from, to) => updateDateRangeFilter(from, to)"
      />

      <div class="flex space-x-3">
        <Button
          :icon-position="ButtonIconPosition.Left"
          @click="toggleFilters"
          @keyup.esc="toggleFilters"
        >
          <template #icon>
            <FunnelIcon class="size-5" />
          </template>

          {{ $t('panel.global:labels:filters') }}
        </Button>
      </div>
    </div>
  </div>

  <div
    v-if="filtersOpen"
    class="mb-6"
  >
    <Card>
      <CardSection>
        <div class="flex space-x-6">
          <div class="flex-1">
            <FormLayout>
              <Select
                :model-value="filterForm.filter.carrier"
                nullable
                :options="props.carriers"
                :label="t('panel.analytics:shipping:filters:carrier')"
                @update:model-value="(value) => updateFilter('carrier', value)"
              />
            </FormLayout>
          </div>

          <div class="flex flex-1 space-x-6">
            <FormLayout class="flex-1">
              <Select
                :model-value="filterForm.filter.from_country"
                nullable
                :options="props.fromCountries"
                :label="t('panel.analytics:shipping:filters:from_country')"
                @update:model-value="(value) => updateFilter('from_country', value)"
              />
            </FormLayout>

            <FormLayout class="flex-1">
              <Select
                :model-value="filterForm.filter.to_country"
                nullable
                :options="props.toCountries"
                :label="t('panel.analytics:shipping:filters:to_country')"
                @update:model-value="(value) => updateFilter('to_country', value)"
              />
            </FormLayout>
          </div>
        </div>
      </CardSection>
    </Card>

    <Divider class="mt-6" />
  </div>

  <div
    v-if="activeFilters.length"
    class="mb-6 space-x-3"
  >
    <Badge
      v-for="filter in activeFilters"
      :key="filter.label"
      color="blue"
      closeable
      @close="() => updateFilter(filter.path, undefined)"
    >
      {{ filter.label }}: {{ filter.value }}
    </Badge>
  </div>
</template>
