<script lang="ts" setup>
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  Form,
  FormLayout,
  TextField,
} from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import { hasFeature } from '@app/panel/Utils';

const props = defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onCreateNoteTemplateModalClose() {
  emit('close');
}

const form = useForm({
  title: '',
  description: '',
  is_enabled_on_requests: true,
  is_enabled_on_returns: true,
  is_enabled_on_payment_requests: false,
});

function save() {
  form.post(route('panel.settings.note-templates.store'), {
    preserveScroll: true,
  });
}

</script>

<template>
  <Dialog
    :open="props.open"
    @cancel="onCreateNoteTemplateModalClose"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('panel.settings:templates:note-templates:create:modal-title') }}</DialogTitle>
      </DialogHeader>
      <DialogSection>
        <Form
          id="create-form"
          @submit="save"
        >
          <FormLayout>
            <FormLayout>
              <CheckboxGroup :label="$t('panel.settings:templates:note-templates:create:enable-label')">
                <Checkbox
                  v-model="form.is_enabled_on_requests"
                  :label="$t('panel.settings:templates:note-templates:requests')"
                  name="enabled_on_requests"
                  value="1"
                />
                <Checkbox
                  v-model="form.is_enabled_on_returns"
                  :label="$t('panel.settings:templates:note-templates:returns')"
                  name="enabled_on_returns"
                  value="1"
                />
                <Checkbox
                  v-if="hasFeature('PaymentRequests')"
                  v-model="form.is_enabled_on_payment_requests"
                  :label="$t('panel.settings:templates:note-templates:payment-requests')"
                  name="enabled_on_payment_requests"
                  value="1"
                />
              </CheckboxGroup>
            </FormLayout>

            <FormLayout>
              <TextField
                v-model="form.title"
                :error="form.errors.title"
                :label="$t('panel.settings:templates:note-templates:create:title-label')"
                :help-text="$t('panel.settings:templates:note-templates:create:title-subtitle')"
                max-length="80"
              />
            </FormLayout>

            <FormLayout>
              <TextField
                v-model="form.description"
                :error="form.errors.description"
                :label="$t('panel.settings:templates:note-templates:create:description-label')"
                :help-text="$t('panel.settings:templates:note-templates:create:description-subtitle')"
                max-length="280"
              />
            </FormLayout>
          </FormLayout>
        </Form>
      </DialogSection>
      <DialogFooter>
        <Button
          variant="secondary"
          form="close-form"
          @click="onCreateNoteTemplateModalClose"
        >
          {{ $t('panel.global:actions:cancel') }}
        </Button>

        <Button
          :disabled="!form.isDirty"
          :loading="form.processing"
          variant="primary"
          type="submit"
          form="create-form"
        >
          {{ $t('panel.settings:templates:note-templates:create:primary-footer-action') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
