<script lang="ts" setup>
import { PropType } from 'vue';
import {
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  TextStyleTransform,
} from '../../TextStyle';
import { findIndex } from 'lodash';

interface Option {
  label: string;
  value: string;
}

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },

  options: {
    type: Array as PropType<Option[]>,
    required: true,
    validate: (options: Option[]) =>
      options.length === 2,
  },
});

const emit = defineEmits(['update:modelValue']);

function onButtonSwitch(value: string): void {
  emit('update:modelValue', value);
}

function isFirstOption(option: Option): boolean {
  return findIndex(props.options, option) === 0;
}

function isActive(option: Option): boolean {
  return props.modelValue === option.value;
}

function getClassList(option: Option): string[] {
  const classList = [
    'relative',
    'inline-flex ',
    'items-center ',
    'px-4',
    'py-2',
    'border',
    'border-slate-300',
    'bg-white',
    'hover:bg-slate-50',
    'outline-none',
    'z-10',
  ];

  if (isActive(option)) {
    classList.push(
      'ring-2',
      'ring-brand-500',
      'z-20',
    );
  }

  if (isFirstOption(option)) {
    classList.push('rounded-l-md');
  } else {
    classList.push('rounded-r-md');
  }

  return classList;
}
</script>

<template>
  <div class="isolate">
    <button
      v-for="(option, index) in options"
      :key="index"
      type="button"
      :class="getClassList(option)"
      @click="() => onButtonSwitch(option.value)"
    >
      <TextStyle
        strong
        color="slate"
        :font-size="TextStyleFontSize.Small"
        :shade="TextStyleShade.Subdued"
        :transform="TextStyleTransform.Uppercase"
      >
        {{ option.label }}
      </TextStyle>
    </button>
  </div>
</template>
