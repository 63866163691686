<script lang="ts" setup>
import { type Types } from '@app/shared/types/generated-v2';
import { ReturnAddressForm } from '@app/panel/Pages/Settings/ReturnAddresses/types/ReturnAddressForm.type';
import { InertiaForm, router } from '@inertiajs/vue3';
import AddressModal from './AddressModal.vue';
import { DependencyWarningDialog, useDependencyWarning } from '@app/panel/Containers/DependencyWarning';

type ReturnAddressViewModel = Types['App.Models.ViewModels.ReturnAddressViewModel'];

const { showDependencyWarning } = useDependencyWarning();

const props = defineProps<{
  open: boolean;
  address: ReturnAddressViewModel;
}>();

defineEmits<{
  close: [];
}>();

const initialValues = {
  google_maps_places_id: props.address.googleMapsPlacesId,
  address_type: props.address.addressType,
  name: props.address.name,
  is_connected_to_shipping_methods: props.address.isConnectedToShippingMethods,
  company_name: props.address.companyName,
  country_id: props.address.country.id,
  telephone: props.address.telephone,
  street: props.address.street,
  house_number: props.address.houseNumber,
  suffix: props.address.suffix,
  postal_code: props.address.postalCode,
  city: props.address.city,
  state: props.address.state,
  is_primary: props.address.primary,
  image: null,
  image_src: props.address.rawImageSrc,
  latitude: null,
  longitude: null,
};

function update(form: InertiaForm<ReturnAddressForm>): Promise<boolean> {
  return new Promise((resolve) => {
    form.put(route('panel.settings.return-addresses.update', props.address), {
      onSuccess: () => resolve(true),
      onError: () => resolve(false),
      preserveState: (item) => {
        return Object.keys(item.props.errors).length > 0;
      },
    });
  });
}

function onAddressDelete() {
  if (props.address.dependencyWarning !== null) {
    showDependencyWarning(props.address.dependencyWarning);
    return;
  }

  router.delete(route('panel.settings.return-addresses.delete', props.address));
}
</script>

<template>
  <AddressModal
    type="edit"
    :open="open"
    :address-type="address.addressType"
    :submit-request="update"
    :initial-form-value="initialValues"
    @delete="onAddressDelete"
    @close="$emit('close')"
  />

  <DependencyWarningDialog />
</template>
