<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Card,
  CardSection,
  CardShadow,
  Heading,
  HeadingElement,
  Overlay,
  OverlayShade,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { asset } from 'laravel-vapor';
import { PlusSmallIcon } from '@heroicons/vue/24/outline';

interface RequestAuthorizationProps {
  provider: string;
  providerImageSrc: string;
}

defineProps<RequestAuthorizationProps>();

function goToIntegrations() {
  window.location.href = route('panel.settings.integrations.platform');
}
</script>

<template>
  <Head>
    <title>Authorize {{ provider }}</title>
  </Head>

  <Teleport to="body">
    <Overlay
      :shade="OverlayShade.Light"
    >
      <Card :shadow="CardShadow.ExtraLarge">
        <CardSection>
          <div class="mx-4">
            <div class="flex gap-12 mt-10 items-center w-2/3 mx-auto">
              <img
                :src="asset('favicon-180.png')"
                class="w-14 m-auto rounded-sm"
                loading="lazy"
              >

              <PlusSmallIcon
                class="h-6 w-6 text-slate-400"
              />

              <img
                :src="asset(providerImageSrc)"
                class="w-14 m-auto rounded-md"
                alt="Integration logo"
                loading="lazy"
              >
            </div>

            <div class="my-12 text-center w-3/4 m-auto">
              <Heading :element="HeadingElement.H3">
                {{ $t('panel.oauth.integration_consent.header', { integration_name: provider }) }}
              </Heading>

              <div class="mt-2">
                <TextContainer>
                  <TextStyle :shade="TextStyleShade.Subdued">
                    {{ $t('panel.basic.integration_consent.description', { integration_name: provider }) }}
                  </TextStyle>
                </TextContainer>
              </div>
            </div>
          </div>

          <ButtonGroup full-width>
            <Button
              :variant="ButtonVariant.Primary"
              class="ml-auto"
              @click="goToIntegrations"
            >
              {{ $t('panel.basic.integration_consent.continue_button') }}
            </Button>
          </ButtonGroup>
        </CardSection>
      </Card>
    </Overlay>
  </Teleport>
</template>
