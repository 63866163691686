<script setup lang="ts">

import { ref } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';
import { Banner, BannerVariant } from '@app/panel/Components';

type HorizontalBarChartViewModel = Types['App.Http.ViewModels.Panel.Analytics.HorizontalBarChartViewModel'];

withDefaults(defineProps<HorizontalBarChartViewModel & {
  clickable?: boolean;
}>(), {
  clickable: false,
});

defineEmits([
  'clicked',
]);

const activeBar = ref<string | null>(null);

function isActive(bar: string) {
  activeBar.value = bar;
}

function resetActive() {
  activeBar.value = null;
}

function activeClass(bar: string) {
  return activeBar.value !== null && activeBar.value !== bar;
}

</script>

<template>
  <div class="w-full">
    <div v-if="data.length === 0">
      <Banner
        :variant="BannerVariant.Default"
        :title="$t('panel.global:no-data-available')"
        align="center"
        hide-icon
      />
    </div>

    <div
      v-if="data.length > 1"
      class="mb-4 flex h-6 overflow-hidden rounded-md border-slate-300 bg-slate-200"
    >
      <div
        v-for="item in data"
        :key="item.id"
        class="border-x border-white transition-opacity duration-150 ease-in-out"
        :class="{
          [item.color]: true,
          'opacity-50': activeClass(item.id),
          'cursor-pointer': clickable,
        }"
        :style="{ width: `${item.percentage}%` }"
        @mouseenter="isActive(item.id)"
        @mouseleave="resetActive"
      />
    </div>

    <div class="flex flex-col">
      <div
        v-for="item in data"
        :key="item.id"
        class="flex items-center justify-between py-2 text-sm transition-opacity duration-150 ease-in-out"
        :class="{
          'text-slate-400': activeClass(item.id),
          'cursor-pointer' : clickable,
          'cursor-default': !clickable,
        }"
        @mouseenter="isActive(item.id)"
        @mouseleave="resetActive"
        @click="$emit('clicked', item.label)"
      >
        <div class="flex items-center space-x-3">
          <div
            class="size-4 rounded-md transition-all duration-150 ease-in-out"
            :class="{
              [item.color]: true,
              'opacity-50': activeClass(item.id),
            }"
          />
          <div>{{ item.label }} (<span class="font-medium">{{ item.value }}</span>)</div>
        </div>
        <div>{{ item.percentage }}%</div>
      </div>
    </div>
  </div>
</template>
