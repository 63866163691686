<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  Modal,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import { BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { onMounted, PropType } from 'vue';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;

type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

defineEmits<{
  (e: 'verify'): void;
}>();

const form = useForm({
  title: '',
  description: '',
  store_url: '',
  client_id: '',
  api_password: '',
  basic_auth_username: '',
  basic_auth_password: '',
  store_id: '',
});

const {
  currentStep,
  firstStep,
  lastStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function verify(): void {
  form.submit('post', route('panel.integration.salesforce.save-credentials'));
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.dashboard.integrations.salesforce.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <div class="space-y-6">
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.title"
            :label="$t('panel.dashboard.integrations.salesforce.step1.title')"
            :error="form.errors.title"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.description"
            :label="$t('panel.dashboard.integrations.salesforce.step1.description')"
            :error="form.errors.description"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.store_url"
            :label="$t('panel.dashboard.integrations.salesforce.step1.store_url')"
            placeholder="https://www.yourapplication.com/"
            :error="form.errors.store_url"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.client_id"
            :label="$t('panel.dashboard.integrations.salesforce.step1.client_id')"
            :error="form.errors.client_id"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.api_password"
            :type="TextInputFieldTypes.Password"
            :label="$t('panel.dashboard.integrations.salesforce.step1.api_password')"
            :error="form.errors.api_password"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_username"
            :label="$t('panel.dashboard.integrations.salesforce.step1.basic_auth_username')"
            :error="form.errors.basic_auth_username"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_password"
            :type="TextInputFieldTypes.Password"
            :label="$t('panel.dashboard.integrations.salesforce.step1.basic_auth_password')"
            :error="form.errors.basic_auth_password"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.store_id"
            :label="$t('panel.dashboard.integrations.salesforce.step1.store_id')"
            :error="form.errors.store_id"
          />
        </FormLayout>
      </FormLayout>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.salesforce.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.salesforce.activate_integration_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
