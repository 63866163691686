<script lang="ts" setup>
import { ref } from 'vue';
import {
  Banner,
  BannerVariant,
  Button,
  Card,
  CardSection,
  List,
  ListItem,
  Modal,
  ModalSize,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import { ButtonVariant } from '@app/panel/Components';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { Types } from '@app/shared/types/generated-v2';

defineProps<{
  isOwner: Types['App.Http.ViewModels.Panel.Account.ProfileViewModel']['isOwner'];
}>();

const criticalButtonVariant = ButtonVariant.Critical;
const warningBannerVariant = BannerVariant.Warning;
const modalOpened = ref(false);
const deleting = ref(false);

const deleteAccount = () => {
  router.delete(route('panel.account.delete'), {
    onStart: () => {
      deleting.value = true;
    },

    onFinish: () => {
      deleting.value = false;
    },
  });
};

function openModal() {
  modalOpened.value = true;
}

function closeModal() {
  modalOpened.value = false;
}
</script>

<template>
  <Card
    v-if="isOwner"
    :title="$t('panel.account.profile.delete_account.card_title')"
  >
    <CardSection>
      <div class="mb-5">
        <Banner
          :title="$t('panel.account.profile.delete_account.banner_title')"
          :variant="warningBannerVariant"
        >
          {{ $t('panel.account.profile.delete_account.banner_body') }}
        </Banner>
      </div>

      <TextContainer>
        <TextStyle :shade="TextStyleShade.Subdued">
          {{ $t('panel.account.profile.delete_account.card_body') }}
        </TextStyle>
      </TextContainer>

      <Button
        class="mt-5"
        :variant="criticalButtonVariant"
        @click="openModal"
      >
        {{ $t('panel.account.profile.delete_account.card_button') }}
      </Button>

      <Modal
        :open="modalOpened"
        icon-color="red"
        :size="ModalSize.Large"
        :title="$t('panel.account.profile.delete_account.modal_title')"
        @close="closeModal"
      >
        <template #icon>
          <ExclamationTriangleIcon class="h-6 w-6" />
        </template>

        <TextContainer :spacing="TextContainerSpacing.Loose">
          <TextStyle :shade="TextStyleShade.Subdued">
            <p>
              {{ $t('panel.account.profile.delete_account.modal_body') }}
            </p>
          </TextStyle>

          <TextStyle :shade="TextStyleShade.Subdued">
            <List>
              <ListItem>{{ $t('panel.account.profile.delete_account.modal_list_item_one') }}</ListItem>

              <ListItem>
                {{ $t('panel.account.profile.delete_account.modal_list_item_two') }}
              </ListItem>
            </List>
          </TextStyle>
        </TextContainer>

        <template #actions>
          <Button @click="closeModal">
            {{ $t('panel.account.profile.delete_account.modal_button_cancel') }}
          </Button>

          <Button
            :variant="criticalButtonVariant"
            :loading="deleting"
            @click="deleteAccount"
          >
            {{ $t('panel.account.profile.delete_account.modal_button_proceed') }}
          </Button>
        </template>
      </Modal>
    </CardSection>
  </Card>
</template>
