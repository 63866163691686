export default function insertIntoObjectAt(
  obj: object,
  key: string,
  value: unknown,
  index: number,
) {
  const newObj = {};
  let i = 0;

  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (i === index && key && value) {
        newObj[key] = value;
      }

      newObj[prop] = obj[prop];

      i++;
    }
  }

  if (!index && key && value) {
    newObj[key] = value;
  }

  return newObj;
}
