<script lang="ts" setup>
import { provide, ref } from 'vue';
import {
  Button,
  ButtonContent,
  ButtonIcon,
  CardLayout,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { ActivateIntegrationModal, PageNavigation } from '../../partials';
import { ShowPlatformIntegrationPageViewModel } from './types';
import { PlusIcon } from '@heroicons/vue/16/solid';
import IntegrationCard from '../../Components/IntegrationCard.vue';

const props = defineProps<ShowPlatformIntegrationPageViewModel>();

const isCreateModalOpen = ref<boolean>(props.createdPlatformIntegrationId !== null);

function closeCreateModal(): void {
  isCreateModalOpen.value = false;
}

provide('platformIntegrationTenants', props.platformIntegrationTenants);
</script>

<template>
  <AppLayout :title="$t('panel.settings:integrations:platform-integrations:title')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:integrations:platform-integrations:title') }}</PageTitle>
        <PageDescription>{{ $t('panel.settings:integrations:platform-integrations:description') }}</PageDescription>
        <template
          v-if="platformIntegrationTenants.length > 0"
          #page-header-actions
        >
          <Button
            :href="$route('panel.settings.integrations.platform.create')"
          >
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:integrations:platform-integrations:create') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <CardLayout :columns="3">
        <IntegrationCard
          v-for="integrationTenant in platformIntegrationTenants"
          :key="integrationTenant.id"
          :title="integrationTenant.platformIntegration.logo.name"
          :subtitle="integrationTenant.title"
          :description="integrationTenant.description"
          :icon="{
            alt: $t('panel.settings.integrations.platform.logo_alt'),
            src: integrationTenant.platformIntegration.logo.imageSourceUrl,
          }"
          :beta="integrationTenant.platformIntegration.isBeta"
          :action="{
            href: $route('panel.settings.integrations.platform.show', integrationTenant.id),
            title: $t('panel.settings.integrations.platform.settings_cta'),
          }"
        />
      </CardLayout>

      <ActivateIntegrationModal
        :open="isCreateModalOpen"
        :forms="props.forms"
        :created-platform-integration-id="createdPlatformIntegrationId"
        @close="closeCreateModal"
      />

      <EmptyState v-if="platformIntegrationTenants.length === 0">
        <EmptyStateContent>
          <EmptyStateTitle>{{ $t('panel.settings:integrations:platform-integrations:empty:title') }}</EmptyStateTitle>
          <EmptyStateDescription>
            {{
              $t('panel.settings:integrations:platform-integrations:empty:description')
            }}
          </EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button :href="$route('panel.settings.integrations.platform.create')">
            {{ $t('panel.settings:integrations:platform-integrations:create') }}
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>
</template>
