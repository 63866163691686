import { onMounted, onUnmounted, Ref, ref } from 'vue';
import { watchDebounced } from '@vueuse/core';

const DEBOUNCE_DELAY = 250;
const MAX_WAIT = 2000;

export function useDebounceAutocompleteItems<T>(
  modelValue: Ref<string>,
  autocompleteCallback: (searchQuery: string) => Promise<void> | void,
) {
  const autocompleteItems = ref<T[]>([]);

  onMounted(() => {
    const stopHandle = watchDebounced(modelValue, () => {
      const searchQuery = modelValue.value?.trim() ?? '';

      if (searchQuery === '' || searchQuery.length < 3) {
        autocompleteItems.value = [];
        return;
      }

      autocompleteCallback.call(null, searchQuery);
    }, {
      debounce: DEBOUNCE_DELAY,
      maxWait: MAX_WAIT,
    });

    onUnmounted(() => {
      stopHandle();
    });
  });

  return {
    autocompleteItems,
  };
}
