<script lang="ts" setup>
import {
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  Select,
  SelectOption,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { useOnboarding } from '../../composables';
import { App, type Types } from '@app/shared/types/generated-v2';

type CountryViewModel = Types['App.Models.ViewModels.CountryViewModel'];
type TenantViewModel = Types['App.Models.ViewModels.TenantViewModel'];
type ReturnAddressViewModel = Types['App.Models.ViewModels.ReturnAddressViewModel'];

const props = defineProps<{
  countries: CountryViewModel[];
  tenant: TenantViewModel;
  returnAddress?: ReturnAddressViewModel;
}>();

const { setLastCompletedStep, changeStep } = useOnboarding();

const stateType = App.Enums.OnboardingStateType.HasReturnAddress;

const primaryReturnAddress = useForm({
  company_name: props.returnAddress?.companyName ?? props.tenant.name,
  name: props.returnAddress?.name,
  telephone: props.returnAddress?.telephone,
  street: props.returnAddress?.street,
  house_number: props.returnAddress?.houseNumber,
  suffix: props.returnAddress?.suffix,
  postal_code: props.returnAddress?.postalCode,
  city: props.returnAddress?.city,
  country_id: props.tenant.country?.id || props.returnAddress?.country.id,
  is_primary: true,
});

const countryList = computed<SelectOption[]>(() => {
  return props.countries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

function setPrimaryReturnAddress(): void {
  primaryReturnAddress.post(route('panel.onboarding.return-address'), {
    preserveState: true,
    onSuccess: () => setLastCompletedStep(stateType),
    onError: () => changeStep(stateType),
  });
}
</script>

<template>
  <Form @submit="setPrimaryReturnAddress">
    <Card
      :primary-footer-action="{
        content: $t('panel.dashboard.primary_return_address.primary_card_action'),
        type: ButtonType.Submit,
        loading: primaryReturnAddress.processing
      }"
    >
      <CardSection>
        <FormLayout>
          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="primaryReturnAddress.company_name"
                :error="primaryReturnAddress.errors.company_name"
                :type="TextInputFieldTypes.Text"
                :label="$t('panel.dashboard.primary_return_address.forms.company_name_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="primaryReturnAddress.telephone"
                :error="primaryReturnAddress.errors.telephone"
                :type="TextInputFieldTypes.Text"
                :label="$t('panel.dashboard.primary_return_address.forms.phone_label')"
              />
            </FormLayout>
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="primaryReturnAddress.street"
                :error="primaryReturnAddress.errors.street"
                :type="TextInputFieldTypes.Text"
                :label="$t('panel.dashboard.primary_return_address.forms.street_label')"
              />
            </FormLayout>

            <FormLayout
              :columns="1"
              :columns-sm="2"
            >
              <FormLayout>
                <TextInput
                  v-model="primaryReturnAddress.house_number"
                  :error="primaryReturnAddress.errors.house_number"
                  :type="TextInputFieldTypes.Text"
                  :label="$t('panel.dashboard.primary_return_address.forms.house_number_label')"
                />
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="primaryReturnAddress.suffix"
                  :error="primaryReturnAddress.errors.suffix"
                  :type="TextInputFieldTypes.Text"
                  :label="$t('panel.dashboard.primary_return_address.forms.suffix_label')"
                />
              </FormLayout>
            </FormLayout>
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-md="2"
          >
            <FormLayout
              :columns="1"
              :columns-sm="2"
            >
              <FormLayout>
                <TextInput
                  v-model="primaryReturnAddress.postal_code"
                  :error="primaryReturnAddress.errors.postal_code"
                  :type="TextInputFieldTypes.Text"
                  :label="$t('panel.dashboard.primary_return_address.forms.postcode_label')"
                />
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="primaryReturnAddress.city"
                  :error="primaryReturnAddress.errors.city"
                  :type="TextInputFieldTypes.Text"
                  :label="$t('panel.dashboard.primary_return_address.forms.city_label')"
                />
              </FormLayout>
            </FormLayout>

            <FormLayout>
              <Select
                v-model="primaryReturnAddress.country_id"
                :error="primaryReturnAddress.errors.country_id"
                :label="$t('panel.dashboard.primary_return_address.forms.country_label')"
                :options="countryList"
              />
            </FormLayout>
          </FormLayout>
        </FormLayout>
      </CardSection>
    </Card>
  </Form>
</template>
