<script lang="ts" setup>
import { Avatar, AvatarGroup, DataTable } from '@app/panel/Components';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Types } from '@app/shared/types/generated-v2';

type FormStoreLocationItemViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FormStoreLocationItemViewModel'];
type FormViewModel = Types['App.Models.ViewModels.FormViewModel'];

const { t } = useI18n();

const props = defineProps<{
  dataTableItems: FormStoreLocationItemViewModel[];
  selectedDataTableItems: FormStoreLocationItemViewModel[];
  form: FormViewModel;
}>();

const emit = defineEmits<{
  'update:dataTableItems': [value: FormStoreLocationItemViewModel[]];
  'update:selectedDataTableItems': [value: FormStoreLocationItemViewModel[]];
}>();

const computedDataTableItems = computed({
  get: () => props.dataTableItems,
  set: (value) => emit('update:dataTableItems', value),
});

const computedSelectedDataTableItems = computed({
  get: () => props.selectedDataTableItems,
  set: (value) => emit('update:selectedDataTableItems', value),
});

const dataTableHeadings = ref([
  { value: 'icon', text: t('panel.settings:forms:store-locations:table_headings:icon'), column: 'icon', sortable: false },
  { value: 'name', text: t('panel.settings:forms:store-locations:table_headings:name'), column: 'name', sortable: false },
  { value: 'city', text: t('panel.settings:forms:store-locations:table_headings:city'), column: 'city', sortable: false },
  { value: 'country', text: t('panel.settings:forms:store-locations:table_headings:country'), column: 'country', sortable: false },
]);

function onCheck(checkedItems: FormStoreLocationItemViewModel[]) {
  computedSelectedDataTableItems.value = checkedItems;
}
</script>

<template>
  <DataTable
    v-if="computedDataTableItems"
    checkable
    :model-value="computedSelectedDataTableItems"
    :headings="dataTableHeadings"
    :items="computedDataTableItems"
    @update:model-value="onCheck"
  >
    <template #icon="{ item } : { item: FormStoreLocationItemViewModel }">
      <span
        v-if="!item.imageSrc"
        class="text-slate-300"
      >
        &mdash;
      </span>

      <AvatarGroup v-if="item.imageSrc">
        <Avatar
          :src="item.imageSrc"
        />
      </AvatarGroup>
    </template>
  </DataTable>
</template>
