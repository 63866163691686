<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Heading,
  List,
  ListItem,
  Modal,
  TextContainer,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import {
  ArrowRightCircleIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/24/outline';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import { onMounted, PropType } from 'vue';

type PlatformIntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;

type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<PlatformIntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

const emit = defineEmits(['verify']);

const {
  currentStep,
  firstStep,
  lastStep,
  nextStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function ccdShopNextStep(): void {
  nextStep();
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}

function emitVerify(): void {
  emit('verify');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.dashboard.integrations.ccvshop.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <TextContainer text-center>
      <TextStyle :shade="TextStyleShade.Subdued">
        {{ $t('panel.dashboard.integrations.ccvshop.description') }}
      </TextStyle>
    </TextContainer>

    <div v-if="currentStep === 1">
      <div class="space-y-1 mt-6">
        <TextStyle
          bold
          :font-size="TextStyleFontSize.Base"
        >
          {{ $t('panel.dashboard.integrations.ccvshop.step') }} 1
        </TextStyle>

        <TextStyle>
          <List marker-subdued>
            <ListItem>{{ $t('panel.dashboard.integrations.ccvshop.step1.item1') }}</ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.ccvshop.step1.item2.part1') }}
            </ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.ccvshop.step1.item3.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.ccvshop.step1.item3.part2') }}</strong>
            </ListItem>
          </List>
        </TextStyle>
      </div>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.ccvshop.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="emitVerify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.ccvshop.verify_integration_button') }}</span>
        </Button>

        <Button
          v-else
          full-width
          :variant="ButtonVariant.Primary"
          @click="ccdShopNextStep"
        >
          <template #icon>
            <ArrowRightCircleIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.ccvshop.next_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
