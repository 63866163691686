import { type FormStructure, FormStructureFieldType } from '@app/panel/Pages/Settings/Integrations/types';
import { computed } from 'vue';

export function useIntegrations(formStructure) {
  function getFieldsByBlock(formStructure: FormStructure): Record<string, string> {
    return formStructure.fields.reduce((acc, field) => {
      if (field.type === FormStructureFieldType.String || field.type === FormStructureFieldType.Password) {
        acc[field.key] = '';
      }

      if (field.type === FormStructureFieldType.Checkbox) {
        acc[field.key] = null;
      }

      if (field.type === FormStructureFieldType.Select) {
        acc[field.key] = field.default ?? null;
      }

      if (field.type === FormStructureFieldType.MultiSelect) {
        acc[field.key] = field.default ?? [];
      }

      if (field.type === FormStructureFieldType.Repeater) {
        acc[field.key] = null;
      }

      return acc;
    }, {});
  }

  const settingsScaffold = computed(() => {
    return formStructure.reduce((acc, block) => {
      return ({ ...acc, ...getFieldsByBlock(block) });
    }, {});
  });

  return { settingsScaffold };
}
