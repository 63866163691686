<script lang="ts" setup>
import { computed } from 'vue';
import { Link } from '../index';
import { PlusIcon } from '@heroicons/vue/24/solid';
import { theme } from '../../Composables/useTheme';

const props = withDefaults(defineProps<{
  href?: string | null;
  title?: string | null;
}>(), {
  href: null,
  title: null,
});

const dynamicComponent = computed(() => props.href !== null ? Link : 'button');

const border = computed(() => {
  return theme(['border', 'hover-border']);
});
</script>

<template>
  <component
    :is="dynamicComponent"
    :href="href"
    class="group rounded-lg border border-dashed overflow-hidden h-full w-full shadow-sm cursor-pointer outline-none"
    :class="border"
  >
    <div class="h-full w-full flex justify-center items-center ">
      <div class="text-sm">
        <PlusIcon class="h-10 w-10 text-slate-300 mx-auto mb-1 group-hover:text-slate-400" />

        <span
          v-if="title"
          class="text-slate-400 group-hover:text-slate-500"
        >
          {{ title }}
        </span>
      </div>
    </div>
  </component>
</template>
