<script lang="ts" setup>
import { computed } from 'vue';
import {
  Banner,
  BannerVariant,
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  Select,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { Types } from '@app/shared/types/generated-v2';

type Tenant = Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
type Countries = Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['countries'];

const props = defineProps<{
  tenant: Tenant;
  countries: Countries;
}>();

const invoiceDetailsForm = useForm({
  country: props.tenant.country?.id,
  name: props.tenant.name,
  invoice_email: props.tenant.invoiceEmail,
  street: props.tenant.street,
  postcode: props.tenant.postcode,
  city: props.tenant.city,
  po_number: props.tenant.poNumber,
  vat_number: props.tenant.vatNumber,
});

const countryList = computed(() => {
  return props.countries.map((country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });
});

function updateBillingDetails(): void {
  invoiceDetailsForm.put(route('panel.account.update-billing-details'));
}

const isTaxVerificationBannerOpen = computed(() => {
  return props.tenant.taxVerificationState === 'pending'
    || props.tenant.taxVerificationState === 'unverified'
    || props.tenant.taxVerificationState === 'unavailable';
});
</script>

<template>
  <Form @submit="updateBillingDetails">
    <Card
      :title="$t('panel.account.billing_and_subscription.invoice_details.card_title')"
      :subtitle="$t('panel.account.billing_and_subscription.invoice_details.card_subtitle')"
      :primary-footer-action="{
        content: `${$t('panel.account.billing_and_subscription.invoice_details.card_cta_content')}`,
        type: ButtonType.Submit,
        loading: invoiceDetailsForm.processing,
      }"
    >
      <CardSection>
        <div
          v-if="isTaxVerificationBannerOpen"
          class="mb-6"
        >
          <Banner
            v-if="tenant.taxVerificationState === 'pending'"
            :variant="BannerVariant.Info"
          >
            {{ $t('panel.account.billing_and_subscription.invoice_details.info_banner') }}
          </Banner>
        </div>

        <FormLayout>
          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="invoiceDetailsForm.name"
                :label="$t('panel.account.billing_and_subscription.invoice_details.company_name_input_label')"
                :type="TextInputFieldTypes.Text"
                :error="invoiceDetailsForm.errors.name"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="invoiceDetailsForm.invoice_email"
                :label="$t('panel.account.billing_and_subscription.invoice_details.invoice_email_input_label')"
                :type="TextInputFieldTypes.Email"
                :error="invoiceDetailsForm.errors.invoice_email"
              />
            </FormLayout>
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="invoiceDetailsForm.street"
                :label="$t('panel.account.billing_and_subscription.invoice_details.street_housenumber_input_label')"
                :type="TextInputFieldTypes.Text"
                :error="invoiceDetailsForm.errors.street"
              />
            </FormLayout>

            <FormLayout
              :columns="1"
              :columns-sm="2"
            >
              <FormLayout>
                <TextInput
                  v-model="invoiceDetailsForm.postcode"
                  :label="$t('panel.account.billing_and_subscription.invoice_details.postcode_input_label')"
                  :type="TextInputFieldTypes.Text"
                  :error="invoiceDetailsForm.errors.postcode"
                />
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="invoiceDetailsForm.city"
                  :label="$t('panel.account.billing_and_subscription.invoice_details.city_input_label')"
                  :type="TextInputFieldTypes.Text"
                  :error="invoiceDetailsForm.errors.city"
                />
              </FormLayout>
            </FormLayout>
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <Select
                v-model="invoiceDetailsForm.country"
                :options="countryList"
                :label="$t('panel.account.billing_and_subscription.invoice_details.country_select_label')"
                :error="invoiceDetailsForm.errors.country"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="invoiceDetailsForm.vat_number"
                :label="$t('panel.account.billing_and_subscription.invoice_details.vat_number_input_label')"
                :type="TextInputFieldTypes.Text"
                :error="invoiceDetailsForm.errors.vat_number"
              />
            </FormLayout>
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <TextInput
              v-model="invoiceDetailsForm.po_number"
              :label="$t('panel.account.billing_and_subscription.invoice_details.po_number_input_label')"
              :type="TextInputFieldTypes.Text"
              :error="invoiceDetailsForm.errors.po_number"
            />
          </FormLayout>
        </FormLayout>
      </CardSection>
    </Card>
  </Form>
</template>
