<script setup lang="ts">
import { Button, ButtonType, ButtonVariant, Form, FormLayout, Modal, Stack, TextInput } from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import { App } from '@app/shared/types/generated-v2';

defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  close: [];
}>();

const form = useForm({
  key: '',
  value: '',
});

const returnOrder = inject<App.Models.ViewModels.ReturnOrderViewModel>('returnOrder');

const submit = () => {
  form.post(route('panel.return-orders.metadata.create', {
    returnOrder: returnOrder.value.id,
  }), {
    onSuccess: close,
    preserveScroll: true,
  });
};

const close = () => {
  form.reset();
  emit('close');
};
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.returns:detail:add_metadata_modal:modal_title')"
    @close="close"
  >
    <Form @submit="submit">
      <Stack vertical>
        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <TextInput
              v-model="form.key"
              :error="form.errors.key"
              :label="$t('panel.returns:detail:metadata:manage:key_field')"
              :max-length="255"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.value"
              :error="form.errors.value"
              :label="$t('panel.returns:detail:metadata:manage:value_field')"
              :max-length="255"
            />
          </FormLayout>
        </FormLayout>
      </Stack>
    </Form>

    <template #actions>
      <Button @click="close">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
        @click="submit"
      >
        {{ $t('panel.returns:detail:metadata:manage:create') }}
      </Button>
    </template>
  </Modal>
</template>
