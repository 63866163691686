import confetti from 'canvas-confetti';

export function confettiService() {
  function fire(particleRatio, opts, target) {
    const { bottom, left, right } = target.getBoundingClientRect();

    const count = 200;
    const defaults = {
      angle: 80,
      gravity: 1.5,
      origin: {
        x: ((left + right) / 2) / window.innerWidth,
        y: bottom / window.innerHeight,
      },
    };

    confetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio),
    }));
  }

  function startConfettiAnimation(target: HTMLElement) {
    fire(0.25, {
      angle: 90,
      spread: 40,
      startVelocity: 20,
    }, target);

    fire(0.2, {
      angle: 90,
      spread: 60,
      startVelocity: 20,
    }, target);

    fire(0.35, {
      angle: 90,
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
      startVelocity: 20,
    }, target);

    fire(0.1, {
      angle: 90,
      spread: 120,
      decay: 0.92,
      scalar: 1.2,
      startVelocity: 20,
    }, target);
  }

  return {
    startConfettiAnimation,
  };
}
