<script lang="ts" setup>
import {
  SimpleTable,
  SimpleTableCell,
  SimpleTableRow,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { Types } from '@app/shared/types/generated-v2';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { ArrowLongDownIcon, ArrowLongUpIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';

defineProps<{
  features: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['features'];
}>();

const open = ref<boolean>(false);

function toggle(): void {
  open.value = !open.value;
}
</script>

<template>
  <div>
    <div v-if="open">
      <SimpleTable>
        <SimpleTableRow>
          <SimpleTableCell>
            <TextStyle
              :font-size="TextStyleFontSize.Large"
              :shade="TextStyleShade.Dense"
              strong
            >
              {{ $t('panel.account:billing:features:title') }}
            </TextStyle>
          </SimpleTableCell>
        </SimpleTableRow>
        <SimpleTableRow
          v-for="(feature, key, rowIndex) in features"
          :key="key"
          :subdued="rowIndex % 2 === 0"
        >
          <SimpleTableCell>
            <span>{{ $t(key) }}</span>
          </SimpleTableCell>
          <SimpleTableCell
            v-for="(value, columnIndex) in feature"
            :key="columnIndex"
          >
            <div class="flex justify-center">
              <span v-if="value.label">{{ $t(value.label) }}</span>
              <CheckCircleIcon
                v-if="value.check"
                class="size-6 fill-brand-500"
              />
              <span
                v-if="!value.label && !value.check"
                class="text-slate-300"
              >
                &mdash;
              </span>
            </div>
          </SimpleTableCell>
        </SimpleTableRow>
      </SimpleTable>
    </div>

    <div
      tabindex="0"
      class="cursor-pointer bg-slate-50 p-4 text-slate-500 hover:text-slate-700"
      @click="toggle"
    >
      <div class="flex items-center justify-center space-x-1 text-sm">
        <ArrowLongUpIcon
          v-if="open"
          class="size-4"
        />
        <ArrowLongDownIcon
          v-else
          class="size-4"
        />
        <div>
          <span v-if="open">{{ $t('panel.account:billing:features:collapse') }}</span>
          <span v-else>{{ $t('panel.account:billing:features:compare') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
