export function cleanObject(input: Record<string, any>): Record<string, any> {
  const cleanedObject: Record<string, any> = {};

  Object
    .entries(input)
    .forEach(([key, value]): void => {
      if (value === '' || value === null || value === undefined) {
        return;
      }

      cleanedObject[key] = typeof value === 'object'
        ? cleanObject(value)
        : value;
    });

  return cleanedObject;
}
