<script lang="ts" setup>
import { computed, inject } from 'vue';
import { Banner, BannerVariant, Button, List, ListItem, Select } from '@app/panel/Components';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { PlusIcon } from '@heroicons/vue/24/solid';
import * as Types from '@app/panel/types/generated';
import { InertiaForm } from '@inertiajs/vue3';
import { ReturnForm } from '@app/panel/Containers/ConditionEngine';

type ActionOption = {
  label: string;
  value: string;
  type: Types.App.Enums.ActionOptionType;
  options: null | { label: string; value: string }[];
};

type Action = { type: null | string; value: null | string };

const props = defineProps<{
  actionOptions: ActionOption[];
  modelValue: Action[];
  errors: Record<string, string>;
}>();

const emit = defineEmits<{
  (e: 'update:value', value: Action[]): void;
}>();

const returnRuleActions = computed({
  get: () => props.modelValue,
  set: (newValue) => emit('update:value', newValue),
});

const returnRuleForm = inject<InertiaForm<ReturnForm>>('returnForm');

function addActionRow() {
  returnRuleActions.value.push({
    type: null,
    value: null,
  });
}

function getAvailableOptions(action: Action) {
  return props.actionOptions.filter((actionOption) => {
    if (action.type === actionOption.value) {
      return true;
    }

    const actionIsSelected = returnRuleActions.value.some((action) => action.type === actionOption.value);

    if (actionOption.type === 'unique-type' && actionIsSelected) {
      return false;
    }

    return true;
  });
}

function getOptionsOfAction(action: undefined | Action): Array | null {
  const actionOption = props.actionOptions.find((actionOption) => actionOption.value === action?.type);

  if (actionOption?.options === null) {
    return null;
  }

  const options = actionOption?.options ?? [];

  if (actionOption?.type !== 'unique-value') {
    return options;
  }

  const selectedOptionOfTheSameAction = returnRuleActions.value
    .filter((returnRuleAction) => returnRuleAction.type === actionOption?.value)
    .flatMap((a) => a.value);

  return options.filter((option) => {
    if (option.value === action.value) {
      return true;
    }

    return !selectedOptionOfTheSameAction.includes(option.value);
  });
}

function resetActionValue(action: Action) {
  action.value = null;
}

function removeActionByIndex(index: number) {
  returnRuleActions.value.splice(index, 1);
}

</script>

<template>
  <div class="flex flex-col space-y-4 items-start">
    <Banner
      v-if="returnRuleForm.errors['action_items'] ?? null"
      :variant="BannerVariant.Critical"
      class="w-full"
    >
      <List>
        <ListItem>{{ returnRuleForm.errors['action_items'] }}</ListItem>
      </List>
    </Banner>

    <div
      v-for="(action, index) in returnRuleActions"
      :key="index"
      class="flex w-full space-x-3 items-start"
    >
      <div class="flex-1">
        <Select
          v-model="action.type"
          :options="getAvailableOptions(action)"
          :error="errors[`action_items.${index}.type`]"
          @change="resetActionValue(action)"
        />
      </div>
      <div
        v-if="getOptionsOfAction(action) !== null"
        class="flex-1"
      >
        <Select
          v-model="action.value"
          :options="getOptionsOfAction(action)"
          :error="errors[`action_items.${index}.value`]"
          :disabled="getOptionsOfAction(action).length === 0"
        />
      </div>

      <div
        class="shrink-0"
      >
        <TrashIcon
          class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-600"
          @click="removeActionByIndex(index)"
        />
      </div>
    </div>
    <Button
      @click="addActionRow"
    >
      <template #icon>
        <PlusIcon />
      </template>
      <span>{{ $t('panel.settings:form:return-rules:create:action:add') }}</span>
    </Button>
  </div>
</template>
