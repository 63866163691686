import type { Types } from '@app/shared/types/generated-v2';
import { map } from 'lodash';
import { computed, onMounted } from 'vue';

type TagViewModel = Types['App.Models.ViewModels.TagViewModel'];

interface Taggable {
  tags: string[];
}

export function useTags(tags: TagViewModel[], form: Taggable, initialTags: TagViewModel[] = []) {
  const activeTags = computed<TagViewModel[]>(() => {
    return tags.filter((tag) => form.tags.includes(tag.id));
  });

  function removeTag(tag: TagViewModel): void {
    form.tags = form.tags.filter((tagId) => {
      return tagId !== tag.id;
    });
  }

  onMounted(() => {
    form.tags = map(initialTags, 'id');
  });

  return { activeTags, removeTag };
}
