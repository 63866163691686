import { type Types } from '@app/shared/types/generated-v2';
import { computed, Ref } from 'vue';

type ReturnOrderItemViewModel = Types['App.Models.ViewModels.ReturnOrderItemViewModel'];
type SalesOrderItemViewModel = Types['App.Models.ViewModels.SalesOrderItemViewModel'];
type SalesOrderViewModel = Types['App.Models.ViewModels.SalesOrderViewModel'];

export function useTrait(salesOrder: SalesOrderViewModel, returnOrderItems: Ref<ReturnOrderItemViewModel[]>) {
  const valueOfReturnedItems = computed(() => {
    return returnOrderItems.value
      .reduce((acc, returnOrderItem: ReturnOrderItemViewModel): number => {
        const salesOrderItem = findBestMatchingSalesOrderItem(salesOrder, returnOrderItem);

        const quantity = returnOrderItem.quantityReceived - returnOrderItem.quantityRefunded;

        if (quantity <= 0) {
          return acc;
        }

        return acc + (salesOrderItem.price.amount * quantity);
      }, 0);
  });

  /**
   * This function should match the ReturnOrderItem@getMatchingSalesOrderItem in the backend
   */
  function findBestMatchingSalesOrderItem(salesOrder: SalesOrderViewModel, returnOrderItem: ReturnOrderItemViewModel): SalesOrderItemViewModel {
    return salesOrder.salesOrderItems.find((salesOrderItem: SalesOrderItemViewModel) => {
      if (salesOrderItem.id === returnOrderItem.salesOrderItem.id) {
        return true;
      }
      return salesOrderItem.compatibleSalesOrderItemId === returnOrderItem.salesOrderItem.id;
    });
  }

  return { valueOfReturnedItems };
}
