import { isEmpty } from 'lodash';
import { nextTick, ref, watch } from 'vue';

export function useDragAndDrop(tableId: string, items, active = ref(true)) {
  let table;

  let isDraggingStarted = false;
  let draggingRowIndex;
  let draggingElement;
  let placeholder;

  let y = 0;

  let list;
  let newTbody;

  const sortedItems = ref(items.value);

  const swap = function (nodeA, nodeB) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    nodeB.parentNode.insertBefore(nodeA, nodeB);

    parentA.insertBefore(nodeB, siblingA);
  };

  const isAbove = function (nodeA, nodeB) {
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();

    return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
  };

  const mouseDownHandler = function (e) {
    const originalRow = e.target.closest('tr');
    draggingRowIndex = [].slice.call(table.querySelectorAll('tr')).indexOf(originalRow);

    y = e.clientY;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const createPlaceholderElement = () => {
    placeholder = document.createElement('div');
    placeholder.classList.add('bg-slate-50');
    draggingElement.parentNode.insertBefore(placeholder, draggingElement.nextSibling);
    placeholder.style.height = `${draggingElement.offsetHeight}px`;

    return placeholder;
  };

  const mouseMoveHandler = function (e) {
    if (!isDraggingStarted) {
      isDraggingStarted = true;
      cloneTable();

      const allRows = [list.children[0]].concat([...newTbody.children]);
      draggingElement = [].slice.call(allRows)[draggingRowIndex];

      placeholder = createPlaceholderElement();
    }

    draggingElement.style.backgroundColor = 'white';
    draggingElement.style.position = 'absolute';
    draggingElement.classList.add('z-50', 'shadow-lg');
    draggingElement.style.top = `${draggingElement.offsetTop + e.clientY - y}px`;

    y = e.clientY;

    const prevEle = draggingElement.previousElementSibling;
    const nextEle = placeholder.nextElementSibling;

    if (prevEle && isAbove(draggingElement, prevEle)) {
      swap(placeholder, draggingElement);
      swap(placeholder, prevEle);
      return;
    }

    if (nextEle && isAbove(nextEle, draggingElement)) {
      swap(nextEle, placeholder);
      swap(nextEle, draggingElement);
    }
  };

  const cloneTable = function () {
    const width = parseInt(window.getComputedStyle(table).width);

    list = document.createElement('div');
    list.style.position = 'absolute';

    const tbody = table.querySelector('tbody');
    newTbody = document.createElement('tbody');
    newTbody.classList.add(...tbody.classList);

    table.parentNode.insertBefore(list, table);
    table.style.visibility = 'hidden';

    table.querySelectorAll('tr').forEach(function (row) {
      const item = document.createElement('div');

      const newTable = document.createElement('table');
      newTable.setAttribute('class', 'clone-table');
      newTable.style.width = `${width}px`;

      const newRow = document.createElement('tr');
      newRow.classList.add(...row.classList);

      const cells = [].slice.call(row.children);

      cells.forEach(function (cell) {
        const newCell = cell.cloneNode(true);
        newCell.style.width = `${parseInt(window.getComputedStyle(cell).width)}px`;
        newCell.classList.add(...cell.classList);

        const thead = cell.closest('thead');

        if (thead !== null) {
          const newThead = document.createElement('thead');
          newThead.classList.add(...thead.classList);

          newRow.appendChild(newCell);
          newThead.appendChild(newRow);
          newTable.appendChild(newThead);
          item.appendChild(newTable);

          list.appendChild(item);

          return;
        }

        newCell.innerHTML = cell.innerHTML;

        const shadowInputs = newCell.querySelectorAll('input');
        const realInputs = cell.querySelectorAll('input');

        shadowInputs.forEach((shadowInput: HTMLInputElement, index: number) => {
          shadowInput.value = realInputs[index].value;
        });

        newRow.appendChild(newCell);

        newTable.appendChild(newRow);
        item.appendChild(newTable);

        newTbody.appendChild(item);
      });

      list.appendChild(newTbody);
    });
  };

  function getSortedItems() {
    const tableBodyElement = document.getElementById(tableId)?.querySelector('tbody') as HTMLElement;
    const rows = Array.from(tableBodyElement?.children || []) as HTMLTableRowElement[];

    sortedItems.value = rows.map((row) => {
      return items.value.find((item) => item.id === row.getAttribute('id'));
    });
  }

  function removeEventHandlers() {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  }

  const mouseUpHandler = function () {
    if (!draggingElement) {
      removeEventHandlers();

      return;
    }

    placeholder.parentNode?.removeChild(placeholder);

    draggingElement.classList.remove('dragging');
    draggingElement.style.removeProperty('top');
    draggingElement.style.removeProperty('left');
    draggingElement.style.removeProperty('position');

    const allRows = [list.children[0]].concat([...newTbody.children]);
    const endRowIndex = [].slice.call(allRows).indexOf(draggingElement);

    isDraggingStarted = false;
    list.parentNode?.removeChild(list);

    const rows = [].slice.call(table.querySelectorAll('tr'));

    rows[endRowIndex].parentNode.insertBefore(
      rows[draggingRowIndex],
      draggingRowIndex > endRowIndex ? rows[endRowIndex] : rows[endRowIndex].nextSibling,
    );

    table.style.removeProperty('visibility');

    removeEventHandlers();

    getSortedItems();
  };

  function makeTableDraggable() {
    nextTick(() => {
      table = document.getElementById(tableId) as HTMLTableElement;

      if (table === null) {
        return;
      }

      const thead = table.querySelector('thead');
      const isTheadPresent = document.body.contains(thead);

      if (!isTheadPresent) {
        const newThead = document.createElement('thead');
        const newTheadRow = document.createElement('tr');
        newThead.appendChild(newTheadRow);
        newTheadRow.classList.add('hidden');
        table.insertBefore(newThead, table.firstChild);
      }

      table.querySelectorAll('#draggable-icon').forEach((draggableIcon) => {
        draggableIcon.classList.add(
          'cursor-move',
          'select-none',
        );
        draggableIcon.addEventListener('mousedown', mouseDownHandler);
      });
    });
  }

  watch([items, active], () => {
    if (!isEmpty(items.value) && active.value) {
      makeTableDraggable();
    }
  }, { immediate: true, deep: true });

  return { sortedItems };
}
