<script lang="ts" setup>
import { ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';

defineProps<{
  title: string;
}>();

const isOpen = ref(false);

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <li
    class="flex flex-col"
  >
    <div
      class="w-full flex space-x-2 items-center p-4 cursor-pointer"
      @click="toggle"
    >
      <div class="flex-1">
        {{ title }}
      </div>

      <div class="shrink-0">
        <ChevronDownIcon
          class="w-4 h-4 text-slate-500 transition-transform"
          :class="{
            'rotate-180': isOpen,
          }"
        />
      </div>
    </div>

    <div
      v-if="isOpen"
      class="border-t p-4"
    >
      <slot />
    </div>
  </li>
</template>
