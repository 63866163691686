<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Card,
  CardSection,
  DataTable,
  Form,
  Modal,
  TextContainer,
  TextInput,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import { PasswordConfirmation } from '@app/panel/Containers';
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import type { Types } from '@app/shared/types/generated-v2';

type PartnerManagementViewModel = Types['App.Http.ViewModels.Panel.Account.PartnerManagementViewModel'];

defineProps<{
  tenant: PartnerManagementViewModel['tenant'];
  partners: PartnerManagementViewModel['partners'];
}>();

const showRevokeAccessModal = ref(false);
const currentItem = ref(null);

function revokeAccess(tenant): void {
  showRevokeAccessModal.value = true;
  currentItem.value = tenant;
}

function closeRevokeAccessModal() {
  showRevokeAccessModal.value = false;
  currentItem.value = null;
}

function confirmRevokeAccess(item) {
  router.delete(route('panel.partner.revoke-access', { partner: item.id }), {
    onFinish: () => {
      closeRevokeAccessModal();
    },
  });
}

function enablePartnerAccess(): void {
  router.post(route('panel.partner.enable-access'));
}

const form = useForm({
  email: '',
});

const showShareInviteModal = ref(false);

function openShareInviteModal(): void {
  showShareInviteModal.value = true;
}

function closeShareInviteModal(): void {
  showShareInviteModal.value = false;
}

function shareInvite(): void {
  form.post(route('panel.partner.send-invite'), {
    preserveScroll: true,
    onSuccess: () => {
      showShareInviteModal.value = false;
      form.reset();
    },
  });
}
</script>

<template>
  <Card
    :title="$t('panel.account:partner:tenant-partner-access:page_title')"
    :subtitle="$t('panel.account:partner:tenant-partner-access:page_subtitle_title')"
  >
    <CardSection v-if="!tenant.hasEnabledPartnerAccess">
      <PasswordConfirmation @confirmed="enablePartnerAccess">
        <Button :variant="ButtonVariant.Primary">
          {{ $t('panel.account:partner:tenant-partner-access:button:enable_label') }}
        </Button>
      </PasswordConfirmation>
    </CardSection>
    <div v-if="tenant.hasEnabledPartnerAccess">
      <CardSection>
        <DataTable
          :items="partners"
          :headings="[{
            text: $t('panel.account:partner:tenant-partner-access:table:heading'),
            value: 'name'
          }]"
        >
          <template #actions="{ item }">
            <Button
              plain
              :variant="ButtonVariant.Critical"
              @click="revokeAccess(item)"
            >
              {{ $t('panel.account:partner:tenant-partner-access:button:revoke_label') }}
            </Button>
          </template>
        </DataTable>
      </CardSection>
      <CardSection>
        <TextContainer>
          {{ $t('panel.account:partner:tenant-partner-access:verification_code_info') }}
        </TextContainer>

        <div class="flex w-full justify-between mt-8">
          <div class="w-1/2">
            <div class="bg-slate-100 text-xl font-medium p-4 rounded-md font-mono items-center justify-center flex h-full">
              {{ tenant.partnerVerificationCode }}
            </div>
          </div>
          <div class="w-1/2 text-center space-y-2">
            <TextContainer>
              {{ $t('panel.account:partner:tenant-partner-access:invite_via_email_label') }}
            </TextContainer>
            <Button @click="openShareInviteModal">
              {{ $t('panel.account:partner:tenant-partner-access:invite_via_email_button_label') }}
            </Button>
          </div>
        </div>
      </CardSection>
    </div>
  </Card>

  <Modal
    :open="showRevokeAccessModal"
    title="Revoke access"
    @close="closeRevokeAccessModal"
  >
    {{ $t('panel.account:partner:tenant-partner-access:revoke:confirmation_info', {name: currentItem.name}) }}
    <template #actions>
      <Button @click="closeRevokeAccessModal">
        {{ $t('panel.account:partner:tenant-partner-access:revoke:cancel_button') }}
      </Button>
      <Button
        :variant="ButtonVariant.Critical"
        @click="confirmRevokeAccess(currentItem)"
      >
        {{ $t('panel.account:partner:tenant-partner-access:revoke:revoke_button') }}
      </Button>
    </template>
  </Modal>

  <Modal
    :open="showShareInviteModal"
    :title="$t('panel.account:partner:tenant-partner-access:invite:title')"
    @close="closeShareInviteModal"
  >
    <div class="mb-4">
      {{ $t('panel.account:partner:tenant-partner-access:invite:subtitle') }}
    </div>
    <Form @submit="shareInvite">
      <TextInput
        v-model="form.email"
        :label="$t('panel.account:partner:tenant-partner-access:invite:email')"
        :error="form.errors.email"
      />
    </Form>
    <template #actions>
      <div class="flex flex-1 justify-between">
        <Button @click="closeShareInviteModal">
          {{ $t('panel.account:partner:tenant-partner-access:invite:cancel') }}
        </Button>
        <Button
          :variant="ButtonVariant.Primary"
          @click="shareInvite"
        >
          {{ $t('panel.account:partner:tenant-partner-access:invite:send') }}
        </Button>
      </div>
    </template>
  </Modal>
</template>
