<script lang="ts" setup>
import {
  Card,
  CardSection,
  IconSize,
  TextContainer,
  Timeline,
  TimelineItem,
} from '@app/panel/Components';
import {
  ArchiveBoxArrowDownIcon,
  ArrowUturnRightIcon,
  ChatBubbleBottomCenterTextIcon,
  CurrencyEuroIcon,
  DocumentCheckIcon,
  DocumentMagnifyingGlassIcon,
  EnvelopeIcon,
  ForwardIcon,
  HandThumbUpIcon,
  PencilIcon,
  TagIcon,
  TicketIcon,
  XMarkIcon,
} from '@heroicons/vue/24/outline';
import { ArrowDownIcon, ArrowUpIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { isEmpty } from 'lodash';
import { computed } from 'vue';
import * as Types from '@app/panel/types/generated';
import Link from '@app/panel/Components/Link/components/Link.vue';

const props = defineProps<{
  items: Array<Types.App.Models.ViewModels.ReturnOrderEventViewModel>;
}>();

const iconList = {
  archiveBoxArrowDown: ArchiveBoxArrowDownIcon,
  arrowUturnRightIcon: ArrowUturnRightIcon,
  check: CheckIcon,
  currencyEuro: CurrencyEuroIcon,
  document: DocumentMagnifyingGlassIcon,
  documentCheckIcon: DocumentCheckIcon,
  down: ArrowDownIcon,
  forward: ForwardIcon,
  mail: EnvelopeIcon,
  note: ChatBubbleBottomCenterTextIcon,
  pencil: PencilIcon,
  tag: TagIcon,
  thumbsUp: HandThumbUpIcon,
  ticket: TicketIcon,
  up: ArrowUpIcon,
  x: XMarkIcon,
};

const hasItems = computed(() => {
  return !isEmpty(props.items);
});
</script>

<template>
  <Card :title="$t('panel.returns.detail_timeline.card_title')">
    <CardSection v-if="hasItems">
      <Timeline>
        <TimelineItem
          v-for="(item, index) in items"
          :key="item.id"
          :date="item.shortDate"
          :icon-size="IconSize.Large"
          :icon-background-color="item.icon.color"
          :last-item="items.length - 1 === index"
        >
          <template #icon>
            <component
              :is="iconList[item.icon.icon]"
              class="w-4"
            />
          </template>

          <TextContainer>
            {{ item.content }}
            <span v-if="item.actorName">{{ $t('panel.returns:detail:timeline:by') }}&nbsp;</span>
            <Link
              v-if="item.actorName && item.actorUrl"
              :href="item.actorUrl"
              native
            >
              {{ item.actorName }}
            </Link>
            <span v-else>
              {{ item.actorName }}
            </span>
          </TextContainer>
        </TimelineItem>
      </Timeline>
    </CardSection>

    <CardSection v-if="! hasItems">
      <TextContainer text-center>
        {{ $t('panel.returns:detail:timeline:no-events') }}
      </TextContainer>
    </CardSection>
  </Card>
</template>
