<script lang="ts" setup>
import {
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogCancelButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  CardLayout,
  Page,
} from '@returnless/focus-ui';
import { router, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { IntegrationModalFactory } from '@app/panel/Partials';
import SubscribeToAddIntegrationModeModal from '../../partials/SubscribeToAddIntegrationModeModal.vue';
import { useModal } from '@app/panel/Composables/useModal';
import { CreatePlatformIntegrationPageViewModel, PlatformIntegrationViewModel } from './types';
import IntegrationCard from '../../Components/IntegrationCard.vue';

defineProps<CreatePlatformIntegrationPageViewModel>();

const hasSubscription = ref(usePage().props.hasSubscription || false);
const verifying = ref<boolean>(false);
const startTime = Date.now();
const betaDialogOpen = ref(false);

function addIntegration(): void {
  if (!selectedIntegration.value) {
    return;
  }

  router.post(route('panel.settings.integrations.platform.add', selectedIntegration.value.id), {
    start_time: startTime,
  }, {
    onStart: () => verifying.value = true,
    onFinish: () => verifying.value = false,
  });
}

const selectedIntegration = ref<PlatformIntegrationViewModel | null>(null);
const betaIntegration = ref<PlatformIntegrationViewModel | null>(null);

const selectedDriver = computed((): string | null => selectedIntegration.value?.driver ?? null);

const {
  isOpen: subscribeToAddIntegrationModeModalIsOpen,
  close: subscribeToAddIntegrationModeModalClose,
  open: subscribeToAddIntegrationModeModalOpen,
} = useModal();

function setSelectedIntegration(integration: PlatformIntegrationViewModel): void {
  if (integration.requiresSubscription && !hasSubscription.value) {
    subscribeToAddIntegrationModeModalOpen();

    return;
  }
  if (integration.isBeta) {
    betaIntegration.value = integration;
    betaDialogOpen.value = true;
    return;
  }

  selectedIntegration.value = integration;
}

function acceptBetaHandler(): void {
  betaDialogOpen.value = false;
  selectedIntegration.value = betaIntegration.value;
}

function resetSelectedIntegration(): void {
  selectedIntegration.value = null;
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:integrations:platform-integrations:create:head')">
    <Page :navigation="PageNavigation">
      <CardLayout :columns="3">
        <IntegrationCard
          v-for="integration in platformIntegrations"
          :key="integration.id"
          :title="integration.logo.name"
          :beta="integration.isBeta"
          :icon="{
            alt: $t('panel.settings.integrations.platform.logo_alt'),
            src: integration.logo.imageSourceUrl,
          }"
          :action="{
            title: $t('panel.settings.integrations.platform.create.add'),
            onTrigger: () => setSelectedIntegration(integration),
          }"
        />
      </CardLayout>
    </Page>

    <AlertDialog
      :open="betaDialogOpen"
      @cancel="betaDialogOpen = false"
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{{ $t('panel.settings:integrations:platform:beta:title') }}</AlertDialogTitle>
          <AlertDialogDescription>
            {{ $t('panel.settings:integrations:platform:beta:description') }}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancelButton>
            {{
              $t('panel.settings:integrations:platform:beta:cancel')
            }}
          </AlertDialogCancelButton>
          <AlertDialogActionButton
            variant="primary"
            @click="acceptBetaHandler"
          >
            {{ $t('panel.settings:integrations:platform:beta:continue') }}
          </AlertDialogActionButton>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>

    <IntegrationModalFactory
      :integration="selectedIntegration"
      :selected-driver="selectedDriver"
      :tenant="tenant"
      :loading="verifying"
      :on-close="resetSelectedIntegration"
      :on-verify="addIntegration"
    />

    <SubscribeToAddIntegrationModeModal
      :open="subscribeToAddIntegrationModeModalIsOpen"
      @close="subscribeToAddIntegrationModeModalClose"
    />
  </applayout>
</template>
