<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../partials';
import {
  ButtonType,
  Card,
  CardSection,
  CardSeparator,
  Form,
  Heading,
  Page,
} from '@app/panel/Components';
import { computed, provide, watch } from 'vue';
import { App, Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { ReturnQuestion, ReturnQuestionDetail } from '../partials';
import { ConditionEngine } from '@app/panel/Containers';
import { ReturnQuestionForm } from '@app/panel/types';
import { TagList, TagPopover } from '@app/panel/Containers';
import { useTags } from '@app/panel/Composables/useTags';
import { omitBy, trim } from 'lodash';
import { CardLayout } from '@returnless/focus-ui';
import FeatureLimitInfo from '@app/panel/Pages/Settings/Components/FeatureLimitInfo.vue';

type CreateReturnQuestionViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ReturnQuestions.CreateReturnQuestionViewModel'];

const props = defineProps<CreateReturnQuestionViewModel>();

const returnQuestionForm = useForm<ReturnQuestionForm>({
  is_enabled: false,
  is_answer_required: false,
  level: 'order',
  field_type: 'text',
  answers: {},
  conditions: {},
  questionTranslations: {},
  descriptionTranslations: {},
  tags: [],
});

const { activeTags, removeTag } = useTags(props.tags, returnQuestionForm);

const availableConditionTypes = computed(() => {
  return props.availableConditionTypesByLevel[returnQuestionForm.level];
});

function removeEmptyValues(obj: object): object {
  return omitBy(obj, (value) => trim(value) === '');
}

function submitReturnQuestionForm(): void {
  returnQuestionForm
    .transform((form) => {
      form.questionTranslations = removeEmptyValues(form.questionTranslations);
      form.descriptionTranslations = removeEmptyValues(form.descriptionTranslations);
      return form;
    })
    .post(route('panel.settings.forms.return-questions.store', props.form), {
      preserveScroll: true,
    });
}

watch(() => returnQuestionForm.field_type, () => {
  returnQuestionForm.answers = {};
});

watch(() => returnQuestionForm.level, (newLevel) => {
  if (newLevel === 'order') {
    Object.values(returnQuestionForm.conditions)
      .forEach((condition) => {
        if (condition.input === 'return_reason') {
          condition.input = 'question_answered';
        }
      });
  }
});

const returnQuestions = computed(() => {
  return returnQuestionForm.level === App.Enums.ReturnQuestionLevel.OrderItem
    ? props.returnOrderItemQuestions
    : props.returnOrderQuestions;
});

provide('countries', props.countries);
provide('locales', props.locales);
provide('defaultLocale', props.defaultLocale);
provide('form', props.form);
provide('returnForm', returnQuestionForm);
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.return_questions.create.head_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="submitReturnQuestionForm">
        <CardLayout>
          <FeatureLimitInfo :feature-usage-info="featureUsageInfo" />

          <Card
            :divide="false"
            :primary-footer-action="{
              content: $t('panel.settings.forms.list_forms.settings.return_questions.create.save'),
              type: ButtonType.Submit,
              loading: returnQuestionForm.processing,
            }"
          >
            <div class="px-6 pt-6">
              <div class="flex justify-between">
                <div class="flex space-x-4">
                  <Heading>
                    <span class="whitespace-nowrap">
                      {{ $t('panel.settings.forms.list_forms.settings.return_questions.create.title') }}
                    </span>
                  </Heading>

                  <TagList
                    :tags="activeTags"
                    @remove="removeTag"
                  />
                </div>

                <TagPopover
                  v-model="returnQuestionForm.tags"
                  :tags="tags"
                />
              </div>
            </div>

            <CardSection>
              <ReturnQuestionDetail :available-field-types="availableFieldTypes" />
            </CardSection>

            <CardSeparator />

            <CardSection>
              <ReturnQuestion />
            </CardSection>

            <CardSeparator />

            <CardSection>
              <ConditionEngine
                :available-condition-types="availableConditionTypes"
                :condition-type-map="conditionTypeMap"
                :return-reasons="returnReasons"
                :return-questions="returnQuestions"
              />
            </CardSection>
          </Card>
        </CardLayout>
      </Form>
    </Page>
  </AppLayout>
</template>
