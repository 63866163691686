<script lang="ts" setup>
import { computed, onMounted, onUnmounted, useSlots } from 'vue';
import { Heading } from '../Heading';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { ModalSize } from './';
import ModalActions from './ModalActions.vue';
import ModalBody from './ModalBody.vue';

const props = withDefaults(defineProps<{
  closable?: boolean;
  iconColor?: string;
  open?: boolean;
  size?: 'small' | 'default' | 'large' | 'extra-large';
  title?: string;
  subtitle?: string;
  flush?: boolean;
}>(), {
  closable: true,
  iconColor: null,
  open: false,
  size: 'default',
  title: null,
  subtitle: null,
  flush: false,
});

const slots = useSlots();

const emit = defineEmits<{
  close: [];
}>();

onMounted((): void => {
  addEventHandlers();
});

onUnmounted((): void => {
  removeEventHandlers();
});

function closeModal(): void {
  if (props.closable && props.open === true) {
    emit('close');
  }
}

function onKeyDown(e: KeyboardEvent): void {
  if (e.key === 'Escape') {
    closeModal();
  }
}

function addEventHandlers(): void {
  document.addEventListener('keydown', onKeyDown);
}

function removeEventHandlers(): void {
  document.removeEventListener('keydown', onKeyDown);
}

const widthClass = computed(() => {
  return {
    'max-w-md': props.size === ModalSize.Small,
    'max-w-lg': props.size === ModalSize.Default,
    'max-w-2xl': props.size === ModalSize.Large,
    'max-w-5xl': props.size === ModalSize.ExtraLarge,
  };
});
</script>

<template>
  <Teleport to="body">
    <div
      v-if="open"
      class="fixed top-0 left-0 z-40 h-screen w-screen bg-slate-500/50 overflow-auto"
    >
      <div class="flex justify-center items-center min-h-full z-50 py-4">
        <div
          class="w-full overflow-hidden rounded-lg bg-white shadow-2xl"
          :class="widthClass"
        >
          <div class="relative px-6 py-4 mt-2 flex items-center justify-between">
            <div class="flex items-center space-x-4">
              <div
                v-if="!!slots.icon"
                :class="[`bg-${iconColor}-100`, `text-${iconColor}-600`, `border-${iconColor}-300`]"
                class="p-1 rounded-md w-8 h-8 flex items-center justify-center border"
              >
                <slot name="icon" />
              </div>

              <Heading v-if="title">
                {{ title }}
                <div
                  v-if="subtitle"
                  class="text-sm text-slate-400 "
                >
                  {{ subtitle }}
                </div>
              </Heading>
            </div>

            <button
              v-if="closable"
              class="p-1 rounded-md flex items-center justify-center hover:bg-slate-200"
              @click="closeModal"
            >
              <XMarkIcon class="w-4 h-4" />
            </button>
          </div>


          <ModalBody
            v-if="$slots.default"
            :flush="flush"
          >
            <slot />
          </ModalBody>

          <ModalActions v-if="$slots.actions">
            <slot name="actions" />
          </ModalActions>
        </div>
      </div>
    </div>
  </Teleport>
</template>
