<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Card,
  CardSection,
  DataTable,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { Types } from '@app/shared/types/generated-v2';

defineProps<{
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  documents: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['documents'];
}>();

const { t } = useI18n();

const headings = [
  {
    text: t('panel.account.tenant_documents.date_column'),
    value: 'createdAt',
  },

  {
    text: t('panel.account.tenant_documents.title_column'),
    value: 'title',
  },
];

function downloadDocument(tenantDocument): void {
  window.open(route('panel.account.tenant-document.download', tenantDocument), '_blank');
}
</script>

<template>
  <Card
    v-if="tenant.isCustomer && documents.length > 0"
    :title="$t('panel.account.tenant_documents.card_title')"
  >
    <CardSection>
      <DataTable
        :headings="headings"
        :items="documents"
      >
        <template #actions="{ item }">
          <span class="space-x-4 text-sm">
            <Button
              plain
              :variant="ButtonVariant.Primary"
              @keydown.enter.prevent="downloadDocument(item)"
              @click.prevent="downloadDocument(item)"
            >
              {{ $t('panel.account.tenant_documents.download_document_cta') }}
            </Button>
          </span>
        </template>
      </DataTable>
    </CardSection>
  </Card>
</template>
