<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Checkbox,
  Form,
  FormLayout,
  Modal,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import Textarea from '@app/customer/Components/Textarea/components/Textarea.vue';
import * as Types from '@app/panel/types/generated';
import { watch } from 'vue';

type WebhookSubscriptionViewModel = Types.App.Models.ViewModels.WebhookSubscriptionViewModel;

const props = defineProps<{
  open: boolean;
  events: string[];
  current: WebhookSubscriptionViewModel;
  signingSecret: string;
}>();

const emit = defineEmits<{ (e: 'close') }>();

function handleClose(): void {
  emit('close');

  form.reset();
}

const form = useForm({
  endpoint: props.current ? props.current.endpoint : '',
  description: props.current ? props.current.description : '',
  events: props.current ? props.current.events : [],
  is_enabled: props.current ? props.current.isEnabled : false,
  test_mode: props.current ? props.current.testMode : false,
});

function edit(): void {
  form.post(route('panel.settings.integrations.webhooks.update', { webhookSubscription: props.current }), {
    onSuccess: () => handleClose(),
  });
}

watch(() => props.current, (webhookSubscription: WebhookSubscriptionViewModel) => {
  if (webhookSubscription) {
    form.endpoint = webhookSubscription.endpoint;
    form.description = webhookSubscription.description;
    form.events = webhookSubscription.events;
    form.is_enabled = webhookSubscription.isEnabled;
    form.test_mode = webhookSubscription.testMode;

    form.defaults();
  }
}, { immediate: true });
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.settings:integrations:webhooks')"
    @close="handleClose"
  >
    <Form
      id="updateWebhookSubscriptionForm"
      @submit="edit"
    >
      <FormLayout>
        <Toggle
          v-model="form.is_enabled"
          :title="$t('panel.settings:integrations:webhooks:is_enabled')"
        />

        <Toggle
          v-model="form.test_mode"
          :title="$t('panel.settings:integrations:webhooks:test_mode')"
        />

        <TextInput
          v-model="form.endpoint"
          :label="$t('panel.settings:integrations:webhooks:endpoint')"
          :error="form.errors.endpoint"
          placeholder="https://"
        />

        <TextInput
          :value="signingSecret"
          disabled
          allow-copy
          :label="$t('panel.settings:integrations:webhooks:webhook_signing_secret')"
        />

        <Textarea
          v-model="form.description"
          :label="$t('panel.settings:integrations:webhooks:description')"
          :error="form.errors.description"
          :placeholder="$t('panel.settings:integrations:webhooks:description:placeholder')"
        />

        <div class="space-y-2">
          <Checkbox
            v-for="(event, key) in events"
            :key="key"
            v-model="form.events"
            :value="event"
            :label="event"
          />
        </div>
      </FormLayout>
    </Form>

    <template #actions>
      <ButtonGroup>
        <Button @click="handleClose">
          {{ $t('panel.settings:integrations:webhooks:cancel') }}
        </Button>

        <Button
          :loading="form.processing"
          :variant="ButtonVariant.Primary"
          form="updateWebhookSubscriptionForm"
          :type="ButtonType.Submit"
        >
          {{ $t('panel.settings:integrations:webhooks:save-subscription') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
