<script lang="ts" setup>
</script>

<template>
  <div
    data-test="timeline"
    class="space-y-4"
  >
    <slot />
  </div>
</template>
