<script setup lang="ts">
import { AppLayout } from '@app/panel/Layouts';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardLayout,
  CardSection,
  DataList,
  DataListContent,
  DataListItem,
  FormLayout,
  Heading,
  Link,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
  Separator,
  Tabs,
  TabTrigger,
} from '@returnless/focus-ui';
import { FeatureSummaryPageViewModel } from '@app/panel/Pages/Account/Features/Summary/types';
import { ClipboardIcon } from '@heroicons/vue/24/outline';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { router } from '@inertiajs/vue3';

defineProps<FeatureSummaryPageViewModel>();

function changePlan(plan: string) {
  router.visit(route('panel.account.features.summary', { plan }));
}
</script>

<template>
  <AppLayout>
    <Page class="!max-w-3xl">
      <div class="flex space-x-4">
        <div class="shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12 bg-brand-500">
          <ClipboardIcon
            class="h-6"
          />
        </div>

        <PageHeader>
          <PageTitle>
            {{ $t('panel.account:features:summary:title') }}
          </PageTitle>
          <PageDescription>
            <CardLayout :columns="1">
              <FormLayout>
                {{ $t('panel.account:features:summary:description', {
                  planLabel: planLabel,
                }) }}

                <p>
                  {{ $t('panel.global:support:need-help') }}
                  <Link href="https://www.returnless.com/meetings/returnless/support-call">
                    {{ $t('panel.global:support:schedule-a-call') }}
                  </Link>
                </p>

                <ButtonGroup
                  v-if="hasCurrentPlanExceedLimits || currentPlanIdentifier === null"
                >
                  <Button
                    variant="primary"
                    size="small"
                    :href="$route('panel.account.billing')"
                  >
                    {{ $t('panel.account:features:summary:buttons:view-upgrade-options') }}
                  </Button>
                  <Button
                    v-if="hasCurrentPlanExceedLimits"
                    variant="secondary"
                    size="small"
                    :href="$route('panel.account.downgrade.tenant')"
                  >
                    {{ $t('panel.account:features:summary:buttons:downgrade-tool') }}
                  </Button>
                </ButtonGroup>

                <Separator />

                <Heading size="lg">
                  {{ $t('panel.account:features:summary:select-plan') }}
                </Heading>

                <Tabs>
                  <TabTrigger
                    v-for="planOption in planOptions"
                    :key="planOption.value"
                    class="flex-1"
                    :active="planOption.value === planIdentifier"
                    @click="changePlan(planOption.value)"
                  >
                    <div class="space-x-2">
                      <span>{{ planOption.label }}</span>
                      <Badge
                        v-if="planOption.value === currentPlanIdentifier"
                        color="slate"
                        size="sm"
                      >
                        {{ $t('panel.account:billing:plans:current') }}
                      </Badge>
                    </div>
                  </TabTrigger>
                </Tabs>
              </FormLayout>

              <Card>
                <CardSection>
                  <FormLayout>
                    <Heading>
                      {{ $t('panel.account:features:summary:account-summary') }}
                    </Heading>

                    <DataList>
                      <DataListItem
                        v-for="feature in features"
                        :key="feature.featureIdentifier"
                      >
                        <DataListContent>
                          <div class="flex justify-between items-center">
                            <div class="flex items-center space-x-3">
                              <CheckCircleIcon
                                v-if="!feature.hasExceededLimit"
                                class="size-6 fill-emerald-500"
                              />

                              <ExclamationCircleIcon
                                v-if="feature.hasExceededLimit"
                                class="size-6 fill-red-400"
                              />

                              <div>
                                <div>{{ feature.featureLabel }}</div>

                                <Link
                                  v-if="feature.featureLimitRoute && feature.hasExceededLimit"
                                  :href="$route(feature.featureLimitRoute)"
                                  external
                                >
                                  {{ $t('panel.account:features:summary:update-feature', { feature: feature.featureLabel.toLowerCase() }) }}
                                </Link>
                              </div>
                            </div>

                            <Badge
                              v-if="feature.hasExceededLimit"
                              color="red"
                              size="sm"
                            >
                              {{ feature.currentUsage }} / {{ feature.limitLabel }}
                            </Badge>
                          </div>
                        </DataListContent>
                      </DataListItem>
                    </DataList>
                  </FormLayout>
                </CardSection>
              </Card>

              <Card
                v-for="formSummary in formSummaries"
                :key="formSummary.formId"
              >
                <CardSection>
                  <FormLayout>
                    <Heading>
                      {{ $t('panel.account:features:summary:form-summary') }}: {{ formSummary.formName }}
                    </Heading>

                    <DataList>
                      <DataListItem
                        v-for="feature in formSummary.features"
                        :key="feature.featureIdentifier"
                      >
                        <DataListContent>
                          <div class="flex justify-between items-center">
                            <div class="flex items-center space-x-3">
                              <CheckCircleIcon
                                v-if="!feature.hasExceededLimit"
                                class="size-6 fill-emerald-500"
                              />

                              <ExclamationCircleIcon
                                v-if="feature.hasExceededLimit"
                                class="size-6 fill-red-400"
                              />

                              <div>
                                <div>{{ feature.featureLabel }}</div>

                                <Link
                                  v-if="feature.featureLimitRoute && feature.hasExceededLimit"
                                  :href="$route(feature.featureLimitRoute, formSummary.formId)"
                                  external
                                >
                                  {{ $t('panel.account:features:summary:update-feature', { feature: feature.featureLabel.toLowerCase() }) }}
                                </Link>
                              </div>
                            </div>

                            <Badge
                              v-if="feature.hasExceededLimit"
                              color="red"
                              size="sm"
                            >
                              {{ feature.currentUsage }} / {{ feature.limitLabel }}
                            </Badge>
                          </div>
                        </DataListContent>
                      </DataListItem>
                    </DataList>
                  </FormLayout>
                </CardSection>
              </Card>
            </CardLayout>
          </PageDescription>
        </PageHeader>
      </div>
    </Page>
  </AppLayout>
</template>
