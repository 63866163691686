/**
 * When sending an array/list to Laravel it will normally have the keys 0, 1, 2, etc.
 * These keys will then be reflected into the errors array in the response.
 *
 * For example:
 *   Sending [{id: 'A', name: 'John'}, {id: 'A', name: null}]
 *   Will result in: { '0.name': ['The name field is required.'] }
 *
 * However, this is not so useful because now the 'index' is bound to the error message.
 * What we ideally want to get back is: { 'A.name': ['The name field is required.'] }
 * Now the ID is bound to the error message.
 *
 * To achieve this we need to convert the flat array to a key array.
 * So instead of sending [{id: 'A', name: 'John'}, {id: 'A', name: null}]
 * We send { 'A': {id: 'A', name: 'John'}, 'B': {id: 'A', name: null} }
 *
 * This function will convert an array to a key array using the specified key.
 */
export function toKeyArray<T>(items: T[], key: keyof T) {
  return items.reduce((acc, item) => ({
    ...acc,
    [item[key] as keyof T]: item,
  }), {});
}
