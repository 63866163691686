import { App } from '@app/shared/types/generated-v2';
import { CardHelpProps } from '@returnless/focus-ui';
import { usePage } from '@inertiajs/vue3';

type helpscoutBeaconKey = `${App.Enums.HelpscoutArticleBinding}` | App.Enums.HelpscoutArticleBinding;

export function getHelpscoutArticleBeacon(key: helpscoutBeaconKey): CardHelpProps | undefined {
  const articles = window.helpscoutArticles[usePage().props.language as string] ?? {};

  if (key in articles && articles[key]) {
    return {
      label: 'Learn more',
      onClick: (e: MouseEvent) => {
        e.preventDefault();

        window.Beacon('article', articles[key], {
          type: 'inline',
        });
      },
    };
  }

  return undefined;
}
