<script lang="ts" setup>
import { computed, inject, provide, Ref, watch } from 'vue';
import { Button, ButtonVariant, Form } from '@app/panel/Components';
import RecursiveBlockRenderer from '../RecursiveBlockRenderer.vue';
import * as Types from '@app/panel/types/generated';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { formKeyTransformer } from '@app/panel/Utils/formKeyTransformer';
import { Credentials, Trait } from '@app/panel/Pages/Returns/partials/Trait/types';
import { useTrait } from '../Composables/useTrait';
import { ModalActions, ModalBody } from '@app/panel/Components';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useValueOfDepreciatedItems } from '@app/panel/Pages/Returns/partials/Trait/Composables/useValueOfDepreciatedItems';
import { round } from 'lodash';

type ReturnOrderItemViewModel = Types.App.Models.ViewModels.ReturnOrderItemViewModel;

const props = defineProps<{
  trait: Trait;
  salesOrder: Types.App.Models.ViewModels.SalesOrderViewModel;
}>();

const emits = defineEmits<{
  close: [];
}>();

const credentialMapping = inject<Record<string, Credentials>>('credentialMapping');
const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const returnOrderItems = inject<Ref<ReturnOrderItemViewModel[]>>('returnOrderItems');
const applyToShipping = inject('applyToShipping');
const freeShipping = inject('enableFreeShipping');

const { valueOfReturnedItems } = useTrait(props.salesOrder, returnOrderItems);

const traitForm = useForm({
  subtractDepreciation: {},
  amountAdjustment: 0,
  applyToShipping: applyToShipping,
  freeShipping: freeShipping,
  backInStock: credentialMapping[props.trait.platformIntegrationTenantId].restock_items ?? false,
  refundDiscountAmount: (props.trait.platform === 'shopify' ? returnOrder.value.recommendedRefundDiscountAmount : 0),
  platformIntegrationTenantId: props.trait.platformIntegrationTenantId,
});

const { valueOfDepreciatedItems } = useValueOfDepreciatedItems(returnOrderItems, traitForm);

watch(valueOfDepreciatedItems, () => {
  traitForm.amountAdjustment = round((valueOfDepreciatedItems.value / 100) * -1, 2);
});

const computedValues = computed(() => {
  return {
    valueOfReturnedItems: valueOfReturnedItems.value,
    valueOfDepreciatedItems: Math.abs(valueOfDepreciatedItems.value) * -1,
    amountAdjustment: Number(traitForm.amountAdjustment),
    recommendedRefundDiscountAmount: parseFloat(returnOrder.value.recommendedRefundDiscountAmount.toString()).toFixed(2),
    shippingCosts: props.salesOrder.shippingAmount.amount,
    totalDiscountAmount: props.salesOrder.totalDiscountAmount.label,
    backInStock: credentialMapping[props.trait.platformIntegrationTenantId].restock_items ?? false,
  };
});

const total = computed(() => {
  let _total = 0;

  _total += computedValues.value.valueOfReturnedItems;
  _total += Math.round(computedValues.value.amountAdjustment * 100);
  _total += (traitForm.applyToShipping ? computedValues.value.shippingCosts : 0);
  _total += Number(traitForm.refundDiscountAmount);

  return isNaN(_total) ? 0 : _total;
});

const precomputedValues = computed(() => {
  const values = computedValues.value;

  return {
    ...values,
    total: total.value,
  };
});

const postValues = computed(() => {
  return formKeyTransformer({
    ...traitForm.data(),
    amount: precomputedValues.value.total,
  });
});

function submitForm() {
  router.post(
    route('panel.return-orders.coupon.create', { returnOrder: returnOrder.value }),
    (postValues.value),
    {
      preserveScroll: true,
      onSuccess: () => {
        emits('close');
      },
    },
  );
}

provide('traitForm', traitForm);
provide('precomputedValues', precomputedValues);

const formId = computed(() => useUniqueId('coupon-trait'));

</script>

<template>
  <Form
    :id="formId"
    @submit="submitForm"
  >
    <ModalBody>
      <RecursiveBlockRenderer
        :children="trait.children"
        @submit="submitForm"
      />
    </ModalBody>

    <ModalActions>
      <Button @click="emits('close')">
        {{ $t('panel.global:actions:close') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :form="formId"
        @click="submitForm"
      >
        {{ $t('panel.returns:detail-page:trait:coupon:cta') }}
      </Button>
    </ModalActions>
  </Form>
</template>
