<script lang="ts" setup>
import { computed, inject, provide, ref, Ref, watch } from 'vue';
import { Button, ButtonType, ButtonVariant, Form } from '@app/panel/Components';
import RecursiveBlockRenderer from '../RecursiveBlockRenderer.vue';
import * as Types from '@app/panel/types/generated';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { formKeyTransformer } from '@app/panel/Utils';
import { centsToEuro } from '@app/panel/Utils/centsToEuro';
import { Credentials, Trait, TraitBlock } from '@app/panel/Pages/Returns/partials/Trait/types';
import { BannerVariant } from '@app/customer/Components';
import Banner from '@app/panel/Components/Banner/components/Banner.vue';
import { useTrait } from '../Composables/useTrait';
import { ModalActions, ModalBody } from '@app/panel/Components';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useValueOfDepreciatedItems } from '@app/panel/Pages/Returns/partials/Trait/Composables/useValueOfDepreciatedItems';
import { round } from 'lodash';
const props = defineProps<{
  trait: Trait;
  salesOrder: Types.App.Models.ViewModels.SalesOrderViewModel;
}>();

const emits = defineEmits<{
  close: [];
}>();

const credentialMapping = inject<Record<string, Credentials>>('credentialMapping');
const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const returnOrderItems = inject<Ref<Types.App.Models.ViewModels.ReturnOrderItemViewModel[]>>('returnOrderItems');

const { valueOfReturnedItems } = useTrait(props.salesOrder, returnOrderItems);

const showComment = ref<boolean>(false);

returnOrderItems.value.forEach((item) => {
  const quantityReceived = item.quantityReceived - item.quantityRefunded;
  const quantityBackInStock = item.quantityBackInStock - item.quantityReturnedInStock;

  if (quantityBackInStock > 0 && quantityReceived > quantityBackInStock) {
    showComment.value = true;
    return true;
  }
});

function supportsRefundDiscountField(): boolean {
  return props.trait.children.some(
    (child) => traitBlockHasFieldName(child, 'refundDiscountAmount'),
  );
}

function traitBlockHasFieldName(child: TraitBlock, fieldName: string): boolean {
  if (child.properties.fieldName === fieldName) {
    return true;
  }

  return child.children.some((child) => traitBlockHasFieldName(child, fieldName));
}

const traitForm = useForm({
  subtractDepreciation: {},
  amountAdjustment: 0,
  refundShipping: credentialMapping[props.trait.platformIntegrationTenantId].default_include_shipping_amount,
  onlineRefund: credentialMapping[props.trait.platformIntegrationTenantId].default_online_refund,
  sendEmail: credentialMapping[props.trait.platformIntegrationTenantId].send_email ?? credentialMapping[props.trait.platformIntegrationTenantId].send_credit_invoice_email,
  refundDiscountAmount: supportsRefundDiscountField() ? returnOrder.value.recommendedRefundDiscountAmount : 0,
  backInStock: credentialMapping[props.trait.platformIntegrationTenantId].restock_items ?? false,
  refundTransactionCost: credentialMapping[props.trait.platformIntegrationTenantId].default_refund_transaction,
  showComment: showComment.value,
  platformIntegrationTenantId: props.trait.platformIntegrationTenantId,
});

const { valueOfDepreciatedItems } = useValueOfDepreciatedItems(returnOrderItems, traitForm);

watch(valueOfDepreciatedItems, () => {
  traitForm.amountAdjustment = round((valueOfDepreciatedItems.value / 100) * -1, 2);
});

const computedValues = computed(() => {
  return {
    valueOfReturnedItems: valueOfReturnedItems.value,
    valueOfDepreciatedItems: Math.abs(valueOfDepreciatedItems.value) * -1,
    amountAdjustment: Number(traitForm.amountAdjustment),
    shippingCosts: props.salesOrder?.shippingAmount.amount,
    totalDiscountAmount: props.salesOrder.totalDiscountAmount.label,
  };
});

const total = computed(() => {
  let _total = 0;

  _total += computedValues.value.valueOfReturnedItems;
  _total += Math.round(computedValues.value.amountAdjustment * 100);
  _total += (traitForm.refundShipping ? computedValues.value.shippingCosts : 0);
  _total += Number(traitForm.refundDiscountAmount ?? 0);

  return isNaN(_total) ? 0 : _total;
});

const exceptionalDiscount = computed(() => {
  return traitForm.refundDiscountAmount > props.salesOrder.totalDiscountAmount.amount;
});

const precomputedValues = computed(() => {
  return {
    ...computedValues.value,
    recommendedRefundDiscountAmount: centsToEuro(returnOrder.value.recommendedRefundDiscountAmount),
    total: total.value,
  };
});

const postValues = computed(() => {
  return formKeyTransformer({
    ...traitForm.data(),
    total: precomputedValues.value.total,
  });
});

const loading = ref(false);

function submitForm() {
  const endpoint = route('panel.return-orders.credit-invoice.create', { returnOrder: returnOrder.value });

  loading.value = true;

  router.post(endpoint, postValues.value, {
    preserveScroll: true,
    onSuccess: () => emits('close'),
    onFinish: () => loading.value = false,
  });
}

provide('traitForm', traitForm);
provide('precomputedValues', precomputedValues);

const formId = computed(() => useUniqueId('credit-invoice-trait'));
</script>

<template>
  <Form
    :id="formId"
    @submit="submitForm"
  >
    <ModalBody>
      <RecursiveBlockRenderer :children="trait.children" />
      <div
        v-if="exceptionalDiscount"
        class="mt-5"
      >
        <Banner :variant="BannerVariant.Warning">
          {{
            $t('panel.returns:detail-page:trait:credit-invoice:exceptional-discount', {
              discount: salesOrder.totalDiscountAmount.label,
            })
          }}
        </Banner>
      </div>
    </ModalBody>

    <ModalActions>
      <Button @click="emits('close')">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :disabled="precomputedValues.total <= 0"
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
        :loading="loading"
        :form="formId"
      >
        {{ $t('panel.returns:detail-page:trait:credit-invoice:cta') }}
      </Button>
    </ModalActions>
  </Form>
</template>
