<script lang="ts" setup>
import { computed, ref } from 'vue';
import { theme } from '../../../Composables/useTheme';
import { isEqual } from 'lodash';

const props = defineProps({
  name: {
    type: String,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  value: {
    type: [String, Object],
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  modelValue: {
    type: [Array, Boolean],
    default: false,
  },

  fullWidth: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const checked = computed(() => {
  if (props.modelValue instanceof Array) {
    if (typeof props.value === 'string') {
      return props.modelValue.includes(props.value);
    }
    return props.modelValue.some((item) => isEqual(item, props.value));
  }

  return props.modelValue;
});

const onCheckboxChange = (e: InputEvent): void => {
  const target = e.target as HTMLInputElement;

  if (typeof props.modelValue === 'boolean') {
    return emit('update:modelValue', target.checked);
  }

  const newModelValue = [...props.modelValue];

  if (target.checked) {
    newModelValue.push(props.value);
  }

  if (!target.checked) {
    const toBeRemovedIndex = newModelValue.findIndex((value) => {
      return isEqual(value, props.value);
    });
    newModelValue.splice(toBeRemovedIndex, 1);
  }

  emit('update:modelValue', newModelValue);
};

const checkboxInput = ref<HTMLInputElement>(null);

function activateCheckboxInput() {
  checkboxInput.value.click();
}
</script>

<template>
  <label
    class="flex items-center cursor-pointer"
    :class="fullWidth ? 'w-full' : 'w-fit'"
    :for="name"
    @keyup.enter="activateCheckboxInput"
  >
    <input
      :id="name"
      ref="checkboxInput"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      class="text-brand-500 border-slate-300 rounded cursor-pointer"
      :class="theme('focus-outline-color')"
      @change="onCheckboxChange"
    >

    <span
      v-if="label || $slots['default']"
      class="block pl-2 text-sm"
    >
      <slot>
        {{ label }}
      </slot>
    </span>
  </label>
</template>
