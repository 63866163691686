<script lang="ts" setup>
import { ref } from 'vue';
import {
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  TextInput,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';

const changedPassword = ref(false);
const changePasswordForm = useForm({
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
});

function changePassword() {
  changePasswordForm.post(route('panel.account.change-password'), {
    preserveScroll: true,

    onSuccess: () => {
      changedPassword.value = true;
      changePasswordForm.reset();
    },
  });
}
</script>

<template>
  <Form @submit="changePassword">
    <Card
      :title="$t('panel.account.profile.password.card_title')"
      :primary-footer-action="{
        content: $t('panel.account.profile.password.save_button'),
        type: ButtonType.Submit,
        loading: changePasswordForm.processing
      }"
    >
      <CardSection>
        <FormLayout>
          <FormLayout>
            <TextInput
              v-model="changePasswordForm.current_password"
              :error="changePasswordForm.errors.current_password"
              :label="$t('panel.account.profile.password.current_password_field')"
              type="password"
            />
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="changePasswordForm.new_password"
                :error="changePasswordForm.errors.new_password"
                :label="$t('panel.account.profile.password.new_password_field')"
                type="password"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="changePasswordForm.new_password_confirmation"
                :error="changePasswordForm.errors.new_password_confirmation"
                :label="$t('panel.account.profile.password.repeat_password_field')"
                type="password"
              />
            </FormLayout>
          </FormLayout>
        </FormLayout>
      </CardSection>
    </Card>
  </Form>
</template>
