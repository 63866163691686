<script lang="ts" setup>
import { onMounted } from 'vue';
import { Spinner } from '../../Spinner';
import { useBulkActionLoader } from '@app/panel/Composables/useBulkActionLoader';

const { isRunningActiveBulkActions, startInterval } = useBulkActionLoader();

onMounted(() => {
  startInterval();
});
</script>
<template>
  <div
    v-if="isRunningActiveBulkActions"
    class="fixed left-10 bottom-0 bg-green-400 w-[400px] rounded-t-lg p-4"
  >
    <div class="flex items-center justify-between text-sm">
      <span>{{ $t('panel.global:bulk-actions:wait-message') }}</span>
      <Spinner />
    </div>
  </div>
</template>
