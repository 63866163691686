<script lang="ts" setup>
import {
  Navigation,
  NavigationItem,
  NavigationSection,
} from '@returnless/focus-ui';
import { ArrowTrendingUpIcon, ChartBarIcon, ShoppingCartIcon, TruckIcon } from '@heroicons/vue/16/solid';
import { usePermission } from '@app/panel/Composables/usePermission';
import { App } from '@app/shared/types/generated-v2';

const { can } = usePermission();

</script>

<template>
  <Navigation>
    <NavigationSection>
      <NavigationItem
        :href="$route('panel.analytics.overview')"
        :active="$route().current('panel.analytics.overview')"
        :icon="ChartBarIcon"
      >
        {{ $t('panel.analytics:menu:overview') }}
      </NavigationItem>
      <NavigationItem
        v-if="can(App.Enums.PermissionType.ANALYTICS_PERFORMANCE)"
        :href="$route('panel.analytics.performance')"
        :active="$route().current('panel.analytics.performance')"
        :icon="ArrowTrendingUpIcon"
      >
        {{ $t('panel.analytics:menu:performance') }}
      </NavigationItem>
      <NavigationItem
        v-if="can(App.Enums.PermissionType.ANALYTICS_PRODUCTS)"
        :href="$route('panel.analytics.products')"
        :active="$route().current('panel.analytics.products') || $route().current('panel.analytics.products.*')"
        :icon="ShoppingCartIcon"
      >
        {{ $t('panel.analytics:menu:products') }}
      </NavigationItem>
      <NavigationItem
        :href="$route('panel.analytics.shipping')"
        :active="$route().current('panel.analytics.shipping')"
        :icon="TruckIcon"
      >
        {{ $t('panel.analytics:menu:shipping') }}
      </NavigationItem>
    </NavigationSection>
  </Navigation>
</template>
