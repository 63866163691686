import { ref } from 'vue';
import { DependencyWarningViewModel } from '@app/panel/Pages/Settings/types';

const dependencyWarning = ref<DependencyWarningViewModel | null>(null);

export function useDependencyWarning() {
  function showDependencyWarning(warning: DependencyWarningViewModel): void {
    dependencyWarning.value = warning;
  }

  function closeDependencyWarning(): void {
    dependencyWarning.value = null;
  }

  return { dependencyWarning, showDependencyWarning, closeDependencyWarning };
}
