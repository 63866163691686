<script lang="ts" setup>
import { Timeline } from '@app/panel/Components';
import { CheckableTimelineItem } from '@app/panel/Containers';
import { App } from '@app/shared/types/generated-v2';
import { useOnboarding } from '../composables';

const {
  changeStep,
  isStepChecked,
  isStepClickable,
  onboardingStatus,
} = useOnboarding();
</script>

<template>
  <Timeline>
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps', { current: 1 })"
      :description="$t('panel.dashboard:onboarding:steps:create-account')"
      :clickable="false"
      :completed="true"
    />
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps', { current: 2 })"
      :description="$t('panel.dashboard:onboarding:steps:primary-return-address')"
      :clickable="isStepClickable(App.Enums.OnboardingStateType.HasReturnAddress)"
      :completed="onboardingStatus.hasReturnAddress"
      :checked="isStepChecked(App.Enums.OnboardingStateType.HasReturnAddress)"
      @check="() => changeStep(App.Enums.OnboardingStateType.HasReturnAddress)"
    />
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps', { current: 3 })"
      :description="$t('panel.dashboard:onboarding:steps:return-form')"
      :clickable="isStepClickable(App.Enums.OnboardingStateType.HasReturnForm)"
      :completed="onboardingStatus.hasReturnForm"
      :checked="isStepChecked(App.Enums.OnboardingStateType.HasReturnForm)"
      @check="() => changeStep(App.Enums.OnboardingStateType.HasReturnForm)"
    />
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps', { current: 4 })"
      :description="$t('panel.dashboard:onboarding:steps:return-questions')"
      :clickable="isStepClickable(App.Enums.OnboardingStateType.HasReturnQuestions)"
      :completed="onboardingStatus.hasReturnQuestions"
      :checked="isStepChecked(App.Enums.OnboardingStateType.HasReturnQuestions)"
      @check="() => changeStep(App.Enums.OnboardingStateType.HasReturnQuestions)"
    />
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps', { current: 5 })"
      :description="$t('panel.dashboard:onboarding:steps:return-rules')"
      :clickable="isStepClickable(App.Enums.OnboardingStateType.HasReturnRules)"
      :completed="onboardingStatus.hasReturnRules"
      :checked="isStepChecked(App.Enums.OnboardingStateType.HasReturnRules)"
      @check="() => changeStep(App.Enums.OnboardingStateType.HasReturnRules)"
    />
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps', { current: 6 })"
      :description="$t('panel.dashboard:onboarding:steps:test-return')"
      :clickable="isStepClickable(App.Enums.OnboardingStateType.HasTestReturn)"
      :completed="onboardingStatus.hasTestReturn"
      :checked="isStepChecked(App.Enums.OnboardingStateType.HasTestReturn)"
      @check="() => changeStep(App.Enums.OnboardingStateType.HasTestReturn)"
    />
    <CheckableTimelineItem
      :label="$t('panel.dashboard:onboarding:steps:party')"
      :clickable="false"
      :completed="false"
      last-item
    >
      <div>
        <span class="h-3 w-3">🎉</span>
      </div>
    </CheckableTimelineItem>
  </Timeline>
</template>
