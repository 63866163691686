<script lang="ts" setup>
import {
  Link,
  LinkFontSize,
  LinkVariant,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { MediaCard } from '@app/panel/Containers';
import { StatusBadge } from '@app/panel/types';
import { IntegrationMediaCardActionIcon } from '../types';
import { CogIcon, PlusIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';

const props = defineProps<{
  title: string;
  subtitle?: string;
  description?: string;
  statusBadge?: StatusBadge;
  logo: {
    src: string;
    alt: string;
  };
  action: {
    title: string;
    icon: IntegrationMediaCardActionIcon;
    href?: string;
    onTrigger?: () => void;
  };
}>();

const actionIcon = computed(() => {
  if (props.action.icon === IntegrationMediaCardActionIcon.Cog) {
    return CogIcon;
  }

  if (props.action.icon === IntegrationMediaCardActionIcon.Plus) {
    return PlusIcon;
  }

  return null;
});
</script>

<template>
  <MediaCard
    :title="title"
    :status-badge="statusBadge"
  >
    <template #header>
      <div class="flex justify-center items-center w-full h-full">
        <div class="h-24 mt-8 mx-20">
          <img
            class="w-full h-full cursor-pointer"
            :alt="logo.alt"
            :src="logo.src"
            loading="lazy"
            @click.prevent="!!action.onTrigger ? action.onTrigger() : null"
          >
        </div>
      </div>
    </template>

    <span class="text-sm text-slate-900 space-y-1">
      <p>
        {{ subtitle }}
      </p>
      <TextStyle :shade="TextStyleShade.Subdued">
        {{ description }}
      </TextStyle>
    </span>

    <template #footer>
      <Link
        :href="action.href"
        :native="!action.href"
        :variant="LinkVariant.Subdued"
        :font-size="LinkFontSize.Small"
        @click.prevent="!!action.onTrigger ? action.onTrigger() : null"
      >
        <TextStyle>
          <div class="flex items-center space-x-2 group-hover:text-slate-500">
            <component
              :is="actionIcon"
              class="h-5 w-5 text-slate-400"
            />

            <span>
              {{ action.title }}
            </span>
          </div>
        </TextStyle>
      </Link>
    </template>
  </MediaCard>
</template>
