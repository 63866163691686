<script lang="ts" setup>
import { h } from 'vue';
import { Link } from '@inertiajs/vue3';
import { type ActionListItem } from '../types';

const props = defineProps<{ items: ActionListItem[] }>();

const ActionList = () => props.items.map((item) => {
  const className = 'flex items-center whitespace-nowrap block p-2 text-sm';

  if (item.disabled) {
    return h('div', {
      class: [className, 'opacity-50 cursor-not-allowed'],
    }, [h('div', { class: 'w-3 mr-2' }, '✓'), item.content]);
  }

  if (item.href) {
    if (item.native) {
      return h('a', {
        class: [className, 'cursor-pointer hover:bg-slate-100'],
        href: item.href,
        target: item.target,
      }, item.content);
    }

    return h(Link, {
      class: [className, 'cursor-pointer hover:bg-slate-100'],
      href: item.href,
    }, () => item.content);
  }

  const statusIndicatorElement = item.color
    ? h('div', { class: `h-3 w-3 bg-${item.color}-500 rounded-full mr-2` })
    : null;

  return h('div', {
    class: [className, 'rounded-md cursor-pointer hover:bg-slate-100'],
    onClick: item.onClick ? item.onClick : null,
  }, [statusIndicatorElement, item.content]);
});
</script>

<template>
  <ActionList />
</template>
