<script lang="ts">
import {
  computed,
  defineComponent,
} from 'vue';
import { validateColumnsNumber } from '../helpers';

export default defineComponent({
  props: {
    columns: {
      type: Number,
      validator: (val: number): boolean => validateColumnsNumber(val),
      default: 1,
    },

    columnsSm: {
      type: Number,
      validator: (val: number): boolean => validateColumnsNumber(val),
      default: null,
    },

    columnsMd: {
      type: Number,
      validator: (val: number): boolean => validateColumnsNumber(val),
      default: null,
    },

    columnsLg: {
      type: Number,
      validator: (val: number): boolean => validateColumnsNumber(val),
      default: null,
    },

    columnsXl: {
      type: Number,
      validator: (val: number): boolean => validateColumnsNumber(val),
      default: null,
    },
  },

  setup(props) {
    const columnsClass = computed(() => {
      return `grid-cols-${props.columns}`;
    });

    const columnsSmClass = computed(() => {
      return props.columnsSm ? `sm:grid-cols-${props.columnsSm}` : '';
    });

    const columnsMdClass = computed(() => {
      return props.columnsMd ? `md:grid-cols-${props.columnsMd}` : '';
    });

    const columnsLgClass = computed(() => {
      return props.columnsLg ? `lg:grid-cols-${props.columnsLg}` : '';
    });

    const columnsXlClass = computed(() => {
      return props.columnsXl ? `xl:grid-cols-${props.columnsXl}` : '';
    });

    const classList = computed(() => {
      return [
        columnsClass.value,
        columnsSmClass.value,
        columnsMdClass.value,
        columnsLgClass.value,
        columnsXlClass.value,
      ];
    });

    return { classList };
  },
});
</script>

<template>
  <div
    data-test="form-layout"
    class="form-layout grid gap-6"
    :class="classList"
  >
    <slot />
  </div>
</template>
