<script lang="ts" setup>
import { provide } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { PageNavigation } from '../partials';
import {
  ButtonType,
  Card,
  CardSection,
  CardSeparator,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Page,
  TextInput,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/panel/Components';
import { CardLayout, Select } from '@returnless/focus-ui';
import type { Types } from '@app/shared/types/generated-v2';
import { App } from '@app/shared/types/generated-v2';
import { ConditionEngine, TagList, TagPopover } from '@app/panel/Containers';
import { useTags } from '@app/panel/Composables/useTags';
import { ClockIcon } from '@heroicons/vue/24/outline';
import FeatureLimitInfo from '@app/panel/Pages/Settings/Components/FeatureLimitInfo.vue';
import Actions from '@app/panel/Pages/Settings/Forms/Actions.vue';
import { toKeyArray } from '@app/panel/Utils/toKeyArray';

type CreateReturnRuleViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ReturnRules.CreateReturnRuleViewModel'];
type ReturnRuleActionViewModel = Types['App.Models.ViewModels.ReturnRuleActionViewModel'];

const props = defineProps<CreateReturnRuleViewModel>();

const returnRuleForm = useForm({
  name: '',
  conditions: {},
  is_enabled: true,
  translations: {},
  tags: [],
  return_instruction_id: null,
  level: props.returnRuleLevel,
  trigger: props.returnRuleLevel === App.Enums.EntityLevel.ITEM
    ? App.Enums.ReturnRuleTrigger.ORDER_EVALUATED
    : App.Enums.ReturnRuleTrigger.RETURN_CREATED,
  action_items: [] as ReturnRuleActionViewModel[],
});

provide('form', props.form);
provide('countries', props.countries);
provide('returnForm', returnRuleForm);

const { activeTags, removeTag } = useTags(props.tags, returnRuleForm);

function submitForm(): void {
  returnRuleForm
    .transform((data) => {
      return {
        ...data,
        action_items: toKeyArray(data.action_items, 'id'),
      };
    })
    .post(route('panel.settings.form.return-rules.store', props.form), {
      preserveScroll: true,
    });
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:form:return-rules:create:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="submitForm">
        <CardLayout>
          <FeatureLimitInfo :feature-usage-info="featureUsageInfo" />

          <Card
            :divide="false"
            :primary-footer-action="{
              content: $t('panel.settings:form:return-rules:create:save-cta'),
              type: ButtonType.Submit,
              loading: returnRuleForm.processing,
            }"
          >
            <div class="px-6 pt-6">
              <div class="flex justify-between">
                <div class="flex space-x-4">
                  <Heading>
                    <span class="whitespace-nowrap">
                      {{
                        returnRuleLevel === App.Enums.EntityLevel.ITEM
                          ? $t('panel.settings:form:item-rules:create:title')
                          : $t('panel.settings:form:return-rules:create:title')
                      }}
                    </span>
                  </Heading>

                  <TagList
                    :tags="activeTags"
                    @remove="removeTag"
                  />
                </div>

                <TagPopover
                  v-model="returnRuleForm.tags"
                  :tags="tags"
                />
              </div>
            </div>

            <CardSection>
              <div class="mb-8 flex w-full items-center space-x-4 rounded-lg bg-blue-100 p-4">
                <ClockIcon class="size-6" />
                <div
                  v-if="returnRuleLevel === App.Enums.EntityLevel.ITEM"
                  class="text-sm"
                >
                  <strong>{{ $t('panel.settings:forms:return-rules:triggers.order-evaluation') }}</strong>
                  <p class="text-xs">
                    {{ $t('panel.settings:forms:return-rules:triggers.order-evaluation.description') }}
                  </p>
                </div>

                <div
                  v-if="returnRuleLevel === App.Enums.EntityLevel.RETURN"
                  class="text-sm"
                >
                  <strong>{{ $t('panel.settings:forms:return-rules:triggers.return-created') }}</strong>
                  <p class="text-xs">
                    {{ $t('panel.settings:forms:return-rules:triggers.return-created.description') }}
                  </p>
                </div>
              </div>

              <FormLayout>
                <FormLayout :columns="2">
                  <TextInput
                    v-model="returnRuleForm.name"
                    :label="$t('panel.settings:form:return-rules:create:title-label')"
                    :placeholder="$t('panel.settings:form:return-rules:create:title-placeholder')"
                    :error="returnRuleForm.errors.name"
                  />

                  <Select
                    v-model="returnRuleForm.return_instruction_id"
                    :label="$t('panel.settings:form:return-rules:create:show-return-instructions')"
                  >
                    <option :value="null">
                      {{ $t('panel.settings:form:return-rules:create:dont-show-instruction') }}
                    </option>
                    <optgroup :label="$t('panel.settings:form:return-rules:create:instructions-group:default')">
                      <option
                        v-for="returnInstruction in returnInstructions.filter((instruction) => instruction.isDefault)"
                        :key="returnInstruction.id"
                        :value="returnInstruction.id"
                      >
                        {{ returnInstruction.name }}
                      </option>
                    </optgroup>
                    <optgroup :label="$t('panel.settings:form:return-rules:create:instructions-group:custom')">
                      <option
                        v-for="returnInstruction in returnInstructions.filter((instruction) => !instruction.isDefault)"
                        :key="returnInstruction.id"
                        :value="returnInstruction.id"
                      >
                        {{ returnInstruction.name }}
                      </option>
                    </optgroup>
                  </Select>
                </FormLayout>

                <Toggle
                  v-model="returnRuleForm.is_enabled"
                  :title="$t('panel.settings:form:return-rules:create:enable-label')"
                />
              </FormLayout>
            </CardSection>

            <CardSeparator />

            <CardSection>
              <div class="mb-4">
                <Heading :element="HeadingElement.H3">
                  <TextStyle
                    strong
                    :font-size="TextStyleFontSize.Base"
                    :shade="TextStyleShade.Dense"
                  >
                    {{ $t('panel.settings:form:return-rules:create:conditions-title') }}
                  </TextStyle>
                </Heading>
              </div>

              <ConditionEngine
                :available-condition-types="availableConditionTypes"
                :condition-type-map="conditionTypeMap"
                :return-reasons="returnReasons"
                :return-questions="returnQuestions"
                :tags="tags"
                :return-addresses-options="returnAddressesOptions"
                :return-statuses-options="returnStatusesOptions"
                :request-statuses-options="requestStatusesOptions"
              />
            </CardSection>

            <CardSeparator />

            <CardSection>
              <div class="mb-4">
                <Heading :element="HeadingElement.H3">
                  <TextStyle
                    strong
                    :font-size="TextStyleFontSize.Base"
                    :shade="TextStyleShade.Dense"
                  >
                    {{ $t('panel.settings:form:return-rules:create:action-trigger-title') }}
                  </TextStyle>
                </Heading>
              </div>

              <Actions
                v-model="returnRuleForm.action_items"
                :actions="actions"
                :errors="returnRuleForm.errors"
              />
            </CardSection>
          </Card>
        </CardLayout>
      </Form>
    </Page>
  </AppLayout>
</template>
