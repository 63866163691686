<script lang="ts" setup>
import { inject, PropType, Ref } from 'vue';
import {
  Button,
  ButtonType,
  ButtonVariant,
  ColorPicker,
  Form,
  FormLayout,
  Modal,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { TranslationInputFields } from '@app/panel/Containers';
import { TranslationInputFieldType } from '@app/panel/Containers/TranslationInputFields/types';
import { entries } from 'lodash';
import { InertiaForm } from '@inertiajs/vue3';
import { StatusForm } from './types';

const statusForm = inject<InertiaForm<StatusForm>>('statusForm')!;
const defaultLocale = inject<Types.App.Models.ViewModels.LocaleViewModel>('defaultLocale')!;
const locales = inject<Ref<Types.App.Models.ViewModels.LocaleViewModel[]>>('locales')!;

const props = defineProps({
  current: {
    type: Object as PropType<Types.App.Models.ViewModels.RequestStatusViewModel>,
    default: null,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onEditModalClose(): void {
  emit('close');
}

function removeEmptyTranslations(translations: Record<string, string>) {
  return entries(translations).reduce((acc, [key, value]) => {
    if (value.trim() !== '') {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});
}

function submitEditForm() {
  statusForm
    .transform((form) => ({
      ...form,
      translations: removeEmptyTranslations(form.translations),
    }))
    .put(route('panel.settings.general.update-request-status', props.current.id), {
      onSuccess: () => onEditModalClose(),
    });
}
</script>

<template>
  <Modal
    :open="current !== null"
    @close="onEditModalClose"
  >
    <Form
      id="edit-form"
      @submit="submitEditForm"
    >
      <FormLayout>
        <FormLayout>
          <ColorPicker
            v-model="statusForm.color"
            :label="$t('panel.settings.general_settings.request_statuses.edit_modal.status_color_label')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="statusForm.label"
            name="label"
            :label="$t('panel.settings.general_settings.request_statuses.edit_modal.status_label_input')"
            :error="statusForm.errors.label"
          />
        </FormLayout>

        <FormLayout>
          <TranslationInputFields
            v-model="statusForm.translations"
            :locales="locales"
            :errors="statusForm.errors"
            field-name="translations"
            :default-locale-id="defaultLocale?.id"
            :type="TranslationInputFieldType.TextInput"
            :label="$t('panel.settings.general_settings.request_statuses.edit_modal.status_translation_input')"
          />
        </FormLayout>

        <Toggle
          v-if="! current.isDefaultStatus"
          v-model="statusForm.is_default_status"
          :title="$t('panel.settings.general_settings.request_statuses.edit_modal.default')"
        />

        <FormLayout>
          <Toggle
            v-model="statusForm.visible_on_tracking_page"
            :title="$t('panel.settings.general_settings.request_statuses.edit_modal.visible_on_tracking_page')"
          />
        </FormLayout>

        <FormLayout>
          <Toggle
            v-model="statusForm.should_notify_user"
            :title="$t('panel.settings.general_settings.request_statuses.edit_modal.should_notify_user')"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="onEditModalClose">
        Cancel
      </Button>
      <Button
        :loading="statusForm.processing"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="edit-form"
      >
        {{ $t('panel.settings.general_settings.request_statuses.edit_modal.save_button') }}
      </Button>
    </template>
  </Modal>
</template>
