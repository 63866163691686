<script setup lang="ts">
import { Autocomplete, AutocompleteItem } from '@returnless/focus-ui';
import { useApi } from '@app/panel/Composables/useHttp';
import { type GoogleMapsPlaceDetails } from '@app/panel/Pages/Settings/ReturnAddresses/types/ReturnAddressForm.type';
import { useDebounceAutocompleteItems } from '@app/panel/Composables/useDebounceAutocompleteItems';

const modelValue = defineModel<string>({
  required: true,
});

const emit = defineEmits<{
  onSelectAutocompleteItem: [details: GoogleMapsPlaceDetails];
}>();

type AutoCompleteItem = { id: string; name: string };

type AutoCompleteResponse = {
  data: AutoCompleteItem[];
};

type PlaceDetailsResponse = {
  data: GoogleMapsPlaceDetails;
};

const { autocompleteItems } = useDebounceAutocompleteItems<AutoCompleteItem>(modelValue, async (searchQuery: string) => {
  const response = await useApi().get<AutoCompleteResponse>(route('api.v1.google-maps.store-locations.autocomplete'), {
    params: {
      search: searchQuery,
    },
  });

  autocompleteItems.value = response.data.data;
});

async function onSelectAutocompleteItem(placeId: string): Promise<void> {
  const response = await useApi().get<PlaceDetailsResponse>(route('api.v1.google-maps.place-details', { placeId }));

  autocompleteItems.value = [];

  emit('onSelectAutocompleteItem', response.data.data);
}

</script>

<template>
  <Autocomplete
    v-model="modelValue"
    :label="$t('panel.settings:store-locations:modal:autocomplete:label')"
    :placeholder="$t('panel.settings:store-locations:modal:autocomplete:placeholder')"
  >
    <AutocompleteItem
      v-for="autocompleteItem in autocompleteItems"
      :key="autocompleteItem.id"
      :value="autocompleteItem.id"
      @select="onSelectAutocompleteItem(autocompleteItem.id)"
    >
      {{ autocompleteItem.name }}
    </AutocompleteItem>
  </Autocomplete>
</template>
