<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import { Button, Form, FormLayout, Link, TextField } from '@returnless/focus-ui';
import { GuestLayout } from '@app/panel/Layouts';
import { asset } from 'laravel-vapor';

withDefaults(defineProps<{
  showForcedLogin: boolean;
}>(), {
  showForcedLogin: false,
});

const form = useForm<{
  email: string;
  password: string;
}>({
  email: '',
  password: '',
});

function submit(): void {
  form.post(route('login'));
}
</script>

<template>
  <GuestLayout
    :title="$t('panel.auth.login.title')"
    :hero-image="asset('img/login-background.jpeg')"
  >
    <template #subtitle>
      <Link :href="$route('register')">
        {{ $t('panel.auth.login.register_link') }}
      </Link>
    </template>

    <Form @submit="submit">
      <input
        type="hidden"
        name="remember"
        value="1"
        checked
      >
      <FormLayout>
        <TextField
          v-model="form.email"
          :label="$t('panel.auth.login.email_field_label')"
          :placeholder="$t('panel.auth.login.email_field_placeholder')"
          auto-complete="email"
          autofocus
          name="email"
          required
          type="email"
        />

        <TextField
          v-model="form.password"
          :error="form.errors.email"
          :label="$t('panel.auth.login.password_field_label')"
          auto-complete="current-password"
          name="password"
          required
          type="password"
        />

        <Link :href="$route('password.request')">
          {{ $t('panel.auth.login.forgot_password_link') }}
        </Link>

        <Button
          :loading="form.processing"
          full-width
          type="submit"
          variant="primary"
        >
          {{ $t('panel.auth.login.sign_in_button') }}
        </Button>

        <Button
          v-if="showForcedLogin"
          variant="secondary"
          :href="$route('forced-login', 1)"
          method="post"
        >
          Login as Demo user
        </Button>
      </FormLayout>
    </Form>
  </GuestLayout>
</template>
