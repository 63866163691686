<script lang="ts" setup>
import { useApi } from '@app/panel/Composables/useHttp';
import { computed, onMounted, ref, watch } from 'vue';
import { InsightsItem } from '@app/panel/Containers';
import {
  Heading,
  HeadingSize,
  Spinner,
  Stack,
  StackItem,
  TestModeToggle,
  TextContainer,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { KPICard, Link } from '@returnless/focus-ui';
import { Link as InertiaLink } from '@inertiajs/vue3';
import { pick } from 'lodash';
import WeeklyReturnsOverviewChart
  from '@app/panel/Pages/Analytics/blocks/WeeklyReturnsOverviewChart/WeeklyReturnsOverviewChart.vue';

const data = ref(null);
const error = ref(null);
const showTestData = ref(false);
const loadingAnalytics = ref(true);

onMounted(async () => {
  try {
    await fetchData();
    loadingAnalytics.value = false;
  } catch (err) {
    error.value = err;
  }
});

async function fetchData() {
  data.value = await new Promise((resolve, reject) => {
    useApi().get(route('api.v1.analytics.dashboard', { show_test_data: showTestData.value }))
      .then((response) => response.data)
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}

const requestStatuses = computed(() => {
  return pick(data.value?.data, ['request_statuses']).request_statuses || [];
});

const returnStatuses = computed(() => {
  return pick(data.value?.data, ['return_statuses']).return_statuses || [];
});

const insightData = computed(() => {
  return pick(data.value?.data, [
    'total_returns_today',
    'total_returns_yesterday',
    'pending_requests',
    'total_returns_last_30_days',
  ]);
});

const overviewDataPoints = computed(() => data.value?.data.overview_data_points ?? []);

watch(showTestData, () => fetchData());
</script>

<template>
  <div v-if="error">
    {{ error }}
  </div>

  <div class="mb-12 flex justify-between">
    <div class="flex flex-col space-y-1">
      <Heading :size="HeadingSize.ExtraLarge">
        {{ $t('panel.dashboard:greeting-message:heading', { name: $page.props.user.name }) }}
      </Heading>

      <TextContainer>
        <TextStyle
          :font-size="TextStyleFontSize.Large"
          :shade="TextStyleShade.Subdued"
        >
          {{ $t('panel.dashboard:greeting-message:body') }}
        </TextStyle>
      </TextContainer>
    </div>
    <div>
      <TestModeToggle
        v-if="! $page.props.isPartner"
        v-model="showTestData"
      />
    </div>
  </div>

  <div v-if="loadingAnalytics">
    <p>{{ $t('panel.dashboard:analytics:loading-line-1') }}</p>
    <p class="mb-2">
      {{ $t('panel.dashboard:analytics:loading-line-2') }}
    </p>
    <Spinner />
  </div>

  <div v-if="!loadingAnalytics">
    <div class="mb-12">
      <Stack vertical>
        <StackItem>
          <div class="flex justify-between">
            <Heading class="mb-4 text-slate-500">
              {{ $t('panel.global.top-bar.requests') }}
            </Heading>
            <Link :href="$route('panel.settings.general.request-statuses')">
              {{ $t('panel.dashboard:manage-request-statuses') }}
            </Link>
          </div>

          <div class="grid grid-cols-3 flex-wrap gap-4 whitespace-nowrap">
            <InertiaLink
              v-for="(status, statusIndex) in requestStatuses"
              :key="statusIndex"
              class="block rounded-md border bg-white px-4 py-2 text-left shadow-sm space-y-2 hover:bg-slate-100"
              :href="$route('panel.requests', { filter: {request_status: status.id} })"
            >
              <div class="flex items-center space-x-4">
                <div
                  class="h-4 w-4 rounded-full"
                  :class="[`bg-${status.status_indicator}-500`]"
                />
                <div class="flex-1">
                  {{ status.label }}
                </div>
                <div class="text-xl font-medium">
                  {{ status.amount }}
                </div>
              </div>
            </InertiaLink>
          </div>
        </StackItem>

        <StackItem>
          <div class="mt-8 flex justify-between">
            <Heading class="mb-4 text-slate-500">
              {{ $t('panel.global.top-bar.returns') }}
            </Heading>
            <Link :href="$route('panel.settings.general.return-statuses')">
              {{ $t('panel.dashboard:manage-return-statuses') }}
            </Link>
          </div>

          <div class="grid grid-cols-3 flex-wrap gap-4 whitespace-nowrap">
            <InertiaLink
              v-for="(status, statusIndex) in returnStatuses"
              :key="statusIndex"
              class="rounded-md border bg-white px-4 py-2 shadow-sm space-y-2 hover:bg-slate-100"
              :href="$route('panel.return-orders.overview', { filter: {return_status: status.id} })"
            >
              <div class="flex items-center space-x-4">
                <div
                  class="h-4 w-4 rounded-full"
                  :class="[`bg-${status.status_indicator}-500`]"
                />
                <div class="flex-1">
                  {{ status.label }}
                </div>
                <div class="text-xl font-medium">
                  {{ status.amount }}
                </div>
              </div>
            </InertiaLink>
          </div>
        </StackItem>
      </Stack>
    </div>

    <div
      v-if="insightData !== undefined"
      class="mb-12"
    >
      <Stack vertical>
        <StackItem>
          <KPICard>
            <InsightsItem
              v-for="(analytic, index) in insightData"
              :key="index"
              :item="analytic"
            />
          </KPICard>
        </StackItem>
      </Stack>
    </div>

    <WeeklyReturnsOverviewChart :data-points="overviewDataPoints" />
  </div>
</template>
