import { onMounted, onUnmounted, ref } from 'vue';

export function useComboBoxResize() {
  const $comboBox = ref<HTMLButtonElement>();

  const comboBoxWidth = ref('auto');

  function onResized() {
    comboBoxWidth.value = $comboBox.value ? $comboBox.value.getBoundingClientRect().width + 'px' : 'auto';
  }

  onMounted(() => {
    onResized();

    window.addEventListener('resize', onResized);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onResized);
  });

  return {
    $comboBox,
    comboBoxWidth,
  };
}
