<script lang="ts" setup>
import { Button, ButtonVariant } from '@app/panel/Components';
import { type Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  form: Types['App.Models.ViewModels.FormViewModel'];
}>();

function goToReturnForm(): void {
  window.open(route('panel.form-preview-url', props.form.id), '_blank');
}
</script>

<template>
  <div class="w-full h-full flex flex-col space-y-6 items-center justify-center">
    <span class="text-sm text-slate-500 tracking-normal normal-case">{{ $t('panel.dashboard:onboarding:steps:first-return') }}</span>
    <Button
      :variant="ButtonVariant.Primary"
      @click="goToReturnForm"
    >
      {{ $t('panel.dashboard:onboarding:steps:create-test-return') }}
    </Button>
  </div>
</template>
