<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { Form as FormType, Locale } from '@app/panel/types';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Combobox,
  DataTable,
  Divider,
  Form,
  FormLayout,
  Modal,
  ModalSize,
  Select,
  TextInput,
  TextInputIconPosition,
} from '@app/panel/Components';
import { FooterActionPosition } from '@app/panel/Components/Card/types';
import { router } from '@inertiajs/vue3';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import * as Types from '@app/panel/types/generated';

type OverviewViewModel = Types.App.Http.ViewModels.Panel.Analytics.Overview.OverviewViewModel;

const props = defineProps<{
  filtersOpen: boolean;
  filter: any;
  filterTabs: OverviewViewModel['filterTabs'];
  hasActiveFilter: OverviewViewModel['hasActiveFilter'];
  states: OverviewViewModel['states'];
  forms: OverviewViewModel['forms'];
  locales: OverviewViewModel['locales'];
  returnStatuses: OverviewViewModel['returnStatuses'];
  requestStatuses: OverviewViewModel['requestStatuses'];
}>();

const emit = defineEmits<{
  change: [form: any];
}>();

const saveFilterModalOpen = ref(false);

const saveFilterForm = useForm({
  label: null,
  filters: null,
  type: 'overview',
});

const filterForm = useForm({
  filter: {
    state: [],
    form: '',
    value: { from: '', to: '' },
    quantity: { from: '', to: '' },
    locale: '',
    return_status: '',
    request_status: '',
    ...props.filter.filter,
  },
});

const onFilterChange = (): void => {
  emit('change', filterForm);
};

const formOptions = computed(() => {
  return props.forms.map((form: FormType) => {
    return {
      value: form.id,
      label: form.name,
    };
  });
});

const localeOptions = computed(() => {
  return props.locales.map((locale: Locale) => {
    return {
      value: locale.id,
      label: locale.name,
    };
  });
});

const requestStatusOptions = computed(() => {
  return props.requestStatuses.map((requestStatus) => {
    return {
      value: requestStatus.id,
      label: requestStatus.label,
    };
  });
});

const returnStatusOptions = computed(() => {
  return props.returnStatuses.map((returnStatus) => {
    return {
      value: returnStatus.id,
      label: returnStatus.label,
    };
  });
});

const manageFilterModalOpen = ref(false);

function openManageFiltersModal(): void {
  manageFilterModalOpen.value = true;
}

function closeManageFiltersModal(): void {
  manageFilterModalOpen.value = false;
}

function openSaveFilterModal(): void {
  saveFilterModalOpen.value = true;
}

function closeSaveFilterModal(): void {
  saveFilterModalOpen.value = false;

  saveFilterForm.reset();
}

function submitSaveFilter(): void {
  saveFilterForm.filters = window.location.search;
  saveFilterForm.post(route('panel.settings.filter-tabs.store'), {
    onSuccess: () => closeSaveFilterModal(),
  });
}

function deleteFilter(filterTab): void {
  router.delete(route('panel.settings.filter-tabs.delete', filterTab.id));
}

</script>

<template>
  <Card
    v-if="filtersOpen"
    :secondary-footer-actions="[
      {
        content: $t('panel.analytics:overview:filters:actions:save'),
        type: ButtonType.Submit,
        disabled: ! hasActiveFilter,
        callback: openSaveFilterModal,
      },
      {
        content: $t('panel.analytics:overview:filters:actions:manage'),
        type: ButtonType.Submit,
        disabled: !filterTabs || filterTabs.length === 0,
        callback: openManageFiltersModal,
      },
    ]"
    :footer-action-position="FooterActionPosition.Left"
  >
    <CardSection>
      <FormLayout>
        <FormLayout
          :columns="2"
        >
          <FormLayout>
            <Select
              v-model="filterForm.filter.form"
              :label="$t('panel.analytics:products:table:filters:fields:form:title')"
              :placeholder="$t('panel.analytics:overview:filters:fields:from:placeholder')"
              :options="formOptions"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.locale"
              :label="$t('panel.analytics:overview:filters:fields:locale:title')"
              :placeholder="$t('panel.analytics:overview:filters:fields:locale:placeholder')"
              :options="localeOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout
          :columns="2"
        >
          <FormLayout>
            <Select
              v-model="filterForm.filter.request_status"
              :label="$t('panel.analytics:overview:filters:fields:request-status:title')"
              :placeholder="$t('panel.analytics:overview:filters:fields:request-status:placeholder')"
              :options="requestStatusOptions"
              @change="onFilterChange"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="filterForm.filter.return_status"
              :label="$t('panel.analytics:overview:filters:fields:return-status:title')"
              :placeholder="$t('panel.analytics:overview:filters:fields:return-status:placeholder')"
              :options="returnStatusOptions"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout
          :columns="4"
        >
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.value.from"
              :label="$t('panel.analytics:overview:filters:fields:value-from')"
              type="number"
              :icon-position="TextInputIconPosition.Left"
              @change="onFilterChange"
            >
              <template #icon>
                €
              </template>
            </TextInput>
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.value.to"
              :label="$t('panel.analytics:overview:filters:fields:value-to')"
              type="number"
              :icon-position="TextInputIconPosition.Left"
              @change="onFilterChange"
            >
              <template #icon>
                €
              </template>
            </TextInput>
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="filterForm.filter.quantity.from"
              :label="$t('panel.analytics:overview:filters:fields:quantity-from')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="filterForm.filter.quantity.to"
              :label="$t('panel.analytics:overview:filters:fields:quantity-to')"
              type="number"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="2">
          <FormLayout>
            <Combobox
              v-model="filterForm.filter.state"
              :label="$t('panel.analytics:overview:filters:fields:state:title')"
              :options="states"
              @change="onFilterChange"
            />
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </CardSection>
  </Card>

  <Divider
    v-if="filtersOpen"
    class="mt-6"
  />

  <Modal
    :size="ModalSize.Small"
    :open="saveFilterModalOpen"
    @close="closeSaveFilterModal"
  >
    <Form @submit="submitSaveFilter">
      <FormLayout>
        <TextInput
          v-model="saveFilterForm.label"
          :error="saveFilterForm.errors.label"
          :label="$t('panel.analytics:overview:filters:save:title')"
          :max-length="30"
        />

        <Button
          :disabled="! saveFilterForm.isDirty"
          :type="ButtonType.Submit"
          :variant="ButtonVariant.Primary"
        >
          {{ $t('panel.analytics:overview:filters:save:button') }}
        </Button>
      </FormLayout>
    </Form>
  </Modal>

  <Modal
    :title="$t('panel.analytics:overview:filters:manage:title')"
    icon-color="blue"
    :size="ModalSize.Small"
    :open="manageFilterModalOpen"
    @close="closeManageFiltersModal"
  >
    <template #icon>
      <FunnelIcon class="w-5" />
    </template>

    <div class="p-2">
      <DataTable
        :headings="[{
          text: null,
          value: 'label'
        }]"
        :headings-visible="false"
        :items="filterTabs"
      >
        <template #actions="{ item }">
          <Button
            plain
            :variant="ButtonVariant.Critical"
            @click="deleteFilter(item)"
          >
            {{ $t('panel.analytics:overview:filters:manage:remove') }}
          </Button>
        </template>
      </DataTable>
    </div>
  </Modal>
</template>
