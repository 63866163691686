<script setup lang="ts">
import { Button, ButtonIconPosition } from '../../Button';
import { HelpText } from '../../HelpText';
import { InputLabel } from '../../InputLabel';
import { computed, onMounted } from 'vue';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { FieldRenderer } from '@app/panel/Pages/Settings/Integrations/partials';
import { PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { cloneDeep, isEmpty, transform } from 'lodash';
import { FormStructureField } from '@app/panel/Pages/Settings/Integrations/types';
import { FormLayout } from '@app/panel/Components';

const props = withDefaults(defineProps<{
  modelValue: object[] | null;
  id?: string;
  name?: string;
  label?: string;
  labelFor?: string;
  labelHidden?: boolean;
  helpText?: string;
  fields: FormStructureField[];
  error?: string;
  fieldName?: string;
}>(), {
  modelValue: () => ([]),
  id: null,
  name: null,
  label: null,
  labelFor: null,
  labelHidden: false,
  helpText: null,
  error: null,
  fieldName: null,
});

const emit = defineEmits(['update:modelValue']);

const elementId = computed<string>(() => props.id || useUniqueId('repeater'));

const template = computed<Record<string, null>>(() => {
  return transform(props.fields, (result, value) => {
    result[value.key] = null;
  }, {});
});

onMounted(() => {
  if (isEmpty(props.modelValue)) {
    emit('update:modelValue', [cloneDeep(template.value)]);
  }
});

function addItem(): void {
  emit('update:modelValue', [...props.modelValue, cloneDeep(template.value)]);
}

function removeItem(index: number): void {
  const newModelValue = props.modelValue.filter((_, position) => index !== position);
  emit('update:modelValue', newModelValue);
}

function getError(index: number, field: FormStructureField): null | string {
  const keyName = `${props.fieldName}.${index}.${field.key}`;
  const errors = props.error ?? {};

  return errors[keyName]?.replace(keyName, field.label) ?? null;
}
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="!!helpText"
    />

    <HelpText v-if="helpText">
      {{ helpText }}
    </HelpText>

    <div class="bg-slate-50 rounded-md p-4">
      <FormLayout>
        <FormLayout
          v-for="(_, index) in modelValue"
          :key="index"
        >
          <div class="flex justify-between gap-4">
            <div class="grid grid-cols-2 gap-4 w-full">
              <template
                v-for="field in fields"
                :key="field.key"
              >
                <FieldRenderer
                  :validation="field.validation"
                  :field-name="`${fieldName}[${index}].${field.key}`"
                  :label="field.label"
                  :type="field.type"
                  :placeholder="field.placeholder"
                  :fields="field.fields"
                  :options="field.options"
                  :errors="getError(index, field)"
                />
              </template>
            </div>

            <div class="self-end">
              <Button
                :icon-position="ButtonIconPosition.Center"
                @click="removeItem(index)"
              >
                <template #icon>
                  <TrashIcon />
                </template>
              </Button>
            </div>
          </div>
        </FormLayout>

        <FormLayout>
          <Button
            full-width
            @click="addItem"
          >
            <template #icon>
              <PlusIcon />
            </template>
          </Button>
        </FormLayout>
      </FormLayout>
    </div>
  </div>
</template>
