<script lang="ts" setup>
import { computed } from 'vue';
import {
  Badge,
  BadgeRounded,
  BadgeSize,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { get, isArray, isEmpty } from 'lodash';
import * as Types from '@app/panel/types/generated';

type OverviewViewModel = Types.App.Http.ViewModels.Panel.Analytics.Overview.OverviewViewModel;

const props = defineProps<{
  filterForm: any;
  resetFilter: Function;
  states: OverviewViewModel['states'];
  forms: OverviewViewModel['forms'];
  locales: OverviewViewModel['locales'];
  requestStatuses: OverviewViewModel['requestStatuses'];
  returnStatuses: OverviewViewModel['returnStatuses'];
}>();

const { t } = useI18n();

const items = [
  {
    label: t('panel.analytics:overview:filters:fields:state:title'),
    filterFormPath: 'filter.state',
    options: 'states',
    path: 'label',
  },
  {
    label: t('panel.analytics:overview:filters:fields:form:title'),
    filterFormPath: 'filter.form',
    options: 'forms',
    path: 'name',
  },
  {
    label: t('panel.analytics:overview:filters:fields:locale:title'),
    filterFormPath: 'filter.locale',
    options: 'locales',
    path: 'name',
  },
  {
    label: t('panel.analytics:overview:filters:fields:request-status:title'),
    filterFormPath: 'filter.request_status',
    options: 'requestStatuses',
    path: 'label',
  },
  {
    label: t('panel.analytics:overview:filters:fields:return-status:title'),
    filterFormPath: 'filter.return_status',
    options: 'returnStatuses',
    path: 'label',
  },
  {
    label: t('panel.analytics:overview:filters:fields:value-from'),
    filterFormPath: 'filter.value.from',
  },
  {
    label: t('panel.analytics:overview:filters:fields:value-to'),
    filterFormPath: 'filter.value.to',
  },
  {
    label: t('panel.analytics:overview:filters:fields:quantity-from'),
    filterFormPath: 'filter.quantity.from',
  },
  {
    label: t('panel.analytics:overview:filters:fields:quantity-to'),
    filterFormPath: 'filter.quantity.to',
  },
];

const filters = computed(() => {
  return items.map((item) => {
    if (item.options) {
      const filterValue = get(props.filterForm, item.filterFormPath);

      if (isArray(filterValue)) {
        const data = [];

        filterValue.forEach((value) => {
          const selectedOption = props[item.options].find((option) => {
            return option.id === value || option.value === value;
          });

          data.push(get(selectedOption, item.path));
        });

        return {
          ...item,
          value: data,
        };
      }

      const selectedOption = props[item.options].find((option) => {
        return option.id === filterValue || option.value === filterValue;
      });

      return {
        ...item,
        value: get(selectedOption, item.path),
      };
    }

    return {
      ...item,
      value: get(props.filterForm, item.filterFormPath),
    };
  });
});

const activeFilters = computed(() => {
  return filters.value.filter((item) => {
    return !isEmpty(item.value);
  });
});
</script>

<template>
  <div
    v-if="activeFilters.length > 0"
    class="flex flex-wrap flex-items-center gap-2 mt-6"
  >
    <Badge
      v-for="(filter, index) in activeFilters"
      :key="index"
      color="blue"
      :rounded="BadgeRounded.Full"
      closeable
      :size="BadgeSize.Large"
      @close="() => resetFilter(filter.filterFormPath)"
    >
      <span v-if="isArray(filter.value)">
        {{ filter.label }}: {{ filter.value.join(', ') }}
      </span>

      <span v-else-if="filter.label === 'Value from' || filter.label === 'Value to'">
        {{ filter.label }} € {{ filter.value }}
      </span>

      <span v-else>{{ filter.label }}: {{ filter.value }}</span>
    </Badge>
  </div>
</template>
