<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue';

const emit = defineEmits<{
  (e: 'files-dropped', files: File[]): void;
}>();

function preventDefault(e: Event) {
  e.preventDefault();
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

onMounted(() => {
  events.forEach((event) => {
    document.body.addEventListener(event, preventDefault);
  });
});

onUnmounted(() => {
  events.forEach((event) => {
    document.body.removeEventListener(event, preventDefault);
  });
});

const isActive = ref<boolean>(false);

const inActiveTimeout = ref(null);

function setActive() {
  isActive.value = true;
  clearTimeout(inActiveTimeout.value);
}

function setInactive() {
  inActiveTimeout.value = setTimeout(() => {
    isActive.value = false;
  }, 100);
}

function onDrop(e: DragEvent) {
  emit('files-dropped', [...e.dataTransfer.files]);

  setInactive();
}
</script>

<template>
  <div
    @drop.prevent="onDrop"
    @dragenter.prevent="setActive"
    @dragover.prevent="setActive"
    @dragleave.prevent="setInactive"
  >
    <slot :drop-zone-active="isActive" />
  </div>
</template>
