<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  Banner,
  Button,
  ButtonVariant,
  Card,
  CardSection,
  List,
  ListItem,
  Modal,
  Select,
  Stack,
  StackItem,
  Textarea,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import type { Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  gracePeriod: string;
  stripeCancellationFeedbackTypes: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['stripeCancellationFeedbackTypes'];
  endOfBillingDate: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['endOfBillingDate'];
}>();

const stoppingSubscription = ref(false);
const resumingSubscription = ref(false);
const isModalOpen = ref(false);
const comment = ref<null | string>(null);
const feedback = ref<null | string>(null);

const { t } = useI18n();

function stopSubscription(): void {
  router.delete(route('panel.account.subscription.cancel', {
    comment: comment.value,
    feedback: feedback.value,
  }), {
    preserveScroll: true,
    preserveState: true,
    onStart: () => stoppingSubscription.value = true,
    onSuccess: () => closeStopSubscriptionModal(),
    onFinish: () => stoppingSubscription.value = false,
  });
}

function resumeSubscription(): void {
  router.post(route('panel.account.subscription.resume'), {}, {
    preserveScroll: true,
    preserveState: true,
    onStart: () => resumingSubscription.value = true,
    onFinish: () => resumingSubscription.value = false,
  });
}

function closeStopSubscriptionModal(): void {
  isModalOpen.value = false;
}

function openStopSubscriptionModal(): void {
  isModalOpen.value = true;
}

const feedbackOptions = computed(() => {
  return props.stripeCancellationFeedbackTypes.map((feedbackType) => {
    return {
      value: feedbackType as string,
      label: t(`panel.stripe-cancellation-feedback-type:${feedbackType}`),
    };
  });
});
</script>

<template>
  <Card
    v-if="tenant.isCustomer && tenant.hasActiveSubscription"
    :title="$t('panel.account.billing_and_subscription.stop_subscription.card_title')"
  >
    <CardSection>
      <Stack vertical>
        <StackItem>
          <TextContainer>
            <TextStyle :shade="TextStyleShade.Subdued">
              {{ $t('panel.account.billing_and_subscription.stop_subscription.description') }}
            </TextStyle>
          </TextContainer>
        </StackItem>

        <StackItem v-if="tenant.isOnGracePeriod">
          <Banner>
            <div class="flex justify-between w-full items-center">
              <span>{{ $t('panel.account.billing_and_subscription.stop_subscription.banner', { grace_period: tenant.gracePeriod }) }}</span>
              <div class="shrink-0">
                <Button
                  :loading="resumingSubscription"
                  @click="resumeSubscription"
                >
                  <span class="text-xs">
                    {{ $t('panel.account.billing_and_subscription.stop_subscription.resume_button') }}
                  </span>
                </Button>
              </div>
            </div>
          </Banner>
        </StackItem>

        <StackItem v-if="!tenant.isOnGracePeriod">
          <Button
            :variant="ButtonVariant.Critical"
            @click="openStopSubscriptionModal"
          >
            {{ $t('panel.account.billing_and_subscription.stop_subscription.stop_subscription_button') }}
          </Button>
        </StackItem>
      </Stack>
    </CardSection>

    <CardSection>
      <Modal
        :open="isModalOpen"
        icon-color="red"
        :title="$t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.label')"
        @close="closeStopSubscriptionModal"
      >
        <template #icon>
          <ExclamationTriangleIcon class="h-6 w-6" />
        </template>

        <TextContainer :spacing="TextContainerSpacing.Loose">
          <TextStyle :shade="TextStyleShade.Subdued">
            <p>
              {{
                $t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.description', { endDate: endOfBillingDate })
              }}
            </p>
          </TextStyle>

          <TextStyle :shade="TextStyleShade.Subdued">
            <List>
              <ListItem>
                {{
                  $t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.details_list.first_item')
                }}
              </ListItem>

              <ListItem>
                {{ $t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.details_list.second_item') }}
              </ListItem>

              <ListItem>
                {{ $t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.details_list.third_item') }}
              </ListItem>
            </List>
          </TextStyle>
        </TextContainer>

        <div class="flex flex-col my-6 space-y-4">
          <Select
            v-model="feedback"
            :options="feedbackOptions"
            :label="$t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.feedback.label')"
          />
          <Textarea
            v-model="comment"
            :placeholder="$t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.comment.placeholder')"
            :label="$t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.comment.label')"
          />
        </div>

        <template #actions>
          <Button @click="closeStopSubscriptionModal">
            {{ $t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.cancel_button') }}
          </Button>

          <Button
            :variant="ButtonVariant.Critical"
            :loading="stoppingSubscription"
            :disabled="feedback === null"
            @click="stopSubscription"
          >
            {{ $t('panel.account.billing_and_subscription.subscription.stop_subscription_confirmation.proceed_button') }}
          </Button>
        </template>
      </Modal>
    </CardSection>
  </Card>
</template>
