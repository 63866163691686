<script lang="ts" setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { computed } from 'vue';
import {
  Banner,
  BannerVariant,
  ButtonType,
  Card,
  CardSection,
  Checkbox,
  Form,
  FormLayout,
  HelpText,
  InputLabel,
  Page,
  Select,
  SelectOption,
  Stack,
  StackItem,
  StackSpacing,
  Textarea,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { CardLayout } from '@returnless/focus-ui';
import { PageNavigation } from './partials';
import { TranslationInputFields, TranslationInputFieldType } from '@app/panel/Containers';
import { AppLayout } from '@app/panel/Layouts';
import { Types } from '@app/shared/types/generated-v2';
import { isEmpty } from 'lodash';
import TestModeSettingToggle from '@app/panel/Pages/Settings/Forms/partials/TestModeSettingToggle.vue';
import FeatureLimitInfo from '@app/panel/Pages/Settings/Components/FeatureLimitInfo.vue';

type CreateGeneralFormViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.CreateGeneralFormViewModel'];

const props = defineProps<CreateGeneralFormViewModel>();

const localeOptions = computed((): SelectOption[] => {
  return props.locales.map((locale) => {
    return {
      label: locale.name,
      value: locale.id,
      disabled: !returnForm.locales.includes(locale.id),
    };
  });
});

const formLocales = ref(props.locales.map((locale) => {
  return {
    isEnabled: false,
    replyToAddress: null,
    shopName: null,
    websiteUrl: null,
    locale: locale,
  };
}));

const returnForm = useForm({
  is_enabled: false,
  is_test_mode: true,
  name: null,
  description: null,
  reply_to_address: {},
  shop_name: {},
  website_url: {},
  default_locale: !isEmpty(props.locales) ? props.locales[0].id : null,
  locales: [],
});

function storeReturnForm(): void {
  returnForm
    .post(route('panel.settings.forms.store'), {
      preserveState: true,
      preserveScroll: true,
    });
}
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.general.create.card_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="storeReturnForm">
        <CardLayout>
          <FeatureLimitInfo :feature-usage-info="featureUsageInfo" />

          <Card
            :title="$t('panel.settings.forms.list_forms.settings.general.create.card_title')"
            :primary-footer-action="{
              content: $t('panel.settings.forms.list_forms.settings.general.create.save_form'),
              type: ButtonType.Submit,
              loading: returnForm.processing,
            }"
          >
            <CardSection>
              <Stack
                vertical
                :spacing="StackSpacing.ExtraLoose"
              >
                <StackItem>
                  <FormLayout>
                    <FormLayout>
                      <FormLayout>
                        <div class="space-y-6">
                          <Toggle
                            v-model="returnForm.is_enabled"
                            :title="$t('panel.settings.forms.list_forms.settings.general.create.enable_form_toggle')"
                            :subtitle="$t('panel.settings.forms.list_forms.settings.general.edit.enable_form_toggle_subtitle')"
                          />

                          <TestModeSettingToggle
                            v-model="returnForm.is_test_mode"
                            :error="featureUsageInfo.hasReachedLimit ? returnForm.errors.is_test_mode : null"
                          />
                        </div>
                      </FormLayout>
                    </FormLayout>

                    <FormLayout>
                      <TextInput
                        v-model="returnForm.name"
                        :label="$t('panel.settings.forms.list_forms.settings.general.create.form_title_input_label')"
                        :help-text="$t('panel.settings.forms.list_forms.settings.general.create.form_title_input_help_text')"
                        :error="returnForm.errors.name"
                      />
                    </FormLayout>

                    <FormLayout>
                      <Textarea
                        v-model="returnForm.description"
                        :label="$t('panel.settings.forms.list_forms.settings.general.create.description_textarea_label')"
                        :help-text="$t('panel.settings.forms.list_forms.settings.general.create.description_textarea_help_text')"
                      />
                    </FormLayout>

                    <FormLayout>
                      <TranslationInputFields
                        v-model="returnForm.website_url"
                        :locales="props.locales"
                        :errors="returnForm.errors"
                        field-name="website_url"
                        :default-locale-id="returnForm.default_locale"
                        :type="TranslationInputFieldType.TextInput"
                        :label="$t('panel.settings.forms.list_forms.settings.general.create.form_website_url_input_label')"
                      />
                    </FormLayout>

                    <!-- todo: add Webshop name field -->

                    <FormLayout>
                      <TranslationInputFields
                        v-model="returnForm.reply_to_address"
                        :locales="props.locales"
                        :errors="returnForm.errors"
                        field-name="reply_to_address"
                        :default-locale-id="returnForm.default_locale"
                        :type="TranslationInputFieldType.TextInput"
                        :label="$t('panel.settings.forms.list_forms.settings.general.create.customer_support_email_input_label')"
                        :help-text="$t('panel.settings.forms.list_forms.settings.general.create.customer_support_email_input_help_text')"
                      />
                    </FormLayout>

                    <FormLayout>
                      <TranslationInputFields
                        v-model="returnForm.shop_name"
                        :locales="props.locales"
                        :errors="returnForm.errors"
                        field-name="shop_name"
                        :default-locale-id="returnForm.default_locale"
                        :type="TranslationInputFieldType.TextInput"
                        :label="$t('panel.settings.forms.list_forms.settings.general.create.shop_name_input_label')"
                        :help-text="$t('panel.settings.forms.list_forms.settings.general.create.shop_name_input_help_text')"
                      />
                    </FormLayout>

                    <FormLayout>
                      <div>
                        <InputLabel
                          :label="$t('panel.settings.forms.list_forms.settings.general.create.languages_checkboxes_label')"
                          flush
                        />

                        <HelpText>
                          {{ $t('panel.settings.forms.list_forms.settings.general.create.languages_checkboxes_help_text') }}
                        </HelpText>

                        <div class="mt-2 mb-3">
                          <Banner
                            :open="!! returnForm.errors.locales"
                            :variant="BannerVariant.Critical"
                          >
                            {{ returnForm.errors.locales }}
                          </Banner>
                        </div>

                        <div class="space-y-1">
                          <Checkbox
                            v-for="locale in formLocales"
                            :key="locale.locale.id"
                            v-model="returnForm.locales"
                            :label="locale.locale.name"
                            :value="locale.locale.id"
                          />
                        </div>
                      </div>
                    </FormLayout>

                    <FormLayout>
                      <Select
                        v-model="returnForm.default_locale"
                        :options="localeOptions"
                        :error="returnForm.errors.default_locale"
                        :label="$t('panel.settings.forms.list_forms.settings.general.create.default_locale_label')"
                      />
                    </FormLayout>
                  </FormLayout>
                </StackItem>
              </Stack>
            </CardSection>
          </Card>
        </CardLayout>
      </Form>
    </Page>
  </AppLayout>
</template>
