<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Modal,
  ModalSize,
} from '@app/panel/Components';
import { computed, provide, ref } from 'vue';
import {
  BillingInvoiceDetailsForm,
  BillingPaymentMethodForm,
  BillingSubscriptionConfirmationForm,
} from '.';
import { App, Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { BanknotesIcon } from '@heroicons/vue/24/outline';
import TextContainer from '@app/customer/Components/TextContainer/components/TextContainer.vue';
import type { PaymentMethodForm } from '@app/panel/Pages/Account/partials/Billing/types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  open: boolean;
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  countries: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['countries'];
  paymentMethods: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['paymentMethods'];
  selectedPlan?: Types['App.Models.ViewModels.SubscriptionPlanViewModel'];
}>();

const emit = defineEmits<{
  close: [];
}>();

const invoiceDetailsForm = useForm({
  country: props.tenant.country?.id,
  name: props.tenant.name,
  invoice_email: props.tenant.invoiceEmail,
  street: props.tenant.street,
  postcode: props.tenant.postcode,
  city: props.tenant.city,
  po_number: props.tenant.poNumber,
  vat_number: props.tenant.vatNumber,
});

provide('invoiceDetailsForm', invoiceDetailsForm);

const paymentMethodForm = useForm<PaymentMethodForm>({
  payment_method: App.Enums.PaymentMethodType.SepaDebit,
  holder_name: '',
  cc_number: '',
  cc_expiration_date: '',
  cc_ccv: '',
  iban_number: '',
});

function submitPaymentMethodForm(): void {
  paymentMethodForm.post(route('panel.account.payment-methods.store'), {
    preserveScroll: true,
    preserveState: true,
  });
}

provide('paymentMethodForm', paymentMethodForm);

function updateBillingDetails(): void {
  invoiceDetailsForm.put(route('panel.account.update-billing-details'));
}

const processingSubscription = ref<boolean>(false);

function subscribe(): void {
  router.put(route('panel.account.subscription.update'), {
    product: props.selectedPlan.subscriptionProduct.id,
  }, {
    preserveScroll: true,
    preserveState: true,
    onStart: () => processingSubscription.value = true,
    onSuccess: () => emit('close'),
    onFinish: () => processingSubscription.value = false,
  });
}

const steps = computed(() => {
  return [
    {
      component: BillingInvoiceDetailsForm,
      onSubmit: updateBillingDetails,
      completed: !!(props.tenant.invoiceEmail && props.tenant.country && props.tenant.vatNumber),
      title: t('panel.account:billing:subscription:modal:invoice:title'),
      description: t('panel.account:billing:subscription:modal:invoice:description'),
      primaryAction: t('panel.global:actions:next'),
      loading: invoiceDetailsForm.processing,
      id: 'invoice-details',
    },
    {
      component: BillingPaymentMethodForm,
      onSubmit: submitPaymentMethodForm,
      completed: props.paymentMethods.length > 0,
      title: t('panel.account:billing:subscription:modal:payment-method:title'),
      description: null,
      primaryAction: t('panel.global:actions:next'),
      loading: paymentMethodForm.processing,
      id: 'payment-method',
    },
    {
      component: BillingSubscriptionConfirmationForm,
      onSubmit: subscribe,
      completed: false,
      title: t('panel.account:billing:subscription:modal:confirm:title'),
      description: t('panel.account:billing:subscription:modal:confirm:description'),
      primaryAction: t('panel.global:actions:confirm'),
      loading: processingSubscription.value,
      id: 'subscription-confirmation',
    },
  ];
},
);

const currentStep = computed(() => {
  const completedSteps = steps.value.filter((step) => step.completed === false);
  return completedSteps[0];
});
</script>

<template>
  <Modal
    :title="currentStep.title"
    :open="open"
    icon-color="blue"
    :size="ModalSize.Large"
    @close="emit('close')"
  >
    <template #icon>
      <BanknotesIcon class="size-10" />
    </template>

    <TextContainer v-if="currentStep.description">
      {{ currentStep.description }}
    </TextContainer>

    <component
      :is="currentStep.component"
      v-bind="{ ...props, id: currentStep.id, selectedPlan }"
    />

    <template #actions>
      <ButtonGroup full-width>
        <Button
          full-width
          @click="emit('close')"
        >
          {{ t('panel.global:actions:cancel') }}
        </Button>
        <Button
          :type="ButtonType.Submit"
          full-width
          :variant="ButtonVariant.Primary"
          :loading="currentStep.loading"
          :form="currentStep.id"
          @click="currentStep.onSubmit"
        >
          {{ currentStep.primaryAction }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
