<script lang="ts" setup>
import { Badge, Button, ButtonVariant, TextStyle, TextStyleFontSize, TextStyleShade } from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { ref } from 'vue';
import { BillingSubscriptionModal } from '.';
import { useI18n } from 'vue-i18n';

type SubscriptionPlanViewModel = Types.App.Models.ViewModels.SubscriptionPlanViewModel;
type SubscriptionProductViewModel = Types.App.Models.ViewModels.SubscriptionProductViewModel;

const props = defineProps<{
  plans: Types.App.Http.ViewModels.Panel.Account.BillingViewModel['plans'];
  currentProduct: Types.App.Http.ViewModels.Panel.Account.BillingViewModel['currentProduct'];
  countries: Types.App.Http.ViewModels.Panel.Account.BillingViewModel['countries'];
  tenant: Types.App.Http.ViewModels.Panel.Account.BillingViewModel['tenant'];
  paymentMethods: Types.App.Http.ViewModels.Panel.Account.BillingViewModel['paymentMethods'];
  suggestedPlanLabel: Types.App.Http.ViewModels.Panel.Account.BillingViewModel['suggestedPlanLabel'];
  hasExceededPlan: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['hasExceededPlan'];
}>();

const { t } = useI18n();

function isCurrentProduct(subscriptionProduct: SubscriptionProductViewModel): boolean {
  if (subscriptionProduct) {
    return props.currentProduct?.id === subscriptionProduct.id;
  }

  return false;
}

function getActionLabel(subscriptionPlan: SubscriptionPlanViewModel): string {
  const subscriptionProduct = subscriptionPlan.subscriptionProduct;

  if (!props.currentProduct) {
    return t('panel.account:billing:plans:labels:select-plan');
  }

  if (isCurrentProduct(subscriptionProduct)) {
    return t('panel.account:billing:plans:labels:current-plan');
  }

  return t('panel.account:billing:plans:labels:select-plan');
}

const confirmationModalOpen = ref<boolean>(false);
const selectedPlan = ref(null);

function openConfirmationModal(subscriptionPlan: SubscriptionPlanViewModel): void {
  selectedPlan.value = subscriptionPlan;
  confirmationModalOpen.value = true;
}

function closeConfirmationModal(): void {
  confirmationModalOpen.value = false;
}
</script>

<template>
  <table class="h-px w-full table-fixed">
    <thead>
      <tr>
        <th
          class="px-6 pb-6 text-left text-sm font-medium text-slate-900"
          scope="col"
        >
          <span>{{ t('panel.account:billing:plans:plan') }}</span>
        </th>

        <th
          v-for="plan in plans"
          :key="plan.id"
          class="w-1/4 px-4 pb-4 text-left text-lg font-medium leading-6 text-slate-900"
          scope="col"
        >
          <div class="flex items-center space-x-2">
            <template v-if="isCurrentProduct(plan.subscriptionProduct)">
              <CheckCircleIcon class="size-5 fill-brand-500" />
              <TextStyle
                :shade="TextStyleShade.Dense"
                :font-size="TextStyleFontSize.Large"
                strong
              >
                {{ plan.name }}
              </TextStyle>
              <Badge
                color="blue"
              >
                {{ t('panel.account:billing:plans:current') }}
              </Badge>
            </template>
            <template v-if="!isCurrentProduct(plan.subscriptionProduct)">
              <TextStyle
                :shade="TextStyleShade.Dense"
                :font-size="TextStyleFontSize.Large"
              >
                {{ plan.name }}
              </TextStyle>
              <Badge
                v-if="hasExceededPlan && suggestedPlanLabel === plan.name"
                color="green"
              >
                {{ t('panel.account:billing:plans:suggested') }}
              </Badge>
            </template>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="border-t">
      <tr>
        <th
          class="px-6 py-8 text-left align-top text-sm font-medium text-slate-900"
          scope="row"
        >
          {{ t('panel.account:billing:plans:pricing') }}
        </th>

        <template
          v-for="plan in plans"
          :key="plan.id"
        >
          <td
            v-if="plan.subscriptionProduct"
            class="px-4 py-8 align-top"
          >
            <div class="relative flex h-52 flex-col justify-between">
              <div class="space-y-5">
                <p>
                  <span class="text-2xl font-extrabold text-slate-900">{{ plan.subscriptionProduct?.price.label }}</span>
                  <span class="text-sm font-medium text-slate-400">/{{ t('panel.global:dates:month').toLowerCase() }}</span>
                </p>
                <p class="text-sm text-slate-500">
                  {{ plan.description }}
                </p>
              </div>
              <div>
                <Button
                  full-width
                  :variant="isCurrentProduct(plan.subscriptionProduct)
                    ? ButtonVariant.Primary
                    : ButtonVariant.Default"
                  :disabled="isCurrentProduct(plan.subscriptionProduct)"
                  @click="openConfirmationModal(plan)"
                >
                  {{ getActionLabel(plan) }}
                </Button>
              </div>
            </div>
          </td>
          <td
            v-if="!plan.subscriptionProduct"
            class="text-center"
          >
            <TextStyle>{{ t('panel.account:billing:plans:product-not-found') }}</TextStyle>
          </td>
        </template>
      </tr>
    </tbody>
  </table>

  <BillingSubscriptionModal
    :open="confirmationModalOpen"
    :countries="countries"
    :tenant="tenant"
    :payment-methods="paymentMethods"
    :selected-plan="selectedPlan"
    @close="closeConfirmationModal"
  />
</template>
