<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardIcon,
  CardSection,
  CardTitle,
  TextStyle,
} from '@returnless/focus-ui';
import { asset } from 'laravel-vapor';
import { usePage } from '@inertiajs/vue3';

const page = usePage();
</script>

<template>
  <div class="w-screen h-screen flex items-center justify-center">
    <div class="mx-auto max-w-2xl w-full px-4">
      <Card>
        <CardHeader>
          <CardIcon
            :source="asset('img/favicon_light.svg')"
            alt="Returnless"
          />
          <CardTitle>{{ $t('panel.account:downgrade:required-downgrade-page:title') }}</CardTitle>
        </CardHeader>
        <CardSection>
          <TextStyle subdued>
            {{ $t('panel.account:downgrade:required-downgrade-page:description', {
              currentPlan: page.props.downgradeRequiredData.currentPlan
            }) }}
          </TextStyle>
        </CardSection>
        <CardFooter>
          <ButtonGroup>
            <Button
              variant="secondary"
              :href="$route('panel.account.billing')"
            >
              {{ $t('panel.account:downgrade:global:view-upgrade-options') }}
            </Button>
            <Button :href="$route('panel.account.downgrade.tenant')">
              {{ $t('panel.account:downgrade:required-downgrade-page:downgrade-now-button') }}
            </Button>
          </ButtonGroup>
        </CardFooter>
      </Card>
    </div>
  </div>
</template>
