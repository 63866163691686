<script setup lang="ts">
import { AppLayout } from '@app/panel/Layouts';
import { Page } from '@app/panel/Components';
import { Button } from '@app/customer/Components/Button/components';
import { computed, ref } from 'vue';
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { asset } from 'laravel-vapor';

const isListening = ref<boolean>(false);
const scannerInput = ref<string>('');
const containsEnter = ref<boolean>(false);
const startTime = ref<number>(0);
const endTime = ref<number>(0);
const intervalPerCharacter = 20;

const elapsedTime = computed<number>(() => {
  return endTime.value - startTime.value;
});

const maxInterval = computed(() => {
  const input = scannerInput.value;
  const inputLength = input.length > 0
    ? input.replace(' + ENTER ', '').length + 1
    : 0;

  return inputLength * intervalPerCharacter;
});

const isFastEnough = computed(() => {
  if (elapsedTime.value === 0) {
    return false;
  }

  return elapsedTime.value <= maxInterval.value;
});

const isTooSlow = computed(() => {
  if (elapsedTime.value === 0) {
    return false;
  }

  return !isFastEnough.value;
});

const catchScannerInput = (e: KeyboardEvent) => {
  if (scannerInput.value === '') {
    startTime.value = Date.now();
    endTime.value = Date.now();

    // After 3 seconds after the first keypress, stop listening
    setTimeout(() => {
      if (scannerInput.value !== '') {
        stopListening();
      }
    }, 3000);
  }

  // If the user presses enter, stop listening
  if (e.key === 'Enter') {
    scannerInput.value += ' + ENTER ';
    containsEnter.value = true;
    endTime.value = Date.now();

    stopListening();
  }

  if (e.key.length === 1 && !e.ctrlKey && !e.metaKey && !e.altKey) {
    scannerInput.value += e.key;

    // Update end of typing moment
    endTime.value = Date.now();
  }
};

function startListening() {
  isListening.value = true;

  // Reset
  scannerInput.value = '';
  containsEnter.value = false;
  startTime.value = 0;
  endTime.value = 0;

  document.addEventListener('keypress', catchScannerInput);
}

function stopListening() {
  isListening.value = false;

  document.removeEventListener('keypress', catchScannerInput);
}

</script>

<template>
  <AppLayout
    :title="$t('panel.dashboard.page_title')"
    :listen-to-barcode-scanner="false"
  >
    <Page>
      <div class="flex gap-4">
        <Button
          :disabled="isListening"
          @click="startListening"
        >
          Start listening
        </Button>
        <Button
          :disabled="!isListening"
          @click="stopListening"
        >
          Stop listening
        </Button>
      </div>

      <div
        v-if="isListening"
        class="bg-white mt-8 p-8 border rounded-md border-slate-200 "
      >
        <img
          :src="asset('img/example-barcode.gif')"
          alt="Barcode example"
          class="mx-auto"
          loading="lazy"
        >

        <p class="text-center mt-8 text-slate-600">
          Scan this (or any other) barcode to test the scanner. <br>
          <span class="text-sm text-slate-400">(note: Not all scanners can scan a barcode from certain screens)</span>
        </p>
      </div>

      <div class="bg-white mt-8 p-4 border rounded-md border-slate-200">
        {{ scannerInput ? scannerInput : 'Waiting for input .. ' }}
      </div>

      <div class="bg-white mt-4 p-4 border rounded-md border-slate-200 flex items-center gap-4">
        <InformationCircleIcon
          v-if="scannerInput.length === 0 && !containsEnter"
          class="w-10 h-10 text-slate-300"
        />
        <CheckCircleIcon
          v-if="containsEnter"
          class="w-10 h-10 text-green-500"
        />
        <ExclamationCircleIcon
          v-if="scannerInput.length > 0 && !containsEnter"
          class="w-10 h-10 text-red-500"
        />
        <span class="font-bold">Contains Enter Key</span>
      </div>

      <div class="bg-white mt-4 p-4 border rounded-md border-slate-200 flex items-center gap-4">
        <InformationCircleIcon
          v-if="elapsedTime === 0"
          class="w-10 h-10 text-slate-300"
        />
        <CheckCircleIcon
          v-if="isFastEnough"
          class="w-10 h-10 text-green-500"
        />
        <ExclamationCircleIcon
          v-if="isTooSlow"
          class="w-10 h-10 text-red-500"
        />
        <span class="font-bold">Speed</span>
        <span>{{ elapsedTime }} ms (max {{ maxInterval }} ms)</span>
      </div>

      <div
        v-if="containsEnter && isFastEnough"
        class="bg-white mt-4 p-4 border rounded-md border-slate-200 gap-4 flex flex-col"
      >
        <p>
          If this barcode is a <strong>tracking number</strong> and related to a shipment within Returnless, you will be redirected to
          related <strong>Return</strong>.
        </p>
        <p>
          If this barcode is a <strong>product identifier like an EAN number</strong> and it's scanned on the Return Detail page on
          Returnless, the quantity of the related return item will be <strong>incremented</strong> for the items received / stock.
        </p>
      </div>
    </Page>
  </AppLayout>
</template>
