<script lang="ts" setup>
import { computed, ref } from 'vue';
import { InputLabel } from '../../InputLabel';
import { ErrorMessage } from '../../ErrorMessage';
import { HelpText } from '../../../Components/HelpText';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { Option } from '../index';
import { theme } from '../../../Composables/useTheme';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { Tooltip } from '../../Tooltip';
import { isArray, isEmpty, isNil, isString } from 'lodash';

const props = withDefaults(defineProps<{
  modelValue?: null | string | number;
  options: Option[];
  id?: string;
  label?: string;
  labelFor?: string;
  labelHidden?: boolean;
  name?: string;
  disabled?: boolean;
  helpText?: string;
  error?: string;
  showTooltipError?: boolean;
  required?: boolean;
  nullableLabel?: string;
}>(), {
  id: null,
  label: null,
  labelFor: null,
  labelHidden: false,
  name: null,
  disabled: false,
  helpText: null,
  error: null,
  showTooltipError: false,
  required: false,
  nullableLabel: null,
  modelValue: null,
});

const emit = defineEmits([
  'update:modelValue',
  'change',
]);

const elementId = computed((): string => props.id || useUniqueId('select'));

const placeholderColor = computed<string>(() => {
  const emptyValueColor = 'text-slate-400';
  const nonEmptyValueColor = 'text-slate-700';

  if (isString(props.modelValue) || isArray(props.modelValue)) {
    return isEmpty(props.modelValue)
      ? emptyValueColor
      : nonEmptyValueColor;
  }

  return isNil(props.modelValue)
    ? emptyValueColor
    : nonEmptyValueColor;
});

const classList = computed(() => {
  return [
    placeholderColor.value,
    theme(
      ['border', 'focus-border', 'focus-ring-color', 'focus-ring-size'],
      props.error ? 'critical' : 'default',
    ),
    theme('border-size'),
    theme('shadow'),
    { 'cursor-pointer ': !props.disabled },
    { 'cursor-not-allowed': props.disabled },
  ];
});

const onItemSelected = (event: InputEvent): void => {
  const target = event.target as HTMLSelectElement;

  const targetValue = target.value;

  target.value = props.modelValue as string;

  if (targetValue === '') {
    emit('update:modelValue', null);
  }

  if (targetValue !== '') {
    emit('update:modelValue', targetValue);
  }

  emit('change', event);
};

const errorIcon = ref(null);
</script>

<template>
  <div class="text-sm w-full">
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="!!helpText"
    />

    <HelpText v-if="helpText">
      {{ helpText }}
    </HelpText>

    <div class="relative">
      <select
        :id="elementId"
        :disabled="disabled"
        :value="modelValue"
        :required="required"
        data-test="select"
        :class="classList"
        class="text-sm relative pr-10 bg-white focus:outline-none block w-full appearance-none rounded-md px-3 py-2 select-none font-normal"
        @change="onItemSelected"
      >
        <option
          v-if="! nullableLabel"
          value=""
          selected
          disabled
        >
          {{ $t('panel.global:select:empty-option-label') }}
        </option>

        <option
          v-if="nullableLabel"
          value=""
        >
          {{ nullableLabel }}
        </option>

        <template
          v-for="(option, index) in options"
          :key="index"
        >
          <optgroup
            v-if="Array.isArray(option.value)"
            :label="option.label"
          >
            <option
              v-for="(subOption, subIndex) in option.value"
              :key="subIndex"
              data-testid="select-option"
              class="text-slate-700"
              :value="subOption.value"
              :disabled="option.disabled"
            >
              {{ subOption.label }}
            </option>
          </optgroup>

          <option
            v-else
            data-testid="select-option"
            class="text-slate-700"
            :value="option.value"
            :disabled="option.disabled"
          >
            {{ option.label }}
          </option>
        </template>
      </select>

      <ExclamationCircleIcon
        v-if="error"
        ref="errorIcon"
        class="h-5 w-5 fill-red-500 absolute top-1/2 -translate-y-1/2 right-8"
      />
    </div>

    <ErrorMessage
      v-if="error && !showTooltipError"
      :message="error"
    />

    <Tooltip
      v-if="showTooltipError"
      :content="error"
      :children="errorIcon"
    />
  </div>
</template>
