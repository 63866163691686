<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import { GuestLayout } from '@app/panel/Layouts';
import { Button, Form, FormLayout, TextField } from '@returnless/focus-ui';
import { asset } from 'laravel-vapor';

const props = defineProps<{
  token: string;
}>();

const form = useForm({
  token: props.token,
  email: '',
  password: '',
  password_confirmation: '',
});

function submit() {
  form.post(route('password.update'), {
    onFinish: () => form.reset('password', 'password_confirmation'),
  });
}
</script>

<template>
  <GuestLayout
    :title="$t('panel.auth.reset_password.title')"
    :hero-image="asset('img/login-background.jpeg')"
  >
    <Form @submit="submit">
      <FormLayout>
        <TextField
          v-model="form.email"
          :label="$t('panel.auth.reset_password.email_field_label')"
          :placeholder="$t('panel.auth.reset_password.email_field_placeholder')"
          auto-complete="email"
          autofocus
          name="email"
          required
          type="email"
        />

        <TextField
          v-model="form.password"
          :error="form.errors.password"
          :label="$t('panel.auth.reset_password.password_field_label')"
          :placeholder="$t('panel.auth.reset_password.password_field_placeholder')"
          auto-complete="current-password"
          name="password"
          required
          type="password"
        />

        <TextField
          v-model="form.password_confirmation"
          :error="form.errors.password_confirmation"
          :label="$t('panel.auth.reset_password.confirm_password_field_label')"
          :placeholder="$t('panel.auth.reset_password.confirm_password_field_placeholder')"
          auto-complete="new-password"
          name="password-confirmation"
          required
          type="password"
        />

        <Button
          :loading="form.processing"
          full-width
          type="submit"
          variant="primary"
        >
          {{ $t('panel.auth.reset_password.reset_button') }}
        </Button>
      </FormLayout>
    </Form>
  </GuestLayout>
</template>
