<script lang="ts" setup>
import { inject } from 'vue';
import { InertiaForm } from '@inertiajs/vue3';
import { Toggle } from '@app/panel/Components';

defineProps({
  children: {
    type: Array,
    default: () => ([]),
  },

  label: {
    type: String,
    required: true,
  },

  fieldName: {
    type: String,
    required: true,
  },

  value: {
    type: String,
    required: true,
  },

  comment: {
    type: String,
    required: false,
    default: undefined,
  },
});

const form = inject<InertiaForm<any>>('traitForm');

function isShowComment(): boolean {
  return form.showComment;
}
</script>

<template>
  <div class="my-2">
    <Toggle
      v-model="form[fieldName]"
      :title="label"
    >
      {{ label }}
    </Toggle>
  </div>
  <div
    v-show="isShowComment()"
    class="my-2"
  >
    {{ comment }}
  </div>
</template>
