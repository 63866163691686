<script lang="ts" setup>
import { computed } from 'vue';
import {
  Badge,
  BadgeRounded,
  BadgeSize,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { get, isArray, isEmpty } from 'lodash';

import { PagePropsViewModel } from './../types';

const props = defineProps<{
  filterForm: any;
  resetFilter: (filterFormPath: string) => void;
  forms: PagePropsViewModel['forms'];
  brands: PagePropsViewModel['brands'];
  suppliers: PagePropsViewModel['suppliers'];
  returnReasons: PagePropsViewModel['returnReasons'];
  locales: PagePropsViewModel['locales'];
}>();

const { t } = useI18n();

const items = [
  {
    label: t('panel.analytics:products:table:filters:fields:form:title'),
    filterFormPath: 'filter.form',
    options: 'forms',
    path: 'name',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:brand:title'),
    filterFormPath: 'filter.brand',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:supplier:title'),
    filterFormPath: 'filter.supplier',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:amount-return-orders-from'),
    filterFormPath: 'filter.amount_return_orders.from',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:amount-return-orders-to'),
    filterFormPath: 'filter.amount_return_orders.to',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:quantity-returned-from'),
    filterFormPath: 'filter.quantity_returned.from',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:quantity-returned-to'),
    filterFormPath: 'filter.quantity_returned.to',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:value-from'),
    filterFormPath: 'filter.value.from',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:value-to'),
    filterFormPath: 'filter.value.to',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:order-date-from'),
    filterFormPath: 'filter.order_date.from',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:order-date-to'),
    filterFormPath: 'filter.order_date.to',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:return-reasons'),
    filterFormPath: 'filter.return_reasons',
    options: 'returnReasons',
    path: 'label',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:category'),
    filterFormPath: 'filter.category',
  },
  {
    label: t('panel.analytics:products:table:filters:fields:locale'),
    filterFormPath: 'filter.locale',
    options: 'locales',
    path: 'name',
  },
];

const filters = computed(() => {
  return items.map((item) => {
    if (item.options) {
      const filterValue = get(props.filterForm, item.filterFormPath);

      if (isArray(filterValue)) {
        const data = [];

        filterValue.forEach((value) => {
          const selectedOption = props[item.options].find((option) => {
            return option.id === value || option.value === value;
          });

          data.push(get(selectedOption, item.path));
        });

        return {
          ...item,
          value: data,
        };
      }

      const selectedOption = props[item.options].find((option) => {
        return option.id === filterValue || option.value === filterValue;
      });

      return {
        ...item,
        value: get(selectedOption, item.path),
      };
    }

    return {
      ...item,
      value: get(props.filterForm, item.filterFormPath),
    };
  });
});

const activeFilters = computed(() => {
  return filters.value.filter((item) => {
    return !isEmpty(item.value);
  });
});
</script>

<template>
  <div
    v-if="activeFilters.length > 0"
    class="flex flex-wrap flex-items-center gap-2"
  >
    <Badge
      v-for="(filter, index) in activeFilters"
      :key="index"
      color="blue"
      :rounded="BadgeRounded.Full"
      closeable
      :size="BadgeSize.Large"
      @close="() => resetFilter(filter.filterFormPath)"
    >
      <span v-if="isArray(filter.value)">
        {{ filter.label }}: {{ filter.value.join(', ') }}
      </span>

      <span v-else-if="filter.label === 'Value from' || filter.label === 'Value to'">
        {{ filter.label }} € {{ filter.value }}
      </span>

      <span v-else>{{ filter.label }}: {{ filter.value }}</span>
    </Badge>
  </div>
</template>
