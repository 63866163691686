import { cloneDeep, isEqual } from 'lodash';
import { Ref, ref, watch } from 'vue';

type Value = string | number | boolean | object | object[];

export function useIsDirty(value: Ref<Value>) {
  const initialValue = ref<Value>(cloneDeep(value));
  const isDirty = ref<boolean>(false);

  function checkIsDirty(): boolean {
    return !isEqual(initialValue.value, value.value);
  }

  watch(value,
    () => isDirty.value = checkIsDirty(),
    { deep: true },
  );

  function reset(newValue: Value = null): void {
    initialValue.value = cloneDeep(newValue || value.value);
    isDirty.value = false;
  }

  return {
    value,
    isDirty,
    reset,
  };
}
