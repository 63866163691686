<script lang="ts" setup>
import {
  Card,
  CardSection,
  List,
  ListStyle,
  Toggle,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { inject, ref, watch } from 'vue';
import { router } from '@inertiajs/vue3';

const returnOrder = inject<Types.App.Models.ViewModels.ReturnOrderViewModel>('returnOrder');

const freeShipping = ref(returnOrder.value.freeShipping);

watch(freeShipping, () => {
  router.put(route('panel.requests.update', { returnOrder: returnOrder.value }), {
    free_shipping: freeShipping.value,
  }, {
    preserveState: true,
    preserveScroll: true,
  });
});

</script>

<template>
  <Card :title="$t('panel.requests.detail_free_shipping.card_title')">
    <CardSection>
      <List
        :style="ListStyle.None"
        flush
        class="divide-y space-y-2"
      >
        <Toggle
          v-model="freeShipping"
          :title="$t('panel.requests.detail_free_shipping.toggle_label')"
        />
      </List>
    </CardSection>
  </Card>
</template>
