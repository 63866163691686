<script lang="ts" setup>
import { computed, inject, PropType } from 'vue';
import * as Types from '@app/panel/types/generated';
import { TextStyle } from '@app/panel/Components';
import {
  IReturnOrderItemDepreciation,
  ReturnOrderItemDepreciation,
} from '@app/panel/Containers/ReturnOrder/components/ReturnOrderItemDepreciation';
import { CheckIcon } from '@heroicons/vue/24/solid';
import { Checkbox } from '@app/panel/Components';
import { useI18n } from 'vue-i18n';
import { InertiaForm } from '@inertiajs/vue3';
import { round } from 'lodash';

const props = defineProps({
  item: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnOrderItemViewModel>,
    required: true,
  },

  subtractDepreciation: {
    type: Boolean,
    default: false,
  },
});

const form = inject<InertiaForm<any>>('traitForm');

const price = computed(() => {
  return Number(props.item.salesOrderItem.price.amount);
});

const quantity = computed(() => {
  return Number(props.item.quantity);
});

const returnOrderItemDepreciations = computed((): IReturnOrderItemDepreciation[] => {
  return props.item.depreciations
    .map(({ id, value, type, comment }) => {
      return new ReturnOrderItemDepreciation(price.value, id, parseFloat(value), type, comment);
    });
});

const totalValue = computed(() => {
  return round((price.value * quantity.value) / 100, 2);
});

const totalDepreciation = computed(() => {
  return returnOrderItemDepreciations.value.reduce((acc, item) => {
    return acc + item.calculateValue();
  }, 0) / 100;
});

const totalDepreciationPercentage = computed(() => {
  return round(totalDepreciation.value / (totalValue.value / 100), 2);
});

const hasDepreciation = computed(() => {
  return totalDepreciation.value && totalDepreciation.value > 0;
});

const { t } = useI18n();
const formattedTotalDepreciation = computed(() => {
  return `${props.item.totalDepreciation.label} ${t('panel.containers.depreciation_item.depreciation')} (${totalDepreciationPercentage.value}%)`;
});
</script>

<template>
  <div class="py-4">
    <TextStyle strong>
      {{ item.quantity }}x {{ item.salesOrderItem.productName ? item.salesOrderItem.productName : item.product.name }}: {{ item.salesOrderItem.price.label }}
    </TextStyle>

    <div
      v-if="hasDepreciation && subtractDepreciation"
      class="mt-1"
    >
      <span class="text-orange-600">
        <Checkbox
          v-model="form.subtractDepreciation[item.id]"
          :label="formattedTotalDepreciation"
        />
        <span v-if="!subtractDepreciation">{{ formattedTotalDepreciation }}</span>
      </span>
    </div>

    <div
      v-if="!hasDepreciation"
      class="flex space-x-1 mt-1"
    >
      <CheckIcon class="h-5 w-5 text-green-500" />
      <span>{{ $t('panel.containers.depreciation_item.no_depreciation') }}</span>
    </div>
  </div>
</template>
