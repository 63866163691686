<script lang="ts" setup>
import { computed, PropType, reactive, ref } from 'vue';
import { Bar } from 'vue-chartjs';
import { TextStyle } from '../../TextStyle';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { ChartColor, chartColors, options } from '../config';
import { cloneDeep } from 'lodash';

ChartJS.register([
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
]);

const props = defineProps({
  labels: {
    type: Array as PropType<string[]>,
    default: () => [],
  },

  datasets: {
    type: Array as PropType<unknown>,
    default: () => [],
  },

  height: {
    type: Number,
    default: 80,
  },

  stacked: {
    type: Boolean,
    default: false,
  },
});

const computedDatasets = computed(() => {
  return props.datasets.map((dataset, index) => {
    return Object.assign(dataset, chartColors[index]);
  });
});

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: computedDatasets.value,
  };
});

const legendLabels = computed(() => {
  return props.datasets.reduce((previous: string[], current) => {
    if (current['label']) {
      previous.push(current['label']);
    }

    return previous;
  }, []);
});

const barChartOptions = cloneDeep(options);

barChartOptions.scales.x.grid.drawTicks = true;
barChartOptions.scales.y.grid.display = true;

if (props.stacked) {
  barChartOptions.scales.x.stacked = true;
  barChartOptions.scales.y.stacked = true;
}

const bar = ref(null);
const active = reactive({
  data: null,
  label: null,
});

function renderActiveElementIndicator(ctx, metaData, index) {
  ctx.beginPath();
  ctx.fillStyle = chartColors[index]['activeColor'];
  ctx.fillRect(metaData.x - (metaData.width / 2), metaData.base, metaData.width, -metaData.height);
  ctx.closePath();

  ctx.beginPath();
  ctx.setLineDash([2, 5]);
  ctx.moveTo(metaData.x, metaData.base);
  ctx.lineTo(metaData.x, 0);
  ctx.strokeStyle = ChartColor.Slate400;
  ctx.stroke();
  ctx.setLineDash([]);
  ctx.closePath();
}

function resetActiveData(bar) {
  active.data = null;
  active.label = null;

  bar.updateChart();
}

function trackActiveBarElement(e, bar) {
  e.preventDefault();
  e.stopPropagation();

  bar.updateChart();

  const barChart = bar.chart;
  const { ctx } = barChart;

  const rect = barChart.canvas.getBoundingClientRect();
  const x = e.clientX - rect.left;

  barChart.data.datasets.forEach((_, i) => {
    barChart.getDatasetMeta(i).data.forEach((metaData, index) => {
      const barLeftX = metaData.x - (metaData.width / 2);
      const barRightX = barLeftX + metaData.width;

      if (x >= barLeftX && x <= barRightX) {
        active.data = barChart.data.datasets[i].data[index];
        active.label = barChart.data.labels[index];

        renderActiveElementIndicator(ctx, metaData, i);
      }
    });
  });
}

function onChartRender() {
  bar.value.chart.canvas.addEventListener('mousemove', (e) => trackActiveBarElement(e, bar.value), true);

  bar.value.chart.canvas.addEventListener('mouseleave', () => resetActiveData(bar.value));
}
</script>

<template>
  <div>
    <div class="ml-5">
      <div class="flex justify-between">
        <slot
          name="active-data"
          :data="active.data"
        />

        <div class="flex space-x-4 ml-auto">
          <div
            v-for="(label, index) in legendLabels"
            :key="index"
            class="flex items-center space-x-2"
          >
            <div
              class="rounded-full w-4 h-4"
              :style="{backgroundColor: chartColors[index].backgroundColor}"
            />

            <TextStyle>
              {{ label }}
            </TextStyle>
          </div>
        </div>
      </div>
      <slot
        name="active-label"
        :label="active.label"
      />
    </div>

    <Bar
      ref="bar"
      :chart-data="chartData"
      :chart-options="barChartOptions"
      :height="height"
      @chart:rendered="onChartRender"
    />
  </div>
</template>
