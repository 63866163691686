<script lang="ts" setup>
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Button,
  ButtonContent,
  ButtonIcon,
  DataList,
  DataListContent,
  DataListItem,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { ref, toRef } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { router, useForm } from '@inertiajs/vue3';
import { EllipsisHorizontalIcon, PencilIcon, PlusIcon } from '@heroicons/vue/16/solid';
import CreateNoteTemplateModal from './Components/CreateNoteTemplateModal.vue';
import { PagePropsViewModel } from './types';

const props = defineProps<PagePropsViewModel>();

const statusForm = useForm<{
  label: string | null;
  translations: Record<string, string>;
  is_default_status: boolean;
  visible_on_tracking_page: boolean;
  should_notify_user: boolean;
}>({
  label: null,
  translations: {},
  is_default_status: false,
  visible_on_tracking_page: false,
  should_notify_user: true,
});

function resetStatusForm(): void {
  statusForm.reset();
  statusForm.clearErrors();
}

const isCreateStatusModalOpen = ref(false);

function openCreateNoteTemplateModal(): void {
  isCreateStatusModalOpen.value = true;
}

function closeCreateNoteTemplateModal(): void {
  isCreateStatusModalOpen.value = false;

  resetStatusForm();
}

const noteTemplates = toRef(props, 'noteTemplates');

function saveSortedItems(noteTemplates: string[]): void {
  router.put(route('panel.settings.note-templates.sort-order'), { ids: noteTemplates }, {
    preserveScroll: true,
    preserveState: false,
  });
}

</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:note-templates:head')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:templates:note-templates:title') }}</PageTitle>
        <PageDescription>{{ $t('panel.settings:templates:note-templates:subtitle') }}</PageDescription>
        <template
          v-if="noteTemplates.length !> 0"
          #page-header-actions
        >
          <Button
            @click="openCreateNoteTemplateModal"
          >
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:templates:note-templates:create') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <DataList
        v-if="noteTemplates.length > 0"
        sortable
        @sort="saveSortedItems"
      >
        <DataListItem
          v-for="noteTemplate in noteTemplates"
          :id="noteTemplate.id"
          :key="noteTemplate.id"
        >
          <DataListContent>
            {{ noteTemplate.title }}
          </DataListContent>

          <ActionList alignment="end">
            <ActionListTrigger>
              <Button
                icon-only
                variant="ghost"
                size="small"
              >
                <ButtonIcon :icon="EllipsisHorizontalIcon" />
              </Button>
            </ActionListTrigger>
            <ActionListBody>
              <ActionListSection label="Actions">
                <ActionListItem
                  :icon="PencilIcon"
                  as="button"
                  :href="$route('panel.settings.note-templates.edit', noteTemplate.id)"
                >
                  {{ $t('panel.global:actions:edit') }}
                </ActionListItem>
              </ActionListSection>
            </ActionListBody>
          </ActionList>
        </DataListItem>
      </DataList>
      <EmptyState v-else>
        <EmptyStateContent>
          <EmptyStateTitle>{{ $t('panel.settings:templates:note-templates:empty:title') }}</EmptyStateTitle>
          <EmptyStateDescription>
            {{
              $t('panel.settings:templates:note-templates:empty:description')
            }}
          </EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button @click="openCreateNoteTemplateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:templates:note-templates:create') }}</ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>

  <CreateNoteTemplateModal
    :open="isCreateStatusModalOpen"
    @close="closeCreateNoteTemplateModal"
  />
</template>
