<script lang="ts" setup>
import {
  computed,
  PropType,
  ref,
  watch,
} from 'vue';
import {
  HelpText,
  InputLabel,
  Textarea,
  TextInput,
} from '@app/panel/Components';
import { ChevronDownIcon, LanguageIcon } from '@heroicons/vue/24/outline';
import { TranslationInputFieldType } from '../types';
import * as Types from '@app/panel/types/generated';
import { entries, values } from 'lodash';

const props = defineProps({
  type: {
    type: String as PropType<TranslationInputFieldType>,
    validator: (val: TranslationInputFieldType) =>
      Object.values(TranslationInputFieldType).includes(val),
    required: true,
  },

  fieldName: {
    type: String,
    required: true,
  },

  locales: {
    type: Array as PropType<Types.App.Models.ViewModels.LocaleViewModel[]>,
    required: true,
  },

  defaultLocaleId: {
    type: String,
    default: null,
  },

  modelValue: {
    type: Object,
    required: true,
  },

  label: {
    type: String,
    default: null,
  },

  helpText: {
    type: String,
    default: null,
  },

  errors: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const isDropdownOpen = ref(false);

function toggleDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
}

function openDropdown() {
  isDropdownOpen.value = true;
}

const currentComponent = computed(() => {
  switch (props.type) {
    case 'textarea':
      return Textarea;

    case 'text-input':
    default:
      return TextInput;
  }
});

const defaultLocale = computed(() => {
  return props.locales.find((locale) => locale.id === props.defaultLocaleId);
});

const translations = ref(props.modelValue);

function onTranslationsTextInputChange() {
  emit('update:modelValue', translations.value);
}

watch(() => props.errors, () => {
  const errorKeys = Object.keys(props.errors);

  const hasErrorsInKeys = errorKeys.some((key) => key.includes(props.fieldName));
  const hasErrorsExact = values(props.errors[props.fieldName]).length > 0;

  if (hasErrorsInKeys || hasErrorsExact) {
    openDropdown();
  }
});

const computedErrors = computed((): Record<string, string> => {
  return entries(props.errors).reduce((acc, [key, value]) => {
    if (key.startsWith(props.fieldName)) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});
});
</script>

<template>
  <div v-if="defaultLocale">
    <InputLabel :label="label ? `${label} (${defaultLocale.name})` : defaultLocale.name" />

    <HelpText v-if="helpText">
      {{ helpText }}
    </HelpText>

    <component
      :is="currentComponent"
      v-model="translations[defaultLocale.id]"
      :name="fieldName + `-${defaultLocale.name}`"
      :error="computedErrors[fieldName] || computedErrors[`${fieldName}.${defaultLocale.id}`]"
    >
      <template #icon>
        <div
          v-if="locales.length > 1"
          class="flex items-center justify-center text-slate-400 hover:text-brand-500 h-full w-full cursor-pointer"
          @click="toggleDropdown"
        >
          <LanguageIcon class="h-5 w-5" />

          <ChevronDownIcon class="h-4 w-4" />
        </div>
      </template>
    </component>

    <div
      v-if="isDropdownOpen"
      class="bg-slate-50 p-4 space-y-3"
    >
      <template
        v-for="locale in locales"
        :key="locale.id"
      >
        <component
          :is="currentComponent"
          v-if="(locale.id !== defaultLocale.id)"
          v-model="translations[locale.id]"
          :name="fieldName + `-${locale.name}`"
          :label="label ? `${label} (${locale.name})` : locale.name"
          :error="computedErrors[`${fieldName}.${locale.id}`]"
          @change="onTranslationsTextInputChange"
        />
      </template>
    </div>
  </div>
</template>
