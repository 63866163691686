<script lang="ts" setup>
import { Badge, ToastVariant } from '@app/panel/Components';
import { useClipboard } from '@app/panel/Composables/useClipboard';
import { useToast } from '@app/panel/Composables/useToast';
import { useI18n } from 'vue-i18n';

const { writeTextToClipboard } = useClipboard();
const { emitToastEvent } = useToast();
const { t } = useI18n();

defineProps<{
  parameters: string[];
}>();

function copyParameter(parameter: string) {
  writeTextToClipboard(parameter);

  emitToastEvent({
    message: t('panel.settings:templates:create:copied'),
    variant: ToastVariant.Info,
  });
}

</script>

<template>
  <div class="flex flex-wrap gap-2 cursor-pointer mb-2">
    <Badge
      v-for="parameter in parameters"
      :key="parameter"
      color="blue"
      @click="copyParameter(parameter)"
    >
      <div class="py-1">
        <span
          class="font-mono"
          v-text="parameter"
        />
      </div>
    </Badge>
  </div>
</template>
