<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  Modal,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { BookOpenIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { PropType } from 'vue';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;
type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

defineEmits<{
  (e: 'verify'): void;
}>();

const form = useForm({
  title: '',
  description: '',
  company_id: '',
  project_id: '',
  api_token: '',
});

function verify(): void {
  form.submit('post', route('panel.integration.channable.save-credentials'));
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
          alt="Channable logo"
        >
      </div>

      <Heading strong>
        {{ $t('panel.integrations:channable:form:header') }}
      </Heading>
    </div>

    <FormLayout>
      <FormLayout>
        <TextInput
          v-model="form.title"
          :label="$t('panel.integrations:channable:form:title')"
          :error="form.errors.title"
        />
      </FormLayout>

      <FormLayout>
        <TextInput
          v-model="form.description"
          :label="$t('panel.integrations:channable:form:label:description')"
          :error="form.errors.description"
        />
      </FormLayout>

      <FormLayout>
        <TextInput
          v-model="form.company_id"
          :label="$t('panel.integrations:channable:form:label:company_id')"
          :error="form.errors.company_id"
        />
      </FormLayout>

      <FormLayout>
        <TextInput
          v-model="form.project_id"
          :label="$t('panel.integrations:channable:form:label:project_id')"
          :error="form.errors.project_id"
        />
      </FormLayout>
      <FormLayout>
        <TextInput
          v-model="form.api_token"
          :label="$t('panel.integrations:channable:form:label:api_token')"
          :error="form.errors.api_token"
          :type="TextInputFieldTypes.Password"
        />
      </FormLayout>
    </FormLayout>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.integrations:channable:form:guide_button') }}</span>
        </Button>

        <Button
          :variant="ButtonVariant.Primary"
          full-width
          :loading="form.processing"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:channable:form:verify_integration') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
