<script lang="ts" setup>
import {
  computed,
} from 'vue';
import {
  Pagination,
  Tabs,
  TabsItem,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import { PagePropsViewModel } from './../types';

defineProps<{
  previousPageUrl: PagePropsViewModel['previousPageUrl'];
  nextPageUrl: PagePropsViewModel['nextPageUrl'];
  filterTabs: PagePropsViewModel['filterTabs'];
}>();

const activeFilterTab = computed(() => {
  return new URLSearchParams(window.location.search).get('active-tab');
});

function setFilter(tab) {
  router.visit(route('panel.analytics.products') + `?${tab.queryString}&active-tab=${tab.id}`);
}

function resetFilters(): void {
  router.visit(route('panel.analytics.products'));
}
</script>

<template>
  <div class="flex justify-between items-center">
    <Tabs>
      <TabsItem
        :active="! activeFilterTab"
        @click="resetFilters"
      >
        {{ $t('panel.analytics:products:table:tabs:all') }}
      </TabsItem>

      <div
        v-for="tab in filterTabs"
        :key="tab.id"
      >
        <TabsItem
          :active="activeFilterTab === tab.id"
          type="button"
          @click="setFilter(tab)"
        >
          {{ tab.label }}
        </TabsItem>
      </div>
    </Tabs>

    <div>
      <Pagination
        :href-next="nextPageUrl"
        :href-previous="previousPageUrl"
      />
    </div>
  </div>
</template>
