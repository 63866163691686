<script lang="ts" setup>
import { computed, inject } from 'vue';
import { Navigation, NavigationItem, NavigationSecondarySection, NavigationSection } from '@returnless/focus-ui';
import {
  BoltIcon,
  BuildingStorefrontIcon,
  CogIcon,
  CubeIcon,
  HandThumbDownIcon,
  LinkIcon,
  PhotoIcon,
  QuestionMarkCircleIcon,
  ShareIcon,
  ShieldCheckIcon,
  TruckIcon,
} from '@heroicons/vue/16/solid';
import type { Types } from '@app/shared/types/generated-v2';
import { usePage } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

type FormViewModel = Types['App.Models.ViewModels.FormViewModel'];

const { t } = useI18n();

const form: FormViewModel = inject('form', null);

const isDisabled = computed(() => {
  return route().current('panel.settings.forms.create');
});

const isFormVirtual = computed(() => {
  return form && form.isVirtual;
});
</script>

<template>
  <Navigation>
    <NavigationSection>
      <NavigationItem
        :active="form ? $route().current('panel.settings.forms.general') :
          $route().current('panel.settings.forms.create')"
        :href="form && form['id'] ? $route('panel.settings.forms.general', form.id) :
          $route('panel.settings.forms.create')"
        :icon="CogIcon"
      >
        {{ $t('panel.settings.forms.navigation.general') }}
      </NavigationItem>

      <NavigationItem
        :active="$route().current('panel.settings.forms.layout')"
        :href="form && $route('panel.settings.forms.layout', { form })"
        :disabled="isDisabled"
        :icon="PhotoIcon"
      >
        {{ $t('panel.settings.forms.navigation.logo_and_layout') }}
      </NavigationItem>
    </NavigationSection>

    <NavigationSection :label="t('panel.settings.forms.navigation.workflow_management')">
      <NavigationItem
        v-if="!isFormVirtual"
        :active="$route().current('panel.settings.forms.return-reasons')"
        :href="form && $route('panel.settings.forms.return-reasons', form)"
        :disabled="isDisabled"
        :icon="HandThumbDownIcon"
      >
        {{ $t('panel.settings.forms.navigation.return_reasons') }}
      </NavigationItem>

      <NavigationItem
        v-if="!isFormVirtual"
        :active=" $route().current('panel.settings.forms.return-questions') ||
          $route().current('panel.settings.forms.return-questions.create') ||
          $route().current('panel.settings.forms.return-questions.show')
        "
        :href=" form && $route('panel.settings.forms.return-questions', form)"
        :disabled="isDisabled"
        :icon="QuestionMarkCircleIcon"
      >
        {{ $t('panel.settings.forms.navigation.return_questions') }}
      </NavigationItem>

      <NavigationItem
        v-if="!isFormVirtual"
        :active="$route().current('panel.settings.forms.return-rules') ||
          $route().current('panel.settings.form.return-rules.create') ||
          $route().current('panel.settings.form.item-rules.create') ||
          $route().current('panel.settings.form.return-rules.show')"
        :href="form && $route('panel.settings.forms.return-rules', form)"
        :disabled="isDisabled"
        :icon="ShieldCheckIcon"
      >
        {{ $t('panel.settings.forms.navigation.return_rules') }}
      </NavigationItem>

      <NavigationItem
        :active="$route().current('panel.settings.forms.automations') ||
          $route().current('panel.settings.forms.automations.create') ||
          $route().current('panel.settings.forms.automations.edit')"
        :href=" form && $route('panel.settings.forms.automations', form)"
        :disabled="isDisabled"
        :icon="BoltIcon"
      >
        {{ $t('panel.settings.forms.navigation.automations') }}
      </NavigationItem>
    </NavigationSection>

    <NavigationSection
      v-if="!isFormVirtual"
      :label="t('panel.settings.forms.navigation.shipping')"
    >
      <NavigationItem
        v-if="!isFormVirtual"
        :active="$route().current('panel.settings.forms.store-locations')"
        :href="form && $route('panel.settings.forms.store-locations', form)"
        :disabled="isDisabled"
        :icon="BuildingStorefrontIcon"
      >
        {{ $t('panel.settings.forms.navigation.store_locations') }}
      </NavigationItem>

      <NavigationItem
        v-if="!isFormVirtual"
        :active="$route().current('panel.settings.forms.shipping-settings.show')"
        :href="form && $route('panel.settings.forms.shipping-settings.show', form)"
        :disabled="isDisabled"
        :icon="TruckIcon"
      >
        {{ $t('panel.settings.forms.navigation.shipping_settings') }}
      </NavigationItem>

      <NavigationItem
        v-if="!isFormVirtual"
        :active="$route().current('panel.settings.forms.shipping-methods')"
        :href="form && $route('panel.settings.forms.shipping-methods', form)"
        :disabled="isDisabled"
        :icon="CubeIcon"
      >
        {{ $t('panel.settings.forms.navigation.shipping_methods') }}
      </NavigationItem>

      <NavigationSecondarySection v-if="form && usePage().props.contractTabs">
        <NavigationItem
          v-for="contract in usePage().props.contractTabs"
          :key="contract.id"
          :active="usePage().props.selectedContract?.id == contract.id"
          :href="contract.id === null ? $route('panel.settings.forms.shipping-methods', form) : `?contract=${contract.id}`"
        >
          {{ contract.label }}
        </NavigationItem>
      </NavigationSecondarySection>
    </NavigationSection>

    <NavigationSection :label="t('panel.settings.forms.navigation.advanced_settings')">
      <NavigationItem
        v-if="!isFormVirtual"
        :active="$route().current('panel.settings.forms.integrations')"
        :href="form && $route('panel.settings.forms.integrations', form)"
        :disabled="isDisabled"
        :icon="ShareIcon"
      >
        {{ $t('panel.settings.forms.navigation.integrations') }}
      </NavigationItem>

      <NavigationItem
        :label="$t('panel.settings.forms.navigation.domains')"
        :active="$route().current('panel.settings.forms.domains')"
        :href="form && $route('panel.settings.forms.domains', form)"
        :disabled="isDisabled"
        :icon="LinkIcon"
      >
        {{ $t('panel.settings.forms.navigation.domains') }}
      </NavigationItem>
    </NavigationSection>
  </Navigation>
</template>
