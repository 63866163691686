<script lang="ts">
import {
  defineComponent,
  h,
  VNode,
} from 'vue';

export default defineComponent({
  setup(_, { slots }) {
    return () => h('div', { class: 'float-right sticky top-0 right-4 max-w-sm h-0 w-full z-50' }, Object.values(slots.default()[0].children).map((el: VNode) => {
      return h(el, { class: 'relative mt-2' });
    }));
  },
});
</script>
