<script lang="ts" setup>
import { computed, inject, provide, Ref } from 'vue';
import { Button, ButtonType, ButtonVariant, Form } from '@app/panel/Components';
import RecursiveBlockRenderer from '../RecursiveBlockRenderer.vue';
import * as Types from '@app/panel/types/generated';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { formKeyTransformer } from '@app/panel/Utils';
import { Trait } from '@app/panel/Pages/Returns/partials/Trait/types';
import { useTrait } from '../Composables/useTrait';
import { ModalActions, ModalBody } from '@app/panel/Components';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useValueOfDepreciatedItems } from '@app/panel/Pages/Returns/partials/Trait/Composables/useValueOfDepreciatedItems';

type ReturnOrderItemViewModel = Types.App.Models.ViewModels.ReturnOrderItemViewModel;

const props = defineProps<{
  trait: Trait;
  salesOrder: Types.App.Models.ViewModels.SalesOrderViewModel;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const returnOrderItems = inject<Ref<ReturnOrderItemViewModel[]>>('returnOrderItems');

const { valueOfReturnedItems } = useTrait(props.salesOrder, returnOrderItems);

const traitForm = useForm({
  subtractDepreciation: {},
  platformIntegrationTenantId: props.trait.platformIntegrationTenantId,
  refundShipping: false,
});

const { valueOfDepreciatedItems } = useValueOfDepreciatedItems(returnOrderItems, traitForm);

const computedValues = computed(() => {
  return {
    valueOfReturnedItems: valueOfReturnedItems.value,
    valueOfDepreciatedItems: Math.abs(valueOfDepreciatedItems.value) * -1,
    shippingCosts: props.salesOrder.shippingAmount.amount,
  };
});

const precomputedValues = computed(() => {
  return {
    ...computedValues.value,
    total: total.value,
  };
});

const total = computed(() => {
  let _total = 0;

  _total += Number(computedValues.value.valueOfReturnedItems);
  _total += Number(computedValues.value.valueOfDepreciatedItems);
  _total += (traitForm.refundShipping ? computedValues.value.shippingCosts : 0);

  return _total;
});

const postValues = computed(() => {
  return formKeyTransformer({
    ...traitForm.data(),
    total: precomputedValues.value.total,
  });
});

function submitForm() {
  router.post(
    route('panel.return-orders.credit-invoice.create', { returnOrder: returnOrder.value }),
    (postValues.value),
    {
      preserveScroll: true,
      onSuccess: () => {
        emits('close');
      },
    },
  );
}

provide('traitForm', traitForm);
provide('precomputedValues', precomputedValues);

const formId = computed(() => useUniqueId('refund-trait'));
</script>

<template>
  <Form
    :id="formId"
    @submit="submitForm"
  >
    <ModalBody>
      <RecursiveBlockRenderer :children="trait.children" />
    </ModalBody>

    <ModalActions>
      <Button @click="emits('close')">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
        :form="formId"
      >
        {{ $t('panel.returns:detail-page:trait:create-refund:cta') }}
      </Button>
    </ModalActions>
  </Form>
</template>
