import { router } from '@inertiajs/vue3';
import { first, isEmpty } from 'lodash';
import { ref } from 'vue';

export function useErrorHandler() {
  const handlers = ref([]);

  function collectElements() {
    return document.querySelectorAll('.error-message, .banner, .tooltip');
  }

  function scrollToElement(element: Element): void {
    element.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  function scrollToMessage(message: string): void {
    const elements = collectElements();

    for (const element of elements) {
      if (element.textContent === message) {
        scrollToElement(element);
        break;
      }
    }
  }

  function scrollToFirstError(): void {
    const elements = collectElements();

    if (isEmpty(elements)) {
      return;
    }

    scrollToElement(first(elements));
  }

  function register() {
    const hasError = ref(false);

    router.on('error', () => hasError.value = true);

    router.on('finish', () => {
      if (!hasError.value) {
        return;
      }

      scrollToFirstError();

      hasError.value = false;
    });
  }

  function remove() {
    handlers.value.forEach((removeHandler) => removeHandler());
  }

  return { register, remove, scrollToMessage };
}
