<script lang="ts" setup>
import { computed, ref } from 'vue';
import ErrorMessage from '../../ErrorMessage/components/ErrorMessage.vue';
import { InputLabel } from '../../InputLabel';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { ExclamationCircleIcon } from '@heroicons/vue/24/solid';
import { theme } from '../../../Composables/useTheme';
import { Tooltip } from '../../Tooltip';

const ELEMENT_ID = useUniqueId('textField');

const props = withDefaults(defineProps<{
  label: string;
  error?: string;
  showTooltipError?: boolean;
  disabled?: boolean;
}>(), {
  error: null,
  showTooltipError: false,
  disabled: false,
});

const modelValue = defineModel<string>();

const $input = ref<HTMLInputElement>();
const $errorIcon = ref<HTMLElement>();

const borderColorClass = computed(() => [
  theme(
    ['border', 'focus-within-border-color', 'focus-within-ring-color'],
    props.error ? 'critical' : 'default',
  ),
]);

function onChanged(e: InputEvent): void {
  modelValue.value = (e.target as HTMLFormElement).value;
}
</script>

<template>
  <div class="w-full">
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="ELEMENT_ID"
    />

    <div class="relative">
      <div class="flex">
        <div
          data-test="text-input"
          class="relative flex w-full items-center overflow-hidden rounded-md border bg-white shadow-sm focus-within:outline-none focus-within:ring-2 focus:outline-none"
          :class="[borderColorClass]"
        >
          <div
            v-if="error"
            class="absolute right-3"
          >
            <ExclamationCircleIcon
              ref="$errorIcon"
              class="size-5 fill-red-500"
            />
          </div>

          <input
            :id="ELEMENT_ID"
            ref="$input"
            type="date"
            class="block w-full appearance-none border-none px-3 py-2 text-slate-700 outline-none placeholder:text-slate-400 disabled:cursor-not-allowed disabled:bg-slate-50"
            :class="{
              'mr-6': error,
            }"
            :aria-disabled="disabled"
            :disabled="disabled"
            tabindex="0"
            :value="modelValue"
            v-bind="$attrs"
            @change="onChanged"
          >
        </div>
      </div>
    </div>

    <ErrorMessage
      v-if="error && !showTooltipError"
      :message="error"
    />

    <Tooltip
      v-if="showTooltipError"
      :content="error"
      :children="$errorIcon"
    />
  </div>
</template>
