<script lang="ts" setup>
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Button,
  ButtonIcon,
  DataList,
  DataListContent,
  DataListItem,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { PagePropsViewModel } from './types';
import { EllipsisHorizontalIcon, PencilIcon } from '@heroicons/vue/16/solid';

const props = defineProps<PagePropsViewModel>();
</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:email-templates:head')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:templates:email-templates:title') }}</PageTitle>
        <PageDescription>{{ $t('panel.settings:templates:email-templates:subtitle') }}</PageDescription>
      </PageHeader>

      <DataList>
        <DataListItem
          v-for="template in props.emailTemplates"
          :id="template.id"
          :key="template.id"
        >
          <DataListContent>
            {{ $t('panel.form-notification-type:' + template) }}
          </DataListContent>

          <ActionList alignment="end">
            <ActionListTrigger>
              <Button
                icon-only
                variant="ghost"
                size="small"
              >
                <ButtonIcon :icon="EllipsisHorizontalIcon" />
              </Button>
            </ActionListTrigger>
            <ActionListBody>
              <ActionListSection label="Actions">
                <ActionListItem
                  :icon="PencilIcon"
                  as="button"
                  :href="$route('panel.settings.email-templates.edit', { mutationType: template })"
                >
                  {{ $t('panel.global:actions:edit') }}
                </ActionListItem>
              </ActionListSection>
            </ActionListBody>
          </ActionList>
        </DataListItem>
      </DataList>
    </Page>
  </AppLayout>
</template>
