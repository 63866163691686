<script lang="ts" setup>
import {
  Avatar,
  AvatarSize,
  Card,
  CardSection,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { isEmpty } from 'lodash';
import { useClipboard } from '@app/panel/Composables/useClipboard';
import { useToast } from '@app/panel/Composables/useToast';
import { ToastVariant } from '@app/panel/Components';
import { useI18n } from 'vue-i18n';

type CouponViewModel = Types.App.Models.ViewModels.CouponViewModel;

const props = defineProps<{ coupons: Array<CouponViewModel> }>();

const hasCoupons = computed(() => !isEmpty(props.coupons));

const { clipboardText, writeTextToClipboard } = useClipboard();

const { emitToastEvent } = useToast();

const { t } = useI18n();

function copyCode(code: string): void {
  writeTextToClipboard(code);

  if (!isEmpty(clipboardText)) {
    emitToastEvent({
      message: t('panel.returns:detail:coupons:code-copied'),
      variant: ToastVariant.Info,
    });
  }
}
</script>

<template>
  <Card :title="t('panel.returns:detail:coupons')">
    <CardSection v-if="hasCoupons">
      <ul class="divide-y space-y-2">
        <li
          v-for="coupon in coupons"
          :key="coupon.id"
          class="first:pt-0 last:pb-0 py-4"
        >
          <div class="flex justify-center items-start space-x-2">
            <Avatar
              :src="coupon.platformIntegration.logo.imageSourceUrl"
              :size="AvatarSize.Large"
            />

            <div class="flex-1">
              <TextContainer>
                <TextStyle
                  font-mono
                  font-medium
                  :shade="TextStyleShade.Dense"
                >
                  <div
                    v-if="coupon.code"
                    class="flex items-center space-x-2"
                  >
                    <span>{{ coupon.code }}</span>

                    <DocumentDuplicateIcon
                      class="h-4 w-4 text-slate-400 hover:text-slate-500 cursor-pointer"
                      @click="() => copyCode(coupon.code)"
                    />
                  </div>
                </TextStyle>

                <div class="flex flex-col mt-1">
                  <div>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns:detail:coupons:type') }}:
                    </TextStyle>
                    {{ coupon.typeLabel }}
                  </div>


                  <div>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns:detail:coupons:value') }}:
                    </TextStyle>
                    {{ coupon.amount }}
                  </div>

                  <div>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns:detail:coupons:created-on') }}:
                    </TextStyle>
                    {{ coupon.createdAt }}
                  </div>

                  <div v-if="coupon.expiresAt">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns:detail:coupons:expires-on') }}:
                    </TextStyle>
                    {{ coupon.expiresAt }}
                  </div>
                </div>
              </TextContainer>
            </div>
          </div>
        </li>
      </ul>
    </CardSection>

    <CardSection v-if="! hasCoupons">
      <TextContainer text-center>
        {{ $t('panel.returns:detail:coupons:no-coupons') }}
      </TextContainer>
    </CardSection>
  </Card>
</template>
