<script setup lang="ts">
import { Toggle } from '@app/panel/Components';
import SubscribeToDisabledTestModeModal
  from '@app/panel/Pages/Settings/Forms/partials/SubscribeToDisabledTestModeModal.vue';
import { ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useModal } from '@app/panel/Composables/useModal';

const hasSubscription = ref(usePage().props.hasSubscription || false);
const {
  isOpen: isSubscribeToDisabledTestModeModalOpen,
  close: closeSubscribeToDisabledTestModeModal,
  open: openSubscribeToDisabledTestModeModal,
} = useModal();

const props = defineProps<{
  modelValue: boolean;
  error?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

function onTestModeChange() {
  const newValue = !props.modelValue;

  if (!newValue && !hasSubscription.value) {
    openSubscribeToDisabledTestModeModal();
    return;
  }

  emit('update:modelValue', newValue);
}
</script>

<template>
  <Toggle
    :model-value="modelValue"
    :title="$t('panel.settings.forms.list_forms.settings.general.test_mode.toggle.title')"
    :subtitle="$t('panel.settings.forms.list_forms.settings.general.test_mode.toggle.subtitle')"
    :error="error"
    @update:model-value="onTestModeChange"
  />

  <SubscribeToDisabledTestModeModal
    :open="isSubscribeToDisabledTestModeModalOpen"
    @close="closeSubscribeToDisabledTestModeModal"
  />
</template>
