<script lang="ts" setup>
import { inject, PropType } from 'vue';
import {
  Button,
  ButtonType,
  ButtonVariant,
  ColorPicker,
  Form,
  FormLayout,
  Modal,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { TranslationInputFields, TranslationInputFieldType } from '@app/panel/Containers';
import { InertiaForm } from '@inertiajs/vue3';
import { type ReturnStatusForm } from './types';
import * as Types from '@app/panel/types/generated';

const props = defineProps({
  current: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnStatusViewModel>,
    default: null,
  },
  // TODO RET-297 - Remove this prop after the feature is fully implemented
  useNewReturnState: {
    type: Boolean,
    default: false,
  },

});

const statusForm = inject<InertiaForm<ReturnStatusForm>>('statusForm');
const locales = inject<Types.App.Models.ViewModels.LocaleViewModel[]>('locales');
const defaultLocale = inject<Types.App.Models.ViewModels.LocaleViewModel>('defaultLocale');

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onModalClose(): void {
  emit('close');
}

function submitForm(): void {
  if (!statusForm) {
    return;
  }

  statusForm.put(route('panel.settings.general.update-return-status', props.current.id), {
    onSuccess: () => onModalClose(),
  });
}
</script>

<template>
  <Modal
    v-if="statusForm"
    :open="!! current"
    @close="onModalClose"
  >
    <Form
      id="edit-form"
      @submit="submitForm"
    >
      <FormLayout>
        <FormLayout>
          <FormLayout>
            <ColorPicker
              v-model="statusForm.color"
              :label="$t('panel.settings.general_settings.return_statuses.edit_modal.status_color_label')"
            />
          </FormLayout>

          <TextInput
            v-model="statusForm.label"
            name="label"
            :label="$t('panel.settings.general_settings.return_statuses.edit_modal.status_label_input')"
            :error="statusForm.errors.label"
          />
        </FormLayout>

        <!-- TODO RET-297 - Remove this after the feature is fully implemented -->
        <FormLayout v-if="!useNewReturnState">
          <TranslationInputFields
            v-model="statusForm.translations"
            :locales="locales"
            :errors="statusForm.errors"
            field-name="translations"
            :default-locale-id="defaultLocale?.id"
            :type="TranslationInputFieldType.TextInput"
            :label="$t('panel.settings.general_settings.return_statuses.edit_modal.status_translation_input')"
          />
        </FormLayout>

        <FormLayout v-if="! current.isDefaultStatus">
          <Toggle
            v-model="statusForm.is_default_status"
            :title="$t('panel.settings.general_settings.return_statuses.edit_modal.default')"
          />
        </FormLayout>

        <!-- TODO RET-297 - Remove this after the feature is fully implemented -->
        <FormLayout v-if="!useNewReturnState">
          <Toggle
            v-model="statusForm.visible_on_tracking_page"
            :title="$t('panel.settings.general_settings.return_statuses.edit_modal.visible_on_tracking_page')"
          />
        </FormLayout>

        <!-- TODO RET-297 - Remove this after the feature is fully implemented -->
        <FormLayout v-if="!useNewReturnState">
          <Toggle
            v-model="statusForm.should_notify_user"
            :title="$t('panel.settings.general_settings.return_statuses.edit_modal.should_notify_user')"
          />
        </FormLayout>

        <!-- TODO RET-297 - Remove this condition after the feature is fully implemented -->
        <FormLayout v-if="useNewReturnState">
          <Toggle
            v-model="statusForm.should_resolve_return_order"
            :title="$t('panel.settings:general:return-statuses:edit-modal:mark-return-as-resolved')"
            :subtitle="$t('panel.settings:general:return-statuses:edit-modal:mark-return-as-resolved:help-text')"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="onModalClose">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :loading="statusForm.processing"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        form="edit-form"
      >
        {{ $t('panel.settings.general_settings.return_statuses.edit_modal.save_button') }}
      </Button>
    </template>
  </Modal>
</template>
