<script lang="ts" setup>
import {
  Badge,
  Banner,
  BannerVariant,
  Button,
  ButtonVariant,
  Card,
  CardSection,
  DataTable,
  Heading,
  LinkFontSize,
  LinkVariant,
  Modal,
  ModalSize,
  Page,
  Pagination,
  Select,
  TextContainer,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { PageNavigation } from '../partials';
import { AppLayout } from '@app/panel/Layouts';
import { type Types } from '@app/shared/types/generated-v2';
import { type Heading as HeadingI } from '@app/panel/Components/DataTable';
import { useI18n } from 'vue-i18n';
import { provide, ref, watch } from 'vue';
import { computed } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { Link } from '@returnless/focus-ui';

type FailedFormAutomationViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FailedFormAutomationViewModel'];
type ListFailedFormAutomationViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ListFailedFormAutomationsViewModel'];

const props = defineProps<ListFailedFormAutomationViewModel>();

provide('form', props.form);

const { t } = useI18n();

const perPage = ref<number>(props.perPage);

watch(perPage, () => {
  router.visit(route('panel.settings.forms.failed-automations', { form: props.form, per_page: perPage.value }));
});

const headings: HeadingI[] = [
  { text: t('panel.settings:integrations:application-logs:message'), value: 'message', noWrap: false },
];

const currentFailedFormAutomation = ref<FailedFormAutomationViewModel | null>(null);

function setCurrentFailedFormAutomation(failedFormAutomationViewModel: FailedFormAutomationViewModel): void {
  currentFailedFormAutomation.value = failedFormAutomationViewModel;
}

function unsetCurrentFailedFormAutomation(): void {
  currentFailedFormAutomation.value = null;
}

const paginationOptions = computed(() => {
  const options = [5, 10, 25, 50, 75, 100];

  return options.map((option) => {
    return {
      value: option,
      label: t('panel.global:pagination:option', { option }),
    };
  });
});

function retryAction(failedFormAutomationIds: string[]) {
  const url = route('panel.settings.forms.failed-automations.retry', { form: props.form });

  useForm({ ids: failedFormAutomationIds }).post(url, {
    preserveScroll: true,
    onFinish: () => {
      unsetCurrentFailedFormAutomation();
    },
  });
}

function deleteFailedFormAutomations(failedFormAutomationIds: string[]) {
  const url = route('panel.settings.forms.failed-automations.delete', { form: props.form });

  useForm({ ids: failedFormAutomationIds }).delete(url, {
    preserveScroll: true,
    onFinish: () => {
      unsetCurrentFailedFormAutomation();
    },
  });
}

</script>

<template>
  <AppLayout :title="$t('panel.settings:form:automations:failed:head-title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <div class="mb-6 flex justify-between">
        <Heading> {{ $t('panel.settings:form:automations:failed:page-title', { total }) }}</Heading>

        <div
          v-if="failedFormAutomations.length"
          class="flex items-center space-x-4"
        >
          <Button
            :font-size="LinkFontSize.Small"
            :variant="LinkVariant.Critical"
            plain
            @click="deleteFailedFormAutomations([])"
          >
            {{ $t('panel.settings:form:automations:failed:delete-all') }}
          </Button>

          <Button
            :variant="ButtonVariant.Default"
            @click="retryAction([])"
          >
            {{ $t('panel.settings:form:automations:failed:retry-all') }}
          </Button>
        </div>
      </div>

      <Banner
        :variant="BannerVariant.Info"
        class="mb-6"
      >
        {{ $t('panel.settings:form:automations:failed:prune-after-period') }}
      </Banner>

      <Card>
        <DataTable
          :headings="headings"
          :items="failedFormAutomations"
          :rounded="false"
          :previous-page-url="previousPageUrl"
          :next-page-url="nextPageUrl"
          clickable
          @row-click="setCurrentFailedFormAutomation"
        >
          <template #message="{ item }: { item: FailedFormAutomationViewModel }">
            <div class="flex items-center justify-between">
              <div class="group flex flex-1 flex-col space-y-1">
                <TextContainer>
                  <TextStyle :shade="TextStyleShade.Default">
                    <div class="space-x-1">
                      <strong>{{ item.formAutomationName }}</strong>
                      <span v-if="item.returnNumber !== null">
                        <span>{{ $t('panel.settings:form:automations:failed:for-return') }}</span>
                        {{ ' ' }}
                        <strong>{{ item.returnNumber }}</strong>
                      </span>
                    </div>
                  </TextStyle>
                </TextContainer>
                <div class="flex items-center space-x-2 divide-x">
                  <TextStyle
                    :shade="TextStyleShade.Pale"
                    :font-size="TextStyleFontSize.Small"
                    class="text-nowrap"
                  >
                    {{ item.failedAt }}
                  </TextStyle>

                  <TextStyle
                    class="text-nowrap pl-2"
                    :shade="TextStyleShade.Pale"
                    :font-size="TextStyleFontSize.Small"
                  >
                    {{ $t('panel.settings:form:automations:failed:failed-action') }}: {{ item.formAutomationActionType }}
                  </TextStyle>

                  <TextStyle
                    class="max-w-sm overflow-hidden text-ellipsis text-nowrap pl-2"
                    :shade="TextStyleShade.Pale"
                    :font-size="TextStyleFontSize.Small"
                  >
                    {{ item.message }}
                  </TextStyle>
                </div>
              </div>
              <span v-if="item.retriedAt">
                <Badge color="yellow">
                  {{ $t('panel.settings:form:automations:failed:retried') }}
                </Badge>
              </span>
            </div>
          </template>
        </DataTable>
        <CardSection dense>
          <div class="flex items-center">
            <TextStyle no-wrap>
              {{ $t('panel.settings:form:automations:failed:results', { results: total }) }}
            </TextStyle>
            <div class="ml-auto flex w-fit space-x-4">
              <Select
                v-model="perPage"
                label-hidden
                :options="paginationOptions"
              />
              <Pagination
                :href-next="nextPageUrl"
                :href-previous="previousPageUrl"
              />
            </div>
          </div>
        </CardSection>
      </Card>
    </Page>
  </AppLayout>

  <Modal
    :open="currentFailedFormAutomation !== null"
    :size="ModalSize.ExtraLarge"
    :title="$t('panel.settings:form:automations:failed:modal:title')"
    @close="unsetCurrentFailedFormAutomation"
  >
    <template
      v-if="currentFailedFormAutomation !== null"
      #actions
    >
      <div class="flex space-x-4">
        <Button
          :font-size="LinkFontSize.Small"
          :variant="LinkVariant.Critical"
          plain
          @click="deleteFailedFormAutomations([currentFailedFormAutomation.id])"
        >
          {{ $t('panel.settings:form:automations:failed:modal:delete') }}
        </Button>
        <Button
          :variant="ButtonVariant.Primary"
          :disabled="currentFailedFormAutomation.retriedAt !== null || currentFailedFormAutomation.hasTrashedFormAutomationAction"
          @click="retryAction([currentFailedFormAutomation.id])"
        >
          {{ $t('panel.settings:form:automations:failed:modal:retry') }}
        </Button>
      </div>
    </template>

    <div
      v-if="currentFailedFormAutomation?.hasTrashedFormAutomationAction"
      class="mb-3"
    >
      <Banner
        :title="$t('panel.settings:form:automations:failed:modal:deleted-action')"
        :variant="BannerVariant.Warning"
      />
    </div>
    <div class="flex flex-col divide-y overflow-hidden rounded border">
      <div class="grid grid-cols-12 p-3 odd:bg-slate-50">
        <div class="col-span-2">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ $t('panel.settings:form:automations:failed:modal:action') }}
          </TextStyle>
        </div>

        <div class="col-span-10">
          <TextStyle :shade="TextStyleShade.Subdued">
            <strong>
              {{ currentFailedFormAutomation.formAutomationActionType }}
            </strong>
            {{ $t('panel.settings:form:automations:failed:modal:in-automation') }}
            <strong>
              {{ currentFailedFormAutomation.formAutomationName }}
            </strong>
          </TextStyle>
        </div>
      </div>
      <div
        v-if="currentFailedFormAutomation?.returnNumber !== null"
        class="grid grid-cols-12 p-3 odd:bg-slate-50"
      >
        <div class="col-span-2">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ $t('panel.settings:form:automations:failed:modal:for-return') }}
          </TextStyle>
        </div>

        <div class="col-span-10">
          <TextStyle
            v-if="currentFailedFormAutomation.returnOrderUrl === null"
            :shade="TextStyleShade.Subdued"
          >
            {{ currentFailedFormAutomation.returnNumber }}
          </TextStyle>
          <Link
            v-if="currentFailedFormAutomation.returnOrderUrl !== null"
            :href="currentFailedFormAutomation.returnOrderUrl"
            external
          >
            {{ currentFailedFormAutomation.returnNumber }}
          </Link>
        </div>
      </div>
      <div class="grid grid-cols-12 p-3 odd:bg-slate-50">
        <div class="col-span-2">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ $t('panel.settings:form:automations:failed:modal:failed-at') }}
          </TextStyle>
        </div>

        <div class="col-span-10">
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ currentFailedFormAutomation.failedAt }}
          </TextStyle>
        </div>
      </div>
      <div class="grid grid-cols-12 p-3 odd:bg-slate-50">
        <div class="col-span-2">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ $t('panel.settings:form:automations:failed:modal:retried-at') }}
          </TextStyle>
        </div>

        <div class="col-span-10">
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ currentFailedFormAutomation.retriedAt ?? '-' }}
          </TextStyle>
        </div>
      </div>
      <div class="grid grid-cols-12 p-3 odd:bg-slate-50">
        <div class="col-span-2">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ $t('panel.settings:form:automations:failed:modal:message') }}
          </TextStyle>
        </div>

        <div class="col-span-10">
          <div class="max-h-44 space-x-1 overflow-auto whitespace-pre-wrap">
            <TextStyle :shade="TextStyleShade.Subdued">
              {{ currentFailedFormAutomation.message }}
            </TextStyle>
            <Link
              :href="currentFailedFormAutomation.applicationLogUrl"
              external
            >
              {{ $t('panel.settings:form:automations:failed:modal:link-to-application-logs') }}
            </Link>
          </div>
        </div>
      </div>
      <div
        v-for="[key, value] in Object.entries(currentFailedFormAutomation.meta)"
        :key="key"
        class="grid grid-cols-12 p-3 odd:bg-slate-50"
      >
        <div class="col-span-2">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ key }}
          </TextStyle>
        </div>
        <div class="col-span-10">
          <div
            v-if="value"
            class="max-h-44 overflow-auto whitespace-pre-wrap"
          >
            <TextStyle :shade="TextStyleShade.Subdued">
              <code>{{ value }}</code>
            </TextStyle>
          </div>
          <span
            v-else
            class="text-slate-300"
          >
            &mdash;
          </span>
        </div>
      </div>
    </div>
  </Modal>
</template>
