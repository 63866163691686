<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { InertiaForm } from '@inertiajs/vue3';
import { getComputedValue, isComputedValue } from '../helpers';
import { TextStyle, TextStyleShade } from '@app/panel/Components';
import { formatCurrency } from '@app/panel/Utils';
import { Checkbox } from '@app/panel/Components';

const props = defineProps({
  children: {
    type: Array,
    default: () => ([]),
  },

  label: {
    type: String,
    required: true,
  },

  fieldName: {
    type: String,
    required: true,
  },

  value: {
    type: String,
    required: true,
  },
});

const form = inject<InertiaForm<any>>('traitForm');
const computedValues = inject<Ref<object>>('precomputedValues');

const computedValue = computed((): number => {
  let value = props.value;

  if (isComputedValue(value)) {
    value = getComputedValue(computedValues, value);
  }

  return parseFloat(value);
});

const locale = inject<Ref<string>>('userLocale');

const formattedValue = computed(() => {
  return formatCurrency(computedValue.value, locale.value);
});
</script>

<template>
  <div class="flex justify-between items-center py-4">
    <Checkbox
      v-model="form[fieldName]"
      :label="label"
    />

    <TextStyle
      strong
      :shade="TextStyleShade.Dense"
    >
      <span>
        {{ formattedValue }}
      </span>
    </TextStyle>
  </div>
</template>
