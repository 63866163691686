import { round } from 'lodash';

export enum ReturnOrderItemDepreciationType {
  Fixed = 'fixed',
  Percentage = 'percentage',
}

export interface IReturnOrderItemDepreciation {
  id?: string;
  value?: number;
  type?: ReturnOrderItemDepreciationType;
  comment?: string;
  salePrice: number;
  readonly storedValue: number;
  calculateValue: () => number;
  calculatePercentage: () => number;
}

export class ReturnOrderItemDepreciation implements IReturnOrderItemDepreciation {
  id: string;
  value: number;
  type: ReturnOrderItemDepreciationType;
  comment: string;
  salePrice: number;

  constructor(
    salePrice: number,
    id: string = null,
    value: number = null,
    type: ReturnOrderItemDepreciationType = null,
    comment: string = null,
  ) {
    this.id = id;
    this.value = value;
    this.type = type;
    this.comment = comment;
    this.salePrice = salePrice;
  }

  get storedValue(): number {
    return this.value * 100;
  }

  public calculateValue(): number {
    let value = this.storedValue;

    if (this.type === ReturnOrderItemDepreciationType.Percentage) {
      value = (this.salePrice / 100) * this.value;
    }

    return round(value, 2);
  }

  public calculatePercentage(): number {
    if (!this.salePrice) {
      return 0;
    }

    return this.calculateValue() / (this.salePrice / 100);
  }
}
