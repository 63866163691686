<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';
import {
  Pagination,
  Select,
  TextContainer,
  TextStyle,
} from '@app/panel/Components';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  paginatorStatus: string;
  perPage: number;
  previousPageUrl: null | string;
  nextPageUrl: null | string;
}>();

const emit = defineEmits<{
  (e: 'change', rowsPerPage: number): void;
}>();

const rowsPerPage = ref(props.perPage);

const paginationOptions = computed(() => {
  const options = [5, 10, 25, 50, 75, 100];
  return options.map((option) => {
    return {
      value: option,
      label: t('panel.global:pagination:option', { option }),
    };
  });
});

const onRowsPerPageChange = () => {
  emit('change', rowsPerPage.value);
};
</script>

<template>
  <div class="flex items-center justify-between">
    <div class="flex items-center space-x-4">
      <TextContainer>
        <TextStyle no-wrap>
          {{ paginatorStatus }}
        </TextStyle>
      </TextContainer>
    </div>

    <div class="flex items-center space-x-4">
      <Select
        v-model="rowsPerPage"
        label-hidden
        :options="paginationOptions"
        @change="onRowsPerPageChange"
      />

      <Pagination
        :href-next="nextPageUrl"
        :href-previous="previousPageUrl"
      />
    </div>
  </div>
</template>
