<script setup lang="ts">
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from '@returnless/focus-ui';
import { useAlert } from '@app/panel/Composables/useAlert';

const { isOpen, alertMessage } = useAlert();

</script>

<template>
  <AlertDialog
    :open="isOpen"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ $t('panel.global:alert-dialog:title') }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {{ alertMessage }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <Button
          variant="primary"
          @click="isOpen = false"
        >
          {{ $t('panel.global:actions:continue') }}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
