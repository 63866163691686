<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Card,
  CardSection,
} from '@app/panel/Components';
import { App, type Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { intersectionBy } from 'lodash';
import { CheckableItem, CheckableList } from '../../components';
import { useOnboarding } from '../../composables';

type OnboardinDashboardViewModel = Types['App.Http.ViewModels.Panel.OnboardingDashboardViewModel'];

const stateType = App.Enums.OnboardingStateType.HasReturnRules;

const props = defineProps<{
  returnForm: OnboardinDashboardViewModel['returnForm'];
  storedReturnRules: OnboardinDashboardViewModel['storedReturnRules'];
  predefinedReturnRules: OnboardinDashboardViewModel['predefinedReturnRules'];
}>();

function getSelectedRules(): string[] {
  return intersectionBy(props.predefinedReturnRules, props.storedReturnRules, 'name')
    .map((predefinedReturnRule) => predefinedReturnRule.name);
}

const form = useForm({
  rules: getSelectedRules(),
});

const { setLastCompletedStep, changeStep } = useOnboarding();

function save(): void {
  form.post(route('panel.onboarding.return-rules', { form: props.returnForm }), {
    preserveScroll: true,
    onSuccess: () => setLastCompletedStep(stateType),
    onError: () => changeStep(stateType),
  });
}
</script>

<template>
  <Card
    :primary-footer-action="{
      content: 'Save & continue',
      callback: save,
    }"
  >
    <CardSection>
      <div
        v-if="form.errors.rules"
        class="mb-5"
      >
        <Banner :variant="BannerVariant.Critical">
          {{ form.errors.rules }}
        </Banner>
      </div>
      <CheckableList>
        <template
          v-for="predefinedReturnRule in predefinedReturnRules"
          :key="predefinedReturnRule.question"
        >
          <CheckableItem
            v-if="predefinedReturnRule.isShown"
            :model-value="form.rules"
            :value="predefinedReturnRule.name"
            :title="predefinedReturnRule.name"
            :description="predefinedReturnRule.description"
            :is-popular="predefinedReturnRule.isPopular"
            @update:model-value="(value) => form.rules = value"
          />
        </template>
      </CheckableList>
    </CardSection>
  </Card>
</template>
