<script lang="ts" setup>
import { CardLayout, Page } from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from './../partials/';
import Countries from './Components/Countries.vue';
import Languages from './Components/Languages.vue';
import ReturnNumberFormatting from './Components/ReturnNumberFormatting.vue';
import { GeneralSettingsPageViewModel } from './types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps<GeneralSettingsPageViewModel>();
</script>

<template>
  <AppLayout :title="t('panel.settings.general_settings.general_settings.head_title')">
    <Page :navigation="PageNavigation">
      <CardLayout>
        <Languages
          :addable-locale-options="addableLocaleOptions"
          :selected-locales="selectedLocales"
        />

        <ReturnNumberFormatting :return-order-number-format="returnOrderNumberFormat" />

        <Countries
          :addable-country-options="addableCountryOptions"
          :selected-countries="selectedCountries"
        />
      </CardLayout>
    </Page>
  </AppLayout>
</template>
