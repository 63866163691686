<script lang="ts" setup>
import { computed, provide, ref } from 'vue';

import {
  Badge,
  Button,
  ButtonContent,
  ButtonGroup,
  ButtonIcon,
  Card,
  CardHeader,
  CardLayout,
  CardMedia,
  CardSection,
  CardTitle,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  FormLayout,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
  TextStyle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import CreateAddressModal from '../partials/CreateAddressModal.vue';
import EditAddressModal from '../partials/EditAddressModal.vue';
import PageNavigation from '../partials/PageNavigation.vue';
import { router } from '@inertiajs/vue3';
import { App } from '@app/shared/types/generated-v2';
import { StatusBadge } from '@app/panel/types';
import { useI18n } from 'vue-i18n';
import { hasFeature } from '@app/panel/Utils';
import { PlusIcon } from '@heroicons/vue/16/solid';
import { ReturnAddressesPageViewModel, ReturnAddressViewModel } from './types';

const props = defineProps<ReturnAddressesPageViewModel>();

const { t } = useI18n();

provide('countries', props.countries);

const createModalOpen = ref(false);

function openCreateModal() {
  createModalOpen.value = true;
}

function closeCreateModal() {
  createModalOpen.value = false;
}

const editModalOpen = computed(() => props.returnAddress !== null);

function closeEditModal(): void {
  if (props.returnAddress?.addressType === App.Enums.ReturnAddressType.STORE_LOCATION) {
    router.visit(route('panel.settings.store-locations'));
    return;
  }

  router.visit(route('panel.settings.return-addresses'));
}

function getEditRoute(returnAddressId: string): string {
  if (props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION) {
    return route('panel.settings.store-locations.show', returnAddressId);
  }
  return route('panel.settings.return-addresses.show', returnAddressId);
}

const canShowLocations = computed(() => {
  if (props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION) {
    return hasFeature('StoreLocations');
  }

  return true;
});

function getStatusBadge(address: ReturnAddressViewModel): StatusBadge | null {
  if (!address.primary) {
    return null;
  }

  return {
    label: `${t('panel.settings.addresses.primary')} ${address.country.isoCode}`,
    color: 'blue',
  };
}
</script>

<template>
  <AppLayout
    :title="
      addressType === App.Enums.ReturnAddressType.STORE_LOCATION
        ? t('panel.settings:store-locations:page-title')
        : t('panel.settings:return-addresses:page-title')
    "
  >
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>
          {{
            addressType === App.Enums.ReturnAddressType.STORE_LOCATION
              ? t('panel.settings:store-locations:page-title')
              : t('panel.settings:return-addresses:page-title')
          }}
        </PageTitle>
        <PageDescription>
          {{
            addressType === App.Enums.ReturnAddressType.STORE_LOCATION
              ? t('panel.settings:store-locations:page-description')
              : t('panel.settings:return-addresses:page-description')
          }}
        </PageDescription>
        <template
          v-if="returnAddresses.length > 0"
          #page-header-actions
        >
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>
              {{
                addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                  ? t('panel.settings:store-locations:create')
                  : t('panel.settings:return-addresses:create')
              }}
            </ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <CardLayout
        v-if="canShowLocations"
        :columns="3"
      >
        <Card
          v-for="address in returnAddresses"
          :key="address.id"
          :title="address.name"
        >
          <CardMedia
            v-fallback-img
            alt="address location"
            :ratio="16 / 8"
            :source="address.rawMapImageSrc ?? ''"
          >
            <Badge
              v-if="getStatusBadge(address)"
              :color="getStatusBadge(address)?.color ?? 'red'"
            >
              {{ getStatusBadge(address).label }}
            </Badge>
          </CardMedia>
          <CardHeader>
            <CardTitle>{{ address.name }}</CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <TextStyle subdued>
                {{ address.companyName }} <br>
                {{ address.addressLine }} <br>
                {{ address.postalCode }} {{ address.city }} <br>
                {{ address.telephone }}
              </TextStyle>
              <ButtonGroup class="flex justify-between items-center">
                <Button
                  variant="secondary"
                  :href="getEditRoute(address.id)"
                >
                  {{
                    addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                      ? t('panel.settings:store-locations:buttons:edit-address-short')
                      : t('panel.settings:return-addresses:buttons:edit-address-short')
                  }}
                </button>
                <img
                  v-if="address.imageSrc"
                  :src="address.imageSrc"
                  alt="brand icon"
                  class="h-8 w-8 object-cover"
                >
              </buttongroup>
            </FormLayout>
          </CardSection>
        </Card>
      </CardLayout>

      <EmptyState v-if="returnAddresses.length < 1">
        <EmptyStateContent>
          <EmptyStateTitle>
            {{
              addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                ? t('panel.settings:store-locations:empty:title')
                : t('panel.settings:return-addresses:empty:title')
            }}
          </EmptyStateTitle>
          <EmptyStateDescription>
            {{
              addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                ? t('panel.settings:store-locations:empty:description')
                : t('panel.settings:return-addresses:empty:description')
            }}
          </EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>
              {{
                addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                  ? t('panel.settings:store-locations:create')
                  : t('panel.settings:return-addresses:create')
              }}
            </ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>

      <EmptyState v-if="!canShowLocations">
        <EmptyStateContent>
          <EmptyStateTitle>
            {{ t('panel.subscriptions:features:unsupported:title', { feature: t('panel.subscriptions:features:store-locations')} ) }}
          </EmptyStateTitle>
          <EmptyStateDescription>
            {{ t('panel.subscriptions:features:unsupported:description', { available_in: t('panel.subscriptions:plans:automate')}) }}
          </EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button
            :href="$route('panel.account.billing')"
          >
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ t('panel.subscriptions:features:unsupported:buttons:view-upgrade-options') }}</ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>

    <CreateAddressModal
      :open="createModalOpen"
      :address-type="addressType"
      @close="closeCreateModal"
    />

    <EditAddressModal
      v-if="returnAddress"
      :open="editModalOpen"
      :address="returnAddress"
      @close="closeEditModal"
    />
  </AppLayout>
</template>
