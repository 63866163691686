<script lang="ts">
import { computed, defineComponent } from 'vue';
import { groupBy } from 'lodash';
import { Heading, HeadingElement, HeadingSize } from '../../Heading';
import { Link } from '../../Link';
import { List, ListItem, ListStyle } from '../../List';
import { TextStyle, TextStyleShade } from '../../TextStyle';

export default defineComponent({
  components: {
    TextStyle,
    Link,
    List,
    ListItem,
    Heading,
  },

  props: {
    searchResults: {
      type: Array,
      required: true,
    },

    previousSearchString: {
      type: String,
      required: true,
    },

    focus: {
      type: Number,
      default: null,
    },

    error: {
      type: String,
      default: null,
    },
  },

  emits: ['mouseover'],

  setup(props, { emit }) {
    const groupedSearchResults = computed(() => {
      return groupBy(props.searchResults, 'resource_type');
    });

    const onMouseOver = (value) => emit('mouseover', value);

    return {
      groupedSearchResults,
      HeadingElement,
      HeadingSize,
      ListStyle,
      onMouseOver,
      TextStyleShade,
    };
  },
});
</script>

<template>
  <List
    v-if="searchResults.length > 0"
    :style="ListStyle.None"
    flush
    class="list-style-none p-0"
  >
    <ListItem
      v-for="(resourceArray, resourceType) in groupedSearchResults"
      :key="resourceType"
    >
      <Heading
        :element="HeadingElement.H2"
        :size="HeadingSize.ExtraSmall"
        class="bg-slate-200 py-2.5 px-4 font-semibold capitalize"
      >
        {{ resourceType }}
      </Heading>

      <List
        :style="ListStyle.None"
        flush
        class="text-sm text-slate-800"
      >
        <ListItem
          v-for="(resource, index) in resourceArray"
          :key="index"
        >
          <Link
            :id="`search-result-${index}`"
            :href="resource.href"
            no-underline
            class="flex justify-between"
          >
            <p
              class="text-sm text-slate-800 cursor-pointer px-4 py-2 w-full flex justify-between"
              :class="{'bg-slate-100': focus === index}"
              @mouseover="() => onMouseOver(index)"
            >
              {{ resource.label }}
              <TextStyle
                :shade="TextStyleShade.Pale"
                class="text-end truncate text-xs max-w-40"
              >
                {{ resource.sub_label }}
              </TextStyle>
            </p>
          </Link>
        </ListItem>
      </List>
    </ListItem>
  </List>

  <div
    v-if="searchResults.length === 0"
    class="text-sm py-2 text-slate-800 text-center"
  >
    <p v-if="!error">
      {{ $t('panel.containers.search-bar.no-results') }} "{{ previousSearchString }}"
    </p>

    <p v-if="error">
      {{ error }}
    </p>
  </div>
</template>
