<script lang="ts" setup>
import { Badge } from '@app/customer/Components';
import type { Types } from '@app/shared/types/generated-v2';

type TagViewModel = Types['App.Models.ViewModels.TagViewModel'];

defineProps<{
  tags: TagViewModel[];
}>();

const emit = defineEmits<{
  remove: [tag: TagViewModel];
}>();
</script>

<template>
  <div class="flex flex-wrap gap-2">
    <div
      v-for="tag in tags"
      :key="tag.id"
    >
      <Badge
        :color="tag.color"
        closeable
        @close="emit('remove', tag)"
      >
        {{ tag.name }}
      </Badge>
    </div>
  </div>
</template>
