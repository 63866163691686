<script lang="ts" setup>
import { computed, inject, ref, Ref } from 'vue';
import { Button, ButtonType, ButtonVariant, Form, ModalActions, ModalBody } from '@app/panel/Components';
import RecursiveBlockRenderer from '../RecursiveBlockRenderer.vue';
import * as Types from '@app/panel/types/generated';
import { router } from '@inertiajs/vue3';
import { Trait } from '@app/panel/Pages/Returns/partials/Trait/types';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';

const props = defineProps<{
  trait: Trait;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');

const loading = ref(false);

function submitForm() {
  const endpoint = route('panel.return-orders.finalize-return', { returnOrder: returnOrder.value });

  loading.value = true;

  router.post(endpoint, {
    platform_integration_tenant_id: props.trait.platformIntegrationTenantId,
  }, {
    preserveScroll: true,
    onSuccess: () => emits('close'),
    onFinish: () => loading.value = false,
  });
}

const formId = computed(() => useUniqueId('finalize-trait'));
</script>

<template>
  <Form
    :id="formId"
    @submit="submitForm"
  >
    <ModalBody>
      <RecursiveBlockRenderer :children="trait.children" />
    </ModalBody>

    <ModalActions>
      <Button @click="emits('close')">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
        :loading="loading"
        :form="formId"
      >
        {{ $t('panel.returns:detail-page:trait:finalize-return:cta') }}
      </Button>
    </ModalActions>
  </Form>
</template>
