<script lang="ts" setup>
import { computed, provide, toRef } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { AppLayout } from '@app/panel/Layouts';
import { Page } from '@app/panel/Components';
import { DetailPaymentRequests } from '@app/panel/Containers';
import {
  DetailCoupons,
  DetailExternalReturns,
  DetailInfo,
  DetailMetaData,
  DetailNotes,
  DetailOrders,
  DetailPageActions,
  DetailRefunds,
  DetailShipments,
  DetailTimeline,
  DetailTransactions,
} from './partials';
import type { Types } from '@app/shared/types/generated-v2';
import { hasFeature } from '@app/panel/Utils';

const props = defineProps<Types['App.Http.ViewModels.Panel.Returns.Detail.ShowReturnOrderViewModel']>();

const userLocale = computed(() => {
  return usePage().props.locale as string;
});

provide('userLocale', userLocale);
provide('customerAddress', props.customerAddress);
provide('credentialMapping', props.credentialMapping);
provide('form', props.form);
provide('returnOrder', toRef(props, 'returnOrder'));
provide('returnOrderItems', toRef(props, 'returnOrderItems'));
provide('returnStatuses', props.returnStatuses);
provide('integrationTraits', props.integrationTraits);
provide('attachmentFileSizeLimit', props.attachmentFileSizeLimit);
provide('attachmentFileTypes', props.attachmentFileTypes);
provide('applyToShipping', props.applyToShipping);
provide('enableFreeShipping', props.enableFreeShipping);
provide('tags', props.tags);
provide('metadata', props.metadataCategories);
</script>

<template>
  <AppLayout :title="$t('panel.returns:detail', { return: returnOrder.returnNumber })">
    <Page>
      <DetailPageActions />

      <div class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
        <div class="flex-1 space-y-6">
          <DetailInfo :note-templates="returnNoteTemplates" />

          <DetailNotes
            :return-order="returnOrder"
            :note-templates="returnNoteTemplates"
          />
        </div>

        <div class="basis-4/12 space-y-6">
          <DetailOrders
            :orders="returnOrder.salesOrders"
            :compatible-sales-order-ids="compatibleSalesOrderIds ?? []"
          />

          <DetailShipments
            :return-order="returnOrder"
            :selected-return-address="returnAddress"
          />

          <DetailCoupons :coupons="coupons" />

          <DetailRefunds :refunds="refunds" />

          <DetailExternalReturns
            v-if="externalReturns.length > 0"
            :external-returns="externalReturns"
          />

          <DetailTransactions :transactions="processedPaymentTransactions" />

          <DetailPaymentRequests
            v-if="hasFeature('PaymentRequests')"
            :return-order="returnOrder"
            :note-templates="paymentRequestsNoteTemplates"
            :payment-requests="paymentRequests"
            :country="customerAddress.country"
          />

          <DetailTimeline :items="returnOrderEvents" />

          <DetailMetaData
            :metadata-categories="metadataCategories"
            :return-order="returnOrder"
          />
        </div>
      </div>
    </Page>
  </AppLayout>
</template>
