import { computed, Ref } from 'vue';
import { InertiaForm, router, usePage } from '@inertiajs/vue3';
import type { App } from '@app/shared/types/generated-v2';
import { cleanObject } from '@app/panel/Utils/object';

export function useSorting(
  initSort: Ref<null | string>,
  preferenceKey: App.Enums.Preference,
  route: any,
  filterForm: InertiaForm<{ sort: null | string }>,
) {
  const sortPreference = computed<string>(() => usePage().props.preferences[preferenceKey] || '');

  const sortBy = computed<string>(() => initSort.value ?? sortPreference.value);

  function sort(sortBy: string | null): void {
    filterForm.sort = sortBy;

    router.get(route, cleanObject(filterForm.data()), {
      preserveScroll: true,
      preserveState: true,
    });
  }

  return {
    sortBy,
    sort,
  };
}
