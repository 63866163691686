<script setup lang="ts">
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
} from '@returnless/focus-ui';
import { useConfirm } from '@app/panel/Composables/useConfirm';

const { isOpen, confirmMessage, proceed, cancel } = useConfirm();

</script>

<template>
  <AlertDialog
    :open="isOpen"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>
          {{ $t('panel.global:confirmation-dialog:title') }}
        </AlertDialogTitle>
        <AlertDialogDescription>
          {{ confirmMessage || $t('panel.global:confirmation-dialog:content') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <Button
          variant="secondary"
          @click="cancel"
        >
          {{ $t('panel.global:actions:cancel') }}
        </Button>
        <Button
          variant="primary"
          @click="proceed"
        >
          {{ $t('panel.global:actions:proceed') }}
        </Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
