<script lang="ts" setup>
import {
  computed,
  provide,
  toRef,
  watch,
} from 'vue';
import {
  Button,
  ButtonVariant,
  CardSection,
  Page,
  PageActions,
  PageActionsPosition,
  ResourceItem,
  ResourceList,
  Stack,
  StackItem,
  StackSpacing,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { Card, CardDescription, CardHeader, CardTitle } from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { router } from '@inertiajs/vue3';
import { PageNavigation } from '../partials';
import type { Types } from '@app/shared/types/generated-v2';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import { PlusIcon } from '@heroicons/vue/24/outline';
import { groupBy } from 'lodash';
import { StatusBadge } from '@app/panel/types';
import { useI18n } from 'vue-i18n';
import FeatureLimitWarning from '@app/panel/Pages/Settings/Components/FeatureLimitWarning.vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type ListReturnQuestionsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ReturnQuestions.ListReturnQuestionsViewModel'];
type ReturnQuestion = Types['App.Models.ViewModels.ReturnQuestionViewModel'];

const props = defineProps<ListReturnQuestionsViewModel>();

provide('form', props.form);

function saveSortedReturnQuestions(items: string[]) {
  router.put(route('panel.settings.forms.return-questions.sort', { form: props.form }), { items }, {
    preserveScroll: true,
    preserveState: false,
  });
}

const questionsByLevel = computed(() => {
  return groupBy(props.returnQuestions, (question) => question.level);
});

const orderTableId = useUniqueId('orderTable');

const orderQuestions = toRef(questionsByLevel.value, 'order', []);

const { sortedItems: sortedOrderQuestions } = useDragAndDrop(orderTableId, orderQuestions);

watch(sortedOrderQuestions, (newValue) => {
  const sortedIds = newValue.map((item) => item.id);

  saveSortedReturnQuestions(sortedIds);
});

const orderItemTableId = useUniqueId('orderItemTable');

const orderItemQuestions = toRef(questionsByLevel.value, 'order-item', []);

const { sortedItems: sortedOrderItemQuestions } = useDragAndDrop(orderItemTableId, orderItemQuestions);

watch(sortedOrderItemQuestions, (newValue) => {
  const sortedIds = newValue.map((item) => item.id);

  saveSortedReturnQuestions(sortedIds);
});

function goToCreateReturnQuestionPage(): void {
  router.get(route('panel.settings.forms.return-questions.create', props.form));
}

function goToReturnQuestionPage(returnQuestion: ReturnQuestion): void {
  router.get(route('panel.settings.forms.return-questions.show', { form: props.form, returnQuestion }));
}

const { t } = useI18n();

function getStatusBadge(isEnabled: boolean): StatusBadge {
  return {
    label: isEnabled
      ? t('panel.settings.forms.list_forms.settings.return_questions.active')
      : t('panel.settings.forms.list_forms.settings.return_questions.disabled'),
    color: isEnabled ? 'green' : 'orange',
  };
}
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.return_questions.head_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem>
          <FeatureLimitWarning :feature-usage-info="featureUsageInfo" />

          <div class="mt-4">
            <div class="space-y-8">
              <PageActions :position="PageActionsPosition.End">
                <div>
                  <Button
                    :variant="ButtonVariant.Primary"
                    @click="goToCreateReturnQuestionPage"
                  >
                    <template #icon>
                      <PlusIcon class="h-5 w-5" />
                    </template>
                    {{ $t('panel.settings.forms.list_forms.settings.return_questions.add') }}
                  </Button>
                </div>
              </PageActions>

              <Card>
                <CardHeader>
                  <CardTitle :card-help="getHelpscoutArticleBeacon('form.return-questions.return-level-questions')">
                    {{ $t('panel.settings.forms.list_forms.settings.return_questions.order_level_title') }}
                  </CardTitle>
                  <CardDescription>{{ $t('panel.settings.forms.list_forms.settings.return_questions.order_level_subtitle') }}</CardDescription>
                </CardHeader>
                <CardSection>
                  <ResourceList
                    v-if="orderQuestions.length > 0"
                    :id="orderTableId"
                  >
                    <ResourceItem
                      v-for="returnQuestion in orderQuestions"
                      :id="returnQuestion.id"
                      :key="returnQuestion.uuid"
                      :label="returnQuestion.question"
                      :description="t(`panel.forms:return-questions:input-types:${returnQuestion.fieldType}`)"
                      :tags="returnQuestion.tags"
                      :badge="getStatusBadge(returnQuestion.isEnabled)"
                      @click="() => goToReturnQuestionPage(returnQuestion)"
                    />
                  </ResourceList>

                  <div
                    v-else
                    class="my-2"
                  >
                    <TextContainer text-center>
                      <TextStyle :shade="TextStyleShade.Subdued">
                        {{ $t('panel.settings.forms.list_forms.settings.return_questions.order_level_empty') }}
                      </TextStyle>
                    </TextContainer>
                  </div>
                </CardSection>
              </Card>
            </div>
          </div>
        </StackItem>

        <StackItem>
          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.return-questions.return-item-level-questions')">
                {{ $t('panel.settings.forms.list_forms.settings.return_questions.item_level_title') }}
              </CardTitle>
              <CardDescription>{{ $t('panel.settings.forms.list_forms.settings.return_questions.item_level_subtitle') }}</CardDescription>
            </CardHeader>
            <CardSection>
              <ResourceList
                v-if="orderItemQuestions.length > 0"
                :id="orderItemTableId"
              >
                <ResourceItem
                  v-for="returnQuestion in questionsByLevel['order-item']"
                  :id="returnQuestion.id"
                  :key="returnQuestion.uuid"
                  :label="returnQuestion.question"
                  :description="returnQuestion.fieldType"
                  :tags="returnQuestion.tags"
                  :href="$route('panel.settings.forms.return-questions.show', { form, returnQuestion })"
                  :badge="getStatusBadge(returnQuestion.isEnabled)"
                  @click="() => goToReturnQuestionPage(returnQuestion)"
                />
              </ResourceList>

              <div
                v-else
                class="my-2"
              >
                <TextContainer text-center>
                  <TextStyle :shade="TextStyleShade.Subdued">
                    {{ $t('panel.settings.forms.list_forms.settings.return_questions.item_level_empty') }}
                  </TextStyle>
                </TextContainer>
              </div>
            </CardSection>
          </Card>
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>
</template>
