<script setup lang="ts">

import {
  Button,
  ButtonType,
  ButtonVariant, Form, FormLayout, Select, Stack, TextContainer, TextInput,
  TextInputFieldTypes,
  TextInputFontFamily, TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import { computed } from 'vue';
import { App, type Types } from '@app/shared/types/generated-v2';
import type { PaymentMethodForm } from '@app/panel/Pages/Account/partials/Billing/types';

const props = defineProps<{
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  afterSuccessfulSubmit: () => void;
}>();

const paymentMethodForm = useForm<PaymentMethodForm>({
  payment_method: App.Enums.PaymentMethodType.SepaDebit,
  holder_name: '',
  cc_number: '',
  cc_expiration_date: '',
  cc_ccv: '',
  iban_number: '',
});

const paymentOptions = computed(() => {
  const paymentMethods = [
    { value: App.Enums.PaymentMethodType.SepaDebit, label: 'SEPA Direct Debit' },
  ];

  if (props.tenant.hasCreditcardPaymentEnabled) {
    paymentMethods.push({ value: App.Enums.PaymentMethodType.Card, label: 'Credit Card' });
  }

  return paymentMethods;
});

function onSubmitSuccess() {
  props.afterSuccessfulSubmit();
  paymentMethodForm.reset();
}

function submitPaymentMethodForm(): void {
  paymentMethodForm.post(route('panel.account.payment-methods.store'), {
    preserveScroll: true,
    onSuccess: onSubmitSuccess,
  });
}

function onPaymentMethodChange(paymentMethod: string) {
  paymentMethodForm.reset();
  paymentMethodForm.payment_method = paymentMethod;
}

</script>

<template>
  <Form @submit="submitPaymentMethodForm">
    <Stack vertical>
      <FormLayout>
        <FormLayout>
          <Select
            :model-value="paymentMethodForm.payment_method"
            :label="$t('panel.account.billing_and_subscription.payment_method.payment_method_input_label')"
            :options="paymentOptions"
            @update:model-value="onPaymentMethodChange"
          />
        </FormLayout>

        <div v-if="paymentMethodForm.payment_method === App.Enums.PaymentMethodType.Card">
          <FormLayout>
            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.cc_number"
                :error="paymentMethodForm.errors.cc_number"
                :font-family="TextInputFontFamily.Mono"
                :label="$t('panel.account.billing_and_subscription.payment_method.cc_number_input_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.holder_name"
                :error="paymentMethodForm.errors.holder_name"
                :label="$t('panel.account.billing_and_subscription.payment_method.cc_name_input_label')"
              />
            </FormLayout>

            <FormLayout :columns="2">
              <FormLayout>
                <TextInput
                  v-model="paymentMethodForm.cc_ccv"
                  :font-family="TextInputFontFamily.Mono"
                  :error="paymentMethodForm.errors.cc_ccv"
                  :label="$t('panel.account.billing_and_subscription.payment_method.cc_ccv_input_label')"
                />
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="paymentMethodForm.cc_expiration_date"
                  :type="TextInputFieldTypes.Month"
                  :error="paymentMethodForm.errors.cc_expiration_date"
                  :label="$t('panel.account.billing_and_subscription.payment_method.expiration_date_input_label')"
                  placeholder="MM/YY"
                />
              </FormLayout>
            </FormLayout>

            <FormLayout>
              <TextContainer>
                <TextStyle
                  :font-size="TextStyleFontSize.Small"
                  :shade="TextStyleShade.Subdued"
                >
                  {{ $t('panel.account.billing_and_subscription.payment_method.modal_credit_card_footer_text') }}
                </TextStyle>
              </TextContainer>
            </FormLayout>
          </FormLayout>
        </div>

        <div v-if="paymentMethodForm.payment_method === App.Enums.PaymentMethodType.SepaDebit">
          <FormLayout>
            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.iban_number"
                :error="paymentMethodForm.errors.iban_number"
                :label="$t('panel.account.billing_and_subscription.payment_method.iban_number_input_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.holder_name"
                :error="paymentMethodForm.errors.holder_name"
                :label="$t('panel.account.billing_and_subscription.payment_method.iban_holder_input_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextContainer>
                <TextStyle
                  :font-size="TextStyleFontSize.Small"
                  :shade="TextStyleShade.Subdued"
                >
                  {{ $t('panel.account.billing_and_subscription.payment_method.modal_sepa_footer_text') }}
                </TextStyle>
              </TextContainer>
            </FormLayout>
          </FormLayout>
        </div>

        <FormLayout>
          <Button
            full-width
            :variant="ButtonVariant.Primary"
            :type="ButtonType.Submit"
            :loading="paymentMethodForm.processing"
          >
            {{ $t('panel.account.billing_and_subscription.payment_method.modal_primary_button') }}
          </Button>
        </FormLayout>
      </FormLayout>
    </Stack>
  </Form>
</template>
