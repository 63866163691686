import { App } from '@app/shared/types/generated-v2';

export interface OnboardingState {
  title: string;
  subtitle: string;
}

export function getOnboardingState(status: App.Enums.OnboardingStateType): OnboardingState {
  switch (status) {
    case App.Enums.OnboardingStateType.HasReturnAddress:
      return {
        title: 'panel.dashboard:onboarding:state:return-address:title',
        subtitle: 'panel.dashboard:onboarding:state:return-address:description',
      };
    case App.Enums.OnboardingStateType.HasReturnForm:
      return {
        title: 'panel.dashboard:onboarding:state:return-form:title',
        subtitle: 'panel.dashboard:onboarding:state:return-form:description',
      };
    case App.Enums.OnboardingStateType.HasReturnQuestions:
      return {
        title: 'panel.dashboard:onboarding:state:return-questions:title',
        subtitle: 'panel.dashboard:onboarding:state:return-questions:description',
      };
    case App.Enums.OnboardingStateType.HasReturnRules:
      return {
        title: 'panel.dashboard:onboarding:state:return-rules:title',
        subtitle: 'panel.dashboard:onboarding:state:return-rules:description',
      };
    case App.Enums.OnboardingStateType.HasIntegrations:
      return {
        title: 'panel.dashboard:onboarding:state:integrations:title',
        subtitle: 'panel.dashboard:onboarding:state:integrations:description',
      };
    case App.Enums.OnboardingStateType.HasTestReturn:
      return {
        title: 'panel.dashboard:onboarding:state:test-return:title',
        subtitle: 'panel.dashboard:onboarding:state:test-return:description',
      };
    default:
      throw new Error('Onboarding state not implemented.');
  }
}
