<script lang="ts" setup>
import {
  Button,
  Heading,
  HeadingElement,
  HeadingSize,
  Stack,
  StackItem,
  StackSpacing,
  TextContainer,
  TextInput,
  TextStyle,
} from '@app/panel/Components';
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { useHexColor } from '@app/panel/Composables/useHexColor';
import { computed, watch } from 'vue';
import { MarkdownService } from '@app/shared/Services';
import type { Types } from '@app/shared/types/generated-v2';

type FormLocaleFallbackViewModel = Types['App.Models.ViewModels.FormLocaleFallbackViewModel'];

const props = withDefaults(
  defineProps<{
    color: string;
    cover: string | null;
    logo: string | null;
    title: string;
    introText?: string;
    orderFieldLabel?: string;
    orderFieldPlaceholder?: string | null;
    searchButtonLabel?: string;
    formLocaleFallback: FormLocaleFallbackViewModel;
  }>(), {
    introText: null,
    orderFieldLabel: 'Order number',
    orderFieldPlaceholder: 'ORD000123',
    searchButtonLabel: 'Search',
  },
);

const { contrast, setHexColor } = useHexColor();

const styles = computed(() => {
  return {
    backgroundColor: props.color,
    border: props.color === '#ffffff'
      ? '1px solid #cbd5e1'
      : 'none',
  };
});

const introText = computed(() => {
  return MarkdownService.parse(props.introText ?? '');
});

watch(() => props.color, (newValue) => setHexColor(newValue), {
  immediate: true,
});

function fallbackValue(input: string, fallbackValue: string): string {
  if (input === null || input === '') {
    return fallbackValue;
  }

  return input;
}
</script>

<template>
  <div class="min-h-full flex bg-white shadow-lg pointer-events-none rounded-lg">
    <div class="flex-1 flex flex-col w-8/12 pt-4 pb-4 px-4 sm:px-6 lg:flex-none md:px-24">
      <div class="mx-auto text-center">
        <img
          v-fallback-img
          class="m-auto w-52 h-24 object-contain"
          :src="logo"
          alt="logo-image"
          loading="lazy"
        >
        <div class="mt-4 space-y-4">
          <Heading
            :element="HeadingElement.H1"
            :size="HeadingSize.Large"
          >
            {{ title }}
          </Heading>
          <TextContainer text-center>
            <div class="prose">
              <TextStyle v-html="introText" />
            </div>
          </TextContainer>
        </div>
      </div>
      <div class="mx-auto w-full justify-center my-12 break-all">
        <Stack
          vertical
          :spacing="StackSpacing.Loose"
        >
          <StackItem>
            <TextInput
              :label="fallbackValue(orderFieldLabel, formLocaleFallback.orderFieldLabel)"
              :placeholder="fallbackValue(orderFieldPlaceholder, formLocaleFallback.orderFieldPlaceholder)"
            />
          </StackItem>
          <StackItem>
            <TextInput
              :label="formLocaleFallback.validationFieldLabel"
              :placeholder="formLocaleFallback.validationFieldPlaceholder"
            />
          </StackItem>
          <StackItem>
            <Button
              full-width
              :style="styles"
            >
              <div
                class="flex items-center space-x-2"
                :style="{ color: contrast }"
              >
                <MagnifyingGlassIcon class="h-4 w-4" />

                <span>{{ fallbackValue(searchButtonLabel, formLocaleFallback.searchButtonLabel) }}</span>
              </div>
            </Button>
          </StackItem>
        </Stack>
      </div>
    </div>
    <div class="hidden md:block relative flex-1">
      <img
        v-fallback-img
        class="absolute inset-0 h-full w-full object-cover"
        style="clip-path: ellipse(58% 100% at 60% 50%);"
        :src="cover"
        alt="cover-image"
        loading="lazy"
      >
    </div>
  </div>
</template>
