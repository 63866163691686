<script lang="ts" setup>
import {
  computed,
  PropType,
  useSlots,
} from 'vue';
import {
  Badge,
  BadgeRounded,
  Link,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { StatusBadge } from '@app/panel/types';

const slots = useSlots();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },

  description: {
    type: String,
    required: true,
  },

  href: {
    type: String,
    required: true,
  },

  statusBadge: {
    type: Object as PropType<StatusBadge>,
    default: null,
  },

  dark: {
    type: Boolean,
    default: false,
  },
});

const isDefaultSlotPresent = computed(() => {
  return slots['default'];
});

const hoverClass = computed(() => {
  return props.dark ? 'hover:bg-slate-100' : 'hover:bg-slate-50';
});

const backgroundClass = computed(() => {
  return props.dark ? 'bg-[#fcbcb8]' : 'bg-brand-500';
});
</script>

<template>
  <Link :href="href">
    <div
      class="hidden md:flex space-x-4 p-3 cursor-pointer rounded-md h-full"
      :class="hoverClass"
    >
      <div
        v-if="isDefaultSlotPresent"
        class="shrink-0 flex items-center justify-center h-10 w-10 rounded-md text-white sm:h-12 sm:w-12"
        :class="backgroundClass"
      >
        <slot />
      </div>

      <div class="flex flex-col space-y-1">
        <div class="flex">
          <TextStyle
            :font-size="TextStyleFontSize.Base"
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ title }}
          </TextStyle>

          <div
            v-if="statusBadge"
            class="ml-3"
          >
            <Badge
              :color="statusBadge.color"
              :rounded="BadgeRounded.Full"
            >
              {{ statusBadge.label }}
            </Badge>
          </div>
        </div>

        <TextStyle
          :font-size="TextStyleFontSize.Default"
          :shade="TextStyleShade.Subdued"
          strong
        >
          {{ description }}
        </TextStyle>
      </div>
    </div>

    <div class="flex md:hidden p-2">
      <div
        v-if="isDefaultSlotPresent"
        class="text-slate-500 mr-2"
      >
        <slot />
      </div>

      <div class="whitespace-nowrap">
        <TextStyle strong>
          {{ title }}
        </TextStyle>
      </div>
    </div>
  </Link>
</template>
