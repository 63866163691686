<script lang="ts" setup>
import {
  computed,
  inject,
  PropType,
  Ref,
  watch,
} from 'vue';
import {
  Textarea,
  TextInput,
  TextInputFieldTypes,
  TextStyle,
} from '@app/panel/Components';
import { CheckIcon } from '@heroicons/vue/24/solid';
import * as Types from '@app/panel/types/generated';
import { useForm } from '@inertiajs/vue3';
import formatCurrency from '@app/panel/Utils/formatCurrency';
import { round } from 'lodash';
import { IReturnOrderItemDepreciation } from './ReturnOrderItemDepreciation';
import { ReturnOrderItemDepreciationType } from './ReturnOrderItemDepreciation';

const emit = defineEmits<{
  (e: 'update', data: ReturnType<typeof depreciationForm.data>): void;
}>();

const props = defineProps({
  depreciation: {
    type: Object as PropType<IReturnOrderItemDepreciation>,
    required: true,
  },

  count: {
    type: Number,
    required: true,
  },

  item: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnOrderItemViewModel>,
    required: true,
  },
});

const depreciationTypes = [
  {
    value: 'percentage',
    label: '%',
  },
  {
    value: 'fixed',
    label: '€',
  },
];

const depreciationForm = useForm({
  id: props.depreciation?.id,
  comment: props.depreciation?.comment,
  value: props.depreciation?.value,
  type: props.depreciation?.type ? props.depreciation.type : 'percentage',
});

watch(depreciationForm, function () {
  emit('update', depreciationForm.data());
});

const userLocale = inject<Ref<string>>('userLocale');

const formattedValue = computed(() => {
  const value = props.depreciation.calculateValue();

  return value ? formatCurrency(value, userLocale.value) : null;
});

const depreciationPercentage = computed(() => {
  const value = props.depreciation.calculatePercentage();

  return value ? round(value, 2) : null;
});

const hasDepreciation = computed(() => {
  return props.depreciation && (formattedValue.value || depreciationPercentage.value);
});

const maxValue = computed(() => {
  switch (depreciationForm.type) {
    case ReturnOrderItemDepreciationType.Percentage:
      return 100;
    case ReturnOrderItemDepreciationType.Fixed:
    default:
      return props.item.salesOrderItem.price.amount / 100;
  }
});
</script>

<template>
  <div class="py-4">
    <div class="flex">
      <div class="flex flex-col flex-1">
        <TextStyle strong>
          {{ $t('panel.containers.depreciation_item.item') }} {{ count }}: {{ item.salesOrderItem.price.label }}
        </TextStyle>
        <div v-if="hasDepreciation">
          <span class="text-orange-600"> {{ formattedValue }} {{
            $t('panel.containers.depreciation_item.depreciation')
          }} ({{ depreciationPercentage }}%)</span>
        </div>

        <div
          v-if="!hasDepreciation"
          class="flex space-x-1 mt-1"
        >
          <CheckIcon class="h-5 w-5 text-green-500" />
          <span>{{ $t('panel.containers.depreciation_item.no_depreciation') }}</span>
        </div>
      </div>

      <div class="w-32 flex">
        <div>
          <TextInput
            v-model="depreciationForm.value"
            :max="maxValue"
            :min="0"
            :type="TextInputFieldTypes.Number"
            allow-decimal
            selectable
            :options="depreciationTypes"
            placeholder="0.00"
            :selected="depreciationForm.type"
            @update:selected="(newValue) => depreciationForm.type = newValue"
          />
        </div>
        <div>{{ depreciationForm.errors.value }}</div>
        <div>{{ depreciationForm.errors.type }}</div>
      </div>
    </div>

    <div
      v-if="depreciationForm.value && depreciationForm.value != 0"
      class="mt-2"
    >
      <Textarea
        v-model="depreciationForm.comment"
        :placeholder="$t('panel.containers.depreciation_item.add_note')"
      />
    </div>
  </div>
</template>
