<script lang="ts" setup>
import { CollapsableResourceItem, ResourceList, Tabs, TabsItem } from '@app/panel/Components';
import { type Types } from '@app/shared/types/generated-v2';
import { computed, ref } from 'vue';

type AnalyticsFormQuestionsAnswersViewModel = Types['App.Models.ViewModels.Analytics.AnalyticsFormQuestionsAnswersViewModel'];

const props = defineProps<{
  formsQuestionsAnswers: AnalyticsFormQuestionsAnswersViewModel[];
}>();

const activeTabFormId = ref<string | null>(props.formsQuestionsAnswers[0]?.formId ?? null);

const activeQuestionsAnswers = computed(function () {
  if (activeTabFormId.value === null) {
    return [];
  }
  return props.formsQuestionsAnswers.find((form) => form.formId === activeTabFormId.value)!.questions;
});

function setActiveTabFormId(formId: string) {
  activeTabFormId.value = formId;
}
</script>

<template>
  <div
    v-if="activeTabFormId !== null"
  >
    <div class="border-b mb-3 -mx-6 px-6 -mt-4">
      <Tabs>
        <TabsItem
          v-for="form in formsQuestionsAnswers"
          :key="form.formId"
          :active="activeTabFormId === form.formId"
          type="button"
          @click="setActiveTabFormId(form.formId)"
        >
          {{ form.formName }}
        </TabsItem>
      </Tabs>
    </div>

    <ResourceList>
      <CollapsableResourceItem
        v-for="questionAndAnswer in activeQuestionsAnswers"
        :key="questionAndAnswer.id"
        :title="questionAndAnswer.question"
        compact
      >
        <div
          class="flex flex-col -mx-4 -my-4 text-sm"
        >
          <div
            v-for="answer in questionAndAnswer.answers"
            :key="answer"
            class="even:bg-gray-100 px-4 py-2"
          >
            <span>{{ answer.answer }}</span> <span class="text-slate-500">({{ answer.count }})</span>
          </div>
        </div>
      </CollapsableResourceItem>
    </ResourceList>
  </div>
</template>
