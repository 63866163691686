<script lang="ts" setup>
import {
  computed,
} from 'vue';

const props = defineProps({
  fill: {
    type: Boolean,
    default: false,
  },
});

const classList = computed(() => {
  return [
    { 'flex-1': props.fill },
  ];
});
</script>

<template>
  <div :class="classList">
    <slot />
  </div>
</template>
