<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogCancelButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  ButtonIcon,
  Card,
  CardDescription,
  CardHeader,
  CardSection,
  CardTitle,
  DataList,
  DataListContent,
  DataListItem,
  FormLayout,
  Select,
  SelectOption,
} from '@returnless/focus-ui';
import { router } from '@inertiajs/vue3';
import { EllipsisHorizontalIcon } from '@heroicons/vue/16/solid';
import { CountryViewModel, GeneralSettingsPageViewModel } from './../types';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

defineProps<{
  addableCountryOptions: GeneralSettingsPageViewModel['addableCountryOptions'];
  selectedCountries: GeneralSettingsPageViewModel['selectedCountries'];
}>();

const currentRemovalCountry = ref<CountryViewModel | null>(null);

function openRemovalModal(country: CountryViewModel): void {
  currentRemovalCountry.value = country;
}

function closeRemovalModal(): void {
  currentRemovalCountry.value = null;
}

const isCountryRemoveModalOpen = computed<boolean>(() => currentRemovalCountry.value !== null);

function detachCountry(): void {
  if (currentRemovalCountry.value === null) {
    return;
  }

  router.delete(route('panel.settings.general.remove-country', {
    id: currentRemovalCountry.value.id,
  }), {
    preserveScroll: true,
    onSuccess: closeRemovalModal,
  });
}

function attachCountry(countryId: string | null) {
  if (countryId === null) {
    return;
  }

  router.post(route('panel.settings.general.add-country'), {
    id: countryId,
  }, {
    preserveScroll: true,
  });
}
</script>

<template>
  <Card>
    <CardHeader>
      <CardTitle :card-help="getHelpscoutArticleBeacon('general-settings.general-settings.countries')">
        {{ t('panel.settings.general_settings.general_settings.countries.card_label') }}
      </CardTitle>
      <CardDescription>{{ t('panel.settings.general_settings.general_settings.countries.card_description') }}</CardDescription>
    </CardHeader>
    <CardSection>
      <FormLayout>
        <Select
          :model-value="null"
          :label="t('panel.settings.general_settings.general_settings.countries.select_label')"
          :placeholder="t('panel.settings.general_settings.general_settings.countries.placeholder')"
          name="country_id"
          @update:model-value="attachCountry"
        >
          <SelectOption
            v-for="countryOption in addableCountryOptions"
            :key="countryOption.value"
            :value="countryOption.value"
          >
            {{ countryOption.label }}
          </SelectOption>
        </Select>

        <DataList>
          <DataListItem
            v-for="country in selectedCountries"
            :key="country.id"
          >
            <DataListContent>
              {{ country.name }}
            </DataListContent>
            <ActionList alignment="end">
              <ActionListTrigger>
                <Button
                  icon-only
                  variant="ghost"
                  size="small"
                >
                  <ButtonIcon :icon="EllipsisHorizontalIcon" />
                </Button>
              </ActionListTrigger>
              <ActionListBody>
                <ActionListSection>
                  <ActionListItem
                    variant="destructive"
                    @click="openRemovalModal(country)"
                  >
                    {{ t('panel.settings.general_settings.general_settings.languages.data_table.remove_button') }}
                  </ActionListItem>
                </ActionListSection>
              </ActionListBody>
            </ActionList>
          </DataListItem>
        </DataList>
      </FormLayout>
    </CardSection>
  </Card>

  <AlertDialog
    :open="isCountryRemoveModalOpen && currentRemovalCountry?.isUsedInShippingMethods === false"
    @cancel="closeRemovalModal"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ t('panel.settings:general:about-to-remove-country') }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ t('panel.settings:general:country-removal-description') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancelButton>
          {{ t('panel.settings:general:cancel') }}
        </AlertDialogCancelButton>
        <AlertDialogActionButton
          variant="destructive"
          @click="detachCountry"
        >
          {{ t('panel.settings:general:proceed-country-removal') }}
        </AlertDialogActionButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>

  <AlertDialog
    :open="isCountryRemoveModalOpen && currentRemovalCountry?.isUsedInShippingMethods === true"
    @cancel="closeRemovalModal"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ t('panel.settings:general:remove-country-in-use:title') }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ t('panel.settings:general:remove-country-in-use:description') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancelButton>
          {{ t('panel.settings:general:remove-country-in-use:confirm') }}
        </AlertDialogCancelButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
