import { ref } from 'vue';

export function useModal() {
  const isOpen = ref(false);

  function close() {
    isOpen.value = false;
  }

  function open() {
    isOpen.value = true;
  }

  return { close, isOpen, open };
}
