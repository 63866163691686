<script lang="ts" setup>
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  ref,
  watch,
} from 'vue';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  Link,
  TextContainer,
  TextInput,
  TextInputSize,
} from '@app/panel/Components';
import { GuestLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { asset } from 'laravel-vapor';

const bannerVariant = BannerVariant.Critical;
const otpInputSize = TextInputSize.Large;
const recoveryInputSize = TextInputSize.Small;
const buttonVariant = ButtonVariant.Primary;
const recovery = ref(false);

const otpInputs = reactive({
  digit1: '',
  digit2: '',
  digit3: '',
  digit4: '',
  digit5: '',
  digit6: '',
});

let otpInputsComputed = computed(() => {
  return Object.assign({}, otpInputs);
});

let otpInputsOld = otpInputs;

const onOtpInputChanged = (_, prevValues = otpInputsOld) => {
  otpInputsOld = prevValues;
};

const otpRefs = {};

Object.keys(otpInputs).map((el) => {
  otpRefs[el] = ref(null);
});

const recoveryRef = ref(null);

const form = useForm({
  code: '',
  recovery_code: '',
});

const submit = () => {
  const allInputsFilled = Object.values(otpInputs).every((el) => el !== '');

  if (allInputsFilled) {
    form.code = Object.values(otpInputs).join('');
    form.post('/two-factor-challenge');
  }
};

const getInput = (inputKey) => {
  const inputWrapper = otpRefs[inputKey].value[0];
  return inputWrapper.querySelector('input');
};

const focusInput = () => {
  if (recovery.value) {
    recoveryRef.value.querySelector('input').focus();
  } else {
    getInput('digit1').focus();
  }
};

const toggleRecovery = async () => {
  recovery.value = !recovery.value;

  await nextTick();

  focusInput();
};

const navigate = (e, digit, index) => {
  const isNumber = /^\d$/i.test(e.key);
  const oldValue = otpInputsOld[digit];
  const newValue = otpInputs[digit];

  // If the user presses backspace or delete
  if (e.key === 'Backspace' || e.key === 'Delete') {
    // If the input is empty and not the first one move to the previous, otherwise only delete current value
    if (digit !== 'digit1' && (oldValue == '' && newValue == '')) {
      const previousInputKey = Object.keys(otpRefs)[index - 1];
      getInput(previousInputKey).focus();
    } else {
      // Register that the user has deleted the digit
      onOtpInputChanged(otpInputs, otpInputs);
    }
  } else {
    // Move focus forward if the value is number and not the last input
    if (isNumber && digit != 'digit6') {
      const nextInputKey = Object.keys(otpRefs)[index + 1];
      getInput(nextInputKey).focus();
    }
  }
};

onMounted(() => {
  focusInput();
});

watch(() => otpInputsComputed.value, onOtpInputChanged);
</script>

<template>
  <GuestLayout
    :title="$t('panel.auth.2fa.title')"
    :hero-image="asset('img/login-background.jpeg')"
  >
    <template #subtitle>
      <TextContainer>
        <p class="mt-2 text-sm text-slate-600">
          <span v-if="recovery">{{ $t('panel.auth.2fa.recovery_intro') }}</span>
          <span v-else>{{ $t('panel.auth.2fa.intro') }}</span>
        </p>
        <Link
          preserve-state
          @click="toggleRecovery"
        >
          <span v-if="recovery">{{ $t('panel.auth.2fa.or_use_auth_app') }}</span>
          <span v-else>{{ $t('panel.auth.2fa.or_use_recovery_code') }}</span>
        </Link>
      </TextContainer>
    </template>

    <div>
      <Form
        @submit="submit"
      >
        <div
          v-if="recovery"
          ref="recoveryRef"
          class="gap-6 h-15 font-mono justify-center"
        >
          <TextInput
            id="recovery-code"
            v-model="form.recovery_code"
            :text-size="recoveryInputSize"
            :placeholder="$t('panel.auth.2fa.recovery_code_field_placeholder')"
            type="text"
            @keyup.enter="submit"
          />
        </div>

        <div
          v-else
          class="flex font-mono space-x-2 justify-between"
        >
          <div
            v-for="(digit, index) in Object.keys(otpInputs)"
            :key="index"
            :ref="otpRefs[digit]"
          >
            <TextInput
              :id="digit"
              v-model="otpInputs[digit]"
              :text-size="otpInputSize"
              :name="digit"
              placeholder="&bull;"
              class="text-center text-xl w-10 sm:w-11 lg:w-12 2xl:w-14 h-10 sm:h-11 lg:h-12 2xl:h-14 shadow-sm"
              maxlength="1"
              required
              type="text"
              @keyup.stop="(event) => navigate(event, digit, index)"
            />
          </div>
        </div>

        <div
          v-if="form.errors.code"
          class="mt-7 -mb-6"
        >
          <Banner :variant="bannerVariant">
            {{ form.errors.code }}
          </Banner>
        </div>

        <div class="mt-12">
          <Button
            :variant="buttonVariant"
            full-width
            :type="ButtonType.Submit"
          >
            {{ $t('panel.auth.2fa.sign_in_button') }}
          </Button>
        </div>
      </Form>
    </div>
  </GuestLayout>
</template>
