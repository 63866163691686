<script lang="ts" setup>
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  Form,
  FormLayout,
  Select,
  SelectOption,
  TextField,
  Toggle,
} from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import { PagePropsViewModel } from './../types';

const props = defineProps<{
  open: boolean;
  types: PagePropsViewModel['types'];
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function onCreateModalClose() {
  emit('close');
}

const form = useForm({
  name: '',
  type: 'dropoff',
  is_primary: false,
});

function save() {
  form.post(route('panel.settings.shipping-instructions.store'), {
    preserveScroll: true,
  });
}

</script>

<template>
  <Dialog
    :open="props.open"
    @cancel="onCreateModalClose"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('panel.settings:templates:shipping-instructions:create:head') }}</DialogTitle>
      </DialogHeader>
      <DialogSection>
        <Form
          id="create-form"
          @submit="save"
        >
          <FormLayout>
            <TextField
              v-model="form.name"
              :label="$t('panel.settings:templates:shipping-instructions:create:name-label')"
              :error="form.errors.name"
              max-length="80"
            />
            <Select
              v-model="form.type"
              :label="$t('panel.settings:templates:shipping-instructions:edit:enable-label')"
            >
              <SelectOption
                v-for="type in props.types"
                :key="type.value"
                v-model="form.type"
                :value="type.value"
              >
                {{ type.label }}
              </SelectOption>
            </Select>

            <Toggle
              v-model="form.is_primary"
              :label="$t('panel.settings:templates:shipping-instructions:set-primary', { type: form.type })"
            />
          </FormLayout>
        </Form>
      </DialogSection>
      <DialogFooter>
        <Button
          variant="secondary"
          form="close-form"
          @click="onCreateModalClose"
        >
          {{ $t('panel.global:actions:cancel') }}
        </Button>

        <Button
          :disabled="!form.isDirty"
          :loading="form.processing"
          variant="primary"
          type="submit"
          form="create-form"
        >
          {{ $t('panel.global:actions:save') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
