<script lang="ts" setup>
import { provide, toRef } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import { Page } from '@app/panel/Components';
import {
  DetailFreeShipping,
  DetailInfo,
  DetailNotes,
  DetailOrders,
  DetailPageActions,
  DetailReturnAddress,
  DetailTimeline,
} from './partials';
import { DetailPaymentRequests } from '@app/panel/Containers';
import { hasFeature } from '@app/panel/Utils';
import type { Types } from '@app/shared/types/generated-v2';
import { DetailMetaData, DetailTransactions } from '@app/panel/Pages/Returns/partials';

const props = defineProps<Types['App.Http.ViewModels.Panel.Requests.Detail.ShowRequestOrderViewModel']>();

provide('customerAddress', props.customerAddress);
provide('form', props.form);
provide('returnOrder', toRef(props, 'returnOrder'));
provide('returnOrderItems', toRef(props, 'returnOrderItems'));
provide('requestStatuses', props.requestStatuses);
provide('attachmentFileSizeLimit', props.attachmentFileSizeLimit);
provide('attachmentFileTypes', props.attachmentFileTypes);
provide('returnAddressOptions', props.returnAddressOptions);
provide('shipmentTypes', props.shipmentTypes);
provide('tags', props.tags);
</script>

<template>
  <AppLayout :title="$t('panel.requests:detail', { request: returnOrder.returnNumber })">
    <Page>
      <DetailPageActions />

      <div class="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
        <div class="flex-1 space-y-6">
          <DetailInfo :note-templates="requestNoteTemplates" />

          <DetailNotes
            :return-order="returnOrder"
            :note-templates="requestNoteTemplates"
          />
        </div>

        <div class="basis-4/12 space-y-6">
          <DetailFreeShipping />

          <DetailOrders
            :orders="returnOrder.salesOrders"
            :compatible-sales-order-ids="compatibleSalesOrderIds ?? []"
          />

          <DetailReturnAddress
            :return-order="returnOrder"
            :selected-return-address="returnAddress"
            :return-address-options="returnAddressOptions"
          />

          <DetailTransactions
            v-if="hasFeature('PaymentRequests')"
            :transactions="processedPaymentTransactions"
          />

          <DetailPaymentRequests
            v-if="hasFeature('PaymentRequests')"
            :return-order="returnOrder"
            :note-templates="paymentRequestsNoteTemplates"
            :payment-requests="paymentRequests"
            :country="customerAddress.country"
          />

          <DetailTimeline :items="returnOrderEvents" />

          <DetailMetaData
            :metadata-categories="metadataCategories"
            :return-order="returnOrder"
          />
        </div>
      </div>
    </Page>
  </AppLayout>
</template>
