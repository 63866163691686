<script lang="ts" setup>
import { Button, ButtonType, ButtonVariant, Checkbox, Form, Textarea } from '@app/panel/Components';
import { useForm, usePage } from '@inertiajs/vue3';
import NoteAttachment from './NoteAttachment.vue';
import NoteReplies from './NoteReplies.vue';
import { type NoteForm } from '../types';
import { PaperClipIcon } from '@heroicons/vue/24/outline';
import { computed } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';

const emit = defineEmits<{
  (e: 'submit', form: any): void;
}>();

withDefaults(defineProps<{
  returnOrder: Types['App.Models.ViewModels.ReturnOrderViewModel'];
  noteTemplates: Types['App.Models.ViewModels.NoteTemplateViewModel'][];
  allowVisibleForCustomers: boolean;
}>(), {
  allowVisibleForCustomers: false,
});

const notesVisibleToCustomerByDefault = computed<boolean>(() => {
  return usePage().props.preferences.notes_visible_to_customer_by_default || false;
});

const form = useForm<NoteForm>({
  note: '',
  is_visible_for_customer: notesVisibleToCustomerByDefault.value || false,
  attachments: [],
});

function createNote() {
  emit('submit', form);
}

function attachFiles(attachments): void {
  form.attachments = attachments;
}

function insertNoteTemplateIntoTextarea(template: string): void {
  form.note = form.note.trim() === ''
    ? template
    : form.note + '\r\n' + template;
}

</script>

<template>
  <Form @submit="createNote">
    <div class="space-y-4">
      <Textarea
        v-model="form.note"
        :error="form.errors.note"
        :placeholder="$t('panel.containers:notes-section:add-note')"
        required
      />

      <div class="flex flex-wrap gap-2">
        <template
          v-for="attachment in form.attachments"
          :key="attachment.id"
        >
          <div class="rounded-md px-2 py-1.5 bg-blue-50 hover:bg-blue-100 cursor-pointer w-fit">
            <a
              class="flex items-center space-x-1.5"
              :href="attachment.previewUrl"
              target="_blank"
            >
              <PaperClipIcon class="h-4 w-4 text-slate-500" />

              <span class="text-xs text-slate-700">{{ attachment.filename }}</span>
            </a>
          </div>
        </template>
      </div>

      <div class="flex justify-between items-center">
        <div class="flex items-center space-x-4">
          <Button
            :variant="ButtonVariant.Primary"
            :type="ButtonType.Submit"
            :loading="form.processing"
          >
            {{ $t('panel.containers:notes-section:comment') }}
          </Button>

          <NoteAttachment
            :form="form"
            @attach="attachFiles"
          />

          <NoteReplies
            :return-order="returnOrder"
            :note-templates="noteTemplates"
            @attach="insertNoteTemplateIntoTextarea"
          />
        </div>

        <Checkbox
          v-model="form.is_visible_for_customer"
          :label="$t('panel.containers:notes-section:visible-for-customer-checkbox')"
        />
      </div>
    </div>
  </Form>
</template>
