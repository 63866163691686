<script lang="ts" setup>
import { ref } from 'vue';
import { Popover, SearchInput } from '@app/panel/Components';
import { useApi } from '@app/panel/Composables/useHttp';
import { debounce, has } from 'lodash';
import GlobalSearchBarDropdown from './GlobalSearchBarDropdown.vue';

defineProps({
  placeholder: {
    type: String,
    default: '',
  },
});

const previousSearchString = ref('');
const searchString = ref('');
const open = ref(false);
const searchResults = ref([]);
const loading = ref(false);
const searchRef = ref(null);
const focus = ref<number | null>(null);
const error = ref<string | null>(null);

const debouncedSearch = debounce(() => {
  const searchQuery = searchString.value;

  useApi().get(`/search?query=${searchQuery}`, { handleErrorGlobally: false })
    .then((response) => {
      searchResults.value = response.data.data;
      error.value = null;
    })
    .catch((err) => {
      if (err.response?.status === 422) {
        const errors = err.response.data.errors as object;
        if (has(errors, 'query')) {
          error.value = errors['query'][0];
        }
      }
      searchResults.value = [];
    })
    .finally(() => {
      previousSearchString.value = searchQuery;
      focus.value = null;
      loading.value = false;
      open.value = true;
    });
}, 500);

const search = () => {
  const trimmedSearchString = searchString.value.trim();
  const trimmedPreviousSearchString = previousSearchString.value.trim();

  if (trimmedSearchString === '') {
    previousSearchString.value = '';
    return;
  }

  if (trimmedSearchString === trimmedPreviousSearchString) {
    return;
  }

  loading.value = true;

  debouncedSearch();
};

const navigateThroughSearchResults = (event) => {
  if (searchResults.value.length === 0) {
    return;
  }

  const maxIndex = searchResults.value.length - 1;

  switch (event.key) {
    case 'Enter':
      if (focus.value !== null) {
        const currentSearchResult = searchRef.value.querySelector(`#search-result-${focus.value}`);
        currentSearchResult?.click();
      }

      break;

    case 'ArrowUp':
      if (focus.value === null) {
        focus.value = 0;
      } else if (focus.value === 0) {
        focus.value = maxIndex;
      } else if (focus.value > 0) {
        focus.value--;
      }

      event.preventDefault();

      break;

    case 'ArrowDown':
      if (focus.value === null) {
        focus.value = 0;
      } else if (focus.value === maxIndex) {
        focus.value = 0;
      } else if (focus.value < maxIndex) {
        focus.value++;
      }

      event.preventDefault();

      break;
  }
};

const openSearchPopover = () => open.value = true;
const closeSearchPopover = () => open.value = false;

const setFocus = (value) => focus.value = value;

const searchInput = ref<HTMLElement>(null);
</script>

<template>
  <div
    ref="searchRef"
    class="px-3"
  >
    <div>
      <div ref="searchInput">
        <SearchInput
          v-model="searchString"
          :loading="loading"
          full-width
          :placeholder="placeholder"
          @update:model-value="search"
          @keydown.stop="navigateThroughSearchResults"
          @click="() => searchString.length > 0 && openSearchPopover()"
        />
      </div>

      <Popover
        flush
        full-width
        :active="open && searchString !== '' && previousSearchString !== ''"
        :parent="searchInput"
        @close="closeSearchPopover"
      >
        <GlobalSearchBarDropdown
          :search-results="searchResults"
          :previous-search-string="previousSearchString"
          :focus="focus"
          :error="error"
          @mouseover="setFocus"
        />
      </Popover>
    </div>
  </div>
</template>
