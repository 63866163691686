<script lang="ts" setup>
import { Avatar, AvatarGroup, DataTable, TextStyleShade, Tooltip } from '@app/panel/Components';
import { CheckIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/solid';
import { InertiaForm, router } from '@inertiajs/vue3';
import { computed, onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import * as Types from '@app/panel/types/generated';
import { ShippingMethod } from '@app/panel/Pages/Settings/Forms/ShippingMethods/types/ShippingMethod';
import TextStyle from '@app/panel/Components/TextStyle/components/TextStyle.vue';
import { cleanObject } from '@app/panel/Utils/object';

type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;
type FormShippingMethodsItemViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsItemViewModel;

const { t } = useI18n();

const props = defineProps<{
  form: FormShippingMethodsViewModel['form'];
  selectedContract: FormShippingMethodsViewModel['selectedContract'];
  formShippingMethodsListViewModel: FormShippingMethodsViewModel['formShippingMethodsListViewModel'];
  sortBy: string | null;
  filterForm: InertiaForm;
}>();

const emit = defineEmits<{
  sorted: [items: string | null];
}>();

const dataTableItems = defineModel<FormShippingMethodsItemViewModel[]>('dataTableItems', { required: true });
const selectedDataTableItems = defineModel<FormShippingMethodsItemViewModel[]>('selectedDataTableItems', { required: true });

const hasPartner = computed<boolean>(() => {
  return usePage().props.hasPartner as boolean;
});

const dataTableHeadings = ref([
  { value: 'carrier', text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.carrier') },
  {
    value: 'name',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.name'),
    column: 'shipping_method_name',
    sortable: true,
  },
  {
    value: 'fromCountry',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.from_country'),
  },
  {
    value: 'toCountry',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.to_country'),
  },
  {
    value: 'minWeight',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.from_weight'),
    column: 'min_weight',
    sortable: true,
  },
  {
    value: 'maxWeight',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.to_weight'),
    column: 'max_weight',
    sortable: true,
  },
  {
    value: 'cost',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.cost'),
    column: 'cost_price',
    sortable: true,
  },
  {
    value: 'price',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.price'),
    column: 'form_shipping_method_price',
    sortable: true,
  },
  {
    value: 'type',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.type'),
    column: 'type',
    sortable: true,
  },
  {
    value: 'maxWidth',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.max_width'),
    column: 'max_width',
    sortable: true,
  },
  {
    value: 'maxHeight',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.max_height'),
    column: 'max_height',
    sortable: true,
  },
  {
    value: 'maxLength',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.max_length'),
    column: 'max_length',
    sortable: true,
  },
  {
    value: 'productCode',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.product_code'),
    column: 'product_code',
    sortable: true,
  },
  {
    value: 'isPrinterless',
    text: t('panel.settings.forms.list_forms.settings.shipping_methods.table_headings.is_printerless'),
    column: 'is_printerless',
    sortable: true,
  },
]);

onMounted(() => {
  if (hasPartner.value || props.selectedContract !== null) {
    dataTableHeadings.value = dataTableHeadings.value.filter((h) => h.value !== 'cost');
  }
});

function onCheck(checkedItems: FormShippingMethodsItemViewModel[]) {
  selectedDataTableItems.value = checkedItems;
}

function onClick(shippingMethod: ShippingMethod) {
  const url = route('panel.settings.forms.shipping-methods', { form: props.form });

  const data = cleanObject({
    ...props.filterForm.data(),
    shipping_method_id: shippingMethod.id,
    contract: props.selectedContract?.id,
  });

  router.get(url, data, {
    preserveScroll: true,
  });
}

function handleSort(sort: string | null): void {
  emit('sorted', sort);
}
</script>

<template>
  <DataTable
    v-if="dataTableItems"
    clickable
    checkable
    :rounded="false"
    :model-value="selectedDataTableItems"
    :headings="dataTableHeadings"
    :items="dataTableItems"
    :sort-by="sortBy"
    @sorted="handleSort"
    @row-click="onClick"
    @update:model-value="onCheck"
  >
    <template #integration="{ item }">
      <AvatarGroup>
        <Avatar
          :src="item.integrationImage"
        />
      </AvatarGroup>
    </template>

    <template #carrier="{ item }">
      <AvatarGroup>
        <Avatar
          :src="item.carrierImage"
        />
      </AvatarGroup>
    </template>

    <template #name="{ item }">
      <Tooltip
        v-if="item.isDeactivated"
        :content="t('panel.settings:forms:shipping-methods:deactivated-method')"
      >
        <div class="flex space-x-2">
          <TextStyle
            :shade="TextStyleShade.Subdued"
            class="line-through"
          >
            {{ item.name }}
          </TextStyle>

          <ExclamationTriangleIcon class="size-5 text-yellow-500" />
        </div>
      </Tooltip>
    </template>

    <template #isPrinterless="{ item }">
      <CheckIcon
        v-if="item.isPrinterless"
        class="size-4 text-slate-500"
      />

      <XMarkIcon
        v-if="! item.isPrinterless"
        class="size-4 text-slate-500"
      />
    </template>
  </DataTable>
</template>
