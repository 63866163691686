<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Modal,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

defineProps<{
  open: boolean;
}>();

const emit = defineEmits<{
  close: [];
  proceed: [];
}>();

function close(): void {
  emit('close');
}

function proceed(): void {
  emit('proceed');
}
</script>

<template>
  <Modal
    :open="open"
    icon-color="red"
    :title="$t('panel.containers:condition-engine:clear-conditions:title')"
    @close="close"
  >
    <template #icon>
      <ExclamationTriangleIcon class="h-6 w-6" />
    </template>

    <TextStyle :shade="TextStyleShade.Subdued">
      <p>
        {{ $t('panel.containers:condition-engine:clear-conditions:description') }}
      </p>
    </TextStyle>

    <template #actions>
      <Button @click="close">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="proceed"
      >
        {{ $t('panel.global:actions:proceed') }}
      </Button>
    </template>
  </Modal>
</template>
