<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  Modal,
  Select,
  TextInput,
  Toggle,
} from '@app/panel/Components';
import { computed, inject, onMounted, ref } from 'vue';
import * as Types from '@app/panel/types/generated';
import { InertiaForm, useForm } from '@inertiajs/vue3';
import { PasswordConfirmation } from '@app/panel/Containers';
import { App } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';
import { FileUpload } from '@app/panel/Components/FileUpload';
import { UploadedFile } from '@app/panel/Composables/useFileManager';
import { type GoogleMapsPlaceDetails, ReturnAddressForm } from '@app/panel/Pages/Settings/ReturnAddresses/types/ReturnAddressForm.type';
import AddressAutocomplete from '@app/panel/Pages/Settings/ReturnAddresses/partials/AddressAutocomplete.vue';
import { Divider } from '@app/customer/Components';
import { isEmpty, pick } from 'lodash';
import { Alert } from '@returnless/focus-ui';

const { t } = useI18n();

const props = defineProps<{
  open: boolean;
  type: 'edit' | 'create';
  addressType: App.Enums.ReturnAddressType;
  submitRequest: (form: InertiaForm<ReturnAddressForm>) => Promise<boolean>;
  initialFormValue?: ReturnAddressForm;
}>();

const emit = defineEmits([
  'close',
  'delete',
]);

const form = useForm<ReturnAddressForm>(props.initialFormValue ?? {
  google_maps_places_id: null,
  address_type: props.addressType,
  name: null,
  is_connected_to_shipping_methods: false,
  company_name: null,
  country_id: null,
  telephone: null,
  street: null,
  house_number: null,
  suffix: null,
  postal_code: null,
  city: null,
  state: null,
  is_primary: false,
  image: null,
  image_src: null,
  latitude: null,
  longitude: null,
});

const countries = inject<Types.App.Models.ViewModels.CountryViewModel[]>('countries')!;

const addressAutocompleteSearch = ref<string>('');

const googlePlaceAddress = ref<Partial<ReturnAddressForm>>({
  city: null,
  country_id: null,
  house_number: null,
  postal_code: null,
  state: null,
  street: null,
  suffix: null,
});

onMounted(() => {
  googlePlaceAddress.value = pick(form, Object.keys(googlePlaceAddress.value));
});

// Returns true if any of the values are different
const hasModifiedGoogleAddress = computed(() => {
  if (form.google_maps_places_id === null) {
    return false;
  }

  return Object.keys(googlePlaceAddress.value).some((key) => {
    const googleValue = googlePlaceAddress.value[key];
    const formValue = form[key];

    // If both values are nil, we don't consider them as modified.
    if (isEmpty(googleValue) && isEmpty(formValue)) {
      return false;
    }

    return googleValue !== formValue;
  });
});

const countryOptions = computed(() => {
  return countries.map((country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });
});

const selectedCountry = computed(() => {
  return countries.find((country) => country.id === form.country_id);
});

const stateSelectOptions = computed(() => {
  return selectedCountry.value?.states.map((item) => {
    return {
      label: item.state,
      value: item.code,
    };
  }) || {};
});

function countryHasChanged() {
  form.state = null;
}

const uploadedFiles = ref<UploadedFile[]>([]);

function deleteAddress() {
  emit('delete');
}

function closeModal() {
  addressAutocompleteSearch.value = '';
  uploadedFiles.value = [];
  form.reset();
  form.clearErrors();
  emit('close');
}

async function submit() {
  // Reset the google maps values when the data has been modified manually.
  if (hasModifiedGoogleAddress.value) {
    form.google_maps_places_id = null;
    form.longitude = null;
    form.latitude = null;
    addressAutocompleteSearch.value = '';
  }

  form.image = await uploadedFiles.value[0]?.upload();

  const success = await props.submitRequest(form);

  if (success) {
    closeModal();
  }
}

function onSelectAutocompleteItem(details: GoogleMapsPlaceDetails) {
  form.city = details.city;
  form.country_id = details.country;
  form.google_maps_places_id = details.id;
  form.house_number = details.house_number;
  form.latitude = details.latitude;
  form.longitude = details.longitude;
  form.name = details.name;
  form.postal_code = details.postal_code;
  form.state = details.state;
  form.street = details.street;
  form.suffix = null; // Set this to null since we will never retrieve it from the google maps details.
  form.telephone = details.phone_number;

  googlePlaceAddress.value = pick(form, Object.keys(googlePlaceAddress.value));
}

const isPrimary = form.is_primary;
const isConnectedToShippingMethods = form.is_connected_to_shipping_methods;

const shouldShowPrimaryToggle = computed(() => {
  if (props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION) {
    return false;
  }

  return props.type === 'create' || (props.type === 'edit' && !isPrimary);
});

const translationPrefix = computed(() => {
  return props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION
    ? 'panel.settings:store-locations'
    : 'panel.settings:return-addresses';
});
</script>

<template>
  <Modal
    :title="type === 'create' ? t(`${translationPrefix}:modal:create-title`) : t(`${translationPrefix}:modal:edit-title`)"
    :open="open"
    @close="closeModal"
  >
    <div class="flex flex-col space-y-4">
      <div
        v-show="addressType === App.Enums.ReturnAddressType.STORE_LOCATION"
        class="flex flex-col space-y-4"
      >
        <Alert
          v-if="hasModifiedGoogleAddress"
          :dismissible="false"
          variant="warning"
        >
          {{ $t('panel.settings:forms:store-locations:google-maps:address-changed-banner:description') }}
        </Alert>

        <AddressAutocomplete
          v-model="addressAutocompleteSearch"
          @on-select-autocomplete-item="onSelectAutocompleteItem"
        />

        <Divider />
      </div>

      <Form
        id="form"
        @submit="submit"
      >
        <FormLayout>
          <FormLayout v-if="addressType === App.Enums.ReturnAddressType.RETURN_ADDRESS">
            <TextInput
              v-model="form.company_name"
              :label="$t('panel.settings.addresses.modal.company')"
              :error="form.errors.company_name"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.name"
              :label="addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                ? $t('panel.settings.addresses.modal.store_name')
                : $t('panel.settings.addresses.modal.contact')"
              :error="form.errors.name"
            />
          </FormLayout>

          <FormLayout
            :columns="1"
            :columns-sm="2"
          >
            <FormLayout>
              <TextInput
                v-model="form.street"
                :label="$t('panel.settings.addresses.modal.street')"
                :error="form.errors.street || form.errors.complete_address"
              />
            </FormLayout>

            <FormLayout :columns="2">
              <FormLayout>
                <TextInput
                  v-model="form.house_number"
                  :label="$t('panel.settings.addresses.modal.house_number')"
                  :error="form.errors.house_number || form.errors.house_number_suffix_combination"
                  show-tooltip-error
                />
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="form.suffix"
                  :label="$t('panel.settings.addresses.modal.suffix')"
                  :error="form.errors.suffix"
                  show-tooltip-error
                />
              </FormLayout>
            </FormLayout>
          </FormLayout>

          <FormLayout :columns="2">
            <FormLayout>
              <TextInput
                v-model="form.postal_code"
                :label="$t('panel.settings.addresses.modal.postcode')"
                :error="form.errors.postal_code"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="form.city"
                :label="$t('panel.settings.addresses.modal.city')"
                :error="form.errors.city"
              />
            </FormLayout>
          </FormLayout>

          <FormLayout>
            <Select
              v-model="form.country_id"
              :label="$t('panel.settings.addresses.modal.country')"
              :options="countryOptions"
              :error="form.errors.country_id"
              :disabled="type === 'edit'"
              @change="countryHasChanged"
            />
          </FormLayout>

          <FormLayout v-if="selectedCountry?.hasStates">
            <Select
              v-model="form.state"
              :label="$t('panel.settings.addresses.modal.state')"
              :options="stateSelectOptions"
              :error="form.errors.state"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.telephone"
              :label="$t('panel.settings.addresses.modal.phone_number')"
              :error="form.errors.telephone"
            />
          </FormLayout>

          <FormLayout v-if="addressType === App.Enums.ReturnAddressType.STORE_LOCATION">
            <FileUpload
              v-model="uploadedFiles"
              v-model:current-file="form.image_src"
              :label="$t('panel.settings.addresses.modal.image')"
              :accept="['image']"
            />
          </FormLayout>

          <FormLayout v-if="shouldShowPrimaryToggle">
            <Toggle
              v-model="form.is_primary"
              :title="$t('panel.settings.addresses.modal.primary')"
              :error="form.errors.is_primary"
            />
          </FormLayout>
        </FormLayout>
      </Form>
    </div>

    <template #actions>
      <PasswordConfirmation
        v-if="type === 'edit'"
        :restriction="isConnectedToShippingMethods ? $t('panel.settings.addresses.modal.cannot_delete_primary_address') : null"
        :warning="isPrimary ? $t('panel.settings.addresses.modal.warning_when_deleting_primary_address') : null"
        @confirmed="deleteAddress"
      >
        <Button
          :variant="ButtonVariant.Critical"
        >
          {{ $t('panel.global:actions:delete') }}
        </Button>
      </PasswordConfirmation>

      <Button
        :variant="ButtonVariant.Primary"
        :type="ButtonType.Submit"
        :loading="form.processing"
        form="form"
      >
        {{ type === 'create' ? $t(`${translationPrefix}:buttons:create-address`) : $t(`${translationPrefix}:buttons:edit-address`) }}
      </Button>
    </template>
  </Modal>
</template>
