<script lang="ts" setup>
import { computed, inject } from 'vue';
import * as Types from '@app/panel/types/generated';
import { App } from '@app/shared/types/generated-v2';
import { TextStyle } from '@app/panel/Components';

const props = withDefaults(defineProps<{
  title: string;
  defaultType: App.Enums.ReturnOrderType;
  availableTypes?: App.Enums.ReturnOrderType[];
}>(), {
  availableTypes: () => [],
});

const returnOrder = inject<Types.App.Http.ViewModels.Panel.Returns.Detail.ReturnOrderViewModel>('returnOrder');

const resolutionType = computed(() => {
  return props.availableTypes.length === 0 || props.availableTypes.includes(returnOrder.value.returnOrderType)
    ? returnOrder.value.returnOrderType
    : props.defaultType;
});
</script>

<template>
  <div class="mt-2 flex flex-col">
    <TextStyle strong>
      {{ title }}
    </TextStyle>

    <p>
      {{ $t('enums.return-order-type:' + resolutionType) }}
    </p>
  </div>
</template>
