<script lang="ts" setup>
import { computed, inject, Ref, watch } from 'vue';
import {
  AttachmentItem,
  AttachmentList,
  Badge,
  BadgeSize,
  Card,
  CardSection,
  Heading,
  HeadingElement,
  Link,
  TextContainer,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { ReturnOrder, TagPopover } from '@app/panel/Containers';
import { isEmpty, map } from 'lodash';
import { ArrowTopRightOnSquareIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { App, Types } from '@app/shared/types/generated-v2';
import { ReturnStateLabel } from '../partials';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type TagViewModel = Types['App.Models.ViewModels.TagViewModel'];

defineProps<{
  noteTemplates: Types['App.Models.ViewModels.NoteTemplateViewModel'];
}>();

const customerAddress = inject<Types['App.Models.ViewModels.CustomerAddressViewModel']>('customerAddress')!;
const form = inject<Types['App.Models.ViewModels.FormViewModel']>('form')!;
const returnOrder = inject<Ref<Types['App.Models.ViewModels.ReturnOrderViewModel']>>('returnOrder')!;
const returnOrderItems = inject<Ref<Types['App.Models.ViewModels.ReturnOrderItemViewModel'][]>>('returnOrderItems');
const tags = inject<TagViewModel[]>('tags')!;

const tagsForm = useForm({
  tags: map(returnOrder.value.tags, 'id'),
});

const activeTags = computed(() => {
  return tags.filter((tag) => tagsForm.tags.includes(tag.id));
});

function removeTag(tag: TagViewModel): void {
  tagsForm.tags = tagsForm.tags.filter((tagId) => tagId !== tag.id);
}

watch(() => tagsForm.tags, () => {
  tagsForm.post(route('panel.return-orders.tags.store', { returnOrder: returnOrder.value }));
});
</script>

<template>
  <Card>
    <CardSection>
      <div class="flex flex-col space-y-6 sm:flex-row sm:justify-between sm:space-y-0">
        <TextContainer>
          <div class="mb-2 flex items-center space-x-2">
            <Heading :element="HeadingElement.H2">
              {{ returnOrder.returnNumber }}
            </Heading>

            <Link
              v-if="returnOrder.previewUrl"
              native
              target="_blank"
              :href="returnOrder.previewUrl"
            >
              <ArrowTopRightOnSquareIcon class="size-4 cursor-pointer text-slate-400 hover:text-slate-500" />
            </Link>

            <Badge
              :size="BadgeSize.Large"
              :color="returnOrder.status.color"
            >
              {{ returnOrder.status.label }}
            </Badge>
            <Badge
              v-if="returnOrder.isTestRecord"
              :size="BadgeSize.Large"
              color="yellow"
            >
              <div class="flex items-center space-x-1">
                <ExclamationTriangleIcon class="size-4" />
                <span>{{ t('panel.requests:detail:test_mode') }}</span>
              </div>
            </Badge>
          </div>
        </TextContainer>

        <!-- TODO RET-297 remove returnOrder.useNewReturnOrderStates fro v-if when fully implemented -->
        <div class="flex items-center space-x-4">
          <div v-if="returnOrder.useNewReturnOrderStates">
            <ReturnStateLabel
              :state="returnOrder.state as App.Enums.ReturnOrder.ReturnOrderState"
              :show-text="true"
            />
          </div>

          <TagPopover
            v-model="tagsForm.tags"
            :tags="tags"
          />
        </div>
      </div>

      <div class="flex space-x-2">
        <div
          v-for="tag in activeTags"
          :key="tag.id"
        >
          <Badge
            :color="tag.color"
            closeable
            @close="() => removeTag(tag)"
          >
            {{ tag.name }}
          </Badge>
        </div>
      </div>
    </CardSection>

    <CardSection>
      <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-2">
        <div class="sm:order-1">
          <TextContainer>
            <TextStyle
              strong
              :shade="TextStyleShade.Subdued"
            >
              {{ t('panel.returns.detail_info.return_form') }}
            </TextStyle>

            <TextStyle :shade="TextStyleShade.Dense">
              <p>
                <Link :href="$route('panel.settings.forms.general', form)">
                  {{ form.name }}
                </Link>

                <TextStyle
                  v-if="returnOrder.returnRule"
                  :shade="TextStyleShade.Subdued"
                >
                  &mdash; {{ t('panel.requests:detail:rule:triggered-by') }}
                  <Link :href="$route('panel.settings.form.return-rules.show', {form, returnRule: returnOrder.returnRule})">
                    {{ returnOrder.returnRule.name }}
                  </Link>
                </TextStyle>
              </p>
            </TextStyle>
          </TextContainer>
        </div>

        <div class="sm:order-3 lg:order-2">
          <TextContainer>
            <TextStyle
              strong
              :shade="TextStyleShade.Subdued"
            >
              {{ t('panel.returns.detail_info.return_date') }}
            </TextStyle>

            <TextStyle :shade="TextStyleShade.Dense">
              <p>{{ returnOrder.createdAt }}</p>
            </TextStyle>
          </TextContainer>
        </div>

        <div class="sm:order-2 lg:order-4">
          <TextContainer>
            <TextStyle
              strong
              :shade="TextStyleShade.Subdued"
            >
              {{ t('panel.returns.detail_info.customer') }}
            </TextStyle>

            <TextStyle :shade="TextStyleShade.Dense">
              <p class="flex w-full space-x-1">
                <span class="shrink-0">{{ returnOrder.customer.name }}</span>
                <span
                  v-if="returnOrder.customer.externalId"
                  :title="returnOrder.customer.externalId"
                  class="flex text-slate-500"
                >
                  <span>(</span>
                  <span class="max-w-20 truncate lg:max-w-32">{{ returnOrder.customer.externalId }}</span>
                  <span>)</span>
                </span>
              </p>

              <p>
                {{ returnOrder.customer.email }}
              </p>

              <p v-if="customerAddress.telephone">
                {{ customerAddress.telephone }}
              </p>
            </TextStyle>
          </TextContainer>
        </div>

        <div class="sm:order-4 lg:order-5">
          <TextContainer>
            <TextStyle
              strong
              :shade="TextStyleShade.Subdued"
            >
              {{ t('panel.returns.detail_info.customer_address') }}
            </TextStyle>

            <TextStyle :shade="TextStyleShade.Dense">
              <p>
                {{ customerAddress.street }}
                {{ customerAddress.houseNumber }}
              </p>

              <p class="flex items-center space-x-1">
                <span>
                  {{ customerAddress.postalCode }}
                  {{ customerAddress.city }}
                  {{ customerAddress.country.isoCode }}
                  <span v-if="customerAddress.state">({{ customerAddress.state }})</span>
                </span>

                <span :class="`fflag fflag-${customerAddress.country.isoCode} ff-sm`" />
              </p>
            </TextStyle>
          </TextContainer>
        </div>
      </div>
    </CardSection>
  </Card>

  <Card>
    <CardSection
      v-for="item in returnOrderItems"
      :key="item.id"
    >
      <ReturnOrder
        :item="item"
        :note-templates="noteTemplates"
        :order="returnOrder"
      />
    </CardSection>

    <div
      v-if="returnOrder.answers.length"
      class="py-6"
    >
      <div
        v-for="answer in returnOrder.answers"
        :key="answer.id"
        class="px-6 pb-4 text-sm last:pb-0"
      >
        <TextStyle
          strong
          :shade="TextStyleShade.Dense"
          full-width
        >
          {{ answer.question }}
        </TextStyle>

        <p class="text-slate-500">
          <span v-if="answer.answer">{{ answer.answer }}</span>

          <AttachmentList v-if="!isEmpty(answer.attachments)">
            <AttachmentItem
              v-for="attachment in answer.attachments"
              :key="attachment.id"
            >
              {{ attachment.filename }}

              <template #actions>
                <Link
                  native
                  :href="attachment.path"
                  target="_blank"
                >
                  {{ t('panel.returns.detail_info.view') }}
                </Link>
              </template>
            </AttachmentItem>
          </AttachmentList>
        </p>
      </div>
    </div>
  </Card>
</template>
