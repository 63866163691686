<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  ColorPicker,
  Form,
  FormLayout,
  Modal,
  Textarea,
  TextInput,
} from '@app/panel/Components';
import { InertiaForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import type { TagForm } from './types';

defineProps<{ open: boolean }>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function handleClose() {
  emit('close');
}

const form = inject<InertiaForm<TagForm>>('tagForm');

function createStatus() {
  form.put(route('panel.settings.general.tags.update', { tag: form.id }), {
    onSuccess: () => handleClose(),
  });
}
</script>

<template>
  <Modal
    :open="open"
    @close="handleClose"
  >
    <Form
      id="edit-form"
      @submit="createStatus"
    >
      <FormLayout>
        <FormLayout>
          <ColorPicker
            v-model="form.color"
            :label="$t('panel.settings.general_settings.tags.edit_modal.tag_color_label')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.name"
            name="name"
            :label="$t('panel.settings:general:edit:name')"
            :error="form.errors.name"
          />
        </FormLayout>

        <FormLayout>
          <Textarea
            v-model="form.description"
            name="description"
            :label="$t('panel.settings:general:edit:description')"
            :error="form.errors.description"
          />
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <Button @click="handleClose">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :disabled="! form.isDirty"
        :type="ButtonType.Submit"
        :variant="ButtonVariant.Primary"
        :loading="form.processing"
        form="edit-form"
      >
        {{ $t('panel.settings:general:edit:save') }}
      </Button>
    </template>
  </Modal>
</template>
