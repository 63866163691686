<script lang="ts" setup>
import {
  computed,
  inject,
  provide,
  ref,
  Ref,
  watch,
} from 'vue';
import {
  Banner,
  BannerVariant,
  List,
  ListItem,
  Modal,
} from '@app/panel/Components';
import CreditInvoiceForm from './forms/CreditInvoiceForm.vue';
import * as Types from '@app/panel/types/generated';
import CreateCouponForm from './forms/CreateCouponForm.vue';
import GiftCardAccount from './forms/CreateGiftCardAccount.vue';
import GiftCard from './forms/CreateGiftCard.vue';
import CreateRefund from './forms/CreateRefund.vue';
import CreateReturn from './forms/CreateReturn.vue';
import FinalizeReturn from './forms/FinalizeReturn.vue';
import { Trait } from './types';
import { isEmpty } from 'lodash';
import { usePage } from '@inertiajs/vue3';

const selectedTrait = inject<Ref<Trait>>('selectedTrait');

const props = defineProps<{
  isOpen: boolean;
  returnOrder: Types.App.Models.ViewModels.ReturnOrderViewModel;
}>();

const salesOrder = computed(() => props.returnOrder.salesOrders.find((s) => s.platformIntegrationTenant.id === selectedTrait.value?.platformIntegrationTenantId));

const currency = computed(() => {
  return salesOrder.value.salesOrderItems[0].currency;
});

provide('currency', currency);

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const errors = ref<any>({});

watch(() => usePage().props.errors, (pageErrors) => {
  errors.value = pageErrors;
});

function onModalClose() {
  errors.value = {};
  emit('close');
}

const traitComponent = computed(() => {
  return {
    CreditInvoice: CreditInvoiceForm,
    CreateCoupon: CreateCouponForm,
    GiftCardAccount: GiftCardAccount,
    GiftCard: GiftCard,
    CreateRefund: CreateRefund,
    CreateReturn: CreateReturn,
    FinalizeReturn: FinalizeReturn,
  }[selectedTrait.value.trait];
});
</script>

<template>
  <Modal
    :open="isOpen"
    :title="selectedTrait?.title"
    :subtitle="selectedTrait?.subtitle"
    flush
    @close="onModalClose"
  >
    <template #icon>
      <div
        v-if="selectedTrait"
        class="h-20 flex items-center justify-center"
      >
        <img
          class="h-full object-contain"
          :src="selectedTrait.logo"
          loading="lazy"
        >
      </div>
    </template>

    <div
      v-if="!isEmpty(errors)"
      class="mb-4"
    >
      <Banner :variant="BannerVariant.Critical">
        <List>
          <ListItem
            v-for="(error, key) in errors"
            :key="key"
          >
            {{ error }}
          </ListItem>
        </List>
      </Banner>
    </div>

    <component
      :is="traitComponent"
      :trait="selectedTrait"
      :sales-order="salesOrder"
      @close="onModalClose"
    />
  </Modal>
</template>
