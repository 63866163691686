<script lang="ts" setup>
import { computed, inject, PropType } from 'vue';
import { FormStructure, FormStructureField, FormStructureFieldType } from '../types';
import {
  MultiSelect,
  Repeater,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { Select, SelectOption, TextField, Toggle } from '@returnless/focus-ui';
import { entries, set } from 'lodash';
import { InertiaForm } from '@inertiajs/vue3';
import { get } from 'lodash';
import { FieldGroup } from '@app/panel/Containers';
import LayoutGroup from './LayoutGroup.vue';
import { onMounted } from 'vue';
import { useAlert } from '@app/panel/Composables/useAlert';

const props = defineProps({
  fieldName: {
    type: String,
    required: true,
  },

  type: {
    type: String as PropType<FormStructureFieldType>,
    required: true,
  },

  label: {
    type: String,
    required: true,
  },

  placeholder: {
    type: String,
    default: null,
  },

  helpText: {
    type: String,
    default: null,
  },

  fields: {
    type: Array as PropType<FormStructureField[]>,
    default: null,
  },

  options: {
    type: [Object, String] as PropType<Record<string, string> | string>,
    default: null,
  },

  validation: {
    type: [Array, null] as PropType<string[] | null>,
    default: null,
  },

  default: {
    type: [String, Array, Boolean],
    default: null,
  },

  title: {
    type: String,
    default: null,
  },

  errors: {
    type: Object,
    default: null,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  nullableLabel: {
    type: String,
    default: null,
  },

  onchangeWarning: {
    type: String,
    default: null,
  },
});

const { alert } = useAlert();

const form = inject<InertiaForm<FormStructure>>('form')!;

const computedOptions = computed((): SelectOption[] => {
  return entries(props.options).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });
});

const textInputFields = {
  [FormStructureFieldType.String]: TextInputFieldTypes.Text,
  [FormStructureFieldType.Password]: TextInputFieldTypes.Password,
};

function getModelValue(): string | number | boolean {
  const value = get(form, props.fieldName);
  if (props.type === FormStructureFieldType.Checkbox) {
    return value !== null ? value : false;
  }
  return value;
}
function setModelValue(value: any): void {
  if (props.onchangeWarning) {
    alert(props.onchangeWarning);
  }

  set(form, props.fieldName, value);
}

function getStatuses() {
  return get(form, props.fieldName).length > 0 ? get(form, props.fieldName) : [];
}

const required = computed(() => {
  return props.validation?.includes('required');
});

onMounted(() => {
  const value = get(form, props.fieldName);
  const hasNoValue = value === null || value === undefined;

  if (props.default !== null && hasNoValue) {
    set(form, props.fieldName, props.default);
  }
});

</script>

<template>
  <div
    v-if="type === FormStructureFieldType.Layout"
    class="space-y-2"
  >
    <LayoutGroup :fields="fields" />
  </div>

  <Toggle
    v-if="type === FormStructureFieldType.Checkbox"
    :error="form.errors[fieldName]"
    :label="label"
    :help-text="helpText"
    :model-value="getModelValue()"
    @update:model-value="setModelValue"
  />

  <TextField
    v-if="Object.keys(textInputFields).includes(type)"
    :required="required"
    :model-value="(getModelValue() as string)"
    :type="textInputFields[type]"
    :error="form.errors[fieldName] ?? errors"
    :placeholder="placeholder"
    :help-text="helpText"
    :label="label"
    :name="label"
    :disabled="disabled"
    :password-reveal="false"
    @update:model-value="setModelValue"
  />

  <Select
    v-if="type === FormStructureFieldType.Select"
    :model-value="get(form, props.fieldName)"
    :error="form.errors[fieldName]"
    :label="label"
    :name="label"
    :help-text="helpText"
    :nullable-label="nullableLabel"
    :disabled="disabled"
    @update:model-value="setModelValue"
  >
    <SelectOption
      v-for="option in computedOptions"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </SelectOption>
  </Select>

  <MultiSelect
    v-if="type === FormStructureFieldType.MultiSelect"
    :model-value="getStatuses()"
    :error="form.errors[fieldName]"
    :label="label"
    :placeholder="placeholder"
    :options="computedOptions"
    :help-text="helpText"
    @update:model-value="setModelValue"
  />

  <Repeater
    v-if="type === FormStructureFieldType.Repeater"
    v-model="form[fieldName]"
    :field-name="fieldName"
    :error="form.errors[fieldName] ?? errors"
    :label="label"
    :fields="fields"
  />

  <FieldGroup
    v-if="type === FormStructureFieldType.Group"
    v-model="form[fieldName]"
    :field-name="fieldName"
    :error="form.errors[fieldName]"
    :label="label"
    :fields="fields"
    :title="title"
  />
</template>
