<script lang="ts" setup>
import {
  Card,
  CardSection,
  Listbox,
  ListboxOption,
  ListStyle,
  TextContainer,
  TextStyle,
  TextStyleShade,
  Tooltip,
} from '@app/panel/Components';
import { type Types } from '@app/shared/types/generated-v2';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline';

type SalesOrderViewModel = Types['App.Models.ViewModels.SalesOrderViewModel'];

const props = defineProps<{
  orders: SalesOrderViewModel[];
  compatibleSalesOrderIds: string[];
}>();

function isCompatible(order: SalesOrderViewModel): boolean {
  return props.compatibleSalesOrderIds?.includes(order.id) ?? false;
}
</script>

<template>
  <Card :title="$t('panel.returns.detail_orders.card_title')">
    <CardSection>
      <Listbox
        :style="ListStyle.None"
        flush
        class="space-y-2 divide-y"
      >
        <div
          v-for="order in orders"
          :key="order.id"
        >
          <ListboxOption
            class="py-4 first:pt-0 last:pb-0"
          >
            <div class="flex items-start justify-between">
              <div class="flex space-x-2">
                <img
                  v-if="order.platformIntegration"
                  class="size-10"
                  :alt="order.platformIntegration.logo.name"
                  :src="order.platformIntegration.logo.imageSourceUrl"
                  loading="lazy"
                >

                <TextContainer>
                  <div class="flex items-center space-x-2">
                    <TextStyle strong>
                      {{ order.orderNumber }}
                    </TextStyle>
                    <div v-if="! isCompatible(order)">
                      <Tooltip :content="$t('panel.returns.detail_info.incompatible_sales_order')">
                        <ExclamationTriangleIcon class="size-5 text-red-600" />
                      </Tooltip>
                    </div>
                  </div>

                  <div v-if="order.platformIntegration && order.externalId">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ order.platformIntegration.name }}:
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ order.externalId }}
                    </TextStyle>
                  </div>

                  <div v-if="order.paymentMethod">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns.detail_info.order_payment_method') }}:
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ order.paymentMethod }}
                    </TextStyle>
                  </div>

                  <div v-if="order.shippingMethod">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns.detail_info.order_shipping_method') }}:
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ order.shippingMethod }}
                    </TextStyle>
                  </div>

                  <div v-if="order.orderDate">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns.detail_info.order_date') }}:
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ order.orderDate }}
                    </TextStyle>
                  </div>

                  <div v-if="order.salesChannels.length > 0">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns.detail_info.channel') }}:
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ order.salesChannels.map((salesChannel) => salesChannel.name).join(', ') }}
                    </TextStyle>
                  </div>

                  <div v-if="order.discountCode">
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.returns.detail_info.discount_code') }}:
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ order.discountCode }}
                    </TextStyle>
                  </div>
                </TextContainer>
              </div>
              <a
                v-if="order.platformUrl"
                class="text-slate-400 hover:text-slate-500"
                :href="order.platformUrl"
                target="_blank"
              >
                <ArrowTopRightOnSquareIcon
                  class="size-4 shrink-0"
                />
              </a>
            </div>
          </ListboxOption>
        </div>
      </Listbox>
    </CardSection>
  </Card>
</template>
