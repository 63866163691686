<script setup lang="ts">
import {
  Button,
  ButtonVariant,
  Modal,
  ModalSize,
  Toggle,
} from '@app/panel/Components';
import { Types } from '@app/shared/types/generated-v2';
import { FormShippingMethod } from '@app/panel/Containers';
import { computed } from 'vue';
import { Textarea } from '@app/customer/Components';
import { useForm } from '@inertiajs/vue3';

type FormShippingMethodViewModel = Types['App.Models.ViewModels.FormShippingMethodViewModel'];
type ReturnOrderViewModel = Types['App.Models.ViewModels.ReturnOrderViewModel'];

const props = defineProps<{
  returnOrder: ReturnOrderViewModel;
  open: boolean;
  formShippingMethods: FormShippingMethodViewModel[];
}>();

const emit = defineEmits<{
  close: [];
}>();

const form = useForm({
  should_notify_customer: false,
  note: null,
  form_shipping_method_id: null,
});

function close() {
  emit('close');
}

function selectFormShippingMethod(formShippingMethod: FormShippingMethodViewModel) {
  form.form_shipping_method_id = formShippingMethod.id;
}

function isFormShippingMethodSelected(formShippingMethod: FormShippingMethodViewModel) {
  return form.form_shipping_method_id === formShippingMethod.id;
}

const hasSelectedFormShippingMethod = computed(() => form.form_shipping_method_id !== null);

function save() {
  form.post(route('panel.return-orders.shipment', { returnOrder: props.returnOrder.id }), {
    preserveState: (item) => {
      return Object.keys(item.props.errors).length > 0;
    },
  });
}
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.returns:detail:shipments:create:title')"
    :size="ModalSize.Large"
    @close="close"
  >
    <div class="flex flex-col px-4 space-y-3">
      <Toggle
        v-model="form.should_notify_customer"
        :title="$t('panel.returns:detail:shipments:create:should-notify')"
      />

      <Textarea
        v-if="form.should_notify_customer"
        v-model="form.note"
        :label="$t('panel.returns:detail:shipments:create:note')"
        :error="form.errors.note"
        required
      />

      <div class="grid grid-cols-2 gap-4">
        <FormShippingMethod
          v-for="formShippingMethod in formShippingMethods"
          :key="formShippingMethod.id"
          :form-shipping-method="formShippingMethod"
          :checked="isFormShippingMethodSelected(formShippingMethod)"
          @click="selectFormShippingMethod(formShippingMethod)"
        />
      </div>
    </div>

    <template #actions>
      <Button @click="close">
        {{ $t('panel.returns:detail:shipments:create:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :disabled="! hasSelectedFormShippingMethod"
        @click="save"
      >
        {{ $t('panel.returns:detail:shipments:create:save') }}
      </Button>
    </template>
  </Modal>
</template>
