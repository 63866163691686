<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  Heading,
  Modal,
  ModalSize,
  Textarea,
  TextContainer,
} from '@app/panel/Components';
import { BeakerIcon } from '@heroicons/vue/24/solid';
import { useForm } from '@inertiajs/vue3';
import { asset } from 'laravel-vapor';

withDefaults(defineProps<{
  onboardingIndustries: Array<{ name: string; label: string; slug: string }>;
  show: boolean;
}>(), {
  show: false,
});

const onboardingForm = useForm({
  monthly_orders: null,
  industry: null,
  discovery: null,
});

const monthlyOrders = [
  '0 - 1.000',
  '1000 - 3.000',
  '3.000 - 7.500',
  '7.500 - 15.000',
  '15.000 - 30.000',
  '30.000 - 50.000',
  '50.000 - 100.000',
  '100.000 or more',
];

function setMonthlyOrders(monthlyOrders) {
  onboardingForm.monthly_orders = monthlyOrders;
}

function setIndustry(industry) {
  onboardingForm.industry = industry.slug;
}

function submitForm() {
  onboardingForm.post(route('panel.account.marketing-onboarding'));
}
</script>

<template>
  <Modal
    :open="show"
    :closable="false"
    :size="ModalSize.ExtraLarge"
    icon-color="blue"
    title="Almost ready!"
  >
    <template #icon>
      <BeakerIcon class="w-6 h-6" />
    </template>

    <div class="space-y-10">
      <TextContainer>
        We're really excited to get you up-and-running as soon as possible.
        Could you answer some questions so we can get to know you?
      </TextContainer>

      <div class="space-y-4">
        <Heading>Approximately how many orders do you process monthly?</Heading>
        <div class="grid grid-cols-4 gap-4 mt-2">
          <button
            v-for="(monthlyOrder, key) in monthlyOrders"
            :key="key"
            class="flex border shadow-sm rounded-md p-4 hover:border-slate-400 border-slate-300"
            :class="{ 'bg-slate-200 border-slate-400': onboardingForm.monthly_orders === monthlyOrder }"
            @click="setMonthlyOrders(monthlyOrder)"
          >
            <span class="font-medium">{{ monthlyOrder }}</span>
          </button>
        </div>
      </div>

      <div class="space-y-4">
        <Heading>What industry are you in?</Heading>
        <div class="grid grid-cols-4 gap-4 mt-2">
          <button
            v-for="(industry, key) in onboardingIndustries"
            :key="key"
            class="flex items-center text-left flex-row space-x-4 border shadow-sm rounded-md p-4 hover:border-slate-400 border-slate-300"
            :class="{ 'bg-slate-200 border-slate-400': onboardingForm.industry === industry.slug }"
            @click="setIndustry(industry)"
          >
            <div class="w-8 h-8 flex items-center justify-center">
              <img
                :src="asset(`img/form-industry-icons/${industry.slug}.svg`)"
                loading="lazy"
              >
            </div>
            <span class="font-medium">{{ industry.name }}</span>
          </button>
        </div>
      </div>

      <div class="space-y-4">
        <Heading>How did you find out about Returnless?</Heading>
        <Textarea
          v-model="onboardingForm.discovery"
          :error="onboardingForm.errors.discovery"
          required
        />
      </div>

      <Form @submit="submitForm">
        <Button
          full-width
          :type="ButtonType.Submit"
          :variant="(onboardingForm.monthly_orders && onboardingForm.industry) ? ButtonVariant.Primary : ButtonVariant.Default"
          :disabled="! (onboardingForm.monthly_orders && onboardingForm.industry && onboardingForm.discovery)"
          :loading="onboardingForm.processing"
        >
          Finish
        </Button>
      </Form>
    </div>
  </Modal>
</template>
