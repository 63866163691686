<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import {
  Avatar,
  Badge,
  BadgeRounded,
  Banner,
  BannerVariant,
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  Checkbox,
  DataTable,
  Form,
  Modal,
  ModalSize,
  TextContainer,
  TextInput,
  TextInputFieldTypes,
  TextStyle,
} from '@app/panel/Components';
import { EnvelopeIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { Invitation, TeamMember } from '@app/panel/types';
import {
  filter,
  flatten,
  includes,
  map,
  values,
} from 'lodash';
import type { Types } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';

type UserViewModel = Types['App.Models.ViewModels.UserViewModel'];
type InvitationViewModel = Types['App.Models.ViewModels.InvitationViewModel'];
type PermissionViewModel = Types['App.Models.ViewModels.PermissionViewModel'];

const { t } = useI18n();

const props = defineProps<{
  members: Array<Types['App.Models.ViewModels.MemberViewModel']>;
  isEditingPermissions: boolean;
  permissions: Record<string, PermissionViewModel[]> | null;
  selectedUser:
    | UserViewModel
    | InvitationViewModel
    | null;
  owner: Types['App.Models.ViewModels.UserViewModel'];
  canEditPermissions: boolean;
  inviteInputPlaceholderText: string;
}>();

const confirmingMemberDelete = ref(false);
const confirmingInvitationDelete = ref(false);
const removing = ref(false);
const editingPermissions = ref(props.isEditingPermissions);
const loadingPermissions = ref(false);
const editablePermissions = ref<Record<string, PermissionViewModel[]> | null>(null);
const updatingUserPermissions = ref(false);
const currentItem = ref<TeamMember | Invitation>();

const headings = [
  { text: t('panel.account.team.team_members.name'), value: 'name' },
  { text: t('panel.account.team.team_members.email'), value: 'email' },
  { text: t('panel.account.team.team_members.status'), value: 'statusBadge' },
  { text: t('panel.account.team.team_members.2fa-status'), value: 'twoFactorStatusBadge' },
];

const selectedUserInfo = computed<string>(() => {
  if ((props.selectedUser as UserViewModel)?.name) {
    return (props.selectedUser as UserViewModel).name;
  }

  return (props.selectedUser as InvitationViewModel)?.email;
});

const inviteMemberForm = useForm({
  email: '',
});

const inviteMember = () => {
  inviteMemberForm.post(route('panel.account.invite-member'), {
    preserveState: true,
    preserveScroll: true,
    onStart: () => {
      inviteMemberForm.reset();
    },
  });
};

const editMemberPermissions = (member: TeamMember | Invitation): void => {
  editingPermissions.value = true;
  currentItem.value = member;
  router.visit(route('panel.account.team', {
    member_id: member.id,
  }), {
    onStart: () => loadingPermissions.value = true,
    onFinish: () => loadingPermissions.value = false,
    preserveScroll: true,
    preserveState: true,
  });
};

const closePermissionsModal = () => {
  editingPermissions.value = false;
  loadingPermissions.value = false;
  router.visit(route('panel.account.team'), {
    preserveScroll: true,
    preserveState: true,
  });
};

const remove = (item: TeamMember | Invitation): void => {
  router.delete(route('panel.account.remove-member', { id: item.id }), {
    preserveState: true,
    preserveScroll: true,
    onStart: () => {
      removing.value = true;
    },

    onFinish: () => {
      removing.value = false;

      if (item.resourceType === 'user') {
        closeMemberDeleteModal();
      }

      if (item.resourceType === 'invitation') {
        closeInvitationDeleteModal();
      }
    },
  });
};

const openRemovalModal = (item: TeamMember | Invitation) => {
  if (item.resourceType === 'user') {
    openMemberDeleteModal(item as TeamMember);
  }

  if (item.resourceType === 'invitation') {
    openInvitationDeleteModal(item as Invitation);
  }
};

const openInvitationDeleteModal = (invitation: Invitation): void => {
  currentItem.value = invitation;
  confirmingInvitationDelete.value = true;
};

const closeInvitationDeleteModal = (): void => {
  confirmingInvitationDelete.value = false;
};

const openMemberDeleteModal = (member: TeamMember): void => {
  currentItem.value = member;
  confirmingMemberDelete.value = true;
};

const closeMemberDeleteModal = (): void => {
  confirmingMemberDelete.value = false;
};

watch(() => props.isEditingPermissions, () => {
  editablePermissions.value = props.permissions;
}, { immediate: true });

const modelValue = computed({
  get() {
    const permissions = flatten(values(editablePermissions.value)) as PermissionViewModel[];
    const enabledPermissions = filter(permissions, (el: PermissionViewModel) => el.enabled);
    return map(enabledPermissions, (el: PermissionViewModel) => el.systemName);
  },

  set(newValue) {
    const permissions = flatten(values(editablePermissions.value)) as PermissionViewModel[];

    permissions.forEach((item) => {
      item.enabled = includes(newValue as string[], item.systemName);
    });
  },
});

const updateUserPermissions = () => {
  const permissions = Object.values(editablePermissions.value ?? {}).flat().map((permission: PermissionViewModel) => {
    return {
      system_name: permission.systemName,
      enabled: permission.enabled,
    };
  });

  const url = route('panel.account.update-member-permissions', { user: props.selectedUser });

  router.put(url, { permissions }, {
    preserveState: true,
    preserveScroll: true,

    onStart: () => {
      updatingUserPermissions.value = true;
    },

    onFinish: () => {
      updatingUserPermissions.value = false;

      closePermissionsModal();
    },
  });
};

function togglePermissions(): void {
  const enabled = values(props.permissions)
    .flat()
    .every((permission) => permission.enabled);

  values(props.permissions).forEach((permissions) => {
    (permissions as unknown as PermissionViewModel[]).forEach(
      (permission) => permission.enabled = !enabled,
    );
  });
}
</script>

<template>
  <section>
    <Card
      :title="$t('panel.account.team.team_members.card_title')"
      :subtitle="$t('panel.account.team.team_members.card_subtitle')"
    >
      <CardSection class="pb-0">
        <Banner :variant="BannerVariant.Info">
          {{ $t('panel.account.team.team_members.banner_body') }}
        </Banner>
      </CardSection>

      <CardSection>
        <Form @submit="inviteMember">
          <div class="flex flex-col sm:flex-row sm:items-end">
            <div class="flex-1">
              <TextInput
                v-model="inviteMemberForm.email"
                :type="TextInputFieldTypes.Email"
                required
                :label="$t('panel.account.team.team_members.invite_input_label')"
                :placeholder="inviteInputPlaceholderText"
              />
            </div>

            <div class="mt-4 sm:ml-4 sm:mt-0">
              <Button
                :variant="ButtonVariant.Primary"
                :type="ButtonType.Submit"
                full-width
                :loading="inviteMemberForm.processing"
              >
                <template #icon>
                  <EnvelopeIcon />
                </template>
                {{ $t('panel.account.team.team_members.invite_button') }}
              </Button>
            </div>
          </div>
        </Form>
      </CardSection>

      <CardSection class="pt-0">
        <div class="mb-1">
          <TextStyle strong>
            {{ $t('panel.account.team.team_members.data_table_label') }}
          </TextStyle>
        </div>

        <DataTable
          :headings="headings"
          :items="members"
        >
          <template #name="{ item }">
            <div class="flex items-center">
              <div class="shrink-0">
                <Avatar
                  v-if="item.profilePhotoUrl"
                  :src="item.profilePhotoUrl"
                  rounded
                />
              </div>

              <p class="ml-2">
                <TextContainer>
                  <TextStyle
                    v-if="item.name"
                    strong
                  >
                    {{ item.name }}
                  </TextStyle>
                  <span
                    v-else
                    class="text-slate-300"
                  >
                    &mdash;
                  </span>
                </TextContainer>
              </p>
            </div>
          </template>

          <template #statusBadge="{ item }">
            <Badge
              :color="item.statusBadge.color"
              :rounded="BadgeRounded.Full"
            >
              {{ item.statusBadge.label }}
            </Badge>
          </template>

          <template #twoFactorStatusBadge="{ item }">
            <Badge
              v-if="item.twoFactorStatusBadge"
              :color="item.twoFactorStatusBadge.color"
            >
              {{ item.twoFactorStatusBadge.label }}
            </Badge>
            <span
              v-else
              class="text-slate-300"
            >
              &mdash;
            </span>
          </template>

          <template
            v-if="canEditPermissions"
            #actions="{ item }"
          >
            <TextContainer v-if="owner.id !== item.id">
              <ButtonGroup>
                <Button
                  :variant="ButtonVariant.Primary"
                  plain
                  @click="editMemberPermissions(item)"
                >
                  {{ $t('panel.account.team.team_members.edit_permissions_button') }}
                </Button>
                <Button
                  plain
                  :variant="ButtonVariant.Critical"
                  @click="() => openRemovalModal(item)"
                >
                  {{ $t('panel.account.team.team_members.remove_button') }}
                </Button>
              </ButtonGroup>
            </TextContainer>
            <span
              v-else
              class="text-slate-300"
            >
              &mdash;
            </span>
          </template>
        </DataTable>
      </CardSection>
    </Card>

    <Modal
      :title="$t(
        'panel.account.team.team_members.edit_permissions_modal_title',
        { name: selectedUserInfo }
      )"
      :open="isEditingPermissions"
      :size="ModalSize.Large"
      @close="closePermissionsModal"
    >
      <template #actions>
        <div class="flex grow justify-between">
          <Button @click="togglePermissions">
            {{ $t('panel.account.team.team_members.toggle-all-permissions') }}
          </Button>
          <Button
            :variant="ButtonVariant.Primary"
            :loading="updatingUserPermissions"
            @click="updateUserPermissions"
          >
            {{ $t('panel.account.team.team_members.edit_permissions_save_button') }}
          </Button>
        </div>
      </template>

      <template #icon>
        <Avatar
          v-if="selectedUser"
          :src="selectedUser.profilePhotoUrl"
          size="extra-large"
          rounded
        />
      </template>

      <div class="columns-2">
        <div
          v-for="(groupPermissions, group) in editablePermissions"
          :key="group"
          class="mb-3"
        >
          <TextStyle strong>
            {{ $t('enums.permission-group:' + group) }}
          </TextStyle>

          <div class="mt-1 space-y-0.5">
            <Checkbox
              v-for="permission in groupPermissions"
              :key="permission.systemName"
              v-model="modelValue"
              :value="permission.systemName"
              :label="$t('enums.permission-type:' + permission.systemName)"
            />
          </div>
        </div>
      </div>
    </Modal>

    <Modal
      :open="confirmingMemberDelete"
      icon-color="red"
      :title="$t('panel.account.team.team_members.member_remove_modal_title', { email: currentItem?.email })"
      @close="closeMemberDeleteModal"
    >
      <template #icon>
        <ExclamationTriangleIcon class="size-6" />
      </template>

      <TextContainer>
        <p>
          {{ $t('panel.account.team.team_members.member_remove_modal_body', { email: currentItem?.email }) }}
        </p>
      </TextContainer>

      <template #actions>
        <Button @click="closeMemberDeleteModal">
          {{ $t('panel.account.team.team_members.cancel_button') }}
        </Button>

        <Button
          :variant="ButtonVariant.Critical"
          :loading="removing"
          @click="remove(currentItem)"
        >
          {{ $t('panel.account.team.team_members.remove_button') }}
        </Button>
      </template>
    </Modal>

    <Modal
      :open="confirmingInvitationDelete"
      icon-color="red"
      :title="`${$t('panel.account.team.team_members.invitation_remove_modal_title', { email: currentItem?.email })}`"
      @close="closeInvitationDeleteModal"
    >
      <template #icon>
        <ExclamationTriangleIcon class="size-6" />
      </template>

      <TextContainer>
        <p>
          {{ $t('panel.account.team.team_members.invitation_remove_modal_body', { email: currentItem?.email }) }}
        </p>
      </TextContainer>

      <template #actions>
        <Button @click="closeInvitationDeleteModal">
          {{ $t('panel.account.team.team_members.cancel_button') }}
        </Button>

        <Button
          :variant="ButtonVariant.Critical"
          :loading="removing"
          @click="remove(currentItem)"
        >
          {{ $t('panel.account.team.team_members.remove_button') }}
        </Button>
      </template>
    </Modal>
  </section>
</template>
