<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  Modal,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import { ref } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';

const props = defineProps<{
  open: boolean;
  currentItem: Types['App.Models.ViewModels.WebhookSubscriptionViewModel'] | null;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function close(): void {
  emit('close');
}

const removing = ref<boolean>(false);

function removeWebhook(): void {
  removing.value = true;

  router.delete(route('panel.settings.integrations.webhooks.delete', {
    webhookSubscription: props.currentItem,
  }), {
    preserveScroll: true,
    onSuccess: close,
    onFinish: () => removing.value = false,
  });
}
</script>

<template>
  <Modal
    :open="open"
    icon-color="red"
    :title="$t('panel.settings:integrations:webhooks:delete-modal:title')"
    @close="close"
  >
    <template #icon>
      <ExclamationTriangleIcon class="h-6 w-6" />
    </template>

    <TextContainer :spacing="TextContainerSpacing.Loose">
      <TextStyle :shade="TextStyleShade.Subdued">
        {{ $t('panel.settings:integrations:webhooks:delete-modal:body') }}
      </TextStyle>
    </TextContainer>

    <template #actions>
      <Button @click="close">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :loading="removing"
        :variant="ButtonVariant.Critical"
        @click="removeWebhook"
      >
        {{ $t('panel.global:actions:proceed') }}
      </Button>
    </template>
  </Modal>
</template>
