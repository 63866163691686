<script lang="ts" setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/solid';

const props = withDefaults(defineProps<{
  hrefPrevious?: null | string;
  hrefNext?: null | string;
}>(), {
  hrefPrevious: null,
  hrefNext: null,
});

const buttonClassList = computed(() => {
  return [
    '-ml-px',
    'relative',
    'inline-flex',
    'items-center',
    'justify-center',
    'px-2',
    'py-2',
    'border',
    'border-slate-300',
    'shadow-sm',
    'text-sm',
    'font-medium',
    'focus:outline-none',
    'bg-white',
    'text-slate-500',
    'hover:bg-slate-50',
  ];
});

const enabledButtonClassList = computed((): string[] => {
  return [
    'focus:ring-2',
    'focus:ring-brand-500',
    'focus:z-10',
  ];
});

const disabledButtonClassList = computed((): string[] => {
  return [
    'opacity-50',
    'cursor-not-allowed',
    'pointer-events-none',
  ];
});

const iconClassList = computed((): string[] => {
  return [
    'h-5',
    'w-5',
  ];
});

const hasPrevious = computed((): boolean => {
  return props.hrefPrevious !== null;
});

const hasNext = computed((): boolean => {
  return props.hrefNext !== null;
});
</script>

<template>
  <div class="whitespace-nowrap">
    <Link
      :class="[
        buttonClassList,
        !hasPrevious ? disabledButtonClassList : enabledButtonClassList
      ]"
      :disabled="!hasPrevious"
      :href="hrefPrevious ?? '#'"
      class="rounded-l-md"
      data-test="link-previous"
      preserve-scroll
      preserve-state
      type="button"
    >
      <span class="sr-only">Previous</span>
      <ChevronLeftIcon :class="iconClassList" />
    </Link>

    <Link
      :class="[
        buttonClassList,
        !hasNext ? disabledButtonClassList : enabledButtonClassList
      ]"
      :disabled="!hasNext"
      :href="hrefNext ?? '#'"
      class="rounded-r-md"
      data-test="link-next"
      preserve-scroll
      preserve-state
      type="button"
    >
      <span class="sr-only">Next</span>
      <ChevronRightIcon :class="iconClassList" />
    </Link>
  </div>
</template>
