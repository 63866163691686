<script lang="ts" setup>

import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Button,
  ButtonContent,
  ButtonIcon,
  CardLayout,
  DataList,
  DataListContent,
  DataListItem,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { EllipsisHorizontalIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/vue/16/solid';
import { router } from '@inertiajs/vue3';
import { PagePropsViewModel } from './types';
import { ref } from 'vue';
import CreateReturnInstructionDialog from './Components/CreateReturnInstructionDialog.vue';
import DeleteReturnInstructionDialog from '../Components/DeleteReturnInstructionDialog.vue';

const props = defineProps<PagePropsViewModel>();

const isCreateDialogOpen = ref(false);
const isDeleteDialogOpen = ref(false);
const selectedReturnInstructionForRemoval = ref('');

function openDeleteModal(returnInstructionId: string) {
  selectedReturnInstructionForRemoval.value = returnInstructionId;
  isDeleteDialogOpen.value = true;
}

function goToEdit(returnInstructionId: string) {
  router.visit(route('panel.settings.return-instructions.edit', returnInstructionId));
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:return-instructions')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:templates:return-instructions') }}</PageTitle>
        <PageDescription>{{ $t('panel.settings:templates:return-instructions:description') }}</PageDescription>
        <template
          v-if="props.defaultInstructions.length !> 0"
          #page-header-actions
        >
          <Button
            @click="isCreateDialogOpen = true"
          >
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:templates:return-instructions:create') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <CardLayout>
        <DataList
          v-if="props.defaultInstructions.length > 0"
        >
          <DataListItem
            v-for="instruction in props.defaultInstructions"
            :id="instruction.id"
            :key="instruction.id"
          >
            <DataListContent>
              {{ instruction.name }}
            </DataListContent>

            <ActionList alignment="end">
              <ActionListTrigger>
                <Button
                  icon-only
                  variant="ghost"
                  size="small"
                >
                  <ButtonIcon :icon="EllipsisHorizontalIcon" />
                </Button>
              </ActionListTrigger>
              <ActionListBody>
                <ActionListSection label="Actions">
                  <ActionListItem
                    :icon="PencilIcon"
                    as="button"
                    @click="goToEdit(instruction.id)"
                  >
                    {{ $t('panel.global:actions:edit') }}
                  </ActionListItem>
                </ActionListSection>
              </ActionListBody>
            </ActionList>
          </DataListItem>
        </DataList>
        <div class="h-8" />
        <div>
          <PageHeader>
            <PageTitle>{{ $t('panel.settings:templates:return-instructions:custom') }}</PageTitle>
            <PageDescription>{{ $t('panel.settings:templates:return-instructions:custom-description') }}</PageDescription>
          </PageHeader>

          <DataList
            v-if="props.customInstructions.length > 0"
          >
            <DataListItem
              v-for="instruction in props.customInstructions"
              :id="instruction.id"
              :key="instruction.id"
            >
              <DataListContent>
                {{ instruction.name }}
              </DataListContent>
              <ActionList alignment="end">
                <ActionListTrigger>
                  <Button
                    icon-only
                    variant="ghost"
                    size="small"
                  >
                    <ButtonIcon :icon="EllipsisHorizontalIcon" />
                  </Button>
                </ActionListTrigger>
                <ActionListBody>
                  <ActionListSection label="Actions">
                    <ActionListItem
                      :icon="PencilIcon"
                      as="button"
                      @click="goToEdit(instruction.id)"
                    >
                      {{ $t('panel.global:actions:edit') }}
                    </ActionListItem>
                    <ActionListItem
                      :icon="TrashIcon"
                      as="button"
                      variant="destructive"
                      @click="openDeleteModal(instruction.id)"
                    >
                      {{ $t('panel.global:actions:delete') }}
                    </ActionListItem>
                  </ActionListSection>
                </ActionListBody>
              </ActionList>
            </DataListItem>
          </DataList>
          <EmptyState v-else>
            <EmptyStateContent>
              <EmptyStateTitle>{{ $t('panel.settings:templates:return-instructions:empty:title') }}</EmptyStateTitle>
              <EmptyStateDescription>{{ $t('panel.settings:templates:return-instructions:empty:description') }}</EmptyStateDescription>
            </EmptyStateContent>
            <EmptyStateActions>
              <Button @click="isCreateDialogOpen = true">
                <ButtonIcon :icon="PlusIcon" />
                <ButtonContent>{{ $t('panel.settings:templates:return-instructions:create') }}</ButtonContent>
              </Button>
            </EmptyStateActions>
          </EmptyState>
        </div>
      </CardLayout>
    </Page>
  </AppLayout>

  <CreateReturnInstructionDialog
    :open="isCreateDialogOpen"
    @close="isCreateDialogOpen = false"
  />

  <DeleteReturnInstructionDialog
    :open="isDeleteDialogOpen"
    :return-instruction="selectedReturnInstructionForRemoval"
    @close="isDeleteDialogOpen = false"
  />
</template>
