<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonType,
  ButtonVariant,
  Modal,
  TextInput,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import { watch } from 'vue';

type PersonalAccessTokenViewModel = Types.App.Models.ViewModels.PersonalAccessTokenViewModel;

const props = defineProps<{
  open: boolean;
  current: PersonalAccessTokenViewModel;
}>();

const emit = defineEmits<{ (e: 'close'): void }>();

function handleClose(): void {
  emit('close');

  form.reset();
}

const form = useForm({
  name: props.current ? props.current.name : '',
});

function edit(): void {
  form.put(route('panel.settings.integrations.api-keys.update', { token: props.current }), {
    onSuccess: () => handleClose(),
  });
}

watch(() => props.current, (token: PersonalAccessTokenViewModel) => {
  if (token) {
    form.name = token.name;

    form.defaults();
  }
}, { immediate: true });
</script>

<template>
  <Modal
    :title="$t('panel.settings:integrations:api-keys:edit-api-key')"
    :open="open"
    @close="handleClose"
  >
    <TextInput
      v-model="form.name"
      :error="form.errors.name"
      :label="$t('panel.settings:integrations:api-keys:name')"
    />

    <template #actions>
      <ButtonGroup>
        <Button @click="handleClose">
          {{ $t('panel.settings:integrations:api-keys:cancel') }}
        </Button>

        <Button
          :loading="form.processing"
          :disabled="! form.isDirty"
          :variant="ButtonVariant.Primary"
          :type="ButtonType.Submit"
          @click="edit"
        >
          {{ $t('panel.settings:integrations:api-keys:save') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
