import { ref } from 'vue';

export function useConfirmTriggerChange() {
  const show = ref(false);

  let confirmPromiseResolver: null | ((status: boolean) => void) = null;

  async function asyncConfirm(): Promise<boolean> {
    return new Promise((resolve) => {
      show.value = true;
      confirmPromiseResolver = resolve;
    });
  }

  function confirm() {
    show.value = false;
    confirmPromiseResolver?.call(this, true);
  }

  function cancel() {
    show.value = false;
    confirmPromiseResolver?.call(this, false);
  }

  return {
    show,
    asyncConfirm,
    confirm,
    cancel,
  };
}
