<script lang="ts" setup>
import { Card, CardSection, Heading, Stack, StackItem } from '@app/panel/Components';
import { GuestLayout } from '@app/panel/Layouts';
import { router } from '@inertiajs/vue3';

defineProps({
  partners: {
    type: Object,
    required: true,
  },

  tenants: {
    type: Object,
    required: true,
  },
});

function switchAccount(resource): void {
  router.post(route('panel.account-selection'), {
    resource: resource.id,
  });
}
</script>

<template>
  <GuestLayout :title="$t('panel.account-selection:title')">
    <Stack vertical>
      <StackItem v-if="partners.length">
        <div class="mb-3">
          <Heading>{{ $t('panel.account-selection:partner:header') }}</Heading>
        </div>
        <Card>
          <CardSection
            v-for="partner in partners"
            :key="partner.id"
            class="cursor-pointer hover:bg-slate-50"
            @click="switchAccount(partner)"
          >
            {{ partner.name }}
          </CardSection>
        </Card>
      </StackItem>
      <StackItem v-if="tenants.length">
        <div class="mb-3 mt-6">
          <Heading>{{ $t('panel.account-selection:webshop-accounts:header') }}</Heading>
        </div>
        <Card>
          <CardSection
            v-for="tenant in tenants"
            :key="tenant.id"
            class="cursor-pointer hover:bg-slate-50"
            @click="switchAccount(tenant)"
          >
            {{ tenant.name }}
          </CardSection>
        </Card>
      </StackItem>
    </Stack>
  </GuestLayout>
</template>
