<script lang="ts" setup>
import { Button, Card, CardSection, TextContainer } from '@app/panel/Components';
import { computed, ref } from 'vue';
import { KeyValue, KeyValueList } from '@app/panel/Components/KeyValue';
import { AddMetaDataModal, EditMetaDataModal } from '@app/panel/Pages/Returns/partials';
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { type Types } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';
import { isEmpty } from 'lodash';

const { t } = useI18n();

type metadataType = Types['App.Http.ViewModels.Panel.Returns.Detail.MetadataViewModel'];

const props = defineProps<{
  returnOrder: Types['App.Models.ViewModels.ReturnOrderViewModel'];
  metadataCategories: Types['App.Http.ViewModels.Panel.Returns.Detail.MetadataCategoryViewModel'][];
}>();

const hasMetadata = computed(() => {
  return !isEmpty(props.metadataCategories);
});

const addMetaDataModalOpen = ref<boolean>(false);
const editMetaDataModalOpen = ref<boolean>(false);

const editMetadata = (metadata: metadataType) => {
  selected.value = {
    id: metadata.id,
    key: metadata.key,
    value: metadata.value,
  };
  editMetaDataModalOpen.value = true;
};

const deleteMetadata = (metadata: metadataType) => {
  (useForm({
    id: metadata.id,
  })).delete(route('panel.return-orders.metadata.edit', {
    returnOrder: props.returnOrder.id,
  }), {
    preserveScroll: true,
  });
};

const closeEditModal = () => {
  editMetaDataModalOpen.value = false;
  selected.value = {
    id: '',
    key: '',
    value: '',
  };
};

const selected = ref({
  id: '',
  key: '',
  value: '',
});
</script>

<template>
  <Card :title="t('panel.returns:detail:metadata:card_title')">
    <CardSection v-if="hasMetadata">
      <div
        v-for="category in metadataCategories"
        :key="category.title"
        class="mb-4 pb-4 border-b border-gray-200"
        :class="{
          'border-b-0 !pb-0 !mb-0': category === metadataCategories[metadataCategories.length - 1],
        }"
      >
        <h3 class="text-md font-semibold mb-2">
          {{ category.title }}
        </h3>
        <KeyValueList>
          <KeyValue
            v-for="metadata in category.metadata"
            :key="metadata.key"
            :value-key="metadata.key"
            :value="metadata.value"
            class="group flex flex-row justify-between pl-2"
          >
            <div class="hidden space-x-2 group-hover:flex">
              <PencilSquareIcon
                class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
                @click="editMetadata(metadata)"
              />
              <TrashIcon
                class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
                @click="deleteMetadata(metadata)"
              />
            </div>
          </KeyValue>
        </KeyValueList>
      </div>
    </CardSection>
    <CardSection v-else>
      <TextContainer text-center>
        {{ t('panel.returns:detail:metadata:no-metadata') }}
      </TextContainer>
    </CardSection>

    <CardSection class="pt-0">
      <Button
        full-width
        @click="addMetaDataModalOpen = true"
      >
        {{ t('panel.returns:detail:metadata:manage:create') }}
      </Button>
    </CardSection>
  </Card>

  <AddMetaDataModal
    :open="addMetaDataModalOpen"
    @close="addMetaDataModalOpen = false"
  />
  <EditMetaDataModal
    :open="editMetaDataModalOpen"
    :selected="selected"
    @close="closeEditModal"
  />
</template>
