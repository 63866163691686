<script lang="ts" setup>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';
import { theme } from '../../../Composables/useTheme';

defineProps({
  profilePhoto: {
    type: String,
    required: true,
  },

  name: {
    type: String,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'close'): void;
}>();

function onUserMenuEsc() {
  emit('close');
}

</script>

<template>
  <div class="relative">
    <button
      class="block relative group py-0.5 px-2"
      :class="theme('focus-outline-color')"
      @click="$emit('click')"
      @keyup.esc="onUserMenuEsc"
    >
      <span class="w-full h-full absolute left-0 top-0 rounded group-hover:bg-slate-100" />
      <span class="flex items-center space-x-2 relative">
        <img
          class="h-8 w-8 rounded-full"
          :src="profilePhoto"
          alt="user account"
          loading="lazy"
        >

        <span class="hidden text-sm font-medium sm:block">
          {{ name }}
        </span>

        <ChevronDownIcon class="h-4 w-4" />
      </span>
    </button>

    <slot />
  </div>
</template>
