<script lang="ts" setup>
import {
  Card,
  CardSection,
  Page,
  Stack,
  StackItem,
  StackSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import {
  BillingHistory,
  BillingSubscription,
  Documents,
  InvoiceDetails,
  PaymentMethod,
  StopSubscription,
} from './partials';
import { AppLayout } from '@app/panel/Layouts';
import PageNavigation from './partials/PageNavigation.vue';
import type { Types } from '@app/shared/types/generated-v2';
import { router } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const loadingState = ref(false);

router.on('start', () => {
  loadingState.value = true;
});

router.on('finish', () => {
  loadingState.value = false;
});

const classList = computed(() => {
  if (loadingState.value === true) {
    return 'opacity-25 pointer-events-none';
  }
  return '';
});

const props = defineProps<Types['App.Http.ViewModels.Panel.Account.BillingViewModel']>();

const usagePercentage = computed(() => {
  if (!props.currentProduct) {
    return 0;
  }

  // Max is 100 and always round down
  return Math.floor(Math.min(1, props.currentPeriodUsage / props.currentProduct.volumeIncluded) * 100);
});
</script>

<template>
  <AppLayout :title="$t('panel.account.billing_and_subscription.head_title')">
    <Page :navigation="PageNavigation">
      <stack
        v-if="showSubscriptionPlan === false && isCustomSubscription === false"
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <stack-item>
          <Card :title="t('panel.account:billing:subscription:current:title')">
            <CardSection>
              <div>
                <TextStyle :shade="TextStyleShade.Pale">
                  {{ $t('panel.account:billing:subscription:current:subscription-plan') }}:
                </TextStyle>
                <TextStyle :shade="TextStyleShade.Dense">
                  {{ currentSubscriptionName }}
                </TextStyle>
              </div>
            </CardSection>
          </Card>
        </stack-item>
      </stack>
      <Stack
        vertical
        :spacing="StackSpacing.ExtraLoose"
      >
        <StackItem
          v-if="hasSubscription"
        >
          <Card :title="t('panel.account:billing:subscription:current:title')">
            <CardSection>
              <div class="flex items-center">
                <div class="flex-1">
                  <div>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.account:billing:subscription:current:subscription-plan') }}:
                    </TextStyle>
                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ currentSubscriptionName }}
                    </TextStyle>
                  </div>

                  <p>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.account:billing:subscription:current:billing-cycle') }}:
                    </TextStyle>
                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ currentSubscriptionBillingCycle }}
                    </TextStyle>
                  </p>

                  <p>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ $t('panel.account:billing:subscription:current:price') }}:
                    </TextStyle>
                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ currentSubscriptionPrice }}
                    </TextStyle>
                  </p>

                  <p>
                    <TextStyle :shade="TextStyleShade.Pale">
                      {{ currentSubscriptionLabel }}:
                    </TextStyle>
                    <TextStyle :shade="TextStyleShade.Dense">
                      {{ currentSubscriptionEndDate }}
                    </TextStyle>
                  </p>
                </div>

                <div
                  v-if="showUsage"
                  class="flex flex-1 flex-col space-y-2 border-l-2 border-slate-100 px-6 py-3"
                >
                  <TextStyle bold>
                    {{ $t('panel.account:billing:subscription:current:usage') }}
                  </TextStyle>
                  <TextStyle :shade="TextStyleShade.Pale">
                    {{ $t('panel.account:billing_and_subscription:usage', { current: currentPeriodUsage, total: currentProduct.volumeIncluded, percentage: usagePercentage.toFixed(0) }) }}
                  </TextStyle>

                  <div class="h-2 w-full rounded-lg bg-slate-100">
                    <div
                      class="h-2 rounded-lg bg-brand-500"
                      :class="{
                        'bg-yellow-500': usagePercentage > 90 && usagePercentage < 100,
                        'bg-red-700': usagePercentage === 100,
                      }"
                      :style="{
                        width: `${usagePercentage}%`,
                      }"
                    />
                  </div>
                </div>
              </div>
            </CardSection>
          </Card>
        </StackItem>
        <div
          v-if="showSubscriptionPlan"
          :class="classList"
        >
          <StackItem>
            <BillingSubscription
              :amount-options-annual="amountOptionsAnnual"
              :amount-options-monthly="amountOptionsMonthly"
              :amount="amount"
              :billing-cycle-options="billingCycleOptions"
              :billing-cycle="billingCycle"
              :countries="countries"
              :current-product="currentProduct"
              :features="features"
              :payment-methods="paymentMethods"
              :plans="plans"
              :tenant="tenant"
              :suggested-plan-identifier="suggestedPlanIdentifier"
              :suggested-plan-label="suggestedPlanLabel"
              :has-exceeded-plan="hasExceededPlan"
            />
          </StackItem>
        </div>

        <StackItem>
          <InvoiceDetails
            :tenant="tenant"
            :countries="countries"
          />
        </StackItem>

        <StackItem>
          <PaymentMethod
            :tenant="tenant"
            :payment-methods="paymentMethods"
            :countries="countries"
          />
        </StackItem>

        <StackItem>
          <BillingHistory
            :tenant="tenant"
            :invoices="invoices"
          />
        </StackItem>

        <StackItem>
          <Documents
            :tenant="tenant"
            :documents="documents"
          />
        </StackItem>

        <StackItem>
          <StopSubscription
            :tenant="tenant"
            :stripe-cancellation-feedback-types="stripeCancellationFeedbackTypes"
            :end-of-billing-date="endOfBillingDate"
            :grace-period="subscriptionGracePeriod"
          />
        </StackItem>
      </Stack>
    </Page>
  </AppLayout>
</template>
