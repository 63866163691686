<script lang="ts" setup>
import {
  computed,
  inject,
  PropType,
  ref,
  Ref,
  watch,
} from 'vue';
import {
  Button,
  ButtonVariant,
  Heading,
  HeadingElement,
  Modal,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import DepreciationItem from './DepreciationItem.vue';
import * as Types from '@app/panel/types/generated';
import formatCurrency from '@app/panel/Utils/formatCurrency';
import { IReturnOrderItemDepreciation, ReturnOrderItemDepreciation } from './ReturnOrderItemDepreciation';
import { values } from 'lodash';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },

  item: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnOrderItemViewModel>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function updateDepreciationData(data, index: number): void {
  if (!returnOrderItemDepreciations.value[index].id) {
    returnOrderItemDepreciations.value[index] = new ReturnOrderItemDepreciation(props.item.salesOrderItem.price.amount);
  }

  returnOrderItemDepreciations.value.forEach((_, key) => {
    if (key === index) {
      returnOrderItemDepreciations.value[index].value = parseFloat(data.value);
      returnOrderItemDepreciations.value[index].type = data.type;
      returnOrderItemDepreciations.value[index].comment = data.comment;
    }
  });
}

function removeEmpty(depreciations: IReturnOrderItemDepreciation[]): IReturnOrderItemDepreciation[] {
  return depreciations.filter((depreciation) => {
    return depreciation.value !== null && depreciation.type !== null;
  });
}

function saveDepreciation(): void {
  const data = removeEmpty(returnOrderItemDepreciations.value);

  router.post(route('panel.return-items.update-depreciations', props.item.id), {
    depreciations: data,
  }, { onSuccess: () => onDepreciationModalClose() });
}

function onDepreciationModalClose(): void {
  returnOrderItemDepreciations.value = null;
  emit('close');
}

function createReturnOrderItemDepreciations(): ReturnOrderItemDepreciation[] {
  const price = props.item.salesOrderItem.price.amount;

  const depreciations = values(props.item.depreciations)
    .map(({ id, value, type, comment }) => {
      return new ReturnOrderItemDepreciation(price, id, parseFloat(value), type, comment);
    });

  const distinction = props.item.quantity - depreciations.length;

  for (let i = 0; i < distinction; i++) {
    depreciations.push(new ReturnOrderItemDepreciation(props.item.salesOrderItem.price.amount));
  }

  return depreciations;
}

const returnOrderItemDepreciations = ref<ReturnOrderItemDepreciation[]>(null);

watch([() => props.item, () => props.open], () => {
  returnOrderItemDepreciations.value = createReturnOrderItemDepreciations();
}, { immediate: true });

const filledInReturnOrderItemDepreciations = computed((): ReturnOrderItemDepreciation[] => {
  return returnOrderItemDepreciations.value.filter((depreciation) => {
    return depreciation.value && depreciation.type;
  });
});

const totalDepreciation = computed((): number => {
  return filledInReturnOrderItemDepreciations.value
    .reduce((previous, current: IReturnOrderItemDepreciation) => {
      return previous += current.calculateValue();
    }, 0);
});

const originalValue = computed((): number => {
  return props.item.salesOrderItem.price.amount * props.item.quantity;
});

const remainingValue = computed((): number => {
  return originalValue.value - totalDepreciation.value;
});

const userLocale = inject<Ref<string>>('userLocale');

const formattedTotalDepreciation = computed((): string => {
  return formatCurrency(totalDepreciation.value, userLocale.value);
});

const formattedRemainingValue = computed((): string => {
  return formatCurrency(remainingValue.value, userLocale.value);
});

const formattedValue = computed((): string => {
  return formatCurrency(originalValue.value, userLocale.value);
});
</script>

<template>
  <Modal
    :open="open"
    @close="onDepreciationModalClose"
  >
    <div class="flex items-center flex-col">
      <div class="mb-3">
        <img
          class="mx-auto w-20 h-20 rounded-full"
          :src="item.product.imageSource"
          loading="lazy"
        >
      </div>

      <Heading :element="HeadingElement.H3">
        {{ item.salesOrderItem.productName ? item.salesOrderItem.productName : item.product.name }}
      </Heading>

      <div class="mt-2">
        {{ $t('panel.containers.return_order.item_returned', { quantity: item.quantity }) }}
      </div>

      <div class="w-full divide-y divide-slate-200 mt-4">
        <DepreciationItem
          v-for="(depreciation, index) in returnOrderItemDepreciations"
          :key="index"
          :depreciation="depreciation"
          :count="index + 1"
          :item="item"
          @update="e => updateDepreciationData(e, index)"
        />
      </div>

      <section class="bg-slate-50 rounded-lg px-4 py-6 w-full">
        <div class="py-2">
          <Heading
            :element="HeadingElement.H2"
          >
            {{ $t('panel.containers.return_order.depreciation_modal.summary') }}
          </Heading>
        </div>

        <div class="divide-y divide-slate-200">
          <div class="py-4 flex ">
            <span class="flex-1">{{ $t('panel.containers.return_order.depreciation_modal.original_price') }}</span>

            <TextStyle
              strong
              :shade="TextStyleShade.Dense"
            >
              {{ formattedValue }}
            </TextStyle>
          </div>

          <div class="py-4 flex">
            <span class="flex-1">{{ $t('panel.containers.return_order.depreciation_modal.depreciation_total') }}</span>

            <TextStyle strong>
              <span class="text-orange-600">
                - {{ formattedTotalDepreciation }}
              </span>
            </TextStyle>
          </div>

          <div class="pt-4 pb-2 flex">
            <div class="flex-1">
              <TextStyle
                strong
                :shade="TextStyleShade.Dense"
                :font-size="TextStyleFontSize.Base"
              >
                {{ $t('panel.containers.return_order.depreciation_modal.remaining') }}
              </TextStyle>
            </div>

            <TextStyle
              strong
              :shade="TextStyleShade.Dense"
            >
              {{ formattedRemainingValue }}
            </TextStyle>
          </div>
        </div>
      </section>
    </div>

    <template #actions>
      <Button @click="onDepreciationModalClose">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        @click="saveDepreciation"
      >
        {{ $t('panel.containers.return_order.depreciation_modal.save') }}
      </Button>
    </template>
  </Modal>
</template>
