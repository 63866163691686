<script lang="ts" setup>
import { inject } from 'vue';
import * as Types from '@app/panel/types/generated';
import ReturnOrderLineItem from './ReturnOrderLineItem.vue';
import { Card, CardSection, Heading, HeadingElement } from '@app/panel/Components';

defineProps({
  title: {
    type: String,
    required: true,
  },

  children: {
    type: Array,
    default: () => ([]),
  },
});

const returnOrderItems = inject<Types.App.Models.ViewModels.ReturnOrderItemViewModel[]>('returnOrderItems');
</script>

<template>
  <div class="mb-4">
    <Card>
      <CardSection subdued>
        <Heading :element="HeadingElement.H3">
          {{ title }}
        </Heading>

        <div class="divide-y divide-slate-200">
          <div
            v-for="item in returnOrderItems"
            :key="item.id"
            class="divide-y divide-slate-200"
          >
            <ReturnOrderLineItem
              :item="item"
            />
          </div>
        </div>
      </CardSection>
    </Card>
  </div>
</template>
