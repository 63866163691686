<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { PageNavigation } from '../partials';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  CardSeparator,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Modal,
  Page,
  TextContainer,
  TextInput,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/panel/Components';
import { Select } from '@returnless/focus-ui';
import { ConditionEngine } from '@app/panel/Containers';
import { isEmpty } from 'lodash';
import { provide, ref } from 'vue';
import { TagList, TagPopover } from '@app/panel/Containers';
import { useTags } from '@app/panel/Composables/useTags';
import type { Types } from '@app/shared/types/generated-v2';
import { router } from '@inertiajs/vue3';
import { App } from '@app/shared/types/generated-v2';
import { ClockIcon } from '@heroicons/vue/24/outline';
import Actions from '@app/panel/Pages/Settings/Forms/Actions.vue';
import { toKeyArray } from '@app/panel/Utils/toKeyArray';

type ShowReturnRuleViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ReturnRules.ShowReturnRuleViewModel'];

const props = defineProps<ShowReturnRuleViewModel>();

provide('form', props.form);
provide('countries', props.countries);

const returnRuleForm = useForm({
  name: props.returnRule.name,
  conditions: !isEmpty(props.returnRule.conditions) ? props.returnRule.conditions : {},
  is_enabled: props.returnRule.isEnabled ?? true,
  return_instruction_id: props.returnRule.returnInstruction?.id,
  tags: [],
  level: props.returnRule.level,
  trigger: props.returnRule.trigger,
  action_items: props.returnRuleActions,
});

const { activeTags, removeTag } = useTags(props.tags, returnRuleForm, props.returnRule.tags);

provide('returnForm', returnRuleForm);

function submitReturnRuleForm(): void {
  returnRuleForm
    .transform((data) => {
      return {
        ...data,
        action_items: toKeyArray(data.action_items, 'id'),
      };
    })
    .put(route('panel.settings.form.return-rules.update', {
      form: props.form,
      returnRule: props.returnRule,
    }), {
      preserveScroll: true,
    });
}

const isDeleteModalOpen = ref<boolean>(false);

function openDeleteModal(): void {
  isDeleteModalOpen.value = true;
}

function closeDeleteModal(): void {
  isDeleteModalOpen.value = false;
}

function deleteRule(): void {
  router.delete(route('panel.settings.form.return-rules.delete', {
    form: props.form,
    returnRule: props.returnRule,
  }));
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:form:return-rules:edit:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="submitReturnRuleForm">
        <Card
          :divide="false"
          :secondary-footer-actions="[{
            content: $t('panel.settings:form:return-rules:edit:delete-cta'),
            variant: ButtonVariant.Critical,
            plain: true,
            callback: openDeleteModal,
          }]"
          :primary-footer-action="{
            content: $t('panel.settings:form:return-rules:edit:save-cta'),
            type: ButtonType.Submit,
            loading: returnRuleForm.processing,
          }"
        >
          <div class="px-6 pt-6">
            <div class="flex justify-between">
              <div class="flex space-x-4">
                <Heading>
                  <span class="whitespace-nowrap">
                    {{
                      returnRule.level === App.Enums.EntityLevel.ITEM
                        ? $t('panel.settings:form:item-rules:edit:title')
                        : $t('panel.settings:form:return-rules:edit:title')
                    }}
                  </span>
                </Heading>

                <TagList
                  :tags="activeTags"
                  @remove="removeTag"
                />
              </div>

              <TagPopover
                v-model="returnRuleForm.tags"
                :tags="tags"
              />
            </div>
          </div>

          <CardSection>
            <div class="mb-8 flex w-full items-center space-x-4 rounded-lg bg-blue-100 p-4">
              <ClockIcon class="size-6" />
              <div
                v-if="returnRule.level === App.Enums.EntityLevel.ITEM"
                class="text-sm"
              >
                <strong>{{ $t('panel.settings:forms:return-rules:triggers.order-evaluation') }}</strong>
                <p class="text-xs">
                  {{ $t('panel.settings:forms:return-rules:triggers.order-evaluation.description') }}
                </p>
              </div>

              <div
                v-if="returnRule.level === App.Enums.EntityLevel.RETURN"
                class="text-sm"
              >
                <strong>{{ $t('panel.settings:forms:return-rules:triggers.return-created') }}</strong>
                <p class="text-xs">
                  {{ $t('panel.settings:forms:return-rules:triggers.return-created.description') }}
                </p>
              </div>
            </div>

            <FormLayout>
              <FormLayout :columns="2">
                <TextInput
                  v-model="returnRuleForm.name"
                  :label="$t('panel.settings:form:return-rules:edit:title-label')"
                  :placeholder="$t('panel.settings:form:return-rules:edit:title-placeholder')"
                  :error="returnRuleForm.errors.name"
                />

                <Select
                  v-model="returnRuleForm.return_instruction_id"
                  :label="$t('panel.settings:form:return-rules:edit:show-return-instructions')"
                >
                  <option :value="null">
                    {{ $t('panel.settings:form:return-rules:edit:dont-show-instruction') }}
                  </option>
                  <optgroup :label="$t('panel.settings:form:return-rules:edit:instructions-group:default')">
                    <option
                      v-for="returnInstruction in returnInstructions.filter((instruction) => instruction.isDefault)"
                      :key="returnInstruction.id"
                      :value="returnInstruction.id"
                    >
                      {{ returnInstruction.name }}
                    </option>
                  </optgroup>
                  <optgroup :label="$t('panel.settings:form:return-rules:edit:instructions-group:custom')">
                    <option
                      v-for="returnInstruction in returnInstructions.filter((instruction) => !instruction.isDefault)"
                      :key="returnInstruction.id"
                      :value="returnInstruction.id"
                    >
                      {{ returnInstruction.name }}
                    </option>
                  </optgroup>
                </Select>
              </FormLayout>

              <Toggle
                v-model="returnRuleForm.is_enabled"
                :title="$t('panel.settings:form:return-rules:edit:enable-label')"
              />
            </FormLayout>
          </CardSection>

          <CardSeparator />

          <CardSection>
            <div class="mb-4">
              <Heading :element="HeadingElement.H3">
                <TextStyle
                  strong
                  color="slate"
                  :font-size="TextStyleFontSize.Base"
                  :shade="TextStyleShade.Dense"
                >
                  {{ $t('panel.settings:form:return-rules:edit:conditions-title') }}
                </TextStyle>
              </Heading>
            </div>

            <ConditionEngine
              :available-condition-types="availableConditionTypes"
              :condition-type-map="conditionTypeMap"
              :return-reasons="returnReasons"
              :return-questions="returnQuestions"
              :tags="tags"
              :return-addresses-options="returnAddressesOptions"
              :return-statuses-options="returnStatusesOptions"
              :request-statuses-options="requestStatusesOptions"
              :non-applicable-condition-fields="nonApplicableConditionFields"
            />
          </CardSection>

          <CardSeparator />

          <CardSection>
            <div class="mb-4">
              <Heading :element="HeadingElement.H3">
                <TextStyle
                  strong
                  color="slate"
                  :font-size="TextStyleFontSize.Base"
                  :shade="TextStyleShade.Dense"
                >
                  {{ $t('panel.settings:form:return-rules:edit:action-trigger-title') }}
                </TextStyle>
              </Heading>
            </div>

            <Actions
              v-model="returnRuleForm.action_items"
              :actions="actions"
              :errors="returnRuleForm.errors"
            />
          </CardSection>
        </Card>
      </Form>
    </Page>
  </AppLayout>

  <Modal
    :open="isDeleteModalOpen"
    :title="$t('panel.settings:forms:return-rules:edit:about-to-remove')"
    @close="closeDeleteModal"
  >
    <TextContainer>
      {{ $t('panel.settings:forms:return-rules:edit:removal-description') }}
    </TextContainer>

    <template #actions>
      <Button @click="closeDeleteModal">
        {{ $t('panel.settings:forms:return-rules:edit:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="deleteRule"
      >
        {{ $t('panel.settings:forms:return-rules:edit:proceed-and-remove') }}
      </Button>
    </template>
  </Modal>
</template>
