<script lang="ts" setup>
import { MarkdownService } from '@app/shared/Services';
import { type Types } from '@app/shared/types/generated-v2';
import { computed } from 'vue';
import { ArrowTopRightOnSquareIcon, MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { watch } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  form: Types['App.Models.ViewModels.FormLocaleViewModel'];
}>();

const emit = defineEmits<{
  ref: [HTMLElement];
}>();

const parsedIntroText = computed<string>(() => {
  return MarkdownService.parse(props.form.introText ?? '');
});

const returnFormPreview = ref<HTMLElement | null>(null);

watch(returnFormPreview, () => emit('ref', returnFormPreview.value));
</script>

<template>
  <div class="flex items-center col-span-12 lg:col-span-3">
    <a
      ref="returnFormPreview"
      :href="$route('panel.form-preview-url', form.id)"
      class="group relative w-full max-w-xs h-36 bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl ease-in-out duration-300 hover:-translate-y-1 hover:scale-105"
      target="_blank"
    >
      <div class="hidden group-hover:flex items-center justify-center absolute inset-0 bg-transparent z-50">
        <a class="bg-slate-100 py-3 px-6 rounded-lg">
          <div class="flex items-center space-x-1 text-slate-500">
            <ArrowTopRightOnSquareIcon class="h-5 w-5" />
            <span>{{ $t('panel.dashboard.onboarding_info.return_form_preview.preview_link') }}</span>
          </div>
        </a>
      </div>
      <div class="flex h-full">
        <div class="w-3/4">
          <div class="max-w-xl mx-auto h-full">
            <div class="scale-[0.5] text-[5px] -mt-16">
              <img
                v-fallback-img
                class="mx-auto w-64 h-24 object-contain scale-[0.5]"
                :src="form.logoSource"
                :alt="$t('panel.dashboard.onboarding_info.return_form_preview.preview_link')"
                loading="lazy"
              >
              <div class="max-w-[180px] mx-auto space-y-6">
                <div class="text-center text-slate-700 space-y-1">
                  <h1 class="font-medium text-slate-900 text-[8px]">
                    {{ form.title }}
                  </h1>

                  <p v-html="parsedIntroText" />
                </div>
                <form>
                  <div class="flex flex-col w-full">
                    <div class="space-y-1">
                      <div class="space-y-[1px]">
                        <label class="font-medium text-slate-700">
                          {{ $t('panel.dashboard.onboarding_info.return_form_preview.order_number_input_label') }}</label>
                        <input
                          class="border-[0.5px] border-slate-300 w-full px-2 py-1 appearance-none rounded-sm"
                          :placeholder="$t('panel.dashboard.onboarding_info.return_form_preview.order_number_input_placeholder')"
                        >
                      </div>
                      <div class="space-y-[1px]">
                        <label class="font-medium text-slate-700">
                          {{ $t('panel.dashboard.onboarding_info.return_form_preview.email_input_label') }}
                        </label>
                        <input
                          class="border-[0.5px] border-slate-300 w-full px-2 py-1 appearance-none rounded-sm"
                          type="email"
                          :placeholder="$t('panel.dashboard.onboarding_info.return_form_preview.email_input_placeholder')"
                        >
                      </div>
                      <button
                        class="cursor-pointer text-white appearance-none inline-flex items-center justify-center px-2 py-1 rounded-sm bg-black w-full"
                        :style="{ backgroundColor: form.brandColor }"
                      >
                        <div class="flex items-center space-x-1">
                          <MagnifyingGlassIcon class="h-2 w-2" />
                          <span>{{ $t('panel.dashboard.onboarding_info.return_form_preview.search_button') }}</span>
                        </div>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="relative w-1/4">
          <img
            v-fallback-img
            class="object-cover absolute h-full"
            style="clip-path: ellipse(58% 100% at 60% 50%);"
            :src="form.coverPhotoSource"
            alt="cover-image"
            loading="lazy"
          >
        </div>
      </div>
    </a>
  </div>
</template>
