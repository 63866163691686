<script lang="ts" setup>
import {
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogCancelButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardLayout,
  CardSection,
  CardTitle,
  FormLayout,
  Page,
  TextField,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { router } from '@inertiajs/vue3';
import { PageNavigation } from '../../partials';
import { onMounted, ref } from 'vue';
import { ParameterList } from '@app/panel/Containers';
import { EmailTemplateViewModel, PagePropsViewModel } from './types';

const props = defineProps<PagePropsViewModel>();
const templates = ref<Array<EmailTemplateViewModel>>([]);

function getEmailTemplates() {
  return props.defaultEmailTemplates.map((defaultTemplate: EmailTemplateViewModel) => {
    const emailTemplate = props.emailTemplates.find((emailTemplate) => {
      return emailTemplate.locale.id === defaultTemplate.locale.id;
    });

    if (emailTemplate !== undefined) {
      return emailTemplate;
    }

    return defaultTemplate;
  });
}

function getDefaultEmailTemplate(localeId: string): EmailTemplateViewModel | null {
  const defaultEmailTemplate = props.defaultEmailTemplates.find((defaultTemplate) => {
    return defaultTemplate.locale.id === localeId;
  });

  return defaultEmailTemplate ?? null;
}

onMounted(() => templates.value = getEmailTemplates());

function update(template: EmailTemplateViewModel): void {
  router.put(route('panel.settings.email-templates.update', {
    mutation_type: props.mutationType,
    locale_id: template.locale.id,
    subject: template.subject,
    body: template.body,
  }), {}, {
    preserveState: true,
    preserveScroll: true,
  });
}

const currentTemplate = ref<EmailTemplateViewModel | null>(null);
const isResetConfirmationModalOpen = ref<boolean>(false);

function openResetConfirmationModal(template: EmailTemplateViewModel): void {
  currentTemplate.value = template;
  isResetConfirmationModalOpen.value = true;
}

function closeResetConfirmationModal(): void {
  isResetConfirmationModalOpen.value = false;
  currentTemplate.value = null;
}

function isDefaultTemplate(template: EmailTemplateViewModel): boolean {
  const defaultTemplate = getDefaultEmailTemplate(template.locale.id);

  if (!defaultTemplate) return false;

  return template.body === defaultTemplate.body;
}

function resetTemplate(): void {
  if (!currentTemplate.value) {
    return;
  }

  const defaultTemplate = getDefaultEmailTemplate(currentTemplate.value.locale.id);
  currentTemplate.value.body = defaultTemplate?.body ?? '';

  isResetConfirmationModalOpen.value = false;
}
</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:note-templates:edit:head')">
    <Page :navigation="PageNavigation">
      <CardLayout>
        <Card
          v-for="template in templates"
          :key="template.id"
        >
          <CardHeader>
            <CardTitle>{{ $t('panel.form-notification-type:' + mutationType) + ` (${template.locale.name})` }}</CardTitle>
          </CardHeader>
          <CardSection>
            <div class="mb-6">
              <ParameterList :parameters="parameters" />
            </div>

            <Form>
              <FormLayout>
                <FormLayout>
                  <TextField
                    v-model="template.subject"
                    :label="$t('panel.settings:templates:email-templates:create:subject-label')"
                    max-length="80"
                  />
                </FormLayout>

                <FormLayout>
                  <TextField
                    v-model="template.body"
                    :label="$t('panel.settings:templates:email-templates:create:body-label')"
                    multiline
                    rows="8"
                    max-length="2500"
                  />
                </FormLayout>
              </FormLayout>
            </Form>
          </CardSection>
          <CardFooter>
            <ButtonGroup>
              <Button
                v-if="!isDefaultTemplate(template)"
                variant="secondary"
                @click="openResetConfirmationModal(template)"
              >
                {{ $t('panel.settings:templates:email-templates:create:secondary-footer-action') }}
              </Button>

              <Button
                variant="primary"
                @click="update(template)"
              >
                {{ $t('panel.settings:templates:email-templates:create:primary-footer-action') }}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </CardLayout>
    </Page>
  </AppLayout>

  <AlertDialog
    :open="isResetConfirmationModalOpen"
    variant="destructive"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ $t('panel.settings:templates:email-templates:reset-confirmation:title') }}</AlertDialogTitle>
        <AlertDialogDescription>
          {{ $t('panel.settings:templates:email-templates:reset-confirmation:content') }}
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancelButton @click="closeResetConfirmationModal">
          {{ $t('panel.global:actions:cancel') }}
        </AlertDialogCancelButton>
        <AlertDialogActionButton
          variant="destructive"
          @click="resetTemplate"
        >
          {{
            $t('panel.global:actions:reset')
          }}
        </AlertDialogActionButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
