<script lang="ts" setup></script>

<template>
  <svg
    id="draggable-icon"
    xmlns="http://www.w3.org/2000/svg"
    class="mr-2 h-5 w-5 text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    stroke-width="2"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
    />
  </svg>
</template>
