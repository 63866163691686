import { router } from '@inertiajs/vue3';
import { computed, Ref, watch } from 'vue';

interface Sortable {
  id: string;
}

export function useSortOrder(
  sortedItems: Ref<Sortable[]>,
  route: string,
  options = {},
): void {
  const ids = computed<string[]>(() => {
    return sortedItems.value.map((item) => item.id);
  });

  watch(sortedItems, () => {
    router.put(route, { items: ids.value, ...options }, {
      preserveScroll: true,
      preserveState: true,
    });
  });
}
