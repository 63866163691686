<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { computed } from 'vue';
import Chart from 'chart.js/auto';
import { chartColors } from '../config';
import { Legend } from 'chart.js';
import { useTailwindColor } from '@app/panel/Composables/useTailwindColor';
import FitLegendPlugin from './plugins/FitLegendPlugin';
import BarChartConfig from './configurations/BarChartConfig';
import _ from 'lodash';

const props = withDefaults(
  defineProps<{
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string | null;
      hoverColor: string | null;
      activeColor: string | null;
      pointStyle: string;
    }[];
    height?: 'small' | 'medium' | 'large';
    labels: Record<number, string>[];
    showLegend?: boolean;
    stacked: boolean;
  }>(),
  {
    height: 'large',
    showLegend: true,
    stacked: false,
  },
);

const heightClass = computed(() => {
  switch (props.height) {
    case 'small':
      return 'h-[20rem]';
    case 'medium':
      return 'h-[25rem]';
    default:
      return 'h-[28rem]';
  }
});

const computedDatasets = computed(() => {
  return props.datasets.map((dataset, index) => {
    dataset.backgroundColor = formatColor(dataset.backgroundColor, index);

    // Remove dataset item fields without information (null values)
    return Object.fromEntries(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(dataset).filter(([_, value]) => value !== null),
    );
  });
});

function formatColor(color: string | null, index: number): string {
  if (!color) {
    return chartColors[index].backgroundColor;
  }

  if (color.startsWith('#') || color.startsWith('rgb')) {
    return color;
  }

  try {
    // Try to fetch color hex code from tailwind
    return useTailwindColor(color, '500');
  } catch (e) {
    console.error(e);

    return chartColors[index].backgroundColor;
  }
}

const chartData = computed(() => {
  return {
    labels: props.labels,
    datasets: computedDatasets.value,
  };
});

Chart.register({
  Legend,
});

const $chart = ref<HTMLCanvasElement | null>(null);

onMounted(() => {
  const chart = new Chart($chart.value, {
    type: 'bar',
    data: chartData.value,
    options: _.merge(BarChartConfig, {
      plugins: {
        legend: {
          display: props.showLegend,
        },
      },
      scales: {
        x: {
          stacked: props.stacked,
        },
        y: {
          stacked: props.stacked,
        },
      },
    }),
    plugins: [FitLegendPlugin],
  });

  watch(() => props.datasets, () => {
    chart.data = chartData.value;
    chart.update();
  });
});

</script>

<template>
  <div
    class="relative w-full -mt-8"
    :class="heightClass"
  >
    <canvas ref="$chart" />
  </div>
</template>
