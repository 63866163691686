<script lang="ts" setup>
import {
  ButtonType,
  Card,
  CardSection,
  Form,
  FormLayout,
  Toggle,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';

type PreferencesViewModel = Types.App.Http.ViewModels.Panel.Account.PreferencesViewModel;

const props = defineProps<{
  preferences: PreferencesViewModel['preferences'];
}>();

const form = useForm({
  notes_visible_to_customer_by_default: props.preferences.notesVisibleToCustomerByDefault || false,
});

const update = () => {
  form.put(route('panel.account.preferences.update'), {
    preserveScroll: true,
  });
};
</script>

<template>
  <Form @submit="update">
    <Card
      :title="$t('panel.account:preferences:return:return-preferences')"
      :primary-footer-action="{
        content: $t('panel.account:preferences:return:save'),
        type: ButtonType.Submit,
        loading: form.processing,
        disabled: !form.isDirty,
      }"
    >
      <CardSection>
        <FormLayout>
          <Toggle
            v-model="form.notes_visible_to_customer_by_default"
            :title="$t('panel.account:preferences:return:notes-visible-by-default')"
          />
        </FormLayout>
      </CardSection>
    </Card>
  </Form>
</template>
