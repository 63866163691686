<script lang="ts" setup>
import { computed } from 'vue';
import { Badge, BadgeSize } from '@app/panel/Components';
import { get, isArray, isEmpty } from 'lodash';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  filterForm: {
    type: Object,
    required: true,
  },

  platformIntegrations: {
    type: Array,
    required: true,
  },

  locales: {
    type: Array,
    required: true,
  },

  forms: {
    type: Array,
    required: true,
  },

  requestStatuses: {
    type: Array,
    required: true,
  },

  returnOrderStates: {
    type: Array,
    required: true,
  },

  resetFilter: {
    type: Function,
    required: true,
  },

  noteFilterOptions: {
    type: Array,
    required: true,
  },

  tags: {
    type: Array,
    required: true,
  },
});

const { t } = useI18n();

const items = [
  {
    label: t('panel.requests.active_filters.created_at_from_label'),
    filterFormPath: 'filter.created_at.from',
  },

  {
    label: t('panel.requests.active_filters.created_at_to_label'),
    filterFormPath: 'filter.created_at.to',
  },

  {
    label: t('panel.requests.active_filters.quantity_from_label'),
    filterFormPath: 'filter.quantity.from',
  },

  {
    label: t('panel.requests.active_filters.quantity_to_label'),
    filterFormPath: 'filter.quantity.to',
  },

  {
    label: t('panel.requests.active_filters.value_from_label'),
    filterFormPath: 'filter.value.from',
  },

  {
    label: t('panel.requests.active_filters.value_to_label'),
    filterFormPath: 'filter.value.to',
  },

  {
    label: t('panel.requests.active_filters.platform_integration_label'),
    filterFormPath: 'filter.platform_integration',
    options: 'platformIntegrations',
    path: 'logo.name',
  },

  {
    label: t('panel.requests.active_filters.form_label'),
    filterFormPath: 'filter.form',
    options: 'forms',
    path: 'name',
  },

  {
    label: t('panel.requests.active_filters.status_label'),
    filterFormPath: 'filter.request_status',
    options: 'requestStatuses',
    path: 'label',
  },

  {
    label: t('panel.requests.active_filters.state_label'),
    filterFormPath: 'filter.return_order_state',
    options: 'returnOrderStates',
    path: 'label',
  },

  {
    label: t('panel.requests.active_filters.locale_label'),
    filterFormPath: 'filter.locale',
    options: 'locales',
    path: 'name',
  },

  {
    label: t('panel.requests.active_filters.notes'),
    filterFormPath: 'filter.note_filter',
    options: 'noteFilterOptions',
    path: 'label',
  },

  {
    label: 'Tags',
    filterFormPath: 'filter.tags',
    options: 'tags',
    path: 'name',
  },
];

const filters = computed(() => {
  return items.map((item) => {
    if (item.options) {
      const filterValue = get(props.filterForm, item.filterFormPath);

      if (isArray(filterValue)) {
        const value = props[item.options]
          .filter((option) => filterValue.includes(option.id) || filterValue.includes(option.value))
          .map((selectedOption) => get(selectedOption, item.path));

        return {
          ...item,
          value,
        };
      }

      const selectedOption = props[item.options].find((option) => {
        return option.id === filterValue || option.value === filterValue;
      });

      return {
        ...item,
        value: get(selectedOption, item.path),
      };
    }
    return {
      ...item,
      value: get(props.filterForm, item.filterFormPath),
    };
  });
});

const activeFilters = computed(() => {
  return filters.value.filter((item) => {
    return !isEmpty(item.value);
  });
});
</script>

<template>
  <div
    v-if="activeFilters.length > 0"
    class="flex flex-wrap flex-items-center gap-2 mt-6"
  >
    <Badge
      v-for="(filter, index) in activeFilters"
      :key="index"
      color="blue"
      closeable
      :size="BadgeSize.Large"
      @close="() => resetFilter(filter.filterFormPath)"
    >
      <span v-if="isArray(filter.value)">
        {{ filter.label }}: {{ filter.value.join(', ') }}
      </span>

      <span v-else-if="filter.label === 'Value from' || filter.label === 'Value to'">
        {{ filter.label }} € {{ filter.value }}
      </span>

      <span v-else>{{ filter.label }}: {{ filter.value }}</span>
    </Badge>
  </div>
</template>
