<script lang="ts" setup>
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  Form,
  FormLayout,
  Link,
  Select,
  SelectOption,
  Separator,
  TextStyle,
} from '@returnless/focus-ui';
import { computed, ref } from 'vue';
import type { Types } from '@app/shared/types/generated-v2';
import { App } from '@app/shared/types/generated-v2';
import { useI18n } from 'vue-i18n';
import { router, useForm } from '@inertiajs/vue3';
import { Timeline } from '@app/customer/Components';
import { CheckableTimelineItem } from '@app/panel/Containers';
import { IconSize } from '@app/panel/Components';

type ShipmentViewModel = Types['App.Models.ViewModels.ShipmentViewModel'];

const props = defineProps<{
  open: boolean;
  currentShipment: ShipmentViewModel;
}>();

const emit = defineEmits<{
  onClear: [];
}>();

const { t } = useI18n();

function clearCurrentShipment(): void {
  emit('onClear');
}

const hasLabel = computed<boolean>(() => {
  if (props.currentShipment.isPrinterless) {
    return false;
  }

  if (props.currentShipment.shippingMethod.name === 'Manual Shipment') {
    return false;
  }

  if (props.currentShipment.trackingNumber === '') {
    return false;
  }

  return true;
});

function viewTrackingPage(shipment: ShipmentViewModel): string {
  const trackingUrl = shipment.trackingUrl;

  if (!trackingUrl) {
    alert(t('panel.returns:detail:shipments:tracking-url-not-found', { tracking_number: shipment.trackingNumber }));
  }

  if (trackingUrl) {
    return trackingUrl;
  }
}

function setAsDelivered(): void {
  if (props.currentShipment === null) {
    return;
  }

  router.put(route('panel.return-orders.shipments.mark-delivered', props.currentShipment), {
    preserveScroll: true,
    preserveState: true,
  }, {
    onSuccess: () => {
      clearCurrentShipment();
    },
  });
}

type InvestigationRequestReason = `${App.Enums.ShipmentInvestigationRequestType}`;

const isRequestingInvestigation = ref(false);

const investigationForm = useForm<{
  request_type: InvestigationRequestReason | null;
  acknowledge: boolean;
}>({
  request_type: null,
  acknowledge: false,
});

function cancelInvestigationRequest(): void {
  investigationForm.reset();
  isRequestingInvestigation.value = false;
}

function submitInvestigationRequest(): void {
  investigationForm.post(route('panel.return-orders.shipments.request-investigation', {
    shipment: props.currentShipment,
  }), {
    onFinish: (): void => {
      isRequestingInvestigation.value = false;
    },
  });
}
</script>

<template>
  <Dialog
    :open="open"
    @cancel="clearCurrentShipment"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('panel.returns:detail:shipments:title') }} {{ currentShipment?.trackingNumber }}</DialogTitle>
      </DialogHeader>
      <DialogSection>
        <Form class="space-y-4">
          <FormLayout class="-space-y-4">
            <TextStyle
              strong
              shade="subdued"
            >
              {{ $t('panel.returns:detail:shipments:method') }}
            </TextStyle>
            <TextStyle>
              {{ currentShipment.shippingMethod.name }}
            </TextStyle>
          </FormLayout>
          <FormLayout
            v-if="currentShipment.pickUpTimeSlot !== null"
            class="-space-y-4"
          >
            <TextStyle
              strong
              shade="subdued"
            >
              {{ $t('panel.returns:detail:shipments:estimated-pick-up-timeslot') }}
            </TextStyle>
            <TextStyle>
              {{ currentShipment.pickUpTimeSlot }}
            </TextStyle>
          </FormLayout>
          <FormLayout
            v-if="currentShipment.shippingMethod?.type !== App.Enums.ShippingMethodType.ReturnInStore"
            class="-space-y-4"
          >
            <TextStyle
              strong
              shade="subdued"
            >
              {{ $t('panel.returns:detail:shipments:label') }}
            </TextStyle>
            <TextStyle>
              <Link
                v-if="hasLabel"
                external
                :href="$route('panel.shipments.label', currentShipment)"
              >
                <span>{{ $t('panel.returns:detail:shipments:download') }}</span>
              </Link>

              <span v-if="!hasLabel">
                {{ $t('panel.returns:detail:shipments:no-label') }}
              </span>
            </TextStyle>
          </FormLayout>
          <FormLayout
            v-if="currentShipment.shippingMethod?.type !== App.Enums.ShippingMethodType.ReturnInStore"
            class="-space-y-4"
          >
            <TextStyle
              strong
              shade="subdued"
            >
              {{ $t('panel.returns:detail:shipments:cost') }}
            </TextStyle>
            <TextStyle>
              {{ currentShipment.costPrice }}
            </TextStyle>
          </FormLayout>
        </Form>
        <Separator v-if="!currentShipment.hideTrackingTimeline" />
        <Form v-if="!currentShipment.hideTrackingTimeline">
          <div
            v-if="currentShipment.shippingMethod?.type !== App.Enums.ShippingMethodType.ReturnInStore"
            class="mb-6 flex justify-between"
          >
            <TextStyle strong>
              {{ $t('panel.returns:detail:shipments:tracking-status-updates') }}
            </TextStyle>

            <Link
              v-if="currentShipment.trackingUrl"
              external
              :href="viewTrackingPage(currentShipment)"
            >
              <span>{{ $t('panel.returns:detail:shipments:view-tracking-page') }}</span>
            </Link>
          </div>
          <Timeline>
            <CheckableTimelineItem
              v-for="(log, index) in currentShipment.statusLogs"
              :key="log.id"
              :date="log.createdAt"
              :icon-size="IconSize.Large"
              :completed="true"
              :last-item="currentShipment.statusLogs.length - 1 === index"
              :label="log.status"
            />
          </Timeline>
        </Form>
      </DialogSection>
      <DialogSection v-if="currentShipment.activeInvestigationRequestDate">
        <div class="border rounded-md p-4 shadow-sm space-y-4 bg-slate-50">
          {{ $t('panel.returns:detail:shipments:investigation-request:already-submitted', { date: currentShipment.activeInvestigationRequestDate }) }}
        </div>
      </DialogSection>
      <DialogSection v-if="isRequestingInvestigation">
        <div class="border rounded-md p-4 shadow-sm space-y-4 bg-slate-50">
          <strong>{{ $t('panel.returns:detail:shipments:investigation-request:title') }}</strong>

          <form
            class="space-y-4"
            @submit.prevent="submitInvestigationRequest"
          >
            <Select
              v-model="investigationForm.request_type"
              :label="$t('panel.returns:detail:shipments:investigation-request:type-label')"
              :placeholder="$t('panel.returns:detail:shipments:investigation-request:type-placeholder')"
            >
              <SelectOption
                v-for="investigationRequestType in App.Enums.ShipmentInvestigationRequestType"
                :key="investigationRequestType"
                :value="investigationRequestType"
              >
                {{ $t(`panel.returns:detail:shipments:investigation-request:type:${investigationRequestType}`) }}
              </SelectOption>
            </Select>

            <label class="flex items-start space-x-2 cursor-pointer">
              <Checkbox
                v-model="investigationForm.acknowledge"
                label-hidden
                :label="$t('panel.returns:detail:shipments:investigation-request:acknowledge')"
              />
              <span>{{ $t('panel.returns:detail:shipments:investigation-request:acknowledge') }}</span>
            </label>

            <div class="space-x-2">
              <Button
                variant="secondary"
                @click="cancelInvestigationRequest"
              >
                {{ $t('panel.returns:detail:shipments:investigation-request:cancel-button') }}
              </Button>
              <Button
                type="submit"
                :disabled="!(investigationForm.acknowledge && investigationForm.request_type !== null)"
              >
                {{ $t('panel.returns:detail:shipments:investigation-request:submit-button') }}
              </Button>
            </div>
          </form>
        </div>
      </DialogSection>
      <DialogFooter v-if="!isRequestingInvestigation && currentShipment.activeInvestigationRequestDate === null">
        <Button
          v-if="currentShipment.canStartInvestigationRequest"
          variant="secondary"
          @click="isRequestingInvestigation = true"
        >
          {{ $t('panel.returns:detail:shipments:investigation-request:start-button') }}
        </Button>
        <Button
          v-if="!currentShipment.isDelivered"
          variant="primary"
          @click="setAsDelivered"
        >
          {{ $t('panel.returns:detail:shipments:modal:mark-delivered') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
