<script setup lang="ts">
import * as Types from '@app/panel/types/generated';
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  List,
  ListItem,
  Modal,
  TextContainer, TextInput, TextStyle, TextStyleFontSize, TextStyleShade,
} from '@app/panel/Components';
import { ArrowRightCircleIcon, BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import { onMounted } from 'vue';
import { router, useForm } from '@inertiajs/vue3';
import { MarkdownService } from '@app/shared/Services';
type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;
type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps<{
  integration: IntegrationViewModel;
  loading: boolean;
  open: boolean;
  tenant: TenantViewModel;
}>();

const emit = defineEmits<{
  verify: [];
}>();

const {
  currentStep,
  firstStep,
  lastStep,
  nextStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

const START_APP_REGISTRATION_STEP = 2;

onMounted(() => {
  setFirstStep(1);
  setLastStep(3);
});

const startAppRegistrationForm = useForm<{
  title: string;
  description: string;
  base_url: string;
}>({
  title: '',
  description: '',
  base_url: '',
});

function startAppRegistration(): void {
  startAppRegistrationForm.post(route('panel.settings.integrations.jtl-wawi.start-app-registration'), {
    onSuccess: () => nextStep(),
    onError: () => previousStep(),
    preserveState: true,
  });
}

function verify(): void {
  router.post(route('panel.integration.jtl-wawi.save-credentials'), {}, {
    onSuccess: () => startAppRegistrationForm.reset() && emit('verify'),
  });
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="size-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="mb-2 flex flex-col items-center space-y-4 text-center">
      <div class="flex h-28 w-40 items-center justify-center">
        <img
          class="h-full w-40 object-contain"
          :src="integration.logo.imageSourceUrl"
          :alt="integration.name"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.integrations:jtl-wawi:title', {name: integration.logo.name}) }}
      </Heading>
    </div>

    <div
      v-if="currentStep === firstStep"
      class="flex flex-col gap-4"
    >
      <TextContainer text-center>
        <TextStyle :shade="TextStyleShade.Subdued">
          {{ $t('panel.integrations:jtl-wawi:connect:step1:description') }}
        </TextStyle>
      </TextContainer>

      <TextStyle
        bold
        :font-size="TextStyleFontSize.Base"
      >
        {{ $t('panel.integrations:modal:global:step') }} 1
      </TextStyle>

      <FormLayout>
        <TextInput
          v-model="startAppRegistrationForm.title"
          autofocus
          :label="$t('panel.integrations:jtl-wawi:connect:step1:title:label')"
          :max-length="255"
          required
          :error="startAppRegistrationForm.errors.title"
        />
        <TextInput
          v-model="startAppRegistrationForm.description"
          :label="$t('panel.integrations:jtl-wawi:connect:step1:description:label')"
          :max-length="255"
          required
          :error="startAppRegistrationForm.errors.description"
        />
        <TextInput
          v-model="startAppRegistrationForm.base_url"
          :label="$t('panel.integrations:jtl-wawi:connect:step1:base-url:label')"
          :placeholder="$t('panel.integrations:jtl-wawi:connect:step1:base-url:placeholder')"
          :error="startAppRegistrationForm.errors.base_url"
        />
      </FormLayout>
    </div>

    <div
      v-if="currentStep === 2"
      class="flex flex-col gap-4"
    >
      <TextContainer text-center>
        <TextStyle :shade="TextStyleShade.Subdued">
          {{ $t('panel.integrations:jtl-wawi:connect:step2:description') }}
        </TextStyle>
      </TextContainer>

      <TextStyle
        bold
        :font-size="TextStyleFontSize.Base"
      >
        {{ $t('panel.integrations:modal:global:step') }} 2
      </TextStyle>

      <TextStyle>
        <List marker-subdued>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step2:item1'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step2:item2'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step2:item3'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step2:item4'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step2:item5'))" /></ListItem>
        </List>
      </TextStyle>
    </div>

    <div
      v-if="currentStep === lastStep"
      class="flex flex-col gap-4"
    >
      <TextContainer text-center>
        <TextStyle :shade="TextStyleShade.Subdued">
          {{ $t('panel.integrations:jtl-wawi:connect:step3:description') }}
        </TextStyle>
      </TextContainer>

      <TextStyle
        bold
        :font-size="TextStyleFontSize.Base"
      >
        {{ $t('panel.integrations:modal:global:step') }} 3
      </TextStyle>

      <TextStyle>
        <List marker-subdued>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step3:item1'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step3:item2'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step3:item3'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step3:item4'))" /></ListItem>
          <ListItem><span v-html="MarkdownService.parse($t('panel.integrations:jtl-wawi:connect:step3:item5'))" /></ListItem>
        </List>
      </TextStyle>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="size-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.integrations:modal:global:integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === START_APP_REGISTRATION_STEP"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="startAppRegistration"
        >
          <template #icon>
            <ArrowRightCircleIcon class="size-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:modal:global:next_step') }}</span>
        </Button>

        <Button
          v-else-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="size-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:modal:global:verify_integration_button') }}</span>
        </Button>

        <Button
          v-else
          full-width
          :variant="ButtonVariant.Primary"
          @click="nextStep"
        >
          <template #icon>
            <ArrowRightCircleIcon class="size-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:modal:global:next_step') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
