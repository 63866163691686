<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  ButtonVariant,
  Stack,
  StackItem,
  StackSpacing,
} from '@app/panel/Components';
import { Modal } from '@app/panel/Components/Modal';
import { PaperClipIcon } from '@heroicons/vue/24/outline';
import { useFileManager } from '@app/panel/Composables/useFileManager';
import { UploadedFile } from '../../UploadedFile';
import { computed, inject, ref, watch } from 'vue';
import { type NoteForm } from '../types';
import { InertiaForm } from '@inertiajs/vue3';
import { useUpload } from '@app/panel/Composables/useUpload';
import { UploadedFileInterface } from '@app/panel/Composables/useFileManager/types';
import { isEmpty } from 'lodash';
import { FileUpload } from '../../FileUpload';

const props = defineProps<{ form: InertiaForm<NoteForm> }>();

const MAX_FILES_TO_UPLOAD = 5;

const isOpen = ref(false);

function openModal(): void {
  isOpen.value = true;
}

function closeModal(): void {
  isOpen.value = false;
}

const emit = defineEmits<{ (e: 'attach', files: object) }>();

const attachmentFileSizeLimit = inject<number>('attachmentFileSizeLimit');
const attachmentFileTypes = inject<string[]>('attachmentFileTypes');

const {
  addFiles,
  clearFiles,
  error,
  removeFile,
  setError,
  uploadedFiles,
} = useFileManager(MAX_FILES_TO_UPLOAD, attachmentFileSizeLimit, attachmentFileTypes);

const hasUploadedFiles = computed<boolean>(() => {
  return uploadedFiles.value.length > 0;
});

const hasError = computed<boolean>(() => !!error.value);

async function uploadFileToVapor(file: UploadedFileInterface) {
  const { upload } = useUpload();

  return await upload(file.file, (progress) => file.progress = progress)
    .then((response) => ({ filename: file.name, previewUrl: file.url, ...response }))
    .catch((error) => setError(error));
}

async function uploadFilesToVapor() {
  const promises = [];

  uploadedFiles.value.forEach((file) => {
    promises.push(() => uploadFileToVapor(file));
  });

  await Promise.all(promises.map((f) => f()))
    .then((response) => {
      emit('attach', response);

      closeModal();
    });
}

watch(() => props.form.attachments, (newValue) => {
  if (isEmpty(newValue.length)) {
    clearFiles();
  }
});
</script>

<template>
  <div>
    <PaperClipIcon
      class="h-5 w-5 text-slate-400 hover:text-slate-500 cursor-pointer"
      @click="openModal"
    />
  </div>

  <Modal
    title="Upload and attach files"
    :open="isOpen"
    @close="closeModal"
  >
    <Stack
      vertical
      :spacing="StackSpacing.Loose"
    >
      <StackItem>
        <Banner
          :title="error"
          :open="hasError"
          :variant="BannerVariant.Critical"
        />
      </StackItem>

      <StackItem>
        <FileUpload
          :accept="attachmentFileTypes"
          :error="!! error"
          multiple
          @upload="addFiles"
        />
      </StackItem>

      <StackItem v-if="hasUploadedFiles">
        <div class="space-y-2">
          <UploadedFile
            v-for="file in uploadedFiles"
            :key="file.id"
            v-bind="file"
            @remove="() => removeFile(file.id)"
          />
        </div>
      </StackItem>
    </Stack>

    <template #actions>
      <Button
        full-width
        @click="closeModal"
      >
        {{ $t('panel.containers:notes-section:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        full-width
        @click="uploadFilesToVapor"
      >
        {{ $t('panel.containers:notes-section:attach-files') }}
      </Button>
    </template>
  </Modal>
</template>
