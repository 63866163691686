<script lang="ts" setup>
import { Banner, BannerVariant, Button, Link, List, ListItem, Select, SelectOption } from '@app/panel/Components';
import { TrashIcon } from '@heroicons/vue/24/outline';
import { PlusIcon } from '@heroicons/vue/24/solid';
import * as Types from '@app/panel/types/generated';
import { App } from '@app/panel/types/generated';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { isNil } from 'lodash';

type FormAutomationActionViewModel = App.Models.ViewModels.FormAutomationActionViewModel;
type FormAutomationPlatformIntegrationOptionViewModel = App.Models.ViewModels.ActionOptions.FormAutomationPlatformIntegrationOptionViewModel;

type ActionOption = {
  label: string;
  value: string;
  type: App.Enums.ActionOptionType;
  hasIntegration: boolean;
  options: null | { label: string; value: string }[];
};

const props = withDefaults(defineProps<{
  actionOptions: ActionOption[];
  errors: Record<string, string>;
  disabled?: boolean;
  form: Types.App.Models.ViewModels.FormViewModel;
  formAutomationPlatformIntegrationOptions: FormAutomationPlatformIntegrationOptionViewModel[];
}>(), {
  disabled: false,
});

const actions = defineModel<Record<string, FormAutomationActionViewModel>>('modelValue', { required: true });

function addActionRow() {
  actions.value[useUniqueId()] = {
    type: null,
    value: null,
    platform_integration_tenant_id: null,
    hasFailed: false,
  };
}

function getAvailableOptions(action: FormAutomationActionViewModel) {
  return props.actionOptions.filter((actionOption) => {
    // if the action is already selected then it was a valid action so it should be available
    if (action.type === actionOption.value) {
      return true;
    }

    // if action should not have to be filtered if the type is 'unique-value'
    if (actionOption.type === 'unique-value') {
      return true;
    }

    if (actionOption.hasIntegration) {
      const supportedIntegrations = props.formAutomationPlatformIntegrationOptions
        .filter((formAutomationPlatformIntegrationOption) => formAutomationPlatformIntegrationOption.actions.includes(actionOption.value))
        .length;

      if (supportedIntegrations === 0) {
        return false;
      }
    }

    // if the action is not selected then it should be available if it is not selected in any other action
    return Object.values(actions.value).every((action) => action.type !== actionOption.value);
  });
}

function hasActionOptionAnIntegration(action: undefined | FormAutomationActionViewModel): boolean {
  const actionOption = props.actionOptions.find((actionOption) => actionOption.value === action?.type);
  return actionOption?.hasIntegration ?? false;
}

function actionOptionIntegrations(action: undefined | FormAutomationActionViewModel): Array<{
  label: string;
  value: string
}> {
  if (!action) {
    return [];
  }

  return props.formAutomationPlatformIntegrationOptions
    .filter((formAutomationPlatformIntegrationOption) => {
      const doesSupportAction = formAutomationPlatformIntegrationOption.actions.includes(action.type);
      const wasAlreadySelected = formAutomationPlatformIntegrationOption.uuid === action.platform_integration_tenant_id;

      return doesSupportAction || wasAlreadySelected;
    })
    .map((formAutomationPlatformIntegrationOption) => ({
      label: formAutomationPlatformIntegrationOption.label,
      value: formAutomationPlatformIntegrationOption.uuid,
    }));
}

function getPlatformIntegrationDoesNotSupportActionError(action: FormAutomationActionViewModel): null | string {
  const showError = props.formAutomationPlatformIntegrationOptions
    .some((formAutomationPlatformIntegrationOption) => {
      const doesSupportAction = formAutomationPlatformIntegrationOption.actions.includes(action.type);
      const wasAlreadySelected = formAutomationPlatformIntegrationOption.uuid === action.platform_integration_tenant_id;

      return !doesSupportAction && wasAlreadySelected;
    });

  return showError
    ? 'Platform does not support this action'
    : null;
}

function getOptionsOfAction(action: undefined | FormAutomationActionViewModel): null | SelectOption[] {
  const actionOption = props.actionOptions.find((actionOption) => actionOption.value === action?.type);

  if (isNil(action) || isNil(actionOption?.options)) {
    return null;
  }

  const options = actionOption?.options ?? [];

  if (actionOption?.type !== 'unique-value') {
    return options;
  }

  const selectedOptionOfTheSameAction = Object.values(actions.value)
    .filter((action) => action.type === actionOption?.value)
    .flatMap((a) => a.value);

  return options.filter((option) => {
    if (option.value === action.value) {
      return true;
    }

    return !selectedOptionOfTheSameAction.includes(option.value);
  });
}

function removeActionByKey(key: string) {
  delete actions.value[key];
}

function onTypeChanged(action: FormAutomationActionViewModel) {
  action.value = null;
  action.platform_integration_tenant_id = null;
}

</script>

<template>
  <div class="flex flex-col items-start space-y-4">
    <Banner
      v-if="errors['actions'] ?? null"
      :variant="BannerVariant.Critical"
      class="w-full"
    >
      <List>
        <ListItem>{{ errors['actions'] }}</ListItem>
      </List>
    </Banner>

    <div
      v-for="[key, action] in Object.entries(actions)"
      :key="key"
      class="flex w-full flex-col space-y-2"
    >
      <Banner
        v-if="action.hasFailed"
        :variant="BannerVariant.Warning"
      >
        <i18n-t
          keypath="panel.settings:form:automations:actions:has-failed"
          for="panel.settings:form:automations:actions:has-failed"
        >
          <template #link>
            <Link
              native
              underline
              :href="$route('panel.settings.forms.failed-automations', {
                form: form.id,
              })"
            >
              {{ $t('panel.settings:form:automations:view-failed') }}
            </Link>
          </template>
        </i18n-t>
      </Banner>
      <div class="flex w-full items-start space-x-3">
        <div class="flex-1">
          <Select
            v-model="action.type"
            :options="getAvailableOptions(action)"
            :error="errors[`actions.${key}.type`]"
            @change="onTypeChanged(action)"
          />
        </div>

        <div
          v-if="hasActionOptionAnIntegration(action)"
          class="flex-1"
        >
          <Select
            v-model="action.platform_integration_tenant_id"
            :options="actionOptionIntegrations(action)"
            :error="getPlatformIntegrationDoesNotSupportActionError(action) ?? errors[`actions.${key}.platform_integration_tenant_id`]"
          />
        </div>

        <div
          v-if="getOptionsOfAction(action) !== null"
          class="flex-1"
        >
          <Select
            v-model="action.value"
            :options="getOptionsOfAction(action)!"
            :error="errors[`actions.${key}.value`]"
          />
        </div>

        <div
          class="mt-2 shrink-0"
        >
          <TrashIcon
            class="z-10 size-6 cursor-pointer text-slate-500 hover:text-slate-600"
            @click="removeActionByKey(key)"
          />
        </div>
      </div>
    </div>
    <Button
      :disabled="disabled"
      @click="addActionRow"
    >
      <template #icon>
        <PlusIcon />
      </template>
      <span>{{ $t('panel.settings:form:automations:actions:add') }}</span>
    </Button>
  </div>
</template>
