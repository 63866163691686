<script lang="ts" setup>
import { ref, watchEffect } from 'vue';
import {
  Button,
  ButtonIconPosition,
  Checkbox,
  Popover,
} from '@app/panel/Components';
import { FunnelIcon, ViewColumnsIcon } from '@heroicons/vue/24/outline';
import DateRange from '@app/panel/Pages/Analytics/components/DateRange.vue';
import { type Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  activeHeadings: string[];
  headings: { text: string; value: string }[];
  filter: any;
  dateFilter: Types['App.Models.ViewModels.Analytics.DateAnalyticsFilterViewModel'];
  toggleFilters: (() => void);
  filterTabs: any;
}>();

const emit = defineEmits([
  'update:active-headings',
  'updated-date-range',
]);

const columnsButton = ref<HTMLElement>(null);

const columnsPopoverOpen = ref(false);

const checkedColumns = ref(props.activeHeadings);

function toggleColumnsPopover() {
  columnsPopoverOpen.value = !columnsPopoverOpen.value;
}

function onCheckboxChange() {
  emit('update:active-headings', checkedColumns.value);
}

watchEffect(() => checkedColumns.value = props.activeHeadings);

</script>

<template>
  <div class="flex items-end justify-between">
    <DateRange
      :init-from="dateFilter.from"
      :init-to="dateFilter.to"
      @updated="(from, to) => $emit('updated-date-range', from, to)"
    />

    <div class="flex space-x-3">
      <div class="relative">
        <div class="flex items-stretch h-full">
          <div ref="columnsButton">
            <Button
              @click="toggleColumnsPopover"
              @keyup.esc="toggleColumnsPopover"
            >
              <template #icon>
                <ViewColumnsIcon class="h-5 w-5" />
              </template>

              {{ $t('panel.analytics:products:table:actions:columns') }}
            </Button>
          </div>

          <Popover
            :active="columnsPopoverOpen"
            :parent="columnsButton"
            @close="toggleColumnsPopover"
          >
            <div
              v-for="(heading, index) in headings"
              :key="index"
            >
              <Checkbox
                v-model="checkedColumns"
                class="p-2 hover:bg-gray-50 whitespace-nowrap"
                full-width
                :label="heading.text"
                :name="heading.text"
                :value="heading.value"
                @change="onCheckboxChange"
              />
            </div>
          </Popover>
        </div>
      </div>

      <Button
        :icon-position="ButtonIconPosition.Left"
        @click="toggleFilters"
        @keyup.esc="toggleFilters"
      >
        <template #icon>
          <FunnelIcon class="h-5 w-5" />
        </template>

        {{ $t('panel.analytics:products:table:actions:filters') }}
      </Button>
    </div>
  </div>
</template>
