<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  ButtonVariant,
  Card,
  CardSection,
  List,
  ListItem,
  Modal,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { App, type Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { differenceWith, intersectionBy } from 'lodash';
import { CheckableItem, CheckableList } from '../../components';
import { useOnboarding } from '../../composables';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { computed } from 'vue';

type OnboardinDashboardViewModel = Types['App.Http.ViewModels.Panel.OnboardingDashboardViewModel'];

const stateType: App.Enums.OnboardingStateType = App.Enums.OnboardingStateType.HasReturnQuestions;

const props = defineProps<{
  returnForm: OnboardinDashboardViewModel['returnForm'];
  storedReturnQuestions: OnboardinDashboardViewModel['storedReturnQuestions'];
  predefinedReturnQuestions: OnboardinDashboardViewModel['predefinedReturnQuestions'];
  usedReturnQuestions: OnboardinDashboardViewModel['usedReturnQuestions'];
}>();

function getSelectedQuestions(): string[] {
  return intersectionBy(props.predefinedReturnQuestions, props.storedReturnQuestions, 'question')
    .map((predefinedReturnQuestion) => predefinedReturnQuestion.question);
}

const form = useForm({
  questions: getSelectedQuestions(),
});

const { changeStep, setLastCompletedStep } = useOnboarding();

const deselectedQuestions = computed(() => {
  return differenceWith(
    Object.values(props.predefinedReturnQuestions),
    form.questions,
    (predefined, formQuestion) => predefined.question === formQuestion,
  );
});

const deletingUsedQuestions = computed(() => {
  return intersectionBy(deselectedQuestions.value, Object.values(props.usedReturnQuestions), 'question');
});

function save(): void {
  if (deletingUsedQuestions.value.length > 0) {
    isRemovalModalOpen.value = true;
    return;
  }

  form.post(route('panel.onboarding.return-questions', { form: props.returnForm }), {
    preserveScroll: true,
    onSuccess: () => setLastCompletedStep(stateType),
    onError: () => changeStep(stateType),
  });
}

const isRemovalModalOpen = ref<boolean>(false);

function closeRemovalModal(): void {
  isRemovalModalOpen.value = false;
}
</script>

<template>
  <div>
    <Card
      :primary-footer-action="{
        content: 'Save & continue',
        callback: save,
      }"
    >
      <CardSection>
        <div
          v-if="form.errors.questions"
          class="mb-5"
        >
          <Banner :variant="BannerVariant.Critical">
            {{ form.errors.questions }}
          </Banner>
        </div>
        <CheckableList>
          <template
            v-for="predefinedReturnQuestion in predefinedReturnQuestions"
            :key="predefinedReturnQuestion.question"
          >
            <CheckableItem
              v-if="predefinedReturnQuestion.isShown"
              :model-value="form.questions"
              :value="predefinedReturnQuestion.question"
              :title="predefinedReturnQuestion.question"
              :description="predefinedReturnQuestion.description"
              :is-popular="predefinedReturnQuestion.isPopular"
              @update:model-value="(value) => form.questions = value"
            />
          </template>
        </CheckableList>
      </CardSection>
    </Card>

    <Modal
      :open="isRemovalModalOpen"
      :title="$t('panel.dashboard:onboarding:return-questions:cannot-remove-used:title')"
      icon-color="red"
      @close="closeRemovalModal"
    >
      <template #icon>
        <ExclamationTriangleIcon class="h-6 w-6" />
      </template>

      <TextContainer :spacing="TextContainerSpacing.Loose">
        <TextStyle :shade="TextStyleShade.Subdued">
          <p>
            {{ $t('panel.dashboard:onboarding:return-questions:cannot-remove-used:description') }}
          </p>
        </TextStyle>

        <TextStyle :shade="TextStyleShade.Subdued">
          <List>
            <ListItem
              v-for="usedReturnQuestion in deletingUsedQuestions"
              :key="usedReturnQuestion.id"
            >
              <TextStyle>
                {{ usedReturnQuestion.question }}
              </TextStyle>
            </ListItem>
          </List>
        </TextStyle>
      </TextContainer>

      <template #actions>
        <Button
          :variant="ButtonVariant.Default"
          @click="closeRemovalModal"
        >
          {{ $t('panel.dashboard:onboarding:return-questions:close') }}
        </Button>
      </template>
    </Modal>
  </div>
</template>
