<script lang="ts" setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardSection,
  CardTitle,
  Form,
  FormLayout,
  Page,
  TextField,
  Toggle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { Types } from '@app/shared/types/generated-v2';
import { PageNavigation } from './partials';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

const props = defineProps<Types['App.Http.ViewModels.Panel.Settings.GeneralSettings.CustomSmtpViewModel']>();

const form = useForm({
  host: props.host,
  port: props.port,
  username: props.username,
  password: props.password,
  is_custom_smtp_enabled: props.isCustomSmtpEnabled,
});

function save(): void {
  form.put(route('panel.settings.general.custom-smtp.update'), { preserveScroll: true });
}

const testingConnection = ref<boolean>(false);

function testConnection() {
  form.post(route('panel.settings.general.custom-smtp.test'), {
    preserveScroll: true,
    onStart: () => testingConnection.value = true,
    onFinish: () => testingConnection.value = false,
  });
}
</script>

<template>
  <AppLayout :title="t('panel.settings:general:custom-smtp')">
    <Page :navigation="PageNavigation">
      <Form @submit="save">
        <Card>
          <CardHeader>
            <CardTitle :card-help="getHelpscoutArticleBeacon('general-settings.custom-smtp.custom-smtp-settings')">
              {{ t('panel.settings:general:custom-smtp:settings') }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <div v-if="connectionEstablished !== null">
                <Alert
                  v-if="connectionEstablished"
                  variant="warning"
                >
                  {{ t('panel.settings:general:custom-smtp:connection-established') }}
                </Alert>
                <Alert
                  v-if="!connectionEstablished"
                  variant="warning"
                >
                  {{ t('panel.settings:general:custom-smtp:connection-failed') }}
                </Alert>
              </div>
              <Toggle
                v-model="form.is_custom_smtp_enabled"
                :label="t('panel.settings:general:custom-smtp:enable-custom-smtp')"
                name="enable_custom_smtp"
              />
              <TextField
                v-model="form.host"
                :disabled="!form.is_custom_smtp_enabled"
                :error="form.errors.host"
                :label="t('panel.settings:general:custom-smtp:host')"
                name="host"
              />
              <TextField
                v-model="form.port"
                :disabled="!form.is_custom_smtp_enabled"
                :error="form.errors.port"
                :label="t('panel.settings:general:custom-smtp:port')"
                name="port"
              />
              <TextField
                v-model="form.username"
                :disabled="!form.is_custom_smtp_enabled"
                :error="form.errors.username"
                :label="t('panel.settings:general:custom-smtp:username')"
                name="username"
              />
              <TextField
                v-model="form.password"
                :disabled="!form.is_custom_smtp_enabled"
                :error="form.errors.password"
                :label="t('panel.settings:general:custom-smtp:password')"
                name="password"
                type="password"
              />
            </FormLayout>
          </CardSection>
          <CardFooter>
            <ButtonGroup>
              <Button
                :loading="form.processing && testingConnection"
                variant="secondary"
                @click="testConnection"
              >
                {{ t('panel.settings:general:custom-smtp:test-settings') }}
              </Button>
              <Button
                :loading="form.processing && !testingConnection"
                type="submit"
              >
                {{ t('panel.settings:general:custom-smtp:save') }}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>
      </Form>
    </Page>
  </AppLayout>
</template>
