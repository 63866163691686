<script lang="ts" setup>
import { computed, PropType, ref } from 'vue';
import {
  AttachmentItem,
  AttachmentList,
  Badge,
  Breadcrumbs,
  BreadcrumbsItem,
  Heading,
  HeadingElement,
  Link,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/panel/Components';
import * as Types from '@app/panel/types/generated';
import { InertiaForm } from '@inertiajs/vue3';
import { NoteSection } from '@app/panel/Containers';
import { isEmpty } from 'lodash';

const props = defineProps({
  item: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnOrderItemViewModel>,
    required: true,
  },

  order: {
    type: Object as PropType<Types.App.Models.ViewModels.ReturnOrderViewModel>,
    required: true,
  },

  noteTemplates: {
    type: Object as PropType<Types.App.Models.ViewModels.NoteTemplateViewModel>,
    required: true,
  },
});

const notesVisible = ref(false);
const executingAction = ref(false);

function createNote(form: InertiaForm<object>) {
  form.post(route('panel.return-items.create-note', props.item), {
    preserveState: true,
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
      executingAction.value = false;
    },
  });
}

const hasDiscount = computed(() => {
  return props.item.salesOrderItem.discount.amount > 0;
});
</script>

<template>
  <div class="flex flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-6 lg:space-y-0">
    <div class="shrink-0 basis-48">
      <img
        v-if="item.product.imageSource"
        v-fallback-img
        class="mx-auto h-auto w-48 max-w-full object-contain"
        :src="item.product.imageSource"
        :alt="item.product.name"
        loading="lazy"
      >
    </div>

    <div class="flex-1">
      <div class="flex flex-col items-start space-y-2 lg:flex-row lg:justify-between lg:space-y-0">
        <div class="flex w-full flex-col space-y-1">
          <div class="flex items-start justify-between space-x-2">
            <div class="space-y-2">
              <Heading :element="HeadingElement.H3">
                <a
                  class="hover:underline"
                  :href="$route('panel.analytics.products.show', { product: item.product.id })"
                >
                  {{ item.salesOrderItem.productName ? item.salesOrderItem.productName : item.product.name }}
                </a>
              </Heading>
              <div
                v-if="! isEmpty(item.salesOrderItem.extensionAttributes)"
                class="flex flex-wrap items-center gap-2 text-sm text-slate-500"
              >
                <template
                  v-for="(attribute, index) in item.salesOrderItem.extensionAttributes"
                  :key="index"
                >
                  <Badge color="slate">
                    <span>{{ attribute.option }}: </span>
                    <span>{{ attribute.value }}</span>
                  </Badge>
                </template>
              </div>
            </div>
            <div class="flex flex-col items-end text-right">
              <TextStyle
                :font-size="TextStyleFontSize.Small"
                :shade="TextStyleShade.Pale"
                no-wrap
              >
                {{ $t('panel.containers:return-item:return-qty') }}
              </TextStyle>
              <span class="text-2xl font-bold tracking-normal text-slate-700">
                {{ item.quantity }} / {{ item.salesOrderItem.quantity }}
              </span>
            </div>
          </div>

          <div class="flex justify-between space-x-4">
            <div class="w-full flex-1">
              <Breadcrumbs>
                <Breadcrumbs>
                  <BreadcrumbsItem
                    v-if="item.product.sku"
                    :name="$t('panel.containers:return-item:sku')"
                    :value="item.product.sku"
                  />
                  <BreadcrumbsItem
                    v-if="item.product.barcode"
                    :name="$t('panel.containers:return-item:barcode')"
                    :value="item.product.barcode"
                  />
                  <BreadcrumbsItem
                    v-if="item.product.brand"
                    :name="$t('panel.containers:return-item:brand')"
                    :value="item.product.brand"
                  />
                </Breadcrumbs>
                <Breadcrumbs>
                  <BreadcrumbsItem>
                    <span class="space-x-1">
                      <span class="font-semibold">{{ $t('panel.containers:return-item:price') }}:</span>
                      <span>{{ item.salesOrderItem.price.label }}</span>
                      <Badge
                        v-if="hasDiscount"
                        color="green"
                      >
                        {{ $t('panel.containers:return_order:discount', { discount: item.salesOrderItem.discount.label }) }}
                      </Badge>
                    </span>
                  </BreadcrumbsItem>
                </Breadcrumbs>
              </Breadcrumbs>
            </div>
            <div v-if="item.returnType">
              <TextStyle :shade="TextStyleShade.Subdued">
                {{ item.returnType }}
              </TextStyle>
            </div>
          </div>
        </div>
      </div>

      <div class="my-3 text-sm">
        <div class="flex flex-col py-1">
          <TextStyle
            :shade="TextStyleShade.Dense"
            strong
          >
            {{ $t('panel.containers.return_order.return_reason') }}
          </TextStyle>
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ item.returnReason.label }}
          </TextStyle>
        </div>

        <template v-if="item.answers.length">
          <div
            v-for="answer in item.answers"
            :key="answer.id"
            class="py-1"
          >
            <TextStyle
              strong
              :shade="TextStyleShade.Dense"
              full-width
            >
              {{ answer.question }}
            </TextStyle>

            <p class="text-slate-500">
              <Link
                v-if="answer.answer && answer.answerIsUrl"
                :href="answer.answer"
                target="_blank"
                native
                class="block max-w-lg overflow-hidden truncate"
              >
                {{ answer.answer }}
              </Link>
              <span v-if="answer.answer && ! answer.answerIsUrl">{{ answer.answer }}</span>
              <span v-if="answer.option">{{ answer.option.answer }}</span>

              <AttachmentList v-if="! isEmpty(answer.attachments)">
                <AttachmentItem
                  v-for="attachment in answer.attachments"
                  :key="attachment.id"
                >
                  {{ attachment.filename }}

                  <template #actions>
                    <Link
                      native
                      :href="attachment.path"
                      target="_blank"
                    >
                      {{ $t('panel.containers.return_request.view') }}
                    </Link>
                  </template>
                </AttachmentItem>
              </AttachmentList>
            </p>
          </div>
        </template>
      </div>

      <div class="mt-4 space-y-4">
        <div class="flex items-center">
          <label class="flex cursor-pointer items-center">
            <Toggle v-model="notesVisible" />

            <TextStyle :shade="TextStyleShade.Dense">
              {{ $t('panel.containers.return_request.show_notes') }}
              <TextStyle :shade="TextStyleShade.Subdued">
                ({{ item.notes?.length ?? 0 }})
              </TextStyle>
            </TextStyle>
          </label>
        </div>

        <div v-if="notesVisible">
          <NoteSection
            :return-order="order"
            :note-templates="noteTemplates"
            :notes="item.notes"
            @submit="createNote"
          />
        </div>
      </div>
    </div>
  </div>
</template>
