<script lang="ts" setup>
import { computed, ref } from 'vue';
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  AlertDialog,
  AlertDialogActionButton,
  AlertDialogCancelButton,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Button,
  ButtonIcon,
  Card,
  CardDescription,
  CardHeader,
  CardSection,
  CardTitle,
  DataList,
  DataListContent,
  DataListItem,
  FormLayout,
  Select,
  SelectOption,
  StatusIndicator,
} from '@returnless/focus-ui';
import { router } from '@inertiajs/vue3';
import { EllipsisHorizontalIcon } from '@heroicons/vue/16/solid';
import { GeneralSettingsPageViewModel, LocaleViewModel } from '@app/panel/Pages/Settings/GeneralSettings/General/types';
import { useI18n } from 'vue-i18n';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

defineProps<{
  addableLocaleOptions: GeneralSettingsPageViewModel['addableLocaleOptions'];
  selectedLocales: GeneralSettingsPageViewModel['selectedLocales'];
}>();

const currentRemovalLocale = ref<LocaleViewModel | null>(null);

function openRemovalModal(locale: LocaleViewModel): void {
  currentRemovalLocale.value = locale;
}

function closeRemovalModal(): void {
  currentRemovalLocale.value = null;
}

const isLocaleRemoveModalOpen = computed<boolean>(() => currentRemovalLocale.value !== null);

function detachLocale(): void {
  if (currentRemovalLocale.value === null) {
    return;
  }

  router.delete(route('panel.settings.general.remove-locale', {
    id: currentRemovalLocale.value.id,
  }), {
    preserveScroll: true,
    onSuccess: closeRemovalModal,
  });
}

function attachLocale(localeId: string | null) {
  if (localeId === null) {
    return;
  }

  router.post(route('panel.settings.general.add-locale'), {
    id: localeId,
  }, {
    preserveScroll: true,
  });
}

function setDefaultLocale(locale: LocaleViewModel): void {
  router.put(route('panel.settings.general.set-default-locale'), { locale_id: locale.id }, {
    preserveScroll: true,
    preserveState: false,
  });
}
</script>

<template>
  <Card>
    <CardHeader>
      <CardTitle :card-help="getHelpscoutArticleBeacon('general-settings.general-settings.languages')">
        {{ t('panel.settings.general_settings.general_settings.languages.card_label') }}
      </CardTitle>
      <CardDescription>{{ t('panel.settings.general_settings.general_settings.languages.card_description') }}</CardDescription>
    </CardHeader>
    <CardSection>
      <FormLayout>
        <Select
          :model-value="null"
          :label="t('panel.settings.general_settings.general_settings.languages.select_label')"
          :placeholder="t('panel.settings.general_settings.general_settings.languages.placeholder')"
          name="language_id"
          @update:model-value="attachLocale"
        >
          <SelectOption
            v-for="localeOption in addableLocaleOptions"
            :key="localeOption.value"
            :value="localeOption.value"
          >
            {{ localeOption.label }}
          </SelectOption>
        </Select>

        <DataList>
          <DataListItem
            v-for="locale in selectedLocales"
            :key="locale.id"
          >
            <DataListContent>{{ locale.name }}</DataListContent>
            <StatusIndicator
              v-if="locale.isDefault"
              color="blue"
              size="small"
            >
              {{ t('panel.settings.general_settings.general_settings.languages.data_table.default') }}
            </StatusIndicator>
            <ActionList alignment="end">
              <ActionListTrigger>
                <Button
                  icon-only
                  variant="ghost"
                  size="small"
                >
                  <ButtonIcon :icon="EllipsisHorizontalIcon" />
                </Button>
              </ActionListTrigger>
              <ActionListBody>
                <ActionListSection>
                  <ActionListItem
                    v-if="! locale.isDefault"
                    @click="setDefaultLocale(locale)"
                  >
                    {{ t('panel.settings.general_settings.general_settings.languages.data_table.set_default') }}
                  </ActionListItem>
                  <ActionListItem
                    variant="destructive"
                    @click="openRemovalModal(locale)"
                  >
                    {{ t('panel.settings.general_settings.general_settings.languages.data_table.remove_button') }}
                  </ActionListItem>
                </ActionListSection>
              </ActionListBody>
            </ActionList>
          </DataListItem>
        </DataList>
      </FormLayout>
    </CardSection>
  </Card>

  <AlertDialog
    :open="isLocaleRemoveModalOpen"
    @cancel="closeRemovalModal"
  >
    <AlertDialogContent>
      <AlertDialogHeader>
        <AlertDialogTitle>{{ t('panel.settings.general_settings.general_settings.languages.removal_title') }}</AlertDialogTitle>
        <AlertDialogDescription>
          <p>{{ t('panel.settings.general_settings.general_settings.languages.removal_description') }}</p>
          <p>{{ t('panel.settings.general_settings.general_settings.languages.removal_alternative') }}</p>
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancelButton>
          {{ t('panel.global:actions:cancel') }}
        </AlertDialogCancelButton>
        <AlertDialogActionButton
          variant="destructive"
          :loading="currentRemovalLocale !== null"
          @click="detachLocale"
        >
          {{ t('panel.global:actions:delete') }}
        </AlertDialogActionButton>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>
