<script lang="ts" setup>
import { provide } from 'vue';
import { AppLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { PageNavigation } from '../partials';
import {
  Banner,
  BannerVariant,
  ButtonType,
  Card,
  CardSection,
  CardSeparator,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Page,
  TextInput,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
  Toggle,
} from '@app/panel/Components';
import { TagList, TagPopover } from '@app/panel/Containers';
import { useTags } from '@app/panel/Composables/useTags';
import type { Types } from '@app/shared/types/generated-v2';
import { ConditionEngine } from '@app/panel/Containers';
import Actions from '@app/panel/Containers/Actions/components/Actions.vue';
import ConfirmTriggerChange from '@app/panel/Pages/Settings/Forms/Automations/partials/ConfirmTriggerChange.vue';
import {
  useConfirmTriggerChange,
} from '@app/panel/Pages/Settings/Forms/Automations/composables/useConfirmTriggerChange';
import AutomationTriggerSelect
  from '@app/panel/Containers/AutomationTriggerSelect/components/AutomationTriggerSelect.vue';
import { CardLayout } from '@returnless/focus-ui';
import FeatureLimitInfo from '@app/panel/Pages/Settings/Components/FeatureLimitInfo.vue';

type CreateFormAutomationsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.CreateFormAutomationsViewModel'];

const props = defineProps<CreateFormAutomationsViewModel>();

const automationForm = useForm({
  name: '',
  trigger: null,
  parameter: null,
  conditions: {},
  is_enabled: true,
  actions: {},
  tags: [],
});

provide('form', props.form);
provide('countries', props.countries);
provide('returnForm', automationForm);

const { show, asyncConfirm, confirm, cancel } = useConfirmTriggerChange();

function submitForm(): void {
  automationForm.post(route('panel.settings.forms.automations.store', props.form));
}

function triggerUpdated(newTriggerValue: string) {
  const hasConditions = Object.keys(automationForm.conditions).length > 0;
  const hasActions = Object.keys(automationForm.actions).length > 0;

  if (automationForm.trigger !== null && (hasConditions || hasActions)) {
    asyncConfirm().then((confirmed) => {
      if (confirmed) {
        automationForm.conditions = {};
        automationForm.actions = {};
        automationForm.trigger = newTriggerValue;
      }
    });
  } else {
    automationForm.trigger = newTriggerValue;
  }
}

const { activeTags, removeTag } = useTags(props.tags, automationForm);
</script>

<template>
  <AppLayout :title="$t('panel.settings:form:automations:create:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="submitForm">
        <CardLayout>
          <FeatureLimitInfo :feature-usage-info="featureUsageInfo" />

          <Card
            :divide="false"
            :primary-footer-action="{
              content: $t('panel.settings:form:automations:create:save-cta'),
              type: ButtonType.Submit,
              loading: automationForm.processing,
            }"
          >
            <div class="px-6 pt-6">
              <div class="flex justify-between">
                <div class="flex space-x-4">
                  <Heading>
                    <span class="whitespace-nowrap">
                      {{ $t('panel.settings:form:automations:create:title') }}
                    </span>
                  </Heading>

                  <TagList
                    :tags="activeTags"
                    @remove="removeTag"
                  />
                </div>

                <TagPopover
                  v-model="automationForm.tags"
                  :tags="tags"
                />
              </div>
            </div>

            <CardSection>
              <FormLayout>
                <FormLayout :columns="2">
                  <TextInput
                    v-model="automationForm.name"
                    :label="$t('panel.settings:form:automations:create:name-label')"
                    :error="automationForm.errors.name"
                  />
                  <AutomationTriggerSelect
                    v-model:parameter="automationForm.parameter"
                    :trigger="automationForm.trigger"
                    :trigger-options="triggerOptions"
                    :errors="automationForm.errors"
                    @update:trigger="triggerUpdated"
                  />
                </FormLayout>

                <Toggle
                  v-model="automationForm.is_enabled"
                  :title="$t('panel.settings:form:automations:create:enable-label')"
                  :error="automationForm.errors.is_enabled"
                />
              </FormLayout>
            </CardSection>

            <CardSeparator />

            <CardSection>
              <div class="mb-4">
                <Heading :element="HeadingElement.H3">
                  <TextStyle
                    strong
                    color="slate"
                    :font-size="TextStyleFontSize.Base"
                    :shade="TextStyleShade.Dense"
                  >
                    {{ $t('panel.settings:form:automations:create:conditions-title') }}
                  </TextStyle>
                </Heading>
              </div>

              <div class="mb-4">
                <Banner
                  :open="!!automationForm.errors.conditions"
                  :title="automationForm.errors.conditions"
                  :variant="BannerVariant.Critical"
                />
              </div>

              <ConditionEngine
                :available-condition-types="availableConditionTypes[automationForm.trigger] ?? []"
                :condition-type-map="conditionTypeMap"
                :return-reasons="returnReasons"
                :return-statuses-options="returnStatusesOptions"
                :request-statuses-options="requestStatusesOptions"
                :return-questions="returnQuestions"
                :tags="tags"
                :disabled="automationForm.trigger === null"
              />
            </CardSection>

            <CardSeparator />

            <CardSection>
              <div class="mb-4">
                <Heading :element="HeadingElement.H3">
                  <TextStyle
                    strong
                    color="slate"
                    :font-size="TextStyleFontSize.Base"
                    :shade="TextStyleShade.Dense"
                  >
                    {{ $t('panel.settings:form:automations:create:action-trigger-title') }}
                  </TextStyle>
                </Heading>
              </div>

              <Actions
                v-model="automationForm.actions"
                :action-options="actionOptions[automationForm.trigger] ?? []"
                :errors="automationForm.errors"
                :disabled="automationForm.trigger === null"
                :form="form"
                :form-automation-platform-integration-options="formAutomationPlatformIntegrationOptions"
              />
            </CardSection>
          </Card>
        </CardLayout>
      </Form>

      <ConfirmTriggerChange
        :open="show"
        @confirm="confirm"
        @cancel="cancel"
      />
    </Page>
  </AppLayout>
</template>
