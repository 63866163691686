<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  List,
  ListItem,
  Modal,
  TextInput,
  TextInputFieldTypes,
  TextStyle,
  TextStyleFontSize,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import { ArrowRightCircleIcon, BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { onMounted, PropType } from 'vue';
import { asset } from 'laravel-vapor';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;
type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

defineEmits<{
  (e: 'verify'): void;
}>();

const form = useForm({
  title: '',
  description: '',
  consumer_key: '',
  consumer_secret: '',
  base_url: '',
});

const {
  currentStep,
  firstStep,
  lastStep,
  nextStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(2);
});

function verify(): void {
  form.submit('post', route('panel.integration.woo-commerce.save-credentials'));
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}

</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />
    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.integrations:woocommerce:title') }}
      </Heading>
    </div>

    <div
      v-if="currentStep === 1"
      class="space-y-6"
    >
      <TextStyle
        bold
        :font-size="TextStyleFontSize.Base"
      >
        {{ $t('panel.integrations:woocommerce:step1:title') }}
      </TextStyle>
      <List marker-subdued>
        <ListItem>
          <i18n-t
            keypath="panel.integrations:woocommerce:step1"
            for="panel.integrations:woocommerce:step1"
          >
            <template #term1>
              <b>
                {{ $t('panel.integrations:woocommerce:step1:term1') }}
              </b>
            </template>
          </i18n-t>
        </ListItem>
        <ListItem>
          <i18n-t
            keypath="panel.integrations:woocommerce:step2"
            for="panel.integrations:woocommerce:step2"
          >
            <template #term1>
              <b>
                {{ $t('panel.integrations:woocommerce:step2:term1') }}
              </b>
            </template>
            <template #term2>
              <b>
                {{ $t('panel.integrations:woocommerce:step2:term2') }}
              </b>
            </template>
          </i18n-t>
        </ListItem>
      </List>
      <img
        :alt="$t('panel.integrations:woocommerce:step1:image_alt_text')"
        :src="asset('img/integrations/woocommerce-plugin-step-1.png')"
        loading="lazy"
      >
    </div>

    <div
      v-if="currentStep === 2"
      class="space-y-6"
    >
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.title"
            :label="$t('panel.integrations:woocommerce:form:label:title')"
            :error="form.errors.title"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.description"
            :label="$t('panel.integrations:woocommerce:form:label:description')"
            :error="form.errors.description"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.consumer_key"
            :label="$t('panel.integrations:woocommerce:form:label:consumer_key')"
            :type="TextInputFieldTypes.Password"
            :error="form.errors.consumer_key"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.consumer_secret"
            :label="$t('panel.integrations:woocommerce:form:label:consumer_secret')"
            :type="TextInputFieldTypes.Password"
            :error="form.errors.consumer_secret"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.base_url"
            :label="$t('panel.integrations:woocommerce:form:label:base_url')"
            :error="form.errors.base_url"
          />
        </FormLayout>
      </FormLayout>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.integrations:woocommerce:guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:woocommerce:verify_integration') }}</span>
        </Button>

        <Button
          v-else
          full-width
          :variant="ButtonVariant.Primary"
          @click="nextStep"
        >
          <template #icon>
            <ArrowRightCircleIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.integrations:woocommerce:next_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
