<script lang="ts" setup>
import { TextStyle, TextStyleFontSize } from '@app/panel/Components';
import {
  BuildingOfficeIcon,
  BuildingStorefrontIcon,
  HomeIcon,
  PrinterIcon,
  QrCodeIcon,
} from '@heroicons/vue/24/outline';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { computed } from 'vue';
import { App, Types } from '@app/shared/types/generated-v2';
import { usePage } from '@inertiajs/vue3';

type FormShippingMethodViewModel = Types['App.Models.ViewModels.FormShippingMethodViewModel'];

const emit = defineEmits<{
  select: [formShippingMethod: FormShippingMethodViewModel];
}>();

const props = defineProps<{
  formShippingMethod: FormShippingMethodViewModel;
  checked: boolean;
}>();

function selectFormShippingMethod() {
  emit('select', props.formShippingMethod);
}

const borderClassList = computed(() => {
  return [
    { 'border-blue-500 bg-blue-50': props.checked },
    { 'hover:border-slate-400': !props.checked },
  ];
});

const hasPartner = computed<boolean>(() => {
  return usePage().props.hasPartner as boolean;
});
</script>

<template>
  <div
    tabindex="0"
    class="cursor-pointer space-y-4 rounded-lg border border-slate-300 p-4 text-sm shadow-sm"
    :class="borderClassList"
    @click="selectFormShippingMethod"
    @keyup.enter="selectFormShippingMethod"
  >
    <div class="flex w-full items-center text-sm font-medium">
      <span class="inline-block truncate">{{ formShippingMethod.name || formShippingMethod.shippingMethod.name }}</span>

      <template v-if="!hasPartner && formShippingMethod.price !== null">
        <span class="mx-2 text-slate-500">·</span>
        <span class="shrink-0">{{ formShippingMethod.price }}</span>
      </template>

      <div
        v-if="checked"
        class="ml-auto"
      >
        <CheckCircleIcon class="ml-2 size-5 fill-blue-500" />
      </div>
    </div>

    <div class="flex space-x-4">
      <img
        v-if="formShippingMethod.shippingMethod.imageSrc"
        class="size-10"
        :src="formShippingMethod.shippingMethod.imageSrc"
        loading="lazy"
      >
      <div>
        <div>{{ formShippingMethod.shippingMethod.carrierName }}</div>

        <TextStyle
          v-if="formShippingMethod.shippingMethod.type === App.Enums.ShippingMethodType.DropOff"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="mt-2 flex items-center space-x-1">
            <BuildingOfficeIcon class="size-5" />

            <span>
              {{ $t('panel.shipping:drop-off') }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="formShippingMethod.shippingMethod.type === App.Enums.ShippingMethodType.PickUp"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="mt-2 flex items-center space-x-1">
            <HomeIcon class="size-5" />

            <span>
              {{ $t('panel.shipping:pick-up') }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="formShippingMethod.shippingMethod.type === App.Enums.ShippingMethodType.ReturnInStore"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="mt-2 flex items-center space-x-1">
            <BuildingStorefrontIcon class="size-5" />

            <span>
              {{ $t('panel.shipping-method-type:return-in-store') }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="formShippingMethod.shippingMethod.isPrinterless"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="mt-1 flex items-center space-x-1 text-green-600">
            <QrCodeIcon class="size-5" />

            <span>{{ $t('panel.shipping:printerless') }}</span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="! formShippingMethod.shippingMethod.isPrinterless && formShippingMethod.shippingMethod.type !== 'other'"
          :font-size="TextStyleFontSize.Small"
        >
          <div class="mt-1 flex items-center space-x-1">
            <PrinterIcon class="size-5" />

            <span>{{ $t('panel.shipping:print-a-label') }}</span>
          </div>
        </TextStyle>
      </div>
    </div>
  </div>
</template>
