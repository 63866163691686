<script lang="ts" setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = withDefaults(defineProps<{
  active: boolean;
  href?: string;
  type?: 'link' | 'button';
}>(), {
  active: false,
  href: null,
  type: 'link',
});

const componentType = computed(() => {
  return props.type === 'button' || props.href === null
    ? 'button'
    : Link;
});

const attributes = computed(() => {
  return componentType.value === 'button' ? { type: 'button' } : {};
});
</script>

<template>
  <component
    :is="componentType"
    v-bind="attributes"
    :class="[
      { 'border-brand-500 text-brand-500': props.active },
      { 'border-transparent text-slate-500 ': !props.active },
    ]"
    :href="href"
    class="group py-6 pb-4 border-b-2 font-medium"
  >
    <span class="group-hover:bg-slate-100 py-2 px-2 rounded-md">
      <slot />
    </span>
  </component>
</template>
