<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { isNull } from 'lodash';
import {
  ActionList,
  ActionListBody,
  ActionListItem,
  ActionListSection,
  ActionListTrigger,
  Button,
  ButtonContent,
  ButtonIcon,
  DataTable,
  DataTableRoot,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
  TextStyle,
  Tooltip,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/vue/16/solid';
import {
  CopyApiKeyModal,
  CreateApiKeyModal,
  EditApiKeyModal,
  PageNavigation,
  RevokeConfirmationModal,
} from './partials';
import * as Types from '@app/panel/types/generated';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';

type PersonalAccessTokenViewModel = Types.App.Models.ViewModels.PersonalAccessTokenViewModel;

defineProps<{
  tokens: PersonalAccessTokenViewModel[];
  userTokenIds: string[];
}>();

const { t } = useI18n();

const currentEditingToken = ref<PersonalAccessTokenViewModel | null>(null);

function openEditModal(token: PersonalAccessTokenViewModel): void {
  currentEditingToken.value = token;
}

function closeEditModal(): void {
  currentEditingToken.value = null;
}

const isEditModalOpen = computed(() => !isNull(currentEditingToken.value));

const currentRevokeToken = ref<PersonalAccessTokenViewModel | null>(null);

function openRevokeModal(token: PersonalAccessTokenViewModel): void {
  currentRevokeToken.value = token;
}

function closeRevokeModal(): void {
  currentRevokeToken.value = null;
}

const isRevokeModalOpen = computed<boolean>(() => !isNull(currentRevokeToken.value));

const isCreateModalOpen = ref<boolean>(false);

function openCreateModal(): void {
  isCreateModalOpen.value = true;
}

function closeCreateModal(): void {
  isCreateModalOpen.value = false;
}

const columns = [
  { accessorKey: 'name', header: t('panel.settings:integrations:api-keys:name') },
  { accessorKey: 'lastUsedAt', header: t('panel.settings:integrations:api-keys:last-used') },
  { accessorKey: 'actions', header: 'Actions', columnType: 'numeric' },
];
</script>

<template>
  <AppLayout :title="$t('panel.settings:integrations:api-keys')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ $t('panel.settings:integrations:api-keys') }}</PageTitle>
        <PageDescription>{{ $t('panel.settings:integrations:api-keys:api-request-authentication') }}</PageDescription>
        <template #page-header-actions>
          <Button
            v-if="tokens.length > 0"
            @click="openCreateModal"
          >
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:integrations:api-keys:create-api-key') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <DataTableRoot
        v-if="tokens.length > 0"
        :columns="columns"
        :rows="tokens"
      >
        <DataTable>
          <template #name="{ row }">
            <div class="flex items-center gap-2">
              <span
                :class="{
                  'line-through': userTokenIds.includes(row.id),
                }"
              >
                {{ row.name }}
              </span>

              <div v-if="userTokenIds.includes(row.id)">
                <Tooltip
                  class="flex"
                  :content="$t('panel.settings:integrations:api-keys:will-expire')"
                >
                  <ExclamationTriangleIcon class="size-5 text-red-600" />
                </Tooltip>
              </div>
            </div>
          </template>
          <template #lastUsedAt="{ row }">
            <span v-if="row.lastUsedAt">
              {{ row.lastUsedAt }}
            </span>

            <TextStyle
              v-else
              subdued
            >
              &mdash;
            </TextStyle>
          </template>

          <template #actions="{ row }">
            <ActionList alignment="end">
              <ActionListTrigger>
                <Button
                  icon-only
                  variant="ghost"
                  size="small"
                >
                  <ButtonIcon :icon="EllipsisHorizontalIcon" />
                </Button>
              </ActionListTrigger>
              <ActionListBody>
                <ActionListSection>
                  <ActionListItem @click="openEditModal(row)">
                    {{ $t('panel.settings:integrations:api-keys:edit') }}
                  </ActionListItem>
                  <ActionListItem
                    variant="destructive"
                    @click="openRevokeModal(row)"
                  >
                    {{ $t('panel.settings:integrations:api-keys:revoke') }}
                  </ActionListItem>
                </ActionListSection>
              </ActionListBody>
            </ActionList>
          </template>
        </DataTable>
      </DataTableRoot>

      <EmptyState v-if="tokens.length === 0">
        <EmptyStateContent>
          <EmptyStateTitle>{{ $t('panel.settings:integrations:api-key:empty:title') }}</EmptyStateTitle>
          <EmptyStateDescription>{{ $t('panel.settings:integrations:api-key:empty:description') }}</EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button @click="openCreateModal">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ $t('panel.settings:integrations:api-keys:create-api-key') }}</ButtonContent>
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>

  <CopyApiKeyModal />

  <CreateApiKeyModal
    :open="isCreateModalOpen"
    @close="closeCreateModal"
  />

  <EditApiKeyModal
    :current="currentEditingToken"
    :open="isEditModalOpen"
    @close="closeEditModal"
  />

  <RevokeConfirmationModal
    :open="isRevokeModalOpen"
    :current="currentRevokeToken"
    @close="closeRevokeModal"
  />
</template>
