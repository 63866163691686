<script lang="ts" setup>
import { CardSection, Form, Stack, StackItem, StackSpacing, TextInput } from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import Select from '@app/customer/Components/Select/components/Select.vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type FormShippingSettingsViewModel = Types.App.Http.ViewModels.Panel.Settings.Form.FormShippingSettingsViewModel;
const props = defineProps<{
  form: FormShippingSettingsViewModel['form'];
  countryOptions: FormShippingSettingsViewModel['countryOptions'];
}>();

const form = useForm({
  default_hs_code: props.form.shippingSettings.defaultHsCode,
  default_country_of_origin_id: props.form.shippingSettings.defaultCountryOfOriginId,
  vat_number: props.form.shippingSettings.vatNumber,
  eori_number: props.form.shippingSettings.eoriNumber,
});

function save() {
  form.put(route('panel.settings.forms.shipping-settings.international-shipping.update', { form: props.form }));
}
</script>

<template>
  <Form @submit="save">
    <Stack
      vertical
      :spacing="StackSpacing.ExtraLoose"
    >
      <StackItem>
        <Card>
          <CardHeader>
            <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.international-shipping-settings')">
              {{ $t('panel.settings:forms:shipping-settings:internation-shipping:title') }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <div class="flex flex-col space-y-6">
              <div class="flex w-full items-start space-x-8">
                <div class="flex-1">
                  <TextInput
                    v-model="form.vat_number"
                    :label="$t('panel.settings:forms:shipping-settings:international-shipping:vat-number:input:title')"
                    placeholder="NL123456789B01"
                    :error="form.errors.vat_number"
                  />
                </div>
                <div class="flex-1">
                  <TextInput
                    v-model="form.eori_number"
                    :label="$t('panel.settings:forms:shipping-settings:international-shipping:eori-number:input:title')"
                    placeholder="NL12345678912345"
                    :error="form.errors.eori_number"
                  />
                </div>
              </div>
              <div class="flex w-full items-end space-x-8">
                <TextInput
                  v-model="form.default_hs_code"
                  :label="$t('panel.settings:forms:shipping-settings:international-shipping:hs-code:input:title')"
                  :help-text="$t('panel.settings:forms:shipping-settings:international-shipping:hs-code:input:subtitle')"
                  :error="form.errors.default_hs_code"
                />
              </div>
              <div class="flex w-full items-end space-x-8">
                <Select
                  v-model="form.default_country_of_origin_id"
                  :label="$t('panel.settings:forms:shipping-settings:international-shipping:country-of-origin:input:title')"
                  :help-text="$t('panel.settings:forms:shipping-settings:international-shipping:country-of-origin:input:subtitle')"
                  :error="form.errors.default_country_of_origin_id"
                  :options="countryOptions"
                />
              </div>
            </div>
          </CardSection>
          <CardFooter>
            <Button
              type="submit"
              :loading="form.processing"
              :disabled="!form.isDirty"
            >
              {{ $t('panel.global:actions:save') }}
            </Button>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  </Form>
</template>
