<script setup lang="ts">
import { Button, ButtonVariant, Modal } from '@app/panel/Components';
import TextContainer from '@app/customer/Components/TextContainer/components/TextContainer.vue';
import { asset } from 'laravel-vapor';
import { router } from '@inertiajs/vue3';

defineProps<{
  open: boolean;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

function close() {
  emits('close');
}

function goToSubscriptions() {
  router.visit(route('panel.account.billing'));
}

</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.settings.forms.list_forms.settings.general.test_mode.alert.title')"
    @close="close"
  >
    <template #icon>
      <img
        :src="asset('img/favicon_light.svg')"
        class="w-20"
        loading="lazy"
      >
    </template>

    <TextContainer>
      {{ $t('panel.settings.forms.list_forms.settings.general.test_mode.alert.description') }}
    </TextContainer>

    <template #actions>
      <Button
        :variant="ButtonVariant.Primary"
        @click="goToSubscriptions"
      >
        {{ $t('panel.settings.forms.list_forms.settings.general.test_mode.alert.go_to_subscription') }}
      </Button>
    </template>
  </Modal>
</template>
