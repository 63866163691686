<script setup lang="ts">
import {
  Button,
  ButtonGroup,
  ButtonType,
  Form,
  FormLayout,
  TextContainer,
  TextInput,
} from '@app/panel/Components';
import { GuestLayout } from '@app/panel/Layouts';
import { useForm } from '@inertiajs/vue3';
import { asset } from 'laravel-vapor';
import type { Types } from '@app/shared/types/generated-v2';

const props = defineProps<Types['App.Http.ViewModels.Panel.Account.ShowInviteViewModel']>();

const form = useForm({
  id: props.invitation.id,
  name: '',
  password: '',
  password_confirmation: '',
});

function submit() {
  form.post(route('accept-membership-invitation'));
}
</script>

<template>
  <GuestLayout
    :title="`${$t('panel.account.show_invite.layout_title', { tenant_name: tenant.name })}`"
    :hero-image="asset('img/registration-background.jpeg')"
  >
    <Form @submit="submit">
      <FormLayout>
        <FormLayout>
          <TextContainer>
            {{ $t('panel.account.show_invite.form_description', { user_name: user.name, tenant_name: tenant.name }) }}
          </TextContainer>
        </FormLayout>

        <FormLayout>
          <TextInput
            disabled
            :value="invitation.email"
            :label="$t('panel.account.show_invite.email_input_label')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.name"
            :label="$t('panel.account.show_invite.name_input_label')"
            name="name"
            auto-focus
            required
            :error="form.errors.name"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.password"
            :label="$t('panel.account.show_invite.new_password_input_label')"
            type="password"
            name="password"
            autocomplete="new-password"
            required
            :error="form.errors.password"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.password_confirmation"
            :label="$t('panel.account.show_invite.new_password_confirmation_input_label')"
            autocomplete="new-password"
            type="password"
            name="password-confirmation"
            required
            :error="form.errors.password_confirmation"
          />
        </FormLayout>

        <FormLayout>
          <ButtonGroup>
            <Button
              variant="primary"
              :type="ButtonType.Submit"
            >
              {{ $t('panel.account.show_invite.accept_invitation_button') }}
            </Button>
          </ButtonGroup>
        </FormLayout>
      </FormLayout>
    </Form>
  </GuestLayout>
</template>
