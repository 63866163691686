<script lang="ts" setup>
import {
  Button,
  ButtonVariant,
  DataTable,
  Modal,
  ModalSize,
  TextStyle,
} from '@app/panel/Components';
import { DraggableIcon } from '@app/panel/Components';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { useUniqueId } from '@app/customer/Composables/useUniqueId';
import { useSortOrder } from '@app/panel/Composables/useSortOrder';
import { App, type Types } from '@app/shared/types/generated-v2';
import { ref, toRef } from 'vue';

const props = defineProps<{
  open: boolean;
  filterTabs: Array<Types['App.Models.ViewModels.FilterTabViewModel']>;
  type: App.Enums.FilterTabType;
}>();

const emit = defineEmits<{
  close: [];
}>();

const deletingFilter = ref(false);

function deleteFilter(filterTab): void {
  router.delete(route('panel.settings.filter-tabs.delete', filterTab.id), {
    onStart: () => deletingFilter.value = true,
    onFinish: () => {
      deletingFilter.value = false;
    },
  });
}

const tableId = useUniqueId('filtersTable');

const { sortedItems: sortedFilters } = useDragAndDrop(
  tableId,
  toRef(props, 'filterTabs'),
  toRef(props, 'open'),
);

useSortOrder(
  sortedFilters,
  route('panel.filter-tabs.sort'),
  { type: props.type },
);
</script>

<template>
  <Modal
    :title="$t('panel.returns.filters.manage_filters')"
    icon-color="blue"
    :size="ModalSize.Small"
    :open="open"
    @close="emit('close')"
  >
    <template #icon>
      <FunnelIcon class="w-5" />
    </template>

    <DataTable
      :id="tableId"
      :headings="[{
        text: $t('panel.returns.filters.removal_modal.name'),
        value: 'label'
      }]"
      :headings-visible="false"
      :items="filterTabs"
    >
      <template #label="{ item }">
        <div class="flex">
          <DraggableIcon />

          <TextStyle>
            {{ item.label }}
          </TextStyle>
        </div>
      </template>

      <template #actions="{ item }">
        <Button
          plain
          :variant="ButtonVariant.Critical"
          @click="deleteFilter(item)"
        >
          {{ $t('panel.returns.filters.removal_modal.action') }}
        </Button>
      </template>
    </DataTable>
  </Modal>
</template>
