<script lang="ts" setup>
import {
  computed,
  provide,
  ref,
} from 'vue';
import {
  ActionCard,
  Heading,
  HeadingElement,
  Link,
  LinkFontSize,
  LinkVariant,
  Page,
  PageActions,
  TextContainer,
  TextStyle,
  TextStyleShade,
  UnsupportedFeature,
} from '@app/panel/Components';
import { CardLayout } from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { MediaCard } from '@app/panel/Containers';
import { PencilSquareIcon } from '@heroicons/vue/24/outline';
import CreateAddressModal from './partials/CreateAddressModal.vue';
import EditAddressModal from './partials/EditAddressModal.vue';
import PageNavigation from './partials/PageNavigation.vue';
import { router } from '@inertiajs/vue3';
import { StatusBadge } from '@app/panel/types';
import { useI18n } from 'vue-i18n';
import { App, type Types } from '@app/shared/types/generated-v2';
import { useFeatureWarning } from '@app/panel/Composables/useFeatureWarning';
import FeatureLimitWarning from '@app/panel/Pages/Settings/Components/FeatureLimitWarning.vue';

type ReturnAddressesViewModel = Types['App.Http.ViewModels.Panel.Settings.ReturnAddresses.ReturnAddressesViewModel'];
type ReturnAddressViewModel = Types['App.Models.ViewModels.ReturnAddressViewModel'];

const props = defineProps<ReturnAddressesViewModel>();

provide('countries', props.countries);

const { setFeatureWarningMessage } = useFeatureWarning();

const createModalOpen = ref(false);

function openCreateModal() {
  if (props.featureUsageInfo.hasReachedLimit) {
    setFeatureWarningMessage(props.featureUsageInfo.warningMessage);

    return;
  }

  createModalOpen.value = true;
}

function closeCreateModal() {
  createModalOpen.value = false;
}

const editModalOpen = computed(() => props.returnAddress !== null);

function closeEditModal(): void {
  if (props.returnAddress.addressType === App.Enums.ReturnAddressType.STORE_LOCATION) {
    router.visit(route('panel.settings.store-locations'));
    return;
  }

  router.visit(route('panel.settings.return-addresses'));
}

const canShowLocations = computed(() => {
  return props.featureUsageInfo.isAvailable || props.featureUsageInfo.usage > 0;
});

const { t } = useI18n();

function getStatusBadge(address: ReturnAddressViewModel): StatusBadge | null {
  if (!address.primary) {
    return null;
  }

  return {
    label: `${t('panel.settings.addresses.primary')} ${address.country.isoCode}`,
    color: 'blue',
  };
}
</script>

<template>
  <AppLayout :title="$t('panel.settings.addresses.head_title')">
    <Page>
      <PageActions>
        <div>
          <Heading
            v-if="canShowLocations"
            :element="HeadingElement.H3"
          >
            {{
              props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                ? $t('panel.settings:store-locations:page-title')
                : $t('panel.settings:return-addresses:page-title')
            }}
          </Heading>
        </div>
      </PageActions>

      <template #page-navigation>
        <PageNavigation />
      </template>

      <CardLayout>
        <FeatureLimitWarning :feature-usage-info="featureUsageInfo" />

        <div
          v-if="canShowLocations"
          class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 auto-rows-[minmax(23rem,_1fr)] gap-5"
        >
          <MediaCard
            v-for="address in returnAddresses"
            :key="address.id"
            :title="address.name"
            :status-badge="getStatusBadge(address)"
            :status-badge-position="'top-right'"
          >
            <template #header>
              <img
                v-fallback-img
                class="h-full w-full object-cover"
                alt="address location"
                :src="address.rawMapImageSrc"
                loading="lazy"
              >
            </template>

            <div class="flex items-center justify-between">
              <TextContainer class="w-full">
                <div class="flex justify-between w-full">
                  <div class="flex grow flex-col space-y-1">
                    <TextStyle :shade="TextStyleShade.Subdued">
                      {{ address.companyName }}
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Subdued">
                      {{ address.addressLine }}
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Subdued">
                      {{ address.postalCode }} {{ address.city }}
                    </TextStyle>

                    <TextStyle :shade="TextStyleShade.Subdued" />

                    <TextStyle :shade="TextStyleShade.Subdued">
                      {{ address.telephone }}
                    </TextStyle>
                  </div>
                  <img
                    v-if="address.imageSrc"
                    :src="address.imageSrc"
                    alt="brand icon"
                    class="h-8 w-8 object-cover"
                  >
                </div>
              </TextContainer>
            </div>

            <template #footer>
              <Link
                :href="$route(
                  props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                    ? 'panel.settings.store-locations.show'
                    : 'panel.settings.return-addresses.show',
                  address
                )"
                :variant="LinkVariant.Subdued"
                :font-size="LinkFontSize.Small"
              >
                <TextStyle>
                  <div class="flex items-center space-x-2 group-hover:text-slate-500">
                    <PencilSquareIcon class="h-5 w-5 text-slate-400" />

                    <span>
                      {{
                        props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION
                          ? $t('panel.settings:store-locations:buttons:edit-address-short')
                          : $t('panel.settings:return-addresses:buttons:edit-address-short')
                      }}
                    </span>
                  </div>
                </TextStyle>
              </Link>
            </template>
          </MediaCard>

          <ActionCard
            v-if="canShowLocations"
            :title="props.addressType === App.Enums.ReturnAddressType.STORE_LOCATION
              ? $t('panel.settings:store-locations:buttons:add-address')
              : $t('panel.settings:return-addresses:buttons:add-address')"
            @click="openCreateModal"
          />
        </div>

        <div v-if="!canShowLocations">
          <UnsupportedFeature
            :feature="$t('panel.subscriptions:features:store-locations')"
            :available-in="[$t('panel.subscriptions:plans:automate')]"
          />
        </div>
      </CardLayout>
    </Page>

    <CreateAddressModal
      :open="createModalOpen"
      :address-type="props.addressType"
      @close="closeCreateModal"
    />

    <EditAddressModal
      v-if="returnAddress"
      :open="editModalOpen"
      :address="returnAddress"
      @close="closeEditModal"
    />
  </AppLayout>
</template>
