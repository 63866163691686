<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '@app/panel/Pages/Settings/Forms/partials';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonGroup,
  ButtonIconPosition,
  ButtonType,
  ButtonVariant,
  Form,
  Link,
  Page,
} from '@app/panel/Components';
import { CardLayout } from '@returnless/focus-ui';
import { ArrowRightIcon } from '@heroicons/vue/24/solid';
import {
  computed,
  provide,
  ref,
  toRef,
  watch,
} from 'vue';
import { Types } from '@app/shared/types/generated-v2';
import { router } from '@inertiajs/vue3';
import EditShippingMethodModal from '@app/panel/Pages/Settings/Forms/ShippingMethods/EditShippingMethodModal.vue';
import AddShippingMethodModal from '@app/panel/Pages/Settings/Forms/ShippingMethods/AddShippingMethodModal.vue';
import EditCustomShippingMethodModal from '@app/panel/Pages/Settings/Forms/ShippingMethods/EditCustomShippingMethodModal.vue';
import ShippingMethodsDataTable from '@app/panel/Pages/Settings/Forms/ShippingMethods/ShippingMethodsDataTable/ShippingMethodsDataTable.vue';
import { FunnelIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import ShippingMethodsDataTableFilters from '@app/panel/Pages/Settings/Forms/ShippingMethods/ShippingMethodsDataTable/ShippingMethodsDataTableFilters.vue';
import ShippingMethodsDataTableActiveFilters from '@app/panel/Pages/Settings/Forms/ShippingMethods/ShippingMethodsDataTable/ShippingMethodsDataTableActiveFilters.vue';
import { get, isArray, set } from 'lodash';
import { useSorting } from '@app/panel/Composables/useSorting';
import UnsupportedFeature from '@app/panel/Components/UnsupportedFeature/UnsupportedFeature.vue';
import FeatureLimitWarning from '@app/panel/Pages/Settings/Components/FeatureLimitWarning.vue';
import { cleanObject } from '@app/panel/Utils/object';

type FormShippingMethodsViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel'];

const props = defineProps<FormShippingMethodsViewModel>();

provide('form', props.form);

const formShippingMethodsListViewModel = toRef(props, 'formShippingMethodsListViewModel');

const isReturnlessContract = computed<boolean>(() => !props.selectedContract?.isOwnContract);

const dataTableItems = computed(() => formShippingMethodsListViewModel.value.shippingMethods);

const selectedDataTableItems = ref([]);

watch(dataTableItems, () => {
  selectedDataTableItems.value = dataTableItems.value.filter((shippingMethod) => shippingMethod.isSelected);
}, { immediate: true });

const isOpenCreateShippingModal = ref(false);

function openCreateShippingModal() {
  isOpenCreateShippingModal.value = true;
}

function closeCreateShippingModal() {
  isOpenCreateShippingModal.value = false;
}

function updateCheckedShippingMethods() {
  const selectedShippingMethodIds = selectedDataTableItems.value.flatMap((item) => item.id);

  const shippingMethodsCheckedStates: any = dataTableItems.value.map((item) => {
    return {
      id: item.id,
      checked: selectedShippingMethodIds.includes(item.id),
    };
  });

  router.put(route('panel.settings.forms.update-shipping-methods', { form: props.form.id }), {
    contract_id: props.selectedContract?.id ?? null,
    shipping_method_checked_state: shippingMethodsCheckedStates,
  }, {
    preserveScroll: true,
  });
}

const isFiltersOpen = ref(false);

function toggleFilters() {
  isFiltersOpen.value = !isFiltersOpen.value;
}

const filterForm = useForm({
  filter: {
    from_country: '',
    to_country: '',
    min_weight: { from: '', to: '' },
    max_weight: { from: '', to: '' },
    price: { from: '', to: '' },
    type: '',
    max_width: { from: '', to: '' },
    max_height: { from: '', to: '' },
    max_length: { from: '', to: '' },
    product_code: '',
    is_printerless: '',
    is_active: '',
    ...props.formShippingMethodsListViewModel.filters,
  },
  sort: null,
  per_page: props.formShippingMethodsListViewModel.perPage,
  contract: props.selectedContract?.id,
});

const { sortBy, sort } = useSorting(
  computed(() => formShippingMethodsListViewModel.value.sort),
  'shipping_method_sorts',
  route('panel.settings.forms.shipping-methods', props.form),
  filterForm,
);

const canUseShippingContracts = computed<boolean>((): boolean => {
  return isReturnlessContract.value || props.featureUsageInfo.isAvailable || props.featureUsageInfo.usage > 0;
});

function onFormChange() {
  router.get(route('panel.settings.forms.shipping-methods', props.form), cleanObject(filterForm.data()), {
    preserveScroll: true,
    preserveState: true,
  });
}

function resetFilter(path: string): void {
  const value = get(filterForm, path);
  set(filterForm, path, isArray(value) ? [] : '');
  onFormChange();
}

function onPerPageChange(perPage: number) {
  filterForm.per_page = perPage;
  onFormChange();
}
</script>

<template>
  <AppLayout :title="$t('panel.forms:shipping-methods:head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <CardLayout>
        <Banner
          v-if="isReturnlessContract"
          :variant="BannerVariant.Info"
        >
          <div class="flex items-center justify-between">
            <div>
              {{ $t('panel.form:shipping-methods:surcharges:warning:title') }}
            </div>
            <Link
              underline
              href="https://docs.returnless.com/docs/product-guide/ez953aasdjpye"
              target="_blank"
              native
              class="flex items-center space-x-2"
            >
              <span>{{ $t('panel.form:shipping-methods:surcharges:warning:link') }}</span>
              <ArrowRightIcon class="size-4" />
            </Link>
          </div>
        </Banner>

        <FeatureLimitWarning
          v-if="!isReturnlessContract && canUseShippingContracts"
          :feature-usage-info="featureUsageInfo"
        />

        <div v-if="canUseShippingContracts">
          <Form
            method="post"
            @submit="updateCheckedShippingMethods"
          >
            <div class="space-y-4">
              <div class="flex items-end justify-between space-x-8">
                <div class="whitespace-nowrap">
                  <ButtonGroup class="flex items-end">
                    <Button
                      :icon-position="ButtonIconPosition.Left"
                      :variant="ButtonVariant.Default"
                      @click="toggleFilters"
                      @keyup.esc="toggleFilters"
                    >
                      <template #icon>
                        <FunnelIcon class="size-5" />
                      </template>
                      {{ $t('panel.global:labels:filters') }}
                    </Button>
                    <Button
                      :variant="ButtonVariant.Default"
                      @click="openCreateShippingModal"
                    >
                      {{ $t('panel.forms:shipping-methods:add-custom-shipping-method') }}
                    </Button>
                  </ButtonGroup>
                </div>

                <Button
                  :variant="ButtonVariant.Primary"
                  :type="ButtonType.Submit"
                >
                  {{ $t('panel.forms:shipping-methods:save') }}
                </Button>
              </div>

              <ShippingMethodsDataTableFilters
                v-if="isFiltersOpen"
                :has-active-filter="formShippingMethodsListViewModel.hasActiveFilter"
                :filter-tabs="formShippingMethodsListViewModel.filterTabs"
                :filter-form="filterForm"
                :form-shipping-methods-filter-view-model="formShippingMethodsFilterViewModel"
                @change="onFormChange"
              />

              <ShippingMethodsDataTableActiveFilters
                :filter-form="filterForm"
                :reset-filter="resetFilter"
                :form-shipping-methods-filter-view-model="formShippingMethodsFilterViewModel"
              />

              <ShippingMethodsDataTable
                v-model:data-table-items="dataTableItems"
                v-model:selected-data-table-items="selectedDataTableItems"
                :form="form"
                :selected-contract="selectedContract"
                :form-shipping-methods-list-view-model="formShippingMethodsListViewModel"
                :sort-by="sortBy"
                :filter-form="filterForm"
                @sorted="sort"
                @per-page-change="onPerPageChange"
              />
            </div>
          </Form>
        </div>
      </CardLayout>

      <div v-if="!canUseShippingContracts">
        <UnsupportedFeature
          :feature="$t('panel.subscriptions:features:shipping-contracts')"
          :available-in="[$t('panel.subscriptions:plans:integrate')]"
        />
      </div>
    </Page>

    <EditShippingMethodModal
      :open="isEditingShippingMethod && selectedContract?.shippingIntegration.driver !== 'custom'"
      :form="form"
      :selected-contract="selectedContract"
      :current-editing-shipping-method="currentEditingShippingMethod"
      :current-editing-form-shipping-method="currentEditingFormShippingMethod"
      :shipping-instructions="shippingInstructions"
      :return-addresses="returnAddresses"
      :filter-form="filterForm"
    />

    <EditCustomShippingMethodModal
      :open="isEditingShippingMethod && selectedContract?.shippingIntegration.driver === 'custom'"
      :form="form"
      :selected-contract="selectedContract"
      :all-countries="allCountries"
      :return-addresses="returnAddresses"
      :shipping-method-types="formShippingMethodsFilterViewModel.shippingMethodTypes"
      :shipping-instructions="shippingInstructions"
      :current-editing-form-shipping-method="currentEditingFormShippingMethod"
      :current-editing-shipping-method="currentEditingShippingMethod"
      @close="closeCreateShippingModal"
    />

    <AddShippingMethodModal
      :open="isOpenCreateShippingModal"
      :form="form"
      :selected-contract="selectedContract"
      :custom-shipping-contracts="customShippingContracts"
      :all-countries="allCountries"
      :return-addresses="returnAddresses"
      :has-custom-shipping-integration="hasCustomShippingIntegration"
      :shipping-method-types="formShippingMethodsFilterViewModel.shippingMethodTypes"
      :shipping-instructions="shippingInstructions"
      @close="closeCreateShippingModal"
    />
  </AppLayout>
</template>
