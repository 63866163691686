<script lang="ts" setup>
import { usePanel } from '@app/panel/Composables/useHttp';
import { router } from '@inertiajs/vue3';
import { useForm } from '@inertiajs/vue3';
import {
  Button,
  ButtonVariant,
  Card,
  CardSection,
  Heading,
  Modal,
  TextContainer,
  TextInput,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { PasswordConfirmation } from '@app/panel/Containers';
import { AutocompleteOption } from '../../../Components/TextInput';
import { computed, ref, watch } from 'vue';
import { Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  user: Types['App.Http.ViewModels.Panel.Account.ProfileViewModel']['user'];
}>();

const regeneratingRecoveryCodes = ref(false);
const confirming = ref(false);
const recoveryCodes = ref([]);
const enabling = ref(false);
const disabling = ref(false);
const qrCode = ref(null);
const secretKey = ref(null);
const primaryButtonVariant = ButtonVariant.Primary;
const criticalButtonVariant = ButtonVariant.Critical;

const confirmationForm = useForm({
  code: '',
});

const twoFactorEnabled = computed(() => props.user.hasTwoFactorEnabled);

const showQrCode = () => {
  return usePanel().get('/user/two-factor-qr-code').then((response) => {
    qrCode.value = response.data.svg;
  });
};

const showSecretKey = () => {
  return usePanel().get('/user/two-factor-secret-key').then((response) => {
    secretKey.value = response.data.secretKey;
  });
};

const showRecoveryCodes = () => {
  return usePanel().get('/user/two-factor-recovery-codes')
    .then((response) => {
      recoveryCodes.value = response.data;
    });
};

const enableTwoFactorAuthentication = () => {
  enabling.value = true;

  router.post('/user/two-factor-authentication', {}, {
    preserveScroll: true,
    onSuccess: function () {
      Promise.all([
        showQrCode(),
        showSecretKey(),
        showRecoveryCodes(),
      ]);
    },
    onFinish: () => {
      enabling.value = false;
      confirming.value = true;
    },
  });
};

const disableTwoFactorAuthentication = () => {
  disabling.value = true;

  router.delete('/user/two-factor-authentication', {
    preserveScroll: true,
    onSuccess: () => {
      disabling.value = false;
      confirming.value = false;
    },
  });
};

const confirmTwoFactorAuthentication = () => {
  confirmationForm.post('/user/confirmed-two-factor-authentication', {
    errorBag: 'confirmTwoFactorAuthentication',
    preserveScroll: true,
    preserveState: true,
    onSuccess: function () {
      confirming.value = false;
      qrCode.value = null;
      secretKey.value = null;
      confirmationForm.code = '';
    },
  });
};

const regenerateRecoveryCodes = () => {
  regeneratingRecoveryCodes.value = true;

  usePanel().post('/user/two-factor-recovery-codes')
    .then(function () {
      showRecoveryCodes();
      regeneratingRecoveryCodes.value = false;
    });
};

watch(twoFactorEnabled, () => {
  if (!twoFactorEnabled.value) {
    confirmationForm.reset();
    confirmationForm.clearErrors();
  }
});
</script>

<template>
  <Card :title="$t('panel.account.profile.2fa.card_title')">
    <CardSection>
      <Heading
        v-if="twoFactorEnabled && ! confirming"
        size="small"
      >
        {{ $t('panel.account.profile.2fa.enabled_message') }}
      </Heading>

      <Heading
        v-else
        size="small"
      >
        {{ $t('panel.account.profile.2fa.not_enabled_message') }}
      </Heading>

      <div class="mt-1">
        <TextContainer>
          <TextStyle :shade="TextStyleShade.Subdued">
            {{ $t('panel.account.profile.2fa.explanation') }}
          </TextStyle>
        </TextContainer>
      </div>

      <div v-if="confirming">
        <div v-if="qrCode">
          <TextContainer v-if="!confirming">
            <TextStyle :shade="TextStyleShade.Subdued">
              Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator
              application or enter the setup key.
            </TextStyle>
          </TextContainer>
        </div>
      </div>

      <div v-if="recoveryCodes.length > 0 && twoFactorEnabled && !confirming">
        <div class="mt-4 max-w-xl text-sm text-slate-600">
          <p class="font-semibold">
            {{ $t('panel.account.profile.2fa.recovery_codes.description') }}
          </p>
        </div>

        <div class="grid gap-1 max-w-xl mt-4 px-4 py-4 font-mono text-sm bg-slate-100 rounded-lg">
          <div
            v-for="code in recoveryCodes"
            :key="code"
          >
            {{ code }}
          </div>
        </div>
      </div>

      <div class="mt-5">
        <div v-if="! twoFactorEnabled">
          <PasswordConfirmation @confirmed="enableTwoFactorAuthentication">
            <Button
              :class="{ 'opacity-25': enabling }"
              :disabled="enabling"
              :variant="primaryButtonVariant"
            >
              {{ $t('panel.account.profile.2fa.enable_button') }}
            </Button>
          </PasswordConfirmation>
        </div>

        <div v-else>
          <PasswordConfirmation @confirmed="regenerateRecoveryCodes">
            <Button
              v-if="recoveryCodes.length > 0 && ! confirming"
              class="mr-3"
              :variant="primaryButtonVariant"
              :loading="regeneratingRecoveryCodes"
            >
              {{ $t('panel.account.profile.2fa.recovery_codes.regenerate_button') }}
            </Button>
          </PasswordConfirmation>

          <PasswordConfirmation @confirmed="showRecoveryCodes">
            <Button
              v-if="recoveryCodes.length === 0 && ! confirming"
              class="mr-3"
              :variant="primaryButtonVariant"
            >
              {{ $t('panel.account.profile.2fa.recovery_codes.show_button') }}
            </Button>
          </PasswordConfirmation>

          <PasswordConfirmation @confirmed="disableTwoFactorAuthentication">
            <Button
              v-if="! confirming"
              :class="{ 'opacity-25': disabling }"
              :disabled="disabling"
              :variant="criticalButtonVariant"
              :loading="disabling"
            >
              {{ $t('panel.account.profile.2fa.disable_button') }}
            </Button>
          </PasswordConfirmation>
        </div>
      </div>
    </CardSection>
  </Card>

  <Modal
    :open="confirming && !! qrCode"
    :title="$t('panel.account.profile.2fa.modal.title')"
    @close="disableTwoFactorAuthentication"
  >
    <div class="mb-4">
      <TextContainer>
        <TextStyle :shade="TextStyleShade.Subdued">
          {{ $t('panel.account.profile.2fa.modal.description') }}
        </TextStyle>
      </TextContainer>
    </div>

    <div class="flex flex-col items-center">
      <div>
        <div
          class="flex justify-center sm:justify-start"
          v-html="qrCode"
        />
      </div>

      <div class="w-full">
        <div class="flex justify-center">
          <div
            v-if="secretKey"
            class="mt-4 text-sm text-slate-600 bg-slate-100 rounded-md text-center p-2 shadow-sm"
          >
            <p class="font-semibold">
              {{ $t('panel.account.profile.2fa.modal.setup_key_label') }}: <span v-html="secretKey" />
            </p>
          </div>
        </div>

        <div class="mt-4">
          <TextInput
            v-model="confirmationForm.code"
            :label="$t('panel.account.profile.2fa.modal.verification_field')"
            name="code"
            autofocus
            :autocomplete="AutocompleteOption.OneTimeCode"
            :error="confirmationForm.errors.code"
            @keyup.enter="confirmTwoFactorAuthentication"
          />
        </div>
      </div>
    </div>

    <template #actions>
      <Button
        :class="{ 'opacity-25': disabling }"
        :disabled="disabling"
        @click="disableTwoFactorAuthentication"
      >
        {{ $t('panel.account.profile.2fa.modal.cancel_button') }}
      </Button>

      <Button
        class="mr-3"
        :class="{ 'opacity-25': enabling }"
        :disabled="enabling"
        :variant="primaryButtonVariant"
        :loading="confirmationForm.processing"
        @click="confirmTwoFactorAuthentication"
      >
        {{ $t('panel.account.profile.2fa.modal.confirm_button') }}
      </Button>
    </template>
  </Modal>
</template>
