<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Form,
  FormLayout,
  InputLabel,
  Modal,
  ModalSize,
  Select,
  SelectOption,
  TextInput,
  TextInputFieldTypes,
  Toggle,
} from '@app/panel/Components';
import { CubeIcon } from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { App } from '@app/panel/types/generated';
import Divider from '@app/customer/Components/Divider/components/Divider.vue';
import TextContainer from '@app/customer/Components/TextContainer/components/TextContainer.vue';

type CarrierViewModel = App.Models.ViewModels.CarrierViewModel;
type CountryViewModel = Types.App.Models.ViewModels.CountryViewModel;

defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits<{ (e: 'close'): void }>();

const countries = inject<CountryViewModel[]>('countries');
const toCountries = inject<CountryViewModel[]>('toCountries');
const carriers = inject<CarrierViewModel[]>('carriers');

const form = useForm({
  name: '',
  carrier_id: '',
  custom_integration_url: '',
  from_country_id: '',
  to_country_id: '',
  min_weight: '',
  max_weight: '',
  max_width: '',
  max_height: '',
  max_length: '',
  type: '',
  is_printerless: false,
});

const carrierOptions = computed<SelectOption[]>(() => {
  return carriers.map((carrier) => {
    return {
      label: carrier.name,
      value: carrier.id,
    };
  });
});

const countryOptions = computed<SelectOption[]>(() => {
  return countries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

const toCountryOptions = computed<SelectOption[]>(() => {
  return toCountries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

const typeOptions: SelectOption[] = [
  {
    label: 'Pick up',
    value: 'pick-up',
  },

  {
    label: 'Drop off',
    value: 'drop-off',
  },
];

function handleClose(): void {
  emit('close');

  form.reset();
}

function store(): void {
  form.post(route('panel.settings.general.shipping-methods.store'), {
    preserveState: true,
    onSuccess: () => handleClose(),
  });
}
</script>

<template>
  <Modal
    :open="open"
    :title="$t('panel.settings:general:shipping-methods:add-shipping-method')"
    icon-color="blue"
    :size="ModalSize.Large"
    @close="handleClose"
  >
    <template #icon>
      <CubeIcon class="size-6" />
    </template>

    <TextContainer>
      <span>{{ $t('panel.settings:general:shipping-methods:custom-shipping-method:description') }}</span>
    </TextContainer>

    <Form>
      <FormLayout>
        <TextInput
          v-model="form.name"
          :error="form.errors.name"
          :label="$t('panel.settings:general:shipping-methods:name')"
        />
      </FormLayout>

      <Divider />

      <FormLayout>
        <FormLayout>
          <Select
            v-model="form.carrier_id"
            :options="carrierOptions"
            :error="form.errors.carrier_id"
            :label="$t('panel.settings:general:shipping-methods:carrier')"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.custom_integration_url"
            :error="form.errors.custom_integration_url"
            :label="$t('panel.settings:general:shipping-methods:request-url')"
            placeholder="https://www.travelshop.com/api/customshippingmethod/"
          />
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <Select
              v-model="form.from_country_id"
              :options="countryOptions"
              :error="form.errors.from_country_id"
              :label="$t('panel.settings:general:shipping-methods:from-country')"
            />
          </FormLayout>

          <FormLayout>
            <Select
              v-model="form.to_country_id"
              :options="toCountryOptions"
              :error="form.errors.to_country_id"
              :label="$t('panel.settings:general:shipping-methods:to-country')"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <TextInput
              v-model="form.min_weight"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.min_weight"
              :label="$t('panel.settings:general:shipping-methods:minimal-weight')"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.max_weight"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_weight"
              :label="$t('panel.settings:general:shipping-methods:maximum-weight')"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="3"
        >
          <FormLayout>
            <TextInput
              v-model="form.max_width"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_width"
              :label="$t('panel.settings:general:shipping-methods:maximum-width')"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.max_height"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_height"
              :label="$t('panel.settings:general:shipping-methods:maximum-height')"
            />
          </FormLayout>

          <FormLayout>
            <TextInput
              v-model="form.max_length"
              :type="TextInputFieldTypes.Number"
              :error="form.errors.max_length"
              :label="$t('panel.settings:general:shipping-methods:maximum-length')"
            />
          </FormLayout>
        </FormLayout>

        <FormLayout
          :columns="1"
          :columns-sm="2"
        >
          <FormLayout>
            <Select
              v-model="form.type"
              :options="typeOptions"
              :error="form.errors.type"
              :label="$t('panel.settings:general:shipping-methods:type')"
            />
          </FormLayout>

          <FormLayout>
            <div>
              <InputLabel
                :label="$t('panel.settings:general:shipping-methods:printerless:title')"
              />

              <Toggle
                v-model="form.is_printerless"
                :error="form.errors.is_printerless"
              />
            </div>
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </Form>

    <template #actions>
      <ButtonGroup full-width>
        <Button full-width>
          {{ $t('panel.settings:general:shipping-methods:verify-request-url') }}
        </Button>

        <Button
          full-width
          :variant="ButtonVariant.Primary"
          :disabled="! form.isDirty"
          :loading="form.processing"
          @click="store"
        >
          {{ $t('panel.settings:general:shipping-methods:save-shipping-method') }}
        </Button>
      </ButtonGroup>
    </template>
  </Modal>
</template>
