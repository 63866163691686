<script lang="ts" setup>
import AddressModal from './AddressModal.vue';
import { App } from '@app/shared/types/generated-v2';
import { ReturnAddressForm } from '@app/panel/Pages/Settings/ReturnAddresses/types/ReturnAddressForm.type';
import { InertiaForm } from '@inertiajs/vue3';

defineProps<{
  open: boolean;
  addressType: App.Enums.ReturnAddressType;
}>();

defineEmits<{
  close: [];
}>();

function save(form: InertiaForm<ReturnAddressForm>): Promise<boolean> {
  return new Promise((resolve) => {
    form.post(route('panel.settings.return-addresses.store'), {
      onSuccess: () => resolve(true),
      onError: () => resolve(false),
    });
  });
}
</script>

<template>
  <AddressModal
    type="create"
    :open="open"
    :address-type="addressType"
    :submit-request="save"
    @close="$emit('close')"
  />
</template>
