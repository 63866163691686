<script lang="ts" setup>
import {
  computed,
} from 'vue';
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { Spinner } from '../../Spinner';
import { theme } from '../../../Composables/useTheme';
import { ErrorMessage } from '../../ErrorMessage';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },

  empty: {
    type: Boolean,
    default: false,
  },

  name: {
    type: String,
    default: null,
  },

  checked: {
    type: Boolean,
    default: false,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  error: {
    type: [String, null],
    default: null,
  },
});

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const classList = computed(() => {
  return [
    !props.checked ? theme(['hover-bg']) : theme(['hover-bg'], 'primary'),
    !props.checked ? theme(['hover-border'], props.error ? 'critical' : 'default') : '',
    props.error ? theme(['border'], 'critical') : '',
    { 'border-2 border-slate-300': !props.checked && !props.empty },
    { 'border-2 border-brand-500 bg-brand-50': props.checked && !props.empty },
    { 'border border-dashed border-slate-300': props.empty },
  ];
});

const onClickHandler = (): void => {
  emit('click');
};
</script>

<template>
  <div
    class="h-full"
    @click="onClickHandler"
  >
    <div
      data-test="callout-card"
      class="relative rounded-lg p-4 cursor-pointer bg-white h-full"
      :class="classList"
    >
      <input
        class="sr-only"
        type="radio"
        :name="name"
      >

      <div
        v-if="loading"
        class="absolute inset-0 flex justify-center items-center"
      >
        <Spinner />
      </div>

      <div :class="{'opacity-0': loading}">
        <div
          v-if="!empty"
          data-test="callout-card-header"
          class="flex justify-between mb-3"
        >
          <span
            v-if="title"
            data-test="callout-card-title"
            class="text-sm text-slate-900 font-medium"
          >
            {{ title }}
          </span>

          <CheckCircleIcon
            v-if="checked && !empty"
            class="h-5 w-5 fill-brand-500 justify-end"
          />
        </div>

        <slot />
      </div>
    </div>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
