<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Form,
  FormLayout,
  Modal,
  TextInput,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const redirectData = computed<string>(() => usePage().props.redirectData as string);
const hasRedirectData = computed<boolean>(() => !!redirectData.value);

function closeApiKeyCopyModal(): void {
  router.reload();
}
</script>

<template>
  <Modal
    :open="hasRedirectData"
    :title="$t('panel.settings:integrations:api-keys:copy-api-key')"
    @close="closeApiKeyCopyModal"
  >
    <Form>
      <FormLayout>
        <Banner :variant="BannerVariant.Info">
          <span>{{ $t('panel.settings:integrations:api-keys:make-sure-to-copy') }}</span>
        </Banner>

        <TextInput
          allow-copy
          disabled
          :model-value="redirectData"
          :label="$t('panel.settings:integrations:api-key')"
        />
      </FormLayout>
    </Form>
  </Modal>
</template>
