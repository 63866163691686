<script lang="ts" setup>
import {
  Button,
  ButtonType,
  ButtonVariant,
  Form,
  FormLayout,
  InputLabel,
  Link,
  Modal,
  ModalSize,
  Select,
  TextInput,
  TextInputFieldTypes,
  TextInputIconPosition,
  TextStyle,
  Toggle,
} from '@app/panel/Components';
import { useForm } from '@inertiajs/vue3';
import * as Types from '@app/panel/types/generated';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { CubeIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import { PasswordConfirmation } from '@app/panel/Containers';
import TextContainer from '@app/customer/Components/TextContainer/components/TextContainer.vue';

type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;

const props = defineProps<{
  open: boolean;
  form: FormShippingMethodsViewModel['form'];
  allCountries: FormShippingMethodsViewModel['allCountries'];
  shippingMethodTypes: FormShippingMethodsViewModel['shippingMethodTypes'];
  returnAddresses: FormShippingMethodsViewModel['returnAddresses'];
  shippingInstructions: FormShippingMethodsViewModel['shippingInstructions'];
  selectedContract: FormShippingMethodsViewModel['selectedContract'];
  currentEditingFormShippingMethod: FormShippingMethodsViewModel['currentEditingFormShippingMethod'];
  currentEditingShippingMethod: FormShippingMethodsViewModel['currentEditingShippingMethod'];
}>();

const { t } = useI18n();

function closeModal(): void {
  const endpoint = route('panel.settings.forms.shipping-methods', {
    form: props.form,
    contract: props.selectedContract.id,
  });

  router.get(endpoint);
}

function round(num: number) {
  return Math.round(num * 100) / 100;
}

const shippingMethodForm = useForm({
  name: props.currentEditingShippingMethod?.name ?? '',
  from_country_id: props.currentEditingShippingMethod?.fromCountry.id,
  return_addresses_id: props.currentEditingFormShippingMethod?.returnAddress.id,
  minimal_weight: round(props.currentEditingShippingMethod?.fromWeightRaw / 1000),
  maximum_weight: round(props.currentEditingShippingMethod?.toWeightRaw / 1000),
  minimum_width: round(props.currentEditingShippingMethod?.minWidthRaw / 10),
  minimum_height: round(props.currentEditingShippingMethod?.minHeightRaw / 10),
  minimum_length: round(props.currentEditingShippingMethod?.minLengthRaw / 10),
  maximum_width: round(props.currentEditingShippingMethod?.maxWidthRaw / 10),
  maximum_height: round(props.currentEditingShippingMethod?.maxHeightRaw / 10),
  maximum_length: round(props.currentEditingShippingMethod?.maxLengthRaw / 10),
  type: props.currentEditingShippingMethod?.type,
  is_printerless: props.currentEditingShippingMethod?.isPrinterless,
  shipping_instruction_id: props.currentEditingFormShippingMethod?.shippingInstruction?.id,
  is_preferred: props.currentEditingFormShippingMethod?.isPreferred ?? false,
  price: props.currentEditingFormShippingMethod?.priceMajor,
});

function submit() {
  shippingMethodForm.put(route('panel.settings.forms.custom-shipping-methods.update', {
    form: props.form,
    shippingMethod: props.currentEditingShippingMethod,
  }), {
    preserveState: (item) => {
      // should preserve state if there are any errors
      return Object.keys(item.props.errors).length > 0;
    },
  });
}

const shippingMethodTypeOptions = computed(() => {
  return props.shippingMethodTypes.filter((shippingMethodType) => {
    return shippingMethodType !== 'manual';
  }).map((shippingMethodType) => {
    return {
      label: t(`panel.shipping-method-type:${shippingMethodType}`),
      value: shippingMethodType,
    };
  });
});

const allCountryOptions = computed(() => {
  return props.allCountries.map((country) => {
    return {
      label: country.name,
      value: country.id,
    };
  });
});

const returnAddressesOptions = computed(() => {
  return props.returnAddresses.map((returnAddress) => {
    return {
      label: returnAddress.name,
      value: returnAddress.id,
    };
  });
});

const shippingInstructionOptions = computed(() => {
  return [
    ...props.shippingInstructions.map((instruction) => {
      return {
        label: instruction.name,
        value: instruction.id,
      };
    }),
  ];
});

function deleteShippingMethod() {
  const deleteRoute = route('panel.settings.forms.custom-shipping-methods.delete', {
    form: props.form,
    shippingMethod: props.currentEditingShippingMethod,
    contract: props.selectedContract.id,
  });
  shippingMethodForm.delete(deleteRoute, {
    preserveState: false,
  });
}
</script>

<template>
  <Modal
    :open="open"
    :size="ModalSize.Large"
    icon-color="blue"
    :title="$t('panel.forms:shipping-methods:edit-custom:title', { name: currentEditingFormShippingMethod?.shippingMethod.name })"
    @close="closeModal"
  >
    <template #icon>
      <CubeIcon class="w-5" />
    </template>

    <TextContainer>
      <i18n-t
        keypath="panel.forms:shipping-methods:edit-custom:with-contract:description"
        for="panel.forms:shipping-methods:edit-custom:with-contract:description"
      >
        <template #guide>
          <Link
            native
            target="_blank"
            href="https://docs.returnless.com/docs/product-guide/38icogh3qauac-shipping-methods"
          >
            {{ $t('panel.forms:shipping-methods:edit-custom:with-contract:description:guide') }}
          </Link>
        </template>
      </i18n-t>
    </TextContainer>

    <Form
      id="createShippingMethod"
      @submit="submit"
    >
      <FormLayout>
        <FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.name"
              :label="$t('panel.forms:shipping-methods:edit-custom:name')"
              :error="shippingMethodForm.errors.name"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="2">
          <FormLayout>
            <Select
              v-model="shippingMethodForm.from_country_id"
              :label="$t('panel.forms:shipping-methods:edit-custom:from-country')"
              :options="allCountryOptions"
              :error="shippingMethodForm.errors.from_country_id"
            />
          </FormLayout>
          <FormLayout>
            <Select
              v-model="shippingMethodForm.return_addresses_id"
              :label="$t('panel.forms:shipping-methods:edit-custom:return-address')"
              :options="returnAddressesOptions"
              :error="shippingMethodForm.errors.return_addresses_id"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimal_weight"
              :label="$t('panel.forms:shipping-methods:edit-custom:minimal-weight')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.minimal_weight"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_weight"
              :label="$t('panel.forms:shipping-methods:edit-custom:maximum-weight')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.maximum_weight"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="3">
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimum_width"
              :label="$t('panel.forms:shipping-methods:edit-custom:minimum-width')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.minimum_width"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimum_height"
              :label="$t('panel.forms:shipping-methods:edit-custom:minimum-height')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.minimum_height"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.minimum_length"
              :label="$t('panel.forms:shipping-methods:edit-custom:minimum-length')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.minimum_length"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="3">
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_width"
              :label="$t('panel.forms:shipping-methods:edit-custom:maximum-width')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.maximum_width"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_height"
              :label="$t('panel.forms:shipping-methods:edit-custom:maximum-height')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.maximum_height"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.maximum_length"
              :label="$t('panel.forms:shipping-methods:edit-custom:maximum-length')"
              :type="TextInputFieldTypes.Number"
              :error="shippingMethodForm.errors.maximum_length"
            />
          </FormLayout>
        </FormLayout>
        <FormLayout :columns="2">
          <FormLayout>
            <Select
              v-model="shippingMethodForm.type"
              :label="$t('panel.forms:shipping-methods:edit-custom:type')"
              :options="shippingMethodTypeOptions"
              :error="shippingMethodForm.errors.type"
            />
          </FormLayout>
          <FormLayout>
            <div class="flex flex-col">
              <InputLabel
                class="shrink-0"
                :label="$t('panel.settings:general:shipping-methods:printerless:title')"
              />
              <div class="flex-1 flex items-center">
                <Toggle
                  v-model="shippingMethodForm.is_printerless"
                  :title="$t('panel.settings:general:shipping-methods:printerless:toggle')"
                />
              </div>
            </div>
          </FormLayout>
          <FormLayout>
            <Select
              v-model="shippingMethodForm.shipping_instruction_id"
              :label="$t('panel.settings:general:shipping-methods:shipping-instruction')"
              :options="shippingInstructionOptions"
              :nullable-label="$t('panel.settings:general:shipping-methods:shipping-instruction:nullable-label')"
              :error="shippingMethodForm.errors.shipping_instruction_id"
            />
          </FormLayout>
          <FormLayout>
            <TextInput
              v-model="shippingMethodForm.price"
              :icon-position="TextInputIconPosition.Left"
              :label="$t('panel.forms:shipping-methods:edit-custom:price')"
              :error="shippingMethodForm.errors.price"
              :type="TextInputFieldTypes.Number"
              allow-decimal
            >
              <template #icon>
                <TextStyle>
                  {{
                    currentEditingFormShippingMethod?.shippingMethod.fromCountry.currencySymbol ?? currentEditingShippingMethod.fromCountry.currencySymbol
                  }}
                </TextStyle>
              </template>
            </TextInput>
          </FormLayout>
          <FormLayout>
            <div class="flex flex-col">
              <InputLabel
                class="shrink-0"
                :label="$t('panel.settings:general:shipping-methods:preferred:title')"
              />
              <div class="flex-1 flex items-center">
                <Toggle
                  v-model="shippingMethodForm.is_preferred"
                  :title="$t('panel.settings:general:shipping-methods:preferred:toggle')"
                />
              </div>
            </div>
          </FormLayout>
        </FormLayout>
      </FormLayout>
    </Form>
    <template
      #actions
    >
      <div class="flex w-full justify-between">
        <Button
          :loading="shippingMethodForm.processing"
          @click="closeModal"
        >
          {{ $t('panel.forms:shipping-methods:edit-custom:cancel') }}
        </Button>

        <div class="flex space-x-3 items-center">
          <PasswordConfirmation
            @confirmed="deleteShippingMethod"
          >
            <Button
              :variant="ButtonVariant.Critical"
              plain
            >
              {{ $t('panel.forms:shipping-methods:edit-custom:delete') }}
            </Button>
          </PasswordConfirmation>
          <Button
            form="createShippingMethod"
            :variant="ButtonVariant.Primary"
            :type="ButtonType.Submit"
          >
            {{ $t('panel.forms:shipping-methods:edit-custom:update') }}
          </Button>
        </div>
      </div>
    </template>
  </Modal>
</template>
