<script lang="ts" setup>
import { inject } from 'vue';
import * as Types from '@app/panel/types/generated';
import OrderLineItem from './OrderLineItem.vue';

defineProps({
  children: {
    type: Array,
    default: () => ([]),
  },

  subtractDepreciation: {
    type: Boolean,
    default: false,
  },
});

const returnOrderItems = inject<Types.App.Models.ViewModels.ReturnOrderItemViewModel[]>('returnOrderItems');
</script>

<template>
  <div
    v-for="item in returnOrderItems"
    :key="item.id"
    class="divide-y divide-slate-200"
  >
    <OrderLineItem
      :item="item"
      :subtract-depreciation="subtractDepreciation"
    />
  </div>
</template>
