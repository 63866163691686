<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  FormLayout,
  Heading,
  Modal,
  Select,
  TextInput,
  TextInputFieldTypes,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import { App, type Types } from '@app/shared/types/generated-v2';
import {
  BookOpenIcon,
  ChevronLeftIcon,
  ShieldCheckIcon,
} from '@heroicons/vue/24/outline';
import { useForm } from '@inertiajs/vue3';
import { onMounted, PropType } from 'vue';

type IntegrationViewModel = Types['App.Models.ViewModels.PlatformIntegrationViewModel'];

type TenantViewModel = Types['App.Models.ViewModels.TenantViewModel'];

const ConnectionType = App.Services.Integration.PlatformService.Drivers.Salesupply.Enums.ConnectionType;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'verify'): void;
}>();

const form = useForm({
  title: '',
  description: '',
  basic_auth_username: '',
  basic_auth_password: '',
  shop_id: '',
  connection_type: ConnectionType.OMS,
});

const {
  currentStep,
  firstStep,
  lastStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function verify(): void {
  form.submit('post', route('panel.integration.salesupply.save-credentials'), {
    onSuccess: () => emit('verify'),
  });
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}

const connectionTypeOptions = [
  { value: ConnectionType.OMS, label: 'OMS' },
  { value: ConnectionType.CMS, label: 'CMS' },
];
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.dashboard.integrations.salesupply.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <div v-if="currentStep === 1">
      <FormLayout>
        <FormLayout>
          <TextInput
            v-model="form.title"
            label="Title"
            :error="form.errors.title"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.description"
            label="Description"
            :error="form.errors.description"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_username"
            label="Basic auth username"
            :error="form.errors.basic_auth_username"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.basic_auth_password"
            label="Basic auth password"
            :type="TextInputFieldTypes.Password"
            :error="form.errors.basic_auth_password"
          />
        </FormLayout>

        <FormLayout>
          <TextInput
            v-model="form.shop_id"
            label="Shop ID"
            :error="form.errors.shop_id"
          />
        </FormLayout>

        <FormLayout>
          <Select
            v-model="form.connection_type"
            :error="form.errors.connection_type"
            label="Connection type"
            :options="connectionTypeOptions"
          />
        </FormLayout>
      </FormLayout>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.salesupply.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading || form.processing"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.salesupply.activate_integration_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
