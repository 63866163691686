<script lang="ts" setup>
import {
  computed,
  PropType,
  provide,
  watch,
} from 'vue';
import {
  Banner,
  BannerVariant,
  Button,
  ButtonVariant,
  DataTable,
  DraggableIcon,
  Page,
  Select,
  Stack,
  StackItem,
  StackSpacing,
  TextStyle,
  Toggle,
} from '@app/panel/Components';
import { Card, CardDescription, CardHeader, CardSection, CardTitle } from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';
import { useDragAndDrop } from '@app/panel/Composables/useDragAndDrop';
import { PageNavigation } from './partials';
import { useI18n } from 'vue-i18n';
import * as Types from '@app/panel/types/generated';
import { router } from '@inertiajs/vue3';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const { t } = useI18n();

const props = defineProps({
  form: {
    type: Object as PropType<Types.App.Models.ViewModels.FormViewModel>,
    required: true,
  },

  returnReasons: {
    type: Array as PropType<Types.App.Models.ViewModels.ReturnReasonViewModel[]>,
    required: true,
  },

  allReturnReasons: {
    type: Array as PropType<Types.App.Models.ViewModels.ReturnReasonViewModel[]>,
    required: true,
  },
});

const headings = [{
  text: t('panel.settings.forms.list_forms.settings.return_reasons.data_table.headings.column_one'),
  value: 'name',
}];

const items = computed(() => {
  return props.returnReasons.map(function (returnReason: Types.App.Models.ViewModels.ReturnReasonViewModel) {
    return {
      id: returnReason.id,
      name: returnReason.label,
    };
  });
});

const returnReasonOptions = computed(() => {
  return props.allReturnReasons
    .filter((returnReason: Types.App.Models.ViewModels.ReturnReasonViewModel) => {
      return items.value.find((i) => i.id === returnReason.id) === undefined;
    })
    .map((returnReason: Types.App.Models.ViewModels.ReturnReasonViewModel) => {
      return {
        value: returnReason.id,
        label: returnReason.label,
      };
    });
});

const tableId = useUniqueId('table');
const { sortedItems } = useDragAndDrop(tableId, items);

watch(sortedItems, () => {
  const items = sortedItems.value.map((item) => {
    return item.id;
  });

  router.put(route('panel.settings.forms.sort-return-reasons', { form: props.form }), { items }, {
    preserveScroll: true,
    preserveState: false,
  });
});

provide('form', props.form);

function removeReturnReason(returnReason: Types.App.Models.ViewModels.ReturnReasonViewModel): void {
  router.delete(
    route('panel.settings.forms.remove-return-reason', { form: props.form, returnReason }),
  );
}

function addReturnReason(reason: string): void {
  router.post(route('panel.settings.forms.add-return-reason', { form: props.form }), { reason }, {
    preserveScroll: true,
  });
}

function setRandomReasonsOrder(isRandomOrder: boolean): void {
  router.post(route('panel.settings.forms.random-reasons-order', {
    form: props.form,
  }), {
    is_random_order: isRandomOrder,
  });
}
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.return_reasons.head_title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Card>
        <CardHeader>
          <CardTitle :card-help="getHelpscoutArticleBeacon('form.return-reasons.return-reasons')">
            {{ $t('panel.settings.forms.list_forms.settings.return_reasons.card_title') }}
          </CardTitle>
          <CardDescription>{{ $t('panel.settings.forms.list_forms.settings.return_reasons.card_subtitle') }}</CardDescription>
        </CardHeader>
        <CardSection>
          <Stack
            vertical
            :spacing="StackSpacing.ExtraLoose"
          >
            <Toggle
              :model-value="form.isRandomReasonsOrder"
              :title="$t('panel.settings.forms.list_forms.settings.return_reasons.random_order_label')"
              @update:model-value="setRandomReasonsOrder"
            />

            <StackItem>
              <Banner :variant="BannerVariant.Info">
                {{ $t('panel.settings.forms.list_forms.settings.return_reasons.info_banner') }}
              </Banner>
            </StackItem>

            <StackItem>
              <Stack vertical>
                <StackItem>
                  <Select
                    :label="$t('panel.settings.forms.list_forms.settings.return_reasons.select_label')"
                    :options="returnReasonOptions"
                    @update:model-value="addReturnReason"
                  />
                </StackItem>

                <StackItem>
                  <DataTable
                    :id="tableId"
                    :headings="headings"
                    :items="items"
                  >
                    <template #name="{ item }">
                      <div class="flex">
                        <DraggableIcon v-if="! form.isRandomReasonsOrder" />

                        <TextStyle>
                          {{ item.name }}
                        </TextStyle>
                      </div>
                    </template>

                    <template #actions="{ item }">
                      <Button
                        plain
                        :variant="ButtonVariant.Critical"
                        @click="removeReturnReason(item)"
                      >
                        {{
                          $t('panel.settings.forms.list_forms.settings.return_reasons.data_table.actions.remove_return_reason_button')
                        }}
                      </Button>
                    </template>
                  </DataTable>
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
        </CardSection>
      </Card>
    </Page>
  </AppLayout>
</template>
