<script lang="ts" setup>
import { provide } from 'vue';
import {
  Button,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardLayout,
  CardSection,
  CardTitle,
  Dialog,
  DialogCancelButton,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  Link,
  Page,
  Select,
  SelectOption,
  TextStyle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../partials';
import { router, useForm } from '@inertiajs/vue3';
import FeatureLimitWarning from '@app/panel/Pages/Settings/Components/FeatureLimitWarning.vue';
import { FormIntegrationsPageViewModel } from './types';
import IntegrationCard from './IntegrationCard.vue';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

const props = defineProps<FormIntegrationsPageViewModel>();

const integrationSettingForm = useForm({
  platform_integration_tenant_id: props.primaryPlatformIntegrationTenantId,
});

function save() {
  integrationSettingForm.put(route('panel.settings.forms.update-primary-integration', {
    form: props.formId,
    force_disable: false,
  }), {
    preserveScroll: true,
    preserveState: true,
    replace: true,
  });
}

function forceSavePrimaryPlatformIntegration() {
  integrationSettingForm.put(route('panel.settings.forms.update-primary-integration', {
    form: props.formId,
    force_disable: true,
  }), {
    preserveScroll: true,
    preserveState: true,
    replace: true,
  });
}

function closeNonApplicableConditionsModal() {
  router.visit(route('panel.settings.forms.integrations', { form: props.formId }), {
    replace: true,
    preserveScroll: true,
  });
}

provide('form', { id: props.formId });
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.integrations.head')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <div class="flex flex-col space-y-6">
        <CardLayout>
          <FeatureLimitWarning :feature-usage-info="featureUsageInfo" />

          <Card>
            <CardHeader>
              <CardTitle :card-help="getHelpscoutArticleBeacon('form.integrations.default-integration')">
                {{ $t('panel.settings:integrations:features:default-search-integration') }}
              </CardTitle>
              <CardDescription>{{ $t('panel.settings:integrations:features:description') }}</CardDescription>
            </CardHeader>
            <CardSection>
              <Select
                v-model="integrationSettingForm.platform_integration_tenant_id"
                :name="$t('panel.settings:integrations:primary-integration')"
                :label="$t('panel.settings:integrations:primary-integration')"
                :placeholder="$t('panel.settings:integrations:select-integration')"
                :error="integrationSettingForm.errors.platform_integration_tenant_id"
                label-hidden
              >
                <SelectOption
                  v-for="platformIntegrationTenantOption in platformIntegrationTenantOptions"
                  :key="platformIntegrationTenantOption.value"
                  :value="platformIntegrationTenantOption.value"
                >
                  {{ platformIntegrationTenantOption.label }}
                </SelectOption>
              </Select>
            </CardSection>
            <CardFooter>
              <Button
                :loading="integrationSettingForm.processing"
                :disabled="!integrationSettingForm.isDirty"
                @click="() => save(false)"
              >
                {{ $t('panel.settings:integrations:features:save') }}
              </Button>
            </CardFooter>
          </Card>
          <IntegrationCard
            v-for="formPlatformIntegrationCard in formPlatformIntegrationCards"
            :key="formPlatformIntegrationCard.id"
            :form-id="formId"
            :platform-integration-tenant-id="formPlatformIntegrationCard.id"
            :form-platform-integration-card="formPlatformIntegrationCard"
            :show-confirmation-dialog="formPlatformIntegrationCard.id === switchFeaturesForPlatformIntegration"
            :non-applicable-features-modal="nonApplicableFeaturesModal"
            :is-primary-integration="formPlatformIntegrationCard.id === integrationSettingForm.platform_integration_tenant_id"
          />
        </CardLayout>
      </div>
    </Page>
  </AppLayout>

  <Dialog
    :open="nonApplicableConditionsModal !== null"
    @cancel="closeNonApplicableConditionsModal"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('panel.settings:integrations:primary-integration-change-title') }}</DialogTitle>
        <DialogDescription>
          {{ $t('panel.settings:integrations:primary-integration-change-description') }}
        </DialogDescription>
      </DialogHeader>

      <DialogSection>
        <div class="flex flex-col space-y-3">
          <div
            v-if="nonApplicableConditionsModal!.returnRules.length > 0"
            class="flex flex-col space-y-1"
          >
            <TextStyle strong>
              {{ $t('panel.settings:integrations:primary-integration-return-rules-title') }}
            </TextStyle>
            <div class="flex flex-col items-start">
              <Link
                v-for="returnRule in nonApplicableConditionsModal!.returnRules"
                :key="returnRule.href"
                :href="returnRule.href"
                external
              >
                {{ returnRule.label }}
              </Link>
            </div>
          </div>
          <div
            v-if="nonApplicableConditionsModal!.formAutomations.length > 0"
            class="flex flex-col space-y-1"
          >
            <TextStyle strong>
              {{ $t('panel.settings:integrations:primary-integration-automations-title') }}
            </TextStyle>
            <div class="flex flex-col items-start">
              <Link
                v-for="formAutomation in nonApplicableConditionsModal!.formAutomations"
                :key="formAutomation.href"
                :href="formAutomation.href"
                external
              >
                {{ formAutomation.label }}
              </Link>
            </div>
          </div>
        </div>
      </DialogSection>

      <DialogFooter>
        <DialogCancelButton>Cancel</DialogCancelButton>
        <Button
          variant="primary"
          @click="() => forceSavePrimaryPlatformIntegration()"
        >
          {{ $t('panel.settings:integrations:primary-integration-proceed') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
