<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Card,
  CardSection,
  List,
  ListItem,
  Overlay,
  OverlayShade,
  TextContainer,
} from '@app/panel/Components';
import { router } from '@inertiajs/vue3';

const props = defineProps({
  tenant: {
    type: Object,
    required: true,
  },

  partner: {
    type: Object,
    required: true,
  },
});

function allowAccess(): void {
  router.post(route('panel.partner.confirm-access'), {
    tenant: props.tenant.id,
    partner: props.partner.id,
  });
}

function cancelAction() {
  router.visit(route('panel.dashboard'));
}
</script>

<template>
  <Teleport to="body">
    <Overlay :shade="OverlayShade.Light">
      <div class="max-w-xl mx-auto">
        <Card
          class="border-slate-300 border"
          :title="`Grant ${partner.name} access to your account`"
        >
          <CardSection>
            <TextContainer class="mb-4">
              When you grant access to this account, the following happens:
            </TextContainer>
            <TextContainer>
              <List>
                <ListItem>
                  You allow your partner to access your account, including: Return data, General settings, Return forms,
                  etc.
                </ListItem>
                <ListItem>
                  Your subscription plan will be transferred to your partner. They will pay for your
                  subscription.
                </ListItem>
                <ListItem>All shipping charges will be charged to your partner.</ListItem>
                <ListItem>
                  You will keep receiving all contributed return costs which we collect from your customers.
                </ListItem>
              </List>
            </TextContainer>
          </CardSection>
          <template #footer>
            <ButtonGroup full-width>
              <Button
                full-width
                @click="cancelAction"
              >
                Cancel
              </Button>
              <Button
                full-width
                :variant="ButtonVariant.Primary"
                @click="allowAccess"
              >
                Allow access
              </Button>
            </ButtonGroup>
          </template>
        </Card>
      </div>
    </Overlay>
  </Teleport>
</template>
