<script lang="ts" setup>
import { AppLayout } from '@app/panel/Layouts';
import {
  Button,
  ButtonType,
  ButtonVariant,
  Card,
  CardSection,
  CardSeparator,
  Form,
  Heading,
  List,
  ListItem,
  Modal,
  Page,
  TextContainer,
  TextContainerSpacing,
  TextStyle,
  TextStyleShade,
} from '@app/panel/Components';
import { PageNavigation } from '../partials';
import {
  computed,
  provide,
  ref,
  watch,
} from 'vue';
import { App, type Types } from '@app/shared/types/generated-v2';
import { useForm } from '@inertiajs/vue3';
import { ReturnQuestion, ReturnQuestionDetail } from '../partials';
import { ReturnQuestionForm } from '@app/panel/types';
import { isEmpty, trim } from 'lodash';
import { ConditionEngine } from '@app/panel/Containers';
import { router } from '@inertiajs/vue3';
import { TagList, TagPopover } from '@app/panel/Containers';
import { useTags } from '@app/panel/Composables/useTags';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import Link from '@app/panel/Components/Link/components/Link.vue';

type ShowReturnQuestionViewModel = Types['App.Http.ViewModels.Panel.Settings.Forms.ReturnQuestions.ShowReturnQuestionViewModel'];

const props = defineProps<ShowReturnQuestionViewModel>();

const returnQuestionForm = useForm<ReturnQuestionForm>({
  is_enabled: props.returnQuestion.isEnabled,
  is_answer_required: props.returnQuestion.isAnswerRequired,
  level: props.returnQuestion.level,
  field_type: props.returnQuestion.fieldType,
  answers: !isEmpty(props.returnQuestion.answers) ? props.returnQuestion.answers : {},
  conditions: !isEmpty(props.returnQuestion.conditions) ? props.returnQuestion.conditions : {},
  questionTranslations: !isEmpty(props.returnQuestion.translations) ? props.returnQuestion.translations : {},
  descriptionTranslations: !isEmpty(props.returnQuestion.descriptionTranslations) ? props.returnQuestion.descriptionTranslations : {},
  tags: [],
});

const { activeTags, removeTag } = useTags(props.tags, returnQuestionForm, props.returnQuestion.tags);

const availableConditionTypes = computed(() => {
  return props.availableConditionTypesByLevel[returnQuestionForm.level];
});

function removeEmptyValues(obj: object): object {
  Object.keys(obj).forEach(function (key) {
    obj[key] = trim(obj[key]) === '' ? null : obj[key];
  });

  return obj;
}

function submitReturnQuestionForm(): void {
  returnQuestionForm
    .transform((form) => {
      form.questionTranslations = removeEmptyValues(form.questionTranslations);
      form.descriptionTranslations = removeEmptyValues(form.descriptionTranslations);
      return form;
    })
    .put(route('panel.settings.forms.return-questions.update', {
      form: props.form,
      returnQuestion: props.returnQuestion,
    }), { preserveState: true, preserveScroll: true });
}

watch(() => returnQuestionForm.field_type, (fieldType) => {
  const singleInputField = ['file', 'text', 'textarea'];

  if (singleInputField.includes(fieldType)) {
    returnQuestionForm.answers = {};
  }
});

watch(() => returnQuestionForm.level, (newLevel) => {
  if (newLevel === 'order') {
    Object.values(returnQuestionForm.conditions)
      .forEach((condition) => {
        if (condition.input === 'return_reason') {
          condition.input = 'question_answered';
        }
      });
  }
});

const isRemovalModalOpen = ref<boolean>(false);
const isDisabledRemovalModalOpen = ref<boolean>(false);

function openRemovalModal(): void {
  router.reload({
    only: ['associatedReturnRules', 'associatedReturnQuestions'],
    onSuccess: () => {
      if (props.associatedReturnRules.length > 0 || props.associatedReturnQuestions.length > 0) {
        isDisabledRemovalModalOpen.value = true;
      } else {
        isRemovalModalOpen.value = true;
      }
    },
  });
}

function closeRemovalModal(): void {
  isRemovalModalOpen.value = false;
  isDisabledRemovalModalOpen.value = false;
}

function removeReturnQuestion() {
  router.delete(route('panel.settings.forms.return-questions.remove', {
    form: props.form,
    returnQuestion: props.returnQuestion,
  }), { preserveState: true });
}

const returnQuestions = computed(() => {
  return returnQuestionForm.level === App.Enums.ReturnQuestionLevel.OrderItem
    ? props.returnOrderItemQuestions
    : props.returnOrderQuestions;
});

provide('countries', props.countries);
provide('form', props.form);
provide('locales', props.locales);
provide('defaultLocale', props.defaultLocale);
provide('returnForm', returnQuestionForm);
</script>

<template>
  <AppLayout :title="$t('panel.settings.forms.list_forms.settings.return_questions.edit.title')">
    <Page>
      <template #page-navigation>
        <PageNavigation />
      </template>

      <Form @submit="submitReturnQuestionForm">
        <Card
          :divide="false"
          :primary-footer-action="{
            content: $t('panel.settings.forms.list_forms.settings.return_questions.edit.save'),
            type: ButtonType.Submit,
            loading: returnQuestionForm.processing,
          }"
          :secondary-footer-actions="[{
            content: $t('panel.settings.forms.list_forms.settings.return_questions.edit.remove'),
            variant: ButtonVariant.Critical,
            plain: true,
            callback: openRemovalModal,
          }]"
        >
          <div class="px-6 pt-6">
            <div class="flex justify-between">
              <div class="flex space-x-4">
                <Heading>
                  <span class="whitespace-nowrap">
                    {{ $t('panel.settings.forms.list_forms.settings.return_questions.edit.title') }}
                  </span>
                </Heading>

                <TagList
                  :tags="activeTags"
                  @remove="removeTag"
                />
              </div>

              <TagPopover
                v-model="returnQuestionForm.tags"
                :tags="tags"
              />
            </div>
          </div>

          <CardSection>
            <ReturnQuestionDetail :available-field-types="availableFieldTypes" />
          </CardSection>

          <CardSeparator />

          <CardSection>
            <ReturnQuestion />
          </CardSection>

          <CardSeparator />

          <CardSection>
            <ConditionEngine
              :available-condition-types="availableConditionTypes"
              :condition-type-map="conditionTypeMap"
              :return-reasons="returnReasons"
              :return-questions="returnQuestions"
            />
          </CardSection>
        </Card>
      </Form>
    </Page>
  </AppLayout>

  <Modal
    :open="isRemovalModalOpen"
    :title="$t('panel.forms:return-questions:about-to-remove')"
    icon-color="red"
    @close="closeRemovalModal"
  >
    <template #icon>
      <ExclamationTriangleIcon class="size-6" />
    </template>

    <TextContainer>
      {{ $t('panel.forms:return-questions:removal-description') }}
    </TextContainer>

    <template #actions>
      <Button @click="closeRemovalModal">
        {{ $t('panel.forms:return-questions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Critical"
        @click="removeReturnQuestion"
      >
        {{ $t('panel.forms:return-questions:proceed-and-remove') }}
      </Button>
    </template>
  </Modal>

  <Modal
    :open="isDisabledRemovalModalOpen"
    :title="$t('panel.forms:return-questions:remove-disabled:title')"
    icon-color="red"
    @close="closeRemovalModal"
  >
    <template #icon>
      <ExclamationTriangleIcon class="size-6" />
    </template>

    <TextContainer :spacing="TextContainerSpacing.Loose">
      <TextStyle :shade="TextStyleShade.Subdued">
        <p>
          {{ $t('panel.forms:return-questions:remove-disabled:body') }}
        </p>
      </TextStyle>

      <TextStyle :shade="TextStyleShade.Subdued">
        <List>
          <ListItem
            v-for="usedReturnQuestion in associatedReturnQuestions"
            :key="usedReturnQuestion.id"
          >
            <Link
              :href="$route('panel.settings.forms.return-questions.show', { form: form, returnQuestion: usedReturnQuestion })"
              target="_blank"
              native
            >
              {{ usedReturnQuestion.question }}
            </Link>
          </ListItem>
          <ListItem
            v-for="usedReturnRule in associatedReturnRules"
            :key="usedReturnRule.id"
          >
            <Link
              :href="$route('panel.settings.form.return-rules.show', { form: form, returnRule: usedReturnRule })"
              target="_blank"
              native
            >
              {{ usedReturnRule.name }}
            </Link>
          </ListItem>
        </List>
      </TextStyle>
    </TextContainer>

    <template #actions>
      <Button
        :variant="ButtonVariant.Default"
        @click="closeRemovalModal"
      >
        {{ $t('panel.forms:return-questions:remove-disabled:close') }}
      </Button>
    </template>
  </Modal>
</template>
