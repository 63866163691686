<script lang="ts" setup>
import { useForm } from '@inertiajs/vue3';
import { SelectOption } from '@app/customer/Components';
import { CardSection, Form, FormLayout, Select, Stack, StackItem, StackSpacing } from '@app/panel/Components';
import { Button, Card, CardFooter, CardHeader, CardTitle } from '@returnless/focus-ui';
import * as Types from '@app/panel/types/generated';
import { getHelpscoutArticleBeacon } from '@app/panel/Utils';

type FormShippingSettingsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingSettingsViewModel;

const props = defineProps<{
  form: FormShippingSettingsViewModel['form'];
  shippingLabelReferenceTypes: SelectOption[];
  returnOrderMetadataKeys: SelectOption[];
}>();

const shippingLabelsForm = useForm({
  shipping_label_reference_type: props.form.shippingLabelReferenceType,
  shipping_label_reference_type_key: props.form.shippingLabelReferenceTypeKey,
});

function saveShippingLabels() {
  shippingLabelsForm.put(route('panel.settings.forms.shipping-settings.labels.update', { form: props.form }));
}

</script>
<template>
  <Form @submit="saveShippingLabels">
    <Stack
      vertical
      :spacing="StackSpacing.ExtraLoose"
    >
      <StackItem>
        <Card>
          <CardHeader>
            <CardTitle :card-help="getHelpscoutArticleBeacon('form.shipping-settings.shipping-labels')">
              {{ $t('panel.settings.forms.list_forms.settings.shipping_settings.shipping_labels') }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout :columns="2">
              <Select
                v-model="shippingLabelsForm.shipping_label_reference_type"
                :options="shippingLabelReferenceTypes"
                :error="shippingLabelsForm.errors.shipping_label_reference_type"
                :label="$t('panel.settings.forms.list_forms.settings.shipping_settings.shipping_label_reference_type')"
              />

              <div class="mt-6">
                <Select
                  v-if="shippingLabelsForm.shipping_label_reference_type === 'return_order_metadata'"
                  v-model="shippingLabelsForm.shipping_label_reference_type_key"
                  :options="returnOrderMetadataKeys"
                  :error="shippingLabelsForm.errors.shipping_label_reference_type_key"
                />
              </div>
            </FormLayout>
          </CardSection>
          <CardFooter>
            <Button
              type="submit"
              :loading="shippingLabelsForm.processing"
              :disabled="!shippingLabelsForm.isDirty"
            >
              {{ $t('panel.settings.forms.list_forms.settings.shipping_settings.save_form') }}
            </Button>
          </CardFooter>
        </Card>
      </StackItem>
    </Stack>
  </Form>
</template>
