<script setup lang="ts">
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline';
import {
  Alert,
  AlertDescription,
  Button as FocusUIButton,
  Form,
  FormLayout,
  TextField,
} from '@returnless/focus-ui';
import {
  Button,
  ButtonIconPosition,
  ButtonVariant,
  InputLabel,
  Modal,
  RadioButton,
} from '@app/panel/Components';
import { parseUrlParameters } from '@app/panel/Pages/Returns/helpers/url';
import { useForm, usePage } from '@inertiajs/vue3';
import { computed, reactive, ref } from 'vue';

const globalProps = computed<any>(() => usePage().props);

const exportModalOpen = ref(false);
const exportStatus = ref<'pending' | 'loading' | 'successful'>('pending');
const exportDateRange = ref<string>('');
const recipient = ref<string>(globalProps.value.user.email);
const generalErrors = ref({});
const orderDate = reactive({
  from: '',
  to: '',
});

function openExportModal(): void {
  exportModalOpen.value = true;
}

function closeExportModal(): void {
  resetExportReturnsModal();

  exportModalOpen.value = false;
}

async function initiateExport(): Promise<void> {
  exportStatus.value = 'loading';

  const parameters = parseUrlParameters(window.location.href) as any; // fixme: don't use any, anywhere

  if (!parameters.filter) {
    parameters.filter = {};
  }

  if (exportDateRange.value === 'custom_range') {
    parameters.filter.order_date = {};
    parameters.filter.order_date.from = orderDate.from;
    parameters.filter.order_date.to = orderDate.to;
  } else {
    parameters.filter.date_range = exportDateRange.value;
  }

  parameters.recipient = recipient.value;

  const exportForm = useForm(parameters);

  exportForm.post(route('panel.return-orders.export'), {
    preserveState: true,
    onSuccess: () => {
      exportStatus.value = 'successful';
      generalErrors.value = {};
    },
    onError: () => {
      exportStatus.value = 'pending';
      generalErrors.value = globalProps.value.errors;
    },
  });
}

function resetExportReturnsModal(): void {
  generalErrors.value = {};
  exportDateRange.value = '';
  orderDate.from = '';
  orderDate.to = '';
}
</script>

<template>
  <Button
    :icon-position="ButtonIconPosition.Left"
    :variant="ButtonVariant.Default"
    @click="openExportModal"
  >
    <template #icon>
      <ArrowDownTrayIcon class="h-5 w-5" />
    </template>

    {{ $t('panel.returns.page_actions.export_button') }}
  </Button>

  <Modal
    :open="exportModalOpen"
    :title="$t('panel.returns.page_actions.export_modal')"
    @close="closeExportModal"
  >
    <template #icon>
      <ArrowDownTrayIcon class="h-6 w-6" />
    </template>

    <Form>
      <FormLayout>
        <Alert
          v-if="'general' in generalErrors"
          variant="warning"
          :dismissible="false"
        >
          <AlertDescription>
            {{ generalErrors.general }}
          </AlertDescription>
        </Alert>
        <Alert
          v-if="exportStatus === 'successful'"
          variant="informative"
          :dismissible="false"
        >
          <AlertDescription>
            {{ $t('panel.return-orders:exporting-return-orders') }}
          </AlertDescription>
        </Alert>

        <TextField
          v-model="recipient"
          name="recipient"
          type="email"
          :label="$t('panel.returns.page_actions.export_recipient_label')"
        />

        <div class="flex flex-col space-y-1">
          <InputLabel :label="$t('panel.returns.page_actions.export_input_label')" />

          <RadioButton
            v-model="exportDateRange"
            name="date_range"
            value="today"
            :label="$t('panel.returns.page_actions.today_radio_button')"
          />

          <RadioButton
            v-model="exportDateRange"
            name="date_range"
            value="current_month"
            :label="$t('panel.returns.page_actions.current_month_radio_button')"
          />

          <RadioButton
            v-model="exportDateRange"
            :label="$t('panel.returns.page_actions.previous_7_days_radio_button')"
            name="date_range"
            value="previous_7_days"
          />

          <RadioButton
            v-model="exportDateRange"
            :label="$t('panel.returns.page_actions.previous_month_radio_button')"
            value="previous_month"
            name="date_range"
          />

          <RadioButton
            v-model="exportDateRange"
            :label="$t('panel.returns.page_actions.all_radio_button')"
            value="all"
            name="date_range"
          />

          <RadioButton
            v-model="exportDateRange"
            :label="$t('panel.returns.page_actions.custom_range_radio_button')"
            value="custom_range"
            name="date_range"
          />
        </div>

        <div
          v-if="exportDateRange === 'custom_range'"
          class="ml-6 -mt-3"
        >
          <FormLayout :columns="2">
            <TextField
              v-model="orderDate.from"
              :label="$t('panel.returns.page_actions.from')"
              name="order_date_from"
              type="date"
              label-hidden
            />

            <TextField
              v-model="orderDate.to"
              :label="$t('panel.returns.page_actions.to')"
              name="order_date_to"
              type="date"
              label-hidden
            />
          </FormLayout>
        </div>
      </FormLayout>
    </Form>

    <template #actions>
      <FocusUIButton
        variant="secondary"
        @click="closeExportModal"
      >
        {{ $t('panel.global:actions:cancel') }}
      </FocusUIButton>

      <FocusUIButton
        variant="primary"
        :loading="exportStatus === 'loading'"
        @click="initiateExport"
      >
        {{ $t('panel.returns.page_actions.generate_export_button') }}
      </FocusUIButton>
    </template>
  </Modal>
</template>
