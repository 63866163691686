<script lang="ts" setup>
import { computed, provide, ref, toRef } from 'vue';
import { router, useForm } from '@inertiajs/vue3';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  CardLayout,
  CardSection,
  CardTitle,
  Form,
  FormLayout,
  Link,
  Page,
  PageHeader,
  PageTitle,
  TextField,
  TextStyle,
} from '@returnless/focus-ui';
import { PasswordConfirmation } from '@app/panel/Containers';
import { AppLayout } from '@app/panel/Layouts';
import { FieldRenderer, Mapping, PageNavigation } from '../../partials';
import { get, keys, transform } from 'lodash';
import { App } from '@app/shared/types/generated-v2';
import { useIntegrations } from '@app/panel/Composables/useIntegrations';
import { useI18n } from 'vue-i18n';
import { usePrecomputedValues } from '@app/panel/Composables/usePrecomputedValues';
import { EditPlatformIntegrationPageViewModel } from './types';
import { DataTable } from '@app/panel/Components';
import { DependencyWarningDialog, useDependencyWarning } from '@app/panel/Containers/DependencyWarning';

const props = defineProps<EditPlatformIntegrationPageViewModel>();

const { showDependencyWarning } = useDependencyWarning();

const { t } = useI18n();

const returnStatusOptions = computed<Record<string, string>>(() => {
  return props.returnStatuses.reduce((acc, status) => {
    acc[status.id] = status.label;
    return acc;
  }, {});
});

const externalReturnStatuses = computed<Record<string, string>>(() => {
  const returnStatuses = get(props.credentials, 'return_statuses');

  // Handle return statuses from credentials (just return them if they exist)
  if (returnStatuses) {
    return returnStatuses;
  }

  // Else map given external return statuses to an object and return
  return props.externalReturnStatuses.reduce((acc, externalStatus) => {
    acc[externalStatus.id] = externalStatus.label;
    return acc;
  }, {});
});

const returnReasonOptions = computed<Record<string, string>>(() => {
  return props.returnReasons.reduce((acc, reason) => {
    acc[reason.id] = reason.label;
    return acc;
  }, {});
});

const externalReturnReasons = computed(() => get(props.credentials, 'return_reasons'));

const warehouses = computed(() => {
  const warehouseOptions = {};

  for (const warehouse of (get(props.credentials, 'warehouses') || [])) {
    warehouseOptions[warehouse.id] = warehouse.name;
  }

  return warehouseOptions;
});

const templateOptions = computed<Record<string, string>>(() => {
  return props.credentials?.templates || {};
});

const countryOptions = computed(() => {
  return transform(props.countries, (result, value) => {
    return result[value.isoCode] = value.name;
  });
});

const precomputedValues = computed(() => {
  return {
    returnStatuses: returnStatusOptions.value,
    externalReturnStatuses: externalReturnStatuses.value,
    returnReasons: returnReasonOptions.value,
    externalReturnReasons: externalReturnReasons.value,
    warehouses: warehouses.value,
    templates: templateOptions.value,
    countries: countryOptions.value,
    forms: props.importerFormOptions,
    externalChannels: props.externalChannels,
  };
});

function getMappingData(data, type): Record<string, null> {
  return data.reduce((acc, status) => {
    acc[status.id] = get(props.settings, `${type}_map.${status.id}`, null);
    return acc;
  }, {});
}

function getStatusColor(status: string): string {
  switch (status) {
    case App.Enums.PlatformIntegrationWebhookStatus.ACTIVE:
      return 'green';
    case App.Enums.PlatformIntegrationWebhookStatus.INACTIVE:
    case App.Enums.PlatformIntegrationWebhookStatus.UNSUPPORTED_EVENT:
      return 'red';
    default:
      return 'yellow';
  }
}

const { settingsScaffold } = useIntegrations(props.formStructure);

usePrecomputedValues(toRef(props, 'formStructure'), precomputedValues);

const form = useForm({
  ...settingsScaffold.value,
  ...props.settings,
  return_statuses_map: getMappingData(props.returnStatuses, 'return_statuses'),
  external_return_statuses_map: getMappingData(props.externalReturnStatuses, 'external_return_statuses'),
  return_reasons_map: getMappingData(props.returnReasons, 'return_reasons'),
});

function findFieldNames(field) {
  if (Array.isArray(field)) {
    return field.flatMap(findFieldNames);
  }
  return [field.key];
}

function filterFieldsByBlock(blockKey) {
  const block = props.formStructure.find((item) => item.key === blockKey);
  const fieldNames = block.fields.flatMap((field) =>
    field.type === 'layout' ? field.fields.flatMap(findFieldNames) : field.key,
  );

  return keys(form)
    .filter((fieldName) => fieldNames.includes(fieldName))
    .reduce((acc, fieldName) => ({ ...acc, [fieldName]: form[fieldName] }), {});
}

const lastBlockKey = ref(null);

function save(block: string): void {
  if (props.dependencyWarning !== null && ! form.is_enabled ) {
    showDependencyWarning(props.dependencyWarning);
    return;
  }

  form
    .transform(() => filterFieldsByBlock(block))
    .put(route('panel.settings.integrations.platform.update', {
      platformIntegrationTenant: props.platformIntegrationTenant,
      block,
    }), {
      preserveScroll: true,
      preserveState: true,
      onBefore: () => {
        lastBlockKey.value = block;
      },
      onFinish: () => {
        lastBlockKey.value = null;
      },
    });
}

function updateTenantData(): void {
  router.get(route('panel.settings.integrations.update-tenant-data', props.platformIntegrationTenant.id));
}

function recreateWebhook(webhook): void {
  router.get(route('panel.settings.integrations.recreate-webhook', {
    platformIntegrationTenant: props.platformIntegrationTenant.id,
    webhookEvent: webhook,
  }));
}

const isRemoving = ref(false);

function removeIntegration(): void {
  if (props.dependencyWarning !== null) {
    showDependencyWarning(props.dependencyWarning);
    return;
  }

  isRemoving.value = true;
  router.delete(route('panel.settings.integrations.platform.delete', props.platformIntegrationTenant));
}

function getBlockErrors(blockName: string): null | Record<string, string> {
  const scopedErrors = Object.keys(form.errors)
    .filter((key: string) => key.startsWith(blockName))
    .reduce((acc, key) => {
      return {
        ...acc,
        [key]: form.errors[key],
      };
    }, {});

  if (Object.keys(scopedErrors).length === 0) {
    return null;
  }

  return scopedErrors;
}

provide('form', form);
</script>

<template>
  <AppLayout :title="t('panel.settings.integrations.platform.page_title')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>
          {{ props.platformIntegrationTenant.platformIntegration.name + " - " + props.platformIntegrationTenant.title }}
        </PageTitle>
        <template
          v-if="showUpdateButton"
          #page-header-actions
        >
          <Button @click="updateTenantData()">
            {{ t('panel.settings.integrations.platform.settings_page.update_tenant_settings') }}
          </Button>
        </template>
      </PageHeader>
      <CardLayout>
        <template
          v-for="(section, index) in formStructure"
          :key="index"
        >
          <Form>
            <CardLayout>
              <Card v-if="section.key==='mapping'">
                <CardHeader>
                  <CardTitle>{{ t('panel.settings.integrations.platform.settings_page.mapping') }}</CardTitle>
                </CardHeader>
                <CardSection>
                  <Mapping
                    :secondary-name="platformIntegrationTenant.platformIntegration.name"
                    :section="section"
                    credentials=""
                    platform-integration-tenant=""
                    return-reasons=""
                    return-statuses=""
                  />
                </CardSection>
                <CardFooter>
                  <Button
                    :loading="form.processing"
                    variant="primary"
                    @click="save(section.key)"
                  >
                    {{ t('panel.settings.integrations.platform.settings_page.save') }}
                  </Button>
                </CardFooter>
              </Card>

              <Card v-else>
                <CardHeader>
                  <CardTitle>{{ t(section.title) }}</CardTitle>
                </CardHeader>
                <CardSection>
                  <FormLayout>
                    <FormLayout
                      v-for="field in section.fields"
                      :key="field.key"
                    >
                      <FieldRenderer
                        :field-name="field.key"
                        :fields="field.fields"
                        :label="field.label"
                        :options="field.options"
                        :placeholder="field.placeholder"
                        :validation="field.validation"
                        :default="field.default"
                        :help-text="field.help_text || null"
                        :type="field.type"
                        :title="field.title"
                        :disabled="field.disabled"
                        :nullable-label="field.nullableLabel || null"
                        :onchange-warning="field.onchange_warning || null"
                        :errors="getBlockErrors(field.key)"
                      />
                    </FormLayout>
                  </FormLayout>
                </CardSection>
                <CardFooter>
                  <Button
                    variant="primary"
                    :loading="form.processing && lastBlockKey === section.key"
                    @click="save(section.key)"
                  >
                    {{ t('panel.settings.integrations.platform.settings_page.save') }}
                  </Button>
                </CardFooter>
              </Card>
            </CardLayout>
          </Form>
        </template>

        <Card v-if="showWebhooks">
          <CardHeader>
            <CardTitle>
              {{
                t('panel.settings.integrations.platform.settings_page.webhooks.title', {integration: platformIntegrationTenant.platformIntegration.name})
              }}
            </CardTitle>
          </CardHeader>

          <CardSection>
            <DataTable
              :headings="[
                {
                  text: t('panel.settings.integrations.platform.settings_page.webhooks.id'),
                  value: 'externalId',
                },
                {
                  text: t('panel.settings.integrations.platform.settings_page.webhooks.name'),
                  value: 'name',
                },
                {
                  text: t('panel.settings.integrations.platform.settings_page.webhooks.event'),
                  value: 'event',
                },
                {
                  text: t('panel.settings.integrations.platform.settings_page.webhooks.status'),
                  value: 'status',
                },
              ]"
              :items="webhooks"
            >
              <template #status="{ item }">
                <Badge :color="getStatusColor(item.status)">
                  {{ item.status }}
                </Badge>
              </template>
              <template #actions="{ item }">
                <Button
                  v-if="item.status === 'inactive'"
                  plain
                  @click="() => recreateWebhook(item.event)"
                >
                  {{ t('panel.settings.integrations.platform.settings_page.webhooks.recreate') }}
                </Button>
              </template>
            </DataTable>
          </CardSection>
        </Card>

        <Card v-if="webhookInstruction">
          <CardHeader>
            <CardTitle>
              {{
                t('panel.settings.integrations.platform.settings_page.webhooks.instructions.title')
              }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <div class="space-y-6">
              <TextStyle>
                <TextStyle :shade="TextStyleShade.Subdued">
                  {{ webhookInstruction.instruction }}

                  <Link
                    v-if="webhookInstruction.documentationLink"
                    :href="webhookInstruction.documentationLink"
                    external
                  >
                    {{ t('panel.settings.integrations.platform.settings_page.webhooks.instructions.documentation') }}
                  </Link>
                </TextStyle>
              </TextStyle>

              <Alert
                v-if="webhookInstruction.warning"
                variant="warning"
              >
                {{ webhookInstruction.warning }}
              </Alert>

              <div v-if="!webhookInstruction.warning">
                <div
                  v-for="(webhookUrl, webhookName) in webhookInstruction.urls"
                  :key="webhookName"
                >
                  <TextField
                    :value="webhookUrl"
                    allow-copy
                    readonly
                    label=""
                    name=""
                    label-hidden
                  />
                </div>
              </div>
            </div>
          </CardSection>
        </Card>

        <Card v-if="showDelete">
          <CardHeader>
            <CardTitle>{{ t('panel.settings.integrations.platform.settings_page.remove') }}</CardTitle>
          </CardHeader>

          <CardSection>
            <TextStyle>
              {{ t('panel.settings.integrations.platform.settings_page.confirm_remove') }}
            </TextStyle>
          </CardSection>
          <CardSection
            subdued
            dense
          >
            <div class="flex justify-end">
              <PasswordConfirmation @confirmed="removeIntegration">
                <Button
                  variant="destructive"
                  :loading="isRemoving"
                >
                  {{ t('panel.settings.integrations.platform.settings_page.remove') }}
                </Button>
              </PasswordConfirmation>
            </div>
          </CardSection>
        </Card>
      </CardLayout>
    </Page>
  </AppLayout>

  <DependencyWarningDialog />
</template>
