<script lang="ts" setup>
import { computed, inject, Ref } from 'vue';
import { getComputedValue, isComputedValue } from '../helpers';
import { formatCurrency } from '@app/panel/Utils';
import { TextStyle, TextStyleShade } from '@app/panel/Components';

const props = defineProps({
  children: {
    type: Array,
    default: () => ([]),
  },

  label: {
    type: String,
    required: true,
  },

  value: {
    type: String,
    required: true,
  },

  note: {
    type: String,
    required: false,
    default: undefined,
  },
});

const computedValues = inject<Ref<object>>('precomputedValues');

const computedValue = computed((): number => {
  let value = props.value;

  if (isComputedValue(value)) {
    value = getComputedValue(computedValues, value);
  }

  return parseFloat(value);
});

const locale = inject<Ref<string>>('userLocale');

const formattedValue = computed(() => {
  return formatCurrency(computedValue.value, locale.value);
});

const isNegativeNumber = computed(() => computedValue.value < 0);

function isShowNote(): boolean {
  return props.note !== undefined;
}
</script>

<template>
  <div class="flex justify-between items-center py-4">
    <span>{{ label }}</span>

    <TextStyle
      v-if="isNegativeNumber"
      strong
      :shade="TextStyleShade.Dense"
    >
      <span class="text-orange-600">
        {{ formattedValue }}
      </span>
    </TextStyle>

    <TextStyle
      v-if="!isNegativeNumber"
      strong
      :shade="TextStyleShade.Dense"
    >
      <span>{{ formattedValue }}</span>
    </TextStyle>
  </div>
  <div
    v-show="isShowNote()"
    class="my-2 !border-t-0 italic"
  >
    {{ note }}
  </div>
</template>
