<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardHeader,
  CardLayout,
  CardSection,
  CardTitle,
  FormLayout,
  Page,
  Select,
  SelectOption,
  TextField,
  Toggle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import { PageNavigation } from '../../partials';
import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import { PagePropsViewModel } from './types';
import DeleteShippingInstructionsDialog
  from '@app/panel/Pages/Settings/Templates/ShippingInstructions/Components/DeleteShippingInstructionsDialog.vue';

const props = defineProps<PagePropsViewModel>();
const isDeleteDialogOpen = ref(false);
const selectedShippingInstructionForRemoval = ref('');

function openDeleteDialog(shippingInstructionId: string) {
  selectedShippingInstructionForRemoval.value = shippingInstructionId;
  isDeleteDialogOpen.value = true;
}

const form = useForm({
  name: props.shippingInstruction.name,
  type: props.shippingInstruction.type,
  is_primary: props.shippingInstruction.isPrimary,
});

function getLocaleById(localeId: string) {
  return props.locales.find((l) => l.id == localeId);
}

function getContentFromLocaleId(localeId: string) {
  return props.shippingInstruction.shippingInstructionLocales.find(
    (shippingInstructionLocale) => shippingInstructionLocale.locale.id === localeId,
  )?.content ?? '';
}

const shippingInstructionLocaleForms = props.locales.map((locale) => {
  return useForm({
    locale_id: locale.id,
    content: getContentFromLocaleId(locale.id),
  });
});

function update() {
  form.put(route('panel.settings.shipping-instructions.update', props.shippingInstruction), {
    preserveScroll: true,
  });
}

function updateTemplateLocaleForm(localeId: string) {
  const shippingInstructionLocaleForm = shippingInstructionLocaleForms.find((f) => f.locale_id === localeId);
  shippingInstructionLocaleForm.put(route('panel.settings.shipping-instructions.locale.update', props.shippingInstruction), {
    preserveScroll: true,
  });
}

</script>

<template>
  <AppLayout :title="$t('panel.settings:templates:shipping-instructions:edit:head')">
    <Page :navigation="PageNavigation">
      <CardLayout>
        <Card>
          <CardHeader>
            <CardTitle>{{ $t('panel.settings:templates:shipping-instructions:edit:title') }}</CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <TextField
                v-model="form.name"
                :label="$t('panel.settings:templates:shipping-instructions:edit:name-label')"
                :error="form.errors.name"
                max-length="80"
              />
              <Select
                v-model="form.type"
                :label="$t('panel.settings:templates:shipping-instructions:edit:enable-label')"
              >
                <SelectOption
                  v-for="type in props.types"
                  :key="type.value"
                  v-model="form.type"
                  :value="type.value"
                >
                  {{ type.label }}
                </SelectOption>
              </Select>

              <Toggle
                v-model="form.is_primary"
                :label="$t('panel.settings:templates:shipping-instructions:set-primary', { type: form.type })"
              />
            </FormLayout>
          </CardSection>
          <CardFooter>
            <ButtonGroup>
              <Button
                variant="destructive"
                @click="openDeleteDialog(props.shippingInstruction.id)"
              >
                {{ $t('panel.settings:templates:shipping-instructions:edit:secondary-footer-action') }}
              </Button>

              <Button
                variant="primary"
                :loading="form.processing"
                @click="update"
              >
                {{ $t('panel.settings:templates:shipping-instructions:edit:primary-footer-action') }}
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Card>

        <Card
          v-for="(shippingInstructionLocaleForm, index) in shippingInstructionLocaleForms"
          :key="index"
        >
          <CardHeader>
            <CardTitle>
              {{ `${shippingInstruction.name} (${getLocaleById(shippingInstructionLocaleForm.locale_id).name})` }}
            </CardTitle>
          </CardHeader>
          <CardSection>
            <FormLayout>
              <TextField
                v-model="shippingInstructionLocaleForm.content"
                :error="shippingInstructionLocaleForm.errors.content"
                multiline
                rows="8"
                max-length="2500"
              />
            </FormLayout>
          </CardSection>
          <CardFooter>
            <Button
              :disabled="!shippingInstructionLocaleForm.isDirty"
              :loading="shippingInstructionLocaleForm.processing"
              variant="primary"
              @click="updateTemplateLocaleForm(shippingInstructionLocaleForm.locale_id)"
            >
              {{ $t('panel.global:actions:save') }}
            </Button>
          </CardFooter>
        </Card>
      </CardLayout>
    </Page>
  </AppLayout>
  <DeleteShippingInstructionsDialog
    :open="isDeleteDialogOpen"
    :shipping-instruction="selectedShippingInstructionForRemoval"
    @close="isDeleteDialogOpen = false"
  />
</template>
