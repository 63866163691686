<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    header: {
      type: Boolean,
      default: false,
    },

    rowspan: {
      type: Number,
      default: null,
    },

    colspan: {
      type: Number,
      default: null,
    },

    noWrap: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const classList = computed(() => {
      if (props.header) {
        return ['px-3 py-2.5 text-left text-xs font-medium text-slate-500 uppercase tracking-wider'];
      }

      return ['p-3', { 'whitespace-nowrap': props.noWrap }];
    });

    return {
      classList,
    };
  },
});
</script>

<template>
  <td
    :class="classList"
    :colspan="colspan"
    :rowspan="rowspan"
  >
    <slot />
  </td>
</template>
