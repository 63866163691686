<script lang="ts" setup>
import { computed, inject, provide, Ref } from 'vue';
import { Button, ButtonVariant, Form } from '@app/panel/Components';
import RecursiveBlockRenderer from '../RecursiveBlockRenderer.vue';
import * as Types from '@app/panel/types/generated';
import { useForm } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { formKeyTransformer } from '@app/panel/Utils/formKeyTransformer';
import { sum } from 'lodash';
import { Credentials, Trait } from '@app/panel/Pages/Returns/partials/Trait/types';
import { useTrait } from '../Composables/useTrait';
import { ModalActions, ModalBody } from '@app/panel/Components';
import { useUniqueId } from '@app/panel/Composables/useUniqueId';

type ReturnOrderItemViewModel = Types.App.Models.ViewModels.ReturnOrderItemViewModel;

const props = defineProps<{
  trait: Trait;
  salesOrder: Types.App.Models.ViewModels.SalesOrderViewModel;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const credentialMapping = inject<Record<string, Credentials>>('credentialMapping');
const returnOrder = inject<Ref<Types.App.Models.ViewModels.ReturnOrderViewModel>>('returnOrder');
const returnOrderItems = inject<Ref<ReturnOrderItemViewModel[]>>('returnOrderItems');

const { valueOfReturnedItems } = useTrait(props.salesOrder, returnOrderItems);

const traitForm = useForm({
  subtractDepreciation: {},
  amountAdjustment: 0,
  applyToShipping: false,
  backInStock: credentialMapping[props.trait.platformIntegrationTenantId].restock_items ?? false,
  platformIntegrationTenantId: props.trait.platformIntegrationTenantId,
});

const depreciatedValues = returnOrderItems.value.reduce((acc, item: ReturnOrderItemViewModel): Record<string, number> => {
  acc[item.id] = item.totalDepreciation.amount;
  return acc;
}, {});

const valueOfDepreciatedItems = computed(() => {
  return Object.entries(traitForm.subtractDepreciation)
    .filter((item) => Boolean(item[1]))
    .map((item) => depreciatedValues[item[0]])
    .reduce((acc, item) => acc + item, 0);
});

const computedValues = computed(() => {
  return {
    valueOfReturnedItems: valueOfReturnedItems.value,
    valueOfDepreciatedItems: Math.abs(valueOfDepreciatedItems.value) * -1,
    amountAdjustment: Number(traitForm.amountAdjustment),
    backInStock: traitForm.backInStock,
    shippingCosts: sum([
      returnOrder.value.shippingTotal.amount,
      returnOrder.value.shippingVat.amount,
    ]),
  };
});

const precomputedValues = computed(() => {
  const values = computedValues.value;

  return {
    ...values,
    total: Number(values.valueOfReturnedItems)
    + Number(values.valueOfDepreciatedItems)
    + Number(values.amountAdjustment * 100)
    + (traitForm.applyToShipping ? values.shippingCosts : 0),
  };
});

const postValues = computed(() => {
  return formKeyTransformer({
    ...traitForm.data(),
    amount: precomputedValues.value.total,
  });
});

function submitForm() {
  router.post(
    route('panel.return-orders.giftcard.create', { returnOrder: returnOrder.value }),
    (postValues.value),
    {
      preserveScroll: true,
      onSuccess: () => {
        emits('close');
      },
    },
  );
}

provide('traitForm', traitForm);
provide('precomputedValues', precomputedValues);

const formId = computed(() => useUniqueId('giftcard-trait'));
</script>

<template>
  <Form
    :id="formId"
    @submit="submitForm"
  >
    <ModalBody>
      <RecursiveBlockRenderer
        :children="trait.children"
        @submit="submitForm"
      />
    </ModalBody>

    <ModalActions>
      <Button @click="emits('close')">
        {{ $t('panel.global:actions:cancel') }}
      </Button>

      <Button
        :variant="ButtonVariant.Primary"
        :form="formId"
        @click="submitForm"
      >
        {{ $t('panel.returns:detail-page:trait:giftcard:cta') }}
      </Button>
    </ModalActions>
  </Form>
</template>
