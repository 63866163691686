<script lang="ts" setup>
import { asset } from 'laravel-vapor';
import { computed } from 'vue';
import { Head } from '@inertiajs/vue3';

const props = defineProps<{
  status: string | number;
  requestId?: string;
}>();

const errorMessage = computed(() => {
  if (Number(props.status) === 404) {
    return 'We can\'t seem to find the page you\'re looking for.';
  }

  return 'Oops. Something went wrong!';
});
</script>

<template>
  <Head>
    <title>{{ errorMessage }}</title>
  </Head>

  <div class="w-screen h-screen flex items-center justify-center bg-slate-50">
    <div class="mx-auto max-w-md w-full text-sm">
      <div class="border border-slate-300 rounded-xl p-10 space-y-10 shadow bg-white">
        <img
          :src="asset('img/favicon_light.svg')"
          class="w-20 -ml-4"
          loading="lazy"
        >
        <h1 class="text-xl font-medium">
          <span>{{ errorMessage }}</span>
        </h1>
        <p class="text-slate-600">
          Error code: {{ status }}
        </p>
        <div
          v-if="status !== 404"
          class="font-mono text-slate-400"
        >
          Request ID
          <p class="text-xs">
            {{ requestId }}
          </p>
        </div>
      </div>
      <div class="flex justify-center mt-4 space-x-4 text-slate-400">
        <a
          href="https://www.returnless.com"
          target="_blank"
          class="text-brand-600 hover:underline"
        >
          Homepage
        </a>

        <span>&bull;</span>

        <a
          href="mailto:support@returnless.com"
          class="text-brand-600 hover:underline"
        >
          Contact support
        </a>
      </div>
    </div>
  </div>
</template>
