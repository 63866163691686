<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  List,
  ListItem,
  Select,
  SelectOption,
  SwitchButton,
} from '@app/panel/Components';
import { ReturnOrderQuestionCondition } from '@app/customer/types';
import { theme } from '@app/panel/Composables/useTheme';
import * as Types from '@app/panel/types/generated';
import {
  Condition,
  ConditionComparator,
  ConditionListParser,
  ConditionOperator,
} from '@app/shared/lib/ConditionEngine';
import { InertiaForm } from '@inertiajs/vue3';
import { PlusIcon, TrashIcon } from '@heroicons/vue/24/outline';
import {
  chain,
  has,
  head,
  isArray,
  isEmpty,
  last,
  set,
  unset,
  values,
} from 'lodash';
import { computed, inject, provide, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import FieldRenderer from './FieldRenderer.vue';
import type { ReturnForm } from '../index';
import { conditionOptions, switchButtonOptions } from '../options';
import { insertIntoObjectAt } from '@app/panel/Utils';
import { useErrorHandler } from '@app/panel/Composables/useErrorHandler';
import { TextInput } from '@app/customer/Components';
import { App } from '@app/shared/types/generated-v2';

type ReturnReason = Types.App.Models.ViewModels.ReturnReasonViewModel;
type SelectOptionViewModel = Types.App.Models.ViewModels.SelectOptionViewModel;
const { t } = useI18n();

const {
  availableConditionTypes,
  conditionTypeMap,
  disabled = false,
  requestStatusesOptions = [],
  returnAddressesOptions = [],
  returnQuestions = {},
  returnReasons,
  returnStatusesOptions = [],
  tags = [],
  nonApplicableConditionFields = [],
} = defineProps<{
  availableConditionTypes: Record<string, string>;
  conditionTypeMap: Record<string, string>;
  disabled?: boolean;
  returnAddressesOptions?: SelectOptionViewModel[];
  requestStatusesOptions?: SelectOptionViewModel[];
  returnQuestions?: Record<string, SelectOption>;
  returnReasons: ReturnReason[];
  returnStatusesOptions?: SelectOptionViewModel[];
  tags?: App.Models.ViewModels.TagViewModel[];
  nonApplicableConditionFields?: string[];
}>();

const returnForm = inject<InertiaForm<ReturnForm>>('returnForm')!;

const availableConditionTypeOptions = computed(() => {
  return Object.entries(availableConditionTypes).map(([value, key]): SelectOption => {
    if (key.options) {
      const options = Object.entries(key.options).map(([subValue, subKey]): SelectOption => {
        return {
          label: subKey as string,
          value: subValue as string,
        };
      });

      return {
        label: value as string,
        value: options as SelectOption[],
      };
    }

    return {
      label: key as string,
      value: value as string,
    };
  });
});

function removeCondition(id: string): void {
  delete returnForm.conditions[id];

  const lastItem = last(Object.entries(returnForm.conditions));

  if (lastItem) {
    lastConditionId.value = lastItem[0];
  } else {
    lastConditionId.value = null;
  }
}

function getConditionTypes(input: string) {
  const inputConditionType = conditionTypeMap[input];

  return Object.entries(conditionOptions)
    .filter((conditionOption: [string, string[]]): boolean => {
      return conditionOption[1].includes(inputConditionType);
    })
    .map((conditionOption: [string, string[]]) => {
      return head(conditionOption);
    });
}

function getConditionOptions(input: string) {
  const inputConditionType = conditionTypeMap[input];

  const translatePath = inputConditionType === 'date_time'
    ? 'panel.forms:date-conditions:'
    : 'panel.forms:conditions:';

  return getConditionTypes(input).map((condition: string) => {
    return { value: condition, label: t(translatePath + condition.toLowerCase()) };
  });
}

function refreshConditionValue(conditionId: string): void {
  const condition = returnForm.conditions[conditionId];

  condition.value = '';

  if ('subSelection' in condition) {
    delete condition.subSelection;
  }
}

function updateInputType(newValue: string, conditionId: string): void {
  returnForm.conditions[conditionId].input = newValue;

  refreshConditionValue(conditionId);
}

const groups = computed(() => {
  const conditionList = ConditionListParser.fromReturnQuestionConditions(values(returnForm.conditions) as ReturnOrderQuestionCondition[]);
  return conditionList.getGroups();
});

const isConditionInGroup = computed((): boolean[] => {
  const evaluation = values(groups.value).map((conditionArr: Condition[]) => {
    if (conditionArr.length > 1) {
      return Array(conditionArr.length).fill(true);
    }
    return Array(conditionArr.length).fill(false);
  });

  return evaluation.flat();
});

const isFirstConditionOfGroup = computed(() => {
  const evaluation = values(groups.value).map((conditionArr: Condition[]) => {
    return conditionArr.map((condition: Condition) => {
      return conditionArr.indexOf(condition) === 0;
    });
  });

  return evaluation.flat();
});

function isLastCondition(index: number) {
  return (values(groups.value).flat().length - 1) === index;
}

function getComparatorClasses(index: number, operator: string) {
  return [
    theme('border'),
    { 'bg-slate-50 border-x': isConditionInGroup.value[index] && operator !== ConditionOperator.AND && !isLastCondition(index) },
    { 'pt-4 border-t rounded-t-md': isConditionInGroup.value[index] && isFirstConditionOfGroup.value[index] },
  ];
}

function getConditionClasses(index: number, operator: string) {
  return [
    theme('border'),
    { 'px-4': isConditionInGroup.value[index] },
    { 'bg-slate-50 pb-4 border-b border-x rounded-b-md': isConditionInGroup.value[index] && (operator === ConditionOperator.AND || isLastCondition(index)) },
  ];
}

const lastConditionId = ref<string | null>(last(values(returnForm.conditions))?.id);

function addCondition(): void {
  const id = Date.now() + '';
  lastConditionId.value = id;

  returnForm.conditions[id] = {
    id,
    input: getBestAvailableConditionTypes(),
    comparator: 'EQUALS',
    value: '',
    operator: 'AND',
  };
}

function getBestAvailableConditionTypes(): string {
  if (has(availableConditionTypes, 'return_reason')) {
    return 'return_reason';
  }

  if (has(availableConditionTypes, 'question_answered')) {
    return 'question_answered';
  }

  return head(Object.keys(availableConditionTypes));
}

function shouldShowOperatorSelection(id: string): boolean {
  return id !== lastConditionId.value;
}

function getConditionTemplate(operator: ConditionOperator = ConditionOperator.AND) {
  return {
    id: Date.now() + '',
    input: returnForm.level === 'order' ? 'question_answered' : 'return_reason',
    comparator: ConditionComparator.EQUALS,
    value: '',
    operator,
  };
}

function insertCondition(conditionId: string): void {
  const conditionTemplate = getConditionTemplate(
    returnForm.conditions[conditionId].operator as ConditionOperator,
  );

  const index = Object.keys(returnForm.conditions).indexOf(conditionId) + 1;

  const newConditions = insertIntoObjectAt(
    returnForm.conditions,
    conditionTemplate.id,
    conditionTemplate,
    index,
  );

  Object.keys(returnForm.conditions).forEach((key) => {
    unset(returnForm.conditions, key);
  });

  Object.entries(newConditions).forEach(([key, value]) => {
    set(returnForm.conditions, key, value);
  });
}

function updateComparatorModelValue(conditionId, newValue): void {
  returnForm.conditions[conditionId].comparator = newValue;
}

const conditionErrors = computed(() => {
  return chain(returnForm.errors)
    .pickBy((_, key) => key.includes('condition'))
    .values()
    .uniq()
    .value();
});

const { scrollToMessage } = useErrorHandler();

provide('returnQuestions', returnQuestions);
provide('returnReasons', returnReasons);
provide('conditions', returnForm.conditions);

function nonApplicableError(conditionName: string): string | nulL {
  return nonApplicableConditionFields.includes(conditionName)
    ? t('panel.forms:conditions:not_applicable')
    : null;
}
</script>

<template>
  <div>
    <div class="mb-4">
      <Banner
        v-if="conditionErrors.length"
        :variant="BannerVariant.Critical"
      >
        <List>
          <ListItem
            v-for="(error, key) in conditionErrors"
            :key="key"
            @click="scrollToMessage(error)"
          >
            <span class="cursor-pointer hover:underline">
              {{ error }}
            </span>
          </ListItem>
        </List>
      </Banner>
    </div>

    <div
      v-for="(condition, index) in values(returnForm.conditions)"
      :key="condition.id"
      class="group flex items-center space-x-1"
      data-testid="condition"
    >
      <div
        class="w-full"
        :class="getComparatorClasses(index, condition.operator)"
      >
        <div
          class="flex space-x-2"
          :class="getConditionClasses(index, condition.operator)"
        >
          <div class="grid flex-1 grid-cols-1 gap-4 md:grid-cols-5">
            <div class="flex space-x-4 md:col-span-2">
              <Select
                class="flex-1"
                data-testid="input-type-selection"
                :model-value="returnForm.conditions[condition.id].input"
                :options="availableConditionTypeOptions"
                :error="nonApplicableError(condition.input)"
                @update:model-value="(value) => updateInputType(value, condition.id)"
              />

              <TextInput
                v-if="conditionTypeMap[returnForm.conditions[condition.id].input] === App.Enums.ReturnConditionType.META_DATA"
                v-model="returnForm.conditions[condition.id].meta_key"
                class="flex-1"
                :placeholder="$t('panel.forms:conditions:placeholder:meta_key')"
              />
            </div>

            <div class="md:col-span-1">
              <Select
                data-testid="comparator-selection"
                :model-value="returnForm.conditions[condition.id].comparator"
                :options="getConditionOptions(returnForm.conditions[condition.id].input)"
                @update:model-value="(value) => updateComparatorModelValue(condition.id, value)"
              />
            </div>

            <div
              v-if="isArray(returnForm.conditions[condition.id].value)"
              class="flex space-x-2 md:col-span-2"
            >
              <div class="w-full space-y-2">
                <div
                  v-for="(valueItem, valueIndex) in returnForm.conditions[condition.id].value"
                  :key="valueIndex"
                  class="flex items-center space-x-2"
                >
                  <FieldRenderer
                    :index="valueIndex"
                    :condition-id="condition.id"
                    :condition-type-map="conditionTypeMap"
                    :return-reasons="returnReasons"
                    :return-addresses-options="returnAddressesOptions"
                    :return-statuses-options="returnStatusesOptions"
                    :request-statuses-options="requestStatusesOptions"
                    :return-questions="returnQuestions"
                    :tags="tags"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="! isArray(returnForm.conditions[condition.id].value)"
              class="flex justify-between space-x-2 md:col-span-2"
            >
              <FieldRenderer
                :condition-id="condition.id"
                :condition-type-map="conditionTypeMap"
                :return-reasons="returnReasons"
                :return-addresses-options="returnAddressesOptions"
                :return-statuses-options="returnStatusesOptions"
                :request-statuses-options="requestStatusesOptions"
                :return-questions="returnQuestions"
                :tags="tags"
              />
            </div>
          </div>

          <div
            class="flex items-center"
            data-testid="remove-condition"
            @click="removeCondition(condition.id)"
          >
            <TrashIcon
              class="size-5 cursor-pointer text-slate-500 hover:text-slate-600"
            />
          </div>
        </div>

        <div
          v-if="shouldShowOperatorSelection(condition.id)"
          class="my-4 mr-9 text-center"
        >
          <div class="grid grid-cols-3 place-items-center justify-items-start ">
            <div
              class="group-hover:visible"
              :class="{
                'ml-5': returnForm.conditions[condition.id].operator === 'OR',
              }"
            >
              <Button
                plain
                @click="() => insertCondition(condition.id)"
              >
                <span>{{ $t('panel.settings.forms.list_forms.settings.return_questions.create.insert_condition') }}</span>
              </Button>
            </div>

            <div class="justify-self-center">
              <SwitchButton
                v-model="returnForm.conditions[condition.id].operator"
                data-testid="operator-switch"
                :options="switchButtonOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="mr-9"
      :class="{'mt-4': !isEmpty(returnForm.conditions)}"
    >
      <Button
        data-testid="add-condition"
        :disabled="disabled"
        @click="addCondition"
      >
        <template #icon>
          <PlusIcon />
        </template>
        <span>{{ $t('panel.settings.forms.list_forms.settings.return_questions.create.add_condition') }}</span>
      </Button>
    </div>
  </div>
</template>
