<script setup lang="ts">
import Card from '@app/panel/Components/Card/components/Card.vue';
import DataPointChart from '@app/panel/Pages/Analytics/blocks/Overview/DataPointChart.vue';
import { ref } from 'vue';
import { ActiveDataPoint } from '@app/panel/Pages/Analytics/types/ActiveDataPoint';
import * as Types from '@app/panel/types/generated';

type DataPoint = Types.App.Http.ViewModels.Panel.Analytics.DataPoint;

defineProps<{
  dataPoints: DataPoint[];
}>();

const activeDataPoint = ref<null | ActiveDataPoint>(null);

function onActiveDataPointChange(_activeDataPoint: null | ActiveDataPoint) {
  activeDataPoint.value = _activeDataPoint;
}
</script>

<template>
  <Card :divide="false">
    <div class="border-0 flex justify-between px-4 sm:px-6 py-6 pb-0">
      <div>
        <h2 class="text-lg font-medium leading-6">
          {{ $t('panel.analytics:overview:overview-chart:title') }}
        </h2>
      </div>
      <span
        v-if="activeDataPoint"
        class="inline-flex items-center rounded-md bg-slate-50 px-2 py-1 text-xs font-medium text-slate-600 ring-1 ring-inset ring-slate-500/10"
      >
        <i18n-t
          keypath="panel.analytics:overview:daily-returns:active"
          for="panel.analytics:overview:daily-returns:active"
        >
          <template #amount>
            <span class="font-bold">{{ activeDataPoint.amount }}&nbsp;</span>
          </template>
          <template #date>
            <span class="font-bold">&nbsp;{{ activeDataPoint.label }}&nbsp;</span>
          </template>
        </i18n-t>
      </span>
    </div>
    <div class="m-5">
      <DataPointChart
        :label="$t('panel.analytics:overview:overview-chart:returns')"
        :data-points="dataPoints"
        type="line"
        @active-data-point-changed="onActiveDataPointChange"
      />
    </div>
  </Card>
</template>
