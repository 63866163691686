<script setup lang="ts">
import {
  Button,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardSection,
  CardTitle,
  Dialog,
  DialogCancelButton,
  DialogContent,
  DialogDescription,
  DialogFooter, DialogHeader,
  DialogSection,
  DialogTitle,
  Link,
  TextStyle,
  Toggle,
} from '@returnless/focus-ui';
import { router, useForm } from '@inertiajs/vue3';
import { FormPlatformIntegrationCardViewModel, NonApplicableFeaturesModalViewModel } from './types';
import { computed, watch } from 'vue';

const props = defineProps<{
  formId: string;
  formPlatformIntegrationCard: FormPlatformIntegrationCardViewModel;
  platformIntegrationTenantId: string;
  showConfirmationDialog: boolean;
  nonApplicableFeaturesModal: NonApplicableFeaturesModalViewModel;
  isPrimaryIntegration: boolean;
}>();

const integrationForm = useForm({
  can_search_order: props.formPlatformIntegrationCard.hasSearchOrderEnabled,
  can_create_return: props.formPlatformIntegrationCard.hasCreateReturnEnabled,
  can_create_refund: props.formPlatformIntegrationCard.hasCreateRefundEnabled,
  can_create_coupon: props.formPlatformIntegrationCard.hasCreateCouponEnabled,
  can_create_gift_card: props.formPlatformIntegrationCard.hasCreateGiftCardEnabled,
  can_finalize_return: props.formPlatformIntegrationCard.hasFinalizeReturnEnabled,
});

const hasEnabledTrait = computed(() => {
  return integrationForm.can_create_return
    || integrationForm.can_create_refund
    || integrationForm.can_create_coupon
    || integrationForm.can_create_gift_card
    || integrationForm.can_finalize_return;
});

watch(() => integrationForm.can_search_order, () => {
  if (!integrationForm.can_search_order) {
    integrationForm.can_create_return = false;
    integrationForm.can_create_refund = false;
    integrationForm.can_create_coupon = false;
    integrationForm.can_create_gift_card = false;
    integrationForm.can_finalize_return = false;
  }
});

watch(() => props.isPrimaryIntegration, () => {
  if (props.isPrimaryIntegration) {
    integrationForm.can_search_order = true;
  }
});

watch(hasEnabledTrait, () => {
  if (hasEnabledTrait.value) {
    integrationForm.can_search_order = true;
  }
});

function save() {
  integrationForm.put(route('panel.settings.forms.integrations.update', {
    form: props.formId,
    platformIntegrationTenant: props.platformIntegrationTenantId,
  }), {
    preserveState: true,
    preserveScroll: true,
  });
}

function forceSaveFeatures() {
  integrationForm.put(route('panel.settings.forms.integrations.update', {
    form: props.formId,
    platformIntegrationTenant: props.platformIntegrationTenantId,
    force_disable: true,
  }), {
    preserveState: true,
    preserveScroll: true,
  });
}

function cancel() {
  router.get(
    route('panel.settings.forms.integrations', { form: props.formId }),
    {},
    {
      replace: true,
      preserveScroll: true,
    },
  );
}
</script>

<template>
  <Card>
    <CardHeader>
      <CardTitle>
        {{ formPlatformIntegrationCard.title }}
      </CardTitle>
      <CardDescription>{{ $t('panel.settings:integrations:integration-card-description') }}</CardDescription>
    </CardHeader>
    <CardSection>
      <div class="flex flex-col space-y-3">
        <Toggle
          v-model="integrationForm.can_search_order"
          :label="$t('panel.settings:integrations:features:search-order')"
          :disabled="!formPlatformIntegrationCard.supportsSearchOrder || isPrimaryIntegration"
          :class="{
            'hidden': !formPlatformIntegrationCard.supportsSearchOrder,
          }"
        />
        <Toggle
          v-model="integrationForm.can_create_coupon"
          :label="$t('panel.settings:integrations:features:create-coupon')"
          :disabled="!formPlatformIntegrationCard.supportsCreateCoupon"
          :class="{
            'hidden': !formPlatformIntegrationCard.supportsCreateCoupon,
          }"
        />
        <Toggle
          v-model="integrationForm.can_create_return"
          :label="$t('panel.settings:integrations:features:create-return')"
          :disabled="!formPlatformIntegrationCard.supportsCreateReturn"
          :class="{
            'hidden': !formPlatformIntegrationCard.supportsCreateReturn,
          }"
        />
        <Toggle
          v-model="integrationForm.can_create_refund"
          :label="$t('panel.settings:integrations:features:create-refund')"
          :disabled="!formPlatformIntegrationCard.supportsCreateRefund"
          :class="{
            'hidden': !formPlatformIntegrationCard.supportsCreateRefund,
          }"
        />
        <Toggle
          v-model="integrationForm.can_create_gift_card"
          :label="$t('panel.settings:integrations:features:create-gift-card')"
          :disabled="!formPlatformIntegrationCard.supportsCreateGiftCard"
          :class="{
            'hidden': !formPlatformIntegrationCard.supportsCreateGiftCard,
          }"
        />
        <Toggle
          v-model="integrationForm.can_finalize_return"
          :label="$t('panel.settings:integrations:features:finalize-return')"
          :disabled="!formPlatformIntegrationCard.supportsFinalizeReturn"
          :class="{
            'hidden': !formPlatformIntegrationCard.supportsFinalizeReturn,
          }"
        />
      </div>
    </CardSection>
    <CardFooter>
      <Button
        :loading="integrationForm.processing"
        :disabled="!integrationForm.isDirty"
        @click="save"
      >
        {{ $t('panel.settings:integrations:features:save') }}
      </Button>
    </CardFooter>
  </Card>

  <Dialog
    :open="showConfirmationDialog"
    @cancel="cancel"
  >
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{{ $t('panel.settings:integrations:features:change-title') }}</DialogTitle>
        <DialogDescription>
          {{ $t('panel.settings:integrations:features:change-description') }}
        </DialogDescription>
      </DialogHeader>
      <DialogSection v-if="nonApplicableFeaturesModal">
        <div class="flex flex-col space-y-3">
          <div
            class="flex flex-col space-y-1"
          >
            <TextStyle strong>
              {{ $t('panel.settings:integrations:features:automations-title') }}
            </TextStyle>
            <div class="flex flex-col items-start">
              <Link
                v-for="formAutomation in nonApplicableFeaturesModal.formAutomations"
                :key="formAutomation.href"
                :href="formAutomation.href"
                external
              >
                {{ formAutomation.label }}
              </Link>
            </div>
          </div>
        </div>
      </DialogSection>

      <DialogFooter>
        <DialogCancelButton>
          {{ $t('panel.global:actions:cancel') }}
        </DialogCancelButton>
        <Button
          variant="primary"
          @click="forceSaveFeatures"
        >
          {{ $t('panel.settings:integrations:features:integration-proceed') }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
