<script lang="ts" setup>
import { Card } from '@app/panel/Components';
import { NoteSection } from '@app/panel/Containers';
import { InertiaForm } from '@inertiajs/vue3';
import type { Types } from '@app/shared/types/generated-v2';

const props = defineProps<{
  returnOrder: Types['App.Models.ViewModels.ReturnOrderViewModel'];
  noteTemplates: Types['App.Models.ViewModels.NoteTemplateViewModel'];
}>();

function createNote(form: InertiaForm<any>) {
  form.post(route('panel.return-orders.create-note', props.returnOrder), {
    preserveState: true,
    preserveScroll: true,
    onSuccess: () => {
      form.reset();
    },
  });
}
</script>

<template>
  <Card :title="$t('panel.returns.detail_notes.card_title')">
    <NoteSection
      :return-order="returnOrder"
      :note-templates="noteTemplates"
      standalone
      :notes="returnOrder.notes"
      @submit="createNote"
    />
  </Card>
</template>
