<script lang="ts" setup>
import { PageNavigation } from '@app/panel/Pages/Settings/Integrations/partials';
import { CardSection } from '@app/panel/Components';
import {
  Badge,
  Button,
  ButtonContent,
  ButtonIcon,
  Card,
  CardDescription,
  CardHeader,
  CardIcon,
  CardLayout,
  CardTitle,
  EmptyState,
  EmptyStateActions,
  EmptyStateContent,
  EmptyStateDescription,
  EmptyStateTitle,
  Page,
  PageDescription,
  PageHeader,
  PageTitle,
} from '@returnless/focus-ui';
import { AppLayout } from '@app/panel/Layouts';
import type { Types } from '@app/shared/types/generated-v2';
import { StatusBadge } from '@app/panel/types';
import { useI18n } from 'vue-i18n';
import { PlusIcon } from '@heroicons/vue/16/solid';

const { t } = useI18n();

defineProps<Types['App.Http.ViewModels.Panel.Settings.Integrations.ShippingContracts.ListShippingContractsViewModel']>();

function getStatusBadge(contract: Types['App.Models.ViewModels.ShippingContractViewModel']): StatusBadge | null {
  if (!contract.isActivated) {
    return { color: 'blue', label: t('panel.settings:contracts:shipping:pending') };
  }

  return contract.isEnabled ? null : { color: 'amber', label: t('panel.settings:contracts:shipping:disabled') };
}
</script>

<template>
  <AppLayout :title="t('panel.settings:contracts:shipping:title')">
    <Page :navigation="PageNavigation">
      <PageHeader>
        <PageTitle>{{ t('panel.settings:contracts:shipping:title') }}</PageTitle>
        <PageDescription>{{ t('panel.settings:integrations:platform-integrations:description') }}</PageDescription>
        <template
          v-if="shippingContracts.length > 0"
          #page-header-actions
        >
          <Button :href="$route('panel.settings.integrations.shipping-contracts.list-integrations')">
            <ButtonIcon :icon="PlusIcon" />
            <ButtonContent>{{ t('panel.settings:contracts:shipping:add') }}</ButtonContent>
          </Button>
        </template>
      </PageHeader>

      <CardLayout
        v-if="shippingContracts.length > 0"
        :columns="3"
      >
        <Card
          v-for="shippingContract in shippingContracts"
          :key="shippingContract.id"
        >
          <CardHeader>
            <div class="flex items-start justify-between space-x-2">
              <CardIcon
                :alt="t('panel.settings.integrations.shipping.logo_alt')"
                :source="shippingContract.shippingIntegration.logo.imageSourceUrl"
              />
              <Badge
                v-if="getStatusBadge(shippingContract)"
                :color="getStatusBadge(shippingContract)!.color"
              >
                {{ getStatusBadge(shippingContract)?.label }}
              </Badge>
            </div>
            <CardTitle>{{ shippingContract.name }}</CardTitle>
            <CardDescription>{{ shippingContract.description }}</CardDescription>
          </CardHeader>
          <CardSection>
            <Button
              variant="secondary"
              :href="$route('panel.settings.integrations.shipping-contracts.show', shippingContract)"
            >
              {{ t('panel.settings:contracts:shipping:edit') }}
            </Button>
          </CardSection>
        </Card>
      </CardLayout>

      <EmptyState v-if="shippingContracts.length === 0">
        <EmptyStateContent>
          <EmptyStateTitle>{{ t('panel.settings:contracts:shipping:empty:title') }}</EmptyStateTitle>
          <EmptyStateDescription>
            {{ t('panel.settings:contracts:shipping:empty:description') }}
          </EmptyStateDescription>
        </EmptyStateContent>
        <EmptyStateActions>
          <Button :href="$route('panel.settings.integrations.shipping-contracts.list-integrations')">
            {{ t('panel.settings:contracts:shipping:add') }}
          </Button>
        </EmptyStateActions>
      </EmptyState>
    </Page>
  </AppLayout>
</template>
