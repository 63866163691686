<script lang="ts" setup>
import * as Types from '@app/panel/types/generated';
import ShippingMethodsDataTableView from '@app/panel/Pages/Settings/Forms/ShippingMethods/ShippingMethodsDataTable/ShippingMethodsDataTableView.vue';
import {
  Card,
  CardSection,
} from '@app/panel/Components';
import ShippingMethodsDataTableFooter from '@app/panel/Pages/Settings/Forms/ShippingMethods/ShippingMethodsDataTable/ShippingMethodsDataTableFooter.vue';
import ShippingMethodsDataTableTabs
  from '@app/panel/Pages/Settings/Forms/ShippingMethods/ShippingMethodsDataTable/ShippingMethodsDataTableTabs.vue';
import { InertiaForm } from '@inertiajs/vue3';

type FormShippingMethodsViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsViewModel;
type FormShippingMethodsItemViewModel = Types.App.Http.ViewModels.Panel.Settings.Forms.FormShippingMethodsItemViewModel;

defineProps<{
  form: FormShippingMethodsViewModel['form'];
  selectedContract: FormShippingMethodsViewModel['selectedContract'];
  formShippingMethodsListViewModel: FormShippingMethodsViewModel['formShippingMethodsListViewModel'];
  sortBy: string | null;
  filterForm: InertiaForm;
}>();

const emit = defineEmits<{
  sorted: [items: string | null];
  perPageChange: [perPage: number];
}>();

const dataTableItems = defineModel<FormShippingMethodsItemViewModel[]>('dataTableItems', { required: true });
const selectedDataTableItems = defineModel<FormShippingMethodsItemViewModel[]>('selectedDataTableItems', { required: true });

function handleTableFooterChange(perPage: number): void {
  emit('perPageChange', perPage);
}

function handleSort(sort: string | null): void {
  emit('sorted', sort);
}
</script>

<template>
  <Card>
    <CardSection flush>
      <ShippingMethodsDataTableTabs
        :form="form"
        :selected-contract="selectedContract"
        :filter-tabs="formShippingMethodsListViewModel.filterTabs"
        :previous-page-url="formShippingMethodsListViewModel.previousPageUrl"
        :next-page-url="formShippingMethodsListViewModel.nextPageUrl"
      />
    </CardSection>

    <ShippingMethodsDataTableView
      v-model:data-table-items="dataTableItems"
      v-model:selected-data-table-items="selectedDataTableItems"
      :form="form"
      :form-shipping-methods-list-view-model="formShippingMethodsListViewModel"
      :selected-contract="selectedContract"
      :sort-by="sortBy"
      :filter-form="filterForm"
      @sorted="handleSort"
    />

    <CardSection dense>
      <ShippingMethodsDataTableFooter
        :paginator-status="formShippingMethodsListViewModel.paginatorStatus"
        :per-page="formShippingMethodsListViewModel.perPage"
        :previous-page-url="formShippingMethodsListViewModel.previousPageUrl"
        :next-page-url="formShippingMethodsListViewModel.nextPageUrl"
        @change="handleTableFooterChange"
      />
    </CardSection>
  </Card>
</template>
