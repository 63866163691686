<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Heading,
  List,
  ListItem,
  Modal,
  TextStyle,
} from '@app/panel/Components';
import { useMultiStep } from '@app/panel/Composables/useMultiStep';
import * as Types from '@app/panel/types/generated';
import { ArrowRightCircleIcon, BookOpenIcon, ChevronLeftIcon, ShieldCheckIcon } from '@heroicons/vue/24/outline';
import { onMounted, PropType } from 'vue';
import { router } from '@inertiajs/vue3';

type IntegrationViewModel = Types.App.Models.ViewModels.PlatformIntegrationViewModel;

type TenantViewModel = Types.App.Models.ViewModels.TenantViewModel;

defineProps({
  integration: {
    type: Object as PropType<IntegrationViewModel>,
    default: null,
  },

  loading: {
    type: Boolean,
    default: false,
  },

  open: {
    type: Boolean,
    default: false,
  },

  tenant: {
    type: Object as PropType<TenantViewModel>,
    required: true,
  },
});

defineEmits<{
  (e: 'verify'): void;
}>();

const {
  currentStep,
  firstStep,
  nextStep,
  lastStep,
  previousStep,
  setFirstStep,
  setLastStep,
} = useMultiStep();

onMounted(() => {
  setFirstStep(1);
  setLastStep(1);
});

function verify(): void {
  router.get(route('panel.integration.lightspeed.verify'));
}

function openDocumentationPage(url: string): void {
  window.open(url, '_blank');
}
</script>

<template>
  <Modal
    :open="open"
    :click-outside="false"
  >
    <ChevronLeftIcon
      v-if="currentStep !== firstStep"
      class="h-6 w-6 cursor-pointer text-slate-500 hover:text-slate-700"
      @click="previousStep"
    />

    <div class="flex items-center flex-col text-center space-y-4 mb-2">
      <div class="w-40 h-28 flex items-center justify-center">
        <img
          class="w-40 h-full"
          :src="integration.logo.imageSourceUrl"
          loading="lazy"
        >
      </div>

      <Heading strong>
        {{ $t('panel.dashboard.integrations.lightspeed.title', { name: integration.logo.name }) }}
      </Heading>
    </div>

    <div
      v-if="currentStep === 1"
      class="space-y-6"
    >
      <div class="space-y-1 mt-6">
        <TextStyle>
          <List marker-subdued>
            <ListItem>{{ $t('panel.dashboard.integrations.lightspeed.step1.item1') }}</ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.lightspeed.step1.item2.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.lightspeed.step1.item2.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.lightspeed.step1.item2.part3') }}
            </ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.lightspeed.step1.item3.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.lightspeed.step1.item3.part2') }}</strong>
            </ListItem>

            <ListItem>
              {{ $t('panel.dashboard.integrations.lightspeed.step1.item4.part1') }}
              <strong>{{ $t('panel.dashboard.integrations.lightspeed.step1.item4.part2') }}</strong>
              {{ $t('panel.dashboard.integrations.lightspeed.step1.item4.part3') }}
            </ListItem>
          </List>
        </TextStyle>
      </div>
    </div>

    <div class="mt-4">
      <ButtonGroup full-width>
        <Button
          full-width
          @click="openDocumentationPage(integration.documentationUrl)"
        >
          <template #icon>
            <BookOpenIcon class="h-5 w-5 text-slate-400" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.lightspeed.integration_guide_button') }}</span>
        </Button>

        <Button
          v-if="currentStep === lastStep"
          :variant="ButtonVariant.Primary"
          full-width
          :loading="loading"
          @click="verify"
        >
          <template #icon>
            <ShieldCheckIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.lightspeed.validate_integration_button') }}</span>
        </Button>
        <Button
          v-else
          full-width
          :variant="ButtonVariant.Primary"
          @click="nextStep"
        >
          <template #icon>
            <ArrowRightCircleIcon class="h-5 w-5 text-white" />
          </template>
          <span>{{ $t('panel.dashboard.integrations.lightspeed.next_button') }}</span>
        </Button>
      </ButtonGroup>
    </div>
  </Modal>
</template>
