<script lang="ts" setup>
import { computed, inject } from 'vue';
import {
  Form,
  FormLayout,
  Select,
  Stack,
  TextContainer,
  TextInput,
  TextInputFieldTypes,
  TextInputFontFamily,
  TextStyle,
  TextStyleFontSize,
  TextStyleShade,
} from '@app/panel/Components';
import { PaymentMethod } from '@app/panel/types';
import { App, Types } from '@app/shared/types/generated-v2';
import { InertiaForm } from '@inertiajs/vue3';
import type { PaymentMethodForm } from '../types';

const props = defineProps<{
  id: string;
  tenant: Types['App.Http.ViewModels.Panel.Account.BillingViewModel']['tenant'];
  paymentMethods: Array<PaymentMethod>;
  onSubmit: () => void;
}>();

const paymentMethodForm = inject<InertiaForm<PaymentMethodForm>>('paymentMethodForm');

function onPaymentMethodChange(paymentMethod: string) {
  paymentMethodForm.reset();
  paymentMethodForm.payment_method = paymentMethod;
}

const paymentOptions = computed(() => {
  const paymentMethods = [
    { value: App.Enums.PaymentMethodType.SepaDebit, label: 'SEPA Direct Debit' },
  ];

  if (props.tenant.hasCreditcardPaymentEnabled) {
    paymentMethods.push({ value: App.Enums.PaymentMethodType.Card, label: 'Credit Card' });
  }

  return paymentMethods;
});
</script>

<template>
  <Form
    :id="id"
    @submit="onSubmit"
  >
    <Stack vertical>
      <FormLayout>
        <FormLayout>
          <Select
            v-model="paymentMethodForm.payment_method"
            :label="$t('panel.account.billing_and_subscription.payment_method.payment_method_input_label')"
            :options="paymentOptions"
            @update:model-value="onPaymentMethodChange"
          />
        </FormLayout>

        <div v-if="paymentMethodForm.payment_method === App.Enums.PaymentMethodType.Card">
          <FormLayout>
            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.cc_number"
                :error="paymentMethodForm.errors.cc_number"
                :font-family="TextInputFontFamily.Mono"
                :label="$t('panel.account.billing_and_subscription.payment_method.cc_number_input_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.holder_name"
                :error="paymentMethodForm.errors.holder_name"
                :label="$t('panel.account.billing_and_subscription.payment_method.cc_name_input_label')"
              />
            </FormLayout>

            <FormLayout :columns="2">
              <FormLayout>
                <TextInput
                  v-model="paymentMethodForm.cc_ccv"
                  :font-family="TextInputFontFamily.Mono"
                  :error="paymentMethodForm.errors.cc_ccv"
                  :label="$t('panel.account.billing_and_subscription.payment_method.cc_ccv_input_label')"
                />
              </FormLayout>

              <FormLayout>
                <TextInput
                  v-model="paymentMethodForm.cc_expiration_date"
                  :type="TextInputFieldTypes.Month"
                  :error="paymentMethodForm.errors.cc_expiration_date"
                  :label="$t('panel.account.billing_and_subscription.payment_method.expiration_date_input_label')"
                  placeholder="MM/YY"
                />
              </FormLayout>
            </FormLayout>

            <FormLayout>
              <TextContainer>
                <TextStyle
                  :font-size="TextStyleFontSize.Small"
                  :shade="TextStyleShade.Subdued"
                >
                  {{ $t('panel.account.billing_and_subscription.payment_method.modal_credit_card_footer_text') }}
                </TextStyle>
              </TextContainer>
            </FormLayout>
          </FormLayout>
        </div>

        <div v-if="paymentMethodForm.payment_method === App.Enums.PaymentMethodType.SepaDebit">
          <FormLayout>
            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.iban_number"
                :error="paymentMethodForm.errors.iban_number"
                :label="$t('panel.account.billing_and_subscription.payment_method.iban_number_input_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextInput
                v-model="paymentMethodForm.holder_name"
                :error="paymentMethodForm.errors.holder_name"
                :label="$t('panel.account.billing_and_subscription.payment_method.iban_holder_input_label')"
              />
            </FormLayout>

            <FormLayout>
              <TextContainer>
                <TextStyle
                  :font-size="TextStyleFontSize.Small"
                  :shade="TextStyleShade.Subdued"
                >
                  {{ $t('panel.account.billing_and_subscription.payment_method.modal_sepa_footer_text') }}
                </TextStyle>
              </TextContainer>
            </FormLayout>
          </FormLayout>
        </div>
      </FormLayout>
    </Stack>
  </Form>
</template>
