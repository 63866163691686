import { App } from '@app/shared/types/generated-v2';
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

export function usePermission() {
  const permissions = computed<Array<App.Enums.PermissionType>>(() => {
    const user = (usePage().props as any).user;
    return user?.permissions as Array<App.Enums.PermissionType> ?? [];
  });

  function can(permission: App.Enums.PermissionType): boolean {
    const { isOwner } = (usePage().props as any).user;

    // Always allow owner to do anything.
    if (isOwner) {
      return true;
    }

    return permissions.value.includes(permission);
  }

  function cannot(permission: App.Enums.PermissionType): boolean {
    return !can(permission);
  }

  return { can, cannot };
}
